import { IReduxAction } from "../store.type";
import LevelTypes from "../type/level.type";
import { fetchLevels, insertLevel, updateLevel, deleteLevel } from "../../service/level.service";
import { ILevel, INormalizedLevels } from "../../../Model/Level";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchLevelsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const levels = await fetchLevels(query);
            dispatch({
                type: LevelTypes.FETCH_LEVELS,
                payload: {
                    byId: levels.reduce((p: INormalizedLevels, c: ILevel): INormalizedLevels => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: levels.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchAllLevelsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const levels = await fetchLevels(query);
            dispatch({
                type: LevelTypes.FETCH_ALL_LEVELS,
                payload: {
                    byId: levels.reduce((p: INormalizedLevels, c: ILevel): INormalizedLevels => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: levels.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertLevelAction(level: ILevel): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertLevel(level);
            dispatch({
                type: LevelTypes.INSERT_LEVEL
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateLevelAction(id: number, level: ILevel): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateLevel(id, level);
            dispatch({
                type: LevelTypes.UPDATE_LEVEL
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteLevelAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteLevel(id);
            dispatch({
                type: LevelTypes.DELETE_LEVEL
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setLevelsAction(dict: INormalizedLevels): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: LevelTypes.SET_LEVELS,
            payload: dict
        });
    };
}

export function setSelectedLevelAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: LevelTypes.SET_SELECTED_LEVEL,
            payload: id
        });
    };
}

export function setSelectedLevelMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: LevelTypes.SET_SELECTED_LEVEL_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingLevelAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: LevelTypes.SET_IS_LOADING_LEVEL,
            payload: isLoading
        });
    };
}
export function setLevelOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: LevelTypes.SET_LEVEL_ORDER,
            payload: idsSorted
        });
    };
}
