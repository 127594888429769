import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firstLetterUppercase } from "../../../modules/strings/case";
import "./TableShiftSummaryTemplate.scoped.scss";
import {
    fetchShiftTemplate as fetchShiftTemplateService,
    insertShiftTemplate,
    updateShiftTemplate
} from "../../service/shiftTemplate.service";
import { IShiftTemplate } from "../../../Model/ShiftTemplate";
import { IBackOfficeTable } from "../../constants/Table.constants";
import { currentInstitutionSelector, langSelector, notificationSelector } from "../../store/selectors";
import { NotificationComponent } from "npm-medgo-components";
import { setNotificationHide, setNotificationMessage } from "../../store/action/notification.action";
import LoadingContainer from "../../components/Shared/Loading.container";

const simulateClickOnInputRef = ({ ref }: { ref: React.RefObject<HTMLInputElement> }): void => {
    ref.current?.click();
};

const useFileName = ({ file }: { file: File | null }): string => {
    const lang = useSelector(langSelector)[0];

    let fileName = "";
    if (file) {
        fileName = file.name;
    } else {
        fileName = firstLetterUppercase(lang.noFileSelected);
    }

    return fileName;
};

const TableShiftSummaryTemplateContainer: FC<any> = ({
    backOfficeTable
}: {
    backOfficeTable: IBackOfficeTable;
}): JSX.Element => {
    const dispatch = useDispatch();
    const { title = "" } = backOfficeTable;
    const lang = useSelector(langSelector)[0]; // @ts-ignore
    const displayedTitle = lang[title];
    const selectedInstitution = useSelector(currentInstitutionSelector);
    const notification = useSelector(notificationSelector);

    const [shiftTemplate, setShiftTemplate] = useState<IShiftTemplate>({
        id: -1,
        templateName: ""
    });
    const [file, setFile] = useState<File | null>(null);
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
    const fileName = useFileName({ file });

    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    const fetchShiftTemplate = async (): Promise<void> => {
        const shiftTemplateResponse = await fetchShiftTemplateService({
            select: ["id", "templateName", "institution"],
            query: { institution: selectedInstitution }
        });
        setShiftTemplate(shiftTemplateResponse[0]);
    };

    useEffect(() => {
        setFile(null);
        fetchShiftTemplate();
    }, [selectedInstitution]);

    const handleChange = (e: any): void => {
        setFile(e.target.files[0]);
        // reset file input to avoid issue with onChange trigger
        e.target.value = null;
    };
    const handleSubmit = async (e: any): Promise<void> => {
        e.preventDefault();
        if (file) {
            setIsUploadingFile(true);
            try {
                const formData = new FormData();
                formData.append("file", file);
                let res;

                if (!shiftTemplate) {
                    formData.append("institution", selectedInstitution.toString());
                    res = await insertShiftTemplate(formData);
                } else {
                    res = await updateShiftTemplate(shiftTemplate.id, formData);
                }

                setShiftTemplate(res);
                setFile(null);
                dispatch(
                    setNotificationMessage({
                        message: lang.fileUploadSuccess,
                        icon: "fa fa-times",
                        color: "success"
                    })
                );
            } catch (err) {
                dispatch(
                    setNotificationMessage({
                        message: lang.errCodeGeneric500,
                        icon: "fa fa-times",
                        color: "danger"
                    })
                );
                console.error(err);
            }
            setIsUploadingFile(false);
        }
    };

    const handleHideNotification = (): void => {
        dispatch(setNotificationHide());
    };

    return (
        <div className="col-12 container-fluid h-100 mt-5">
            <NotificationComponent
                text={notification.message}
                icon={notification.icon}
                isVisible={notification.isVisible}
                handleHide={handleHideNotification}
                color={notification.color}
                disapearsAfter={3000}
            />
            <div className="table-title">{firstLetterUppercase(displayedTitle)}</div>
            <div className="jumbotron mt-0 p-5">
                <h5 className="mb-5">{shiftTemplate ? shiftTemplate.templateName : lang.noTemplateUploaded}</h5>
                <form className="row no-gutters w-100" onSubmit={handleSubmit}>
                    <div className="mr-auto d-flex">
                        <button
                            type="button"
                            className="button select-file-button"
                            onClick={() => simulateClickOnInputRef({ ref: hiddenFileInputRef })}
                        >
                            {firstLetterUppercase(lang.selectAFile)}
                        </button>
                        <span className="input-file-name my-auto ml-4">{fileName}</span>
                    </div>
                    <input
                        ref={hiddenFileInputRef}
                        className="d-none"
                        type="file"
                        name="file"
                        onChange={e => handleChange(e)}
                    />
                    <button type="submit" className="col-1 button">
                        {isUploadingFile ? (
                            <div className="loader-container h-100">
                                <LoadingContainer isLoading={true} isFullPage={false} />
                            </div>
                        ) : (
                            firstLetterUppercase(lang.upload)
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default TableShiftSummaryTemplateContainer;
