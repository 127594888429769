enum JobActionTypes {
    FETCH_JOBS = "FETCH_JOBS",
    SET_JOBS = "SET_JOBS",
    SET_SELECTED_JOB = "SET_SELECTED_JOB",
    SET_SELECTED_JOB_MULTI = "SET_SELECTED_JOB_MULTI",
    SET_IS_LOADING_JOB = "SET_IS_LOADING_JOB",
    SET_JOB_ORDER = "SET_JOB_ORDER",
    INSERT_INSTITUTION_HAS_JOB = "INSERT_INSTITUTION_HAS_JOB",
    UPDATE_JOB = "UPDATE_JOB",
    DELETE_JOB = "DELETE_JOB",
    FETCH_JOBS_COUNT = "FETCH_JOBS_COUNT",
    FETCH_ALL_JOBS = "FETCH_ALL_JOBS"
}
export default JobActionTypes;
