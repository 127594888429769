import React, { Component } from "react";
import { connect } from "react-redux";

import { Constants, Equality } from "npm-hublo-toolbox";
import { IQueryParams } from "npm-medgo-query";

import { IAdmin } from "../../../Model/Admin";
import { IInstitution, INormalizedInstitutions } from "../../../Model/Institution";
import { IJob } from "../../../Model/Job";
import { ISchedule } from "../../../Model/Schedule";
import { INormalizedServices } from "../../../Model/Service";
import { INormalizedSpecialties } from "../../../Model/Specialty";
import { EBackOfficeColumnType, InstitutionColumns } from "../../constants/Column.constants";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { ILang } from "../../lang/Lang";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import {
    setFormDictionaryAction,
    setFormKeyValueAction,
    setFormMessageContentAction,
    setFormScheduleRequestContentAction
} from "../../store/action/form.action";
import {
    fetchInstitutionsAction,
    setSelectedInstitutionAction,
    updateInstitutionAction
} from "../../store/action/institution.action";
import { fetchJobsAction } from "../../store/action/job.action";
import { fetchSchedulesAction } from "../../store/action/schedule.action";
import { fetchServicesAction } from "../../store/action/service.action";
import { fetchSpecialtiesAction } from "../../store/action/specialty.action";
import { IFormDictionary } from "../../store/type/form.type";
import View from "./Institution.view";

import { FetchLimits } from "../../../Constant/Api.constant";
import { INormalizedLevels } from "../../../Model/Level";
import AxiosInstance from "../../../modules/axios/axios.instance";
import {
    checkAddress,
    checkAddressAutocomplete,
    checkLatitude,
    checkLongitude,
    checkMultiple,
    checkNameEtablissement,
    checkPostalCode
} from "../../../toolbox/checks";
import { Permission, isAllowedTo } from "../../modules/authorization/permission";

interface IReduxPropsInstitutionContainer {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
    idSelectedInstitution: number;
    institutionsById: IInstitution[];
    idInstitutions: number[];
    selectedMulti: number[];
    jobsById: IJob[];
    idJobs: number[];
    scheduleDict: ISchedule[];
    idSchedules: number[];
    servicesById: INormalizedServices;
    idServices: number[];
    idSpecialties: number[];
    specialtyDict: INormalizedSpecialties;
    formDictionary: IFormDictionary;
    messageContent: string;
    scheduleRequestContent: string;
    level: INormalizedLevels;
}

interface IReduxActionInstitutionContainer {
    setInstitutionsAction: (dict: INormalizedInstitutions) => any;
    setSelectedInstitutionAction: (id: number) => any;
    fetchInstitutionsAction: (param: IQueryParams) => any;
    updateInstitutionAction: (id: number, institution: IInstitution) => void;
    fetchJobsAction: (param: IQueryParams) => any;
    fetchServicesAction: (param: IQueryParams) => any;
    fetchSpecialtiesAction: (param: IQueryParams) => any;
    fetchSchedulesAction: (param: IQueryParams) => any;
    setFormDictionaryAction: (selectedOption: { [key: string]: any }) => void;
    setFormScheduleRequestContentAction: (scheduleRequestContent: string) => void;
    setFormMessageContentAction: (messageContent: string) => void;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
}

interface IStateInstitutionContainer {
    addressText: string;
    errorMessage: string;
    infoMessage: string;
    isLoading: boolean;
    firstLoad: boolean;
    hasBeenUpdated: boolean;
}

interface IPropsInstitutionContainer extends IReduxPropsInstitutionContainer, IReduxActionInstitutionContainer {}

class InstitutionContainer extends Component<IPropsInstitutionContainer, IStateInstitutionContainer> {
    constructor(props: IPropsInstitutionContainer) {
        super(props);

        this.state = {
            addressText: "",
            errorMessage: "",
            infoMessage: "",
            isLoading: false,
            firstLoad: false,
            hasBeenUpdated: false
        };
    }

    async componentDidMount(): Promise<void> {
        this.updateInstitutions();
    }

    async updateInstitutions(): Promise<void> {
        await this.props
            .fetchInstitutionsAction({
                select: ["id", "name", "address", "phone", "accessInfo", "presentation", "lat", "lng", "postalCode"],
                limit: 300,
                query: {},
                orderby: [
                    {
                        columnName: "name",
                        orientation: "ASC"
                    }
                ]
            })
            .catch(console.error);
        let institutionList: IInstitution[];
        institutionList = [];
        if (this.props.institutionsById) {
            if (this.state.firstLoad === false) {
                institutionList = getModelsFromDict<IInstitution>(
                    this.props.institutionsById,
                    this.props.idInstitutions
                );
                await this.props.setSelectedInstitutionAction(institutionList[0].id);
                this.selectInstitution(institutionList[0]);
                this.setState({
                    firstLoad: true
                });
            } else {
                this.selectInstitution(this.getSelectedInstitution(this.props.idSelectedInstitution));
            }
        }
    }

    selectInstitution(institution: any): void {
        if (institution) {
            this.props.setSelectedInstitutionAction(institution.id);
        }
        if (this.state.hasBeenUpdated === true) {
            this.updateInstitutions();
            this.setState({
                hasBeenUpdated: false
            });
        }
        let emptyFormDictionary: IFormDictionary = {};
        this.props.setFormDictionaryAction(emptyFormDictionary);

        this.updateSchedules(institution.id);
        this.updateServices(institution.id);
        this.updateJobs(institution.id);
        this.updateSpecialties(institution.id);
    }

    async updateSchedules(institutionId: any): Promise<void> {
        await this.props.fetchSchedulesAction({
            query: {
                institution: [institutionId],
                isDeleted: false
            },
            limit: FetchLimits.schedules
        });
    }

    async updateServices(institutionId: any): Promise<void> {
        await this.props
            .fetchServicesAction({
                select: ["id", "name"],
                query: {
                    institution: institutionId
                },
                orderby: [
                    {
                        columnName: "name",
                        orientation: "ASC"
                    }
                ],
                limit: FetchLimits.services
            })
            .catch(console.error);
    }

    async updateJobs(institutionId: any): Promise<void> {
        await this.props
            .fetchJobsAction({
                select: ["id", "name"],
                query: {
                    institution: institutionId
                },
                orderby: [
                    {
                        columnName: "name",
                        orientation: "ASC"
                    }
                ],
                limit: FetchLimits.jobs
            })
            .catch(console.error);
    }

    async updateSpecialties(insitutionId: any): Promise<void> {
        await this.props
            .fetchSpecialtiesAction({
                select: ["id", "name"],
                query: {
                    institution: insitutionId
                },
                orderby: [
                    {
                        columnName: "name",
                        orientation: "ASC"
                    }
                ],
                limit: FetchLimits.specialties
            })
            .catch(console.error);
    }

    willHandleChangeInstitution(event: any): void {
        this.handleChangeInstitution(event.value);
    }

    handleChangeInstitution(_whichInstitution: any): void {
        if (!_whichInstitution) {
            return;
        }
        this.setState({
            errorMessage: "",
            infoMessage: ""
        });
        let institution = this.getSelectedInstitution(_whichInstitution);
        this.selectInstitution(institution);
    }

    getSelectedInstitution(idInstitution: number): IInstitution {
        let unsetInstitution = {
            id: -1,
            name: "",
            prefixMission: "",
            phone: "",
            address: "",
            presentation: "",
            accessInfo: ""
        };
        if (this.props.institutionsById) {
            let institutionList = getModelsFromDict<IInstitution>(
                this.props.institutionsById,
                this.props.idInstitutions
            );
            for (let i = 0; i < institutionList.length; i++) {
                if (institutionList[i].id == idInstitution) {
                    return institutionList[i] as IInstitution;
                }
            }
        }
        return unsetInstitution as IInstitution;
    }

    isColumnChanged(field: string): boolean {
        if (getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.institution)[0]) {
            if (
                typeof getFormDictionaryRecordsDict(
                    this.props.formDictionary,
                    EBackOfficeTableName.institution
                )[0].hasOwnProperty(field) !== "undefined"
            ) {
                return true;
            }
        }
        return false;
    }

    saveChanges(): void {
        if (this.props.formDictionary && this.props.formDictionary.hasOwnProperty("institution")) {
            let institution = this.getSelectedInstitution(this.props.idSelectedInstitution);
            let name =
                typeof this.props.formDictionary.institution[0].name !== "undefined"
                    ? this.props.formDictionary.institution[0].name
                    : institution.name;
            let phone =
                typeof this.props.formDictionary.institution[0].phone !== "undefined"
                    ? this.props.formDictionary.institution[0].phone
                    : institution.phone;
            let lat =
                typeof this.props.formDictionary.institution[0].lat !== "undefined"
                    ? this.props.formDictionary.institution[0].lat
                    : institution.lat;
            let lng =
                typeof this.props.formDictionary.institution[0].lng !== "undefined"
                    ? this.props.formDictionary.institution[0].lng
                    : institution.lng;
            let postalCode =
                typeof this.props.formDictionary.institution[0].postalCode !== "undefined"
                    ? this.props.formDictionary.institution[0].postalCode
                    : institution.postalCode;
            let address =
                typeof this.props.formDictionary.institution[0].addressText !== "undefined"
                    ? this.props.formDictionary.institution[0].addressText
                    : institution.address;
            let presentation =
                typeof this.props.formDictionary.institution[0].presentation !== "undefined"
                    ? this.props.formDictionary.institution[0].presentation
                    : institution.presentation;
            let accessInfo =
                typeof this.props.formDictionary.institution[0].accessInfo !== "undefined"
                    ? this.props.formDictionary.institution[0].accessInfo
                    : institution.accessInfo;
            let postData = {
                id: institution.id,
                name: name,
                phone: phone,
                address: address,
                presentation: presentation,
                accessInfo: accessInfo,
                lat: lat,
                lng: lng,
                postalCode: postalCode
            };
            this.check(postData);
            this.update(postData);
        }
    }

    check(institutionToCheck: any): void {
        let checks = [];
        checks.push(checkNameEtablissement(name, this.props.lang));
        if (!Equality.isNullOrUndefined(institutionToCheck.addressText) && this.state.addressText !== "") {
            checks.push(checkAddressAutocomplete(institutionToCheck.address, this.state.addressText, this.props.lang));
        }
        checks.push(checkAddress(institutionToCheck.address, this.props.lang));
        checks.push(checkPostalCode(institutionToCheck.postalCode, this.props.lang));
        checks.push(checkLatitude(institutionToCheck.lat, this.props.lang));
        checks.push(checkLongitude(institutionToCheck.lng, this.props.lang));
        let { errorMessage } = checkMultiple(checks);
        if (!Equality.isNullOrUndefined(errorMessage)) {
            this.setState({
                errorMessage: errorMessage,
                infoMessage: ""
            });
            return;
        }
    }

    async update(postData: any): Promise<void> {
        this.setState({ isLoading: true });
        try {
            this.props.updateInstitutionAction(postData.id, postData);
            this.setState({
                errorMessage: "",
                infoMessage: this.props.lang.modificationsSaved,
                isLoading: false,
                hasBeenUpdated: true
            });
        } catch (err) {
            if (err.responseText) {
                this.setState({ errorMessage: err.responseText, isLoading: false, infoMessage: "" });
            } else {
                this.setState({ errorMessage: err, isLoading: false, infoMessage: "" });
            }
            let emptyFormDictionary: IFormDictionary = {};
            this.props.setFormDictionaryAction(emptyFormDictionary);
        }
    }

    onSelectAddress(addressObject: any): void {
        this.props.setFormKeyValueAction(
            "addressText",
            addressObject.address,
            EBackOfficeTableName.institution,
            InstitutionColumns[4].type,
            0
        );
        this.props.setFormKeyValueAction(
            "lat",
            addressObject.lat,
            EBackOfficeTableName.institution,
            InstitutionColumns[6].type,
            0
        );
        this.props.setFormKeyValueAction(
            "lng",
            addressObject.lng,
            EBackOfficeTableName.institution,
            InstitutionColumns[7].type,
            0
        );
        this.props.setFormKeyValueAction(
            "postalCode",
            addressObject.postalCode,
            EBackOfficeTableName.institution,
            InstitutionColumns[8].type,
            0
        );
        this.setState({
            infoMessage: "",
            addressText: addressObject.address
        });
    }

    async adminWantsNewSchedule(): Promise<void> {
        if (this.props.scheduleRequestContent) {
            try {
                this.props.setFormScheduleRequestContentAction("");
                let foundContact = this.props.me.email ? this.props.me.email : this.props.me.mobilePhone;
                const parameters = {
                    idAdmin: this.props.me.id,
                    contactInfos: foundContact,
                    firstNameAdmin: this.props.me.firstName,
                    lastNameAdmin: this.props.me.lastName,
                    message: this.props.scheduleRequestContent,
                    title: this.props.lang.addTimeCodeToCreateMission
                };
                this.sendMessageByEmail();
                this.sendSlackNotification(parameters);
            } catch (err) {
                if (err.responseText) {
                    this.setState({ errorMessage: err.responseText });
                } else {
                    this.setState({ errorMessage: this.props.lang.noInternetConnexion });
                }
            }
        }
    }

    async adminWantsToBeContacted(): Promise<void> {
        if (this.props.messageContent) {
            try {
                this.props.setFormMessageContentAction("");
                let foundContact = this.props.me.email ? this.props.me.email : this.props.me.mobilePhone;
                const parameters = {
                    idAdmin: this.props.me.id,
                    contactInfos: foundContact,
                    firstNameAdmin: this.props.me.firstName,
                    lastNameAdmin: this.props.me.lastName,
                    message: this.props.messageContent,
                    title: this.props.lang.youHaveQuestion
                };
                this.sendMessageByEmail();
                this.sendSlackNotification(parameters);
            } catch (err) {
                if (err.responseText) {
                    this.setState({ errorMessage: err.responseText });
                } else {
                    this.setState({ errorMessage: this.props.lang.noInternetConnexion });
                }
            }
        }
    }

    async sendMessageByEmail(): Promise<void> {
        const contactDetails = Constants.companyContactDetailsFor(this.props.lang as any);

        const parameters = {
            from: contactDetails.email,
            to: this.props.me.email,
            template: "TEMPLATE__ADMIN__WANTS_TO_BE_CONTACTED",
            lang: localStorage.getItem("lang"),
            data: {
                adminName: this.props.me.firstName
            }
        };
        try {
            await AxiosInstance.post(
                `${process.env.REACT_APP_SERVICE_MESSAGING_URL}/api/v1/email-with-template/send`,
                parameters
            );
        } catch (err) {
            console.error(`Error while calling servicemessaging email-with-template/send: ${err}`);
        }
    }

    async sendSlackNotification(slackData: any): Promise<void> {
        const parameters = {
            name: Constants.ESlackNotificationName.adminWantsToBeContacted,
            vars: slackData
        };
        try {
            await AxiosInstance.post(
                `${process.env.REACT_APP_SERVICE_MESSAGING_URL}/api/v1/slack-with-template/send`,
                parameters
            );
        } catch (err) {
            console.error(`Error while calling servicemessaging slack-with-template/send: ${err}`);
        }
    }

    render(): JSX.Element {
        if (
            !isAllowedTo({
                institutionIds: this.props.me.institutionIds,
                permission: Permission.SEE_PRO_INSTITUTION_PAGE,
                permissions: this.props.me.permissions
            })
        ) {
            return <></>;
        }

        return (
            <div>
                <View
                    isColumnChanged={this.isColumnChanged.bind(this)}
                    getSelectedInstitution={this.getSelectedInstitution.bind(this)}
                    willHandleChangeInstitution={this.willHandleChangeInstitution.bind(this)}
                    onSelectAddress={this.onSelectAddress.bind(this)}
                    saveChanges={this.saveChanges.bind(this)}
                    adminWantsNewSchedule={this.adminWantsNewSchedule.bind(this)}
                    adminWantsToBeContacted={this.adminWantsToBeContacted.bind(this)}
                    errorMessage={this.state.errorMessage}
                    infoMessage={this.state.infoMessage}
                    isLoading={this.state.isLoading}
                />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionContainer => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user,
        formDictionary: centralState.form.formDictionary,
        institutionsById: centralState.institutions.byId,
        idInstitutions: centralState.institutions.ids,
        idSelectedInstitution: centralState.institutions.selectedId,
        selectedMulti: centralState.institutions.selectedMulti,
        jobsById: centralState.jobs.byId,
        idJobs: centralState.jobs.ids,
        scheduleDict: centralState.schedule.byId,
        idSchedules: centralState.schedule.ids,
        servicesById: centralState.services.byId,
        idServices: centralState.services.ids,
        idSpecialties: centralState.specialties.ids,
        specialtyDict: centralState.specialties.byId,
        messageContent: centralState.form.messageContent,
        scheduleRequestContent: centralState.form.scheduleRequestContent,
        level: centralState.level.byId
    }),
    {
        setFormDictionaryAction,
        setFormKeyValueAction,
        setSelectedInstitutionAction,
        fetchInstitutionsAction,
        updateInstitutionAction,
        fetchJobsAction,
        fetchServicesAction,
        fetchSpecialtiesAction,
        fetchSchedulesAction,
        setFormScheduleRequestContentAction,
        setFormMessageContentAction
    }
)(InstitutionContainer);
