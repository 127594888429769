import { IReduxAction, IReduxInitialState } from "../store.type";
import InfoOnWorkerTypes from "../type/infoOnWorker.type";
import { IInfoOnWorker } from "../../../Model/InfoOnWorker";

const initialState: IReduxInitialState<IInfoOnWorker> = {
    byId: {},
    ids: [],
    selectedId: -1,
    allById: {},
    allIds: [],
    selectedMulti: [],
    isLoading: false
};

export default function infoOnWorkerReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InfoOnWorkerTypes.FETCH_INFOONWORKERS:
        case InfoOnWorkerTypes.SET_INFOONWORKERS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InfoOnWorkerTypes.SET_SELECTED_INFOONWORKER: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InfoOnWorkerTypes.SET_SELECTED_INFOONWORKER_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InfoOnWorkerTypes.SET_IS_LOADING_INFOONWORKER: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InfoOnWorkerTypes.SET_INFOONWORKER_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case InfoOnWorkerTypes.FETCH_ALL_INFOONWORKERS: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case InfoOnWorkerTypes.INSERT_INFOONWORKER: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.infoOnWorker.infoOnWorker]: {
                        ...action.payload.infoOnWorker,
                        id: action.payload.infoOnWorker.infoOnWorker,
                        interfacingCode: state.allById[action.payload.infoOnWorker.infoOnWorker].interfacingCode,
                        name: state.allById[action.payload.infoOnWorker.infoOnWorker].name,
                        institution: [action.payload.infoOnWorker.institution]
                    }
                },
                ids: state.ids.includes(action.payload.infoOnWorker.infoOnWorker)
                    ? [...state.ids]
                    : [...state.ids, action.payload.infoOnWorker.infoOnWorker]
            };
        }

        default:
            return state;
    }
}
