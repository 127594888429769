import { IReduxAction } from "../store.type";
import AdminTypes from "../type/admin.type";
import {
    fetchAdmins,
    updateAdmin,
    deleteAdmin,
    deleteAdminInInstitutionNotificationService,
    findOrCreateAdmin,
    tryInsertOrUpdateAdminInfo
} from "../../service/admin.service";
import { IAdmin, INormalizedAdmins } from "../../../Model/Admin";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { IAdminInInstitutionNotificationService } from "../../../Model/AdminInInstitutionNotificationService";

export function fetchAdminsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const admins = await fetchAdmins(query);
            dispatch({
                type: AdminTypes.FETCH_ADMINS,
                payload: {
                    byId: admins.reduce((p: INormalizedAdmins, c: IAdmin): INormalizedAdmins => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: admins.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertAdminAction(
    admin: IAdmin & { idLanguage: number }
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<IAdmin> => {
        try {
            const { admin: newAdmin, created } = await findOrCreateAdmin(admin);
            if (!created) {
                // previous admins do not have necessarily the admin info, try to post it, else, update
                await tryInsertOrUpdateAdminInfo(newAdmin.id, admin.idLanguage);
            }
            newAdmin.service = admin.service ? admin.service : [];
            newAdmin.status = true;
            newAdmin.lang = admin.lang;
            dispatch({
                type: AdminTypes.INSERT_ADMIN,
                payload: newAdmin
            });
            return newAdmin;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateAdminAction(id: number, admin: IAdmin): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateAdmin(id, admin);
            dispatch({
                type: AdminTypes.UPDATE_ADMIN,
                payload: admin
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteAdminAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteAdmin(id);
            dispatch({
                type: AdminTypes.DELETE_ADMIN
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setAdminsAction(dict: INormalizedAdmins): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AdminTypes.SET_ADMINS,
            payload: dict
        });
    };
}

export function setSelectedAdminAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AdminTypes.SET_SELECTED_ADMIN,
            payload: id
        });
    };
}

export function setSelectedAdminMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AdminTypes.SET_SELECTED_ADMIN_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingAdminAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AdminTypes.SET_IS_LOADING_ADMIN,
            payload: isLoading
        });
    };
}
export function setAdminOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AdminTypes.SET_ADMIN_ORDER,
            payload: idsSorted
        });
    };
}
export function deleteAdminInInstitutionNotificationServiceAction(
    adminInInstitutionNotificationServiceAction: IAdminInInstitutionNotificationService,
    idAdmin: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteAdminInInstitutionNotificationService(adminInInstitutionNotificationServiceAction.id);
            dispatch({
                type: AdminTypes.DELETE_ADMIN_IN_INSTITUTION_NOTIFICATION_SERVICE,
                payload: { adminInInstitutionNotificationServiceAction, idAdmin }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
