enum InstitutionOptionContractActionTypes {
    FETCH_INSTITUTIONOPTIONCONTRACTS = "FETCH_INSTITUTIONOPTIONCONTRACTS",
    SET_INSTITUTIONOPTIONCONTRACTS = "SET_INSTITUTIONOPTIONCONTRACTS",
    FETCH_INSTITUTIONOPTIONCONTRACTS_COUNT = "FETCH_INSTITUTIONOPTIONCONTRACTS_COUNT",
    SET_SELECTED_INSTITUTIONOPTIONCONTRACT = "SET_SELECTED_INSTITUTIONOPTIONCONTRACT",
    SET_SELECTED_INSTITUTIONOPTIONCONTRACT_MULTI = "SET_SELECTED_INSTITUTIONOPTIONCONTRACT_MULTI",
    SET_IS_LOADING_INSTITUTIONOPTIONCONTRACT = "SET_IS_LOADING_INSTITUTIONOPTIONCONTRACT",
    SET_INSTITUTIONOPTIONCONTRACT_ORDER = "SET_INSTITUTIONOPTIONCONTRACT_ORDER",
    INSERT_INSTITUTIONOPTIONCONTRACT = "INSERT_INSTITUTIONOPTIONCONTRACT",
    UPDATE_INSTITUTIONOPTIONCONTRACT = "UPDATE_INSTITUTIONOPTIONCONTRACT",
    DELETE_INSTITUTIONOPTIONCONTRACT = "DELETE_INSTITUTIONOPTIONCONTRACT",
    SET_INSTITUTIONOPTIONCONTRACT_ERROR_CODE = "SET_INSTITUTIONOPTIONCONTRACT_ERROR_CODE"
}
export default InstitutionOptionContractActionTypes;
