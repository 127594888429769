import { en } from "./en";
import { ILang } from "./Lang";

/* eslint-disable @typescript-eslint/camelcase */
export const es: ILang = {
    ...en,
    profile: "Mi cuenta",
    myInstitutions: "Mis centros sanitarios",
    myNotifications: "Mis notificaciones",
    myConfig: "Parametrización",
    logout: "Cerrar session",
    myServices: "Mis servicios",
    listServicesWantNotif: "Esta es la lista de servicios para los que deseo ser notificado",
    service: "servicio",
    save: "Guardar",
    manageNotifications: "Gestionar mis notificaciones",
    notifAboutWorker: "Notificaciones relacionadas con mi red de sustitutos",
    wantNotifWhenWorker: "Me gustaría recibir una notificación cuando un sustituto…",
    isInvited: "... está invitado",
    subscribes: "... forma parte de",
    askedNewSpecialties: "... requiere nuevas competencias",
    hasSpecialtiesModified: "... tiene sus competencias modificadas",
    isDeletedFromNetwork: "... se retira de la red",
    notifAboutMissions: "Notificaciones relacionadas con mis coberturas",
    wantNotifWhenAdmin: "Me gustaría recibir una notificación cuando un administrador...",
    createsNewMission: "... publicar una nueva cobertura",
    createsMissionRequest: "... publicar una necesidad de cobertura",
    assignStandardMission: "... asigna una cobertura Estándar a un candidato",
    cancelMission: "... cancela una cobertura",
    declareFutureMission: "... declara una futura cobertura a un candidato",
    declarePastMission: "... declara una cobertura dada a un candidato",
    wantNotifWhenMission: "Me gustaría recibir una notificación cuando una cobertura...",
    isPending: "... debe ser tratada",
    wasModified: "... ha sido modificado",
    applyStandardMission: "... solicitar una cobertura Estándar",
    applyExpressMission: "... solicitar una cobertura Express",
    hasMissionOnDifferentService: "... tiene una cobertura en un servicio diferente de su servicio de origen",
    wantNotifWhen: "Me gustaría ser notificado cuando...",
    allCandidatesWarned: "... todos los candidatos a una cobertura han sido notificados",
    adminWantsInterim: "... cuando un directivo quiere solicitar una cobertura temporal",
    agencySetInterim: "... cuando una agencia ofrece un trabajador temporal para una cobertura",
    never: "Nunca",
    allTheTime: "Todo el tiempo",
    onlyConcernMyServices: "Sólo si se trata de mis servicios",
    onlyIInvited: "Sólo si soy yo quien lo ha invitado",
    onlyMyMissionCanceledOtherAdmin: "Sólo si he publicado la cobertura y es cancelada por otro administrador",
    onlyMyMission: "Sólo si soy yo quien ha creado la cobertura",
    proMissions: "mis coberturas",
    proVacataires: "mi red",
    proContracts: "mi contractos",
    dashboard: "cuadro de mando",
    myProfile: "Mi cuenta",
    firstName: "Nombre",
    lastName: "Apellidos",
    mobilePhone: "Número de teléfono",
    phoneNumber: "Número de teléfono móvil",
    email: "Correo electrónico",
    password: "Contraseña",
    toChangeMailContactUs:
        "Para cambiar su dirección de correo electrónico, póngase en contacto con nosotros en contact@hublo.com",
    language: "Idioma",
    lang: "idioma",
    changeMyPassword: "Cambiar mi contraseña",
    actualPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    confirmationPassword: "Confirmación de la nueva contraseña",
    youHaveQuestion: "¿Tiene alguna pregunta?",
    creditConsume: "Créditos utilizados",
    creditPackMessage:
        "Se ha suscrito a paquetes que le dan derecho a __NB_CREDITS_PACK__ créditos que pueden ser transferidos de un mes a otro.",
    usefullInformations: "Información práctica",
    institutionName: "Nombre de la institución",
    institutionAddress: "Dirección del establecimiento",
    visibleMobileForWorkers: "Teléfono visible para los suplentes",
    timeCodePlural: "Códigos horarios",
    youWantAddTimeCode: "¿Quiere añadir un código horario?",
    addTimeCodeToCreateMission: "Añadir un código de tiempo para facilitar la creación de una cobertura",
    contactUsAtAddress: " Póngase en contacto con nosotros en ",
    orAskToBeContacted: " o pida que se le vuelva a contactar.",
    WhichTimeCodeYouWantAdd:
        "¿Qué códigos horarios desea añadir? Por favor, especifique para cada código el nombre del código, la hora de inicio, la hora de finalización, la duración realmente trabajada sin tiempo de pausa",
    cancel: "Cancelar",
    askToBeRecontacted: "Solicitar que se le contacte de nuevo",
    serviceCamelCase: "Servicios",
    jobCamelCase: "Oficios",
    specialtyPlural: "Especialidades",
    toModifyServiceListOrTimeCode:
        "Para modificar la lista de servicios, oficios, especialidades o códigos horarios, póngase en contacto con nosotros en",
    institutionPresentation: "Presentación del establecimiento",
    accessInformation: "Información de acceso",
    servicePlural: "Servicios",
    jobPlural: "Oficios",
    speciaties: "Especialidades",
    subtitleServices: "Servicios",
    subtitleJobs: "Oficios",
    idSubscription: "idSubscription",
    "idSubscription-form": "idSubscription",
    agencyClientId: "Agency ClientId",
    "agencyClientId-form": "Agency ClientId",
    subscriptionName: "Etiqueta",
    "subscriptionName-form": "Etiqueta",
    timeLimitationToPostShiftInDays: "Límite de creación de misiones (en días)",
    timezone: "Zona horaria",
    slotConfig: "Configuración de franjas horarias",
    dealShift: "Negociar una misión",
    internalPool: "Internal pool",
    internalPoolCalendarBlockDays: "Internal pool pediodo de bloqueo del calendario (días)"
};
