import { connect } from "react-redux";
import { updateInstitutionOptionAction } from "../../store/action/institutionOption.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IInstitutionOption } from "../../../Model/InstitutionOption";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { selectESignatureProviderOptions, selectDealShiftOptions } from "../../constants/SelectOptions.constants";
import { ISlotConfig } from "../../../Model/Slot";

interface ISlotConfigProps {
    slotConfigs: ISlotConfig[];
}

interface IPropsFormInstitutionOption
    extends IReduxPropsForm<IInstitutionOption>,
        IReduxActionsForm,
        ISlotConfigProps,
        IPropsForm<IInstitutionOption> {}

interface IReduxActionsForm {
    updateInstitutionOptionAction: (
        id: number,
        institutionOption: IInstitutionOption,
        hasHubloPoolOldValue?: boolean
    ) => void;
}

class FormInstitutionOptionComponent extends FormComponent<IInstitutionOption, IPropsFormInstitutionOption> {
    async updateElementAction(): Promise<void> {
        const institutionOption = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOption
        )[0];
        institutionOption.eSignatureProvider = institutionOption.eSignatureProvider.value;
        institutionOption.slotConfig = institutionOption.slotConfig.value;
        institutionOption.dealShift = institutionOption.dealShift.value;

        const hasHubloPoolOldValue = this.props.institutionOptionDict[institutionOption.institution].hasHubloPool;
        await this.props.updateInstitutionOptionAction(
            institutionOption.institution,
            institutionOption as IInstitutionOption,
            hasHubloPoolOldValue
        );
    }
}

const mapStateToProps = (centralState: any): IPropsFormInstitutionOption => {
    return {
        ...getConnectParentState(centralState),
        selectOptions: {
            eSignatureProvider: selectESignatureProviderOptions.eSignatureProvider,
            slotConfig: (centralState.institutionOption.slotConfigs || []).map(
                ({ id, labelKey }: { id: string; labelKey: string }) => ({
                    value: id,
                    label: labelKey
                })
            ),
            dealShift: selectDealShiftOptions.dealShift
        },
        institutionOptionDict: centralState.institutionOption.byId
    };
};

export default connect(mapStateToProps, {
    ...getConnectParentActions(),
    updateInstitutionOptionAction
})(FormInstitutionOptionComponent);
