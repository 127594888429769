import React from "react";
import { connect } from "react-redux";
import { setFormStatusAction } from "../../store/action/form.action";
import "../../scss/components/table/HubloBackOfficeSimpleTable.scss";
import DataTableView from "./DataTable.view";
import { EBackOfficeTableName, IBackOfficeTable } from "../../constants/Table.constants";
import DeleteConfirmationModal from "../../components/Modals/Shared/DeleteConfirmation.modal";
import ConfirmationModal, { IConfirmationModalData } from "../../components/Modals/Shared/Confirmation.modal";
import { ILang } from "../../lang/Lang";
import ConfirmationImportCSVModal from "../../components/Modals/Shared/ConfirmationImportCSV.modal";

interface IReduxPropsHubloBackOfficeSimpleTable {
    isDeleteConfirmation: boolean;
    selectedTab: number;
    modifyingElement: any;
    lang: ILang;
    confirmationModalData: IConfirmationModalData;
    formTable: EBackOfficeTableName;
}

interface IReduxActionHubloBackOfficeSimpleTable {
    setFormStatusAction: (status: any) => void;
}

interface IPropsHubloBackOfficeSimpleTable
    extends IReduxPropsHubloBackOfficeSimpleTable,
        IReduxActionHubloBackOfficeSimpleTable {
    backOfficeTable: IBackOfficeTable;
    elements: any[];
    onModifyLine: (id: number, index: number) => void;
    onSearch: (value: string | null) => void;
    selectCheckboxListOptions?: () => any;
    isLoading: boolean;
    onDeleteLineClick: () => void;
}

class HubloBackOfficeSimpleTable extends React.Component<IPropsHubloBackOfficeSimpleTable> {
    render(): JSX.Element {
        let selectCheckboxListOptions: any;
        if (this.props.selectCheckboxListOptions) selectCheckboxListOptions = this.props.selectCheckboxListOptions();
        const BackOfficeTableFormComponent = this.props.backOfficeTable.formComponent
            ? this.props.backOfficeTable.formComponent[this.props.selectedTab]
            : null;
        const AdditionalInformation = this.props.backOfficeTable.additionalInformation;
        return (
            <div className="card">
                <ConfirmationImportCSVModal
                    lang={this.props.lang}
                    subtitle={this.props.confirmationModalData.subtitle}
                    text={this.props.confirmationModalData.text}
                    onClick1={this.props.confirmationModalData.onClick1}
                    onClick2={this.props.confirmationModalData.onClick2}
                    textButton1={this.props.confirmationModalData.textButton1}
                    textButton2={this.props.confirmationModalData.textButton2}
                />
                {!this.props.isDeleteConfirmation && BackOfficeTableFormComponent ? (
                    <>
                        <BackOfficeTableFormComponent
                            key={`table_${this.props.backOfficeTable.name[this.props.selectedTab]}`}
                            backOfficeTable={this.props.backOfficeTable}
                            selectCheckboxListOptions={selectCheckboxListOptions}
                            setFormStatusAction={this.props.setFormStatusAction}
                        />
                        {this.props.formTable === this.props.backOfficeTable.name[0] && (
                            <ConfirmationModal
                                lang={this.props.lang}
                                subtitle={this.props.confirmationModalData.subtitle}
                                text={this.props.confirmationModalData.text}
                                onClick1={this.props.confirmationModalData.onClick1}
                                onClick2={this.props.confirmationModalData.onClick2}
                                textButton1={this.props.confirmationModalData.textButton1}
                                textButton2={this.props.confirmationModalData.textButton2}
                            />
                        )}
                    </>
                ) : (
                    <DeleteConfirmationModal
                        backOfficeTable={this.props.backOfficeTable}
                        onDeleteLineClick={this.props.onDeleteLineClick}
                    />
                )}
                {AdditionalInformation ? <AdditionalInformation /> : null}
                <DataTableView
                    backOfficeTable={this.props.backOfficeTable}
                    elements={this.props.elements}
                    onModifyLine={this.props.onModifyLine}
                    selectCheckboxListOptions={this.props.selectCheckboxListOptions}
                    isLoading={this.props.isLoading}
                    onSearch={this.props.onSearch}
                />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsHubloBackOfficeSimpleTable => ({
        lang: centralState.language.lang,
        isDeleteConfirmation: centralState.form.isDeleteConfirmation,
        selectedTab: centralState.tables.selectedTab,
        modifyingElement: centralState.tables.modifyingElement,
        confirmationModalData: centralState.form.confirmationModalData,
        formTable: centralState.form.formTable
    }),
    {
        setFormStatusAction
    }
)(HubloBackOfficeSimpleTable);
