import { EMedgoColor, EMedgoIcon, HubloFontAwesome } from "npm-medgo-components";
import { firstLetterUppercase } from "npm-medgo-toolbox/dist/modules/string";
import React, { Component } from "react";
import { IBackOfficeColumn } from "../../constants/Column.constants";

interface IPropsColumnName {
    lang: any;
    column: IBackOfficeColumn;
    columnName?: string;
}

export default class ColumnName extends Component<IPropsColumnName> {
    render(): JSX.Element {
        const tooltip = (
            <HubloFontAwesome
                icon={EMedgoIcon.questionCircle}
                color={EMedgoColor.comet}
                tooltipText={this.props.column.helper ? this.props.lang[this.props.column.helper] : ""}
            />
        );
        return (
            <>
                {firstLetterUppercase(
                    this.props.columnName ? this.props.columnName : this.props.lang[this.props.column.name]
                )}{" "}
                {this.props.column.link ? (
                    <>
                        <a target="_blank" rel="noopener noreferrer" href={this.props.column.link}>
                            {tooltip}
                        </a>
                    </>
                ) : this.props.column.helper ? (
                    <> {tooltip}</>
                ) : null}
            </>
        );
    }
}
