/* eslint-disable react/prop-types */
import React from "react";
import { ILang } from "../../../lang/Lang";
import { LoadingIndicator } from "../elements/LoadingIndicator";
import { useInstitutionHasOAuth2Loading } from "../hooks/useInstitutionHasOAuth2Loading.hook";
import { useOAuth2Form } from "../hooks/useOAuth2Form.hook";
import { Slider } from "../elements/Slider";
import { usePKCEState } from "../hooks/usePKCEState.hook";

interface SsoOAuth2FormProps {
    idInstitution: number;
    lang: ILang;
    isDisabled: boolean;
}

export const SsoOAuth2Form: React.FC<SsoOAuth2FormProps> = ({ idInstitution, lang, isDisabled }): JSX.Element => {
    const [persistedData, isLoading] = useInstitutionHasOAuth2Loading(idInstitution);
    const { formData, handleChange, handleSubmit } = useOAuth2Form(idInstitution, persistedData);
    const { isEnabled, handleUsePKCEState } = usePKCEState(persistedData);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const isButtonDisabled =
        formData.clientId?.length === 0 || formData.authUrl?.length === 0 || formData.accessTokenUrl?.length === 0;

    return (
        <form className="w-100" onSubmit={handleSubmit}>
            <fieldset disabled={isDisabled}>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="clientId">{lang.clientId}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="clientId"
                                placeholder="Xyyyyyyyy"
                                onChange={handleChange}
                                value={formData.clientId}
                            />
                            <div className="invalid-feedback">{lang.auth2FormClientIdRequired}</div>{" "}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientSecret">{lang.clientSecret}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="clientSecret"
                                placeholder="XXXXXXXXXXX"
                                onChange={handleChange}
                                value={formData.clientSecret}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="authUrl">{lang.authUrl}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="authUrl"
                                placeholder="https://exemple.com"
                                onChange={handleChange}
                                value={formData.authUrl}
                            />
                            <div className="invalid-feedback">{lang.auth2FormAuthUrlRequired}</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="accessTokenUrl">{lang.accessTokenUrl}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="accessTokenUrl"
                                placeholder="https://exemple.com/auth_oauth"
                                onChange={handleChange}
                                value={formData.accessTokenUrl}
                            />
                            <div className="invalid-feedback">{lang.auth2FormAccessTokenUrlRequired}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="userInfoUrl">{lang.userInfoUrl}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="userInfoUrl"
                                placeholder="https://exemple.com/userInfo"
                                onChange={handleChange}
                                value={formData.userInfoUrl}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="scope">{lang.scope}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="scope"
                                placeholder="oauth2"
                                onChange={handleChange}
                                value={formData.scope}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="resource">{lang.resource}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="resource"
                                placeholder="email"
                                onChange={handleChange}
                                value={formData.resource}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="responseType">{lang.responseType}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="responseType"
                                onChange={handleChange}
                                placeholder="code"
                                value={formData.responseType}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Slider
                                onValueChanged={handleUsePKCEState}
                                id="pkce-slider"
                                text={lang.usePKCE}
                                isEnabled={isEnabled}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button className={isButtonDisabled ? "disabled-button" : "button"} disabled={isButtonDisabled}>
                        {lang.save}
                    </button>
                </div>
            </fieldset>
        </form>
    );
};
