import { connect } from "react-redux";
import {
    updateInstitutionOptionContractAction,
    insertInstitutionOptionContractAction
} from "../../store/action/institutionOptionContract.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IInstitutionOptionContract } from "../../../Model/InstitutionOptionContract";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";

interface IPropsForminstitutionOptionContract
    extends IReduxPropsForm<IInstitutionOptionContract>,
        IReduxActionsForm,
        IPropsForm<IInstitutionOptionContract> {}

interface IReduxActionsForm {
    updateInstitutionOptionContractAction: (id: number, institutionOptionContract: IInstitutionOptionContract) => void;
    insertInstitutionOptionContractAction: (institutionOptionContract: IInstitutionOptionContract) => void;
}

class ForminstitutionOptionContractComponent extends FormComponent<
    IInstitutionOptionContract,
    IPropsForminstitutionOptionContract
> {
    async createElementAction(): Promise<void> {
        const formDictionaryInstitutionOptionContracts = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionContract
        );
        const institution = this.getSelectedIdInstitutions()[0];
        const formDictionaryInstitutionOptionContract = Object.assign(formDictionaryInstitutionOptionContracts[0], {
            institution
        });
        try {
            await this.props.insertInstitutionOptionContractAction(formDictionaryInstitutionOptionContract);
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.notAllInsertHaveBeenWellDone,
                icon: "fa fa-times",
                color: "danger"
            });
        }
    }

    async updateElementAction(): Promise<void> {
        const institutionOptionContract = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionContract
        )[0];
        await this.props.updateInstitutionOptionContractAction(
            institutionOptionContract.institution,
            institutionOptionContract as IInstitutionOptionContract
        );
    }
}

export default connect(
    (centralState: any): IPropsForminstitutionOptionContract => Object.assign(getConnectParentState(centralState), {}),
    Object.assign(getConnectParentActions(), {
        updateInstitutionOptionContractAction,
        insertInstitutionOptionContractAction
    })
)(ForminstitutionOptionContractComponent);
