import { IInstitutionOctime } from "../../Model/InstitutionOctime";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionOctimeDefaultSelect = [
    "id",
    "institution",
    "isProxyRequired",
    "url",
    "codeAPI",
    "user",
    "password",
    "loginKey",
    "isVersionGreaterOrEqualTo92",
    "token",
    "clientName",
    "societe",
    "webrest",
    "codeSociete",
    "codeEtablissement",
    "codeService",
    "codeInterface"
];

export async function fetchInstitutionOctimes(query: IQueryParams): Promise<IInstitutionOctime[]> {
    const institutionOctimes = await postFetchFactory<IInstitutionOctime>(
        PossibleFetchs.institutionOctime,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionOctimeDefaultSelect
        },
        query
    );
    return institutionOctimes;
}

export async function insertInstitutionOctime(institutionOctime: IInstitutionOctime): Promise<IInstitutionOctime> {
    return await postFactory<IInstitutionOctime>(
        PossiblePosts.institutionOctime,
        PossibleApis.medgoApi
    )(institutionOctime);
}

export async function updateInstitutionOctime(
    id: number,
    institutionOctime: IInstitutionOctime
): Promise<IInstitutionOctime> {
    return await updateFactory<IInstitutionOctime>(PossibleUpdates.institutionOctime, PossibleApis.medgoApi)(
        id,
        institutionOctime
    );
}

export async function deleteInstitutionOctime(id: number): Promise<IInstitutionOctime> {
    return await deleteFactory<IInstitutionOctime>(PossibleDeletes.institutionOctime, PossibleApis.medgoApi)(id);
}
