import { IInstitutionOption } from "../../Model/InstitutionOption";
import { IInstitutionSlotConfig, ISlotConfig } from "../../Model/Slot";

import {
    PossibleApis,
    PossibleDeletes,
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import AxiosInstance from "../../modules/axios/axios.instance";
import { AxiosError } from "axios";

interface ServiceApiError {
    errors: {
        code: 400;
        message: string;
    }[];
    status: 400;
    messages: {
        SuccessStatus: string;
    }[];
    data: unknown;
}
const InstitutionOptionDefaultSelect = [
    "institution",
    "isAddressRequired",
    "isSocialSecurityNumberRequired",
    "hasDPAE",
    "isKorian",
    "isHCL",
    "hasContract",
    "isMedgoDemo",
    "hasInterim",
    "hasUnspecifiedService",
    "hasSMS",
    "canPostMissionWithoutSchedule",
    "canSeeTotalCostForInstitution",
    "canValidateMission",
    "eSignature",
    "eSignatureProvider",
    "HasMassEsignatureEtab",
    "HasMultiReplacementContract",
    "healthPass",
    "isCvMandatory",
    "isDiplomaMandatory",
    "hasHubloPool",
    "agencyClientId",
    "timeLimitationToPostShiftInDays",
    "slotConfig",
    "dealShift",
    "internalPool",
    "internalPoolCalendarBlockDays"
];

export async function fetchInstitutionOptions(query: IQueryParams): Promise<IInstitutionOption[]> {
    return postFetchFactory<IInstitutionOption>(PossibleFetchs.institutionOption, PossibleApis.medgoApi)(
        {
            select: InstitutionOptionDefaultSelect
        },
        query
    );
}

export async function fetchInstitutionOptionsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionOption>(PossibleFetchs.institutionOption, PossibleApis.medgoApi)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionOption(institutionOption: IInstitutionOption): Promise<IInstitutionOption> {
    return await postFactory<IInstitutionOption>(
        PossiblePosts.institutionOption,
        PossibleApis.medgoApi
    )(institutionOption);
}

export async function updateInstitutionOption(
    institution: number,
    institutionOption: Partial<IInstitutionOption>
): Promise<Partial<IInstitutionOption>> {
    return await updateFactory<Partial<IInstitutionOption>>(PossibleUpdates.institutionOption, PossibleApis.medgoApi)(
        institution,
        institutionOption
    ).catch((err: AxiosError<ServiceApiError>) => {
        // ServiceApi random fail on this endpoint.
        if (err.response?.status === 400 && err.response.data.errors[0].message === "BSONError") {
            return updateFactory<Partial<IInstitutionOption>>(PossibleUpdates.institutionOption, PossibleApis.medgoApi)(
                institution,
                institutionOption
            );
        }
        throw err;
    });
}

export async function deleteInstitutionOption(id: number): Promise<IInstitutionOption> {
    return await deleteFactory<IInstitutionOption>(PossibleDeletes.institutionOption, PossibleApis.medgoApi)(id);
}

export async function fetchSlotConfigs(): Promise<ISlotConfig[]> {
    try {
        const res = await AxiosInstance.get(`${PossibleApis.institution}/${PossibleFetchs.slotConfig}`);
        return res.data && res.data.data ? res.data.data : res.data === "" ? [] : res.data;
    } catch (error) {
        return [];
    }
}

export async function fetchInstitutionSlotConfig(id: number): Promise<IInstitutionSlotConfig | null> {
    try {
        const res = await AxiosInstance.get(`${PossibleApis.institution}/${PossibleFetchs.slotConfig}/${id}`);
        return res.data && res.data.data ? res.data.data : res.data === "" ? [] : res.data;
    } catch (error) {
        return null;
    }
}

export async function updateInstitutionSlotConfig(id: number, idSlotConfig: string): Promise<void> {
    await AxiosInstance.post(`${PossibleApis.institution}/${PossibleUpdates.slotConfig}/${id}`, {
        idSlotConfig
    }).catch(() => {});
}
