import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// @ts-ignore
import loadable from "@loadable/component";
import LoadingFallback from "./Loading";
import { NavbarComponent, FooterComponent, ILabelHref, EAvailableLang } from "@hublo/legacy-components";
import { connect } from "react-redux";
import { fetchMeAction, setTokenAction } from "./store/action/auth.action";
import { setLangAction } from "./store/action/lang.action";
import { setIsAppReady } from "./store/action/app.action";
import { IAdmin } from "../Model/Admin";
import { implementHookIsTokenOk, IImplementHookIsTokenOk } from "./hooks/authentification.hook";
import { implementHookStoreLangFromLocalStorage, IImplementHookStoreLangFromLocalStorage } from "./hooks/lang.hook";
import { implementHookGetDropdownItems, implementHookGetMenuItems, IImplementHookGetMenus } from "./hooks/menus.hook";
import InstitutionContainer from "./container/page/Institution.container";
import AdminProfileContainer from "./container/page/AdminProfile.container";
import NotificationsContainer from "./container/page/Notifications.container";
import { INormalizedInstitutionOptions } from "../Model/InstitutionOption";
import { fetchAllSlotConfigsAction, fetchInstitutionOptionsAction } from "./store/action/institutionOption.action";
import { fetchInstitutionsAction } from "./store/action/institution.action";
import { IQueryParams } from "npm-medgo-query";
import { decodeToken } from "../toolbox/token";
import { adminLogout } from "./modules/logout";
import { handleSSOAdminIfNeeded } from "./modules/authorization/sso";
import { fetchAllJobsAction } from "./store/action/job.action";
import { fetchAllSpecialtiesAction } from "./store/action/specialty.action";
import { INormalizedJobs } from "../Model/Job";
import { fetchLevelsAction } from "./store/action/level.action";
import { INormalizedLevels } from "../Model/Level";
import { fetchInstitutionGroupsAction } from "./store/action/institutionGroup.action";
import { Url } from "npm-medgo-toolbox";
import { setupAxeptio } from "npm-hublo-analytics";
import setupZendeskWidget from "./modules/zendesk";

const BackOfficeBase = loadable((): Promise<{}> => import("./container/category/BackOffice.base"), {
    fallback: LoadingFallback
});

interface IReduxPropsApp {
    me: IAdmin;
    lang: any;
    currentLang: EAvailableLang;
    isLoadingApp: boolean;
    idInstitutions: number[];
    institutionsOptions: INormalizedInstitutionOptions;
    jobDict: INormalizedJobs;
    level: INormalizedLevels;
}
interface IReduxActionsApp {
    fetchMeAction: () => any;
    setLangAction: (lang: string) => any;
    setIsAppReady: (bool: boolean) => any;
    setTokenAction: (token: string, ssoAccessToken: string) => any;
    fetchInstitutionOptionsAction: (query: IQueryParams) => any;
    fetchInstitutionsAction: (query: IQueryParams) => any;
    fetchAllJobsAction: (param: IQueryParams) => any;
    fetchAllSpecialtiesAction: (param: IQueryParams, jobs: INormalizedJobs) => any;
    fetchLevelsAction: (query: IQueryParams) => any;
    fetchInstitutionGroupsAction: (param: IQueryParams) => any;
    fetchAllSlotConfigsAction: () => any;
}
export interface IPropsApp extends IReduxPropsApp, IReduxActionsApp {
    isFooter: boolean;
}
class App extends React.Component<IPropsApp>
    implements IImplementHookGetMenus, IImplementHookIsTokenOk, IImplementHookStoreLangFromLocalStorage {
    constructor(props: IPropsApp) {
        super(props);
        Url.checkAndSaveTokensFromUrl();
        Url.removeTokensFromUrl(window.location.href);
        implementHookIsTokenOk(this);
        implementHookStoreLangFromLocalStorage(this);
        this.logout = this.logout.bind(this);
        this.isTokenOk();
        this.storeLangFromLocalStorage();
        this.props
            .fetchMeAction()
            .then(
                async (): Promise<void> => {
                    try {
                        this.props.setLangAction(this.props.me.lang);
                        this.props.fetchLevelsAction({
                            query: { id: this.props.me && this.props.me.level ? this.props.me.level : -1 },
                            limit: 10,
                            orderby: [{ columnName: "id", orientation: "ASC" }]
                        });
                    } catch (err) {
                        console.error(err);
                        window.location.href = process.env.REACT_APP_MEDGO_URL + "/login";
                    }
                }
            )
            .then(
                async (): Promise<void> => {
                    try {
                        this.props.fetchInstitutionsAction({ limit: 300 });
                        await this.props.fetchAllJobsAction({ limit: 99999 });
                        await this.props.fetchAllSpecialtiesAction({ limit: 99999 }, this.props.jobDict);
                        await this.props.fetchAllSlotConfigsAction();
                    } catch (err) {
                        console.error(err);
                        window.location.href = process.env.REACT_APP_MEDGO_URL + "/login";
                    }
                }
            )
            .then(
                async (): Promise<void> => {
                    try {
                        this.props.fetchInstitutionOptionsAction({
                            query: { institution: this.props.idInstitutions },
                            limit: 300,
                            orderby: [{ columnName: "institution", orientation: "ASC" }]
                        });
                        this.props.fetchInstitutionGroupsAction({
                            query: {
                                institution: this.props.idInstitutions
                            },
                            limit: 300,
                            orderby: [{ columnName: "id", orientation: "ASC" }]
                        });
                    } catch (err) {
                        console.error(err);
                        window.location.href = process.env.REACT_APP_MEDGO_URL + "/login";
                    }
                }
            )
            .then((): void => {
                this.props.setIsAppReady(false);
            })
            .catch((err: any): void => {
                console.error(err);
                window.location.href = process.env.REACT_APP_MEDGO_URL + "/login";
            });
        implementHookGetDropdownItems(this);
        implementHookGetMenuItems(this);
    }
    async logout(): Promise<void> {
        const token = localStorage.getItem("token");
        if (token && decodeToken(token)) {
            return adminLogout();
        }
        return adminLogout(false, true);
    }

    async componentDidMount(): Promise<void> {
        // handle SSO admins
        await handleSSOAdminIfNeeded();

        // setup axeptio
        setupAxeptio();

        // setup zendesk
        if (this.props.currentLang === EAvailableLang.fr) {
            setupZendeskWidget();
        }
    }

    componentDidUpdate(prevProps: IPropsApp): void {
        // re-setup axeptio and zendesk on language change
        if (prevProps.currentLang !== this.props.currentLang) {
            // setup axeptio
            setupAxeptio();

            // setup zendesk
            if (this.props.currentLang === EAvailableLang.fr) {
                setupZendeskWidget();
            }
        }
    }

    render(): JSX.Element {
        const displayName = this.props.me
            ? `${this.props.me.firstName ? this.props.me.firstName : ""} ${
                  this.props.me.lastName ? this.props.me.lastName : ""
              }`
            : "";
        const dropdownItems = this.getDropdownItems();
        const menuItems = this.getMenuItems();
        return (
            <div className="App">
                <Router>
                    <div>
                        {this.props.me && this.props.me.level && this.props.me.level > 0 ? (
                            <NavbarComponent
                                logout={this.logout}
                                displayName={displayName.toUpperCase()}
                                dropdownItems={dropdownItems}
                                menuItems={menuItems}
                            />
                        ) : null}
                        {!this.props.isLoadingApp ? (
                            <Switch>
                                <Route path="/me/institutions" component={InstitutionContainer} />
                                <Route path="/me/profile" component={AdminProfileContainer} />
                                <Route path="/me/notifications" component={NotificationsContainer} />
                                <Route
                                    path="/"
                                    component={(props: any): JSX.Element => <BackOfficeBase {...props} />}
                                />
                            </Switch>
                        ) : null}
                        {!this.props.isLoadingApp && this.props.isFooter ? (
                            <div>
                                <div className="has-padding-top is-large" />
                                <FooterComponent
                                    emailText={"contact@medgo.fr"}
                                    urlBase={process.env.REACT_APP_MEDGO_URL}
                                    currentLang={this.props.currentLang}
                                    lang={this.props.lang}
                                    handleChangeLang={(): void => {}}
                                />
                            </div>
                        ) : null}
                    </div>
                </Router>
            </div>
        );
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    getDropdownItems(): ILabelHref[] {
        throw new Error("getDropdownItems will be implemented by a hook.");
    }
    getMenuItems(): ILabelHref[] {
        throw new Error("getMenuItems will be implemented by a hook.");
    }
    isTokenOk(): void {
        throw new Error("isTokenOk will be implemented by a hook.");
    }
    storeLangFromLocalStorage(): void {
        throw new Error("storeLangFromLocalStorage will be implemented by a hook.");
    }
}

export default connect(
    (centralState: any): IReduxPropsApp => ({
        me: centralState.auth.user,
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        isLoadingApp: centralState.app.isLoadingApp,
        idInstitutions: centralState.institutions.ids,
        institutionsOptions: centralState.institutionOption.byId,
        jobDict: centralState.jobs.allById,
        level: centralState.level.byId
    }),
    {
        fetchMeAction,
        setLangAction,
        setIsAppReady,
        setTokenAction,
        fetchInstitutionsAction,
        fetchInstitutionOptionsAction,
        fetchAllJobsAction,
        fetchAllSpecialtiesAction,
        fetchLevelsAction,
        fetchInstitutionGroupsAction,
        fetchAllSlotConfigsAction
    }
)(App);
