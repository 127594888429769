import { IReduxAction } from "../store.type";
import InstitutionOptionContractTypes from "../type/institutionOptionContract.type";
import {
    fetchInstitutionOptionContracts,
    fetchInstitutionOptionContractsCount,
    insertInstitutionOptionContract,
    updateInstitutionOptionContract,
    deleteInstitutionOptionContract
} from "../../service/institutionOptionContract.service";
import {
    IInstitutionOptionContract,
    INormalizedInstitutionOptionContracts
} from "../../../Model/InstitutionOptionContract";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionOptionContractsAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionOptionContracts = await fetchInstitutionOptionContracts(query);
            dispatch({
                type: InstitutionOptionContractTypes.FETCH_INSTITUTIONOPTIONCONTRACTS,
                payload: {
                    byId: institutionOptionContracts.reduce(
                        (
                            p: INormalizedInstitutionOptionContracts,
                            c: IInstitutionOptionContract
                        ): INormalizedInstitutionOptionContracts => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionOptionContracts.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionOptionContractsCountAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionOptionContractsCount(query);
            dispatch({
                type: InstitutionOptionContractTypes.FETCH_INSTITUTIONOPTIONCONTRACTS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOptionContractAction(
    institutionOptionContract: IInstitutionOptionContract
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOptionContract = await insertInstitutionOptionContract(institutionOptionContract);
            dispatch({
                type: InstitutionOptionContractTypes.INSERT_INSTITUTIONOPTIONCONTRACT,
                payload: newInstitutionOptionContract
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOptionContractAction(
    id: number,
    institutionOptionContract: IInstitutionOptionContract
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionOptionContract = await updateInstitutionOptionContract(
                id,
                institutionOptionContract
            );
            dispatch({
                type: InstitutionOptionContractTypes.UPDATE_INSTITUTIONOPTIONCONTRACT,
                payload: updatedInstitutionOptionContract
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOptionContractAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOptionContract(id);
            dispatch({
                type: InstitutionOptionContractTypes.DELETE_INSTITUTIONOPTIONCONTRACT
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOptionContractsAction(
    dict: INormalizedInstitutionOptionContracts
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_INSTITUTIONOPTIONCONTRACTS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOptionContractAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_SELECTED_INSTITUTIONOPTIONCONTRACT,
            payload: id
        });
    };
}

export function setSelectedInstitutionOptionContractMultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_SELECTED_INSTITUTIONOPTIONCONTRACT_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionOptionContractAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_IS_LOADING_INSTITUTIONOPTIONCONTRACT,
            payload: isLoading
        });
    };
}
export function setInstitutionOptionContractOrderAction(
    idsSorted: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_INSTITUTIONOPTIONCONTRACT_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOptionContractErrorCodeAction(
    errorCode: number
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionContractTypes.SET_INSTITUTIONOPTIONCONTRACT_ERROR_CODE,
            payload: errorCode
        });
    };
}
