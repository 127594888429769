import { AxiosError } from "axios";

export function getErrorFromResponse(axiosError: AxiosError): number {
    if (!axiosError || !axiosError.response || !axiosError.response.status) {
        console.log(axiosError);
        return 520;
    }
    return axiosError.response.status;
}

export function getServiceApiAxiosError(err: AxiosError) {
    if (err?.response?.data?.errors) {
        const [error] = err?.response?.data.errors;
        return error;
    }
    return undefined;
}
