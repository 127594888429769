import { PossibleFetchs, PossibleApis, PossibleUpdates } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { IMissionPeriod } from "../../Model/MissionPeriod";
import { IMission } from "../../Model/Mission";
import { updateFactory } from "../../toolbox/factory/update.factory";
import moment from "moment";

export async function fetchMissionIDsAttachedToMissionPeriods(query: IQueryParams): Promise<number[]> {
    const data = await postFetchFactory<IMissionPeriod>(PossibleFetchs.missionPeriod, PossibleApis.medgoApi)(
        {
            select: ["mission"]
        },
        query
    );
    return data.map((value: { mission: number }): number => value.mission);
}

export async function removeScheduleFromAttachedMissions(IDInstitution: number, IDSchedule: number): Promise<void> {
    const data = await postFetchFactory<IMission>(PossibleFetchs.mission, PossibleApis.medgoApi)(
        {
            select: ["id"]
        },
        {
            query: {
                institution: IDInstitution,
                schedule: IDSchedule,
                rangeBeginAt: `${moment().valueOf()},${moment()
                    .add(1, "y")
                    .valueOf()}`
            }
        }
    );
    data.map(
        async (value: IMission): Promise<void> => {
            await updateFactory<IMission>(PossibleUpdates.mission, PossibleApis.medgoApi)(value.id, {
                id: value.id,
                schedule: NaN,
                institution: IDInstitution
            });
        }
    );
}
