import AxiosInstance from "../../modules/axios/axios.instance";
import { IQueryParams } from "npm-medgo-query";
import { PossibleFetchs, PossibleApis } from "../../Constant/Api.constant";
import generateParams from "../url/customParamGenerator";

export function postFetchFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.post(`${api}/api/v${apiVersion}/${modalName}`, {
            paramString: generateParams(params)
        });
        const data = res.data && res.data.data ? res.data.data : res.data === "" ? [] : res.data;
        return data;
    };
}

export function postFetchNormalizedFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (queryParams: IQueryParams, defaultParams?: IQueryParams) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.post(`${api}/api/v${apiVersion}/${modalName}`, {
            paramString: generateParams(params)
        });
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data.reduce((p: any, c: any): any => {
            p[c.id ? c.id : -1] = c;
            return p;
        }, {});
    };
}
