import { connect } from "react-redux";
import { setAdminsAction, fetchAdminsAction, setIsLoadingAdminAction } from "../../store/action/admin.action";
import { fetchServicesAction } from "../../store/action/service.action";
import { IAdmin, INormalizedAdmins } from "../../../Model/Admin";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";
import { IService } from "../../../Model/Service";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { isNullOrUndefined } from "util";
import { ILang } from "../../lang/Lang";
import { fetchInstitutionOptionsAction } from "../../store/action/institutionOption.action";
import { fetchInstitutionHasAdminsAction } from "../../store/action/institutionHasAdmin.action";
import { IInstitutionHasAdmin, INormalizedInstitutionHasAdmins } from "../../../Model/InstitutionHasAdmin";

interface IReduxPropsTableAdmin extends IReduxPropsTable {
    idAdmins: number[];
    adminDict: INormalizedAdmins;
    idServices: number[];
    selectedMulti: number[];
    searchValue: string;
    lang: ILang;
    idInstitutionOptions: number[];
    idInstitutionHasAdmins: number[];
    institutionHasAdminById: INormalizedInstitutionHasAdmins;
}

interface IPropsTableAdmin extends IReduxPropsTableAdmin, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setAdminsAction: (elements: IAdmin[]) => any;
    fetchAdminsAction: (param: IQueryParams) => any;
    setAdminsPageAction: (pageNum: number) => any;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    fetchServicesAction: (param: IQueryParams) => any;
    setIsLoadingAdminAction: (isLoading: boolean) => any;
    fetchInstitutionOptionsAction: (param: IQueryParams) => any;
    fetchInstitutionHasAdminsAction: (param: IQueryParams) => any;
}

class TableAdminV2Component extends TableComponent<IPropsTableAdmin> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingAdminAction(true);
        if (this.props.idInstitutionHasAdmins.length === 0)
            await this.props.fetchInstitutionHasAdminsAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        if (this.props.idAdmins.length === 0) await this.props.fetchAdminsAction(params);
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        if (this.props.idInstitutionOptions.length === 0) await this.props.fetchInstitutionOptionsAction(params);
        this.props.setIsLoadingAdminAction(false);
    }

    setElements(elements: any): void {
        this.props.setAdminsAction(elements);
    }
    getElements(): any[] {
        const allResultsElements = getModelsFromDict<IAdmin>(this.props.adminDict, this.props.idAdmins);
        const institutionHasAdmins = getModelsFromDict(
            this.props.institutionHasAdminById,
            this.props.idInstitutionHasAdmins
        ).filter((institutionHasAdmin: IInstitutionHasAdmin): any =>
            this.props.idSelectedMultiInstitutions.includes(institutionHasAdmin.institution)
        );
        allResultsElements.forEach((element: any) => {
            element.service = element.service ? element.service.filter((service: any): any => service !== null) : [];
            element.services =
                element.service.length === this.props.idServices.length
                    ? `${this.props.lang.all}`
                    : element.service.length > 1
                    ? `${element.service.length} ${this.props.lang.services}`
                    : element.service.length === 1
                    ? `1 ${this.props.lang.service}`
                    : "";
            const institutionHasAdmin = institutionHasAdmins.find(
                (institutionHasAdmin: IInstitutionHasAdmin): any => institutionHasAdmin.admin === element.id
            );
            element.status = institutionHasAdmin?.isActivated;
            element.matricule = institutionHasAdmin?.matricule;
            element.position = institutionHasAdmin?.position;
            element.deactivatedAt = institutionHasAdmin?.deactivatedAt;
        });
        allResultsElements.forEach((element: any) => (element.user = `${element.firstName} ${element.lastName}`));
        const searchValue = this.props.searchValue;
        if (isNullOrUndefined(searchValue)) return allResultsElements;
        return allResultsElements.filter(
            (e): boolean =>
                isIncludedStringCaseInsensitive(e.firstName, searchValue) ||
                isIncludedStringCaseInsensitive(e.lastName, searchValue) ||
                isIncludedStringCaseInsensitive(e.email, searchValue) ||
                isIncludedStringCaseInsensitive(e.mobilePhone, searchValue) ||
                isIncludedStringCaseInsensitive(e.matricule, searchValue)
        );
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.admin]: {
                [EBackOfficeColumnName.service]: getElementsInInstitution(
                    getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                    this.props.selectedMulti
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsTableAdmin =>
        Object.assign(getConnectParentState(centralState), {
            servicesById: centralState.services.byId,
            selectedMulti: centralState.institutions.selectedMulti,
            idAdmins: centralState.admins.ids,
            adminDict: centralState.admins.byId,
            idServices: centralState.services.ids,
            isLoading: centralState.admins.isLoading,
            searchValue: centralState.tables.searchValue,
            lang: centralState.language.lang,
            idInstitutionOptions: centralState.institutionOption.ids,
            idInstitutionHasAdmins: centralState.institutionHasAdmin.ids,
            institutionHasAdminById: centralState.institutionHasAdmin.byId
        }),
    Object.assign(getConnectParentActions(), {
        setAdminsAction,
        fetchAdminsAction,
        fetchServicesAction,
        setIsLoadingAdminAction,
        fetchInstitutionOptionsAction,
        fetchInstitutionHasAdminsAction
    })
)(TableAdminV2Component);
