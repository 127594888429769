enum LevelActionTypes {
    FETCH_LEVELS = "FETCH_LEVELS",
    SET_LEVELS = "SET_LEVELS",
    FETCH_LEVELS_COUNT = "FETCH_LEVELS_COUNT",
    SET_SELECTED_LEVEL = "SET_SELECTED_LEVEL",
    SET_SELECTED_LEVEL_MULTI = "SET_SELECTED_LEVEL_MULTI",
    SET_IS_LOADING_LEVEL = "SET_IS_LOADING_LEVEL",
    SET_LEVEL_ORDER = "SET_LEVEL_ORDER",
    INSERT_LEVEL = "INSERT_LEVEL",
    UPDATE_LEVEL = "UPDATE_LEVEL",
    DELETE_LEVEL = "DELETE_LEVEL",
    FETCH_ALL_LEVELS = "FETCH_ALL_LEVELS"
}
export default LevelActionTypes;
