import { IReduxAction } from "../store.types";
import FormActionTypes from "../type/form.type";
import { hasErrorMessage } from "../../modules/store/util";
import { IConfirmationModalData } from "../../components/Modals/Shared/Confirmation.modal";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EButtonMedgoActionClassName } from "@hublo/legacy-components";

export interface FormReducer {
    formDictionary: object;
    initialFormDictionary: object;
    status: string;
    errorMessage: { [key: string]: { [key: string]: string } };
    showCreateOrModifyModal: boolean;
    showConfirmationModal: boolean;
    showConfirmationImportCSVModal: boolean;
    confirmationModalData: IConfirmationModalData;
    numberForms: number;
    isDeleteConfirmation: boolean;
    messageContent: string;
    scheduleRequestContent: string;
    futureSelectedInstitution: any;
    formTable: EBackOfficeTableName;
    idDetailedElement: number;
    isLoadingForm: boolean;
    isCreationOrUpdateLoading: boolean;
}

const initialState: FormReducer = {
    formDictionary: {},
    initialFormDictionary: {},
    status: "",
    errorMessage: {},
    showCreateOrModifyModal: false,
    showConfirmationModal: false,
    showConfirmationImportCSVModal: false,
    confirmationModalData: {
        text: "",
        subtitle: "",
        onClick1: (): void => {},
        onClick2: (): void => {},
        textButton1: "",
        textButton2: "",
        colorButton2: EButtonMedgoActionClassName.medgoButtonPrimary
    },
    numberForms: 1,
    isDeleteConfirmation: false,
    messageContent: "",
    scheduleRequestContent: "",
    futureSelectedInstitution: {},
    formTable: EBackOfficeTableName.institution,
    idDetailedElement: -1,
    isLoadingForm: false,
    isCreationOrUpdateLoading: false
};

export default function tableReducer(state: any = initialState, action: IReduxAction): FormReducer {
    let formDictionaryTable: any = {};
    switch (action.type) {
        case FormActionTypes.SET_KEY_VALUE_FORM_DICTIONARY:
            const payload = action.payload;
            const table = payload.table;
            const key = payload.key;
            const value = payload.value;
            const index = payload.index;
            let formDictionary = Object.assign({}, state.formDictionary);
            formDictionary[table]
                ? formDictionary[table][index]
                    ? (formDictionary[table][index][key] = value)
                    : Object.assign(formDictionary[table], { [index]: { [key]: value } })
                : Object.assign(formDictionary, {
                      [table]: { [index]: { [key]: value } }
                  });
            return {
                ...state,
                formDictionary
            };

        case FormActionTypes.SET_FORM_DICTIONARY:
            return {
                ...state,
                formDictionary: action.payload
            };

        case FormActionTypes.SET_FORM_STATUS:
            return {
                ...state,
                status: action.payload
            };

        case FormActionTypes.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };

        case FormActionTypes.SET_MESSAGE_CONTENT:
            return {
                ...state,
                messageContent: action.payload
            };

        case FormActionTypes.SET_SCHEDULE_REQUEST_CONTENT:
            return {
                ...state,
                scheduleRequestContent: action.payload
            };

        case FormActionTypes.SET_FUTURE_SELECTED_INSTITUTION:
            return {
                ...state,
                futureSelectedInstitution: action.payload
            };

        case FormActionTypes.SET_RESET_FORM:
            return {
                ...state,
                formDictionary: {},
                status: "",
                errorMessage: {}
            };

        case FormActionTypes.SET_ERROR_MESSAGE:
            const errorMessage = Object.assign({}, state.errorMessage);
            errorMessage[action.payload.table]
                ? errorMessage[action.payload.table][action.payload.index]
                    ? (errorMessage[action.payload.table][action.payload.index][action.payload.column] =
                          action.payload.message)
                    : (errorMessage[action.payload.table][action.payload.index] = {
                          [action.payload.column]: action.payload.message
                      })
                : (errorMessage[action.payload.table] = {
                      [action.payload.index]: { [action.payload.column]: action.payload.message }
                  });
            return {
                ...state,
                errorMessage
            };

        case FormActionTypes.RESET_ERROR_MESSAGE:
            const errorMessage2 = Object.assign({}, state.errorMessage);
            if (hasErrorMessage(errorMessage2, action.payload.table, action.payload.index, action.payload.column)) {
                errorMessage2[action.payload.table][action.payload.index][action.payload.column] = "";
            }
            return {
                ...state,
                errorMessage: errorMessage2
            };

        case FormActionTypes.SET_CREATE_OR_MODIFY_SHOW:
            return {
                ...state,
                showCreateOrModifyModal: action.payload
            };

        case FormActionTypes.SET_CONFIRMATION_CSV_SHOW:
            return {
                ...state,
                showConfirmationImportCSVModal: action.payload
            };

        case FormActionTypes.SET_CONFIRMATION_SHOW:
            return {
                ...state,
                showConfirmationModal: action.payload.showConfirmationModal,
                isDeleteConfirmation: action.payload.isDeleteConfirmation
            };

        case FormActionTypes.SET_CONFIRMATION_PARAMS:
            return {
                ...state,
                confirmationModalData: action.payload
            };

        case FormActionTypes.SET_NUMBER_FORMS:
            formDictionaryTable = Object.assign({}, state.formDictionary[action.payload.backOfficeTableName]);
            const lastIndex = Object.keys(formDictionaryTable)
                .map((e): number => +e)
                .filter((e): boolean => !isNaN(e))
                .sort()
                .reverse()[0];
            formDictionaryTable[lastIndex + 1] = {};
            return {
                ...state,
                numberForms: action.payload.numberForms,
                formDictionary: {
                    ...state.formDictionary,
                    [action.payload.backOfficeTableName]: formDictionaryTable
                }
            };

        case FormActionTypes.DELETE_FORM_LINE:
            formDictionaryTable = Object.assign({}, state.formDictionary[action.payload.backOfficeTableName]);
            let errorMessageTable = Object.assign({}, state.errorMessage[action.payload.backOfficeTableName]);
            for (let i = action.payload.formIndex; i < state.numberForms; i++) {
                formDictionaryTable[i] = formDictionaryTable[i + 1];
                errorMessageTable[i] = errorMessageTable[i + 1];
            }
            delete formDictionaryTable[state.numberForms - 1];
            delete errorMessageTable[state.numberForms - 1];
            return {
                ...state,
                numberForms: state.numberForms - 1,
                formDictionary: {
                    ...state.formDictionary,
                    [action.payload.backOfficeTableName]: formDictionaryTable
                },
                errorMessage: { ...state.errorMessage, [action.payload.backOfficeTableName]: errorMessageTable }
            };

        case FormActionTypes.SET_FORM_TABLE:
            return {
                ...state,
                formTable: action.payload
            };

        case FormActionTypes.SET_ID_DETAILED_ELEMENT:
            return {
                ...state,
                idDetailedElement: action.payload
            };

        case FormActionTypes.SET_INITIAL_FORM:
            return {
                ...state,
                initialFormDictionary: action.payload
            };

        case FormActionTypes.SET_IS_LOADING_FORM:
            return {
                ...state,
                isLoadingForm: action.payload
            };

        case FormActionTypes.SET_IS_CREATION_OR_UPDATE_LOADING:
            return {
                ...state,
                isCreationOrUpdateLoading: action.payload
            };

        default:
            return state;
    }
}
