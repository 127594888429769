import { IReduxAction } from "../store.type";
import InstitutionChronosTypes from "../type/institutionChronos.type";
import {
    fetchInstitutionChronoss,
    insertInstitutionChronos,
    updateInstitutionChronos,
    deleteInstitutionChronos
} from "../../service/institutionChronos.service";
import { IInstitutionChronos, INormalizedInstitutionChronoss } from "../../../Model/InstitutionChronos";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionChronossAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionChronoss = await fetchInstitutionChronoss(query);
            dispatch({
                type: InstitutionChronosTypes.FETCH_INSTITUTIONCHRONOSS,
                payload: {
                    byId: institutionChronoss.reduce(
                        (p: INormalizedInstitutionChronoss, c: IInstitutionChronos): INormalizedInstitutionChronoss => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionChronoss.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionChronosAction(
    institutionChronos: IInstitutionChronos
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionChronos = await insertInstitutionChronos(institutionChronos);
            dispatch({
                type: InstitutionChronosTypes.INSERT_INSTITUTIONCHRONOS,
                payload: newInstitutionChronos
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionChronosAction(
    id: number,
    institutionChronos: IInstitutionChronos
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionChronos = await updateInstitutionChronos(id, institutionChronos);
            dispatch({
                type: InstitutionChronosTypes.UPDATE_INSTITUTIONCHRONOS,
                payload: updatedInstitutionChronos
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionChronosAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionChronos(id);
            dispatch({
                type: InstitutionChronosTypes.DELETE_INSTITUTIONCHRONOS
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionChronossAction(
    dict: INormalizedInstitutionChronoss
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_INSTITUTIONCHRONOSS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionChronosAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_SELECTED_INSTITUTIONCHRONOS,
            payload: id
        });
    };
}

export function setSelectedInstitutionChronosMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_SELECTED_INSTITUTIONCHRONOS_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionChronosAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_IS_LOADING_INSTITUTIONCHRONOS,
            payload: isLoading
        });
    };
}
export function setInstitutionChronosOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_INSTITUTIONCHRONOS_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionChronosErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionChronosTypes.SET_INSTITUTIONCHRONOS_ERROR_CODE,
            payload: errorCode
        });
    };
}
