import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionOptionWorkerTypes from "../type/institutionOptionWorker.type";
import { IInstitutionOptionWorker } from "../../../Model/InstitutionOptionWorker";

const initialState: IReduxInitialState<IInstitutionOptionWorker> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};
export default function institutionOptionWorkerReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOptionWorkerTypes.FETCH_INSTITUTIONOPTIONWORKERS:
        case InstitutionOptionWorkerTypes.SET_INSTITUTIONOPTIONWORKERS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] };

        case InstitutionOptionWorkerTypes.FETCH_INSTITUTIONOPTIONWORKERS_COUNT:
            return { ...state, count: action.payload };

        case InstitutionOptionWorkerTypes.SET_SELECTED_INSTITUTIONOPTIONWORKER:
            return { ...state, selectedId: action.payload };

        case InstitutionOptionWorkerTypes.SET_SELECTED_INSTITUTIONOPTIONWORKER_MULTI:
            return { ...state, selectedMulti: action.payload };

        case InstitutionOptionWorkerTypes.SET_IS_LOADING_INSTITUTIONOPTIONWORKER:
            return { ...state, isLoading: action.payload };

        case InstitutionOptionWorkerTypes.SET_INSTITUTIONOPTIONWORKER_ORDER:
            return { ...state, ids: action.payload };

        case InstitutionOptionWorkerTypes.INSERT_INSTITUTIONOPTIONWORKER: {
            return {
                ...state,
                byId: {
                    [action.payload.institution]: {
                        ...action.payload
                    }
                },
                ids: [action.payload.institution]
            };
        }

        case InstitutionOptionWorkerTypes.UPDATE_INSTITUTIONOPTIONWORKER: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.institution]: {
                        ...state.byId[action.payload.institution],
                        ...action.payload
                    }
                }
            };
        }
        default:
            return state;
    }
}
