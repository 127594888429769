//@ts-nocheck
import { IQueryParams } from "npm-medgo-query";
import {
    PossibleApis,
    PossibleDeletes,
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates
} from "../../Constant/Api.constant";
import { ILevel } from "../../Model/Level";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";

const LevelDefaultSelect = [
    "id",
    "name",
    "isAllowedToSeeCustomStatistics",
    "isAllowedToSeeProInstitutionConfigPage",
    "isAllowedToSeeProInstitutionPage",
    "isAllowedToSeeProNotificationHistory",
    "isPostingMissionAllowed",
    "isAllowedToPostMedgoStandardMission",
    "isDeclaringPastMissionAllowed",
    "isSelectingPotentialWorkerMissionAllowed",
    "isCancelingMissionAllowed",
    "isModifyingMissionAllowed",
    "isAllowedToRequestMission",
    "isAllowedToValidateMissionRequestWithoutWorker",
    "isAllowedToSeeRequestedMission",
    "isAllowedToValidateMission",
    "isAllowedToCancelMissionAfterValidation",
    "isPlanningMissionAllowed",
    "isAllowedToSeeProVacatairePage",
    "isAllowedToSeeWorker",
    "isAllowedToSeeWorkerContactInfo",
    "isAllowedToSeeWorkerProfile",
    "isAllowedToSeeMissionHistory",
    "isAllowedToSeeWorkerContactBeforeMissionIsProvided",
    "isAllowedToSeeWorkerContactAfterMissionIsProvided",
    "isInvitingNewWorkerAllowed",
    "isRemovingOrAddingWorkerFromNetworkAllowed",
    "isAllowedToAddressWorkerToJoinNetwork",
    "isAllowedToAddressWorkerNewJobSpecialty",
    "isAllowedToChangeWorkerJob",
    "isAllowedToChangeWorkerService",
    "isAllowedToRequestInterimAgencyOrMarketplace",
    "isAllowedToChangeService",
    "isAllowedToSeeMissionOutsideService",
    "isAllowedToSeeStatisticsPage",
    "isAllowedToSaveOnWorkerJobPage",
    "isAllowedToSaveServiceUndefinedForWorkerStatutZero",
    "isAllowedToAddressWorkerStatutOne",
    "isAllowedToValidateMissionRequestWithoutWorkerLevel2: false",
    "isAllowedToValidateMissionRequestWithWorker",
    "isAllowedToValidateMissionRequestWithWorkerLevel2",
    "isAllowedToChooseReplacementForMission",
    "hasContractPermissions"
];

export async function fetchLevels(query: IQueryParams): Promise<ILevel[]> {
    const levels = await postFetchFactory<ILevel>(PossibleFetchs.level, PossibleApis.medgoApi)(
        {
            select: LevelDefaultSelect
        },
        query
    );
    return levels;
}

export async function insertLevel(level: ILevel): Promise<ILevel> {
    const levels = await postFactory<ILevel>(PossiblePosts.level, PossibleApis.medgoApi)(level);
    return levels;
}

export async function updateLevel(id: number, level: ILevel): Promise<ILevel> {
    const levels = await updateFactory<ILevel>(PossibleUpdates.level, PossibleApis.medgoApi)(id, level);
    return levels;
}

export async function deleteLevel(id: number): Promise<ILevel> {
    const levels = await deleteFactory<ILevel>(PossibleDeletes.level, PossibleApis.medgoApi)(id);
    return levels;
}
