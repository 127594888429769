import React, { Component } from "react";
import { connect } from "react-redux";
import { IAdmin, INormalizedAdmins } from "../../../Model/Admin";
import { EBackOfficeColumnType, ExportColumns } from "../../constants/Column.constants";
import { DatetimeComponent } from "npm-medgo-components";
import { Moment } from "moment";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { setFormKeyValueAction } from "../../store/action/form.action";
import moment from "moment";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { insertExportAction, fetchExportsAction } from "../../store/action/export.action";
import { fetchAdminsAction } from "../../store/action/admin.action";
import { setNotificationMessage } from "../../store/action/notification.action";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedExports } from "../../../Model/Export";
import { isNullOrUndefined } from "util";
import "../../scss/components/banner/ExportBanner.scss";
import { INotification } from "../../store/reducer/notification.reducer";
import { ILang } from "../../lang/Lang";

interface IReduxPropsExportBanner {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
    formDictionary: IFormDictionary;
    exportDict: INormalizedExports;
    idExports: number[];
    adminDict: INormalizedAdmins;
    idAdmins: number[];
    idSelectedInstitutions: number[];
}

interface IReduxActionExportBanner {
    fetchExportsAction: (param: IQueryParams) => any;
    insertExportAction: (
        beginAtDate: Moment,
        endAtDate: Moment,
        admin: number,
        idInstitution: number[]
    ) => Promise<void>;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    fetchAdminsAction: (param: IQueryParams) => any;
    setNotificationMessage: (message: INotification) => void;
}

interface IStateExportBanner {
    beginAtDate: Moment;
    endAtDate: Moment;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPropsExportBanner extends IReduxPropsExportBanner, IReduxActionExportBanner {}

class ExportBanner extends Component<IPropsExportBanner, IStateExportBanner> {
    constructor(props: IPropsExportBanner) {
        super(props);

        this.state = {
            beginAtDate: moment()
                .subtract(1, "month")
                .startOf("month"),
            endAtDate: moment()
                .subtract(1, "month")
                .endOf("month")
        };

        this.handleChangeBeginAt = this.handleChangeBeginAt.bind(this);
        this.handleChangeEndAt = this.handleChangeEndAt.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.props
            .fetchAdminsAction({
                limit: 99999,
                orderby: [
                    {
                        columnName: "id",
                        orientation: "ASC"
                    }
                ],
                query: { institution: this.props.idSelectedInstitutions },
                select: ["id", "firstName", "lastName"]
            })
            .catch(console.error);
        await this.props
            .fetchExportsAction({
                query: { admin: this.props.idAdmins },
                orderby: [
                    {
                        columnName: "exportedAt",
                        orientation: "DESC"
                    }
                ],
                select: ["id", "exportedAt", "admin"]
            })
            .catch(console.error);
    }

    handleChangeBeginAt(date: any): void {
        const endAtValue = this.getEndAtElement();
        this.setState(
            {
                beginAtDate: date,
                endAtDate: endAtValue
            },
            (): void => {
                this.props.setFormKeyValueAction(
                    "beginAtDate",
                    date.clone(),
                    EBackOfficeTableName.exportConfiguration,
                    ExportColumns[0].type,
                    0
                );
                this.props.setFormKeyValueAction(
                    "endAtDate",
                    endAtValue.clone(),
                    EBackOfficeTableName.exportConfiguration,
                    ExportColumns[1].type,
                    0
                );
            }
        );
    }

    handleChangeEndAt(date: any): void {
        const beginAtValue = this.getBeginAtElement();
        this.setState(
            {
                beginAtDate: beginAtValue,
                endAtDate: date
            },
            (): void => {
                this.props.setFormKeyValueAction(
                    "beginAtDate",
                    beginAtValue.clone(),
                    EBackOfficeTableName.exportConfiguration,
                    ExportColumns[0].type,
                    0
                );
                this.props.setFormKeyValueAction(
                    "endAtDate",
                    date.clone(),
                    EBackOfficeTableName.exportConfiguration,
                    ExportColumns[1].type,
                    0
                );
            }
        );
    }

    getBeginAtElement(): Moment {
        return getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.exportConfiguration,
            this.state.beginAtDate
        )[0]
            ? (getFormDictionaryRecordsDict(
                  this.props.formDictionary,
                  EBackOfficeTableName.exportConfiguration,
                  this.state.beginAtDate
              )[0].beginAtDate as Moment)
            : this.state.beginAtDate;
    }

    getEndAtElement(): Moment {
        return getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.exportConfiguration,
            this.state.endAtDate
        )[0]
            ? (getFormDictionaryRecordsDict(
                  this.props.formDictionary,
                  EBackOfficeTableName.exportConfiguration,
                  this.state.endAtDate
              )[0].endAtDate as Moment)
            : this.state.endAtDate;
    }

    render(): JSX.Element {
        let lastExportDate;
        let lastAdminFirstName;
        let lastAdminLastname;
        if (this.props.idExports.length > 0) {
            for (let index = 0; this.props.exportDict[this.props.idExports[index]]; index++) {
                if (this.props.idAdmins.includes(this.props.exportDict[this.props.idExports[index]].admin)) {
                    lastExportDate = moment
                        .unix(this.props.exportDict[this.props.idExports[index]].exportedAt)
                        .format("DD/MM/YYYY");
                    lastAdminFirstName = this.props.adminDict[this.props.exportDict[this.props.idExports[index]].admin]
                        .firstName;
                    lastAdminLastname = this.props.adminDict[this.props.exportDict[this.props.idExports[index]].admin]
                        .lastName;
                    break;
                }
            }
        }

        return (
            <div className="card export-banner">
                <div className="row">
                    <div className="col-5 description is-vertical-centered">
                        {!isNullOrUndefined(lastExportDate)
                            ? this.props.lang.lastExportOnXXXByXXX
                                  .replace("__VAR1", lastExportDate)
                                  .replace("__VAR2", `${lastAdminFirstName} ${lastAdminLastname}`)
                            : this.props.lang.noLastExport}
                    </div>
                    <div className="col-7 is-vertical-centered">
                        <div className="row">
                            <div className="col-4">
                                <DatetimeComponent
                                    placeholder={`${this.getBeginAtElement()}`}
                                    type="date"
                                    hasPastDates={true}
                                    hasFutureDates={true}
                                    disableAfter={this.getEndAtElement()}
                                    value={this.getBeginAtElement()}
                                    onChange={this.handleChangeBeginAt}
                                    className="calendar"
                                />
                            </div>
                            <div className="col-4">
                                <DatetimeComponent
                                    placeholder={`${this.getEndAtElement()}`}
                                    type="date"
                                    hasPastDates={true}
                                    hasFutureDates={true}
                                    disableBefore={this.getBeginAtElement()}
                                    value={this.getEndAtElement()}
                                    onChange={this.handleChangeEndAt}
                                    className="calendar"
                                />
                            </div>
                            <div className="col-4">
                                <button
                                    onClick={(): void => {
                                        this.props
                                            .insertExportAction(
                                                this.getBeginAtElement(),
                                                this.getEndAtElement(),
                                                this.props.me.id,
                                                this.props.idSelectedInstitutions
                                            )
                                            .then((): void => {
                                                this.props.setNotificationMessage({
                                                    message: this.props.lang.anEmailWillBeSentToXXX.replace(
                                                        "___VAR1",
                                                        this.props.me.email ? this.props.me.email : ""
                                                    ),
                                                    icon: "fa fa-check",
                                                    color: "success"
                                                });
                                            })
                                            .catch((): void => {
                                                this.props.setNotificationMessage({
                                                    message: this.props.lang.errCodeGeneric500,
                                                    icon: "fa fa-times",
                                                    color: "danger"
                                                });
                                            });
                                    }}
                                    className="button"
                                >
                                    {this.props.lang.export}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsExportBanner => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user,
        formDictionary: centralState.form.formDictionary,
        exportDict: centralState.exports.byId,
        idExports: centralState.exports.ids,
        idAdmins: centralState.admins.ids,
        adminDict: centralState.admins.byId,
        idSelectedInstitutions: centralState.institutions.selectedMulti
    }),
    { setFormKeyValueAction, insertExportAction, fetchExportsAction, fetchAdminsAction, setNotificationMessage }
)(ExportBanner);
