//@ts-ignore
import * as clone from "clone";
import { IReduxAction, IReduxInitialState } from "../store.type";
import SpecialHourTypes from "../type/specialHour.type";
import { ISpecialHour } from "../../../Model/SpecialHour";

const initialState: IReduxInitialState<ISpecialHour> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function specialHourReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case SpecialHourTypes.FETCH_SPECIALHOURS:
        case SpecialHourTypes.SET_SPECIALHOURS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case SpecialHourTypes.SET_SELECTED_SPECIALHOUR: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case SpecialHourTypes.SET_SELECTED_SPECIALHOUR_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case SpecialHourTypes.SET_IS_LOADING_SPECIALHOUR: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case SpecialHourTypes.SET_SPECIALHOUR_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case SpecialHourTypes.INSERT_SPECIALHOUR: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                        institution: [action.payload.idInstitution]
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case SpecialHourTypes.INSERT_SPECIALHOUR_HAS_JOB: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idSpecialHour]: {
                        ...state.byId[action.payload.idSpecialHour],
                        job: [...(state.byId[action.payload.idSpecialHour].job || []), action.payload.idJob]
                    }
                }
            };
        }

        case SpecialHourTypes.INSERT_SPECIALHOUR_HAS_SERVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idSpecialHour]: {
                        ...state.byId[action.payload.idSpecialHour],
                        service: [...(state.byId[action.payload.idSpecialHour].service || []), action.payload.idService]
                    }
                }
            };
        }

        case SpecialHourTypes.UPDATE_SPECIALHOUR: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idSpecialHour]: {
                        ...state.byId[action.payload.id],
                        ...action.payload.specialHour
                    }
                }
            };
        }

        case SpecialHourTypes.DELETE_SPECIALHOUR: {
            let byId = clone(state.byId);
            let ids = clone(state.ids);
            delete byId[action.payload];
            ids.splice(state.ids.indexOf(action.payload), 1);
            return {
                ...state,
                byId,
                ids
            };
        }

        default:
            return state;
    }
}
