import { IInstitutionInfo } from "../../Model/InstitutionInfo";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionInfoDefaultSelect = ["institution", "hubSpotId", "hubSpotInvoiceId"];

export async function fetchInstitutionInfos(query: IQueryParams): Promise<IInstitutionInfo[]> {
    const institutionInfos = await postFetchFactory<IInstitutionInfo>(
        PossibleFetchs.institutionInfo,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionInfoDefaultSelect
        },
        query
    );
    return institutionInfos;
}

export async function fetchInstitutionInfosCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionInfo>(PossibleFetchs.institutionInfo, PossibleApis.medgoApi)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionInfo(institutionInfo: IInstitutionInfo): Promise<IInstitutionInfo> {
    return await postFactory<IInstitutionInfo>(PossiblePosts.institutionInfo, PossibleApis.medgoApi)(institutionInfo);
}

export async function updateInstitutionInfo(id: number, institutionInfo: IInstitutionInfo): Promise<IInstitutionInfo> {
    return await updateFactory<IInstitutionInfo>(PossibleUpdates.institutionInfo, PossibleApis.medgoApi)(
        id,
        institutionInfo
    );
}

export async function deleteInstitutionInfo(id: number): Promise<IInstitutionInfo> {
    return await deleteFactory<IInstitutionInfo>(PossibleDeletes.institutionInfo, PossibleApis.medgoApi)(id);
}
