import { IInstitution, IInstitutionGTA } from "../../Model/Institution";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionDefaultSelect = [
    "id",
    "name",
    "prefixMission",
    "address",
    "phone",
    "postalCode",
    "siret",
    "corporateName",
    "healthService",
    "urssafCode",
    "apeCode",
    "nameSMS",
    "country",
    "lang",
    "sector",
    "timezone"
];

export async function fetchInstitutions(query: IQueryParams): Promise<IInstitution[]> {
    const institutions = await postFetchFactory<IInstitution>(PossibleFetchs.institution, PossibleApis.medgoApi)(
        {
            select: InstitutionDefaultSelect
        },
        query
    );
    return institutions;
}

export async function insertInstitution(institution: IInstitution): Promise<IInstitution> {
    const institutions = await postFactory<IInstitution>(PossiblePosts.institution, PossibleApis.medgoApi)(institution);
    return institutions;
}

export async function updateInstitution(id: number, institution: IInstitution): Promise<IInstitution> {
    const institutions = await updateFactory<IInstitution>(PossibleUpdates.institution, PossibleApis.medgoApi)(
        id,
        institution
    );
    return institutions;
}

export async function deleteInstitution(id: number): Promise<IInstitution> {
    const institutions = await deleteFactory<IInstitution>(PossibleDeletes.institution, PossibleApis.medgoApi)(id);
    return institutions;
}

export async function fetchInstitutionGTAs(query: IQueryParams): Promise<IInstitutionGTA[]> {
    const institutionGTAs = await postFetchFactory<IInstitutionGTA>(
        PossibleFetchs.institutionGTA,
        PossibleApis.medgoApi
    )(
        {
            select: ["id"]
        },
        query
    );
    return institutionGTAs;
}
