import React from "react";
import ConfirmationModal from "./Confirmation.modal";
import { ILang } from "../../../lang/Lang";
import { connect } from "react-redux";

interface IReduxPropsConfirmationImportCSVModal {
    showConfirmationImportCSVModal: boolean;
}

interface IPropsConfirmationImportCSVModal extends IReduxPropsConfirmationImportCSVModal {
    lang: ILang;
    subtitle: string;
    text: string;
    onClick1: () => void;
    onClick2: () => void;
    textButton1: string;
    textButton2: string;
}

export class ConfirmationImportCSVModal extends React.Component<IPropsConfirmationImportCSVModal> {
    render() {
        return (
            <ConfirmationModal
                lang={this.props.lang}
                subtitle={this.props.subtitle}
                text={this.props.text}
                onClick1={this.props.onClick1}
                onClick2={this.props.onClick2}
                textButton1={this.props.textButton1}
                textButton2={this.props.textButton2}
                show={this.props.showConfirmationImportCSVModal}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsConfirmationImportCSVModal => ({
        showConfirmationImportCSVModal: centralState.form.showConfirmationImportCSVModal
    }),
    {}
)(ConfirmationImportCSVModal);
