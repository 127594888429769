import { connect } from "react-redux";
import {
    setTemplatesAction,
    fetchTemplatesAction,
    setIsLoadingTemplateAction,
    deleteTemplateAction
} from "../../store/action/template.action";

import { ITemplate } from "../../../Model/Template";
import { INormalizedJobs } from "../../../Model/Job";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedTemplates } from "../../../Model/Template";
import { INormalizedSpecialties } from "../../../Model/Specialty";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsTemplate extends IReduxPropsTable {
    idTemplates: number[];
    templateDict: INormalizedTemplates;
    jobDict: INormalizedJobs;
    specialtyDict: INormalizedSpecialties;
    idAllServices: number[];
    idAllJobs: number[];
    idItemToBeDeleted: number;
}

interface IPropsTemplate extends IReduxPropsTemplate, IReduxActionsTemplate, IPropsTable {}

interface IReduxActionsTemplate extends IReduxActionsTable {
    setTemplatesAction: (elements: ITemplate[]) => any;
    fetchTemplatesAction: (param: IQueryParams, jobs: INormalizedJobs, specialties: INormalizedSpecialties) => any;
    setIsLoadingTemplateAction: (isLoading: boolean) => any;
    deleteTemplateAction: (id: number) => void;
}

class TableTemplateContainer extends TableComponent<IPropsTemplate> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        await this.props.setIsLoadingTemplateAction(true);
        await this.props.fetchTemplatesAction(params, this.props.jobDict, this.props.specialtyDict);
        await this.props.setIsLoadingTemplateAction(false);
    }

    setElements(elements: any): void {
        this.props.setTemplatesAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.templateDict, this.props.idTemplates);
    }

    onDeleteLineClick(): void {
        this.props.deleteTemplateAction(this.props.idItemToBeDeleted);
    }
}

export default connect(
    (centralState: any): IReduxPropsTemplate =>
        Object.assign(getConnectParentState(centralState), {
            idTemplates: centralState.template.ids,
            templateDict: centralState.template.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            jobDict: centralState.jobs.allById,
            specialtyDict: centralState.specialties.allById,
            idAllServices: centralState.specialties.allIds,
            idAllJobs: centralState.jobs.allIds,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setTemplatesAction,
        fetchTemplatesAction,
        setIsLoadingTemplateAction,
        deleteTemplateAction
    })
)(TableTemplateContainer);
