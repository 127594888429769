import { ILang } from "../../lang/Lang";
import React from "react";
import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import {
    EHubloFormInputType,
    EMedgoLabelClassName,
    HubloErrorMessageInfoBox,
    HubloFormInputWithLabelComponent,
    HubloJumbotron,
    MedgoLabelComponent
} from "npm-medgo-components";
import { EAvailableLang, SelectLanguage } from "@hublo/legacy-components";
import { Constants } from "npm-hublo-toolbox";
import { getModelsFromDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { AdminColumns, EBackOfficeColumnType } from "../../constants/Column.constants";
import { setFormKeyValueAction } from "../../store/action/form.action";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import "./AdminProfile.view.scss";

interface IReduxActionsAdminProfileView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
}

interface IReduxPropsAdminProfileView {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
    idAdmins: number[];
    adminDict: IAdmin[];
    formDictionary: IFormDictionary;
}

interface IPropsAdminProfileView extends IReduxPropsAdminProfileView, IReduxActionsAdminProfileView {
    errorMessagePassword: string;
    errorMessageProfile: string;
    infoMessageProfile: string;
    infoMessagePassword: string;
    isLoadingProfile: boolean;
    isLoadingPassword: boolean;
    hasColumnChanged: (field: string) => boolean;
    password: string;
    newPassword: string;
    confirmPassword: string;
    handleChange: (key: string, value: string) => void;
    saveChanges: () => void;
    updatePassword: () => void;
    updateLanguage: (lang: EAvailableLang) => void;
}

class AdminProfileView extends React.Component<IPropsAdminProfileView> {
    render(): JSX.Element {
        const contactDetails = Constants.companyContactDetailsFor(this.props.lang as any);

        let adminList: IAdmin[];
        adminList = [];
        if (this.props.adminDict) {
            adminList = getModelsFromDict<IAdmin>(this.props.adminDict, this.props.idAdmins);
        }

        let admin: any = {};
        if (adminList.length > 0) {
            admin = adminList[0];
        }

        return (
            <div
                className="container padding-bottom-l grey-background full-page unlimited-width"
                style={{ minHeight: "100%" }}
            >
                <HubloJumbotron
                    title={this.props.lang.myProfile}
                    size="l"
                    innerJumbotron={
                        <div className="row">
                            <div className="col-12">
                                <HubloFormInputWithLabelComponent
                                    name={this.props.lang.lastName}
                                    type="text"
                                    placeholder={admin.lastName ? admin.lastName : ""}
                                    value={
                                        this.props.hasColumnChanged("lastName")
                                            ? (getFormDictionaryRecordsDict(
                                                  this.props.formDictionary,
                                                  EBackOfficeTableName.admin
                                              )[0].lastName as string)
                                            : admin.lastName
                                            ? admin.lastName
                                            : ""
                                    }
                                    onChange={(value: any): void => {
                                        this.props.setFormKeyValueAction(
                                            "lastName",
                                            value,
                                            EBackOfficeTableName.admin,
                                            AdminColumns[3].type,
                                            0
                                        );
                                    }}
                                />
                                <HubloFormInputWithLabelComponent
                                    type="text"
                                    name={this.props.lang.firstName}
                                    placeholder={admin.firstName ? admin.firstName : ""}
                                    value={
                                        this.props.hasColumnChanged("firstName")
                                            ? (getFormDictionaryRecordsDict(
                                                  this.props.formDictionary,
                                                  EBackOfficeTableName.admin
                                              )[0].firstName as string)
                                            : admin.firstName
                                            ? admin.firstName
                                            : ""
                                    }
                                    onChange={(value: any): void => {
                                        this.props.setFormKeyValueAction(
                                            "firstName",
                                            value,
                                            EBackOfficeTableName.admin,
                                            AdminColumns[2].type,
                                            0
                                        );
                                    }}
                                />
                                <HubloFormInputWithLabelComponent
                                    type="text"
                                    name={this.props.lang.phoneNumber}
                                    placeholder={admin.mobilePhone ? admin.mobilePhone : ""}
                                    value={
                                        this.props.hasColumnChanged("mobilePhone")
                                            ? (getFormDictionaryRecordsDict(
                                                  this.props.formDictionary,
                                                  EBackOfficeTableName.admin
                                              )[0].mobilePhone as string)
                                            : admin.mobilePhone
                                            ? admin.mobilePhone
                                            : ""
                                    }
                                    onChange={(value: any): void => {
                                        this.props.setFormKeyValueAction(
                                            "mobilePhone",
                                            value,
                                            EBackOfficeTableName.admin,
                                            AdminColumns[4].type,
                                            0
                                        );
                                    }}
                                />
                                <HubloFormInputWithLabelComponent
                                    type="text"
                                    name={this.props.lang.email}
                                    value={
                                        this.props.hasColumnChanged("email")
                                            ? (getFormDictionaryRecordsDict(
                                                  this.props.formDictionary,
                                                  EBackOfficeTableName.admin
                                              )[0].email as string)
                                            : admin.email
                                            ? admin.email
                                            : ""
                                    }
                                    onChange={(value: any): void => {
                                        this.props.setFormKeyValueAction(
                                            "email",
                                            value,
                                            EBackOfficeTableName.admin,
                                            AdminColumns[1].type,
                                            0
                                        );
                                    }}
                                    isDisabled={!(admin.email && admin.email.endsWith("hublo.com"))}
                                />
                                <MedgoLabelComponent
                                    className={EMedgoLabelClassName.medgoLabelFormLabel}
                                    text={this.props.lang.toChangeMailContactUs.replace(
                                        "_CONSTANTS_EMAIL_CONTACT_",
                                        contactDetails.email
                                    )}
                                />
                                <div>
                                    <MedgoLabelComponent
                                        className={EMedgoLabelClassName.medgoLabelFormLabel}
                                        text={this.props.lang.language}
                                    />
                                    <SelectLanguage
                                        // @ts-ignore
                                        lang={this.props.lang}
                                        currentLang={this.props.currentLang}
                                        handleChangeLang={(lang: EAvailableLang): void => {
                                            this.props.updateLanguage(lang);
                                        }}
                                    />
                                </div>
                                <HubloErrorMessageInfoBox
                                    lang={this.props.lang}
                                    infoMessage={this.props.infoMessageProfile}
                                    errorMessage={this.props.errorMessageProfile}
                                    isLoading={this.props.isLoadingProfile}
                                    nextPage={this.props.saveChanges}
                                />
                                <br />
                            </div>
                        </div>
                    }
                />
                <HubloJumbotron
                    title={this.props.lang.changeMyPassword}
                    size="l"
                    innerJumbotron={
                        <div className="row">
                            <div className="col-12">
                                <HubloFormInputWithLabelComponent
                                    type="password"
                                    name={this.props.lang.actualPassword}
                                    value={this.props.password ? this.props.password : ""}
                                    onChange={(value: any): void => {
                                        this.props.handleChange("password", value);
                                    }}
                                />
                                <HubloFormInputWithLabelComponent
                                    type="password"
                                    name={this.props.lang.newPassword}
                                    value={this.props.newPassword ? this.props.newPassword : ""}
                                    onChange={(value: any): void => {
                                        this.props.handleChange("newPassword", value);
                                    }}
                                />
                                <HubloFormInputWithLabelComponent
                                    type="password"
                                    name={this.props.lang.confirmationPassword}
                                    value={this.props.confirmPassword ? this.props.confirmPassword : ""}
                                    onChange={(value: any): void => {
                                        this.props.handleChange("confirmPassword", value);
                                    }}
                                />
                                <HubloErrorMessageInfoBox
                                    lang={this.props.lang}
                                    infoMessage={this.props.infoMessagePassword}
                                    errorMessage={this.props.errorMessagePassword}
                                    isLoading={this.props.isLoadingPassword}
                                    nextPage={this.props.updatePassword}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsAdminProfileView => ({
        currentLang: centralState.language.currentLang,
        lang: centralState.language.lang,
        me: centralState.auth.user,
        formDictionary: centralState.form.formDictionary,
        idAdmins: centralState.admins.ids,
        adminDict: centralState.admins.byId
    }),
    {
        setFormKeyValueAction
    }
)(AdminProfileView);
