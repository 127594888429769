enum PublicholidayActionTypes {
    FETCH_PUBLICHOLIDAYS = "FETCH_PUBLICHOLIDAYS",
    SET_PUBLICHOLIDAYS = "SET_PUBLICHOLIDAYS",
    FETCH_PUBLICHOLIDAYS_COUNT = "FETCH_PUBLICHOLIDAYS_COUNT",
    SET_SELECTED_PUBLICHOLIDAY = "SET_SELECTED_PUBLICHOLIDAY",
    SET_SELECTED_PUBLICHOLIDAY_MULTI = "SET_SELECTED_PUBLICHOLIDAY_MULTI",
    SET_IS_LOADING_PUBLICHOLIDAY = "SET_IS_LOADING_PUBLICHOLIDAY",
    SET_PUBLICHOLIDAY_ORDER = "SET_PUBLICHOLIDAY_ORDER",
    INSERT_PUBLICHOLIDAY = "INSERT_PUBLICHOLIDAY",
    UPDATE_PUBLICHOLIDAY = "UPDATE_PUBLICHOLIDAY",
    DELETE_PUBLICHOLIDAY = "DELETE_PUBLICHOLIDAY",
    SET_PUBLICHOLIDAY_ERROR_CODE = "SET_PUBLICHOLIDAY_ERROR_CODE"
}
export default PublicholidayActionTypes;
