import { connect } from "react-redux";
import { setAdminsAction, fetchAdminsAction, setIsLoadingAdminAction } from "../../store/action/admin.action";
import { fetchServicesAction } from "../../store/action/service.action";
import { IAdmin, INormalizedAdmins } from "../../../Model/Admin";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";
import { IService } from "../../../Model/Service";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { isNullOrUndefined } from "util";

//@ts-ignore
interface IReduxPropsTableAdmin extends IReduxPropsTable {
    idAdmins: number[];
    adminDict: INormalizedAdmins;
    idServices: number[];
    selectedMulti: number[];
    searchValue: string;
}

interface IPropsTableAdmin extends IReduxPropsTableAdmin, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setAdminsAction: (elements: IAdmin[]) => any;
    fetchAdminsAction: (param: IQueryParams) => any;
    setAdminsPageAction: (pageNum: number) => any;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    fetchServicesAction: (param: IQueryParams) => any;
    setIsLoadingAdminAction: (isLoading: boolean) => any;
}

class TableAdminComponent extends TableComponent<IPropsTableAdmin> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingAdminAction(true);
        if (this.props.idAdmins.length === 0) await this.props.fetchAdminsAction(params);
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        this.props.setIsLoadingAdminAction(false);
    }

    setElements(elements: any): void {
        this.props.setAdminsAction(elements);
    }
    getElements(): any[] {
        const allResultsElements = getModelsFromDict<IAdmin>(this.props.adminDict, this.props.idAdmins);
        const searchValue = this.props.searchValue;
        if (isNullOrUndefined(searchValue)) return allResultsElements;
        return allResultsElements.filter(
            (e): boolean =>
                isIncludedStringCaseInsensitive(e.firstName, searchValue) ||
                isIncludedStringCaseInsensitive(e.lastName, searchValue) ||
                isIncludedStringCaseInsensitive(e.email, searchValue) ||
                isIncludedStringCaseInsensitive(e.mobilePhone, searchValue)
        );
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.admin]: {
                [EBackOfficeColumnName.service]: getElementsInInstitution(
                    getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                    this.props.selectedMulti
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsTableAdmin =>
        Object.assign(getConnectParentState(centralState), {
            servicesById: centralState.services.byId,
            selectedMulti: centralState.institutions.selectedMulti,
            idAdmins: centralState.admins.ids,
            adminDict: centralState.admins.byId,
            idServices: centralState.services.ids,
            isLoading: centralState.admins.isLoading,
            searchValue: centralState.tables.searchValue
        }),
    Object.assign(getConnectParentActions(), {
        setAdminsAction,
        fetchAdminsAction,
        fetchServicesAction,
        setIsLoadingAdminAction
    })
)(TableAdminComponent);
