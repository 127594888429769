import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Constants } from "npm-hublo-toolbox";
import { getModelsFromDict } from "../../modules/store/util";

import {
    HubloFormInputWithLabelComponent,
    SelectComponent,
    MedgoLabelComponent,
    EMedgoLabelClassName,
    HubloTextAreaWithLabel,
    HubloJumbotron,
    HubloErrorMessageInfoBox,
    HubloGoogleAddressAutoComplete,
    ButtonMedgoActionComponent,
    EButtonMedgoActionClassName
} from "npm-medgo-components";

import "../../scss/components/shared/BackofficeContainer.scss";
import "./Institution.view.scss";

import { isNullOrUndefined } from "util";

import { IInstitution } from "../../../Model/Institution";
import { IAdmin } from "../../../Model/Admin";
import { ISchedule } from "../../../Model/Schedule";
import { INormalizedServices, IService } from "../../../Model/Service";
import { IJob } from "../../../Model/Job";
import { INormalizedSpecialties, ISpecialty } from "../../../Model/Specialty";
import { IFormDictionary } from "../../store/type/form.type";

import { setFormKeyValueAction } from "../../store/action/form.action";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";

import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnType, InstitutionColumns } from "../../constants/Column.constants";
import {
    setShowConfirmationModalAction,
    setConfirmationModalDataAction,
    setFormScheduleRequestContentAction,
    setFormMessageContentAction,
    setFormFutureSelectedInstitutionAction
} from "../../store/action/form.action";
import ConfirmationModal, { IConfirmationModalData } from "../../components/Modals/Shared/Confirmation.modal";
import { ILang } from "../../lang/Lang";

interface IReduxPropsInstitutionView {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
    institutionsById: IInstitution[];
    idInstitutions: number[];
    idSelectedInstitution: number;
    jobsById: IJob[];
    idJobs: number[];
    scheduleDict: ISchedule[];
    idSchedules: number[];
    servicesById: INormalizedServices;
    idServices: number[];
    idSpecialties: number[];
    specialtyDict: INormalizedSpecialties;
    formDictionary: IFormDictionary;
    confirmationModalData: IConfirmationModalData;
    futureSelectedInstitution: any;
}

interface IReduxActionsInstitutionView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    setShowConfirmationModalAction: (show: boolean, isDeleteConfirmation?: boolean) => void;
    setConfirmationModalDataAction: (
        subtitle: string,
        text: string,
        onClick1: () => void,
        onClick2: () => void,
        textButton1: string,
        textButton2: string,
        placeholder?: string,
        isSimpleMessage?: boolean,
        handleChange?: () => void
    ) => void;
    setFormScheduleRequestContentAction: (scheduleRequestContent: string) => void;
    setFormMessageContentAction: (messageContent: string) => void;
    setFormFutureSelectedInstitutionAction: (futureSelectedInstitution: any) => void;
}

interface IPropsInstitutionView extends IReduxPropsInstitutionView, IReduxActionsInstitutionView {
    infoMessage: string;
    errorMessage: string;
    isLoading: boolean;
    willHandleChangeInstitution: (event: any) => void;
    onSelectAddress: (address: string) => void;
    isColumnChanged: (field: string) => boolean;
    saveChanges: () => void;
    getSelectedInstitution: (institutionId: number) => IInstitution;
    adminWantsNewSchedule: () => void;
    adminWantsToBeContacted: () => void;
}

class InstitutionView extends Component<IPropsInstitutionView> {
    constructor(props: IPropsInstitutionView) {
        super(props);
    }

    getScheduleElement(schedule: ISchedule) {
        let beginAt = moment.utc(schedule.beginAt, "H:mm").format("H:mm");
        let endAt = moment.utc(schedule.endAt, "H:mm").format("H:mm");
        return (
            <div
                key={schedule.id}
                className="badge badge-primary margin-right-s float-left padding-badge"
                style={{ marginTop: "2px", marginBottom: "2px" }}
            >
                {schedule.code} : {beginAt} {"=>"} {endAt}
            </div>
        );
    }

    getObjectElement(object: any) {
        return (
            <div
                key={object.id}
                className="badge badge-primary margin-right-s float-left padding-badge"
                style={{ marginTop: "2px", marginBottom: "2px" }}
            >
                {object.name}
            </div>
        );
    }

    updateScheduleRequestInput(event: any): void {
        this.props.setFormScheduleRequestContentAction(event);
    }

    updateMessageInput(event: any): void {
        this.props.setFormMessageContentAction(event);
    }

    render(): JSX.Element {
        const contactDetails = Constants.companyContactDetailsFor(this.props.lang as any);

        let institutionList: IInstitution[];
        institutionList = [];
        if (this.props.institutionsById) {
            institutionList = getModelsFromDict<IInstitution>(this.props.institutionsById, this.props.idInstitutions);
        }

        let institution = this.props.getSelectedInstitution(this.props.idSelectedInstitution);

        let scheduleList: ISchedule[];
        scheduleList = [];
        let scheduleElements;
        if (this.props.scheduleDict) {
            scheduleList = getModelsFromDict<ISchedule>(this.props.scheduleDict, this.props.idSchedules);
            scheduleElements = scheduleList.map(this.getScheduleElement);
        }

        let serviceList: IService[];
        serviceList = [];
        let serviceElements;
        if (this.props.servicesById) {
            serviceList = getModelsFromDict<IService>(this.props.servicesById, this.props.idServices);
            serviceElements = serviceList.map(this.getObjectElement);
        }

        let jobList: IJob[];
        jobList = [];
        let jobElements;
        if (this.props.jobsById) {
            jobList = getModelsFromDict<IJob>(this.props.jobsById, this.props.idJobs);
            jobElements = jobList.map(this.getObjectElement);
        }

        let specialtyList: ISpecialty[];
        specialtyList = [];
        let specialtyElements;
        if (this.props.specialtyDict) {
            specialtyList = getModelsFromDict<ISpecialty>(this.props.specialtyDict, this.props.idSpecialties);
            if (specialtyList) {
                specialtyElements = specialtyList.filter(
                    (e: any, i: any, arr: any) => arr.map((e: { name: any }) => e.name).indexOf(e.name) === i
                );
                specialtyElements = specialtyElements.map(this.getObjectElement);
            }
        }

        if (!isNullOrUndefined(institutionList)) {
            return (
                <div id="containerHome" className="center containerHome">
                    <ConfirmationModal
                        lang={this.props.lang}
                        subtitle={this.props.confirmationModalData.subtitle}
                        text={this.props.confirmationModalData.text}
                        onClick1={this.props.confirmationModalData.onClick1}
                        onClick2={this.props.confirmationModalData.onClick2}
                        textButton1={this.props.confirmationModalData.textButton1}
                        textButton2={this.props.confirmationModalData.textButton2}
                        placeholder={this.props.confirmationModalData.placeholder}
                        isSimpleMessage={this.props.confirmationModalData.isSimpleMessage}
                        handleChange={this.props.confirmationModalData.handleChange}
                    />
                    <HubloJumbotron
                        size="s"
                        innerJumbotron={
                            <div className="row">
                                <div className="col-6">
                                    <SelectComponent
                                        value={institutionList
                                            .filter(({ id }) => id === institution.id)
                                            .map(e => {
                                                return { label: e.name, value: e.id };
                                            })}
                                        onChange={
                                            getFormDictionaryRecordsDict(
                                                this.props.formDictionary,
                                                EBackOfficeTableName.institution
                                            )[0]
                                                ? (event: any): void => {
                                                      this.props.setFormFutureSelectedInstitutionAction(event);
                                                      this.props.setConfirmationModalDataAction(
                                                          this.props.lang.beCareful,
                                                          this.props.lang.modificationNotSaved,
                                                          (): void => this.props.setShowConfirmationModalAction(false),
                                                          (): void => {
                                                              this.props.setShowConfirmationModalAction(false);
                                                              this.props.willHandleChangeInstitution(
                                                                  this.props.futureSelectedInstitution
                                                              );
                                                              this.props.setFormFutureSelectedInstitutionAction({});
                                                          },
                                                          this.props.lang.cancel,
                                                          this.props.lang.continue
                                                      );
                                                      this.props.setShowConfirmationModalAction(true);
                                                  }
                                                : this.props.willHandleChangeInstitution
                                        }
                                        options={institutionList.map((e: IInstitution) => {
                                            return { label: e.name, value: e.id };
                                        })}
                                    />
                                </div>
                                <div className="col-6">
                                    <ButtonMedgoActionComponent
                                        label={this.props.lang.youHaveQuestion}
                                        className={EButtonMedgoActionClassName.medgoButtonFull}
                                        handleClick={(): void => {
                                            this.props.setConfirmationModalDataAction(
                                                this.props.lang.youHaveQuestion,
                                                "",
                                                (): void => this.props.setShowConfirmationModalAction(false),
                                                (): void => {
                                                    this.props.setShowConfirmationModalAction(false);
                                                    this.props.adminWantsToBeContacted();
                                                },
                                                this.props.lang.cancel,
                                                this.props.lang.askToBeRecontacted,
                                                this.props.lang.writeNotesOrQuestions,
                                                true,
                                                // @ts-ignore
                                                (event: any): void => this.updateMessageInput(event)
                                            );
                                            this.props.setShowConfirmationModalAction(true);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    />
                    <HubloJumbotron
                        title={this.props.lang.usefullInformations}
                        size="l"
                        innerJumbotron={
                            <div className="row">
                                <div className="col-12">
                                    <HubloFormInputWithLabelComponent
                                        name={this.props.lang.institutionName}
                                        type={"text"}
                                        placeholder={
                                            institution.name ? institution.name : this.props.lang.institutionName
                                        }
                                        value={
                                            this.props.isColumnChanged("name")
                                                ? (getFormDictionaryRecordsDict(
                                                      this.props.formDictionary,
                                                      EBackOfficeTableName.institution
                                                  )[0].name as string)
                                                : institution.name
                                                ? institution.name
                                                : ""
                                        }
                                        onChange={(value: any): void => {
                                            this.props.setFormKeyValueAction(
                                                "name",
                                                value,
                                                EBackOfficeTableName.institution,
                                                InstitutionColumns[1].type,
                                                0
                                            );
                                        }}
                                    />
                                    <HubloGoogleAddressAutoComplete
                                        label={this.props.lang.institutionAddress}
                                        placeholder={
                                            institution.address
                                                ? institution.address
                                                : this.props.lang.institutionAddress
                                        }
                                        handleChange={(value: any): void => {
                                            this.props.setFormKeyValueAction(
                                                "addressText",
                                                value,
                                                EBackOfficeTableName.institution,
                                                InstitutionColumns[4].type,
                                                0
                                            );
                                        }}
                                        onSelectAddress={(newValue: any) => {
                                            this.props.onSelectAddress(newValue);
                                        }}
                                        id={"autocomplete"}
                                    />
                                    <HubloFormInputWithLabelComponent
                                        name={this.props.lang.visibleMobileForWorkers}
                                        placeholder={
                                            institution.phone
                                                ? institution.phone
                                                : this.props.lang.visibleMobileForWorkers
                                        }
                                        value={
                                            this.props.isColumnChanged("phone")
                                                ? (getFormDictionaryRecordsDict(
                                                      this.props.formDictionary,
                                                      EBackOfficeTableName.institution
                                                  )[0].phone as string)
                                                : institution.phone
                                                ? institution.phone
                                                : ""
                                        }
                                        onChange={(value: any): void => {
                                            this.props.setFormKeyValueAction(
                                                "phone",
                                                value,
                                                EBackOfficeTableName.institution,
                                                InstitutionColumns[2].type,
                                                0
                                            );
                                        }}
                                    />
                                    <>
                                        <h3 className="label-element">{this.props.lang.timeCodePlural}</h3>
                                        <a
                                            className="text-12 link-form"
                                            onClick={() => {
                                                this.props.setConfirmationModalDataAction(
                                                    this.props.lang.addTimeCodeToCreateMission,
                                                    "",
                                                    (): void => this.props.setShowConfirmationModalAction(false),
                                                    (): void => {
                                                        this.props.setShowConfirmationModalAction(false);
                                                        this.props.adminWantsNewSchedule();
                                                    },
                                                    this.props.lang.cancel,
                                                    this.props.lang.askToBeRecontacted,
                                                    this.props.lang.WhichTimeCodeYouWantAdd,
                                                    false,
                                                    // @ts-ignore
                                                    (event: any): void => this.updateScheduleRequestInput(event)
                                                );
                                                this.props.setShowConfirmationModalAction(true);
                                            }}
                                        >
                                            {this.props.lang.youWantAddTimeCode}
                                        </a>
                                    </>
                                    <div className="inline-block data-element">{scheduleElements}</div>
                                    <>
                                        <h3 className="label-element">{this.props.lang.subtitleServices}</h3>
                                    </>
                                    <div className="inline-block data-element">{serviceElements}</div>
                                    <div>
                                        <h3 className="label-element">{this.props.lang.subtitleJobs}</h3>
                                    </div>
                                    <div className="inline-block data-element">{jobElements}</div>
                                    <div>
                                        <h3 className="label-element">{this.props.lang.speciaties}</h3>
                                    </div>
                                    <div className="inline-block data-element">{specialtyElements}</div>
                                    <MedgoLabelComponent
                                        className={EMedgoLabelClassName.medgoLabelFormLabel}
                                        text={this.props.lang.toModifyServiceListOrTimeCode}
                                    />
                                    <a className="link-form" href={"mailto:" + `${contactDetails.email}`}>
                                        {" "}
                                        {contactDetails.email}
                                    </a>
                                    <HubloTextAreaWithLabel
                                        label={this.props.lang.institutionPresentation}
                                        placeholder={this.props.lang.institutionPresentation}
                                        value={
                                            this.props.isColumnChanged("presentation")
                                                ? (getFormDictionaryRecordsDict(
                                                      this.props.formDictionary,
                                                      EBackOfficeTableName.institution
                                                  )[0].presentation as string)
                                                : institution.presentation
                                                ? institution.presentation
                                                : ""
                                        }
                                        onChange={(value: any): void => {
                                            this.props.setFormKeyValueAction(
                                                "presentation",
                                                value,
                                                EBackOfficeTableName.institution,
                                                InstitutionColumns[3].type,
                                                0
                                            );
                                        }}
                                    />
                                    <HubloTextAreaWithLabel
                                        label={this.props.lang.accessInformation}
                                        placeholder={this.props.lang.accessInformation}
                                        value={
                                            this.props.isColumnChanged("accessInfo")
                                                ? (getFormDictionaryRecordsDict(
                                                      this.props.formDictionary,
                                                      EBackOfficeTableName.institution
                                                  )[0].accessInfo as string)
                                                : institution.accessInfo
                                                ? institution.accessInfo
                                                : ""
                                        }
                                        onChange={(value: any): void => {
                                            this.props.setFormKeyValueAction(
                                                "accessInfo",
                                                value,
                                                EBackOfficeTableName.institution,
                                                InstitutionColumns[5].type,
                                                0
                                            );
                                        }}
                                    />
                                    <HubloErrorMessageInfoBox
                                        lang={this.props.lang}
                                        infoMessage={this.props.infoMessage}
                                        errorMessage={this.props.errorMessage}
                                        isLoading={this.props.isLoading}
                                        nextPage={this.props.saveChanges}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            );
        } else {
            return <></>;
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionView => ({
        currentLang: centralState.language.currentLang,
        lang: centralState.language.lang,
        me: centralState.auth.user,
        formDictionary: centralState.form.formDictionary,
        institutionsById: centralState.institutions.byId,
        idInstitutions: centralState.institutions.ids,
        idSelectedInstitution: centralState.institutions.selectedId,
        jobsById: centralState.jobs.byId,
        idJobs: centralState.jobs.ids,
        scheduleDict: centralState.schedule.byId,
        idSchedules: centralState.schedule.ids,
        servicesById: centralState.services.byId,
        idServices: centralState.services.ids,
        idSpecialties: centralState.specialties.ids,
        specialtyDict: centralState.specialties.byId,
        confirmationModalData: centralState.form.confirmationModalData,
        futureSelectedInstitution: centralState.form.futureSelectedInstitution
    }),
    {
        setFormKeyValueAction,
        setShowConfirmationModalAction,
        setConfirmationModalDataAction,
        setFormScheduleRequestContentAction,
        setFormMessageContentAction,
        setFormFutureSelectedInstitutionAction
    }
)(InstitutionView);
