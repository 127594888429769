import { IReduxAction } from "../store.types";
import FormActionTypes from "../type/form.type";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnType, EBackOfficeColumnName } from "../../constants/Column.constants";
import { EButtonMedgoActionClassName } from "@hublo/legacy-components";

export function setFormKeyValueAction(
    key: string,
    value: any,
    table: EBackOfficeTableName,
    columnType: EBackOfficeColumnType,
    index: number
): (dispatch: (x: IReduxAction) => void) => void {
    switch (columnType) {
        case EBackOfficeColumnType.number:
            value = parseInt(value);
            break;
        case EBackOfficeColumnType.boolean:
            value = value === "true";
            break;
    }
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_KEY_VALUE_FORM_DICTIONARY,
            payload: {
                key,
                value,
                table,
                index
            }
        });
    };
}

export function setFormStatusAction(formStatus: any): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_FORM_STATUS,
            payload: formStatus
        });
    };
}

export function setFormDictionaryAction(formDictionary: any): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_FORM_DICTIONARY,
            payload: formDictionary
        });
    };
}

export function setFormMessageContentAction(messageContent: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_MESSAGE_CONTENT,
            payload: messageContent
        });
    };
}

export function setFormFutureSelectedInstitutionAction(
    futureSelectedInstitution: any
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_FUTURE_SELECTED_INSTITUTION,
            payload: futureSelectedInstitution
        });
    };
}

export function setFormScheduleRequestContentAction(
    scheduleRequestContent: string
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_SCHEDULE_REQUEST_CONTENT,
            payload: scheduleRequestContent
        });
    };
}

export function setFormIsLoadingAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_IS_LOADING,
            payload: isLoading
        });
    };
}

export function setResetFormAction(): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_RESET_FORM
        });
    };
}

export function setErrorMessageAction({
    table,
    index,
    column,
    message
}: {
    table: EBackOfficeTableName;
    index: number;
    column: EBackOfficeColumnName;
    message: string;
}): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_ERROR_MESSAGE,
            payload: {
                table,
                column,
                index,
                message
            }
        });
    };
}

export function resetErrorMessageAction(
    table: EBackOfficeTableName,
    index: number,
    column: EBackOfficeColumnName
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.RESET_ERROR_MESSAGE,
            payload: {
                table,
                index,
                column
            }
        });
    };
}

export function setShowCreateOrModifyModalAction(
    showCreateOrModifyModal: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_CREATE_OR_MODIFY_SHOW,
            payload: showCreateOrModifyModal
        });
    };
}

export function setShowConfirmationImportCSVModalAction(
    showConfirmationImportCSVModal: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_CONFIRMATION_CSV_SHOW,
            payload: showConfirmationImportCSVModal
        });
    };
}

export function setShowConfirmationModalAction(
    showConfirmationModal: boolean,
    isDeleteConfirmation: boolean = false
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_CONFIRMATION_SHOW,
            payload: { showConfirmationModal, isDeleteConfirmation }
        });
    };
}

export function setConfirmationModalDataAction(
    subtitle: string,
    text: string,
    onClick1: () => void,
    onClick2: () => void,
    textButton1: string,
    textButton2: string,
    placeholder?: string,
    isSimpleMessage?: boolean,
    handleChange?: (event: any) => void,
    colorButton2?: EButtonMedgoActionClassName
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_CONFIRMATION_PARAMS,
            payload: {
                subtitle,
                text,
                onClick1,
                onClick2,
                textButton1,
                textButton2,
                placeholder,
                isSimpleMessage,
                handleChange,
                colorButton2
            }
        });
    };
}

export function setNumberFormsAction(
    numberForms: number,
    backOfficeTableName: EBackOfficeTableName
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_NUMBER_FORMS,
            payload: { numberForms, backOfficeTableName }
        });
    };
}

export function deleteFormLineAction(
    formIndex: number,
    backOfficeTableName: EBackOfficeTableName
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.DELETE_FORM_LINE,
            payload: { formIndex, backOfficeTableName }
        });
    };
}

export function setFormTableAction(
    backOfficeTableName: EBackOfficeTableName
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_FORM_TABLE,
            payload: backOfficeTableName
        });
    };
}

export function setIdDetailedElementAction(idDetailedElement: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_ID_DETAILED_ELEMENT,
            payload: idDetailedElement
        });
    };
}

export function setInitialFormAction(initialForm: {
    [key: string]: any;
}): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_INITIAL_FORM,
            payload: initialForm
        });
    };
}

export function setIsLoadingFormAction(isLoadingForm: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_IS_LOADING_FORM,
            payload: isLoadingForm
        });
    };
}

export function setIsCreationOrUpdateLoadingAction(
    isCreationOrUpdateLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: FormActionTypes.SET_IS_CREATION_OR_UPDATE_LOADING,
            payload: isCreationOrUpdateLoading
        });
    };
}
