/* eslint-disable react/prop-types */
import { ISelectLabelValue, SelectComponent } from "npm-medgo-components";
import React from "react";
import { ILang } from "../../../lang/Lang";

interface SsoTypeSelectorProps {
    onChange: (value: ISelectLabelValue) => void;
    isEnabled: boolean;
    value: string;
    lang: ILang;
}

export const SsoTypeSelect: React.FC<SsoTypeSelectorProps> = ({ onChange, isEnabled, value, lang }): JSX.Element => {
    if (!isEnabled) return <div className="col-sm text-right">...</div>;

    return (
        <div className="col-sm text-right" style={{ paddingRight: 0 }}>
            <SelectComponent
                isLoading={!isEnabled}
                simpleValue
                options={[
                    {
                        label: lang.ssoViaOAuth2,
                        value: "ssoViaOAuth2"
                    }
                ]}
                value={[{ label: (lang as any)[value], value }]}
                onChange={onChange}
                isSearchable={false}
            />
        </div>
    );
};
