import { Equality } from "npm-medgo-toolbox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IInstitutionHasSso } from "../../../../Model/InstitutionHasSso";
import {
    insertInstitutionHasSsoAction,
    updateInstitutionHasSsoAction
} from "../../../store/action/institutionHasSso.action";
import { SsoForm, ssoFormToSsoType } from "../data-mapping/ssoType.mapping";

interface SsoStateProps {
    isEnabled: boolean;
    handleSsoState: () => void;
}

export const useSsoState = (
    sso: IInstitutionHasSso | undefined,
    idInstitution: number,
    form: SsoForm
): SsoStateProps => {
    const dispatch = useDispatch();
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect((): void => {
        if (Equality.isNullOrUndefined(sso)) {
            setIsEnabled(false);
        } else {
            setIsEnabled((sso as IInstitutionHasSso).enabled);
        }
    }, [sso]);

    const handleSsoState = (): void => {
        setIsEnabled((prev): boolean => !prev);
        if (Equality.isNullOrUndefined(sso)) {
            dispatch(
                insertInstitutionHasSsoAction({
                    enabled: true,
                    idInstitution,
                    shouldPropagateToGroup: true,
                    ssoType: ssoFormToSsoType(form)
                })
            );
        } else {
            const data = sso as IInstitutionHasSso;
            dispatch(
                updateInstitutionHasSsoAction(data.id, {
                    ...data,
                    enabled: !isEnabled,
                    shouldPropagateToGroup: true
                })
            );
        }
    };

    return {
        isEnabled,
        handleSsoState
    };
};
