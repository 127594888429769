enum FormActionTypes {
    SET_FORM_DICTIONARY = "SET_FORM_DICTIONARY",
    SET_KEY_VALUE_FORM_DICTIONARY = "SET_KEY_VALUE_FORM_DICTIONARY",
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_FORM_STATUS = "SET_FORM_STATUS",
    SET_RESET_FORM = "SET_RESET_FORM",
    SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
    RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE",
    SET_CREATE_OR_MODIFY_SHOW = "SET_CREATE_OR_MODIFY_SHOW",
    SET_CONFIRMATION_SHOW = "SET_CONFIRMATION_SHOW",
    SET_CONFIRMATION_PARAMS = "SET_CONFIRMATION_PARAMS",
    SET_NUMBER_FORMS = "SET_NUMBER_FORMS",
    DELETE_FORM_LINE = "DELETE_FORM_LINE",
    SET_MESSAGE_CONTENT = "SET_MESSAGE_CONTENT",
    SET_SCHEDULE_REQUEST_CONTENT = "SET_SCHEDULE_REQUEST_CONTENT",
    SET_FUTURE_SELECTED_INSTITUTION = "SET_FUTURE_SELECTED_INSTITUTION",
    SET_FORM_TABLE = "SET_FORM_TABLE",
    SET_ID_DETAILED_ELEMENT = "SET_ID_DETAILED_ELEMENT",
    SET_CONFIRMATION_CSV_SHOW = "SET_CONFIRMATION_CSV_SHOW",
    SET_INITIAL_FORM = "SET_INITIAL_FORM",
    SET_IS_LOADING_FORM = "SET_IS_LOADING_FORM",
    SET_IS_CREATION_OR_UPDATE_LOADING = "SET_IS_CREATION_OR_UPDATE_LOADING"
}
export default FormActionTypes;

export interface IFormDictionary {
    [tableName: string]: {
        [formIndex: number]: {
            [key: string]: any;
        };
    };
}
