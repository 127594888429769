import { IReduxAction } from "../store.types";
import LangTypes from "../type/lang.type";
import { AvailableLangsMomentCorrespondance } from "../reducer/lang.reducer";

export function setLangAction(
    lang: string = "fr",
    availableLangsMomentCorrespondance: any = AvailableLangsMomentCorrespondance
): (dispatch: (action: IReduxAction) => void) => void {
    let language = availableLangsMomentCorrespondance[lang]
        ? availableLangsMomentCorrespondance[lang]
        : availableLangsMomentCorrespondance["fr"];
    return (dispatch: (action: IReduxAction) => void): void => {
        require("moment/locale/".concat(language));
        localStorage.setItem("lang", lang);
        dispatch({
            type: LangTypes.SET_LANG,
            payload: lang
        });
    };
}
