import { IReduxAction, IReduxInitialState } from "../store.type";
import MotifTypes from "../type/motif.type";
import { IMotif } from "../../../Model/Motif";

const initialState: IReduxInitialState<IMotif> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false,
    errorCode: -1,
    allById: {},
    allIds: []
};

export default function motifReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MotifTypes.FETCH_MOTIFS:
        case MotifTypes.SET_MOTIFS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case MotifTypes.FETCH_ALL_MOTIFS: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case MotifTypes.SET_SELECTED_MOTIF: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case MotifTypes.SET_SELECTED_MOTIF_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case MotifTypes.SET_IS_LOADING_MOTIF: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case MotifTypes.SET_MOTIF_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case MotifTypes.SET_MOTIF_ERROR_CODE: {
            return {
                ...state,
                errorCode: action.payload
            };
        }

        case MotifTypes.UPDATE_MOTIF: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case MotifTypes.INSERT_INSTITUTION_HAS_MOTIF: {
            const motif = state.byId[action.payload.missionMotifCreation]
                ? state.byId[action.payload.missionMotifCreation]
                : state.allById[action.payload.missionMotifCreation];
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [motif.id]: {
                        ...motif,
                        institution: [...motif.institution, action.payload.institution]
                    }
                },
                ids: state.ids.includes(action.payload.missionMotifCreation)
                    ? [...state.ids]
                    : [...state.ids, action.payload.missionMotifCreation]
            };
        }

        default:
            return state;
    }
}
