import { connect } from "react-redux";
import { insertInstitutionHasMotifAction, fetchAllMotifsAction } from "../../store/action/motif.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { IMotif, ICreateMotifRequest } from "../../../Model/Motif";
import { IQueryParams } from "npm-medgo-query";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

interface IReduxPropsFormMotif extends IReduxPropsForm<{ motif: { label: string; value: number } }> {
    idAllMotifs: number[];
    selectedMulti: number[];
    idInstitutions: number[];
    motifs: IMotif;
}

interface IPropsFormMotif
    extends IReduxPropsFormMotif,
        IReduxActionsForm,
        IPropsForm<{ motif: { label: string; value: number } }> {}
interface IReduxActionsForm {
    insertInstitutionHasMotifAction: (createMotifRequest: ICreateMotifRequest) => void;
    fetchAllMotifsAction: (param: IQueryParams) => void;
    updateMotifAction: (id: number, motif: IMotif) => void;
}
class FormMotifComponent extends FormComponent<{ motif: { label: string; value: number } }, IPropsFormMotif> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllMotifs.length === 0) await this.props.fetchAllMotifsAction({ limit: 99999 });
    }
    async createElementAction(): Promise<void> {
        const formDictionaryMotifs = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.missionMotifCreation,
            {
                motif: {}
            }
        );
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionaryMotifs);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryMotif = formDictionaryMotifs[key];
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await Promise.all(
                                    formDictionaryMotif.name.map(
                                        async (motif: number): Promise<void> => {
                                            await this.props.insertInstitutionHasMotifAction({
                                                institution: idInstitution,
                                                missionMotifCreation: motif
                                            });
                                        }
                                    )
                                );
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        const motif = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.missionMotifCreation
        );
        await this.props.updateMotifAction(motif.id, motif as IMotif);
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.missionMotifCreation]: {
                [EBackOfficeColumnName.name]: getElementsForSelectComponent(
                    this.props.motifs,
                    this.props.selectedMulti.length > 0 ? this.props.selectedMulti : this.props.idInstitutions,
                    true,
                    false
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<ICreateMotifRequest> =>
        Object.assign(getConnectParentState(centralState), {
            selectedMulti: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids,
            motifs: centralState.motifs,
            idAllMotifs: centralState.motifs.allIds
        }),
    Object.assign(getConnectParentActions(), {
        insertInstitutionHasMotifAction,
        fetchAllMotifsAction
    })
)(FormMotifComponent);
