/* eslint-disable react/prop-types */
import React from "react";
import { ILang } from "../../../lang/Lang";
import "./SsoName.scss";

interface SsoNameProps {
    isEnabled: boolean;
    signupCode: string[];
    lang: ILang;
}

export const SsoName: React.FC<SsoNameProps> = ({ isEnabled, signupCode, lang }): JSX.Element | null => {
    if (isEnabled && signupCode?.length > 0)
        return (
            <div className="row text-center top-padding">
                <div className="col-sm">
                    <h4>{`${lang.ssoName} : ${signupCode[0]}`}</h4>
                </div>
            </div>
        );

    return null;
};
