import { IReduxAction, IReduxInitialState } from "../store.type";
import ScheduleTypes from "../type/schedule.type";
import { ISchedule } from "../../../Model/Schedule";

const initialState: IReduxInitialState<ISchedule> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function scheduleReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ScheduleTypes.FETCH_SCHEDULES:
        case ScheduleTypes.SET_SCHEDULES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }
        case ScheduleTypes.SET_SELECTED_SCHEDULE: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case ScheduleTypes.SET_SELECTED_SCHEDULE_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case ScheduleTypes.SET_IS_LOADING_SCHEDULE: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case ScheduleTypes.SET_SCHEDULE_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case ScheduleTypes.INSERT_SCHEDULE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case ScheduleTypes.UPDATE_SCHEDULE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }
        case ScheduleTypes.INSERT_INSTITUTION_HAS_SCHEDULE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idSchedule]: {
                        ...state.byId[action.payload.idSchedule],
                        institution: [
                            ...(state.byId[action.payload.idSchedule].institution || []),
                            action.payload.idInstitution
                        ]
                    }
                }
            };
        }
        default:
            return state;
    }
}
