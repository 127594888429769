//@ts-ignore
import * as clone from "clone";
import { IReduxAction, IReduxInitialState } from "../store.type";
import TemplateTypes from "../type/template.type";
import { ITemplate } from "../../../Model/Template";

const initialState: IReduxInitialState<ITemplate> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function templateReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case TemplateTypes.FETCH_TEMPLATES:
        case TemplateTypes.SET_TEMPLATES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case TemplateTypes.SET_SELECTED_TEMPLATE: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case TemplateTypes.SET_SELECTED_TEMPLATE_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case TemplateTypes.SET_IS_LOADING_TEMPLATE: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case TemplateTypes.SET_TEMPLATE_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case TemplateTypes.INSERT_TEMPLATE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case TemplateTypes.UPDATE_TEMPLATE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case TemplateTypes.DELETE_TEMPLATE: {
            let byId = clone(state.byId);
            let ids = clone(state.ids);
            delete byId[action.payload];
            ids.splice(state.ids.indexOf(action.payload), 1);
            return {
                ...state,
                byId,
                ids
            };
        }

        default:
            return state;
    }
}
