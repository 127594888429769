import React from "react";
import "../../scss/components/shared/LoadingContainer.scss";
export default function LoadingContainer({
    isLoading,
    children,
    isFullPage = true
}: {
    isLoading: boolean;
    children?: JSX.Element[] | JSX.Element;
    isFullPage?: boolean;
}): JSX.Element {
    return (
        <div>
            {isLoading ? <div className={"loader ".concat(isFullPage ? "full-page-loader" : "inline-loader")} /> : null}
            {children && <div className={"loading-element " + (isLoading ? "onload" : "")}>{children}</div>}
        </div>
    );
}
