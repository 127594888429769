import { shouldDisplayInterface } from "../../../toolbox/permissions";
import store from "../../store";
import { getModelsFromDict } from "../../modules/store/util";
import { IInstitution } from "../../../Model/Institution";
import { getLang } from "../../modules/lang";

export function generateScheduleCSVTemplate(): string[][] {
    const state = store.getState();
    const lang = getLang();
    const template = [
        [lang.code, lang.beginAt, lang.endAt, lang.break],
        ["A1", "hh:mm", "hh:mm", "hh:mm"]
    ];
    if (state.institutions !== null && state.institutions !== undefined) {
        const institutionsStore = (state.institutions as unknown) as { byId: Record<string, any>; ids: number[] };
        const institutionArray = getModelsFromDict<IInstitution>(institutionsStore.byId, institutionsStore.ids);
        if (shouldDisplayInterface(institutionArray)) {
            template[0].push(lang.interfacingCode);
            template[1].push("AP25");
        }
    }
    return template;
}
