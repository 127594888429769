enum CounterActionTypes {
    FETCH_COUNTERS = "FETCH_COUNTERS",
    SET_COUNTERS = "SET_COUNTERS",
    FETCH_COUNTERS_COUNT = "FETCH_COUNTERS_COUNT",
    SET_SELECTED_COUNTER = "SET_SELECTED_COUNTER",
    SET_SELECTED_COUNTER_MULTI = "SET_SELECTED_COUNTER_MULTI",
    SET_IS_LOADING_COUNTER = "SET_IS_LOADING_COUNTER",
    SET_COUNTER_ORDER = "SET_COUNTER_ORDER",
    INSERT_COUNTER = "INSERT_COUNTER",
    UPDATE_COUNTER = "UPDATE_COUNTER",
    DELETE_COUNTER = "DELETE_COUNTER",
    SET_COUNTER_ERROR_CODE = "SET_COUNTER_ERROR_CODE"
}
export default CounterActionTypes;
