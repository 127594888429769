import { IReduxAction } from "../store.type";
import PayrollcodeTypes from "../type/payrollcode.type";
import {
    fetchPayrollcodes,
    fetchPayrollcodesCount,
    insertPayrollcode,
    updatePayrollcode,
    deletePayrollcode
} from "../../service/payrollcode.service";
import {
    IPayrollcode,
    INormalizedPayrollcodes,
    IDispatchedPayrollcode,
    INormalizedDispatchedPayrollcodes
} from "../../../Model/Payrollcode";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import moment from "moment";

export function fetchPayrollcodesAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const payrollcodes = await fetchPayrollcodes(query);
            const dispatchedPayrollcodes = payrollcodes.map(
                (payrollcode): IDispatchedPayrollcode => {
                    let dispatchedPayrollcode;
                    dispatchedPayrollcode = Object.assign(
                        {},
                        payrollcode,
                        {
                            beginAt: moment(payrollcode.beginAt, "X")
                                .utc()
                                .format("HH:mm")
                        },
                        {
                            endAt: moment(payrollcode.endAt, "X")
                                .utc()
                                .format("HH:mm")
                        }
                    );
                    return dispatchedPayrollcode;
                }
            );
            dispatch({
                type: PayrollcodeTypes.FETCH_PAYROLLCODES,
                payload: {
                    byId: dispatchedPayrollcodes.reduce(
                        (
                            p: INormalizedDispatchedPayrollcodes,
                            c: IDispatchedPayrollcode
                        ): INormalizedDispatchedPayrollcodes => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: dispatchedPayrollcodes.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchPayrollcodesCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchPayrollcodesCount(query);
            dispatch({
                type: PayrollcodeTypes.FETCH_PAYROLLCODES_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertPayrollcodeAction(payrollcode: IPayrollcode): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newPayrollcode = await insertPayrollcode(payrollcode);
            const dispatchedPayrollcode = Object.assign(
                {},
                newPayrollcode,
                {
                    beginAt: moment(newPayrollcode.beginAt, "X")
                        .utc()
                        .format("HH:mm")
                },
                {
                    endAt: moment(newPayrollcode.endAt, "X")
                        .utc()
                        .format("HH:mm")
                }
            );
            dispatch({
                type: PayrollcodeTypes.INSERT_PAYROLLCODE,
                payload: dispatchedPayrollcode
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updatePayrollcodeAction(
    id: number,
    payrollcode: IPayrollcode
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedPayrollcode = await updatePayrollcode(id, payrollcode);
            const dispatchedPayrollcode = Object.assign(
                {},
                updatedPayrollcode,
                {
                    beginAt: moment(updatedPayrollcode.beginAt, "X")
                        .utc()
                        .format("HH:mm")
                },
                {
                    endAt: moment(updatedPayrollcode.endAt, "X")
                        .utc()
                        .format("HH:mm")
                }
            );
            dispatch({
                type: PayrollcodeTypes.UPDATE_PAYROLLCODE,
                payload: dispatchedPayrollcode
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deletePayrollcodeAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deletePayrollcode(id);
            dispatch({
                type: PayrollcodeTypes.DELETE_PAYROLLCODE
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setPayrollcodesAction(dict: INormalizedPayrollcodes): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_PAYROLLCODES,
            payload: dict
        });
    };
}

export function setSelectedPayrollcodeAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_SELECTED_PAYROLLCODE,
            payload: id
        });
    };
}

export function setSelectedPayrollcodeMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_SELECTED_PAYROLLCODE_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingPayrollcodeAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_IS_LOADING_PAYROLLCODE,
            payload: isLoading
        });
    };
}
export function setPayrollcodeOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_PAYROLLCODE_ORDER,
            payload: idsSorted
        });
    };
}

export function setPayrollcodeErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PayrollcodeTypes.SET_PAYROLLCODE_ERROR_CODE,
            payload: errorCode
        });
    };
}
