import { ArrayToolbox } from "npm-hublo-toolbox";
import { IService } from "../Model/Service";

export function getElementsInInstitution(elements: any[], idSelectedMultiInstitutions: number[]): any[] {
    const isInstitutionANumber = elements[0] ? typeof elements[0].institution === "number" : false;
    if (!idSelectedMultiInstitutions || idSelectedMultiInstitutions.length === 0) {
        return elements;
    }
    if (isInstitutionANumber) {
        return elements.filter((e): boolean =>
            e.institution ? idSelectedMultiInstitutions.includes(e.institution) : false
        );
    }
    return elements.filter((e): boolean =>
        e.institution ? e.institution.some((id: number): boolean => idSelectedMultiInstitutions.includes(id)) : false
    );
}

export function getAssignmentInstitutionsInSelectedInstitution(
    institution: any[],
    idSelectedMultiInstitutions: number[]
): any[] {
    if (idSelectedMultiInstitutions.length === 0) {
        return institution;
    }
    return institution.filter((e): boolean => idSelectedMultiInstitutions.includes(e.id));
}

export function getNotificationServicesInPolesAndInstitutions(
    services: any[],
    idSelectedNotificationPoles: number[],
    idSelectedMultiInstitutions: number[]
): any[] {
    const servicesInInstitutions = getElementsInInstitution(services, idSelectedMultiInstitutions);
    if (idSelectedNotificationPoles.length === 0) {
        return servicesInInstitutions;
    }
    return servicesInInstitutions.filter((e): boolean =>
        e.pole ? idSelectedNotificationPoles.includes(e.pole) : false
    );
}

export function getPolesOfNotificationServices(
    selectCheckboxListOptionsServices: IService[],
    selectedServices: number[]
): number[] {
    const poles = selectCheckboxListOptionsServices.map((e): any => {
        if (e.pole && selectedServices.includes(e.id)) return e.pole;
    });
    return ArrayToolbox.removeDuplicateInArray(poles).filter(e => e !== undefined);
}
