import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionOptionContractTypes from "../type/institutionOptionContract.type";
import { IInstitutionOptionContract } from "../../../Model/InstitutionOptionContract";

const initialState: IReduxInitialState<IInstitutionOptionContract> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionOptionContractReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOptionContractTypes.FETCH_INSTITUTIONOPTIONCONTRACTS:
        case InstitutionOptionContractTypes.SET_INSTITUTIONOPTIONCONTRACTS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] };

        case InstitutionOptionContractTypes.FETCH_INSTITUTIONOPTIONCONTRACTS_COUNT:
            return { ...state, count: action.payload };

        case InstitutionOptionContractTypes.SET_SELECTED_INSTITUTIONOPTIONCONTRACT:
            return { ...state, selectedId: action.payload };

        case InstitutionOptionContractTypes.SET_SELECTED_INSTITUTIONOPTIONCONTRACT_MULTI:
            return { ...state, selectedMulti: action.payload };

        case InstitutionOptionContractTypes.SET_IS_LOADING_INSTITUTIONOPTIONCONTRACT:
            return { ...state, isLoading: action.payload };

        case InstitutionOptionContractTypes.SET_INSTITUTIONOPTIONCONTRACT_ORDER:
            return { ...state, ids: action.payload };

        case InstitutionOptionContractTypes.INSERT_INSTITUTIONOPTIONCONTRACT: {
            return {
                ...state,
                byId: {
                    [action.payload.institution]: {
                        ...action.payload
                    }
                },
                ids: [action.payload.institution]
            };
        }

        case InstitutionOptionContractTypes.UPDATE_INSTITUTIONOPTIONCONTRACT: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.institution]: {
                        ...state.byId[action.payload.institution],
                        ...action.payload
                    }
                }
            };
        }
        default:
            return state;
    }
}
