export const PossibleApis = {
    medgoApi: process.env.REACT_APP_SERVICE_API_URL || "",
    hubloServiceAuth: process.env.REACT_APP_SERVICE_AUTH_URL || "",
    medgoBase: process.env.REACT_APP_MEDGO_URL || "",
    medgoContract: process.env.REACT_APP_CONTRACT_URL || "",
    hubloCounter: process.env.REACT_APP_COUNTER_URL || "",
    hubloCounterV2: process.env.REACT_APP_COUNTER_V2_URL || "",
    hubloMission: process.env.REACT_APP_MISSION_URL || "",
    hubloExport: process.env.REACT_APP_EXPORT_URL || "",
    hubloMessaging: process.env.REACT_APP_SERVICE_MESSAGING_URL || "",
    monorepoAuthentication: process.env.REACT_APP_SERVICE_MONOREPO_AUTHENTICATION_URL || "",
    bffBackoffice: process.env.REACT_APP_BFF_BACKOFFICE_BASE_URL || "",
    bffAdmin: process.env.REACT_APP_BFF_ADMIN_URL || "",
    featureFlags: process.env.REACT_APP_FEATURE_FLAGS_URL || "",
    institution: process.env.REACT_APP_INSTITUTION_URL || ""
};
export enum PossibleFetchs {
    missionPeriod = "mission-period/fetch",
    institution = "institution/fetch",
    institutionGroup = "institution-group/fetch",
    schedule = "schedule/fetch",
    job = "job/fetch",
    service = "service/fetch",
    admin = "admin/fetch",
    adminInInstitutionNotificationService = "admin-in-instituion-notification-service/fetch",
    level = "level/fetch",
    specialty = "specialty/fetch",
    institutionOctime = "institution-octime/fetch",
    motif = "mission-motif-creation/fetch",
    infoOnWorker = "info-on-worker/fetch",
    specialHour = "special-hour/fetch",
    specialHourHasJob = "special-hour-has-job/fetch",
    specialHourHasService = "special-hour-has-service/fetch",
    institutionChronos = "institution-chronos/fetch",
    interimAgency = "interim-agency/fetch",
    interimAgencyHasJob = "interim-agency-has-job/fetch",
    interimAgencyHasSpecialty = "interim-agency-has-specialty/fetch",
    contractSalaryGrid = "contract-salary-grid/fetch",
    institutionHasContractSalaryGrid = "institution-has-contract-salary-grid/fetch",
    template = "template/fetch",
    notificationContract = "notification-contract/fetch",
    institutionHasNotificationContract = "institution-has-notification-contract/fetch",
    pole = "pole/fetch",
    institutionGTA = "institution-gta/fetch",
    institutionHasSchedule = "institution-has-schedule/fetch",
    counter = "counter/fetch",
    mission = "mission/fetch",
    payrollcode = "payrollcode/fetch",
    export = "export/fetch",
    publicholiday = "publicholiday/fetch",
    institutionHasAdmin = "institution-has-admin/fetch",
    signUpCode = "signup-code/fetch",
    institutionOption = "institution-option/fetch",
    institutionHasSso = "institution-has-sso/fetch",
    institutionHasOAuth2 = "institution-has-oauth2/fetch",
    institutionInfo = "institution-info/fetch",
    adminInfo = "admin-info/fetch",
    institutionOptionContract = "institution-option-contract/fetch",
    institutionOptionChurn = "institution-option-churn/fetch",
    institutionOptionMission = "institution-option-mission/fetch",
    institutionOptionWorker = "institution-option-worker/fetch",
    shiftTemplate = "shift-template/fetch",
    notificationAdmin = "notification-admin/fetch",
    slotConfig = "slot-config"
}
export const FetchLimits = {
    schedules: 100,
    services: 100,
    jobs: 100,
    specialties: 100,
    sso: 100,
    admin: 1
};
export enum PossibleUpdates {
    institution = "institution",
    institutionGroup = "institution-group",
    job = "institution-has-job",
    schedule = "schedule",
    institutionHasService = "institution-has-service",
    service = "service",
    admin = "admin",
    institutionOctime = "institution-octime",
    level = "level",
    specialty = "institution-has-specialty",
    infoOnWorker = "info-on-worker",
    specialHour = "special-hour",
    institutionChronos = "institution-chronos",
    interimAgency = "interim-agency",
    interimAgencyHasJob = "interim-agency-has-job",
    interimAgencyHasSpecialty = "interim-agency-has-specialty",
    motif = "institution-has-mission-motif-creation",
    contractSalaryGrid = "institution-has-contract-salary-grid",
    template = "template",
    notificationContract = "notification-contract",
    institutionHasNotificationContract = "institution-has-notification-contract",
    pole = "pole",
    institutionHasSchedule = "institution-has-schedule",
    counter = "counter",
    payrollcode = "payrollcode",
    export = "export",
    publicholiday = "publicholiday",
    mission = "mission",
    institutionOption = "institution-option",
    institutionHasSso = "institution-has-sso",
    institutionHasOAuth2 = "institution-has-oauth2",
    institutionInfo = "institution-info",
    adminInfo = "admin-info",
    institutionOptionContract = "institution-option-contract",
    institutionOptionChurn = "institution-option-churn",
    institutionOptionMission = "institution-option-mission",
    institutionOptionWorker = "institution-option-worker",
    shiftTemplate = "shift-template",
    institutionHasAdmin = "institution-has-admin",
    notificationAdmin = "notification-admin",
    slotConfig = "slot-config"
}
export enum PossiblePosts {
    institution = "institution",
    institutionHasSchedule = "institution-has-schedule",
    batchInstitutionHasSchedule = "institution-has-schedule/batch",
    institutionGroup = "institution-group",
    job = "institution-has-job",
    schedule = "schedule",
    upsertBatchSchedule = "schedule/upsert-batch",
    institutionHasService = "institution-has-service",
    service = "service",
    admin = "admin",
    institutionHasAdmin = "institution-has-admin",
    adminInfo = "admin-info",
    adminInInstitutionNotificationService = "admin-in-instituion-notification-service",
    level = "level",
    institutionOctime = "institution-octime",
    specialty = "institution-has-specialty",
    infoOnWorker = "info-on-worker",
    institutionHasInfoOnWorker = "institution-has-info-on-worker",
    specialHour = "special-hour",
    specialHourHasService = "special-hour-has-service",
    specialHourHasJob = "special-hour-has-job",
    institutionChronos = "institution-chronos",
    interimAgency = "interim-agency",
    interimAgencyHasJob = "interim-agency-has-job",
    interimAgencyHasSpecialty = "interim-agency-has-specialty",
    motif = "institution-has-mission-motif-creation",
    contractSalaryGrid = "institution-has-contract-salary-grid",
    template = "template",
    notificationContract = "notification-contract",
    institutionHasNotificationContract = "institution-has-notification-contract",
    pole = "pole",
    counter = "counter",
    payrollcode = "payrollcode",
    export = "export",
    publicholiday = "publicholiday",
    institutionOption = "institution-option",
    institutionHasSso = "institution-has-sso",
    institutionHasOAuth2 = "institution-has-oauth2",
    institutionInfo = "institution-info",
    institutionOptionContract = "institution-option-contract",
    institutionOptionChurn = "institution-option-churn",
    institutionOptionMission = "institution-option-mission",
    institutionOptionWorker = "institution-option-worker",
    shiftTemplate = "shift-template",
    notificationAdmin = "notification-admin"
}
export enum PossibleDeletes {
    institution = "institution",
    institutionGroup = "institution-group",
    job = "institution-has-job",
    schedule = "schedule",
    institutionHasService = "institution-has-service",
    service = "service",
    admin = "admin",
    adminInInstitutionNotificationService = "admin-in-instituion-notification-service",
    level = "level",
    institutionOctime = "institution-octime",
    specialty = "institution-has-specialty",
    infoOnWorker = "info-on-worker",
    specialHour = "special-hour",
    specialHourHasJob = "special-hour-has-job",
    specialHourHasService = "special-hour-has-service",
    institutionChronos = "institution-chronos",
    interimAgency = "interim-agency",
    interimAgencyHasJob = "interim-agency-has-job/:idInterimAgency/:idJob",
    interimAgencyHasSpecialty = "interim-agency-has-specialty/:idInterimAgency/:idSpecialty",
    motif = "institution-has-mission-motif-creation",
    contractSalaryGrid = "contract-salary-grid",
    institutionHasContractSalaryGrid = "institution-has-contract-salary-grid",
    template = "template",
    notificationContract = "notification-contract",
    institutionHasNotificationContract = "institution-has-notification-contract",
    pole = "pole",
    counter = "counter",
    payrollcode = "payrollcode",
    export = "export",
    publicholiday = "publicholiday",
    institutionOption = "institution-option",
    institutionHasSso = "institution-has-sso",
    institutionHasOAuth2 = "institution-has-oauth2",
    institutionInfo = "institution-info",
    adminInfo = "admin-info",
    institutionOptionContract = "institution-option-contract",
    institutionOptionChurn = "institution-option-churn",
    institutionOptionMission = "institution-option-mission",
    institutionOptionWorker = "institution-option-worker",
    institutionHasAdmin = "institution-has-admin",
    notificationAdmin = "notification-admin"
}
