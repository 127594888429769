import { IReduxAction } from "../store.types";
import AppTypes from "../type/app.type";

export function setIsAppReady(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AppTypes.SET_IS_APP_READY,
            payload: bool
        });
    };
}

export function setIsBackOfficePageReady(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AppTypes.SET_IS_BACKOFFICE_PAGE_READY,
            payload: bool
        });
    };
}
