enum InstitutionOptionChurnActionTypes {
    FETCH_INSTITUTIONOPTIONCHURNS = "FETCH_INSTITUTIONOPTIONCHURNS",
    SET_INSTITUTIONOPTIONCHURNS = "SET_INSTITUTIONOPTIONCHURNS",
    FETCH_INSTITUTIONOPTIONCHURNS_COUNT = "FETCH_INSTITUTIONOPTIONCHURNS_COUNT",
    SET_SELECTED_INSTITUTIONOPTIONCHURN = "SET_SELECTED_INSTITUTIONOPTIONCHURN",
    SET_SELECTED_INSTITUTIONOPTIONCHURN_MULTI = "SET_SELECTED_INSTITUTIONOPTIONCHURN_MULTI",
    SET_IS_LOADING_INSTITUTIONOPTIONCHURN = "SET_IS_LOADING_INSTITUTIONOPTIONCHURN",
    SET_INSTITUTIONOPTIONCHURN_ORDER = "SET_INSTITUTIONOPTIONCHURN_ORDER",
    INSERT_INSTITUTIONOPTIONCHURN = "INSERT_INSTITUTIONOPTIONCHURN",
    UPDATE_INSTITUTIONOPTIONCHURN = "UPDATE_INSTITUTIONOPTIONCHURN",
    DELETE_INSTITUTIONOPTIONCHURN = "DELETE_INSTITUTIONOPTIONCHURN",
    SET_INSTITUTIONOPTIONCHURN_ERROR_CODE = "SET_INSTITUTIONOPTIONCHURN_ERROR_CODE"
}
export default InstitutionOptionChurnActionTypes;
