const INSTITUTION_PATTERN = "Hublo::Match::Admin::Institution:__INSTITUTION_ID__";

export enum Permission {
    ACCESS_SETTINGS = "accessSettings",
    CHANGE_SERVICE = "changeService",
    BROADCAST_SHIFT = "broadcastShift",
    SEE_PRO_INSTITUTION_CONFIG_PAGE = "seeProInstitutionConfigPage",
    SEE_PRO_INSTITUTION_PAGE = "seeProInstitutionPage",
    SEE_STATISTICS_PAGE = "seeStatisticsPage",
    CANCEL_MISSION_AFTER_VALIDATION = "cancelMissionAfterValidation",
    VALIDATE_MISSION_REQUEST_WITHOUT_WORKER = "validateMissionRequestWithoutWorker",
    VALIDATE_MISSION_REQUEST_WITH_WORKER = "validateMissionRequestWithWorker"
}

export type UserPermissions = Record<string, Permission[]>;

const institutionScope = (institutionId: number): string => {
    return INSTITUTION_PATTERN.replace("__INSTITUTION_ID__", institutionId.toString());
};

const hasPermissionWithinInstitution = (
    permission: Permission,
    permissions: UserPermissions,
    institutionId: number
): boolean => {
    if (typeof institutionId === "undefined") {
        return false;
    }
    return permissions[institutionScope(institutionId)]?.includes(permission);
};

export const isAllowedTo = ({
    institutionIds,
    permission,
    permissions
}: {
    institutionIds?: number[];
    permission: Permission;
    permissions?: UserPermissions;
}): boolean => {
    if (!permissions || !institutionIds) {
        return false;
    }
    return institutionIds.some(id => hasPermissionWithinInstitution(permission, permissions, id));
};

export const buildTargetPermissionsResult = <TargetPermissionType extends Permission>({
    targetPermissions,
    userPermissions,
    institutionIds
}: {
    userPermissions?: UserPermissions;
    institutionIds?: number[];
    targetPermissions: TargetPermissionType[];
}): { [permissionName in TargetPermissionType]?: boolean } => {
    return targetPermissions.reduce((acc, permission) => {
        return {
            ...acc,
            [permission]: isAllowedTo({
                institutionIds,
                permissions: userPermissions,
                permission
            })
        };
    }, {});
};
