import AxiosInstance from "../../modules/axios/axios.instance";

import { IFeatureFlagsResponse } from "./../type/featureFlag.type";

const serviceFeatureToggleUrl = `${process.env.REACT_APP_SERVICE_FEATURE_TOGGLES_URL}/flags`;

export const getFeatureFlags = async (userAgent?: string): Promise<IFeatureFlagsResponse> => {
    const result = await AxiosInstance.post<IFeatureFlagsResponse>(
        serviceFeatureToggleUrl,
        userAgent
            ? {
                  userAgent
              }
            : {}
    );
    return result.data;
};
