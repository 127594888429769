import React from "react";
import { useSelector } from "react-redux";

import { HubloJumbotron, HubloErrorMessageInfoBox } from "@hublo/legacy-components";

import { INotificationAdmin } from "../../../../Model/NotificationAdmin";
import { langSelector, meSelector } from "../../../store/selectors";
import { getCategoryElementNotifications } from "./NotificationActivationElement.view";
import { IInstitutionOption } from "../../../../Model/InstitutionOption";
import { ILevel } from "../../../../Model/Level";

interface IReduxPropsNotificationsView {
    institutionOptions: IInstitutionOption;
    adminPermissions: ILevel;
}

interface IPropsNotificationsView extends IReduxPropsNotificationsView {
    infoMessageNotif: string;
    errorMessageNotif: string;
    isLoadingNotif: boolean;
    didLoadNotif: boolean;
    notificationAdmin: INotificationAdmin;
    handleSubmitNotification: () => void;
    onSelectNotif: (whichNotif: string, val: number) => void;
}

const NotificationActivationsView = (props: IPropsNotificationsView) => {
    const [lang] = useSelector(langSelector);
    const me = useSelector(meSelector);

    const {
        category1Notif,
        category2Notif,
        category3Notif,
        category4Notif,
        category5Notif
    } = getCategoryElementNotifications(
        props.notificationAdmin,
        props.onSelectNotif,
        lang,
        props.adminPermissions,
        props.institutionOptions,
        me
    );

    return (
        <HubloJumbotron
            title={lang.manageNotifications}
            size="l"
            innerJumbotron={
                <div>
                    <div>
                        <h3>{lang.notifAboutWorker}</h3>
                    </div>
                    <div>
                        <h3>{lang.wantNotifWhenWorker}</h3>
                        <div className="container-fluid">{category1Notif}</div>
                    </div>
                    <div>
                        <h3>{lang.notifAboutMissions}</h3>
                    </div>
                    <div>
                        <h3>{lang.wantNotifWhenAdmin}</h3>
                        <div className="container-fluid">{category2Notif}</div>
                    </div>
                    <div>
                        <h3>{lang.wantNotifWhenMission}</h3>
                        <div className="container-fluid">{category3Notif}</div>
                    </div>
                    <div>
                        <h3>{lang.wantNotifWhenWorker}</h3>
                        <div className="container-fluid">{category5Notif}</div>
                    </div>
                    <div>
                        <h3>{lang.wantNotifWhen}</h3>
                        <div className="container-fluid">{category4Notif}</div>

                        <HubloErrorMessageInfoBox
                            infoMessage={props.infoMessageNotif}
                            errorMessage={props.errorMessageNotif}
                            isLoading={props.isLoadingNotif}
                            nextPage={props.handleSubmitNotification}
                            lang={lang}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default NotificationActivationsView;
