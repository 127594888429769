import { IReduxAction } from "../store.types";
import AppTypes from "../type/app.type";
const initialState: any = {
    isLoadingApp: true,
    isLoadingBackOfficePage: true
};
export default function appReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AppTypes.SET_IS_APP_READY:
            return {
                ...state,
                isLoadingApp: action.payload
            };
        case AppTypes.SET_IS_BACKOFFICE_PAGE_READY:
            return {
                ...state,
                isLoadingBackOfficeBase: action.payload
            };
        default:
            return state;
    }
}
