import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionGroupTypes from "../type/institutionGroup.type";
import { IInstitutionGroup } from "../../../Model/InstitutionGroup";

const initialState: IReduxInitialState<IInstitutionGroup> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionGroupReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionGroupTypes.FETCH_INSTITUTIONGROUPS:
        case InstitutionGroupTypes.SET_INSTITUTIONGROUPS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InstitutionGroupTypes.SET_SELECTED_INSTITUTIONGROUP: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InstitutionGroupTypes.SET_SELECTED_INSTITUTIONGROUP_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InstitutionGroupTypes.SET_IS_LOADING_INSTITUTIONGROUP: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InstitutionGroupTypes.SET_INSTITUTIONGROUP_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        default:
            return state;
    }
}
