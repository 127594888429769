import { connect } from "react-redux";
import {
    fetchInstitutionOctimesAction,
    setInstitutionOctimesAction,
    setIsLoadingInstitutionOctimeAction
} from "../../store/action/institutionOctime.action";

import { IInstitutionOctime } from "../../../Model/InstitutionOctime";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOctime extends IReduxPropsTable {
    idInstitutionOctime: number[];
    institutionOctimeDict: IInstitutionOctime[];
}

interface IPropsTableInstitutionOctime extends IReduxPropsInstitutionOctime, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setInstitutionOctimesAction: (elements: IInstitutionOctime[]) => any;
    fetchInstitutionOctimesAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOctimeAction: (isLoading: boolean) => any;
}

class TableInstitutionOctimeComponent extends TableComponent<IPropsTableInstitutionOctime> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOctimeAction(true);
        if (this.props.idInstitutionOctime.length === 0) await this.props.fetchInstitutionOctimesAction(params);
        this.props.setIsLoadingInstitutionOctimeAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOctimesAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOctimeDict, this.props.idInstitutionOctime);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOctime =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOctime: centralState.institutionOctime.ids,
            institutionOctimeDict: centralState.institutionOctime.byId,
            isLoading: centralState.institutionOctime.isLoading
        }),
    Object.assign(getConnectParentActions(), {
        fetchInstitutionOctimesAction,
        setInstitutionOctimesAction,
        setIsLoadingInstitutionOctimeAction
    })
)(TableInstitutionOctimeComponent);
