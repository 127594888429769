import { connect } from "react-redux";
import {
    setInterimAgencysAction,
    fetchInterimAgencysAction,
    setIsLoadingInterimAgencyAction,
    deleteInterimAgencyAction
} from "../../store/action/interimAgency.action";
import { fetchJobsAction } from "../../store/action/job.action";
import { fetchSpecialtiesAction } from "../../store/action/specialty.action";
import { IInterimAgency } from "../../../Model/InterimAgency";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInterimAgencys } from "../../../Model/InterimAgency";
import { getModelByIdFromDict, getModelsFromDict } from "../../modules/store/util";
import { ILang } from "../../lang/Lang";
import { INormalizedJobs } from "../../../Model/Job";

//@ts-ignore
interface IReduxPropsInterimAgency extends IReduxPropsTable {
    idInterimAgencys: number[];
    interimAgencyDict: INormalizedInterimAgencys;
    idSelectedInstitutions: number[];
    idJobs: number[];
    idSpecialties: number[];
    lang: ILang;
    jobDict: INormalizedJobs;
    idItemToBeDeleted: number;
}

interface IPropsInterimAgency extends IReduxPropsInterimAgency, IReduxActionsInterimAgency, IPropsTable {}

interface IReduxActionsInterimAgency extends IReduxActionsTable {
    setInterimAgencysAction: (elements: IInterimAgency[]) => any;
    fetchInterimAgencysAction: (param: IQueryParams) => any;
    fetchInterimAgencyHasJobsAction: (param: IQueryParams) => any;
    deleteInterimAgencyAction: (param: { idInstitution: number; idSubscription: string }) => Promise<void>;
    fetchJobsAction: (param: IQueryParams) => any;
    fetchSpecialtiesAction: (param: IQueryParams, jobDict: INormalizedJobs) => any;
    setIsLoadingInterimAgencyAction: (isLoading: boolean) => any;
}

class TableInterimAgencyContainer extends TableComponent<IPropsInterimAgency> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInterimAgencyAction(true);
        if (this.props.idInterimAgencys.length === 0) await this.props.fetchInterimAgencysAction(params);
        if (this.props.idJobs.length === 0)
            await this.props.fetchJobsAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        if (this.props.idSpecialties.length === 0)
            await this.props.fetchSpecialtiesAction(
                {
                    query: { institution: this.props.idInstitutions },
                    limit: 99999
                },
                this.props.jobDict
            );
        this.props.setIsLoadingInterimAgencyAction(false);
    }
    async onDeleteLineClick(): Promise<void> {
        this.props.setIsLoadingInterimAgencyAction(true);
        const agency = getModelByIdFromDict(this.props.interimAgencyDict, this.props.idItemToBeDeleted);
        await this.props.deleteInterimAgencyAction({
            idInstitution: agency.institution,
            idSubscription: agency.idSubscription
        });
        await this.props.fetchInterimAgencysAction({
            query: {
                institution: agency.institution
            }
        });
        this.props.setIsLoadingInterimAgencyAction(false);
    }
    setElements(elements: any): void {
        this.props.setInterimAgencysAction(elements);
    }

    getElements(): any[] {
        const allResultsElements = getModelsFromDict(this.props.interimAgencyDict, this.props.idInterimAgencys);
        allResultsElements.forEach((element: any) => {
            element.jobs = element.jobs ? element.jobs.filter((idJob: any): any => idJob !== null) : [];
            element.subtitleJobs =
                element.jobs.length === this.props.idJobs.length
                    ? `${this.props.lang.all}`
                    : element.jobs.length > 1
                    ? `${element.jobs.length} ${this.props.lang.jobs}`
                    : element.jobs.length === 1
                    ? `1 ${this.props.lang.job}`
                    : "";
            element.specialties = element.specialties
                ? element.specialties.filter((idSpecialty: any): any => idSpecialty !== null)
                : [];

            element.subtitleSpecialties =
                element.specialties.length === this.props.idSpecialties.length
                    ? `${this.props.lang.all}`
                    : element.specialties.length > 1
                    ? `${element.specialties.length} ${this.props.lang.specialties}`
                    : element.specialties.length === 1
                    ? `1 ${this.props.lang.specialty}`
                    : "";
        });
        return allResultsElements;
    }
}

export default connect(
    (centralState: any): IReduxPropsInterimAgency =>
        Object.assign(getConnectParentState(centralState), {
            idInterimAgencys: centralState.interimAgency.ids,
            interimAgencyDict: centralState.interimAgency.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.interimAgency.isLoading,
            idJobs: centralState.jobs.ids,
            idSpecialties: centralState.specialties.ids,
            jobDict: centralState.jobs.allById,
            lang: centralState.language.lang,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setInterimAgencysAction,
        fetchInterimAgencysAction,
        fetchJobsAction,
        fetchSpecialtiesAction,
        setIsLoadingInterimAgencyAction,
        deleteInterimAgencyAction
    })
)(TableInterimAgencyContainer);
