import { IMotif, ICreateMotifRequest } from "../../Model/Motif";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const MotifDefaultSelect = [
    "id",
    "name",
    "isNameAbsentPersonRequired",
    "nameAbsentPersonPlaceholderInfo",
    "interfacingCode",
    "missionmotifcreationcategory",
    "isMatriculeAbsentPersonDisplayed",
    "matriculeAbsentPersonPlaceholderInfo",
    "isMatriculeAbsentPersonRequired",
    "institution"
];

export async function fetchMotifs(query: IQueryParams): Promise<IMotif[]> {
    const motifs = await postFetchFactory<IMotif>(PossibleFetchs.motif, PossibleApis.medgoApi)(
        {
            select: MotifDefaultSelect
        },
        query
    );
    return motifs;
}

export async function insertInstitutionHasMotif(motif: ICreateMotifRequest): Promise<ICreateMotifRequest> {
    return await postFactory<ICreateMotifRequest>(PossiblePosts.motif, PossibleApis.medgoApi)(motif);
}

export async function updateMotif(id: number, motif: IMotif): Promise<IMotif> {
    return await updateFactory<IMotif>(PossibleUpdates.motif, PossibleApis.medgoApi)(id, motif);
}

export async function deleteMotif(id: number): Promise<IMotif> {
    return await deleteFactory<IMotif>(PossibleDeletes.motif, PossibleApis.medgoApi)(id);
}
