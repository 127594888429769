import { firstLetterUppercase } from "npm-medgo-toolbox/dist/modules/string";
import React from "react";
import { connect } from "react-redux";
import { IBackOfficeColumn } from "../../constants/Column.constants";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { setResetFormAction, setFormStatusAction } from "../../store/action/form.action";
import { setModifyingElementAction } from "../../store/action/table.action";
import { IFormDictionary } from "../../store/type/form.type";
import ConfirmationModal, { IConfirmationModalData } from "../Modals/Shared/Confirmation.modal";

interface IReduxActionElementDetails {
    setResetFormAction: () => void;
    setFormStatusAction: (formStatus: any) => any;
    setModifyingElementAction: (checkedElementDict: any) => void;
}

interface IReduxPropsElementDetails {
    lang: any;
    confirmationModalData: IConfirmationModalData;
    formDictionary: IFormDictionary;
    initialForm: {
        [key: string]: any;
    };
    isLoadingForm: boolean;
    isCreationOrUpdateLoading: boolean;
}

interface IPropsElementDetails extends IReduxPropsElementDetails, IReduxActionElementDetails {
    formView: any;
    backOfficeTableName: EBackOfficeTableName;
    checkFormDictionary: (isOnSave: boolean) => boolean;
    customInputs?: JSX.Element;
    columns: IBackOfficeColumn[];
}
export class ElementDetails extends React.Component<IPropsElementDetails> {
    render() {
        const isDisabled = this.isSaveButtonDisabled();
        return (
            <>
                <ConfirmationModal
                    lang={this.props.lang}
                    subtitle={this.props.confirmationModalData.subtitle}
                    text={this.props.confirmationModalData.text}
                    onClick1={this.props.confirmationModalData.onClick1}
                    onClick2={this.props.confirmationModalData.onClick2}
                    textButton1={this.props.confirmationModalData.textButton1}
                    textButton2={this.props.confirmationModalData.textButton2}
                    colorButton2={this.props.confirmationModalData.colorButton2}
                />
                <div className="card" style={{ width: "100%" }}>
                    {this.props.isLoadingForm ? (
                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <i className="fas fa-spinner fa-spin fa-10x"></i>
                        </div>
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    window.location.href = `#${this.props.backOfficeTableName}`;
                                    this.props.setResetFormAction();
                                    this.props.setModifyingElementAction(null);
                                }}
                                style={{ cursor: "pointer", marginBottom: "30px" }}
                            >
                                <i className="fas fa-arrow-circle-left" style={{ marginRight: "10px" }} />
                                {
                                    this.props.lang[
                                        `getBackTo${firstLetterUppercase(this.props.backOfficeTableName)}List`
                                    ]
                                }
                            </div>
                            {this.props.customInputs ? this.props.customInputs : null}
                            {this.props.formView}
                            <div>
                                <button
                                    className={`save-button-form ${isDisabled ? "is-disabled" : ""}`}
                                    onClick={(): void => {
                                        if (this.props.checkFormDictionary(true)) return;
                                        this.props.setFormStatusAction("update");
                                    }}
                                    disabled={isDisabled}
                                >
                                    {this.props.isCreationOrUpdateLoading ? (
                                        <span className="fas fa-spinner fa-lg fa-spin" />
                                    ) : (
                                        this.props.lang.save
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
    isSaveButtonDisabled() {
        const formDictionary = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            this.props.backOfficeTableName
        )[0];
        const initialForm = this.props.initialForm;
        const columns = this.props.columns.filter(
            (column: IBackOfficeColumn): boolean => column.shouldLookForDisabledSaveButton
        );
        if (formDictionary && initialForm) {
            for (let i in columns) {
                const column = columns[i];
                if (initialForm[column.name] !== formDictionary[column.name]) return false;
            }
        }
        return true;
    }
}

export default connect(
    (centralState: any): IReduxPropsElementDetails => ({
        lang: centralState.language.lang,
        confirmationModalData: centralState.form.confirmationModalData,
        formDictionary: centralState.form.formDictionary,
        initialForm: centralState.form.initialFormDictionary,
        isLoadingForm: centralState.form.isLoadingForm,
        isCreationOrUpdateLoading: centralState.form.isCreationOrUpdateLoading
    }),
    { setResetFormAction, setFormStatusAction, setModifyingElementAction }
)(ElementDetails);
