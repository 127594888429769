import { IReduxAction, IReduxInitialState } from "../store.type";
import InterimAgencyTypes from "../type/interimAgency.type";
import { IInterimAgency } from "../../../Model/InterimAgency";

const initialState: IReduxInitialState<IInterimAgency> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function interimAgencyReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InterimAgencyTypes.FETCH_INTERIMAGENCYS:
        case InterimAgencyTypes.SET_INTERIMAGENCYS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InterimAgencyTypes.SET_SELECTED_INTERIMAGENCY: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InterimAgencyTypes.SET_SELECTED_INTERIMAGENCY_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InterimAgencyTypes.SET_IS_LOADING_INTERIMAGENCY: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InterimAgencyTypes.SET_INTERIMAGENCY_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case InterimAgencyTypes.INSERT_INTERIMAGENCY: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case InterimAgencyTypes.UPDATE_INTERIMAGENCY: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }
        default:
            return state;
    }
}
