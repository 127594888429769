import { IReduxAction } from "../store.type";
import InterimAgencyTypes from "../type/interimAgency.type";
import {
    deleteInterimAgency,
    fetchInterimAgencys,
    insertInterimAgency,
    updateInterimAgency
} from "../../service/interimAgency.service";
import { IInterimAgency, INormalizedInterimAgencys } from "../../../Model/InterimAgency";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInterimAgencysAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const interimAgencys = await fetchInterimAgencys(query);
            dispatch({
                type: InterimAgencyTypes.FETCH_INTERIMAGENCYS,
                payload: {
                    byId: interimAgencys.reduce(
                        (p: INormalizedInterimAgencys, c: IInterimAgency): INormalizedInterimAgencys => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: interimAgencys.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInterimAgencyAction(
    interimAgency: IInterimAgency
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (_dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertInterimAgency(interimAgency);
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInterimAgencyAction(params: {
    id: number;
    idSubscription: string;
    idInstitution: number;
    interimAgency: IInterimAgency;
}): (dispatch: (x: IReduxAction) => void) => void {
    const { id, interimAgency, idInstitution, idSubscription } = params;
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateInterimAgency({
                id,
                interimAgency,
                idInstitution,
                idSubscription
            });
            dispatch({
                type: InterimAgencyTypes.UPDATE_INTERIMAGENCY,
                payload: interimAgency
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInterimAgencyAction(params: {
    idInstitution: number;
    idSubscription: string;
}): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInterimAgency(params);
            dispatch({
                type: InterimAgencyTypes.DELETE_INTERIMAGENCY
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInterimAgencysAction(
    dict: INormalizedInterimAgencys
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_INTERIMAGENCYS,
            payload: dict
        });
    };
}

export function setSelectedInterimAgencyAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_SELECTED_INTERIMAGENCY,
            payload: id
        });
    };
}

export function setSelectedInterimAgencyMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_SELECTED_INTERIMAGENCY_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInterimAgencyAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_IS_LOADING_INTERIMAGENCY,
            payload: isLoading
        });
    };
}
export function setInterimAgencyOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_INTERIMAGENCY_ORDER,
            payload: idsSorted
        });
    };
}

export function setInterimAgencyErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InterimAgencyTypes.SET_INTERIMAGENCY_ERROR_CODE,
            payload: errorCode
        });
    };
}
