import React, { Component } from "react";

import {
    setPageNumberAction,
    setModifyingElementAction,
    setSearchValueAction,
    resetSearchValueAction
} from "../../store/action/table.action";
import {
    setFormKeyValueAction,
    setNumberFormsAction,
    setShowCreateOrModifyModalAction,
    setFormTableAction,
    setInitialFormAction,
    setIsLoadingFormAction
} from "../../store/action/form.action";
import TableView from "./Table.view";
import { EBackOfficeColumnType } from "../../constants/Column.constants";
import { IBackOfficeTable, EBackOfficeTableName } from "../../constants/Table.constants";

//@ts-ignore
import * as clone from "clone";
import { IQueryParams } from "npm-medgo-query";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { putModifyingElementInForm } from "../../../toolbox/container/SharedFormFunctions";
import { ILevel } from "../../../Model/Level";
import { getModelsFromDict } from "../../modules/store/util";
import { INormalizedServices, IService } from "../../../Model/Service";

export interface IReduxPropsTable {
    pageNumber: number;
    modifyingElement: any;
    idSelectedMultiInstitutions: number[];
    idInstitutions: number[];
    selectedTab: number;
    levelsAllIds: number[];
    levelsAllById: ILevel[];
    servicesById: INormalizedServices;
    idServices: number[];
}

export interface IReduxActionsTable {
    setPageNumberAction: (pageNum: number) => any;
    setModifyingElementAction: (modifyingElement: any) => any;
    setFormStatusAction: (formStatus: any) => any;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    setSearchValueAction: (searchValue: string | null) => any;
    resetSearchValueAction: () => any;
    setNumberFormsAction: (numberForms: number) => void;
    setShowCreateOrModifyModalAction: (show: boolean) => void;
    setFormTableAction: (backofficeTableName: EBackOfficeTableName) => void;
    setInitialFormAction: (form: object) => void;
    setIsLoadingFormAction: (isLoadingForm: boolean) => void;
}
export interface IPropsTable extends IReduxPropsTable, IReduxActionsTable {
    backOfficeTable: IBackOfficeTable;
    count: number;
    selectCheckboxListOptions: () => any;
}

export class TableComponent<T extends IPropsTable> extends Component<T> {
    constructor(props: T) {
        super(props);
        this.props.resetSearchValueAction();
        this.onModifyLine = this.onModifyLine.bind(this);
        this.onDeleteLineClick = this.onDeleteLineClick.bind(this);
        this.selectCheckboxListOptions = this.selectCheckboxListOptions.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onValidateLineForm = this.onValidateLineForm.bind(this);
        this.onCancelledLineForm = this.onCancelledLineForm.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.fetchElements();
        this.props.setModifyingElementAction(null);
        this.props.setPageNumberAction(1);
        this.props.setNumberFormsAction(1);
    }

    render(): JSX.Element {
        const elements = this.getElementsByPage();
        return (
            <TableView
                {...this.props}
                backOfficeTable={this.props.backOfficeTable}
                onModifyLine={this.onModifyLine}
                onDeleteLineClick={this.onDeleteLineClick}
                onSearch={this.onSearch}
                elements={elements}
                selectCheckboxListOptions={this.selectCheckboxListOptions}
                onValidateLineForm={this.onValidateLineForm}
                onCancelledLineForm={this.onCancelledLineForm}
            />
        );
    }

    async onModifyLine(id: number | string, index: number): Promise<void> {
        this.props.setIsLoadingFormAction(true);
        const elements = clone(this.filterElements(this.getElements(), this.props.idSelectedMultiInstitutions));
        this.props.setFormTableAction(this.props.backOfficeTable.name[0]);
        const element = elements.find((elem: { id: number }): boolean => elem.id === id);
        putModifyingElementInForm(
            this.props.backOfficeTable,
            element,
            this.props.setFormKeyValueAction,
            getModelsFromDict(this.props.levelsAllById, this.props.levelsAllIds),
            this.props.setInitialFormAction,
            getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
            this.props.setIsLoadingFormAction
        );
        try {
            element.missionsCount = await this.getMissionsCount(element);
        } catch (err) {}
        this.props.setNumberFormsAction(1);
        this.props.setShowCreateOrModifyModalAction(true);
        this.props.setModifyingElementAction(element);
    }

    onDeleteLineClick(): void {
        throw new Error(`onDeleteLineClick should be implemented`);
        return;
    }
    onSearch(value: string | null): void {
        this.props.setSearchValueAction(value);
    }

    async fetchElements(): Promise<void> {
        await this.fetchElementsAction({
            query: {
                institution: this.props.idInstitutions
            },
            limit: 99999,
            orderby: [{ columnName: "id", orientation: "ASC" }]
        });
    }

    selectCheckboxListOptions(): any {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        throw new Error(`fetchElementsAction should be implemented`);
    }

    getElementsByPage(): any[] {
        return clone(this.filterElements(this.getElements(), this.props.idSelectedMultiInstitutions));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setElements(elements: any): void {
        throw new Error(`setElements should be implemented`);
    }

    getElements(): any[] {
        throw new Error(`setElements should be implemented`);
    }

    filterElements(elements: any[], idSelectedMultiInstitutions: number[]): any[] {
        return getElementsInInstitution(elements, idSelectedMultiInstitutions);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onValidateLineForm(e: any): void {
        throw new Error(`onValidateLineForm should be implemented`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCancelledLineForm(e: any): void {
        throw new Error(`onCancelledLineForm should be implemented`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getMissionsCount(element: any): Promise<number> {
        // Child components can implement their own logic, otherwise, set value to 0
        return 0;
    }
}

export function getConnectParentState(centralState: any): any {
    return {
        pageNumber: centralState.tables.pageNumber,
        modifyingElement: centralState.tables.modifyingElement,
        idSelectedMultiInstitutions: centralState.institutions.selectedMulti,
        idInstitutions: centralState.institutions.ids,
        selectedTab: centralState.tables.selectedTab,
        levelsAllIds: centralState.level.allIds,
        levelsAllById: centralState.level.allById,
        servicesById: centralState.services.byId,
        idServices: centralState.services.ids
    };
}

export function getConnectParentActions(): any {
    return {
        setPageNumberAction,
        setModifyingElementAction,
        setFormKeyValueAction,
        setSearchValueAction,
        resetSearchValueAction,
        setNumberFormsAction,
        setShowCreateOrModifyModalAction,
        setFormTableAction,
        setInitialFormAction,
        setIsLoadingFormAction
    };
}
