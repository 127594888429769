import React from "react";
import { HubloModalComponent } from "npm-medgo-components";
import { EBackOfficeTableName, EFormViewStyle, IBackOfficeTable } from "../../../constants/Table.constants";
import { connect } from "react-redux";
import {
    setShowCreateOrModifyModalAction,
    setShowConfirmationModalAction,
    setConfirmationModalDataAction,
    setNumberFormsAction,
    setFormStatusAction,
    setResetFormAction
} from "../../../store/action/form.action";
import { setModifyingElementAction } from "../../../store/action/table.action";
import { isNullOrUndefined } from "util";

interface IReduxPropsCreateOrModifyElementModal {
    numberForms: number;
    showCreateOrModifyModal: boolean;
    modifyingElement: any;
    selectedTab: number;
    isCreationOrUpdateLoading: boolean;
}

interface IReduxActionCreateOrModifyElementModal {
    setNumberFormsAction: (numberForms: number, backOfficeTableName: EBackOfficeTableName) => void;
    setShowCreateOrModifyModalAction: (showCreateOrModifyModal: boolean) => void;
    setShowConfirmationModalAction: (showConfirmation: boolean) => void;
    setConfirmationModalDataAction: (
        subtitle: string,
        text: string,
        onClick1: () => void,
        onClick2: () => void,
        textButton1: string,
        textButton2: string
    ) => void;
    setFormStatusAction: (formStatus: any) => any;
    setModifyingElementAction: (checkedElementDict: any) => void;
    setResetFormAction: () => void;
}
interface IPropsCreateOrModifyElementModal
    extends IReduxPropsCreateOrModifyElementModal,
        IReduxActionCreateOrModifyElementModal {
    backOfficeTable: IBackOfficeTable;
    lang: any;
    formView: JSX.Element;
    checkFormDictionary: (isOnSave: boolean) => boolean;
}

class CreateOrModifyElementModal extends React.Component<IPropsCreateOrModifyElementModal> {
    render(): JSX.Element {
        const isModifying = isNullOrUndefined(this.props.modifyingElement) ? false : true;
        const title = isModifying
            ? this.props.lang[`modify_${this.props.backOfficeTable.name[this.props.selectedTab]}`]
            : this.props.lang[`add_title_${this.props.backOfficeTable.name[this.props.selectedTab]}`]
            ? this.props.lang[`add_title_${this.props.backOfficeTable.name[this.props.selectedTab]}`]
            : this.props.lang[`add_${this.props.backOfficeTable.name[this.props.selectedTab]}`];
        return (
            <HubloModalComponent
                show={this.props.showCreateOrModifyModal}
                content={this.renderContent(isModifying)}
                footer={this.renderFooter(isModifying)}
                title={title}
                isTitleBold={true}
                size={"xl"}
                dialogClassName={"modal-dialog-centered"}
            />
        );
    }
    renderFooter(isModifying: boolean): JSX.Element {
        return (
            <>
                <button
                    className="cancel-button-form"
                    onClick={(): void => {
                        this.props.setShowCreateOrModifyModalAction(false);
                        this.props.setModifyingElementAction(null);
                        this.props.setResetFormAction();
                    }}
                >
                    {this.props.lang.cancel}
                </button>
                <button
                    className="button-form"
                    onClick={(): void => {
                        if (this.props.checkFormDictionary(true)) return;
                        if (isModifying) {
                            this.props.setConfirmationModalDataAction(
                                this.props.lang[`confirmationSubtitle_${this.props.backOfficeTable.name}`]
                                    ? this.props.lang[`confirmationSubtitle_${this.props.backOfficeTable.name}`]
                                    : this.props.lang.areYouSure,
                                this.props.lang[`confirmationText_${this.props.backOfficeTable.name}`]
                                    ? this.props.lang[
                                          `confirmationText_${this.props.backOfficeTable.name}${
                                              this.props.modifyingElement.missionsCount > 1 ? "_plural" : ""
                                          }`
                                      ].replace(
                                          "__MISSIONS_COUNT",
                                          this.props.modifyingElement ? this.props.modifyingElement.missionsCount : "0"
                                      )
                                    : "",

                                (): void => {
                                    this.props.setFormStatusAction("backToModal");
                                },
                                (): void => {
                                    this.props.setFormStatusAction("update");
                                    this.props.setShowConfirmationModalAction(false);
                                },
                                this.props.lang.noDontApply,
                                this.props.lang.yesApply
                            );
                            this.props.setShowConfirmationModalAction(true);
                            this.props.setShowCreateOrModifyModalAction(false);
                        } else {
                            this.props.setFormStatusAction("insert");
                        }
                    }}
                >
                    {this.props.isCreationOrUpdateLoading ? (
                        <span className="fas fa-spinner fa-lg fa-spin" />
                    ) : isModifying ? (
                        this.props.lang.save
                    ) : (
                        this.props.lang.create
                    )}
                </button>
            </>
        );
    }
    renderContent(isModifying: boolean): JSX.Element {
        return (
            <div>
                {this.props.formView}
                {!isModifying &&
                this.props.numberForms < 5 &&
                !this.props.backOfficeTable.canAddOnlyOneLine &&
                this.props.backOfficeTable.formStyle === EFormViewStyle.FormView ? (
                    <span
                        className="pointer add-element"
                        onClick={(): void =>
                            this.props.setNumberFormsAction(
                                this.props.numberForms + 1,
                                this.props.backOfficeTable.name[this.props.selectedTab]
                            )
                        }
                    >
                        <i
                            className="far fa-plus-circle"
                            style={{ marginLeft: "5px", marginRight: "10px", marginTop: "10px" }}
                        ></i>
                        {this.props.lang[`add_${this.props.backOfficeTable.name[this.props.selectedTab]}`]}
                    </span>
                ) : null}
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsCreateOrModifyElementModal => ({
        showCreateOrModifyModal: centralState.form.showCreateOrModifyModal,
        numberForms: centralState.form.numberForms,
        modifyingElement: centralState.tables.modifyingElement,
        selectedTab: centralState.tables.selectedTab,
        isCreationOrUpdateLoading: centralState.form.isCreationOrUpdateLoading
    }),
    {
        setShowCreateOrModifyModalAction,
        setNumberFormsAction,
        setFormStatusAction,
        setModifyingElementAction,
        setShowConfirmationModalAction,
        setConfirmationModalDataAction,
        setResetFormAction
    }
)(CreateOrModifyElementModal);
