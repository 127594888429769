import { connect } from "react-redux";
import {
    fetchInstitutionChronossAction,
    setInstitutionChronossAction,
    setIsLoadingInstitutionChronosAction
} from "../../store/action/institutionChronos.action";

import { IInstitutionChronos } from "../../../Model/InstitutionChronos";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionChronos extends IReduxPropsTable {
    idInstitutionChronos: number[];
    institutionChronosDict: IInstitutionChronos[];
}

interface IPropsTableInstitutionChronos extends IReduxPropsInstitutionChronos, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setInstitutionChronossAction: (elements: IInstitutionChronos[]) => any;
    fetchInstitutionChronossAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionChronosAction: (isLoading: boolean) => any;
}

class TableInstitutionChronosComponent extends TableComponent<IPropsTableInstitutionChronos> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionChronosAction(true);
        if (this.props.idInstitutionChronos.length === 0) await this.props.fetchInstitutionChronossAction(params);
        this.props.setIsLoadingInstitutionChronosAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionChronossAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionChronosDict, this.props.idInstitutionChronos);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionChronos =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionChronos: centralState.institutionChronos.ids,
            institutionChronosDict: centralState.institutionChronos.byId,
            isLoading: centralState.institutionChronos.isLoading
        }),
    Object.assign(getConnectParentActions(), {
        fetchInstitutionChronossAction,
        setInstitutionChronossAction,
        setIsLoadingInstitutionChronosAction
    })
)(TableInstitutionChronosComponent);
