import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionHasSsoTypes from "../type/institutionHasSso.type";
import { IInstitutionHasSso } from "../../../Model/InstitutionHasSso";
import InstitutionHasSsoActionTypes from "../type/institutionHasSso.type";

const initialState: IReduxInitialState<IInstitutionHasSso> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: true,
    errorCode: -1,
    ssoName: ""
};

export default function institutionHasSsoReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionHasSsoTypes.FETCH_INSTITUTIONHASSSOS:
        case InstitutionHasSsoTypes.SET_INSTITUTIONHASSSOS:
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids,
                isLoading: false
            };

        case InstitutionHasSsoTypes.FETCH_INSTITUTIONHASSSOS_COUNT:
            return {
                ...state,
                count: action.payload
            };

        case InstitutionHasSsoTypes.SET_SELECTED_INSTITUTIONHASSSO:
            return {
                ...state,
                selectedId: action.payload
            };

        case InstitutionHasSsoTypes.SET_SELECTED_INSTITUTIONHASSSO_MULTI:
            return {
                ...state,
                selectedMulti: action.payload
            };

        case InstitutionHasSsoTypes.SET_IS_LOADING_INSTITUTIONHASSSO:
            return {
                ...state,
                isLoading: action.payload
            };

        case InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ORDER:
            return {
                ...state,
                ids: action.payload
            };

        case InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE:
            return {
                ...state,
                errorCode: action.payload,
                isLoading: action.payload === -1 ? true : false
            };

        case InstitutionHasSsoTypes.INSERT_INSTITUTIONHASSSO:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id],
                isLoading: false
            };
        case InstitutionHasSsoTypes.UPDATE_INSTITUTIONHASSSO:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                isLoading: false
            };
        case InstitutionHasSsoActionTypes.SET_SSO_NAME:
            return { ...state, ssoName: action.payload };

        default:
            return state;
    }
}
