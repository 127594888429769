import { connect } from "react-redux";
import {
    setInstitutionOptionContractsAction,
    fetchInstitutionOptionContractsAction,
    setIsLoadingInstitutionOptionContractAction
} from "../../store/action/institutionOptionContract.action";
import { IInstitutionOptionContract } from "../../../Model/InstitutionOptionContract";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionOptionContracts } from "../../../Model/InstitutionOptionContract";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOptionContract extends IReduxPropsTable {
    idInstitutionOptionContracts: number[];
    institutionOptionContractDict: INormalizedInstitutionOptionContracts;
    idInstitutions: number[];
}

interface IPropsInstitutionOptionContract
    extends IReduxPropsInstitutionOptionContract,
        IReduxActionsInstitutionOptionContract,
        IPropsTable {}

interface IReduxActionsInstitutionOptionContract extends IReduxActionsTable {
    setInstitutionOptionContractsAction: (elements: IInstitutionOptionContract[]) => any;
    fetchInstitutionOptionContractsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOptionContractAction: (isLoading: boolean) => any;
}

class TableInstitutionOptionContractContainer extends TableComponent<IPropsInstitutionOptionContract> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOptionContractAction(true);
        if (this.props.idInstitutionOptionContracts.length === 0)
            await this.props.fetchInstitutionOptionContractsAction(params);
        this.props.setIsLoadingInstitutionOptionContractAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOptionContractsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOptionContractDict, this.props.idInstitutionOptionContracts);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOptionContract =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOptionContracts: centralState.institutionOptionContract.ids,
            institutionOptionContractDict: centralState.institutionOptionContract.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionOptionContractsAction,
        fetchInstitutionOptionContractsAction,
        setIsLoadingInstitutionOptionContractAction
    })
)(TableInstitutionOptionContractContainer);
