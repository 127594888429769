import { INotificationAdmin } from "../../Model/NotificationAdmin";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const NotificationAdminDefaultSelect = ["id"].concat(Array.from(new Array(22), (val, index) => "notif" + (index + 1)));

export async function fetchNotificationAdmins(query: IQueryParams): Promise<INotificationAdmin[]> {
    const notificationAdmins = await postFetchFactory<INotificationAdmin>(
        PossibleFetchs.notificationAdmin,
        PossibleApis.medgoApi
    )(
        {
            select: NotificationAdminDefaultSelect
        },
        query
    );
    return notificationAdmins;
}

export async function fetchNotificationAdminsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<INotificationAdmin>(PossibleFetchs.notificationAdmin, PossibleApis.medgoApi)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertNotificationAdmin(notificationAdmin: INotificationAdmin): Promise<INotificationAdmin> {
    return await postFactory<INotificationAdmin>(
        PossiblePosts.notificationAdmin,
        PossibleApis.medgoApi
    )(notificationAdmin);
}

export async function updateNotificationAdmin(
    id: number,
    notificationAdmin: INotificationAdmin
): Promise<INotificationAdmin> {
    return await updateFactory<INotificationAdmin>(PossibleUpdates.notificationAdmin, PossibleApis.medgoApi)(
        id,
        notificationAdmin
    );
}

export async function deleteNotificationAdmin(id: number): Promise<INotificationAdmin> {
    return await deleteFactory<INotificationAdmin>(PossibleDeletes.notificationAdmin, PossibleApis.medgoApi)(id);
}
