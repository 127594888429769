import moment, { Moment } from "moment";

export function minutesToHHmmFormat(minutes: number): string {
    const h = Math.floor(Math.abs(minutes) / 60);
    const m = Math.floor(Math.abs(minutes) % 60);
    const hString = h < 10 ? "0" + h : h;
    const mString = m < 10 ? "0" + m : m;
    return `${minutes < 0 ? "- " : ""}${hString}:${mString}`;
}

export function datesMomentMinutesDiff(beginAt: Moment, endAt: Moment, minutes: number): number {
    if (endAt.isSameOrBefore(beginAt, "minute")) endAt.add(1, "d");
    return endAt.diff(beginAt) / 1000 / 60 - minutes;
}

export function datesStringMinutesDiff(beginAt: string, endAt: string, minutes: number): number {
    return datesMomentMinutesDiff(moment(beginAt), moment(endAt), minutes);
}

export function stringHHmmToUtcMoment(formattedTime: string): Moment {
    const [formattedTimeHour, formattedTimeMinute] = formattedTime.split(":");
    return moment()
        .utc()
        .hour(parseInt(formattedTimeHour))
        .minute(parseInt(formattedTimeMinute));
}

export function stringHHmmToMinutes(formattedTime: string): number {
    const [formattedTimeHour, formattedTimeMinute] = formattedTime.split(":");
    return parseInt(formattedTimeHour) * 60 + parseInt(formattedTimeMinute);
}

export const minutesBetweenHHmm = (startDate: string, endDate: string): number => {
    const start = moment(startDate, "HH:mm");
    const end = moment(endDate, "HH:mm");
    if (end.isBefore(start)) end.add(1, "d");
    const diff = end.diff(start);

    return Math.round(moment.duration(diff).asMinutes());
};
