import { IReduxAction } from "../store.type";
import PublicholidayTypes from "../type/publicholiday.type";
import {
    fetchPublicholidays,
    fetchPublicholidaysCount,
    insertPublicholiday,
    updatePublicholiday,
    deletePublicholiday
} from "../../service/publicholiday.service";
import {
    IPublicholiday,
    INormalizedPublicholidays,
    IDispatchedPublicholiday,
    INormalizedDispatchedPublicholidays
} from "../../../Model/Publicholiday";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import moment from "moment";

export function fetchPublicholidaysAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const publicholidays = await fetchPublicholidays(query);
            const dispatchedPublicholidays = publicholidays.map(
                (publicholiday): IDispatchedPublicholiday => {
                    let dispatchedPublicholiday = Object.assign({}, publicholiday, {
                        date: moment.utc(publicholiday.date).format("L")
                    });
                    return dispatchedPublicholiday;
                }
            );
            dispatch({
                type: PublicholidayTypes.FETCH_PUBLICHOLIDAYS,
                payload: {
                    byId: dispatchedPublicholidays.reduce(
                        (
                            p: INormalizedDispatchedPublicholidays,
                            c: IDispatchedPublicholiday
                        ): INormalizedDispatchedPublicholidays => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: publicholidays.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchPublicholidaysCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchPublicholidaysCount(query);
            dispatch({
                type: PublicholidayTypes.FETCH_PUBLICHOLIDAYS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertPublicholidayAction(
    publicholiday: IPublicholiday
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newPublicholiday = await insertPublicholiday(publicholiday);
            const dispatchedPublicholiday = Object.assign({}, newPublicholiday, {
                date: moment.utc(newPublicholiday.date).format("L")
            });
            dispatch({
                type: PublicholidayTypes.INSERT_PUBLICHOLIDAY,
                payload: dispatchedPublicholiday
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updatePublicholidayAction(
    id: number,
    publicholiday: IPublicholiday
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedPublicholiday = await updatePublicholiday(id, publicholiday);
            const dispatchedPublicholiday = Object.assign({}, updatedPublicholiday, {
                date: moment.utc(updatedPublicholiday.date).format("L")
            });
            dispatch({
                type: PublicholidayTypes.UPDATE_PUBLICHOLIDAY,
                payload: dispatchedPublicholiday
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deletePublicholidayAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deletePublicholiday(id);
            dispatch({
                type: PublicholidayTypes.DELETE_PUBLICHOLIDAY
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setPublicholidaysAction(
    dict: INormalizedPublicholidays
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_PUBLICHOLIDAYS,
            payload: dict
        });
    };
}

export function setSelectedPublicholidayAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_SELECTED_PUBLICHOLIDAY,
            payload: id
        });
    };
}

export function setSelectedPublicholidayMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_SELECTED_PUBLICHOLIDAY_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingPublicholidayAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_IS_LOADING_PUBLICHOLIDAY,
            payload: isLoading
        });
    };
}
export function setPublicholidayOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_PUBLICHOLIDAY_ORDER,
            payload: idsSorted
        });
    };
}

export function setPublicholidayErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PublicholidayTypes.SET_PUBLICHOLIDAY_ERROR_CODE,
            payload: errorCode
        });
    };
}
