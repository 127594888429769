import { IReduxAction } from "../store.type";
import InstitutionHasSsoTypes from "../type/institutionHasSso.type";
import {
    fetchInstitutionHasSsos,
    fetchInstitutionHasSsosCount,
    insertInstitutionHasSso,
    updateInstitutionHasSso,
    deleteInstitutionHasSso
} from "../../service/institutionHasSso.service";
import {
    CreateInstitutionHasSso,
    IInstitutionHasSso,
    INormalizedInstitutionHasSsos
} from "../../../Model/InstitutionHasSso";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { fetchSignUpCodes } from "../../service/signUpCode.service";

export function fetchInstitutionHasSsosAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
                payload: -1
            });
            const institutionHasSsos = await fetchInstitutionHasSsos(query);
            const signUpCode = await fetchSignUpCodes({
                query: {
                    institutions: query.query.institution
                },
                select: ["code"]
            });
            dispatch({ type: InstitutionHasSsoTypes.SET_SSO_NAME, payload: signUpCode.map((el): string => el.code) });
            dispatch({
                type: InstitutionHasSsoTypes.FETCH_INSTITUTIONHASSSOS,
                payload: {
                    byId: institutionHasSsos.reduce(
                        (p: INormalizedInstitutionHasSsos, c: IInstitutionHasSso): INormalizedInstitutionHasSsos => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionHasSsos.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionHasSsosCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
                payload: -1
            });
            const count = await fetchInstitutionHasSsosCount(query);
            dispatch({
                type: InstitutionHasSsoTypes.FETCH_INSTITUTIONHASSSOS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasSsoAction(
    institutionHasSso: CreateInstitutionHasSso
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
                payload: -1
            });
            const newInstitutionHasSso = await insertInstitutionHasSso(institutionHasSso);
            dispatch({
                type: InstitutionHasSsoTypes.INSERT_INSTITUTIONHASSSO,
                payload: newInstitutionHasSso
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionHasSsoAction(
    id: number,
    institutionHasSso: IInstitutionHasSso
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
                payload: -1
            });
            const updatedInstitutionHasSso = await updateInstitutionHasSso(id, institutionHasSso);
            dispatch({
                type: InstitutionHasSsoTypes.UPDATE_INSTITUTIONHASSSO,
                payload: updatedInstitutionHasSso
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionHasSsoAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
                payload: -1
            });
            await deleteInstitutionHasSso(id);
            dispatch({
                type: InstitutionHasSsoTypes.DELETE_INSTITUTIONHASSSO
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionHasSsosAction(
    dict: INormalizedInstitutionHasSsos
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSOS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionHasSsoAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_SELECTED_INSTITUTIONHASSSO,
            payload: id
        });
    };
}

export function setSelectedInstitutionHasSsoMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_SELECTED_INSTITUTIONHASSSO_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionHasSsoAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_IS_LOADING_INSTITUTIONHASSSO,
            payload: isLoading
        });
    };
}
export function setInstitutionHasSsoOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionHasSsoErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasSsoTypes.SET_INSTITUTIONHASSSO_ERROR_CODE,
            payload: errorCode
        });
    };
}
