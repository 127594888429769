import { IPole } from "../../Model/Pole";
import { PossibleFetchs, PossibleApis } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";

const PoleDefaultSelect = ["id", "name", "institution"];

export async function fetchPoles(query: IQueryParams): Promise<IPole[]> {
    const poles = await postFetchFactory<IPole>(PossibleFetchs.pole, PossibleApis.medgoApi)(
        {
            select: PoleDefaultSelect
        },
        query
    );
    return poles;
}
