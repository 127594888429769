enum InterimAgencyActionTypes {
    FETCH_INTERIMAGENCYS = "FETCH_INTERIMAGENCYS",
    SET_INTERIMAGENCYS = "SET_INTERIMAGENCYS",
    FETCH_INTERIMAGENCYS_COUNT = "FETCH_INTERIMAGENCYS_COUNT",
    SET_SELECTED_INTERIMAGENCY = "SET_SELECTED_INTERIMAGENCY",
    SET_SELECTED_INTERIMAGENCY_MULTI = "SET_SELECTED_INTERIMAGENCY_MULTI",
    SET_IS_LOADING_INTERIMAGENCY = "SET_IS_LOADING_INTERIMAGENCY",
    SET_INTERIMAGENCY_ORDER = "SET_INTERIMAGENCY_ORDER",
    INSERT_INTERIMAGENCY = "INSERT_INTERIMAGENCY",
    UPDATE_INTERIMAGENCY = "UPDATE_INTERIMAGENCY",
    DELETE_INTERIMAGENCY = "DELETE_INTERIMAGENCY",
    SET_INTERIMAGENCY_ERROR_CODE = "SET_INTERIMAGENCY_ERROR_CODE"
}
export default InterimAgencyActionTypes;
