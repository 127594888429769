import { connect } from "react-redux";
import {
    setNotificationContractsAction,
    fetchNotificationContractsAction,
    fetchInstitutionHasNotificationContractsAction,
    setIsLoadingNotificationContractAction,
    deleteNotificationContractAction,
    deleteInstitutionHasNotificationContractAction
} from "../../store/action/notificationContract.action";

import { INotificationContract } from "../../../Model/NotificationContract";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedNotificationContracts } from "../../../Model/NotificationContract";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsNotificationContract extends IReduxPropsTable {
    idNotificationContracts: number[];
    notificationContractDict: INormalizedNotificationContracts;
    idItemToBeDeleted: number;
    idSelectedInstitutions: number;
}

interface IPropsNotificationContract
    extends IReduxPropsNotificationContract,
        IReduxActionsNotificationContract,
        IPropsTable {}

interface IReduxActionsNotificationContract extends IReduxActionsTable {
    setNotificationContractsAction: (elements: INotificationContract[]) => any;
    fetchNotificationContractsAction: (param: IQueryParams) => any;
    fetchInstitutionHasNotificationContractsAction: (param: IQueryParams) => any;
    setIsLoadingNotificationContractAction: (isLoading: boolean) => any;
    deleteNotificationContractAction: (id: number) => void;
    deleteInstitutionHasNotificationContractAction: (
        idInstitutionHasNotificationContract: number,
        idNotificationContract: number
    ) => void;
}

class TableNotificationContractContainer extends TableComponent<IPropsNotificationContract> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingNotificationContractAction(true);
        if (this.props.idNotificationContracts.length === 0) await this.props.fetchNotificationContractsAction(params);
        this.props.setIsLoadingNotificationContractAction(false);
    }

    setElements(elements: any): void {
        this.props.setNotificationContractsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.notificationContractDict, this.props.idNotificationContracts);
    }

    async onDeleteLineClick(): Promise<void> {
        const institutionHasNotificationContracts = await this.props.fetchInstitutionHasNotificationContractsAction({
            query: { notificationContract: this.props.idItemToBeDeleted }
        });
        const institutionHasNotificationContract = institutionHasNotificationContracts.filter((e: any): any => {
            return e.institution === this.props.idSelectedInstitutions;
        });
        this.props.deleteInstitutionHasNotificationContractAction(
            institutionHasNotificationContract[0].id,
            this.props.idItemToBeDeleted
        );
        if (institutionHasNotificationContracts.lenght === 1)
            this.props.deleteNotificationContractAction(this.props.idItemToBeDeleted);
    }
}

export default connect(
    (centralState: any): IReduxPropsNotificationContract =>
        Object.assign(getConnectParentState(centralState), {
            idNotificationContracts: centralState.notificationContract.ids,
            notificationContractDict: centralState.notificationContract.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.notificationContract.isLoading,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setNotificationContractsAction,
        fetchNotificationContractsAction,
        fetchInstitutionHasNotificationContractsAction,
        setIsLoadingNotificationContractAction,
        deleteNotificationContractAction,
        deleteInstitutionHasNotificationContractAction
    })
)(TableNotificationContractContainer);
