import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionChronosTypes from "../type/institutionChronos.type";
import { IInstitutionChronos } from "../../../Model/InstitutionChronos";

const initialState: IReduxInitialState<IInstitutionChronos> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionChronosReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionChronosTypes.FETCH_INSTITUTIONCHRONOSS:
        case InstitutionChronosTypes.SET_INSTITUTIONCHRONOSS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InstitutionChronosTypes.SET_SELECTED_INSTITUTIONCHRONOS: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InstitutionChronosTypes.SET_SELECTED_INSTITUTIONCHRONOS_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InstitutionChronosTypes.SET_IS_LOADING_INSTITUTIONCHRONOS: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InstitutionChronosTypes.SET_INSTITUTIONCHRONOS_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        default:
            return state;
    }
}
