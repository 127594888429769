import { IService, ICreateServiceRequest } from "../../Model/Service";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const ServiceDefaultSelect = [
    "id",
    "name",
    "nameSMS",
    "code",
    "pole",
    "interfacingCode",
    "interfacingCode2",
    "interfacingCode3",
    "institution",
    "isOnForCreatingMission",
    "address",
    "phone",
    "bedsCapacity",
    "building",
    "floor",
    "hasFood",
    "hasSmock",
    "minCaregiversPerShift",
    "parking",
    "software",
    "digicode"
];

export async function fetchServices(query: IQueryParams): Promise<IService[]> {
    const services = await postFetchFactory<IService>(PossibleFetchs.service, PossibleApis.medgoApi)(
        {
            select: ServiceDefaultSelect
        },
        query
    );
    return services;
}

export async function insertInstitutionHasService(
    idService: number,
    idInstitution: number
): Promise<ICreateServiceRequest> {
    const data = {
        institution: idInstitution,
        service: idService
    };
    const institutionHasService = await postFactory<ICreateServiceRequest>(
        PossiblePosts.institutionHasService,
        PossibleApis.medgoApi
    )(data);
    return institutionHasService;
}

export async function insertService(service: IService): Promise<IService> {
    const services = await postFactory<IService>(PossiblePosts.service, PossibleApis.medgoApi)(service);
    return services;
}

export async function updateService(id: number, service: IService): Promise<IService> {
    const services = await updateFactory<IService>(PossibleUpdates.service, PossibleApis.medgoApi)(id, service);
    return services;
}

export async function deleteService(id: number): Promise<IService> {
    const services = await deleteFactory<IService>(PossibleDeletes.service, PossibleApis.medgoApi)(id);
    return services;
}
