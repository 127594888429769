import { IQueryParams } from "npm-medgo-query";
import { PossibleApis, PossibleFetchs } from "../../Constant/Api.constant";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { ISignUpCode } from "../../Model/SignUpCode";

const SignUpCodeDefaultSelect = ["id", "code", "institutions"];

export async function fetchSignUpCodes(query: IQueryParams): Promise<ISignUpCode[]> {
    const signupCodes = await postFetchFactory<ISignUpCode>(PossibleFetchs.signUpCode, PossibleApis.medgoApi)(
        {
            select: SignUpCodeDefaultSelect
        },
        query
    );
    return signupCodes;
}
