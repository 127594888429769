import { generateParams, setGenerateSearch } from "npm-medgo-query";

function generateSearch(query: any, key: any): string {
    switch (key) {
        default:
            return "" + key + ":" + query[key];
    }
}
setGenerateSearch(generateSearch);
export default generateParams;
