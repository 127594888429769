import { IInfoOnWorker } from "../../Model/InfoOnWorker";
import { PossibleFetchs, PossiblePosts, PossibleDeletes, PossibleApis } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InfoOnWorkerDefaultSelect = ["id", "name", "type", "interfacingCode", "institution"];

export async function fetchInfoOnWorkers(query: IQueryParams): Promise<IInfoOnWorker[]> {
    const infoOnWorkers = await postFetchFactory<IInfoOnWorker>(PossibleFetchs.infoOnWorker, PossibleApis.medgoApi)(
        {
            select: InfoOnWorkerDefaultSelect
        },
        query
    );
    return infoOnWorkers;
}

export async function insertInfoOnWorker(infoOnWorker: IInfoOnWorker): Promise<IInfoOnWorker> {
    return await postFactory<IInfoOnWorker>(PossiblePosts.infoOnWorker, PossibleApis.medgoApi)(infoOnWorker);
}

export async function insertInstitutionHasInfoOnWorker(
    idInfoOnWorker: number,
    idInstitution: number
): Promise<IInfoOnWorker> {
    return await postFactory<any>(
        PossiblePosts.institutionHasInfoOnWorker,
        PossibleApis.medgoApi
    )({
        infoOnWorker: idInfoOnWorker,
        institution: idInstitution
    });
}

export async function deleteInfoOnWorker(id: number): Promise<IInfoOnWorker> {
    return await deleteFactory<IInfoOnWorker>(PossibleDeletes.infoOnWorker, PossibleApis.medgoApi)(id);
}
