import { IAdmin } from "../../Model/Admin";
import { IAdminInInstitutionNotificationService } from "../../Model/AdminInInstitutionNotificationService";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { simpleUpdateFactory, updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import { IAdminInfo } from "../../Model/AdminInfo";
import { LangToolbox } from "npm-hublo-toolbox";
import { getServiceApiAxiosError } from "../../toolbox/store/apiError";
import { AxiosError } from "axios";

const AdminDefaultSelect = [
    "id",
    "firstName",
    "lastName",
    "email",
    "level",
    "contractLevel",
    "didAcceptTerms",
    "isPlanningAllowed",
    "isStatisticAllowed",
    "typeAdmin",
    "service",
    "mobilePhone",
    "institution"
];

const AdminInfoDefaultSelect = ["admin", "idLanguage"];

const AdminInInstitutionDefaultSelect = ["id", "admin", "institution"];

const AdminInInstitutionNotificationServiceDefaultSelect = ["id", "service", "admin", "institution"];

export async function fetchAdminInfo(query: IQueryParams): Promise<IAdminInfo[]> {
    const adminInfos = await postFetchFactory<any>(PossibleFetchs.adminInfo, PossibleApis.medgoApi)(
        {
            select: AdminInfoDefaultSelect
        },
        query
    );
    return adminInfos;
}

export async function fetchAdmins(query: IQueryParams): Promise<IAdmin[]> {
    const admins = await postFetchFactory<IAdmin>(PossibleFetchs.admin, PossibleApis.medgoApi)(
        {
            select: AdminDefaultSelect
        },
        query
    );
    const adminInfos = await fetchAdminInfo({
        query: { admin: admins.map((admin: IAdmin): number => admin.id) },
        limit: 9999
    });
    for (let index in admins) {
        const admin = admins[index];
        const adminInfo = adminInfos.find((e): boolean => e.admin === admin.id);
        if (adminInfo) admin.lang = LangToolbox.getLangStringFromId(adminInfo.idLanguage);
    }
    return admins;
}

export async function fetchAdminInInstitutionNotificationService(
    query: IQueryParams
): Promise<IAdminInInstitutionNotificationService[]> {
    const adminInInstitutionNotificationService = await postFetchFactory<any>(
        PossibleFetchs.adminInInstitutionNotificationService,
        PossibleApis.medgoApi
    )(
        {
            select: AdminInInstitutionNotificationServiceDefaultSelect
        },
        query
    );
    return adminInInstitutionNotificationService;
}

export async function insertAdmin(admin: IAdmin): Promise<IAdmin> {
    const admins = await postFactory<IAdmin>(PossiblePosts.admin, PossibleApis.medgoApi)(admin);
    return admins;
}

export async function updateAdminInfo(idAdmin: number, idLanguage: number): Promise<IAdminInfo> {
    const adminInfo = await updateFactory<IAdminInfo>(PossibleUpdates.adminInfo, PossibleApis.medgoApi)(idAdmin, {
        admin: idAdmin,
        idLanguage
    });
    return adminInfo;
}

export async function insertOrUpdateAdminInfo(idAdmin: number, idLanguage: number): Promise<IAdminInfo> {
    const adminInfos = await fetchAdminInfo({
        query: { admin: idAdmin }
    });
    if (adminInfos.length === 0) {
        return await postFactory<IAdminInfo>(
            PossiblePosts.adminInfo,
            PossibleApis.medgoApi
        )({
            admin: idAdmin,
            idLanguage
        });
    } else {
        return await updateAdminInfo(idAdmin, idLanguage);
    }
}

export async function tryInsertOrUpdateAdminInfo(idAdmin: number, idLanguage: number): Promise<void> {
    try {
        await postFactory<IAdminInfo>(PossiblePosts.adminInfo, PossibleApis.medgoApi)({ admin: idAdmin, idLanguage });
    } catch (error) {
        const extractedError = getServiceApiAxiosError(error as AxiosError);
        if (extractedError && extractedError.status === 400 && extractedError.message === "uniqueKeyViolation") {
            await updateAdminInfo(idAdmin, idLanguage);
        }
    }
}

export async function insertAdminInInstitutionNotificationService(
    admin: number,
    institution: number,
    service: number
): Promise<IAdminInInstitutionNotificationService> {
    return await postFactory<any>(
        PossiblePosts.adminInInstitutionNotificationService,
        PossibleApis.medgoApi
    )({
        admin,
        institution,
        service
    });
}

export async function updateAdmin(id: number, admin: IAdmin): Promise<IAdmin> {
    const admins = await updateFactory<IAdmin>(PossibleUpdates.admin, PossibleApis.medgoApi)(id, admin);
    return admins;
}

export async function deleteAdmin(id: number): Promise<IAdmin> {
    const admins = await deleteFactory<IAdmin>(PossibleDeletes.admin, PossibleApis.medgoApi)(id);
    return admins;
}

export async function deleteAdminInInstitutionNotificationService(id: number): Promise<void> {
    await deleteFactory<any>(PossibleDeletes.adminInInstitutionNotificationService, PossibleApis.medgoApi)(id);
    return;
}

export async function findOrCreateAdmin(admin: IAdmin) {
    return simpleUpdateFactory<IAdmin, { created: boolean; admin: IAdmin }>(
        PossibleUpdates.admin,
        PossibleApis.bffBackoffice
    )(admin);
}
