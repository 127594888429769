//@ts-ignore
import * as clone from "clone";
import {
    isNegativeOrZeroHourStringDependOnDate,
    isDateFormatHHmm,
    isNullOrUndefinedValue,
    isBoolean,
    isDateFormatDDMMYYYY,
    hasSelectNotNullValue,
    isEmail,
    isStringEmpty,
    isUndefinedOrDateFormatHHmm,
    isFilenameValid,
    isNumber,
    isPositiveNumber
} from "../../toolbox/checks";
import {
    shouldDisplayContractColumn,
    shouldDisplayOnlyOnCreation,
    shouldDisplayNotificationPoles
} from "../../toolbox/permissions";

/**
 * Column
 */
export enum EBackOfficeColumnName {
    id = "id",
    name = "name",
    firstName = "firstName",
    lastName = "lastName",
    email = "email",
    mobilePhone = "mobilePhone",
    level = "level",
    contractLevel = "contractLevel",
    isPlanningAllowed = "isPlanningAllowed",
    isStatisticAllowed = "isStatisticAllowed",
    service = "service",
    shouldSendEmail = "shouldSendEmail",
    typeAdmin = "typeAdmin",
    type = "type",
    code = "code",
    codeUF = "codeUF",
    beginAt = "beginAt",
    institution = "institution",
    url = "url",
    isProxyRequired = "isProxyRequired",
    token = "token",
    password = "password",
    hasSecurityModule = "hasSecurityModule",
    endAt = "endAt",
    nbWorkedMinutes = "nbWorkedMinutes",
    interfacingCode = "interfacingCode",
    nameSMS = "nameSMS",
    pole = "pole",
    interfacingCode2 = "interfacingCode2",
    interfacingCode3 = "interfacingCode3",
    job = "job",
    specialty = "specialty",
    isNameAbsentPersonRequired = "isNameAbsentPersonRequired",
    nameAbsentPersonPlaceholderInfo = "nameAbsentPersonPlaceholderInfo",
    isFullTime = "isFullTime",
    nbMinutesAfterLaunchingRequest = "nbMinutesAfterLaunchingRequest",
    hasAutoValidation = "hasAutoValidation",
    idSubscription = "idSubscription",
    isDayMission = "isDayMission",
    isPublicHoliday = "isPublicHoliday",
    isSunday = "isSunday",
    seniorityShouldBeLessThan = "seniorityShouldBeLessThan",
    seniorityShouldBeMoreThan = "seniorityShouldBeMoreThan",
    services = "services",
    jobs = "jobs",
    codeAPI = "codeAPI",
    user = "user",
    loginKey = "loginKey",
    isVersionGreaterOrEqualTo92 = "isVersionGreaterOrEqualTo92",
    clientName = "clientName",
    societe = "societe",
    webrest = "webrest",
    codeSociete = "codeSociete",
    codeEtablissement = "codeEtablissement",
    codeService = "codeService",
    codeInterface = "codeInterface",
    jobName = "jobName",
    specialtyName = "specialtyName",
    poleName = "poleName",
    recapType = "recapType",
    to = "to",
    cc = "cc",
    bcc = "bcc",
    break = "break",
    isActive = "isActive",
    whichCounter = "whichCounter",
    starter = "starter",
    counterLimitType = "counterLimitType",
    workerType = "workerType",
    weekly = "weekly",
    monthly = "monthly",
    yearly = "yearly",
    weeklyH = "weeklyH",
    monthlyH = "monthlyH",
    yearlyH = "yearlyH",
    internalCode = "internalCode",
    externalCode = "externalCode",
    isBusinessDay = "isBusinessDay",
    isNight = "isNight",
    isBeforeSundayOrPublicHoliday = "isBeforeSundayOrPublicHoliday",
    date = "date",
    presentation = "presentation",
    address = "address",
    accessInfo = "accessInfo",
    postalCode = "postalCode",
    typeName = "typeName",
    prefixMission = "prefixMission",
    phone = "phone",
    siret = "siret",
    corporateName = "corporateName",
    healthService = "healthService",
    urssafCode = "urssafCode",
    apeCode = "apeCode",
    hubSpotId = "hubSpotId",
    hubSpotInvoiceId = "hubSpotInvoiceId",
    hasDistinctionBetweenPartTimeAndFullTime = "hasDistinctionBetweenPartTimeAndFullTime",
    coefficientValue = "coefficientValue",
    isChurn = "isChurn",
    isAddressRequired = "isAddressRequired",
    isSocialSecurityNumberRequired = "isSocialSecurityNumberRequired",
    hasDPAE = "hasDPAE",
    healthPass = "healthPass",
    isCvMandatory = "isCvMandatory",
    isDiplomaMandatory = "isDiplomaMandatory",
    isKorian = "isKorian",
    isHCL = "isHCL",
    hasContract = "hasContract",
    isMedgoDemo = "isMedgoDemo",
    hasInterim = "hasInterim",
    hasUnspecifiedService = "hasUnspecifiedService",
    hasSMS = "hasSMS",
    canPostMissionWithoutSchedule = "canPostMissionWithoutSchedule",
    canSeeTotalCostForInstitution = "canSeeTotalCostForInstitution",
    canValidateMission = "canValidateMission",
    hasPool = "hasPool",
    canSelectOneWorkerForMissionRequestCreate = "canSelectOneWorkerForMissionRequestCreate",
    hasDoubleValidation = "hasDoubleValidation",
    canSelectBetweenRecupAndSupp = "canSelectBetweenRecupAndSupp",
    country = "country",
    eSignature = "eSignature",
    eSignatureProvider = "eSignatureProvider",
    HasMassEsignatureEtab = "HasMassEsignatureEtab",
    HasMultiReplacementContract = "HasMultiReplacementContract",
    status = "status",
    lang = "lang",
    matricule = "matricule",
    assignmentInstitutions = "assignmentInstitutions",
    position = "position",
    notificationPoles = "notificationPoles",
    notificationServices = "notificationServices",
    specialties = "specialties",
    agencyName = "agencyName",
    subtitleSpecialties = "subtitleSpecialties",
    subtitleJobs = "subtitleJobs",
    sector = "sector",
    deactivatedAt = "deactivatedAt",
    isOnForCreatingMission = "isOnForCreatingMission",
    isDeleted = "isDeleted",
    agencyClientId = "agencyClientId",
    subscriptionName = "subscriptionName",
    hasHubloPool = "hasHubloPool",
    timeLimitationToPostShiftInDays = "timeLimitationToPostShiftInDays",
    timezone = "timezone",
    slotConfig = "slotConfig",
    dealShift = "dealShift",
    internalPool = "internalPool",
    internalPoolCalendarBlockDays = "internalPoolCalendarBlockDays",

    // service related keys
    building = "building",
    floor = "floor",
    bedsCapacity = "bedsCapacity",
    minCaregiversPerShift = "minCaregiversPerShift",
    hasFood = "hasFood",
    parking = "parking",
    hasSmock = "hasSmock",
    software = "software",
    digicode = "digicode"
}

export enum EBackOfficeColumnHelper {
    typeHelper = "typeHelper",
    contractLevelHelper = "contractLevelHelper",
    recapTypeHelper = "recapTypeHelper",
    nbMinutesAfterLaunchingRequestHelper = "nbMinutesAfterLaunchingRequestHelper",
    seniorityShouldBeLessThanHelper = "seniorityShouldBeLessThanHelper",
    seniorityShouldBeMoreThanHelper = "seniorityShouldBeMoreThanHelper",
    interfacingCodeHelper = "interfacingCodeHelper"
}

export enum EBackOfficeColumnType {
    string = "string",
    number = "number",
    email = "email",
    boolean = "boolean",
    select = "select",
    hour = "hour",
    selectCheckbox = "selectCheckbox",
    isActive = "isActive",
    date = "date",
    checkbox = "checkbox",
    badge = "badge"
}

export enum EBackOfficeCategoryColumnName {
    contactInformation = "contactInformation",
    levelOfAuthorityAndAssignment = "levelOfAuthorityAndAssignment"
}

export interface IFormCategoryColumns {
    name?: EBackOfficeCategoryColumnName;
    nbOfInputsPerLine?: number;
    columns: IBackOfficeColumn[];
}

export interface IBackOfficeColumn {
    name: EBackOfficeColumnName;
    type: EBackOfficeColumnType;
    checks: ((value: any, formDictionaryTable: { [key: string]: any }, isOnSave: boolean) => void)[];
    helper: EBackOfficeColumnHelper;
    min?: number;
    max?: number;
    regexPattern?: string;
    displayCriteria?: any;
    disableFormInput?: boolean;
    link?: string;
    placeholder?: string;
    displayColumn: boolean;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    isRequired?: boolean;
    hasCustomText?: boolean;
    isDisabledWhenModifying: boolean;
    shouldLookForDisabledSaveButton: boolean;
    centerContent: boolean;
}

export const DefaultValueColumn = {
    checks: [],
    displayColumn: true,
    isDisabledWhenModifying: false,
    shouldLookForDisabledSaveButton: true,
    centerContent: false
};

export const InstitutionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        displayColumn: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.prefixMission,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.address,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.phone,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.postalCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.siret,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.corporateName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.healthService,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.urssafCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.apeCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nameSMS,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.country,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.lang,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.sector,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.timezone,
        type: EBackOfficeColumnType.string
    })
];
export const FormInstitutionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.siret,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.corporateName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.healthService,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.urssafCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.apeCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nameSMS,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.country,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.lang,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.sector,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.timezone,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue]
    })
];

export const InstitutionInfoColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hubSpotId,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hubSpotInvoiceId,
        type: EBackOfficeColumnType.number
    })
];

export const InstitutionOptionContractColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.coefficientValue,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDistinctionBetweenPartTimeAndFullTime,
        type: EBackOfficeColumnType.boolean
    })
];

export const FormInstitutionOptionContractColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.coefficientValue,
        type: EBackOfficeColumnType.number,
        min: 0
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDistinctionBetweenPartTimeAndFullTime,
        type: EBackOfficeColumnType.checkbox
    })
];

export const InstitutionOptionChurnColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isChurn,
        type: EBackOfficeColumnType.boolean
    })
];

export const InstitutionOptionMissionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSelectOneWorkerForMissionRequestCreate,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDoubleValidation,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSelectBetweenRecupAndSupp,
        type: EBackOfficeColumnType.boolean
    })
];

export const FormInstitutionOptionMissionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSelectOneWorkerForMissionRequestCreate,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDoubleValidation,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSelectBetweenRecupAndSupp,
        type: EBackOfficeColumnType.checkbox
    })
];

export const InstitutionOptionWorkerColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasPool,
        type: EBackOfficeColumnType.boolean
    })
];

export const FormInstitutionOptionWorkerColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasPool,
        type: EBackOfficeColumnType.checkbox
    })
];

export const InstitutionOptionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isAddressRequired,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isSocialSecurityNumberRequired,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDPAE,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasContract,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isKorian,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isHCL,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasInterim,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.agencyClientId,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasUnspecifiedService,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isMedgoDemo,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasSMS,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canPostMissionWithoutSchedule,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSeeTotalCostForInstitution,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canValidateMission,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.eSignature,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.eSignatureProvider,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.HasMassEsignatureEtab,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.HasMultiReplacementContract,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.healthPass,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isCvMandatory,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isDiplomaMandatory,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasHubloPool,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.timeLimitationToPostShiftInDays,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.slotConfig,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.dealShift,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.internalPool,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.internalPoolCalendarBlockDays,
        type: EBackOfficeColumnType.number
    })
];

export const FormInstitutionOptionColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.institution,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isAddressRequired,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isSocialSecurityNumberRequired,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasDPAE,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.healthPass,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasContract,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isKorian,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isHCL,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasInterim,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.agencyClientId,
        type: EBackOfficeColumnType.string,
        isDisabledWhenModifying: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasUnspecifiedService,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isMedgoDemo,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasSMS,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canPostMissionWithoutSchedule,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canSeeTotalCostForInstitution,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.canValidateMission,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.eSignature,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.eSignatureProvider,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.HasMassEsignatureEtab,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.HasMultiReplacementContract,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isCvMandatory,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isDiplomaMandatory,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasHubloPool,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.timeLimitationToPostShiftInDays,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.slotConfig,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.dealShift,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.internalPool,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.internalPoolCalendarBlockDays,
        type: EBackOfficeColumnType.number
    })
];

export const InstitutionGroupColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        displayColumn: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.type,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.typeHelper
    })
];

export const InstitutionChronosColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        displayColumn: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.url,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isProxyRequired,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.token,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.password,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasSecurityModule,
        type: EBackOfficeColumnType.boolean
    })
];

export const ScheduleColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.code,
        placeholder: "placeholderNameSchedule",
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.beginAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.endAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.break,
        type: EBackOfficeColumnType.hour,
        checks: [isUndefinedOrDateFormatHHmm]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nbWorkedMinutes,
        type: EBackOfficeColumnType.hour,
        disableFormInput: true,
        checks: [isNegativeOrZeroHourStringDependOnDate]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode,
        placeholder: "placeholderInterfacingCodeSchedule",
        type: EBackOfficeColumnType.string,
        helper: EBackOfficeColumnHelper.interfacingCodeHelper,
        link: "https://hublohelp.zendesk.com/"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isDeleted,
        type: EBackOfficeColumnType.boolean,
        checks: [isBoolean],
        isRequired: true
    })
];

export const InstitutionOctimeColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isProxyRequired,
        type: EBackOfficeColumnType.boolean,
        displayColumn: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.url,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeAPI,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.user,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.password,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.loginKey,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isVersionGreaterOrEqualTo92,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.clientName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.societe,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.webrest,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeSociete,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeEtablissement,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeService,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeInterface,
        type: EBackOfficeColumnType.string
    })
];

export const InfoOnWorkerColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode,
        type: EBackOfficeColumnType.number
    })
];

export const FormInfoOnWorkerColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue, hasSelectNotNullValue]
    })
];

export const JobColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    })
];

export const FormJobColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.job,
        type: EBackOfficeColumnType.selectCheckbox,
        checks: [isNullOrUndefinedValue]
    })
];

export const SpecialtyColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    })
];

export const FormSpecialtyColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.specialty,
        type: EBackOfficeColumnType.selectCheckbox,
        checks: [isNullOrUndefinedValue]
    })
];

export const MissionMotifCreationColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isNameAbsentPersonRequired,
        type: EBackOfficeColumnType.boolean,
        maxWidth: "60px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nameAbsentPersonPlaceholderInfo,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode,
        type: EBackOfficeColumnType.string
    })
];
export const FormMissionMotifCreationColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.selectCheckbox,
        checks: [isNullOrUndefinedValue]
    })
];

export const ServiceColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        minWidth: "200px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nameSMS,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeUF,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.poleName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode2,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode3,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.phone,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.address,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.building,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.digicode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.floor,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.bedsCapacity,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.software,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.minCaregiversPerShift,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.parking,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasSmock,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasFood,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isOnForCreatingMission,
        type: EBackOfficeColumnType.boolean
    })
];

export const FormServiceColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nameSMS,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.codeUF,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.pole,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode2,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.interfacingCode3,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isOnForCreatingMission,
        type: EBackOfficeColumnType.boolean,
        checks: [isBoolean],
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.phone,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.address,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.building,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.digicode,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.floor,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.software,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.bedsCapacity,
        type: EBackOfficeColumnType.number,
        checks: [isPositiveNumber]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.minCaregiversPerShift,
        type: EBackOfficeColumnType.number,
        checks: [isPositiveNumber]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasFood,
        type: EBackOfficeColumnType.boolean,
        isRequired: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasSmock,
        type: EBackOfficeColumnType.boolean,
        isRequired: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.parking,
        type: EBackOfficeColumnType.select
    })
];

export const ExportColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.beginAt,
        type: EBackOfficeColumnType.hour,
        checks: [],
        helper: EBackOfficeColumnHelper.typeHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.endAt,
        type: EBackOfficeColumnType.hour,
        checks: [],
        helper: EBackOfficeColumnHelper.typeHelper
    })
];

export const AdminColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.email,
        type: EBackOfficeColumnType.string,
        minWidth: "200px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.firstName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.lastName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.mobilePhone,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.level,
        type: EBackOfficeColumnType.number,
        min: 1
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.contractLevel,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.contractLevelHelper,
        min: 1
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isPlanningAllowed,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isStatisticAllowed,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.service,
        type: EBackOfficeColumnType.selectCheckbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.typeAdmin,
        type: EBackOfficeColumnType.number,
        min: 1,
        max: 7
    })
];

export const AdminV2Columns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        displayColumn: false
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.user,
        type: EBackOfficeColumnType.string,
        width: "350px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.level,
        type: EBackOfficeColumnType.number,
        width: "90px",
        centerContent: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.contractLevel,
        type: EBackOfficeColumnType.number,
        displayCriteria: shouldDisplayContractColumn,
        width: "90px",
        centerContent: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.services,
        type: EBackOfficeColumnType.string,
        width: "125px",
        centerContent: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.status,
        type: EBackOfficeColumnType.badge,
        centerContent: true
    })
];

export const FormAdminColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.email,
        type: EBackOfficeColumnType.string,
        checks: [isEmail, isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.firstName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.lastName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.mobilePhone,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.level,
        type: EBackOfficeColumnType.number,
        min: 1
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.contractLevel,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.contractLevelHelper,
        min: 1
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isPlanningAllowed,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isStatisticAllowed,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.service,
        type: EBackOfficeColumnType.selectCheckbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.shouldSendEmail,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.typeAdmin,
        type: EBackOfficeColumnType.number,
        min: 1,
        max: 7
    })
];

export const FormCategoryAdminV2Columns: IFormCategoryColumns[] = [
    {
        name: EBackOfficeCategoryColumnName.contactInformation,
        columns: [
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.id,
                type: EBackOfficeColumnType.number
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.status,
                type: EBackOfficeColumnType.boolean,
                displayColumn: false,
                shouldLookForDisabledSaveButton: false
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.deactivatedAt,
                type: EBackOfficeColumnType.number,
                displayColumn: false,
                shouldLookForDisabledSaveButton: false
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.firstName,
                type: EBackOfficeColumnType.string,
                placeholder: "placeholderFirstNameAdmin",
                checks: [isNullOrUndefinedValue, isStringEmpty],
                isRequired: true
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.lastName,
                type: EBackOfficeColumnType.string,
                placeholder: "placeholderLastNameAdmin",
                checks: [isNullOrUndefinedValue, isStringEmpty],
                isRequired: true
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.typeAdmin,
                type: EBackOfficeColumnType.select,
                placeholder: "none",
                checks: [isNullOrUndefinedValue, isStringEmpty],
                isRequired: true
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.email,
                type: EBackOfficeColumnType.string,
                checks: [isEmail, isNullOrUndefinedValue, isStringEmpty],
                placeholder: "placeholderEmailAdmin",
                isRequired: true
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.mobilePhone,
                type: EBackOfficeColumnType.string,
                placeholder: "placeholderMobilePhoneAdmin"
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.lang,
                type: EBackOfficeColumnType.select,
                isRequired: true,
                checks: [isNullOrUndefinedValue]
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.matricule,
                type: EBackOfficeColumnType.string,
                placeholder: "placeholderMatriculeAdmin"
            })
        ]
    },
    {
        name: EBackOfficeCategoryColumnName.levelOfAuthorityAndAssignment,
        columns: [
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.level,
                type: EBackOfficeColumnType.select,
                placeholder: "none",
                checks: [isNullOrUndefinedValue],
                isRequired: true
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.contractLevel,
                type: EBackOfficeColumnType.select,
                placeholder: "none",
                checks: [isNullOrUndefinedValue],
                isRequired: true,
                displayCriteria: shouldDisplayContractColumn
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.position,
                type: EBackOfficeColumnType.string,
                placeholder: "none"
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.assignmentInstitutions,
                type: EBackOfficeColumnType.selectCheckbox,
                placeholder: "none",
                hasCustomText: true,
                checks: [isNullOrUndefinedValue],
                isRequired: true,
                displayCriteria: shouldDisplayOnlyOnCreation,
                shouldLookForDisabledSaveButton: false
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.notificationPoles,
                type: EBackOfficeColumnType.selectCheckbox,
                placeholder: "none",
                hasCustomText: true,
                displayCriteria: shouldDisplayNotificationPoles,
                shouldLookForDisabledSaveButton: false
            }),
            Object.assign(clone(DefaultValueColumn), {
                name: EBackOfficeColumnName.notificationServices,
                type: EBackOfficeColumnType.selectCheckbox,
                placeholder: "none",
                hasCustomText: true
            })
        ]
    }
];

export const InterimAgencyColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "70px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.subscriptionName,
        type: EBackOfficeColumnType.string,
        minWidth: "180px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.email,
        type: EBackOfficeColumnType.email,
        minWidth: "200px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nbMinutesAfterLaunchingRequest,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.nbMinutesAfterLaunchingRequestHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.subtitleJobs,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.subtitleSpecialties,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasAutoValidation,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.agencyName,
        isDisabledWhenModifying: true,
        type: EBackOfficeColumnType.string,
        minWidth: "180px"
    })
];

export const FormInterimAgencyColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.subscriptionName,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty],
        placeholder: "placeholderAgencyName",
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.email,
        type: EBackOfficeColumnType.email,
        checks: [isNullOrUndefinedValue, isStringEmpty],
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.nbMinutesAfterLaunchingRequest,
        type: EBackOfficeColumnType.number,
        checks: [isNumber],
        placeholder: "placeholderNbMinutesAfterLaunchingRequest",
        min: 0,
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.jobs,
        type: EBackOfficeColumnType.selectCheckbox,
        checks: [isNullOrUndefinedValue],
        placeholder: "placeholderAgencyJob",
        isRequired: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.specialties,
        type: EBackOfficeColumnType.selectCheckbox,
        placeholder: "placeholderAgencySpecialty"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.hasAutoValidation,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.idSubscription,
        type: EBackOfficeColumnType.string,
        isDisabledWhenModifying: true
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.agencyName,
        isDisabledWhenModifying: true,
        type: EBackOfficeColumnType.string
    })
];

export const SalaryGridColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string
    })
];

export const FormSalaryGridColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    })
];

export const TemplateColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        minWidth: "200px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.jobName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.specialtyName,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isFullTime,
        type: EBackOfficeColumnType.boolean
    })
];

export const FormTemplateColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty, isFilenameValid]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.job,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.specialty,
        type: EBackOfficeColumnType.select
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isFullTime,
        type: EBackOfficeColumnType.checkbox
    })
];

export const SpecialHourColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        minWidth: "200px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isDayMission,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isPublicHoliday,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isSunday,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.seniorityShouldBeLessThan,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.seniorityShouldBeLessThanHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.seniorityShouldBeMoreThan,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.seniorityShouldBeMoreThanHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.service,
        type: EBackOfficeColumnType.selectCheckbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.job,
        type: EBackOfficeColumnType.selectCheckbox
    })
];

export const FormSpecialHourColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isDayMission,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isPublicHoliday,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isSunday,
        type: EBackOfficeColumnType.boolean
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.seniorityShouldBeLessThan,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.seniorityShouldBeLessThanHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.seniorityShouldBeMoreThan,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.seniorityShouldBeMoreThanHelper
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.service,
        type: EBackOfficeColumnType.selectCheckbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.job,
        type: EBackOfficeColumnType.selectCheckbox
    })
];

export const NotificationContractColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.id,
        type: EBackOfficeColumnType.number,
        width: "90px"
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.recapType,
        type: EBackOfficeColumnType.number,
        helper: EBackOfficeColumnHelper.recapTypeHelper,
        min: 0,
        max: 3
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.to,
        type: EBackOfficeColumnType.email,
        checks: [isEmail, isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.cc,
        type: EBackOfficeColumnType.email,
        checks: [isEmail]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.bcc,
        type: EBackOfficeColumnType.email,
        checks: [isEmail]
    })
];

export const CounterStatusColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.counterLimitType,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isActive,
        type: EBackOfficeColumnType.isActive
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.whichCounter,
        type: EBackOfficeColumnType.string
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.starter,
        type: EBackOfficeColumnType.string
    })
];

export let CounterLimitColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.workerType,
        type: EBackOfficeColumnType.string
    })
];

export const PayrollCodeFormColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.type,
        type: EBackOfficeColumnType.select,
        checks: [isNullOrUndefinedValue, hasSelectNotNullValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.code,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.beginAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.endAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isBusinessDay,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isNight,
        type: EBackOfficeColumnType.checkbox
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isBeforeSundayOrPublicHoliday,
        type: EBackOfficeColumnType.checkbox
    })
];

export const PayrollCodeColumns: IBackOfficeColumn[] = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.name,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.typeName,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.code,
        type: EBackOfficeColumnType.string,
        checks: [isNullOrUndefinedValue, isStringEmpty]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.beginAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.endAt,
        type: EBackOfficeColumnType.hour,
        checks: [isDateFormatHHmm, isNullOrUndefinedValue]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isBusinessDay,
        type: EBackOfficeColumnType.boolean,
        checks: [isBoolean]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isNight,
        type: EBackOfficeColumnType.boolean,
        checks: [isBoolean]
    }),
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.isBeforeSundayOrPublicHoliday,
        type: EBackOfficeColumnType.boolean,
        checks: [isBoolean]
    })
];

export const PublicHolidayColumns = [
    Object.assign(clone(DefaultValueColumn), {
        name: EBackOfficeColumnName.date,
        type: EBackOfficeColumnType.date,
        checks: [isNullOrUndefinedValue, isDateFormatDDMMYYYY]
    })
];
