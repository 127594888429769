import { IInstitutionOptionChurn } from "../../Model/InstitutionOptionChurn";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionOptionChurnDefaultSelect = ["institution", "isChurn"];

export async function fetchInstitutionOptionChurns(query: IQueryParams): Promise<IInstitutionOptionChurn[]> {
    const institutionOptionChurns = await postFetchFactory<IInstitutionOptionChurn>(
        PossibleFetchs.institutionOptionChurn,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionOptionChurnDefaultSelect
        },
        query
    );
    return institutionOptionChurns;
}

export async function fetchInstitutionOptionChurnsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionOptionChurn>(
        PossibleFetchs.institutionOptionChurn,
        PossibleApis.medgoApi
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionOptionChurn(
    institutionOptionChurn: IInstitutionOptionChurn
): Promise<IInstitutionOptionChurn> {
    return await postFactory<IInstitutionOptionChurn>(
        PossiblePosts.institutionOptionChurn,
        PossibleApis.medgoApi
    )(institutionOptionChurn);
}

export async function updateInstitutionOptionChurn(
    id: number,
    institutionOptionChurn: IInstitutionOptionChurn
): Promise<IInstitutionOptionChurn> {
    return await updateFactory<IInstitutionOptionChurn>(PossibleUpdates.institutionOptionChurn, PossibleApis.medgoApi)(
        id,
        institutionOptionChurn
    );
}

export async function deleteInstitutionOptionChurn(id: number): Promise<IInstitutionOptionChurn> {
    return await deleteFactory<IInstitutionOptionChurn>(
        PossibleDeletes.institutionOptionChurn,
        PossibleApis.medgoApi
    )(id);
}
