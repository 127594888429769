import React, { Component } from "react";
import { connect } from "react-redux";

import { IAdmin } from "../../../Model/Admin";
import FormLabelInputView from "./FormLabelInput.view";
import { IBackOfficeColumn, EBackOfficeColumnName } from "../../constants/Column.constants";
import { ISelectLabelValue, IOptionsSelectCheckboxList } from "npm-medgo-components";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { valueForm } from "../../type/form.type";
import { ILang } from "../../lang/Lang";

interface IReduxPropsFormLabelInput {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
}
interface IPropsFormLabelInput extends IReduxPropsFormLabelInput {
    value?: any;
    column: IBackOfficeColumn;
    onChange: (value: valueForm) => void;
    onBlur?: (target?: EventTarget & HTMLInputElement) => void;
    selectOptions?: { [key: string]: ISelectLabelValue[] };
    customSelectCheckboxOnChange: (value: any, column: IBackOfficeColumn, formIndex: number) => void;
    table: EBackOfficeTableName;
    selectCheckboxListOptions?: { [key: string]: { [key: string]: any[] } };
    formIndex: number;
    hasError: boolean;
    name?: string;
}
export function getSelectCheckboxOptions(
    selectCheckboxListOptions: {
        [key: string]: { [key: string]: any[] };
    } = {},
    table: EBackOfficeTableName,
    columnName: EBackOfficeColumnName
): IOptionsSelectCheckboxList[] {
    const selectCheckboxOptions =
        selectCheckboxListOptions && selectCheckboxListOptions[table] && selectCheckboxListOptions[table][columnName]
            ? selectCheckboxListOptions[table][columnName].map(
                  (e): IOptionsSelectCheckboxList => ({
                      label: e.name ? e.name : "",
                      values: [e.id ? e.id : -1]
                  })
              )
            : [];
    return selectCheckboxOptions;
}
class FormLabelInput extends Component<IPropsFormLabelInput> {
    render(): JSX.Element {
        return (
            <FormLabelInputView
                selectOptions={this.props.selectOptions ? this.props.selectOptions[this.props.column.name] : []}
                selectCheckboxListOptions={getSelectCheckboxOptions(
                    this.props.selectCheckboxListOptions,
                    this.props.table,
                    this.props.column.name
                )}
                customSelectCheckboxOnChange={this.props.customSelectCheckboxOnChange}
                column={this.props.column}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                table={this.props.table}
                formIndex={this.props.formIndex}
                hasError={this.props.hasError}
                name={this.props.name}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsFormLabelInput => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user
    }),
    {}
)(FormLabelInput);
