enum InstitutionHasOAuth2ActionTypes {
    FETCH_INSTITUTIONHASOAUTH2S = "FETCH_INSTITUTIONHASOAUTH2S",
    SET_INSTITUTIONHASOAUTH2S = "SET_INSTITUTIONHASOAUTH2S",
    FETCH_INSTITUTIONHASOAUTH2S_COUNT = "FETCH_INSTITUTIONHASOAUTH2S_COUNT",
    SET_SELECTED_INSTITUTIONHASOAUTH2 = "SET_SELECTED_INSTITUTIONHASOAUTH2",
    SET_SELECTED_INSTITUTIONHASOAUTH2_MULTI = "SET_SELECTED_INSTITUTIONHASOAUTH2_MULTI",
    SET_IS_LOADING_INSTITUTIONHASOAUTH2 = "SET_IS_LOADING_INSTITUTIONHASOAUTH2",
    SET_INSTITUTIONHASOAUTH2_ORDER = "SET_INSTITUTIONHASOAUTH2_ORDER",
    INSERT_INSTITUTIONHASOAUTH2 = "INSERT_INSTITUTIONHASOAUTH2",
    UPDATE_INSTITUTIONHASOAUTH2 = "UPDATE_INSTITUTIONHASOAUTH2",
    DELETE_INSTITUTIONHASOAUTH2 = "DELETE_INSTITUTIONHASOAUTH2",
    SET_INSTITUTIONHASOAUTH2_ERROR_CODE = "SET_INSTITUTIONHASOAUTH2_ERROR_CODE"
}
export default InstitutionHasOAuth2ActionTypes;
