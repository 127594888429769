import { connect } from "react-redux";
import {
    setContractSalaryGridsAction,
    fetchContractSalaryGridsAction,
    fetchInstitutionHasContractSalaryGridsAction,
    setIsLoadingContractSalaryGridAction,
    deleteInstitutionHasContractSalaryGridAction,
    deleteContractSalaryGridAction
} from "../../store/action/contractSalaryGrid.action";

import { IContractSalaryGrid } from "../../../Model/ContractSalaryGrid";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedContractSalaryGrids } from "../../../Model/ContractSalaryGrid";
import { getModelsFromDict } from "../../modules/store/util";

interface IReduxPropsContractSalaryGrid extends IReduxPropsTable {
    idContractSalaryGrids: number[];
    contractSalaryGridDict: INormalizedContractSalaryGrids;
    idInstitutions: number[];
    idItemToBeDeleted: number;
    idSelectedInstitutions: number;
}

interface IPropsContractSalaryGrid
    extends IReduxPropsContractSalaryGrid,
        IReduxActionsContractSalaryGrid,
        IPropsTable {}

interface IReduxActionsContractSalaryGrid extends IReduxActionsTable {
    setContractSalaryGridsAction: (elements: IContractSalaryGrid[]) => any;
    fetchContractSalaryGridsAction: (param: IQueryParams) => any;
    fetchInstitutionHasContractSalaryGridsAction: (param: IQueryParams) => any;
    setIsLoadingContractSalaryGridAction: (isLoading: boolean) => any;
    deleteContractSalaryGridAction: (id: number) => void;
    deleteInstitutionHasContractSalaryGridAction: (
        idInstitutionHasContractSalaryGrid: number,
        idContractSalaryGrid: number
    ) => void;
}

class TableContractSalaryGridContainer extends TableComponent<IPropsContractSalaryGrid> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingContractSalaryGridAction(true);
        if (this.props.idContractSalaryGrids.length === 0) await this.props.fetchContractSalaryGridsAction(params);
        this.props.setIsLoadingContractSalaryGridAction(false);
    }

    setElements(elements: any): void {
        this.props.setContractSalaryGridsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<IContractSalaryGrid>(
            this.props.contractSalaryGridDict,
            this.props.idContractSalaryGrids
        );
    }

    async onDeleteLineClick(): Promise<void> {
        const institutionHasContractSalaryGrids = await this.props.fetchInstitutionHasContractSalaryGridsAction({
            query: { notificationContract: this.props.idItemToBeDeleted }
        });
        const institutionHasContractSalaryGrid = institutionHasContractSalaryGrids.filter((e: any): any => {
            return e.institution === this.props.idSelectedInstitutions;
        });
        this.props.deleteInstitutionHasContractSalaryGridAction(
            institutionHasContractSalaryGrid[0].id,
            this.props.idItemToBeDeleted
        );
        if (institutionHasContractSalaryGrids.length === 1) {
            this.props.deleteContractSalaryGridAction(this.props.idItemToBeDeleted);
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsContractSalaryGrid =>
        Object.assign(getConnectParentState(centralState), {
            idContractSalaryGrids: centralState.contractSalaryGrids.ids,
            contractSalaryGridDict: centralState.contractSalaryGrids.byId,
            idInstitutions: centralState.institutions.ids,
            isLoading: centralState.contractSalaryGrids.isLoading,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setContractSalaryGridsAction,
        fetchContractSalaryGridsAction,
        fetchInstitutionHasContractSalaryGridsAction,
        setIsLoadingContractSalaryGridAction,
        deleteInstitutionHasContractSalaryGridAction,
        deleteContractSalaryGridAction
    })
)(TableContractSalaryGridContainer);
