import { IReduxAction } from "../store.type";
import SpecialtyTypes from "../type/specialty.type";
import {
    fetchSpecialties,
    insertInstitutionHasSpecialty,
    updateSpecialty,
    deleteSpecialty
} from "../../service/specialty.service";
import { ISpecialty, INormalizedSpecialties } from "../../../Model/Specialty";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { INormalizedJobs } from "../../../Model/Job";

export function fetchSpecialtiesAction(
    query: IQueryParams,
    jobDict?: INormalizedJobs
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            let specialties = await fetchSpecialties(query);
            if (jobDict) {
                specialties.forEach((e: ISpecialty): void => {
                    e.name = (jobDict[e.job] ? jobDict[e.job].name + ": " : "") + e.name;
                });
                specialties.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            }
            dispatch({
                type: SpecialtyTypes.FETCH_SPECIALTIES,
                payload: {
                    byId: specialties.reduce((p: INormalizedSpecialties, c: ISpecialty): INormalizedSpecialties => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: specialties.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
export function fetchAllSpecialtiesAction(
    query: IQueryParams,
    jobDict?: INormalizedJobs
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            let specialties = await fetchSpecialties(query);
            if (jobDict)
                specialties.forEach((e: ISpecialty): void => {
                    e.name = (jobDict[e.job] ? jobDict[e.job].name + ": " : "") + e.name;
                });
            dispatch({
                type: SpecialtyTypes.FETCH_ALLSPECIALTIES,
                payload: {
                    byId: specialties.reduce((p: INormalizedSpecialties, c: ISpecialty): INormalizedSpecialties => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: specialties.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasSpecialtyAction(
    specialty: ISpecialty
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertInstitutionHasSpecialty(specialty);
            dispatch({
                type: SpecialtyTypes.INSERT_INSTITUTION_HAS_SPECIALTY,
                payload: specialty
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateSpecialtyAction(
    id: number,
    specialty: ISpecialty
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateSpecialty(id, specialty);
            dispatch({
                type: SpecialtyTypes.UPDATE_SPECIALTY
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteSpecialtyAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteSpecialty(id);
            dispatch({
                type: SpecialtyTypes.DELETE_SPECIALTY
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setSpecialtiesAction(dict: INormalizedSpecialties): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_SPECIALTIES,
            payload: dict
        });
    };
}

export function setSelectedSpecialtyAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_SELECTED_SPECIALTY,
            payload: id
        });
    };
}

export function setSelectedSpecialtyMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_SELECTED_SPECIALTY_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingSpecialtyAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_IS_LOADING_SPECIALTY,
            payload: isLoading
        });
    };
}
export function setSpecialtyOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_SPECIALTY_ORDER,
            payload: idsSorted
        });
    };
}
