import { IInstitutionChronos } from "../../Model/InstitutionChronos";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionChronosDefaultSelect = [
    "id",
    "institution",
    "url",
    "isProxyRequired",
    "token",
    "password",
    "hasSecurityModule"
];

export async function fetchInstitutionChronoss(query: IQueryParams): Promise<IInstitutionChronos[]> {
    const institutionChronoss = await postFetchFactory<IInstitutionChronos>(
        PossibleFetchs.institutionChronos,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionChronosDefaultSelect
        },
        query
    );
    return institutionChronoss;
}

export async function insertInstitutionChronos(institutionChronos: IInstitutionChronos): Promise<IInstitutionChronos> {
    return await postFactory<IInstitutionChronos>(
        PossiblePosts.institutionChronos,
        PossibleApis.medgoApi
    )(institutionChronos);
}

export async function updateInstitutionChronos(
    id: number,
    institutionChronos: IInstitutionChronos
): Promise<IInstitutionChronos> {
    return await updateFactory<IInstitutionChronos>(PossibleUpdates.institutionChronos, PossibleApis.medgoApi)(
        id,
        institutionChronos
    );
}

export async function deleteInstitutionChronos(id: number): Promise<IInstitutionChronos> {
    return await deleteFactory<IInstitutionChronos>(PossibleDeletes.institutionChronos, PossibleApis.medgoApi)(id);
}
