enum PayrollcodeActionTypes {
    FETCH_PAYROLLCODES = "FETCH_PAYROLLCODES",
    SET_PAYROLLCODES = "SET_PAYROLLCODES",
    FETCH_PAYROLLCODES_COUNT = "FETCH_PAYROLLCODES_COUNT",
    SET_SELECTED_PAYROLLCODE = "SET_SELECTED_PAYROLLCODE",
    SET_SELECTED_PAYROLLCODE_MULTI = "SET_SELECTED_PAYROLLCODE_MULTI",
    SET_IS_LOADING_PAYROLLCODE = "SET_IS_LOADING_PAYROLLCODE",
    SET_PAYROLLCODE_ORDER = "SET_PAYROLLCODE_ORDER",
    INSERT_PAYROLLCODE = "INSERT_PAYROLLCODE",
    UPDATE_PAYROLLCODE = "UPDATE_PAYROLLCODE",
    DELETE_PAYROLLCODE = "DELETE_PAYROLLCODE",
    SET_PAYROLLCODE_ERROR_CODE = "SET_PAYROLLCODE_ERROR_CODE"
}
export default PayrollcodeActionTypes;
