//@ts-ignore
import * as clone from "clone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import { IBackOfficeColumn, EBackOfficeColumnType, EBackOfficeColumnName } from "../../constants/Column.constants";
import {
    ISelectLabelValue,
    IOptionsSelectCheckboxList,
    EAvailableLang,
    EHubloFormInputType,
    HubloFormInputWithLabelComponent
} from "@hublo/legacy-components";
import { setFormKeyValueAction, resetErrorMessageAction } from "../../store/action/form.action";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { getFormDictionaryRecordsDict, getModelsFromDict, hasErrorMessage } from "../../modules/store/util";
import { StringToolbox } from "npm-medgo-toolbox";
import { valueForm } from "../../type/form.type";
import { ILang } from "../../lang/Lang";
import { INormalizedServices, IService } from "../../../Model/Service";
import { getNotificationServicesInPolesAndInstitutions } from "../../../toolbox/filters";
import { ArrayToolbox } from "npm-hublo-toolbox";

interface IReduxPropsFormLabelInputView {
    currentLang: EAvailableLang;
    lang: ILang & { [key: string]: string };
    me: IAdmin;
    formDictionary: IFormDictionary;
    errorMessage: { [table: string]: { [index: string]: { [column: string]: string } } };
    modifyingElement: any;
    servicesById: INormalizedServices;
    idServices: number[];
}

interface IReduxActionFormLabelInputView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    resetErrorMessageAction: (table: EBackOfficeTableName, index: number, column: EBackOfficeColumnName) => void;
    customSelectCheckboxOnChange: (value: any, column: IBackOfficeColumn, formIndex: number) => void;
}

interface IPropsFormLabelInputView extends IReduxPropsFormLabelInputView, IReduxActionFormLabelInputView {
    column: IBackOfficeColumn;
    onChange: (value: valueForm) => void;
    onBlur?: (target?: EventTarget & HTMLInputElement) => void;
    selectOptions?: ISelectLabelValue[];
    table: EBackOfficeTableName;
    selectCheckboxListOptions?: IOptionsSelectCheckboxList[];
    formIndex: number;
    hasError: boolean;
    name?: string;
}

class FormLabelInputView extends Component<IPropsFormLabelInputView> {
    render(): JSX.Element {
        const formDictionaryTable = getFormDictionaryRecordsDict(this.props.formDictionary, this.props.table);
        let value = formDictionaryTable[this.props.formIndex]
            ? formDictionaryTable[this.props.formIndex][this.props.column.name]
            : undefined;
        let placeholder = this.props.column.placeholder
            ? this.props.lang[this.props.column.placeholder]
            : StringToolbox.firstLetterUppercase(this.props.lang[this.props.column.name]);
        const checkedOptions =
            formDictionaryTable[this.props.formIndex] &&
            formDictionaryTable[this.props.formIndex][this.props.column.name]
                ? formDictionaryTable[this.props.formIndex][this.props.column.name]
                : [];
        let inputType: EHubloFormInputType;
        switch (this.props.column.type) {
            case EBackOfficeColumnType.hour:
                inputType = EHubloFormInputType.hour;
                break;
            case EBackOfficeColumnType.select:
                inputType = EHubloFormInputType.select;
                break;
            case EBackOfficeColumnType.selectCheckbox:
                inputType = EHubloFormInputType.selectCheckbox;
                break;
            case EBackOfficeColumnType.boolean:
                inputType = EHubloFormInputType.boolean;
                value = value === true ? "true" : value === false ? "false" : null;
                break;
            case EBackOfficeColumnType.number:
                inputType = EHubloFormInputType.number;
                if (value === 0) value = "0";
                break;
            case EBackOfficeColumnType.date:
                placeholder = this.props.lang.dateInput;
                inputType = EHubloFormInputType.text;
                break;
            case EBackOfficeColumnType.checkbox:
                inputType = EHubloFormInputType.checkbox;
                break;
            default:
                inputType = EHubloFormInputType.text;
        }
        return (
            <div className={`${this.props.column.type}-form ${this.props.column.name}-form`}>
                <HubloFormInputWithLabelComponent
                    inputType={inputType}
                    type={this.props.column.type === EBackOfficeColumnType.email ? "email" : "text"}
                    name={this.props.name ? this.props.name : ""}
                    placeholder={placeholder}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    selectCheckBoxOnChange={(val: any) => {
                        this.selectCheckBoxOnChange(val);
                        this.props.customSelectCheckboxOnChange(val, this.props.column, this.props.formIndex);
                    }}
                    checkboxOnClick={(): void => {
                        this.props.setFormKeyValueAction(
                            this.props.column.name,
                            !value,
                            this.props.table,
                            this.props.column.type,
                            this.props.formIndex
                        );
                    }}
                    isChecked={value}
                    value={value}
                    dateValue={value}
                    selectValue={value}
                    selectCheckboxValues={checkedOptions}
                    selectOptions={this.props.selectOptions}
                    selectCheckboxOptions={this.props.selectCheckboxListOptions}
                    min={this.props.column.min}
                    max={this.props.column.max}
                    regexPattern={this.props.column.regexPattern}
                    currentLang={this.props.currentLang}
                    // @ts-ignore
                    hasError={this.props.hasError}
                    isRequired={this.props.column.isRequired}
                    isDisabled={this.isDisabled()}
                    customText={this.renderCustomText()}
                />
            </div>
        );
    }

    renderCustomText() {
        if (!this.props.column.hasCustomText) {
            return undefined;
        }
        const formDictionaryTable = getFormDictionaryRecordsDict(this.props.formDictionary, this.props.table);
        const checkedOptions =
            formDictionaryTable[this.props.formIndex] &&
            formDictionaryTable[this.props.formIndex][this.props.column.name]
                ? formDictionaryTable[this.props.formIndex][this.props.column.name]
                : [];
        const textToBeDisplayed =
            this.props.selectCheckboxListOptions &&
            checkedOptions.length === this.props.selectCheckboxListOptions.length
                ? this.props.lang.all
                : `${checkedOptions.length} ${this.props.lang[`${this.props.column.name}_customText`]}`;
        if (checkedOptions.length !== 0) {
            return <div className="input-custom-text">{textToBeDisplayed}</div>;
        }
    }

    isDisabled(): boolean {
        const isSelectCheckboxListWithoutOption =
            this.props.column.type === EBackOfficeColumnType.selectCheckbox &&
            this.props.selectCheckboxListOptions &&
            this.props.selectCheckboxListOptions.length === 0;
        const isDisabledWhenModifying = this.props.column.isDisabledWhenModifying && this.props.modifyingElement;
        const elementIsInactif = this.props.modifyingElement?.status === false;
        return isSelectCheckboxListWithoutOption || isDisabledWhenModifying || elementIsInactif;
    }

    selectCheckBoxOnChange(val: any): void {
        if (hasErrorMessage(this.props.errorMessage, this.props.table, this.props.formIndex, this.props.column.name))
            this.props.resetErrorMessageAction(this.props.table, this.props.formIndex, this.props.column.name);
        if (this.props.column.name === EBackOfficeColumnName.notificationPoles) {
            this.selectCheckBoxOnChangeNotificationPole(val);
        }
        if (this.props.column.name === EBackOfficeColumnName.notificationServices) {
            this.selectCheckBoxOnChangeNotificationServices(val);
        }
        this.props.setFormKeyValueAction(
            this.props.column.name,
            val,
            this.props.table,
            this.props.column.type,
            this.props.formIndex
        );
    }

    selectCheckBoxOnChangeNotificationPole(val: any) {
        const form = getFormDictionaryRecordsDict(this.props.formDictionary, this.props.table)[0];
        const oldPoles = form?.notificationPoles ? form.notificationPoles : [];
        const oldServices = form?.notificationServices ? form.notificationServices : [];
        const idInstitutions = form?.assignmentInstitutions ? form.assignmentInstitutions : [];
        const hasAddedPole = oldPoles.length < val.length;
        if (hasAddedPole) {
            this.selectCheckBoxOnAddNotificationPole(val, oldPoles, oldServices, idInstitutions);
        } else {
            this.selectCheckBoxOnDeleteNotificationPole(val, oldPoles, oldServices, idInstitutions);
        }
    }

    selectCheckBoxOnAddNotificationPole(val: any, oldPoles: number[], oldServices: number[], idInstitutions: number[]) {
        const addedPoles = ArrayToolbox.diffArrays<number>(val, oldPoles);
        const servicesToAdds = getNotificationServicesInPolesAndInstitutions(
            getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
            addedPoles,
            idInstitutions
        ).map(e => e.id);
        const servicesResults = ArrayToolbox.removeDuplicateInArray(oldServices.concat(servicesToAdds));
        this.props.setFormKeyValueAction(
            EBackOfficeColumnName.notificationServices,
            servicesResults,
            this.props.table,
            this.props.column.type,
            this.props.formIndex
        );
    }

    selectCheckBoxOnDeleteNotificationPole(
        val: any,
        oldPoles: number[],
        oldServices: number[],
        idInstitutions: number[]
    ) {
        const deletedPoles = ArrayToolbox.diffArrays<number>(oldPoles, val);
        const allServicesInDeletedPoles = getNotificationServicesInPolesAndInstitutions(
            getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
            deletedPoles,
            idInstitutions
        ).map(e => e.id);
        if (
            ArrayToolbox.intersectArrays<number>(oldServices, allServicesInDeletedPoles).length ===
            allServicesInDeletedPoles.length
        ) {
            const servicesToKeep = ArrayToolbox.diffArrays<number>(oldServices, allServicesInDeletedPoles);
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.notificationServices,
                servicesToKeep,
                this.props.table,
                this.props.column.type,
                this.props.formIndex
            );
        }
    }

    selectCheckBoxOnChangeNotificationServices(val: any) {
        const form = getFormDictionaryRecordsDict(this.props.formDictionary, this.props.table)[0];
        const oldPoles = form?.notificationPoles ? form.notificationPoles : [];
        const oldServices = form?.notificationServices ? form.notificationServices : [];
        const idInstitutions = form?.assignmentInstitutions ? form.assignmentInstitutions : [];
        const hasAddedServices = oldServices.length < val.length;
        if (hasAddedServices) {
            this.selectCheckBoxOnAddNotificationService(val, oldPoles, oldServices, idInstitutions);
        } else {
            this.selectCheckBoxOnDeleteNotificationService(val, oldPoles, oldServices);
        }
    }

    selectCheckBoxOnAddNotificationService(
        val: any,
        oldPoles: number[],
        oldServices: number[],
        idInstitutions: number[]
    ) {
        const addedServices = ArrayToolbox.diffArrays<number>(val, oldServices);
        let polesResults: number[] = clone(oldPoles);
        for (let i in addedServices) {
            const addedService = addedServices[i];
            const poleOfAddedService = this.props.servicesById[addedService].pole;
            if (!poleOfAddedService || polesResults.includes(poleOfAddedService)) continue;
            const servicesInPoles = getNotificationServicesInPolesAndInstitutions(
                getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                [poleOfAddedService],
                idInstitutions
            ).map(e => e.id);
            const hasAllServicesInPole =
                ArrayToolbox.intersectArrays<number>(val, servicesInPoles).length === servicesInPoles.length;
            if (hasAllServicesInPole) {
                polesResults.push(poleOfAddedService);
            }
        }
        if (oldPoles.length < polesResults.length) {
            polesResults = ArrayToolbox.removeDuplicateInArray<number>(polesResults.filter((e: any) => e !== null));
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.notificationPoles,
                polesResults,
                this.props.table,
                this.props.column.type,
                this.props.formIndex
            );
        }
    }

    selectCheckBoxOnDeleteNotificationService(val: any, oldPoles: number[], oldServices: number[]) {
        const deletedService = ArrayToolbox.diffArrays<number>(oldServices, val);
        const polesOfDeletedServices = getModelsFromDict<IService>(this.props.servicesById, deletedService)
            .filter(e => e.pole !== null)
            .map(e => e.pole);
        // @ts-ignore
        const polesToKeep = ArrayToolbox.diffArrays<number>(oldPoles, polesOfDeletedServices);
        if (polesToKeep.length !== oldPoles.length) {
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.notificationPoles,
                polesToKeep,
                this.props.table,
                this.props.column.type,
                this.props.formIndex
            );
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsFormLabelInputView => ({
        lang: centralState.language.lang,
        me: centralState.auth.user,
        currentLang: centralState.language.currentLang,
        formDictionary: centralState.form.formDictionary,
        errorMessage: centralState.form.errorMessage,
        modifyingElement: centralState.tables.modifyingElement,
        servicesById: centralState.services.byId,
        idServices: centralState.services.ids
    }),
    { setFormKeyValueAction, resetErrorMessageAction }
)(FormLabelInputView);
