import { fr as DEFAULT_LANG } from "../src/lang/fr";
import { isNullOrUndefined } from "util";
import { ISelectLabelValue } from "npm-medgo-components";
import { Equality } from "npm-medgo-toolbox";

export function isNegativeOrZeroHourString(value: string): void {
    if (value && value[0] && (value[0] === "-" || value === "00:00")) {
        throw { errorCode: 406 };
    }
}

export function isNegativeOrZeroHourStringDependOnDate(
    value: string,
    formDictionaryTable: { [key: string]: any }
): void {
    if (formDictionaryTable.beginAt && formDictionaryTable.endAt) {
        isNegativeOrZeroHourString(value);
    }
}

export function isDateFormatHHmm(value: string, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (isOnSave && typeof value === "string" && value.trim() === "") {
        throw { errorCode: 419 };
    }
    const regEx = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (isOnSave && (!value || value.length === 0 || !regEx.test(value))) {
        throw { errorCode: 406 };
    }
}

export function isUndefinedOrDateFormatHHmm(
    value: string,
    formDictionaryTable: { [key: string]: any },
    isOnSave: boolean
): void {
    if (isOnSave && ((typeof value === "string" && value.trim() === "") || value === undefined)) {
        return;
    }
    const regEx = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (isOnSave && (!value || value.length === 0 || !regEx.test(value))) {
        throw { errorCode: 406 };
    }
}

export function isNullOrUndefinedValue(
    value: string | number[],
    formDictionaryTable: { [key: string]: any },
    isOnSave: boolean
): void {
    if (isOnSave && (!value || value.length === 0)) {
        throw { errorCode: 419 };
    }
}

export function isStringEmpty(value: string, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (isOnSave && typeof value === "string" && value === "") {
        throw { errorCode: 419 };
    }
    if (isOnSave && typeof value === "string" && value.trim() === "") {
        throw { errorCode: 406 };
    }
}

export function isNumber(value: number, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (isOnSave && (typeof value !== "number" || Equality.isNullOrUndefined(value))) {
        throw { errorCode: 419 };
    }
}

export function isPositiveNumber(value: number, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (!value) {
        return;
    }
    if (isOnSave && (typeof value !== "number" || value <= 0)) {
        throw { errorCode: 406 };
    }
}

export function isBoolean(value: any, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if ((isOnSave && typeof value === "undefined") || (typeof value !== "boolean" && typeof value !== "undefined")) {
        throw { errorCode: 419 };
    }
}

export function isEmail(value: any, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (
        isOnSave &&
        value &&
        /^[ ]{0,}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[ ]{0,}$/.test(
            value
        ) === false
    ) {
        throw { errorCode: 4062 };
    }
}

export function isFilenameValid(value: any, formDictionaryTable: { [key: string]: any }, isOnSave: boolean): void {
    if (isOnSave && /^[a-zA-Z0-9,_,.,-]{1,100}.(docx|doc|csv|txt)$/.test(value) === false) {
        throw { errorCode: 406 };
    }
}

export function checkNameEtablissement(value: string | any[], lang = DEFAULT_LANG): any {
    if (value.length < 2) {
        return { errorMessage: lang.youShouldPreciseInstitutionName };
    }
}

export function checkFirstName(firstName: string, lang: any) {
    if (isNullOrUndefined(firstName) || firstName.replace(/\s/g, "").length < 2) {
        return { errorMessage: lang.firstNameTooShort };
    }
}

export function checkLastName(lastName: string, lang: any) {
    if (isNullOrUndefined(lastName) || lastName.replace(/\s/g, "").length < 2) {
        return { errorMessage: lang.lastNameTooShort };
    }
}

export function checkMultiple(array: any[]): any {
    return array.reduce((a, b): any => {
        a.errorMessage = !isNullOrUndefined(a.errorMessage)
            ? a.errorMessage
            : !isNullOrUndefined(b)
            ? b.errorMessage
            : undefined;
        return a;
    }, {});
}

export function checkAddress(value: any, lang = DEFAULT_LANG): any {
    if (!value) {
        return {
            errorMessage: lang.yourAddressAreNotRecognized
        };
    }
}

export function checkPostalCode(value: string, lang = DEFAULT_LANG): any {
    const ckRegex = /^[0-9]{5}$/;
    if (!ckRegex.test(value)) {
        return { errorMessage: lang.youShouldIndicateValidAddress };
    }
}

export function checkLatitude(value: string, lang = DEFAULT_LANG): any {
    const ckRegex = /^\-?\d*\.?\d*$/;
    if (!ckRegex.test(value)) {
        return { errorMessage: lang.youShouldIndicateValidAddress };
    }
}

export function checkLongitude(value: string, lang = DEFAULT_LANG): any {
    const ckRegex = /^\-?\d*\.?\d*$/;
    if (!ckRegex.test(value)) {
        return { errorMessage: lang.youShouldIndicateValidAddress };
    }
}

export function checkAddressAutocomplete(address: any, addressText: any, lang = DEFAULT_LANG): any {
    if (address !== addressText) {
        return {
            errorMessage: lang.pleaseFillYourAddressAndSelectAChoice
        };
    }
}

export function isDateFormatDDMMYYYY(
    value: string,
    formDictionaryTable: { [key: string]: any },
    isOnSave: boolean
): void {
    const regEx = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    if (value && value.length > 0 && !regEx.test(value)) {
        throw { errorCode: 406 };
    }
}

export function hasSelectNotNullValue(
    option: ISelectLabelValue,
    formDictionaryTable: { [key: string]: any },
    isOnSave: boolean
): void {
    if (isOnSave && (isNullOrUndefined(option) || isNullOrUndefined(option.value))) {
        throw { errorCode: 419 };
    }
}
