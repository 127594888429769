import { connect } from "react-redux";
import {
    fetchPayrollcodesAction,
    setPayrollcodesAction,
    deletePayrollcodeAction
} from "../../store/action/payrollcode.action";

import { IDispatchedPayrollcode } from "../../../Model/Payrollcode";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedDispatchedPayrollcodes } from "../../../Model/Payrollcode";
import { getModelsFromDict } from "../../modules/store/util";
import { ILang } from "../../lang/Lang";

//@ts-ignore
interface IReduxPropsPayrollCode extends IReduxPropsTable {
    idPayrollcodes: number[];
    payrollcodeDict: INormalizedDispatchedPayrollcodes;
    idItemToBeDeleted: number;
    lang: ILang;
}

interface IPropsPayrollCode extends IReduxPropsPayrollCode, IReduxActionsPayrollCode, IPropsTable {}

interface IReduxActionsPayrollCode extends IReduxActionsTable {
    setPayrollcodesAction: (elements: IDispatchedPayrollcode[]) => any;
    fetchPayrollcodesAction: (param: IQueryParams) => any;
    deletePayrollcodeAction: (id: number) => any;
}

class TablePayrollCodeContainer extends TableComponent<IPropsPayrollCode> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        await this.props.fetchPayrollcodesAction(params);
    }

    setElements(elements: any): void {
        this.props.setPayrollcodesAction(elements);
    }

    getElements(): any[] {
        const payrollcodeTypeNames = [this.props.lang.salarie, this.props.lang.vacataire];
        const payrollcodes = getModelsFromDict<IDispatchedPayrollcode>(
            this.props.payrollcodeDict,
            this.props.idPayrollcodes
        );
        payrollcodes.forEach((pc: any): void => {
            pc.typeName = payrollcodeTypeNames[pc.type];
        });
        return payrollcodes;
    }

    async onDeleteLineClick(): Promise<void> {
        const id = this.props.idItemToBeDeleted;
        await this.props.deletePayrollcodeAction(id);
        await this.fetchElements();
    }
}

export default connect(
    (centralState: any): IReduxPropsPayrollCode =>
        Object.assign(getConnectParentState(centralState), {
            idPayrollcodes: centralState.payrollCode.ids,
            payrollcodeDict: centralState.payrollCode.byId,
            idSelectedMultiInstitutions: centralState.institutions.selectedMulti,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted,
            lang: centralState.language.lang
        }),
    Object.assign(getConnectParentActions(), {
        setPayrollcodesAction,
        fetchPayrollcodesAction,
        deletePayrollcodeAction
    })
)(TablePayrollCodeContainer);
