enum InstitutionOptionMissionActionTypes {
    FETCH_INSTITUTIONOPTIONMISSIONS = "FETCH_INSTITUTIONOPTIONMISSIONS",
    SET_INSTITUTIONOPTIONMISSIONS = "SET_INSTITUTIONOPTIONMISSIONS",
    FETCH_INSTITUTIONOPTIONMISSIONS_COUNT = "FETCH_INSTITUTIONOPTIONMISSIONS_COUNT",
    SET_SELECTED_INSTITUTIONOPTIONMISSION = "SET_SELECTED_INSTITUTIONOPTIONMISSION",
    SET_SELECTED_INSTITUTIONOPTIONMISSION_MULTI = "SET_SELECTED_INSTITUTIONOPTIONMISSION_MULTI",
    SET_IS_LOADING_INSTITUTIONOPTIONMISSION = "SET_IS_LOADING_INSTITUTIONOPTIONMISSION",
    SET_INSTITUTIONOPTIONMISSION_ORDER = "SET_INSTITUTIONOPTIONMISSION_ORDER",
    INSERT_INSTITUTIONOPTIONMISSION = "INSERT_INSTITUTIONOPTIONMISSION",
    UPDATE_INSTITUTIONOPTIONMISSION = "UPDATE_INSTITUTIONOPTIONMISSION",
    DELETE_INSTITUTIONOPTIONMISSION = "DELETE_INSTITUTIONOPTIONMISSION",
    SET_INSTITUTIONOPTIONMISSION_ERROR_CODE = "SET_INSTITUTIONOPTIONMISSION_ERROR_CODE"
}
export default InstitutionOptionMissionActionTypes;
