export type SsoForm = "ssoViaOAuth2";

export const ssoTypeToSsoForm = (type: number): SsoForm => {
    switch (type) {
        case 1:
            return "ssoViaOAuth2";
    }

    throw new Error("Invalid SsoType value");
};
export const ssoFormToSsoType = (form: SsoForm): number => {
    switch (form) {
        case "ssoViaOAuth2":
            return 1;
    }

    throw new Error("Invalid SsoForm value");
};
