import { EButtonMedgoActionClassName } from "@hublo/legacy-components";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IInstitutionGroup } from "../../Model/InstitutionGroup";
import { IInstitutionHasAdmin } from "../../Model/InstitutionHasAdmin";
import { EBackOfficeTableName } from "../constants/Table.constants";
import { fetchAdminInInstitutionNotificationService } from "../service/admin.service";
import { deleteAdminInInstitutionNotificationServiceAction } from "../store/action/admin.action";
import {
    setConfirmationModalDataAction,
    setResetFormAction,
    setShowConfirmationModalAction
} from "../store/action/form.action";
import { updateInstitutionHasAdminAction } from "../store/action/institutionHasAdmin.action";
import { setNotificationMessage } from "../store/action/notification.action";
import { institutionGroupsSelector, langSelector } from "../store/selectors";

const manageServiceNotifications = (idAdmin: number, idInstitution: number, dispatch: Dispatch<any>) => {
    const deleteNotificationServices = async () => {
        const adminInInstitutionNotificationServices = await fetchAdminInInstitutionNotificationService({
            query: { admin: idAdmin, institution: idInstitution }
        });
        adminInInstitutionNotificationServices.map(
            async (adminInInstitutionNotificationService): Promise<void> => {
                dispatch(
                    deleteAdminInInstitutionNotificationServiceAction(adminInInstitutionNotificationService, idAdmin)
                );
            }
        );
    };
    return deleteNotificationServices;
};

const confirmActivateOrDeactivateAdmin = (
    isActivated: boolean,
    institutionHasAdmin: IInstitutionHasAdmin,
    idLanguage: number,
    dispatch: Dispatch<any>
) => {
    const [lang] = useSelector(langSelector);
    const institutionGroupsById = useSelector(institutionGroupsSelector);
    const deleteNotificationServices = manageServiceNotifications(
        institutionHasAdmin?.admin,
        institutionHasAdmin?.institution,
        dispatch
    );
    let isInInstitutionGroupType1 = false;
    const institutionGroups = Object.values<IInstitutionGroup>(institutionGroupsById);
    for (let i in institutionGroups) {
        if (
            institutionGroups[i].institution.includes(institutionHasAdmin.institution) &&
            institutionGroups[i].type === 1
        ) {
            isInInstitutionGroupType1 = true;
            break;
        }
    }
    const onConfirm = (shouldUpdateOtherInstitutions: boolean) => {
        dispatch(
            updateInstitutionHasAdminAction(
                institutionHasAdmin.id,
                Object.assign(institutionHasAdmin, {
                    isActivated: !isActivated,
                    deactivatedAt: isActivated ? Math.floor(Date.now() / 1000) : null,
                    idLanguage: idLanguage,
                    shouldUpdateOtherInstitutions: shouldUpdateOtherInstitutions
                })
            )
        );
        if (isActivated) dispatch(deleteNotificationServices);
        dispatch(setShowConfirmationModalAction(false));
        window.location.href = `#${EBackOfficeTableName.adminV2}`;
        dispatch(setResetFormAction());
        dispatch(
            setNotificationMessage({
                message: isActivated ? lang.notification_deactivate_admin : lang.notification_activate_admin,
                color: isActivated ? "danger" : "success"
            })
        );
    };
    if (isInInstitutionGroupType1 && isActivated) {
        return () => {
            dispatch(
                setConfirmationModalDataAction(
                    lang.doYouWantToDeactivateAdminForAllInstitutions,
                    lang.ifYouClickNoAdminWillBeDeactivatedOnlyOnThisInstitution,
                    () => onConfirm(false),
                    () => onConfirm(true),
                    lang.no,
                    lang.yes,
                    "",
                    false,
                    () => {},
                    EButtonMedgoActionClassName.medgoButtonOrange
                )
            );
        };
    }
    return () => onConfirm(false);
};

const useConfirmationModalDatas = (
    isActivated: boolean,
    institutionHasAdmin: IInstitutionHasAdmin,
    idLanguage: number
) => {
    const [lang] = useSelector(langSelector);
    const dispatch = useDispatch();
    const onConfirm = confirmActivateOrDeactivateAdmin(isActivated, institutionHasAdmin, idLanguage, dispatch);
    const subtitle = isActivated ? lang.confirmationTitle_deactivate_admin : lang.confirmationTitle_activate_admin;
    const text = isActivated ? lang.confirmationText_deactivate_admin : lang.confirmationText_activate_admin;
    const onClick1 = () => dispatch(setShowConfirmationModalAction(false));
    const onClick2 = onConfirm;
    const textButton1 = lang.cancel;
    const textButton2 = lang.confirm;
    const colorButton2 = isActivated
        ? EButtonMedgoActionClassName.medgoButtonOrange
        : EButtonMedgoActionClassName.medgoButtonSucess;
    return { subtitle, text, onClick1, onClick2, textButton1, textButton2, colorButton2 };
};

export default useConfirmationModalDatas;
