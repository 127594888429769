import { connect } from "react-redux";
import { ISpecialHour } from "../../../Model/SpecialHour";
import { getElementsInInstitution } from "../../../toolbox/filters";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { INormalizedServices, IService } from "../../../Model/Service";
import { IQueryParams } from "npm-medgo-query";
import {
    getModelsFromDict,
    getFormDictionaryRecordsDict,
    isKeyElementAlreadyInFormOrInstitutions
} from "../../modules/store/util";
import { IJob } from "../../../Model/Job";
import { fetchServicesAction } from "../../store/action/service.action";
import {
    insertSpecialHourAction,
    insertSpecialHourHasServiceAction,
    insertSpecialHourHasJobAction,
    updateSpecialHourAction
} from "../../store/action/specialHour.action";
import { fetchJobsAction } from "../../store/action/job.action";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName, IBackOfficeColumn } from "../../constants/Column.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { Equality } from "npm-medgo-toolbox";
import {
    deleteSpecialHourHasJob,
    deleteSpecialHourHasService,
    fetchSpecialHourHasJob,
    fetchSpecialHourHasService,
    insertSpecialHourHasJob,
    insertSpecialHourHasService
} from "../../service/specialHour.service";

interface IReduxPropsFormSpecialHour extends IReduxPropsForm<ISpecialHour> {
    idServices: number[];
    idJobs: number[];
    selectedMulti: number[];
    idInstitutions: number[];
    jobsById: IJob[];
    servicesById: INormalizedServices;
    specialHourDict: ISpecialHour[];
    idSpecialHour: number[];
}

interface IPropsFormSpecialHour extends IReduxPropsFormSpecialHour, IReduxActionsForm, IPropsForm<ISpecialHour> {}

interface IReduxActionsForm {
    fetchServicesAction: (param: IQueryParams) => void;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    fetchJobsAction: (param: IQueryParams) => void;
    insertSpecialHourAction: (formDictionary: IFormDictionary) => ISpecialHour;
    insertSpecialHourHasServiceAction: (idSpecialHour: number, idSerivce: number) => void;
    insertSpecialHourHasJobAction: (idSpecialHour: number, idJob: number) => void;
    updateSpecialHourAction: (id: number, specialHour: ISpecialHour) => void;
}

class FormSpecialHourComponent extends FormComponent<ISpecialHour, IPropsFormSpecialHour> {
    async componentDidMount(): Promise<void> {
        if (this.props.idJobs.length === 0)
            await this.props.fetchJobsAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
    }

    async createElementAction(): Promise<void> {
        const formDictionarySpecialHours = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.specialHour
        );
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionarySpecialHours);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                const formDictionarySpecialHour = Object.assign(
                                    {
                                        idInstitution
                                    },
                                    {
                                        ...formDictionarySpecialHours[key],
                                        seniorityShouldBeLessThan: Equality.isNullOrUndefined(
                                            formDictionarySpecialHours[key].seniorityShouldBeLessThan
                                        )
                                            ? null
                                            : formDictionarySpecialHours[key].seniorityShouldBeLessThan,
                                        seniorityShouldBeMoreThan: Equality.isNullOrUndefined(
                                            formDictionarySpecialHours[key].seniorityShouldBeMoreThan
                                        )
                                            ? null
                                            : formDictionarySpecialHours[key].seniorityShouldBeMoreThan
                                    }
                                );
                                let newSpecialHour = await this.props.insertSpecialHourAction(
                                    formDictionarySpecialHour
                                );
                                if (newSpecialHour.service) {
                                    await Promise.all(
                                        formDictionarySpecialHour.service.map(
                                            async (service: number): Promise<void> => {
                                                await this.props.insertSpecialHourHasServiceAction(
                                                    newSpecialHour.id,
                                                    service
                                                );
                                            }
                                        )
                                    );
                                }
                                if (newSpecialHour.job) {
                                    await Promise.all(
                                        formDictionarySpecialHour.job.map(
                                            async (job: number): Promise<void> => {
                                                await this.props.insertSpecialHourHasJobAction(newSpecialHour.id, job);
                                            }
                                        )
                                    );
                                }
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.specialHour]: {
                [EBackOfficeColumnName.job]: getElementsInInstitution(
                    getModelsFromDict<IJob>(this.props.jobsById, this.props.idJobs),
                    this.props.selectedMulti
                ),
                [EBackOfficeColumnName.service]: getElementsInInstitution(
                    getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                    this.props.selectedMulti
                )
            }
        };
    }

    async updateElementAction(): Promise<void> {
        let specialHour = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.specialHour)[0];
        specialHour = Object.assign(specialHour, { institution: this.props.idInstitutions[0] });
        await this.props.updateSpecialHourAction(specialHour.id, specialHour as ISpecialHour);
        const servicesToBeDeleted = this.props.modifyingElement.service.filter(
            (service: number): boolean => specialHour.service.indexOf(service) === -1
        );
        const servicesToBeAdded = specialHour.service.filter(
            (service: number): boolean => this.props.modifyingElement.service.indexOf(service) === -1
        );
        const jobsToBeDeleted = this.props.modifyingElement.job.filter(
            (job: number): boolean => specialHour.job.indexOf(job) === -1
        );
        const jobsToBeAdded = specialHour.job.filter(
            (job: number): boolean => this.props.modifyingElement.job.indexOf(job) === -1
        );
        for (let i in servicesToBeDeleted) {
            const idServiceToBeDeleted = servicesToBeDeleted[i];
            const specialHourHasService = await fetchSpecialHourHasService({
                query: { idService: idServiceToBeDeleted, idSpecialHour: specialHour.id }
            });
            deleteSpecialHourHasService(specialHourHasService[0].id);
        }
        for (let i in jobsToBeDeleted) {
            const idJobToBeDeleted = jobsToBeDeleted[i];
            const specialHourHasJob = await fetchSpecialHourHasJob({
                query: { idJob: idJobToBeDeleted, idSpecialHour: specialHour.id }
            });
            deleteSpecialHourHasJob(specialHourHasJob[0].id);
        }
        for (let i in servicesToBeAdded) {
            await insertSpecialHourHasService(specialHour.id, servicesToBeAdded[i]);
        }
        for (let i in jobsToBeAdded) {
            await insertSpecialHourHasJob(specialHour.id, jobsToBeAdded[i]);
        }
    }

    customCheck(value: string, column: IBackOfficeColumn): void {
        const formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.specialHour
        );
        if (
            column.name === EBackOfficeColumnName.name &&
            value &&
            typeof value === "string" &&
            value.trim() !== "" &&
            isKeyElementAlreadyInFormOrInstitutions({
                dict: this.props.specialHourDict,
                ids: this.props.idSpecialHour,
                selectedMultis: this.props.selectedMulti,
                formDictionaryTable,
                formDictionaryKey: value,
                key: EBackOfficeColumnName.name,
                modifyingElement: this.props.modifyingElement
            })
        ) {
            throw { columnName: EBackOfficeColumnName.code, errorCode: 409 };
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsFormSpecialHour =>
        Object.assign(getConnectParentState(centralState), {
            selectedMulti: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids,
            servicesById: centralState.services.byId,
            idServices: centralState.services.ids,
            jobsById: centralState.jobs.byId,
            idJobs: centralState.jobs.ids,
            modifyingElement: centralState.tables.modifyingElement,
            specialHourDict: centralState.specialHours.byId,
            idSpecialHour: centralState.specialHours.ids
        }),
    Object.assign(getConnectParentActions(), {
        fetchServicesAction,
        fetchJobsAction,
        insertSpecialHourAction,
        insertSpecialHourHasJobAction,
        insertSpecialHourHasServiceAction,
        updateSpecialHourAction
    })
)(FormSpecialHourComponent);
