enum NotificationAdminActionTypes {
    FETCH_NOTIFICATIONADMINS = "FETCH_NOTIFICATIONADMINS",
    SET_NOTIFICATIONADMINS = "SET_NOTIFICATIONADMINS",
    FETCH_NOTIFICATIONADMINS_COUNT = "FETCH_NOTIFICATIONADMINS_COUNT",
    SET_SELECTED_NOTIFICATIONADMIN = "SET_SELECTED_NOTIFICATIONADMIN",
    SET_SELECTED_NOTIFICATIONADMIN_MULTI = "SET_SELECTED_NOTIFICATIONADMIN_MULTI",
    SET_IS_LOADING_NOTIFICATIONADMIN = "SET_IS_LOADING_NOTIFICATIONADMIN",
    SET_NOTIFICATIONADMIN_ORDER = "SET_NOTIFICATIONADMIN_ORDER",
    INSERT_NOTIFICATIONADMIN = "INSERT_NOTIFICATIONADMIN",
    UPDATE_NOTIFICATIONADMIN = "UPDATE_NOTIFICATIONADMIN",
    DELETE_NOTIFICATIONADMIN = "DELETE_NOTIFICATIONADMIN",
    SET_NOTIFICATIONADMIN_ERROR_CODE = "SET_NOTIFICATIONADMIN_ERROR_CODE"
}
export default NotificationAdminActionTypes;
