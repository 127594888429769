import { connect } from "react-redux";
import { setServicesAction, fetchServicesAction, setIsLoadingServiceAction } from "../../store/action/service.action";

import { fetchPolesAction } from "../../store/action/pole.action";
import { IService } from "../../../Model/Service";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedServices } from "../../../Model/Service";
import { getModelsFromDict } from "../../modules/store/util";
import { INormalizedPoles } from "../../../Model/Pole";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";
import { ServiceParkingLabel } from "../../constants/SelectOptions.constants";

//@ts-ignore
interface IReduxPropsService extends IReduxPropsTable {
    idServices: number[];
    idPoles: number[];
    serviceDict: INormalizedServices;
    poleDict: INormalizedPoles;
    idInstitutions: number[];
    searchValue: string;
}

interface IPropsService extends IReduxPropsService, IReduxActionsService, IPropsTable {}

interface IReduxActionsService extends IReduxActionsTable {
    setServicesAction: (elements: IService[]) => any;
    fetchServicesAction: (param: IQueryParams, poleDict: INormalizedPoles) => any;
    fetchPolesAction: (param: IQueryParams) => any;
    setIsLoadingServiceAction: (isLoading: boolean) => any;
}

class TableServiceContainer extends TableComponent<IPropsService> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingServiceAction(true);
        if (this.props.idPoles.length === 0)
            await this.props.fetchPolesAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
        if (this.props.idServices.length === 0) await this.props.fetchServicesAction(params, this.props.poleDict);
        this.props.setIsLoadingServiceAction(false);
    }

    setElements(elements: any): void {
        this.props.setServicesAction(elements);
    }

    getElements(): any[] {
        this.props.idServices.forEach((idService: number): void => {
            const parkingVal = this.props.serviceDict[idService].parking;
            const parkingLabel = parkingVal ? ServiceParkingLabel[parkingVal] : null;

            this.props.serviceDict[idService] = Object.assign(this.props.serviceDict[idService], {
                codeUF: this.props.serviceDict[idService].code,
                parking: parkingLabel
            });
        });
        return getModelsFromDict(this.props.serviceDict, this.props.idServices).filter(
            (e): boolean =>
                !this.props.searchValue ||
                isIncludedStringCaseInsensitive(e.name, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.nameSMS, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.code, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.poleName, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.interfacingCode, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.interfacingCode2, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.interfacingCode3, this.props.searchValue)
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsService =>
        Object.assign(getConnectParentState(centralState), {
            idServices: centralState.services.ids,
            idPoles: centralState.poles.ids,
            poleDict: centralState.poles.byId,
            serviceDict: centralState.services.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids,
            isLoading: centralState.services.isLoading,
            searchValue: centralState.tables.searchValue
        }),
    Object.assign(getConnectParentActions(), {
        setServicesAction,
        fetchServicesAction,
        fetchPolesAction,
        setIsLoadingServiceAction
    })
)(TableServiceContainer);
