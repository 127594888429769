import { IPublicholiday } from "../../Model/Publicholiday";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const PublicholidayDefaultSelect = ["id", "institution", "date"];

export async function fetchPublicholidays(query: IQueryParams): Promise<IPublicholiday[]> {
    const publicholidays = await postFetchFactory<IPublicholiday>(
        PossibleFetchs.publicholiday,
        PossibleApis.hubloExport
    )(
        {
            select: PublicholidayDefaultSelect
        },
        query
    );
    return publicholidays;
}

export async function fetchPublicholidaysCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IPublicholiday>(PossibleFetchs.publicholiday, PossibleApis.hubloExport)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertPublicholiday(publicholiday: IPublicholiday): Promise<IPublicholiday> {
    return await postFactory<IPublicholiday>(PossiblePosts.publicholiday, PossibleApis.hubloExport)(publicholiday);
}

export async function updatePublicholiday(id: number, publicholiday: IPublicholiday): Promise<IPublicholiday> {
    return await updateFactory<IPublicholiday>(PossibleUpdates.publicholiday, PossibleApis.hubloExport)(
        id,
        publicholiday
    );
}

export async function deletePublicholiday(id: number): Promise<IPublicholiday> {
    return await deleteFactory<IPublicholiday>(PossibleDeletes.publicholiday, PossibleApis.hubloExport)(id);
}
