import { IInterimAgencyHasJob } from "../../Model/InterimAgencyHasJob";
import { PossibleApis, PossibleFetchs } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import axiosInstance from "../../modules/axios/axios.instance";

const InterimAgenciesHasJobsDefaultSelect = ["id", "idInterimAgency", "idJob"];

export async function fetchInterimAgenciesHasJobs(query: IQueryParams): Promise<IInterimAgencyHasJob[]> {
    const interimAgenciesHasJobs = await postFetchFactory<IInterimAgencyHasJob>(
        PossibleFetchs.interimAgencyHasJob,
        PossibleApis.medgoApi
    )(
        {
            select: InterimAgenciesHasJobsDefaultSelect
        },
        query
    );
    return interimAgenciesHasJobs;
}

export async function insertSubscriptionJobs(params: {
    idSubscription: string;
    idLegacySubscription: number;
    idInstitution: number;
    jobs: number[];
    specialties: number[];
}): Promise<void> {
    const { idSubscription, idInstitution, idLegacySubscription, specialties, jobs } = params;
    const payload = {
        idLegacySubscription,
        jobs,
        specialties
    };
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency/subscriptions/${idSubscription}/jobs`;
    await axiosInstance.post<{ id: string }>(url, payload);
}

export async function deleteSubscriptionJobs(params: {
    idSubscription: string;
    idLegacySubscription: number;
    idInstitution: number;
    jobs: number[];
    specialties: number[];
}): Promise<void> {
    const { idSubscription, idInstitution, idLegacySubscription, specialties, jobs } = params;
    const payload = {
        idLegacySubscription,
        jobs,
        specialties
    };
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency/subscriptions/${idSubscription}/jobs`;
    await axiosInstance.delete<{ id: string }>(url, { data: payload });
}
