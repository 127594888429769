import { IContractSalaryGrid, ICreateContractSalaryGridRequest } from "../../Model/ContractSalaryGrid";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const ContractSalaryGridDefaultSelect = ["id", "name", "institution"];

export async function fetchContractSalaryGrids(query: IQueryParams): Promise<IContractSalaryGrid[]> {
    const contractSalaryGrids = await postFetchFactory<IContractSalaryGrid>(
        PossibleFetchs.contractSalaryGrid,
        PossibleApis.medgoApi
    )(
        {
            select: ContractSalaryGridDefaultSelect
        },
        query
    );
    return contractSalaryGrids;
}

export async function fetchInstitutionHasContractSalaryGrids(query: IQueryParams): Promise<IContractSalaryGrid[]> {
    const institutionHasContractSalaryGrids = await postFetchFactory<IContractSalaryGrid>(
        PossibleFetchs.institutionHasContractSalaryGrid,
        PossibleApis.medgoApi
    )(
        {
            select: ["id", "institution", "contractSalaryGrid"]
        },
        query
    );
    return institutionHasContractSalaryGrids;
}

export async function insertInstitutionHasContractSalaryGrid(
    contractSalaryGrid: ICreateContractSalaryGridRequest
): Promise<ICreateContractSalaryGridRequest> {
    return await postFactory<ICreateContractSalaryGridRequest>(
        PossiblePosts.contractSalaryGrid,
        PossibleApis.medgoApi
    )(contractSalaryGrid);
}

export async function updateContractSalaryGrid(
    id: number,
    contractSalaryGrid: IContractSalaryGrid
): Promise<IContractSalaryGrid> {
    return await updateFactory<IContractSalaryGrid>(PossibleUpdates.contractSalaryGrid, PossibleApis.medgoApi)(
        id,
        contractSalaryGrid
    );
}

export async function deleteContractSalaryGrid(id: number): Promise<IContractSalaryGrid> {
    return await deleteFactory<IContractSalaryGrid>(PossibleDeletes.contractSalaryGrid, PossibleApis.medgoApi)(id);
}

export async function deleteInstitutionHasContractSalaryGrid(id: number): Promise<IContractSalaryGrid> {
    return await deleteFactory<IContractSalaryGrid>(
        PossibleDeletes.institutionHasContractSalaryGrid,
        PossibleApis.medgoApi
    )(id);
}
