import {
    IBackOfficeColumn,
    EBackOfficeColumnType,
    EBackOfficeColumnName,
    IFormCategoryColumns
} from "../../src/constants/Column.constants";
import { EBackOfficeTableName, IBackOfficeTable } from "../../src/constants/Table.constants";
import {
    selectContractLevelOptions,
    selectLangOptions,
    selectTypeAdminOptions
} from "../../src/constants/SelectOptions.constants";
import { ILevel } from "../../Model/Level";
import { IService } from "../../Model/Service";
import { getPolesOfNotificationServices } from "../filters";

export function getArrayFormColumns(backOfficeTable: IBackOfficeTable): IBackOfficeColumn[] {
    if (backOfficeTable.formCategoriesColumns) {
        const columns: IBackOfficeColumn[] = [];
        backOfficeTable.formCategoriesColumns[0].forEach((categoryColumn: IFormCategoryColumns) =>
            categoryColumn.columns.forEach((column: IBackOfficeColumn) => columns.push(column))
        );
        return columns;
    }
    return backOfficeTable.formColumns ? backOfficeTable.formColumns[0] : backOfficeTable.columns[0];
}

export async function putModifyingElementInForm(
    backOfficeTable: IBackOfficeTable,
    element: any,
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void,
    allLevelsById: ILevel[],
    setInitialFormAction: (form: object) => void,
    allServices: IService[],
    setIsLoadingFormAction: (isLoadingForm: boolean) => void
): Promise<void> {
    const columns = getArrayFormColumns(backOfficeTable);
    if (!element) return;
    const initialForm = {};
    columns.forEach((column: IBackOfficeColumn): void => {
        let columnName = column.name;
        let value = element[columnName];
        if (column.type === EBackOfficeColumnType.boolean) value = element[columnName] ? "true" : "false";
        if (column.type === EBackOfficeColumnType.select) value = { value: value, label: value };
        if (column.type === EBackOfficeColumnType.select && columnName.concat("Name") in EBackOfficeColumnName)
            value =
                element[columnName] !== null
                    ? { value: element[name], label: element[columnName.concat("Name")] }
                    : null;
        if (columnName === EBackOfficeColumnName.notificationServices) {
            value = element.service;
            setFormKeyValueAction(
                EBackOfficeColumnName.notificationPoles,
                getPolesOfNotificationServices(allServices, value),
                backOfficeTable.name[0],
                column.type,
                0
            );
        }
        if (columnName === EBackOfficeColumnName.typeAdmin) {
            const typeAdmin = value.value ?? value;
            value = selectTypeAdminOptions.typeAdmin.find(e => e.value === typeAdmin);
            if (backOfficeTable.name.indexOf(EBackOfficeTableName.admin) !== -1) {
                value = value.value;
            }
        }
        if (columnName === EBackOfficeColumnName.contractLevel) {
            const contractLevel = value.value ?? value;
            value = selectContractLevelOptions.contractLevel.find(e => e.value === contractLevel);
            if (backOfficeTable.name.indexOf(EBackOfficeTableName.admin) !== -1) {
                value = value.value;
            }
        }
        if (columnName === EBackOfficeColumnName.lang) {
            value = selectLangOptions.lang.find(e => e.value === value.value);
        }
        if (columnName === EBackOfficeColumnName.level) {
            const adminLevel = value.value ?? value;
            const label = allLevelsById.find(e => e.id === adminLevel)?.name;
            value = {
                value: adminLevel,
                label: `${label === adminLevel.toString() ? label : `${adminLevel}: ${label}`}`
            };
            if (backOfficeTable.name.indexOf(EBackOfficeTableName.admin) !== -1) {
                value = value.value;
            }
        }
        setFormKeyValueAction(columnName, value, backOfficeTable.name[0], column.type, 0);
        Object.assign(initialForm, { [columnName]: value });
    });
    setInitialFormAction(initialForm);
    setIsLoadingFormAction(false);
}
