import store from "../../store";
import { fr } from "../../lang/fr";
import { langs } from "../../lang/index";
import { ILang } from "../../lang/Lang";

export function getLang(): any {
    const state = store.getState();
    let lang = fr;
    if (state.language !== null && state.language !== undefined) {
        lang = ((state.language as unknown) as { lang: ILang }).lang;
    }
    return lang;
}

export function isInAnyLang(key: string, value: string): boolean {
    for (const [langName, lang] of Object.entries(langs)) {
        langName as unknown;
        // @ts-ignore
        if (lang[key] === value) return true;
    }
    return false;
}
