import { AvailableLangs } from "../store/reducer/lang.reducer";

function storeLangFromLocalStorage(this: any): void {
    const lang = localStorage.getItem("lang");
    const validLangs = Object.values(AvailableLangs) as string[];
    if (lang && validLangs.includes(lang)) {
        this.props.setLangAction(lang);
    } else {
        this.props.setLangAction(AvailableLangs.fr);
    }
}

export function implementHookStoreLangFromLocalStorage(container: IImplementHookStoreLangFromLocalStorage): void {
    container.storeLangFromLocalStorage = (): void => storeLangFromLocalStorage.bind(container)();
}
export interface IImplementHookStoreLangFromLocalStorage {
    storeLangFromLocalStorage: () => void;
}
