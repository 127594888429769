import { IReduxAction } from "../store.type";
import InfoOnWorkerTypes from "../type/infoOnWorker.type";
import {
    fetchInfoOnWorkers,
    insertInfoOnWorker,
    insertInstitutionHasInfoOnWorker,
    deleteInfoOnWorker
} from "../../service/infoOnWorker.service";
import { IInfoOnWorker, INormalizedInfoOnWorkers } from "../../../Model/InfoOnWorker";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInfoOnWorkersAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const infoOnWorkers = await fetchInfoOnWorkers(query);
            dispatch({
                type: InfoOnWorkerTypes.FETCH_INFOONWORKERS,
                payload: {
                    byId: infoOnWorkers.reduce(
                        (p: INormalizedInfoOnWorkers, c: IInfoOnWorker): INormalizedInfoOnWorkers => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: infoOnWorkers.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchAllInfoOnWorkerAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const infoOnWorkers = await fetchInfoOnWorkers(query);
            dispatch({
                type: InfoOnWorkerTypes.FETCH_ALL_INFOONWORKERS,
                payload: {
                    byId: infoOnWorkers.reduce(
                        (p: INormalizedInfoOnWorkers, c: IInfoOnWorker): INormalizedInfoOnWorkers => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: infoOnWorkers.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInfoOnWorkerAction(infoOnWorker: IInfoOnWorker): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInfoOnWorker = await insertInfoOnWorker(infoOnWorker);
            dispatch({
                type: InfoOnWorkerTypes.INSERT_INFOONWORKER,
                payload: newInfoOnWorker
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasInfoOnWorkerAction(
    idInfoOnWorker: number,
    idInstitution: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInfoOnWorker = await insertInstitutionHasInfoOnWorker(idInfoOnWorker, idInstitution);
            dispatch({
                type: InfoOnWorkerTypes.INSERT_INFOONWORKER,
                payload: {
                    infoOnWorker: newInfoOnWorker
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInfoOnWorkerAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInfoOnWorker(id);
            dispatch({
                type: InfoOnWorkerTypes.DELETE_INFOONWORKER
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInfoOnWorkersAction(dict: INormalizedInfoOnWorkers): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_INFOONWORKERS,
            payload: dict
        });
    };
}

export function setSelectedInfoOnWorkerAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_SELECTED_INFOONWORKER,
            payload: id
        });
    };
}

export function setSelectedInfoOnWorkerMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_SELECTED_INFOONWORKER_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInfoOnWorkerAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_IS_LOADING_INFOONWORKER,
            payload: isLoading
        });
    };
}
export function setInfoOnWorkerOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_INFOONWORKER_ORDER,
            payload: idsSorted
        });
    };
}

export function setInfoOnWorkerErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InfoOnWorkerTypes.SET_INFOONWORKER_ERROR_CODE,
            payload: errorCode
        });
    };
}
