import React, { Component } from "react";

import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import { IBackOfficeColumn, EBackOfficeColumnType } from "../../constants/Column.constants";
import {
    SelectCheckboxListComponent,
    HubloFontAwesome,
    EMedgoIcon,
    EMedgoColor,
    IOptionsSelectCheckboxList,
    EAvailableLang
} from "npm-medgo-components";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import moment from "moment";
import { ILang } from "../../lang/Lang";
import "./Box.scss";

interface IReduxPropsBoxView {
    currentLang: EAvailableLang;
    lang: ILang;
    me: IAdmin;
}

interface IPropsBoxView extends IReduxPropsBoxView {
    value: any;
    column: IBackOfficeColumn;
    table: EBackOfficeTableName;
    selectCheckboxListOptions?: IOptionsSelectCheckboxList[];
    hasError?: boolean;
}

class BoxView extends Component<IPropsBoxView> {
    renderBool(): JSX.Element {
        return (
            <HubloFontAwesome
                icon={this.props.value ? EMedgoIcon.check : EMedgoIcon.times}
                color={this.props.value ? EMedgoColor.emerald : EMedgoColor.red}
            />
        );
    }

    renderCheckboxList(): JSX.Element {
        return (
            <SelectCheckboxListComponent
                hasBadge={true}
                values={this.props.value ? this.props.value : []}
                options={this.props.selectCheckboxListOptions}
                hasSearchBox={true}
                className="form-control is-border-radius-right-only is-border-radius-left-only is-gray"
                currentLang={this.props.currentLang}
                onChange={(): void => {
                    return;
                }}
            />
        );
    }

    renderSpan(content: any): JSX.Element {
        return (
            <span data-tag="allowRowEvents" className={`span-line ${this.props.hasError ? "error-message" : ""}`}>
                {content}
            </span>
        );
    }

    renderDate(content: any): JSX.Element {
        return (
            <span data-tag="allowRowEvents" className={`span-line ${this.props.hasError ? "error-message" : ""}`}>
                {moment(content, "DD-MM-YYYY")
                    .locale(this.props.currentLang)
                    .format("LL")}{" "}
            </span>
        );
    }
    renderBadge(): JSX.Element {
        return (
            <span data-tag="allowRowEvents" className={`badge ${this.props.value ? "isActive" : ""}`}>
                {this.props.value ? this.props.lang.active : this.props.lang.inactive}
            </span>
        );
    }

    render(): JSX.Element {
        let JSXToRender;
        switch (this.props.column.type) {
            case EBackOfficeColumnType.boolean:
                JSXToRender = this.renderBool();
                break;
            case EBackOfficeColumnType.selectCheckbox:
                JSXToRender = this.renderCheckboxList();
                break;
            case EBackOfficeColumnType.hour:
                JSXToRender = this.renderSpan(this.props.value ? this.props.value : "00:00");
                break;
            case EBackOfficeColumnType.date:
                JSXToRender = this.renderDate(this.props.value);
                break;
            case EBackOfficeColumnType.badge:
                JSXToRender = this.renderBadge();
                break;
            default:
                JSXToRender = this.renderSpan(this.props.value);
                break;
        }
        return JSXToRender;
    }
}

export default connect(
    (centralState: any): IReduxPropsBoxView => ({
        lang: centralState.language.lang,
        me: centralState.auth.user,
        currentLang: centralState.language.currentLang
    }),
    {}
)(BoxView);
