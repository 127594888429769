import { ISchedule } from "../../Model/Schedule";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import { stringHHmmToMinutes, stringHHmmToUtcMoment } from "../../toolbox/date";

const ScheduleDefaultSelect = [
    "beginAt",
    "code",
    "endAt",
    "id",
    "interfacingCode",
    "nbWorkedMinutes",
    "institution",
    "isDeleted"
];

export async function fetchSchedules(query: IQueryParams): Promise<ISchedule[]> {
    const data = await postFetchFactory<ISchedule>(PossibleFetchs.schedule, PossibleApis.medgoApi)(
        {
            select: ScheduleDefaultSelect
        },
        query
    );
    const sanitizedData = data.map(
        (elem: ISchedule): ISchedule => {
            if (elem.interfacingCode?.trim() === "") elem.interfacingCode = undefined;
            return elem;
        }
    );
    return sanitizedData;
}

export async function insertInstitutionHasSchedule(schedule: number, institution: number): Promise<ISchedule> {
    return await postFactory<any>(
        PossiblePosts.institutionHasSchedule,
        PossibleApis.medgoApi
    )({
        schedule,
        institution
    });
}

export async function insertBatchInstitutionHasSchedule(schedules: number[], institution: number): Promise<ISchedule> {
    return await postFactory<any>(
        PossiblePosts.batchInstitutionHasSchedule,
        PossibleApis.medgoApi
    )({
        schedules,
        institution
    });
}

export async function upsertBatchSchedule(batch: ISchedule[], IDInstitution: number): Promise<any> {
    return await postFactory<{ batch: ISchedule[]; IDInstitution: number }>(
        PossiblePosts.upsertBatchSchedule,
        PossibleApis.medgoApi
    )({ batch, IDInstitution });
}

export async function insertSchedule(schedule: ISchedule): Promise<ISchedule> {
    return await postFactory<ISchedule>(PossiblePosts.schedule, PossibleApis.medgoApi)(schedule);
}

export async function updateSchedule(id: number, schedule: ISchedule): Promise<ISchedule> {
    return await updateFactory<ISchedule>(PossibleUpdates.schedule, PossibleApis.medgoApi)(id, schedule);
}

export async function deleteSchedule(id: number): Promise<ISchedule> {
    return await deleteFactory<ISchedule>(PossibleDeletes.schedule, PossibleApis.medgoApi)(id);
}

export function formatScheduleTimesFormats(schedule: any): ISchedule {
    schedule.beginAt = stringHHmmToUtcMoment(schedule.beginAt);
    schedule.endAt = stringHHmmToUtcMoment(schedule.endAt);
    schedule.nbWorkedMinutes = stringHHmmToMinutes(schedule.nbWorkedMinutes);
    return schedule;
}
