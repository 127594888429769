import { connect } from "react-redux";
import {
    setInstitutionsAction,
    fetchInstitutionsAction,
    setIsLoadingInstitutionAction
} from "../../store/action/institution.action";

import { IInstitution } from "../../../Model/Institution";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";
import { INormalizedInstitutions } from "../../../Model/Institution";

//@ts-ignore
interface IReduxPropsTableInstitution extends IReduxPropsTable {
    institutionDict: INormalizedInstitutions;
    idInstitutions: number[];
}

interface IPropsTableInstitution extends IReduxPropsTableInstitution, IReduxActionsTableInstitution, IPropsTable {}

interface IReduxActionsTableInstitution extends IReduxActionsTable {
    setInstitutionsAction: (elements: IInstitution[]) => any;
    fetchInstitutionsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionAction: (isLoading: boolean) => any;
}

class TableInstitutionContainer extends TableComponent<IPropsTableInstitution> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionAction(true);
        if (this.props.idInstitutions.length === 0) await this.props.fetchInstitutionsAction(params);
        this.props.setIsLoadingInstitutionAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<IInstitution>(this.props.institutionDict, this.props.idInstitutions);
    }

    filterElements(elements: any[], idSelectedMultiInstitutions: number[]): any[] {
        if (idSelectedMultiInstitutions && idSelectedMultiInstitutions.length > 0)
            return elements.filter((e): boolean => idSelectedMultiInstitutions.includes(e.id));
        return elements;
    }
}

export default connect(
    (centralState: any): IReduxPropsTableInstitution =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutions: centralState.institutions.ids,
            institutionDict: centralState.institutions.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.institutions.isLoading
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionsAction,
        fetchInstitutionsAction,
        setIsLoadingInstitutionAction
    })
)(TableInstitutionContainer);
