import React, { Component } from "react";
import { connect } from "react-redux";
import View from "./AdminProfile.view";
import { IAdmin } from "../../../Model/Admin";
import { ILang } from "../../lang/Lang";
import { IQueryParams } from "npm-medgo-query";
import { fetchAdminsAction, updateAdminAction } from "../../store/action/admin.action";
import { FetchLimits } from "../../../Constant/Api.constant";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { checkPasswordConfirmIsHere, checkPasswordIsHere } from "npm-medgo-toolbox/dist/modules/check";
import { checkMultiple, checkFirstName, checkLastName } from "../../../toolbox/checks";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { isNullOrUndefined } from "util";
import { setFormDictionaryAction } from "../../store/action/form.action";
import { setLangAction } from "../../store/action/lang.action";
import { EAvailableLang } from "@hublo/legacy-components";
import { DefaultLangConstant, LangConstants } from "npm-hublo-toolbox/dist/modules/constant/international.constant";
import { updateAdminInfo } from "../../service/adminInfo.service";
import { IAdminInfo } from "../../../Model/AdminInfo";
import AxiosInstance from "../../../modules/axios/axios.instance";

interface IReduxPropsAdminProfileContainer {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
    idAdmins: number[];
    adminDict: IAdmin[];
    formDictionary: IFormDictionary;
}

interface IReduxActionAdminProfileContainer {
    fetchAdminsAction: (param: IQueryParams) => any;
    updateAdminAction: (id: number, admin: IAdmin) => void;
    setFormDictionaryAction: (selectedOption: { [key: string]: any }) => void;
    setLangAction: (lang: string) => any;
}

interface IStateAdminProfileContainer {
    errorMessagePassword: string;
    errorMessageProfile: string;
    infoMessageProfile: string;
    infoMessagePassword: string;
    isLoadingProfile: boolean;
    isLoadingPassword: boolean;
    password: string;
    newPassword: string;
    confirmPassword: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPropsAdminProfileContainer extends IReduxPropsAdminProfileContainer, IReduxActionAdminProfileContainer {}

class AdminProfileContainer extends Component<IPropsAdminProfileContainer, IStateAdminProfileContainer> {
    constructor(props: IPropsAdminProfileContainer) {
        super(props);

        const newHost = process.env.REACT_APP_MONOREPO_BASE_URL;
        if (typeof newHost === "string") {
            window.location.replace(newHost.concat("/hr-management/me/profile"));
        }

        this.state = {
            errorMessagePassword: "",
            errorMessageProfile: "",
            infoMessagePassword: "",
            infoMessageProfile: "",
            isLoadingPassword: false,
            isLoadingProfile: false,
            password: "",
            newPassword: "",
            confirmPassword: ""
        };
    }

    async componentDidMount(): Promise<void> {
        this.updateAdmin(this.props.me.id);
    }

    async updateAdmin(adminId: any): Promise<void> {
        await this.props
            .fetchAdminsAction({
                select: ["id", "firstName", "lastName", "mobilePhone", "email"],
                limit: FetchLimits.admin,
                query: { id: adminId }
            })
            .catch(console.error);
    }

    getAdmin(idAdmin: number): IAdmin {
        let unsetAdmin = {};
        if (this.props.adminDict) {
            let adminList = getModelsFromDict<IAdmin>(this.props.adminDict, this.props.idAdmins);
            for (let i = 0; i < adminList.length; i++) {
                if (adminList[i].id == idAdmin) {
                    return adminList[i] as IAdmin;
                }
            }
        }
        return unsetAdmin as IAdmin;
    }

    hasColumnChanged(field: string): boolean {
        if (getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.admin)[0]) {
            if (typeof getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.admin)[0]?.field) {
                return true;
            }
        }
        return false;
    }

    handleChange(key: string, value: string): void {
        if (this.state.hasOwnProperty(key)) {
            switch (key) {
                case "password":
                    this.setState({
                        password: value
                    });
                    break;
                case "newPassword":
                    this.setState({
                        newPassword: value
                    });
                    break;
                case "confirmPassword":
                    this.setState({
                        confirmPassword: value
                    });
                    break;
                default:
                    break;
            }
        }
    }

    async updatePassword(): Promise<void> {
        const checks: any[] = [];
        checks.push(checkPasswordIsHere(this.state.password));
        checks.push(checkPasswordConfirmIsHere(this.state.newPassword, this.state.confirmPassword));
        const { errorMessage } = checkMultiple(checks);
        if (!isNullOrUndefined(errorMessage)) {
            this.setState({
                errorMessagePassword: errorMessage,
                infoMessagePassword: ""
            });
            return;
        }
        this.setState({ isLoadingPassword: true });
        const scope = "Scope::Match:Root";
        const parameters = {
            currentPassword: this.state.password,
            newPassword: this.state.newPassword,
            scope
        };
        try {
            await AxiosInstance.put(`${process.env.REACT_APP_SERVICE_AUTH_URL}/password/change-password`, parameters);
        } catch (err) {
            console.error(err);
            this.setState({
                errorMessagePassword: this.props.lang.identifyOrPasswordIncorrect,
                infoMessagePassword: "",
                isLoadingPassword: false
            });
            return;
        }
        this.setState({
            errorMessagePassword: "",
            infoMessagePassword: this.props.lang.modificationsSaved,
            isLoadingPassword: false
        });
    }

    async updateProfile(postData: any): Promise<void> {
        const checks = [];
        checks.push(checkLastName(postData.lastName, this.props.lang));
        checks.push(checkFirstName(postData.firstName, this.props.lang));
        const { errorMessage } = checkMultiple(checks);
        if (!isNullOrUndefined(errorMessage)) {
            this.setState({
                errorMessageProfile: errorMessage,
                infoMessageProfile: ""
            });
            return;
        }
        this.setState({ isLoadingProfile: true });
        try {
            this.props.updateAdminAction(postData.id, postData);
            this.setState({
                errorMessageProfile: "",
                infoMessageProfile: this.props.lang.modificationsSaved,
                isLoadingProfile: false
            });
        } catch (err) {
            console.error(err);
            if (err.responseText) {
                this.setState({
                    errorMessageProfile: err.responseText,
                    infoMessageProfile: "",
                    isLoadingProfile: false
                });
            } else {
                this.setState({
                    errorMessageProfile: err,
                    infoMessageProfile: "",
                    isLoadingProfile: false
                });
            }
            let emptyFormDictionary: IFormDictionary = {};
            this.props.setFormDictionaryAction(emptyFormDictionary);
        }
    }

    saveChanges(): void {
        if (this.props.formDictionary && this.props.formDictionary.hasOwnProperty("admin")) {
            let adminList: IAdmin[];
            adminList = [];
            if (this.props.adminDict) {
                adminList = getModelsFromDict<IAdmin>(this.props.adminDict, this.props.idAdmins);
                if (adminList.length > 0) {
                    const admin = adminList[0];
                    let firstName =
                        typeof this.props.formDictionary.admin[0].firstName !== "undefined"
                            ? this.props.formDictionary.admin[0].firstName
                            : admin.firstName;
                    let lastName =
                        typeof this.props.formDictionary.admin[0].lastName !== "undefined"
                            ? this.props.formDictionary.admin[0].lastName
                            : admin.lastName;
                    let mobilePhone =
                        typeof this.props.formDictionary.admin[0].mobilePhone !== "undefined"
                            ? this.props.formDictionary.admin[0].mobilePhone
                            : admin.mobilePhone;
                    let email =
                        typeof this.props.formDictionary.admin[0].email !== "undefined"
                            ? this.props.formDictionary.admin[0].email
                            : admin.email;
                    let postData = {
                        id: admin.id,
                        firstName: firstName,
                        lastName: lastName,
                        mobilePhone: mobilePhone,
                        email: email
                    };
                    this.updateProfile(postData);
                }
            }
        }
    }

    async updateLanguage(lang: EAvailableLang): Promise<void> {
        const LangConstant = LangConstants.find((e: any): any => e.lang === lang);
        const idLanguage = LangConstant ? LangConstant.idLanguage : DefaultLangConstant.idLanguage;
        const postData: IAdminInfo = {
            admin: this.props.me.id,
            idLanguage
        };

        try {
            this.props.setLangAction(lang);
            await updateAdminInfo(this.props.me.id, postData);
        } catch (err) {
            console.error(err);
        }
    }

    render(): JSX.Element {
        return (
            <div>
                <View
                    errorMessageProfile={this.state.errorMessageProfile}
                    errorMessagePassword={this.state.errorMessagePassword}
                    infoMessageProfile={this.state.infoMessageProfile}
                    infoMessagePassword={this.state.infoMessagePassword}
                    isLoadingProfile={this.state.isLoadingProfile}
                    isLoadingPassword={this.state.isLoadingPassword}
                    hasColumnChanged={this.hasColumnChanged.bind(this)}
                    password={this.state.password}
                    newPassword={this.state.newPassword}
                    confirmPassword={this.state.confirmPassword}
                    handleChange={this.handleChange.bind(this)}
                    saveChanges={this.saveChanges.bind(this)}
                    updatePassword={this.updatePassword.bind(this)}
                    updateLanguage={this.updateLanguage.bind(this)}
                />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsAdminProfileContainer => ({
        currentLang: centralState.language.currentLang,
        lang: centralState.language.lang,
        me: centralState.auth.user,
        formDictionary: centralState.form.formDictionary,
        idAdmins: centralState.admins.ids,
        adminDict: centralState.admins.byId
    }),
    {
        setFormDictionaryAction,
        fetchAdminsAction,
        updateAdminAction,
        setLangAction
    }
)(AdminProfileContainer);
