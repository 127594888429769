import { connect } from "react-redux";
import {
    insertNotificationContractAction,
    updateNotificationContractAction,
    insertInstitutionHasNotificationContractAction
} from "../../store/action/notificationContract.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { INotificationContract } from "../../../Model/NotificationContract";
import { IFormDictionary } from "../../store/type/form.type";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";

interface IPropsFormNotificationContract
    extends IReduxPropsForm<INotificationContract>,
        IReduxActionsForm,
        IPropsForm<INotificationContract> {}

interface IReduxActionsForm {
    insertNotificationContractAction: (formDictionary: IFormDictionary) => number;
    insertInstitutionHasNotificationContractAction: (notificationContract: number, idInstitution: number) => void;
    updateNotificationContractAction: (id: number, notificationContract: INotificationContract) => void;
}

class FormNotificationContractComponent extends FormComponent<INotificationContract, IPropsFormNotificationContract> {
    async createElementAction(): Promise<void> {
        const formDictionaryNotificationContracts = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.notification
        );
        const selectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionaryNotificationContracts);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryNotificationContract = formDictionaryNotificationContracts[key];
                    const idNotificationContract = await this.props.insertNotificationContractAction(
                        formDictionaryNotificationContract
                    );
                    await Promise.all(
                        selectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasNotificationContractAction(
                                    idNotificationContract,
                                    idInstitution
                                );
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        const notificationContract = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.notification
        )[0];
        await this.props.updateNotificationContractAction(
            notificationContract.id,
            notificationContract as INotificationContract
        );
    }
}

export default connect(
    (centralState: any): IPropsFormNotificationContract => Object.assign(getConnectParentState(centralState), {}),
    Object.assign(getConnectParentActions(), {
        insertNotificationContractAction,
        updateNotificationContractAction,
        insertInstitutionHasNotificationContractAction
    })
)(FormNotificationContractComponent);
