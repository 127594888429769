import { IReduxAction } from "../store.type";
import InstitutionTypes from "../type/institution.type";
import {
    fetchInstitutions,
    insertInstitution,
    updateInstitution,
    deleteInstitution,
    fetchInstitutionGTAs
} from "../../service/institution.service";
import { IInstitution, INormalizedInstitutions } from "../../../Model/Institution";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutions = await fetchInstitutions(query);
            await Promise.all(
                institutions.map(
                    async (institution): Promise<void> => {
                        const institutionGTAs = await fetchInstitutionGTAs({
                            select: ["id"],
                            query: { institution: institution.id }
                        });
                        if (institutionGTAs.length > 0) {
                            institution.idInstitutionGTA = institutionGTAs[0].id;
                        }
                    }
                )
            );
            dispatch({
                type: InstitutionTypes.FETCH_INSTITUTIONS,
                payload: {
                    byId: institutions.reduce(
                        (p: INormalizedInstitutions, c: IInstitution): INormalizedInstitutions => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutions.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionAction(institution: IInstitution): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertInstitution(institution);
            dispatch({
                type: InstitutionTypes.INSERT_INSTITUTION
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionAction(
    id: number,
    institution: IInstitution
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitution = await updateInstitution(id, institution);
            dispatch({
                type: InstitutionTypes.UPDATE_INSTITUTION,
                payload: updatedInstitution
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitution(id);
            dispatch({
                type: InstitutionTypes.DELETE_INSTITUTION
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionsAction(dict: INormalizedInstitutions): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_INSTITUTIONS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_SELECTED_INSTITUTION,
            payload: id
        });
    };
}

export function setSelectedInstitutionMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_SELECTED_INSTITUTION_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_IS_LOADING_INSTITUTION,
            payload: isLoading
        });
    };
}
export function setInstitutionOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_INSTITUTION_ORDER,
            payload: idsSorted
        });
    };
}
