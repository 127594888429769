import { IReduxAction } from "../store.type";
import PublicholidayTypes from "../type/publicholiday.type";

const initialState = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};

export default function publicholidayReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case PublicholidayTypes.FETCH_PUBLICHOLIDAYS:
        case PublicholidayTypes.SET_PUBLICHOLIDAYS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] };

        case PublicholidayTypes.FETCH_PUBLICHOLIDAYS_COUNT:
            return { ...state, count: action.payload };

        case PublicholidayTypes.SET_SELECTED_PUBLICHOLIDAY:
            return { ...state, selectedId: action.payload };

        case PublicholidayTypes.SET_SELECTED_PUBLICHOLIDAY_MULTI:
            return { ...state, selectedMulti: action.payload };

        case PublicholidayTypes.SET_IS_LOADING_PUBLICHOLIDAY:
            return { ...state, isLoading: action.payload };

        case PublicholidayTypes.SET_PUBLICHOLIDAY_ORDER:
            return { ...state, ids: action.payload };

        case PublicholidayTypes.INSERT_PUBLICHOLIDAY:
        case PublicholidayTypes.UPDATE_PUBLICHOLIDAY: {
            const byId = { ...state.byId };
            const ids = [...state.ids];
            if (byId[action.payload.id]) {
                byId[action.payload.id] = Object.assign({}, action.payload);
            } else {
                byId[action.payload.id] = Object.assign({}, byId[action.payload.id], action.payload);
            }
            if (ids.indexOf(action.payload.id) === -1) {
                ids.push(action.payload.id);
            }
            return { ...state, byId: byId, ids: ids };
        }
        default:
            return state;
    }
}
