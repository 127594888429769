import Axios, { AxiosResponse, AxiosRequestConfig, AxiosPromise } from "axios";
import { refreshToken, decodeToken, Token } from "../../toolbox/token";
import { Equality } from "npm-medgo-toolbox";
import { adminLogout } from "../../src/modules/logout";
import { isEmpty } from "lodash";

const AxiosInstance = Axios.create({
    headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
    },
    withCredentials: true
});

const attemptRefreshToken = async (
    error: unknown,
    originalRequest: AxiosRequestConfig & { _isRetryRequest?: boolean },
    decodedToken: Token
): Promise<AxiosPromise<unknown>> => {
    originalRequest._isRetryRequest = true;
    const res = await refreshToken(decodedToken);
    if (res.status === 401) {
        await adminLogout(false);
        return Promise.reject(error);
    }
    if (res.status === 201) {
        localStorage.setItem("token", res.data.token);
    }
    return AxiosInstance(originalRequest);
};

if (typeof localStorage !== "undefined") {
    // overriding potential default authorization header
    delete AxiosInstance.defaults.headers.common.Authorization;

    AxiosInstance.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig => {
            const token = localStorage.getItem("token");
            if (config.url) {
                if (config.url.includes(process.env.REACT_APP_SERVICE_MESSAGING_URL as string)) {
                    config.headers.authorization = process.env.REACT_APP_MESSAGING_ACCESS_TOKEN;
                    return config;
                }
            }
            if (!Equality.isNullOrUndefined(token) && token !== "undefined") {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error): any => Promise.reject(error)
    );

    AxiosInstance.interceptors.response.use(
        (response): AxiosResponse<any> => {
            // Any status code that lie within the range of 2xx cause this function to trigger

            if (response.headers.authorization) {
                localStorage.setItem("token", response.headers.authorization);
            }
            return response;
        },
        async (error): Promise<any> => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            if (error.response) {
                if (error.response.headers.authorization) {
                    localStorage.setItem("token", error.response.headers.authorization);
                }
                const isSession = isEmpty(localStorage.getItem("token"));
                if (!isSession) {
                    const originalToken = localStorage.getItem("token");
                    const originalRequest = error.config;

                    const decodedToken = decodeToken(originalToken as string);
                    if (
                        [401, 403].includes(error.response.status) &&
                        (error.response.data === "Token expired" || error.response.data.message === "Token expired") &&
                        Equality.isNullOrUndefined(originalRequest._isRetryRequest)
                    ) {
                        return attemptRefreshToken(error, originalRequest, decodedToken);
                    } else if (error.response.status === 440) {
                        return adminLogout(false);
                    }
                } else {
                    if ([401, 440].includes(error.response.status)) {
                        const originalRequest = error.config;
                        if (Equality.isNullOrUndefined(originalRequest._isRetryRequest)) {
                            originalRequest._isRetryRequest = true;
                            return AxiosInstance(originalRequest);
                        } else {
                            return adminLogout(false);
                        }
                    }
                }
            }

            return Promise.reject(error);
        }
    );
}

export default AxiosInstance;
