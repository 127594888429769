import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frontPage, frontTrack } from "npm-hublo-analytics";
import { ArrayToolbox, Equality } from "npm-hublo-toolbox";
import NotificationsView from "./Notifications.view";
import {
    institutionGroupsSelector,
    institutionsSelector,
    langSelector,
    levelByIdSelector,
    meSelector,
    serviceByIdSelector,
    serviceIdsSelector
} from "../../store/selectors";

import { fetchInstitutionsAction } from "../../store/action/institution.action";
import { fetchPolesAction } from "../../store/action/pole.action";
import { fetchServicesAction } from "../../store/action/service.action";
import { getModelByIdFromDict, getModelsFromDict } from "../../modules/store/util";
import { IInstitution } from "../../../Model/Institution";
import { IService } from "../../../Model/Service";
import {
    deleteAdminInInstitutionNotificationService,
    fetchAdminInInstitutionNotificationService,
    insertAdminInInstitutionNotificationService
} from "../../service/admin.service";
import {
    fetchNotificationAdmins,
    insertNotificationAdmin,
    updateNotificationAdmin
} from "../../service/notificationAdmin.service";
import { INotificationAdmin } from "../../../Model/NotificationAdmin";
import { getDefaultNotificationAdmin } from "../../constants/ProNotification.constant";
import { ILevel } from "../../../Model/Level";
import { fetchInstitutionGroupsAction } from "../../store/action/institutionGroup.action";
import { IInstitutionGroup, INormalizedInstitutionGroups } from "../../../Model/InstitutionGroup";
import { isAllowedTo, Permission } from "../../modules/authorization/permission";

const NotificationsContainer: React.FC = () => {
    const dispatch = useDispatch();
    const me = useSelector(meSelector);

    const [{ modificationsSaved }] = useSelector(langSelector);
    const institutionsById = useSelector(institutionsSelector).byId;
    const institutionsIds = useSelector(institutionsSelector).ids;
    const institutions = getModelsFromDict<IInstitution>(institutionsById, institutionsIds);

    const institutionGroupDict = useSelector(institutionGroupsSelector); //byId
    const [segmentProperty] = useState({
        idAdmin: me.id,
        idInstitutions: institutionsIds,
        institutionsGroupsGrouping: Object.values(institutionGroupDict as INormalizedInstitutionGroups).reduce(
            (
                acc,
                current
            ): {
                [key: number]: IInstitutionGroup[];
            } => {
                const idInstitutions = current.institution;
                const institutionGroup = JSON.parse(JSON.stringify(current));
                delete institutionGroup.institution;
                idInstitutions.forEach((idInstitution: number): void => {
                    if (acc[idInstitution]) {
                        acc[idInstitution].push(institutionGroup);
                    } else {
                        acc[idInstitution] = [institutionGroup];
                    }
                });
                return acc;
            },
            {}
        ),
        adminLevel: me.level,
        typeAdmin: me.typeAdmin,
        path: "me/notifications"
    });
    const [errorMessageNotif, setErrorMessageNotif] = useState("");
    const [infoMessageNotif, setInfoMessageNotif] = useState("");
    const [errorMessageService, setErrorMessageService] = useState("");
    const [infoMessageService, setInfoMessageService] = useState("");
    const [isLoadingNotif, setIsLoadingNotif] = useState(false);
    const [isLoadingService, setIsLoadingService] = useState(false);
    const [didLoadNotif, setDidLoadNotif] = useState(false);
    const [didLoadService, setDidLoadService] = useState(false);
    const [selectedServices, setSelectedServices] = useState<number[]>([]);
    const [notificationAdmin, setNotificationAdmin] = useState<INotificationAdmin>(getDefaultNotificationAdmin());
    const servicesIds = useSelector(serviceIdsSelector);
    const servicesById = useSelector(serviceByIdSelector);
    const services: IService[] = getModelsFromDict(servicesById, servicesIds);
    const adminPermissionsById = useSelector(levelByIdSelector);
    const [adminPermissions] = useState<ILevel>(getModelByIdFromDict(adminPermissionsById, me.level));
    const [areServicesReadOnly] = useState(
        !isAllowedTo({
            institutionIds: institutionsIds,
            permission: Permission.CHANGE_SERVICE,
            permissions: me.permissions
        })
    );

    const getNotificationAdmin = async () => {
        const notifAdmins = await fetchNotificationAdmins({
            query: {
                id: me.id
            }
        });
        if (Equality.isNullOrUndefined(notifAdmins) || notifAdmins.length === 0) {
            return;
        }
        setNotificationAdmin(notifAdmins[0]);
        setDidLoadNotif(true);
    };

    const getAdminsNotificationServices = async () => {
        const adminNotificationServices = await fetchAdminInInstitutionNotificationService({
            query: {
                admin: me.id
            },
            limit: 999999,
            select: ["service"]
        });
        if (Equality.isNullOrUndefined(adminNotificationServices) || adminNotificationServices.length === 0) {
            return;
        }
        setSelectedServices(adminNotificationServices.map(ns => ns.service));
        setDidLoadService(true);
    };

    const getAdminUserPermissions = async () => {};

    useEffect(() => {
        const init = async () => {
            setIsLoadingService(true);
            setIsLoadingNotif(true);

            window.scrollTo(0, 0);

            await dispatch(fetchInstitutionGroupsAction({ limit: 999999 }));
            await dispatch(
                fetchInstitutionsAction({
                    limit: 9999999,
                    orderby: [{ columnName: "name", orientation: "ASC" }]
                })
            );
            await dispatch(
                fetchPolesAction({
                    limit: 9999999,
                    orderby: [{ columnName: "name", orientation: "ASC" }]
                })
            );
            await dispatch(
                fetchServicesAction({
                    limit: 9999999,
                    orderby: [{ columnName: "name", orientation: "ASC" }]
                })
            );
            await getAdminsNotificationServices();
            await getNotificationAdmin();

            setIsLoadingNotif(false);
            setIsLoadingService(false);
        };
        init();
    }, []);

    useEffect(() => {
        if (me.level === 1) {
            return;
        }
        const analyticsPage = async () => {
            await frontPage({
                segmentProperty,
                name: "me/notifications"
            });
        };

        analyticsPage();
    }, []);

    const handleSubmitNotification = async () => {
        setIsLoadingNotif(true);
        try {
            const notifAdmins = await fetchNotificationAdmins({
                query: {
                    id: me.id
                }
            });
            if (Equality.isNullOrUndefined(notifAdmins) || notifAdmins.length === 0) {
                notificationAdmin.id = me.id;
                await insertNotificationAdmin(notificationAdmin);
                setNotificationAdmin(notificationAdmin);
            } else {
                await updateNotificationAdmin(me.id, notificationAdmin);
            }
            frontTrack({
                segmentProperty,
                event: "Update Notifications"
            });
            setErrorMessageNotif("");
            setInfoMessageNotif(modificationsSaved);
            setIsLoadingNotif(false);
        } catch (xhr) {
            console.error(xhr);
            setErrorMessageNotif(xhr.responseText);
            setInfoMessageNotif("");
            setIsLoadingNotif(false);
        }
    };

    const deleteAdminInInstitutionNotificationServices = async (
        adminInInstitutionNotificationServices: {
            id: number;
            service: number;
        }[],
        idServicesToRemove: number[]
    ) => {
        if (idServicesToRemove.length === 0) {
            return;
        }
        const notificationServicesToRemove = adminInInstitutionNotificationServices.filter(ns =>
            idServicesToRemove.includes(ns.service)
        );
        notificationServicesToRemove.forEach(async toRemove => {
            await deleteAdminInInstitutionNotificationService(toRemove.id);
        });
    };

    const insertAdminInInstitutionNotificationServices = async (idServicesToAdd: number[]) => {
        if (idServicesToAdd.length === 0) {
            return;
        }
        idServicesToAdd.forEach(async idToAdd => {
            const service = services.find(
                s =>
                    s.id === idToAdd &&
                    s.institution !== null &&
                    s.institution !== undefined &&
                    s.institution.length > 0
            );
            if (service !== null && service !== undefined) {
                await insertAdminInInstitutionNotificationService(me.id, service.institution[0], idToAdd);
            }
        });
    };

    const handleSubmitService = async () => {
        setIsLoadingService(true);
        try {
            const notificationServices = await fetchAdminInInstitutionNotificationService({
                query: {
                    admin: me.id
                },
                select: ["id", "service"],
                limit: 999999
            });
            const idServicesPrevious = notificationServices.map(ns => ns.service);
            const idServicesToAdd = ArrayToolbox.diffArrays(selectedServices, idServicesPrevious);
            const idServicesToRemove = ArrayToolbox.diffArrays(idServicesPrevious, selectedServices);

            deleteAdminInInstitutionNotificationServices(notificationServices, idServicesToRemove);
            insertAdminInInstitutionNotificationServices(idServicesToAdd);
            frontTrack({ segmentProperty, event: "Update Services" });
            setErrorMessageService("");
            setInfoMessageService(modificationsSaved);
            setIsLoadingService(false);
            setDidLoadService(true);
        } catch (xhr) {
            setErrorMessageService(xhr.responseText);
            setInfoMessageService("");
            setIsLoadingService(false);
        }
    };

    const onSelectNotif = async (whichNotif: string, val: number) => {
        const updatedNotifcationAdmin = {
            ...notificationAdmin,
            [whichNotif]: val
        };
        setNotificationAdmin(updatedNotifcationAdmin);
        setInfoMessageNotif("");
    };

    const onSelectService = async (idInstitution: number, idServices: number[]) => {
        setSelectedServices(idServices);
    };

    return (
        <div className="container" style={{ minHeight: "100%" }}>
            <NotificationsView
                infoMessageNotif={infoMessageNotif}
                infoMessageService={infoMessageService}
                errorMessageNotif={errorMessageNotif}
                errorMessageService={errorMessageService}
                isLoadingNotif={isLoadingNotif}
                isLoadingService={isLoadingService}
                didLoadService={didLoadService}
                didLoadNotif={didLoadNotif}
                notificationAdmin={notificationAdmin}
                institutions={institutions}
                idServices={selectedServices}
                areServicesReadOnly={areServicesReadOnly}
                adminPermissions={adminPermissions}
                handleSubmitNotification={handleSubmitNotification}
                handleSubmitService={handleSubmitService}
                onSelectNotif={onSelectNotif}
                onSelectService={onSelectService}
            />
        </div>
    );
};
export default NotificationsContainer;
