import jwt from "jsonwebtoken";
import { stringify } from "querystring";

import { getLang } from "../src/modules/lang";
import Axios from "../modules/axios/axios.instance";
import { AxiosResponse } from "axios";

export type UserType = "worker" | "admin";

export interface Token {
    user: {
        id: number;
        idInstitutions?: number[];
        tokenValidationNumber?: number;
        idLanguage?: number;
    };
    userType: UserType;
    userId?: number;
    exp?: number;
    iat?: number;
    timestamp?: number;
    isRefresh?: boolean;
    adminId?: number;
}

export const refreshToken = async (payload: any): Promise<AxiosResponse<{ token: string }>> => {
    try {
        return await Axios.get(`${process.env.REACT_APP_SERVICE_AUTH_URL}/token/refresh?${stringify(payload)}`);
    } catch (error) {
        if (payload.hasOwnProperty("adminId")) {
            console.error(
                `An error has occured in refreshToken for admin ${payload.adminId} with payload: ${JSON.stringify(
                    payload
                )}: ${error}`
            );
        } else {
            console.error(
                `An error has occured in refreshToken for worker ${payload.userId} with payload: ${JSON.stringify(
                    payload
                )}: ${error}`
            );
        }
        const lang = getLang();
        throw new Error(lang.anErrorOccured);
    }
};

export const decodeToken = (token: string): Token => jwt.decode(token) as Token;
