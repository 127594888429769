enum InstitutionHasSsoActionTypes {
    FETCH_INSTITUTIONHASSSOS = "FETCH_INSTITUTIONHASSSOS",
    SET_INSTITUTIONHASSSOS = "SET_INSTITUTIONHASSSOS",
    FETCH_INSTITUTIONHASSSOS_COUNT = "FETCH_INSTITUTIONHASSSOS_COUNT",
    SET_SELECTED_INSTITUTIONHASSSO = "SET_SELECTED_INSTITUTIONHASSSO",
    SET_SELECTED_INSTITUTIONHASSSO_MULTI = "SET_SELECTED_INSTITUTIONHASSSO_MULTI",
    SET_IS_LOADING_INSTITUTIONHASSSO = "SET_IS_LOADING_INSTITUTIONHASSSO",
    SET_INSTITUTIONHASSSO_ORDER = "SET_INSTITUTIONHASSSO_ORDER",
    INSERT_INSTITUTIONHASSSO = "INSERT_INSTITUTIONHASSSO",
    UPDATE_INSTITUTIONHASSSO = "UPDATE_INSTITUTIONHASSSO",
    DELETE_INSTITUTIONHASSSO = "DELETE_INSTITUTIONHASSSO",
    SET_INSTITUTIONHASSSO_ERROR_CODE = "SET_INSTITUTIONHASSSO_ERROR_CODE",
    SET_SSO_NAME = "SET_SSO_NAME"
}
export default InstitutionHasSsoActionTypes;
