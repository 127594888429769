import { IReduxAction } from "../store.types";
import LangTypes from "../type/lang.type";
// French
import { fr } from "../../lang/fr";

// German
import { de } from "../../lang/de";

//English
import { en } from "../../lang/en";

//Spanish
import { es } from "../../lang/es";

import { ILang } from "../../lang/Lang";

export enum AvailableLangs {
    "fr" = "fr",
    "de" = "de",
    "en" = "en",
    "es" = "es"
}

export const AvailableLangsMomentCorrespondance: any = {
    fr: "fr",
    de: "de",
    en: "en-gb",
    es: "es"
};

const langs: {
    currentLang: AvailableLangs;
    fr: ILang;
    de: ILang;
    en: ILang;
    es: ILang;
} = {
    currentLang: AvailableLangs.fr,
    fr: Object.assign({}, fr),
    de: Object.assign({}, de),
    en: Object.assign({}, en),
    es: Object.assign({}, es)
};

const initialState: any = {
    currentLang: langs.currentLang,
    lang: langs.fr
};

export default function langReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LangTypes.SET_LANG:
            return {
                ...state,
                // @ts-ignore
                lang: langs[action.payload],
                currentLang: action.payload
            };

        default:
            return state;
    }
}
