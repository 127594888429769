enum InstitutionActionTypes {
    FETCH_INSTITUTIONS = "FETCH_INSTITUTIONS",
    SET_INSTITUTIONS = "SET_INSTITUTIONS",
    SET_SELECTED_INSTITUTION = "SET_SELECTED_INSTITUTION",
    SET_SELECTED_INSTITUTION_MULTI = "SET_SELECTED_INSTITUTION_MULTI",
    SET_IS_LOADING_INSTITUTION = "SET_IS_LOADING_INSTITUTION",
    SET_INSTITUTION_ORDER = "SET_INSTITUTION_ORDER",
    INSERT_INSTITUTION = "INSERT_INSTITUTION",
    UPDATE_INSTITUTION = "UPDATE_INSTITUTION",
    DELETE_INSTITUTION = "DELETE_INSTITUTION",
    FETCH_INSTITUTIONS_COUNT = "FETCH_INSTITUTIONS_COUNT"
}
export default InstitutionActionTypes;
