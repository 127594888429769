import { connect } from "react-redux";
import {
    fetchPublicholidaysAction,
    setPublicholidaysAction,
    deletePublicholidayAction
} from "../../store/action/publicholiday.action";

import { IDispatchedPublicholiday } from "../../../Model/Publicholiday";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedDispatchedPublicholidays } from "../../../Model/Publicholiday";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsPublicHoliday extends IReduxPropsTable {
    idPublicholidays: number[];
    publicholidayDict: INormalizedDispatchedPublicholidays;
    idItemToBeDeleted: number;
}

interface IPropsPublicHoliday extends IReduxPropsPublicHoliday, IReduxActionsPublicHoliday, IPropsTable {}

interface IReduxActionsPublicHoliday extends IReduxActionsTable {
    setPublicholidaysAction: (elements: IDispatchedPublicholiday[]) => any;
    fetchPublicholidaysAction: (param: IQueryParams) => any;
    deletePublicholidayAction: (id: number) => any;
}

class TablePublicHolidayContainer extends TableComponent<IPropsPublicHoliday> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        await this.props.fetchPublicholidaysAction(params);
    }

    setElements(elements: any): void {
        this.props.setPublicholidaysAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<IDispatchedPublicholiday>(this.props.publicholidayDict, this.props.idPublicholidays);
    }

    async onDeleteLineClick(): Promise<void> {
        const id = this.props.idItemToBeDeleted;
        await this.props.deletePublicholidayAction(id);
        await this.fetchElements();
    }
}

export default connect(
    (centralState: any): IReduxPropsPublicHoliday =>
        Object.assign(getConnectParentState(centralState), {
            idPublicholidays: centralState.publicHoliday.ids,
            publicholidayDict: centralState.publicHoliday.byId,
            idSelectedMultiInstitutions: centralState.institutions.selectedMulti,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setPublicholidaysAction,
        fetchPublicholidaysAction,
        deletePublicholidayAction
    })
)(TablePublicHolidayContainer);
