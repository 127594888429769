import { IReduxAction } from "../store.type";
import InstitutionHasAdminActionTypes from "../type/institutionHasAdmin.type";
import {
    fetchInstitutionHasAdmins,
    insertInstitutionHasAdmin,
    updateInstitutionHasAdmin,
    deleteInstitutionHasAdmin
} from "../../service/institutionHasAdmin.service";
import { IInstitutionHasAdmin, INormalizedInstitutionHasAdmins } from "../../../Model/InstitutionHasAdmin";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import AdminActionTypes from "../type/admin.type";

export function fetchInstitutionHasAdminsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionHasAdmin = await fetchInstitutionHasAdmins(query);
            dispatch({
                type: InstitutionHasAdminActionTypes.FETCH_INSTITUTIONHASADMINS,
                payload: {
                    byId: institutionHasAdmin.reduce(
                        (
                            p: INormalizedInstitutionHasAdmins,
                            c: IInstitutionHasAdmin
                        ): INormalizedInstitutionHasAdmins => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionHasAdmin.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasAdminAction(
    institutionHasAdmin: IInstitutionHasAdmin
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<IInstitutionHasAdmin> => {
        try {
            const insertedInstitutionHasAdmin = await insertInstitutionHasAdmin(institutionHasAdmin);
            dispatch({
                type: InstitutionHasAdminActionTypes.INSERT_INSTITUTIONHASADMIN,
                payload: insertedInstitutionHasAdmin
            });
            dispatch({
                type: AdminActionTypes.INSERT_INSTITUTION_HAS_ADMIN,
                payload: insertedInstitutionHasAdmin
            });
            return insertedInstitutionHasAdmin;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionHasAdminAction(
    id: number,
    institutionHasAdmin: IInstitutionHasAdmin
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionsHasAdmin = await updateInstitutionHasAdmin(id, institutionHasAdmin);
            if (Array.isArray(updatedInstitutionsHasAdmin)) {
                dispatch({
                    type: InstitutionHasAdminActionTypes.UPDATE_INSTITUTIONSHASADMIN,
                    payload: updatedInstitutionsHasAdmin
                });
            } else
                dispatch({
                    type: InstitutionHasAdminActionTypes.UPDATE_INSTITUTIONHASADMIN,
                    payload: updatedInstitutionsHasAdmin
                });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionHasAdminAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionHasAdmin(id);
            dispatch({
                type: InstitutionHasAdminActionTypes.DELETE_INSTITUTIONHASADMIN
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
