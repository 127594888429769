/* eslint-disable react/prop-types */
import { NotificationComponent } from "npm-medgo-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ILang } from "../../../lang/Lang";
import { setNotificationHide, setNotificationMessage } from "../../../store/action/notification.action";
import { notificationSelector, oauth2ErrorCodeSelector } from "../../../store/selectors";

interface NotificationProps {
    lang: ILang;
}

export const Notification: React.FC<NotificationProps> = ({ lang }): JSX.Element => {
    const dispatch = useDispatch();
    const notification = useSelector(notificationSelector);
    const errorCode = useSelector(oauth2ErrorCodeSelector);

    useEffect((): void => {
        switch (errorCode) {
            case 1:
                dispatch(
                    setNotificationMessage({
                        message: lang.oauth2ConfigSaveError,
                        icon: "fa fa-times",
                        color: "danger"
                    })
                );
                return;
            case 2:
                dispatch(
                    setNotificationMessage({
                        message: lang.oauth2ConfigSaveSuccess,
                        icon: "fa fa-times",
                        color: "success"
                    })
                );
                return;
        }
    }, [errorCode]);

    const handleHideNotification = (): void => {
        dispatch(setNotificationHide());
    };

    return (
        <div className="container-fluid text-center">
            <NotificationComponent
                text={notification.message}
                icon={notification.icon}
                isVisible={notification.isVisible}
                handleHide={handleHideNotification}
                color={notification.color}
                disapearsAfter={2000}
            />
        </div>
    );
};
