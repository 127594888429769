enum ContractSalaryGridActionTypes {
    FETCH_CONTRACTSALARYGRIDS = "FETCH_CONTRACTSALARYGRIDS",
    SET_CONTRACTSALARYGRIDS = "SET_CONTRACTSALARYGRIDS",
    FETCH_CONTRACTSALARYGRIDS_COUNT = "FETCH_CONTRACTSALARYGRIDS_COUNT",
    SET_SELECTED_CONTRACTSALARYGRID = "SET_SELECTED_CONTRACTSALARYGRID",
    SET_SELECTED_CONTRACTSALARYGRID_MULTI = "SET_SELECTED_CONTRACTSALARYGRID_MULTI",
    SET_IS_LOADING_CONTRACTSALARYGRID = "SET_IS_LOADING_CONTRACTSALARYGRID",
    SET_CONTRACTSALARYGRID_ORDER = "SET_CONTRACTSALARYGRID_ORDER",
    INSERT_INSTITUTION_HAS_CONTRACTSALARYGRID = "INSERT_INSTITUTION_HAS_CONTRACTSALARYGRID",
    UPDATE_CONTRACTSALARYGRID = "UPDATE_CONTRACTSALARYGRID",
    DELETE_CONTRACTSALARYGRID = "DELETE_CONTRACTSALARYGRID",
    SET_CONTRACTSALARYGRID_ERROR_CODE = "SET_CONTRACTSALARYGRID_ERROR_CODE",
    FETCH_ALL_CONTRACTSALARYGRIDS = "FETCH_ALL_CONTRACTSALARYGRIDS",
    FETCH_INSTITUTION_HAS_CONTRACTSALARYGRIDS = "FETCH_INSTITUTION_HAS_CONTRACTSALARYGRIDS",
    DELETE_INSTITUTION_HAS_CONTRACTSALARYGRIDS = "DELETE_INSTITUTION_HAS_CONTRACTSALARYGRIDS"
}
export default ContractSalaryGridActionTypes;
