import { CreateInstitutionHasSso, IInstitutionHasSso } from "../../Model/InstitutionHasSso";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import { AdminColumns } from "../constants/Column.constants";
import AxiosInstance from "../../modules/axios/axios.instance";

const InstitutionHasSsoDefaultSelect = AdminColumns.map((el): string => el.name);

export async function fetchInstitutionHasSsos(query: IQueryParams): Promise<IInstitutionHasSso[]> {
    const institutionHasSsos = await postFetchFactory<IInstitutionHasSso>(
        PossibleFetchs.institutionHasSso,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionHasSsoDefaultSelect
        },
        query
    );
    return institutionHasSsos;
}

export async function fetchInstitutionHasSsosCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionHasSso>(PossibleFetchs.institutionHasSso, PossibleApis.medgoApi)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionHasSso(institutionHasSso: CreateInstitutionHasSso): Promise<IInstitutionHasSso> {
    const res = await AxiosInstance.post(
        `${PossibleApis.monorepoAuthentication}/${PossiblePosts.institutionHasSso}`,
        institutionHasSso
    );
    //@ts-ignore
    const newInstitutionHasSso = res.result.filter(
        (e: CreateInstitutionHasSso) => e.idInstitution === institutionHasSso.idInstitution
    );
    return { ...newInstitutionHasSso, institution: newInstitutionHasSso.idInstitution };
}

export async function updateInstitutionHasSso(
    id: number,
    institutionHasSso: IInstitutionHasSso
): Promise<IInstitutionHasSso> {
    return await updateFactory<IInstitutionHasSso>(PossibleUpdates.institutionHasSso, PossibleApis.medgoApi)(
        id,
        institutionHasSso
    );
}

export async function deleteInstitutionHasSso(id: number): Promise<IInstitutionHasSso> {
    return await deleteFactory<IInstitutionHasSso>(PossibleDeletes.institutionHasSso, PossibleApis.medgoApi)(id);
}
