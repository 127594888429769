import { IInstitutionHasOAuth2 } from "../../Model/InstitutionHasOAuth2";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";
import { AdminColumns } from "../constants/Column.constants";

const InstitutionHasOAuth2DefaultSelect = AdminColumns.map((el): string => el.name);

export async function fetchInstitutionHasOAuth2s(query: IQueryParams): Promise<IInstitutionHasOAuth2[]> {
    const institutionHasOAuth2s = await postFetchFactory<IInstitutionHasOAuth2>(
        PossibleFetchs.institutionHasOAuth2,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionHasOAuth2DefaultSelect
        },
        query
    );
    return institutionHasOAuth2s;
}

export async function fetchInstitutionHasOAuth2sCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionHasOAuth2>(
        PossibleFetchs.institutionHasOAuth2,
        PossibleApis.medgoApi
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionHasOAuth2(
    institutionHasOAuth2: IInstitutionHasOAuth2
): Promise<IInstitutionHasOAuth2> {
    return await postFactory<IInstitutionHasOAuth2>(
        PossiblePosts.institutionHasOAuth2,
        PossibleApis.medgoApi
    )(institutionHasOAuth2);
}

export async function updateInstitutionHasOAuth2(
    id: number,
    institutionHasOAuth2: IInstitutionHasOAuth2
): Promise<IInstitutionHasOAuth2> {
    return await updateFactory<IInstitutionHasOAuth2>(PossibleUpdates.institutionHasOAuth2, PossibleApis.medgoApi)(
        id,
        institutionHasOAuth2
    );
}

export async function deleteInstitutionHasOAuth2(id: number): Promise<IInstitutionHasOAuth2> {
    return await deleteFactory<IInstitutionHasOAuth2>(PossibleDeletes.institutionHasOAuth2, PossibleApis.medgoApi)(id);
}
