import React, { Component } from "react";
import { connect } from "react-redux";

import { IAdmin } from "../../../Model/Admin";
import LineHeaderView from "./LineHeader.view";
import { IBackOfficeTable } from "../../constants/Table.constants";
import { ILang } from "../../lang/Lang";

interface IReduxPropsLineHeader {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
}

interface IPropsLineHeader extends IReduxPropsLineHeader {
    backOfficeTable: IBackOfficeTable;
}

class LineHeader extends Component<IPropsLineHeader> {
    constructor(props: IPropsLineHeader) {
        super(props);
    }

    render(): JSX.Element {
        return <LineHeaderView backOfficeTable={this.props.backOfficeTable} />;
    }
}

export default connect(
    (centralState: any): IReduxPropsLineHeader => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user
    }),
    {}
)(LineHeader);
