import AxiosInstance from "../../modules/axios/axios\.instance";
import { PossibleApis, PossibleDeletes } from "../../Constant/Api.constant";

export function deleteFactory<T>(
    modalName: PossibleDeletes,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (id: number) => Promise<T> {
    return async (id: number): Promise<T> => {
        const res = await AxiosInstance.delete(`${api}/api/v${apiVersion}/${modalName}/${id}`);
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data;
    };
}

export function customDeleteFactory<T>(
    modalName: PossibleDeletes,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (fields: { [key: string]: number }) => Promise<T> {
    return async (fields: { [key: string]: number }): Promise<T> => {
        let url: PossibleDeletes | string = modalName;
        for (const [key, value] of Object.entries(fields)) {
            const fieldName = ":".concat(key);
            url = url.replace(fieldName, value.toString());
        }
        const res = await AxiosInstance.delete(`${api}/api/v${apiVersion}/${url}`);
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data;
    };
}

export function deleteSubFieldFactory<T>(
    modalName: PossibleDeletes,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (id: number) => Promise<T> {
    return async (id: number): Promise<T> => {
        const res = await AxiosInstance.delete(`${api}/api/v${apiVersion}/${modalName.replace(":id", id.toString())}`);
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data;
    };
}
