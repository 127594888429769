import { IReduxAction } from "../store.type";
import InstitutionOctimeTypes from "../type/institutionOctime.type";
import {
    fetchInstitutionOctimes,
    insertInstitutionOctime,
    updateInstitutionOctime,
    deleteInstitutionOctime
} from "../../service/institutionOctime.service";
import { IInstitutionOctime, INormalizedInstitutionOctimes } from "../../../Model/InstitutionOctime";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionOctimesAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionOctimes = await fetchInstitutionOctimes(query);
            dispatch({
                type: InstitutionOctimeTypes.FETCH_INSTITUTIONOCTIMES,
                payload: {
                    byId: institutionOctimes.reduce(
                        (p: INormalizedInstitutionOctimes, c: IInstitutionOctime): INormalizedInstitutionOctimes => {
                            c.id = c.institution;
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionOctimes.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOctimeAction(
    institutionOctime: IInstitutionOctime
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOctime = await insertInstitutionOctime(institutionOctime);
            dispatch({
                type: InstitutionOctimeTypes.INSERT_INSTITUTIONOCTIME,
                payload: newInstitutionOctime
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOctimeAction(
    id: number,
    institutionOctime: IInstitutionOctime
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionOctime = await updateInstitutionOctime(id, institutionOctime);
            dispatch({
                type: InstitutionOctimeTypes.UPDATE_INSTITUTIONOCTIME,
                payload: updatedInstitutionOctime
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOctimeAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOctime(id);
            dispatch({
                type: InstitutionOctimeTypes.DELETE_INSTITUTIONOCTIME
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOctimesAction(
    dict: INormalizedInstitutionOctimes
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_INSTITUTIONOCTIMES,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOctimeAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_SELECTED_INSTITUTIONOCTIME,
            payload: id
        });
    };
}

export function setSelectedInstitutionOctimeMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_SELECTED_INSTITUTIONOCTIME_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionOctimeAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_IS_LOADING_INSTITUTIONOCTIME,
            payload: isLoading
        });
    };
}
export function setInstitutionOctimeOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_INSTITUTIONOCTIME_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOctimeErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOctimeTypes.SET_INSTITUTIONOCTIME_ERROR_CODE,
            payload: errorCode
        });
    };
}
