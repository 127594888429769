import { connect } from "react-redux";
import { IPublicholiday, IDispatchedPublicholiday } from "../../../Model/Publicholiday";

import { updatePublicholidayAction, insertPublicholidayAction } from "../../store/action/publicholiday.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IFormDictionary } from "../../store/type/form.type";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
//@ts-ignore
import * as clone from "clone";
import moment from "moment";
import { ILang } from "../../lang/Lang";

interface IPropsFormPublicholiday extends IReduxActionsForm, IPropsForm<IDispatchedPublicholiday> {
    publicholidayDict: IDispatchedPublicholiday[];
    idPublicholidays: number[];
    selectedMulti: number[];
    itemToBeModified: IDispatchedPublicholiday;
    lang: ILang;
}

interface IReduxActionsForm {
    insertPublicholidayAction: (formDictionary: IFormDictionary) => IDispatchedPublicholiday;
    updatePublicholidayAction: (id: number, publicholiday: IPublicholiday) => void;
}

class FormPublicHolidayComponent extends FormComponent<IDispatchedPublicholiday, IPropsFormPublicholiday> {
    async createElementAction(): Promise<void> {
        const formDictionaryPublicholidays = clone(
            getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.publicholidayConfiguration)
        );
        const formKeys = Object.keys(formDictionaryPublicholidays);
        const alreadyExistingPublicholidays = getModelsFromDict<IDispatchedPublicholiday>(
            this.props.publicholidayDict,
            this.props.idPublicholidays
        );
        let publicHolidayAlreadyExists = false;
        formKeys.forEach((key: any): void => {
            const formDictionaryPublicholiday = formDictionaryPublicholidays[key];
            alreadyExistingPublicholidays.forEach((alreadyExistingPublicholiday: IDispatchedPublicholiday): void => {
                if (
                    alreadyExistingPublicholiday.date === formDictionaryPublicholiday.date &&
                    this.props.selectedMulti.includes(alreadyExistingPublicholiday.institution)
                ) {
                    publicHolidayAlreadyExists = true;
                }
            });
        });
        if (publicHolidayAlreadyExists) {
            this.props.setNotificationMessage({
                message:
                    formKeys.length > 1
                        ? this.props.selectedMulti.length > 1
                            ? this.props.lang.addedPublicHolidaysAlreadyExistsInTheInstitutions
                            : this.props.lang.addedPublicHolidaysAlreadyExistsInTheInstitution
                        : this.props.selectedMulti.length > 1
                        ? this.props.lang.addedPublicHolidayAlreadyExistsInOneOfTheSelectedInstitutions
                        : this.props.lang.addedPublicHolidayAlreadyExistsInTheInstitution,
                icon: "fa fa-times",
                color: "danger"
            });
            return;
        }
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryPublicholiday = formDictionaryPublicholidays[key];
                    formDictionaryPublicholiday.date = moment.utc(formDictionaryPublicholiday.date, "DD-MM-YYYY");
                    let formDictionaryPublicholidayWithInstitutions: any[] = [];
                    this.props.selectedMulti.forEach(
                        (selectedInstitution, index): void =>
                            (formDictionaryPublicholidayWithInstitutions[index] = Object.assign(
                                {},
                                formDictionaryPublicholiday,
                                { institution: selectedInstitution }
                            ))
                    );
                    await Promise.all(
                        formDictionaryPublicholidayWithInstitutions.map(
                            async (formDictionaryPublicholidayWithInstitution): Promise<void> => {
                                await this.props.insertPublicholidayAction(formDictionaryPublicholidayWithInstitution);
                                return;
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        let publicholiday = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.publicholidayConfiguration
        )[0];
        publicholiday.date = moment.utc(publicholiday.date, "DD-MM-YYYY");
        publicholiday = Object.assign(publicholiday, { id: this.props.itemToBeModified.id });
        await this.props.updatePublicholidayAction(publicholiday.id, publicholiday as IPublicholiday);
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<IDispatchedPublicholiday> =>
        Object.assign(getConnectParentState(centralState), {
            publicholidayDict: centralState.publicHoliday.byId,
            idPublicholidays: centralState.publicHoliday.ids,
            selectedMulti: centralState.institutions.selectedMulti,
            itemToBeModified: centralState.tables.modifyingElement,
            lang: centralState.language.lang
        }),
    Object.assign(getConnectParentActions(), {
        insertPublicholidayAction,
        updatePublicholidayAction
    })
)(FormPublicHolidayComponent);
