import React from "react";
import { EAvailableLang, SelectCheckboxListComponent } from "@hublo/legacy-components";
import { langSelector } from "../../store/selectors";
import { IService } from "../../../Model/Service";
import { useSelector } from "react-redux";

export default function InputService({
    idServices,
    services,
    onSelectService,
    isReadingOnly,
    lang
}: {
    idServices: number[];
    services: IService[];
    onSelectService: (idServices: number[]) => void;
    isReadingOnly: boolean;
    lang: string;
}): JSX.Element {
    const [{ service }] = useSelector(langSelector);
    return (
        <SelectCheckboxListComponent
            hasOptionAll={false}
            values={idServices}
            options={services.map(e => ({
                isCategory: false,
                label: e.name,
                values: [e.id]
            }))}
            onChange={onSelectService}
            text={service}
            isReadingOnly={isReadingOnly}
            currentLang={lang as EAvailableLang}
        />
    );
}
