import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionInfoTypes from "../type/institutionInfo.type";
import { IInstitutionInfo } from "../../../Model/InstitutionInfo";

const initialState: IReduxInitialState<IInstitutionInfo> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionInfoReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionInfoTypes.FETCH_INSTITUTIONINFOS:
        case InstitutionInfoTypes.SET_INSTITUTIONINFOS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] };

        case InstitutionInfoTypes.FETCH_INSTITUTIONINFOS_COUNT:
            return { ...state, count: action.payload };

        case InstitutionInfoTypes.SET_SELECTED_INSTITUTIONINFO:
            return { ...state, selectedId: action.payload };

        case InstitutionInfoTypes.SET_SELECTED_INSTITUTIONINFO_MULTI:
            return { ...state, selectedMulti: action.payload };

        case InstitutionInfoTypes.SET_IS_LOADING_INSTITUTIONINFO:
            return { ...state, isLoading: action.payload };

        case InstitutionInfoTypes.SET_INSTITUTIONINFO_ORDER:
            return { ...state, ids: action.payload };

        case InstitutionInfoTypes.INSERT_INSTITUTIONINFO:
        case InstitutionInfoTypes.UPDATE_INSTITUTIONINFO: {
            const byId = { ...state.byId };
            const ids = [...state.ids];
            if (byId[action.payload.id]) {
                byId[action.payload.id] = Object.assign({}, byId[action.payload.id], action.payload);
            } else {
                byId[action.payload.id] = Object.assign({}, action.payload);
            }
            if (ids.indexOf(action.payload.id) === -1) {
                ids.push(action.payload.id);
            }
            return { ...state, byId: byId, ids: ids };
        }
        default:
            return state;
    }
}
