import { connect } from "react-redux";
import {
    setInstitutionGroupsAction,
    fetchInstitutionGroupsAction,
    setIsLoadingInstitutionGroupAction
} from "../../store/action/institutionGroup.action";

import { IInstitutionGroup } from "../../../Model/InstitutionGroup";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionGroups } from "../../../Model/InstitutionGroup";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionGroup extends IReduxPropsTable {
    idInstitutionGroups: number[];
    institutionGroupDict: INormalizedInstitutionGroups;
}

interface IPropsInstitutionGroup extends IReduxPropsInstitutionGroup, IReduxActionsInstitutionGroup, IPropsTable {}

interface IReduxActionsInstitutionGroup extends IReduxActionsTable {
    setInstitutionGroupsAction: (elements: IInstitutionGroup[]) => any;
    fetchInstitutionGroupsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionGroupAction: (isLoading: boolean) => any;
}

class TableInstitutionGroupContainer extends TableComponent<IPropsInstitutionGroup> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionGroupAction(true);
        if (this.props.idInstitutionGroups.length === 0) await this.props.fetchInstitutionGroupsAction(params);
        this.props.setIsLoadingInstitutionGroupAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionGroupsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionGroupDict, this.props.idInstitutionGroups);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionGroup =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionGroups: centralState.institutionGroups.ids,
            institutionGroupDict: centralState.institutionGroups.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.institutionGroups.isLoading
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionGroupsAction,
        fetchInstitutionGroupsAction,
        setIsLoadingInstitutionGroupAction
    })
)(TableInstitutionGroupContainer);
