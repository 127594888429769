import React from "react";
import { Equality } from "npm-hublo-toolbox";
import { getProNotifications, IProNotification } from "../../../constants/ProNotification.constant";
import { INotificationAdmin } from "../../../../Model/NotificationAdmin";
import { ILang } from "../../../lang/Lang";
import { SelectComponent } from "@hublo/legacy-components";
import { ILevel } from "../../../../Model/Level";
import { IInstitutionOption } from "../../../../Model/InstitutionOption";
import { buildTargetPermissionsResult, Permission } from "../../../modules/authorization/permission";
import { IAdmin } from "../../../../Model/Admin";

interface IPropsNotificationActivationElement {
    proNotificationConstant: IProNotification;
    notificationAdmin: INotificationAdmin;
    onSelectNotif: (whichNotif: string, val: any) => void;
    lang: ILang;
}

const NotificationActivationElementView: React.FC<IPropsNotificationActivationElement> = props => {
    const { proNotificationConstant, notificationAdmin, onSelectNotif, lang } = props;
    const nameNotif = proNotificationConstant.name;
    const value =
        !Equality.isNullOrUndefined(notificationAdmin) && !Equality.isNullOrUndefined(notificationAdmin[nameNotif])
            ? notificationAdmin[nameNotif]
            : proNotificationConstant.defaultValue;

    if (proNotificationConstant.hidden) {
        return null;
    }

    return (
        <div key={nameNotif} className="hero is-small row">
            <div className="col-6 vcenter">{proNotificationConstant.label}</div>
            <div className="col-6">
                <SelectComponent
                    simpleValue={true}
                    isMulti={false}
                    name={nameNotif}
                    id={nameNotif}
                    value={proNotificationConstant.options.filter((option: { value: any }) => value === option.value)}
                    options={proNotificationConstant.options}
                    onChange={val => {
                        onSelectNotif(nameNotif, val ? val.value : null);
                    }}
                    isClearable={false}
                    placeholder={lang.defaultPlaceholder}
                />
            </div>
        </div>
    );
};

export function getCategoryElementNotifications(
    notificationAdmin: INotificationAdmin,
    onSelectNotif: (whichNotif: string, val: number) => void,
    lang: ILang,
    adminPermissions: ILevel,
    institutionOptions: IInstitutionOption,
    me?: IAdmin
) {
    const proNotificationsConstants = getProNotifications({
        permissions: adminPermissions,
        institutionOptions,
        lang,
        me
    });

    const category1Notif = proNotificationsConstants
        .filter(e => e.category === 1)
        .sort((a, b) => a.position - b.position)
        .map(proNotificationConstant => {
            return NotificationActivationElementView({
                proNotificationConstant,
                notificationAdmin,
                onSelectNotif,
                lang
            });
        });

    const category2Notif = proNotificationsConstants
        .filter(e => e.category === 2)
        .sort((a, b) => a.position - b.position)
        .map(proNotificationConstant => {
            return NotificationActivationElementView({
                proNotificationConstant,
                notificationAdmin,
                onSelectNotif,
                lang
            });
        });

    const category3Notif = proNotificationsConstants
        .filter(e => e.category === 3)
        .sort((a, b) => a.position - b.position)
        .map(proNotificationConstant => {
            return NotificationActivationElementView({
                proNotificationConstant,
                notificationAdmin,
                onSelectNotif,
                lang
            });
        });

    const category4Notif = proNotificationsConstants
        .filter(e => e.category === 4)
        .sort((a, b) => a.position - b.position)
        .map(proNotificationConstant => {
            return NotificationActivationElementView({
                proNotificationConstant,
                notificationAdmin,
                onSelectNotif,
                lang
            });
        });

    const category5Notif = proNotificationsConstants
        .filter(e => e.category === 5)
        .sort((a, b) => a.position - b.position)
        .map(proNotificationConstant => {
            return NotificationActivationElementView({
                proNotificationConstant,
                notificationAdmin,
                onSelectNotif,
                lang
            });
        });

    return { category1Notif, category2Notif, category3Notif, category4Notif, category5Notif };
}
