export interface IReduxNotification {
    message?: string;
    color?: "primary" | "success" | "info" | "warning" | "danger" | "link";
    icon?: string;
    isVisible?: boolean;
}

enum NotificationActionTypes {
    SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE",
    SET_NOTIFICATION_HIDE = "SET_NOTIFICATION_HIDE"
}

export default NotificationActionTypes;
