import { IReduxAction } from "../store.type";
import InstitutionInfoTypes from "../type/institutionInfo.type";
import {
    fetchInstitutionInfos,
    fetchInstitutionInfosCount,
    insertInstitutionInfo,
    updateInstitutionInfo,
    deleteInstitutionInfo
} from "../../service/institutionInfo.service";
import { IInstitutionInfo, INormalizedInstitutionInfos } from "../../../Model/InstitutionInfo";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionInfosAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionInfos = await fetchInstitutionInfos(query);
            dispatch({
                type: InstitutionInfoTypes.FETCH_INSTITUTIONINFOS,
                payload: {
                    byId: institutionInfos.reduce(
                        (p: INormalizedInstitutionInfos, c: IInstitutionInfo): INormalizedInstitutionInfos => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionInfos.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionInfosCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionInfosCount(query);
            dispatch({
                type: InstitutionInfoTypes.FETCH_INSTITUTIONINFOS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionInfoAction(
    institutionInfo: IInstitutionInfo
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionInfo = await insertInstitutionInfo(institutionInfo);
            dispatch({
                type: InstitutionInfoTypes.INSERT_INSTITUTIONINFO,
                payload: newInstitutionInfo
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionInfoAction(
    id: number,
    institutionInfo: IInstitutionInfo
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionInfo = await updateInstitutionInfo(id, institutionInfo);
            dispatch({
                type: InstitutionInfoTypes.UPDATE_INSTITUTIONINFO,
                payload: updatedInstitutionInfo
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionInfoAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionInfo(id);
            dispatch({
                type: InstitutionInfoTypes.DELETE_INSTITUTIONINFO
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionInfosAction(
    dict: INormalizedInstitutionInfos
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_INSTITUTIONINFOS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionInfoAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_SELECTED_INSTITUTIONINFO,
            payload: id
        });
    };
}

export function setSelectedInstitutionInfoMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_SELECTED_INSTITUTIONINFO_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionInfoAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_IS_LOADING_INSTITUTIONINFO,
            payload: isLoading
        });
    };
}
export function setInstitutionInfoOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_INSTITUTIONINFO_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionInfoErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionInfoTypes.SET_INSTITUTIONINFO_ERROR_CODE,
            payload: errorCode
        });
    };
}
