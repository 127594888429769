enum AdminActionTypes {
    FETCH_ADMINS = "FETCH_ADMINS",
    SET_ADMINS = "SET_ADMINS",
    FETCH_ADMINS_COUNT = "FETCH_ADMINS_COUNT",
    SET_SELECTED_ADMIN = "SET_SELECTED_ADMIN",
    SET_SELECTED_ADMIN_MULTI = "SET_SELECTED_ADMIN_MULTI",
    SET_IS_LOADING_ADMIN = "SET_IS_LOADING_ADMIN",
    SET_ADMIN_ORDER = "SET_ADMIN_ORDER",
    INSERT_ADMIN = "INSERT_ADMIN",
    INSERT_INSTITUTION_HAS_ADMIN = "INSERT_INSTITUTION_HAS_ADMIN",
    UPDATE_ADMIN = "UPDATE_ADMIN",
    DELETE_ADMIN = "DELETE_ADMIN",
    DELETE_ADMIN_IN_INSTITUTION_NOTIFICATION_SERVICE = "DELETE_ADMIN_IN_INSTITUTION_NOTIFICATION_SERVICE"
}
export default AdminActionTypes;
