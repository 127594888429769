enum SpecialHourActionTypes {
    FETCH_SPECIALHOURS = "FETCH_SPECIALHOURS",
    SET_SPECIALHOURS = "SET_SPECIALHOURS",
    FETCH_SPECIALHOURS_COUNT = "FETCH_SPECIALHOURS_COUNT",
    SET_SELECTED_SPECIALHOUR = "SET_SELECTED_SPECIALHOUR",
    SET_SELECTED_SPECIALHOUR_MULTI = "SET_SELECTED_SPECIALHOUR_MULTI",
    SET_IS_LOADING_SPECIALHOUR = "SET_IS_LOADING_SPECIALHOUR",
    SET_SPECIALHOUR_ORDER = "SET_SPECIALHOUR_ORDER",
    INSERT_SPECIALHOUR = "INSERT_SPECIALHOUR",
    INSERT_SPECIALHOUR_HAS_SERVICE = "INSERT_SPECIALHOUR_HAS_SERVICE",
    INSERT_SPECIALHOUR_HAS_JOB = "INSERT_SPECIALHOUR_HAS_JOB",
    UPDATE_SPECIALHOUR = "UPDATE_SPECIALHOUR",
    DELETE_SPECIALHOUR = "DELETE_SPECIALHOUR",
    SET_SPECIALHOUR_ERROR_CODE = "SET_SPECIALHOUR_ERROR_CODE",
    FETCH_SPECIALHOUR_HAS_JOB = "FETCH_SPECIALHOUR_HAS_JOB",
    FETCH_SPECIALHOUR_HAS_SERVICE = "FETCH_SPECIALHOUR_HAS_SERVICE",
    DELETE_SPECIALHOUR_HAS_JOB = "DELETE_SPECIALHOUR_HAS_JOB",
    DELETE_SPECIALHOUR_HAS_SERVICE = "DELETE_SPECIALHOUR_HAS_SERVICE"
}
export default SpecialHourActionTypes;
