enum InstitutionChronosActionTypes {
    FETCH_INSTITUTIONCHRONOSS = "FETCH_INSTITUTIONCHRONOSS",
    SET_INSTITUTIONCHRONOSS = "SET_INSTITUTIONCHRONOSS",
    FETCH_INSTITUTIONCHRONOSS_COUNT = "FETCH_INSTITUTIONCHRONOSS_COUNT",
    SET_SELECTED_INSTITUTIONCHRONOS = "SET_SELECTED_INSTITUTIONCHRONOS",
    SET_SELECTED_INSTITUTIONCHRONOS_MULTI = "SET_SELECTED_INSTITUTIONCHRONOS_MULTI",
    SET_IS_LOADING_INSTITUTIONCHRONOS = "SET_IS_LOADING_INSTITUTIONCHRONOS",
    SET_INSTITUTIONCHRONOS_ORDER = "SET_INSTITUTIONCHRONOS_ORDER",
    INSERT_INSTITUTIONCHRONOS = "INSERT_INSTITUTIONCHRONOS",
    UPDATE_INSTITUTIONCHRONOS = "UPDATE_INSTITUTIONCHRONOS",
    DELETE_INSTITUTIONCHRONOS = "DELETE_INSTITUTIONCHRONOS",
    SET_INSTITUTIONCHRONOS_ERROR_CODE = "SET_INSTITUTIONCHRONOS_ERROR_CODE"
}
export default InstitutionChronosActionTypes;
