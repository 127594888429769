import { connect } from "react-redux";
import {
    insertInstitutionHasContractSalaryGridAction,
    fetchAllContractSalaryGridsAction,
    fetchContractSalaryGridsAction
} from "../../store/action/contractSalaryGrid.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { ICreateContractSalaryGridRequest, IContractSalaryGrid } from "../../../Model/ContractSalaryGrid";
import { IQueryParams } from "npm-medgo-query";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { getElementsInInstitution } from "../../../toolbox/filters";

interface IReduxPropsFormContractSalaryGrid
    extends IReduxPropsForm<{ ContractSalaryGrid: { label: string; value: number } }> {
    idAllSalaryGrids: number[];
    idContractSalaryGrids: number[];
    idInstitutions: number[];
}

interface IPropsFormContractSalaryGrid
    extends IReduxPropsFormContractSalaryGrid,
        IReduxActionsForm,
        IPropsForm<{ ContractSalaryGrid: { label: string; value: number } }> {}
interface IReduxActionsForm {
    insertInstitutionHasContractSalaryGridAction: (
        createContractSalaryGridRequest: ICreateContractSalaryGridRequest
    ) => void;
    fetchAllContractSalaryGridsAction: (param: IQueryParams) => void;
    fetchContractSalaryGridsAction: (param: IQueryParams) => void;
}
class FormContractSalaryGridComponent extends FormComponent<
    { ContractSalaryGrid: { label: string; value: number } },
    IPropsFormContractSalaryGrid
> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllSalaryGrids.length === 0)
            await this.props.fetchAllContractSalaryGridsAction({ limit: 99999 });
        if (this.props.idContractSalaryGrids.length === 0)
            await this.props.fetchContractSalaryGridsAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
    }
    async createElementAction(): Promise<void> {
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formDictionarySalaryGrids = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.salaryGrid,
            {
                ContractSalaryGrid: {}
            }
        );
        const formKeys = Object.keys(formDictionarySalaryGrids);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionarySalaryGrid = formDictionarySalaryGrids[key];
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasContractSalaryGridAction({
                                    institution: idInstitution,
                                    contractSalaryGrid: formDictionarySalaryGrid.name.value,
                                    name: formDictionarySalaryGrid.name.label
                                });
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }
}
export default connect(
    (centralState: any): IReduxPropsForm<ICreateContractSalaryGridRequest> =>
        Object.assign(getConnectParentState(centralState), {
            selectOptions:
                getElementsInInstitution(
                    getModelsFromDict<IContractSalaryGrid>(
                        centralState.contractSalaryGrids.byId,
                        centralState.contractSalaryGrids.ids
                    ),
                    centralState.institutions.selectedMulti
                ).length > 0
                    ? []
                    : {
                          [EBackOfficeColumnName.name]: getElementsForSelectComponent(
                              centralState.contractSalaryGrids,
                              centralState.institutions.selectedMulti
                          )
                      },
            idAllSalaryGrids: centralState.contractSalaryGrids.allIds,
            idContractSalaryGrids: centralState.contractSalaryGrids.ids,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        insertInstitutionHasContractSalaryGridAction,
        fetchAllContractSalaryGridsAction,
        fetchContractSalaryGridsAction
    })
)(FormContractSalaryGridComponent);
