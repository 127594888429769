import { IReduxAction } from "../store.type";
import InstitutionOptionTypes from "../type/institutionOption.type";

const initialState = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false,
    fusedInstitutionsOptions: {},
    slotConfigs: []
};

export default function institutionOptionReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOptionTypes.FETCH_INSTITUTIONOPTIONS:
        case InstitutionOptionTypes.SET_INSTITUTIONOPTIONS:
            return {
                ...state,
                byId: { ...action.payload.byId },
                ids: [...action.payload.ids],
                fusedInstitutionsOptions: { ...action.payload.fusedInstitutionsOptions }
            };

        case InstitutionOptionTypes.FETCH_INSTITUTIONOPTIONS_COUNT:
            return { ...state, count: action.payload };

        case InstitutionOptionTypes.SET_SELECTED_INSTITUTIONOPTION:
            return { ...state, selectedId: action.payload };

        case InstitutionOptionTypes.SET_SELECTED_INSTITUTIONOPTION_MULTI:
            return { ...state, selectedMulti: action.payload };

        case InstitutionOptionTypes.SET_IS_LOADING_INSTITUTIONOPTION:
            return { ...state, isLoading: action.payload };

        case InstitutionOptionTypes.SET_INSTITUTIONOPTION_ORDER:
            return { ...state, ids: action.payload };

        case InstitutionOptionTypes.INSERT_INSTITUTIONOPTION:
        case InstitutionOptionTypes.UPDATE_INSTITUTIONOPTION: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.institution]: {
                        ...state.byId[action.payload.institution],
                        ...action.payload
                    }
                }
            };
        }

        case InstitutionOptionTypes.FETCH_SLOT_CONFIGS:
            return {
                ...state,
                slotConfigs: action.payload.slotConfigs
            };

        default:
            return state;
    }
}
