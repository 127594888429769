import { IFormDictionary } from "../../store/type/form.type";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

export function getModelByIdFromDict<T>(dict: { [key: number]: T }, id: number): T {
    return dict[id];
}

export function getModelsFromDict<T>(dict: { [key: number]: T }, ids: number[]): T[] {
    return ids.map((id: number): T => dict[id]);
}

export function getModelsByIdsFromDict<T>(elements: any): { [key: number]: T } {
    const nbElements = elements.length;
    let dict = {};
    for (let i = 0; i < nbElements; i++) {
        const element = elements[i];
        const id = element.id;
        dict = Object.assign(dict, {
            [id]: element
        });
    }
    return dict;
}

export function getModelIdsFromDict(elements: any): number[] {
    const nbElements = elements.length;
    let ids = [];
    for (let i = 0; i < nbElements; i++) {
        const idElement = elements[i].id;
        ids.push(idElement);
    }
    return ids;
}

export function getFormDictionaryRecordsDict(
    formDictionary: IFormDictionary,
    tableName: EBackOfficeTableName,
    defaultValue = {}
): { [key: string]: any } {
    return formDictionary[tableName] ? formDictionary[tableName] : defaultValue;
}

export function isKeyElementAlreadyInSelectedInstitutions<T>(
    dict: { [key: number]: T },
    ids: number[],
    selectedMultis: number[],
    formDictionaryKey: string,
    key: keyof T,
    modifyingElement: any
): boolean {
    const existingElements = getElementsInInstitution(getModelsFromDict<T>(dict, ids), selectedMultis);
    const existingElementsWithKey = existingElements.filter((element: T): boolean => {
        // @ts-ignore
        if (modifyingElement && modifyingElement.id === element.id) return false;
        // @ts-ignore
        return element[key] === formDictionaryKey;
    });
    return existingElementsWithKey.length > 0;
}

export function isKeyElementAlreadyInFormDictionary(
    formDictionaryTable: { [column: string]: { [index: string]: any } },
    formDictionaryKey: string,
    key: string
): boolean {
    const formElements = Object.values(formDictionaryTable).filter((element): boolean => {
        // @ts-ignore
        return element[key] === formDictionaryKey;
    });
    return formElements.length > 1;
}

export function isKeyElementAlreadyInFormOrInstitutions({
    dict,
    ids,
    selectedMultis,
    formDictionaryTable,
    formDictionaryKey,
    key,
    modifyingElement
}: {
    dict: { [key: number]: any };
    ids: number[];
    selectedMultis: number[];
    formDictionaryTable: { [column: string]: { [index: string]: any } };
    formDictionaryKey: string;
    key: string;
    modifyingElement: any;
}): boolean {
    return (
        isKeyElementAlreadyInFormDictionary(formDictionaryTable, formDictionaryKey, key) ||
        isKeyElementAlreadyInSelectedInstitutions(dict, ids, selectedMultis, formDictionaryKey, key, modifyingElement)
    );
}

export function hasErrorMessage(
    errorMessage: { [table: string]: { [index: string]: { [column: string]: string } } },
    tableName: EBackOfficeTableName,
    index: number,
    columnName: EBackOfficeColumnName
): boolean {
    const hasErrorMessage =
        errorMessage &&
        errorMessage[tableName] &&
        errorMessage[tableName][index] &&
        errorMessage[tableName][index][columnName]
            ? true
            : false;
    return hasErrorMessage;
}
