/* eslint-disable prettier/prettier */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";

const initialState: any = {};
const trackMiddleware = (): any => (next: any): any => (action: any): any => {
    // if (action.type.indexOf("SET_IS_LOADING") === -1 && action.type.indexOf("IS_APP") === -1) {
    //     console.log(action.type);
    // }
    // if (action.type.indexOf("FETCH") !== -1 && action.type.indexOf("SET_IS_LOADING") === -1) {
    //     // We can track here
    //     console.log(action.type);
    // }
    return next(action);
};
/* eslint-disable no-underscore-dangle */
declare let window: any;
const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const middlewares = [thunk, trackMiddleware];
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
/* eslint-enable */

export default store;
