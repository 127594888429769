import { Equality } from "npm-medgo-toolbox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IInstitutionHasOAuth2 } from "../../../../Model/InstitutionHasOAuth2";
import { updateInstitutionHasOAuth2Action } from "../../../store/action/institutionHasOAuth2.action";

interface UsePKCEStateProps {
    isEnabled: boolean;
    handleUsePKCEState: () => void;
}

export const usePKCEState = (oauth2: IInstitutionHasOAuth2 | undefined): UsePKCEStateProps => {
    const dispatch = useDispatch();
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect((): void => {
        if (Equality.isNullOrUndefined(oauth2)) {
            setIsEnabled(false);
        } else {
            setIsEnabled((oauth2 as IInstitutionHasOAuth2).usePKCE);
        }
    }, [oauth2]);

    const handleUsePKCEState = (): void => {
        setIsEnabled((prev): boolean => !prev);
        const data = oauth2 as IInstitutionHasOAuth2;
        dispatch(
            updateInstitutionHasOAuth2Action(data.id, {
                ...data,
                usePKCE: !isEnabled,
                shouldPropagateToGroup: true
            })
        );
    };

    return {
        isEnabled,
        handleUsePKCEState
    };
};
