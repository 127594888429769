import { connect } from "react-redux";
import {
    fetchInfoOnWorkersAction,
    setInfoOnWorkersAction,
    setIsLoadingInfoOnWorkerAction
} from "../../store/action/infoOnWorker.action";

import { IInfoOnWorker } from "../../../Model/InfoOnWorker";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInfoOnWorker extends IReduxPropsTable {
    idInfoOnWorkers: number[];
    infoOnWorkerDict: IInfoOnWorker[];
    idInstitutions: number[];
}

interface IPropsTableInfoOnWorker extends IReduxPropsInfoOnWorker, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setInfoOnWorkersAction: (elements: IInfoOnWorker[]) => any;
    fetchInfoOnWorkersAction: (param: IQueryParams) => any;
    setIsLoadingInfoOnWorkerAction: (isLoading: boolean) => any;
}

class TableInfoOnWorkerComponent extends TableComponent<IPropsTableInfoOnWorker> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInfoOnWorkerAction(true);
        if (this.props.idInfoOnWorkers.length === 0) await this.props.fetchInfoOnWorkersAction(params);
        this.props.setIsLoadingInfoOnWorkerAction(false);
    }

    setElements(elements: any): void {
        this.props.setInfoOnWorkersAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.infoOnWorkerDict, this.props.idInfoOnWorkers);
    }
}

export default connect(
    (centralState: any): IReduxPropsInfoOnWorker =>
        Object.assign(getConnectParentState(centralState), {
            idInfoOnWorkers: centralState.infoOnWorker.ids,
            infoOnWorkerDict: centralState.infoOnWorker.byId,
            idInstitutions: centralState.institutions.ids,
            isLoading: centralState.infoOnWorker.isLoading
        }),
    Object.assign(getConnectParentActions(), {
        fetchInfoOnWorkersAction,
        setInfoOnWorkersAction,
        setIsLoadingInfoOnWorkerAction
    })
)(TableInfoOnWorkerComponent);
