import { IReduxAction } from "../store.type";
import JobTypes from "../type/job.type";
import { fetchJobs, insertInstitutionHasJob, updateJob, deleteJob } from "../../service/job.service";
import { IJob, INormalizedJobs, ICreateJobRequest } from "../../../Model/Job";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchJobsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const jobs = await fetchJobs(query);
            dispatch({
                type: JobTypes.FETCH_JOBS,
                payload: {
                    byId: jobs.reduce((p: INormalizedJobs, c: IJob): INormalizedJobs => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: jobs.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchAllJobsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const jobs = await fetchJobs(query);
            dispatch({
                type: JobTypes.FETCH_ALL_JOBS,
                payload: {
                    byId: jobs.reduce((p: INormalizedJobs, c: IJob): INormalizedJobs => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: jobs.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasJobAction(job: ICreateJobRequest): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertInstitutionHasJob(job);
            dispatch({
                type: JobTypes.INSERT_INSTITUTION_HAS_JOB,
                payload: job
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateJobAction(id: number, job: IJob): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateJob(id, job);
            dispatch({
                type: JobTypes.UPDATE_JOB
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteJobAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteJob(id);
            dispatch({
                type: JobTypes.DELETE_JOB
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setJobsAction(dict: { byId: any; ids: any }): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_JOBS,
            payload: dict
        });
    };
}

export function setSelectedJobAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_SELECTED_JOB,
            payload: id
        });
    };
}

export function setSelectedJobMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_SELECTED_JOB_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingJobAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_IS_LOADING_JOB,
            payload: isLoading
        });
    };
}
export function setJobOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_JOB_ORDER,
            payload: idsSorted
        });
    };
}
