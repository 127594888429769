import React, { Component } from "react";
import { connect } from "react-redux";
import { EBackOfficeColumnName, EBackOfficeColumnType } from "../../constants/Column.constants";
import { IBackOfficeTable, EBackOfficeTableName } from "../../constants/Table.constants";
import { IBackOfficeColumn } from "../../constants/Column.constants";
import "../../scss/components/table/FormTable.scss";
import { ISelectLabelValue } from "npm-medgo-components";
import FormInputComponent, { getSelectCheckboxOptions } from "../../components/form/FormLabelInput.component";
import { setFormKeyValueAction, resetErrorMessageAction, deleteFormLineAction } from "../../store/action/form.action";
import { hasErrorMessage, getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import BoxComponent from "../../components/table/Box.component";
import { IFormDictionary } from "../../store/type/form.type";
import LineHeaderComponent from "../../components/table/LineHeader.component";
import { valueForm } from "../../type/form.type";
import { displayCriteria } from "../../../toolbox/permissions";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { INormalizedInstitutions } from "../../../Model/Institution";

interface IReduxPropsFormView {
    errorMessage: { [table: string]: { [index: string]: { [column: string]: string } } };
    formDictionary: IFormDictionary;
    numberForms: number;
    selectedTab: number;
    institutionDict: INormalizedInstitutions;
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    idInstitutions: number[];
    idPoles: number[];
    modifyingElement: any;
}

interface IReduxActionsTableFilterView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    resetErrorMessageAction: (table: EBackOfficeTableName, index: number, column: EBackOfficeColumnName) => void;
    deleteFormLineAction: (formIndex: number, backOfficeTableName: EBackOfficeTableName) => void;
}

interface IPropsFormView extends IReduxPropsFormView, IReduxActionsTableFilterView {
    backOfficeTable: IBackOfficeTable;
    selectOptions?: { [key: string]: ISelectLabelValue[] };
    selectCheckboxListOptions?: { [key: string]: { [key: string]: any[] } };
    customOnChange: (value: valueForm, column: IBackOfficeColumn, formIndex: number) => void;
    checkFormDictionary: (isOnSave: boolean) => void;
    setErrorMessageFromErrorCode: (error: any, columnName: EBackOfficeColumnName, index: number) => void;
    customSelectCheckboxOnChange: (value: any, column: IBackOfficeColumn, formIndex: number) => void;
}

class FormView extends Component<IPropsFormView> {
    render(): JSX.Element {
        const formLines = [];
        for (let i = 0; i < this.props.numberForms; i++) {
            formLines.push(this.renderFormLine(i));
            formLines.push(this.renderErrorLine(i));
        }
        return (
            <table style={{ width: "100%" }}>
                <thead>
                    <LineHeaderComponent backOfficeTable={this.props.backOfficeTable} />
                </thead>
                {formLines}
            </table>
        );
    }

    renderFormLine(formIndex: number): JSX.Element {
        const backOfficeTable = this.props.backOfficeTable;
        const columns = (backOfficeTable.formColumns
            ? backOfficeTable.formColumns[this.props.selectedTab]
            : backOfficeTable.columns[this.props.selectedTab]
        ).filter(
            (e): boolean =>
                e.name !== EBackOfficeColumnName.id && e.name !== EBackOfficeColumnName.institution && e.displayColumn
        );
        return (
            <tr className="tr-line-form">
                {columns.map(
                    (column, index): JSX.Element => {
                        if (
                            !displayCriteria(
                                column.displayCriteria,
                                getModelsFromDict(this.props.institutionDict, this.props.idInstitutions),
                                getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions),
                                this.props.modifyingElement ? true : false,
                                this.props.idPoles
                            )
                        )
                            return <></>;
                        const hasError = hasErrorMessage(
                            this.props.errorMessage,
                            backOfficeTable.name[this.props.selectedTab],
                            formIndex,
                            column.name
                        );
                        let component;
                        if (column.disableFormInput) {
                            component = this.renderBoxComponent(column, hasError, formIndex);
                        } else {
                            component = this.renderFormComponent(column, backOfficeTable, hasError, formIndex);
                        }
                        return (
                            <td key={`header_${index}`} className="td-line">
                                {component}
                            </td>
                        );
                    }
                )}
                {this.props.numberForms > 1 ? (
                    <td className="td-line">
                        <div className="pointer">
                            <span
                                style={{ color: "#FF6B6B" }}
                                onClick={(): void =>
                                    this.props.deleteFormLineAction(
                                        formIndex,
                                        backOfficeTable.name[this.props.selectedTab]
                                    )
                                }
                            >
                                <i className="fas fa-minus-circle"></i>
                            </span>
                        </div>
                    </td>
                ) : null}
            </tr>
        );
    }

    renderErrorLine(formIndex: number): JSX.Element {
        const backOfficeTable = this.props.backOfficeTable;
        const columns = (backOfficeTable.formColumns
            ? backOfficeTable.formColumns[this.props.selectedTab]
            : backOfficeTable.columns[this.props.selectedTab]
        ).filter((e): boolean => e.name !== EBackOfficeColumnName.id && e.name !== EBackOfficeColumnName.institution);
        return (
            <tr className="tr-line-form">
                {columns.map(
                    (column, index): JSX.Element => {
                        const hasError = hasErrorMessage(
                            this.props.errorMessage,
                            backOfficeTable.name[this.props.selectedTab],
                            formIndex,
                            column.name
                        );
                        return (
                            <td key={`header_${index}`} className="td-line">
                                {hasError ? (
                                    <div className="error-message">
                                        {
                                            this.props.errorMessage[backOfficeTable.name[this.props.selectedTab]][
                                                formIndex
                                            ][column.name]
                                        }
                                    </div>
                                ) : null}
                            </td>
                        );
                    }
                )}
            </tr>
        );
    }

    renderBoxComponent(column: IBackOfficeColumn, hasError: boolean, formIndex: number): JSX.Element {
        let formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            this.props.backOfficeTable.name[this.props.selectedTab]
        );
        formDictionaryTable = formDictionaryTable[formIndex] ? formDictionaryTable[formIndex] : {};
        const value = formDictionaryTable[column.name];
        return (
            <BoxComponent
                value={value}
                column={column}
                table={this.props.backOfficeTable.name[this.props.selectedTab]}
                selectCheckboxListOptions={getSelectCheckboxOptions(
                    this.props.selectCheckboxListOptions,
                    this.props.backOfficeTable.name[this.props.selectedTab],
                    column.name
                )}
                hasError={hasError}
            />
        );
    }

    renderFormComponent(
        column: IBackOfficeColumn,
        backOfficeTable: IBackOfficeTable,
        hasError: boolean,
        formIndex: number
    ): JSX.Element {
        const formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            backOfficeTable.name[this.props.selectedTab]
        );
        return (
            <FormInputComponent
                key={column.name}
                column={column}
                selectOptions={this.props.selectOptions}
                selectCheckboxListOptions={this.props.selectCheckboxListOptions}
                customSelectCheckboxOnChange={this.props.customSelectCheckboxOnChange}
                onChange={(value: valueForm): void => {
                    for (const index in formDictionaryTable) {
                        if (
                            hasErrorMessage(
                                this.props.errorMessage,
                                backOfficeTable.name[this.props.selectedTab],
                                parseInt(index),
                                column.name
                            )
                        )
                            this.props.resetErrorMessageAction(
                                backOfficeTable.name[this.props.selectedTab],
                                parseInt(index),
                                column.name
                            );
                    }
                    this.props.setFormKeyValueAction(
                        column.name,
                        value,
                        backOfficeTable.name[this.props.selectedTab],
                        column.type,
                        formIndex
                    );
                    this.props.customOnChange(value, column, formIndex);
                }}
                onBlur={(): void => {
                    try {
                        this.props.checkFormDictionary(false);
                    } catch (err) {
                        this.props.setErrorMessageFromErrorCode(err, err.columnName, formIndex);
                    }
                }}
                table={backOfficeTable.name[this.props.selectedTab]}
                formIndex={formIndex}
                hasError={hasError}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsFormView => ({
        errorMessage: centralState.form.errorMessage,
        formDictionary: centralState.form.formDictionary,
        numberForms: centralState.form.numberForms,
        selectedTab: centralState.tables.selectedTab,
        institutionDict: centralState.institutions.byId,
        idInstitutions: centralState.institutions.selectedMulti,
        idInstitutionOptions: centralState.institutionOption.ids,
        institutionOptionDict: centralState.institutionOption.byId,
        idPoles: centralState.poles.ids,
        modifyingElement: centralState.tables.modifyingElement
    }),
    {
        setFormKeyValueAction,
        resetErrorMessageAction,
        deleteFormLineAction
    }
)(FormView);
