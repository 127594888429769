enum NotificationContractActionTypes {
    FETCH_NOTIFICATIONCONTRACTS = "FETCH_NOTIFICATIONCONTRACTS",
    SET_NOTIFICATIONCONTRACTS = "SET_NOTIFICATIONCONTRACTS",
    FETCH_NOTIFICATIONCONTRACTS_COUNT = "FETCH_NOTIFICATIONCONTRACTS_COUNT",
    SET_SELECTED_NOTIFICATIONCONTRACT = "SET_SELECTED_NOTIFICATIONCONTRACT",
    SET_SELECTED_NOTIFICATIONCONTRACT_MULTI = "SET_SELECTED_NOTIFICATIONCONTRACT_MULTI",
    SET_IS_LOADING_NOTIFICATIONCONTRACT = "SET_IS_LOADING_NOTIFICATIONCONTRACT",
    SET_NOTIFICATIONCONTRACT_ORDER = "SET_NOTIFICATIONCONTRACT_ORDER",
    INSERT_NOTIFICATIONCONTRACT = "INSERT_NOTIFICATIONCONTRACT",
    UPDATE_NOTIFICATIONCONTRACT = "UPDATE_NOTIFICATIONCONTRACT",
    DELETE_NOTIFICATIONCONTRACT = "DELETE_NOTIFICATIONCONTRACT",
    SET_NOTIFICATIONCONTRACT_ERROR_CODE = "SET_NOTIFICATIONCONTRACT_ERROR_CODE",
    INSERT_INSTITUTION_HAS_NOTIFICATION_CONTRACT = "INSERT_INSTITUTION_HAS_NOTIFICATION_CONTRACT",
    FETCH_INSTITUTION_HAS_NOTIFICATIONCONTRACTS = "FETCH_INSTITUTION_HAS_NOTIFICATIONCONTRACTS",
    DELETE_INSTITUTION_HAS_NOTIFICATIONCONTRACT = "DELETE_INSTITUTION_HAS_NOTIFICATIONCONTRACT"
}
export default NotificationContractActionTypes;
