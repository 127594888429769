import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { INormalizedDispatchedPublicholidays } from "../../../../Model/Publicholiday";
import { IBackOfficeTable } from "../../../constants/Table.constants";
import ConfirmationModal from "./Confirmation.modal";
import { setShowConfirmationModalAction } from "../../../store/action/form.action";
import { setNotificationMessage } from "../../../store/action/notification.action";

interface IReduxPropsDeleteConfirmationModal {
    currentLang: string;
    lang: any;
    selectedTab: number;
    publicholidayDict?: INormalizedDispatchedPublicholidays;
    idItemToBeDeleted?: number;
}

interface IReduxActionsDeleteConfirmationModal {
    setShowConfirmationModalAction: (show: boolean, isDeleteConfirmation?: boolean) => void;
    setNotificationMessage: (message: any) => void;
}

interface IPropsDeleteConfirmationModal
    extends IReduxPropsDeleteConfirmationModal,
        IReduxActionsDeleteConfirmationModal {
    backOfficeTable: IBackOfficeTable;
    onDeleteLineClick: () => void;
}

export class DeleteConfirmationModal extends React.Component<IPropsDeleteConfirmationModal> {
    render(): JSX.Element {
        return (
            <ConfirmationModal
                lang={this.props.lang}
                subtitle={
                    this.props.lang[
                        `deleteConfirmationSubtitle_${this.props.backOfficeTable.name[this.props.selectedTab]}`
                    ]
                        ? this.props.lang[
                              `deleteConfirmationSubtitle_${this.props.backOfficeTable.name[this.props.selectedTab]}`
                          ]
                        : this.props.lang.areYouSure
                }
                text={
                    this.props.lang[`deleteConfirmationText_${this.props.backOfficeTable.name[this.props.selectedTab]}`]
                        ? this.props.lang[
                              `deleteConfirmationText_${this.props.backOfficeTable.name[this.props.selectedTab]}`
                          ].replace(
                              "__PUBLIC__HOLIDAY",
                              this.props.publicholidayDict &&
                                  this.props.idItemToBeDeleted &&
                                  this.props.publicholidayDict[this.props.idItemToBeDeleted]
                                  ? moment(
                                        this.props.publicholidayDict[this.props.idItemToBeDeleted].date,
                                        "DD-MM-YYYY"
                                    )
                                        .locale(this.props.currentLang)
                                        .format("LL")
                                  : ""
                          )
                        : ""
                }
                onClick1={(): void => {
                    this.props.setShowConfirmationModalAction(false);
                }}
                onClick2={(): void => {
                    this.props.onDeleteLineClick();
                    this.props.setShowConfirmationModalAction(false);
                    this.props.setNotificationMessage({
                        message: this.props.lang[
                            `deleteActionCompletedConfirmationMessage_${
                                this.props.backOfficeTable.name[this.props.selectedTab]
                            }`
                        ]
                            ? this.props.lang[
                                  `deleteActionCompletedConfirmationMessage_${
                                      this.props.backOfficeTable.name[this.props.selectedTab]
                                  }`
                              ]
                            : this.props.lang.deleted,
                        icon: "fa fa-check",
                        color: "success"
                    });
                }}
                textButton1={this.props.lang.cancel}
                textButton2={this.props.lang.confirm}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsDeleteConfirmationModal => ({
        lang: centralState.language.lang,
        selectedTab: centralState.tables.selectedTab,
        publicholidayDict: centralState.publicHoliday.byId,
        idItemToBeDeleted: centralState.tables.idItemToBeDeleted,
        currentLang: centralState.language.currentLang
    }),
    { setShowConfirmationModalAction, setNotificationMessage }
)(DeleteConfirmationModal);
