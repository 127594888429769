import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import {
    insertOrUpdateAdminInfo,
    fetchAdminInInstitutionNotificationService,
    insertAdminInInstitutionNotificationService,
    deleteAdminInInstitutionNotificationService,
    fetchAdmins
} from "../../service/admin.service";
import { insertAdminAction, updateAdminAction } from "../../store/action/admin.action";
import {
    insertInstitutionHasAdminAction,
    updateInstitutionHasAdminAction
} from "../../store/action/institutionHasAdmin.action";
import {
    getFormDictionaryRecordsDict,
    getModelsFromDict,
    isKeyElementAlreadyInSelectedInstitutions
} from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { fetchServicesAction } from "../../store/action/service.action";
import { IQueryParams } from "npm-medgo-query";
import { IFormDictionary } from "../../store/type/form.type";
import { EBackOfficeColumnName, EBackOfficeColumnType, IBackOfficeColumn } from "../../constants/Column.constants";
import { INormalizedServices, IService } from "../../../Model/Service";
import { getElementsInInstitution, getAssignmentInstitutionsInSelectedInstitution } from "../../../toolbox/filters";
import { ArrayToolbox, LangToolbox } from "npm-hublo-toolbox";
import {
    selectContractLevelOptions,
    selectLangOptions,
    selectTypeAdminOptions
} from "../../constants/SelectOptions.constants";
import { INormalizedPoles, IPole } from "../../../Model/Pole";
import { IInstitution, INormalizedInstitutions } from "../../../Model/Institution";
import { fetchPolesAction } from "../../store/action/pole.action";
import { fetchAllLevelsAction } from "../../store/action/level.action";
import { setFormKeyValueAction } from "../../store/action/form.action";
import { IInstitutionHasAdmin, INormalizedInstitutionHasAdmins } from "../../../Model/InstitutionHasAdmin";
import { setShowConfirmationModalAction, setConfirmationModalDataAction } from "../../store/action/form.action";
//@ts-ignore
import * as clone from "clone";
import { firstLetterUppercase } from "npm-hublo-toolbox/dist/modules/string";

interface IReduxPropsFormAdmin extends IReduxPropsForm<IAdmin> {
    servicesById: INormalizedServices;
    idServices: number[];
    idInstitutions: number[];
    selectedMulti: number[];
    adminById: any;
    idPoles: number[];
    polesById: INormalizedPoles;
    institutionById: INormalizedInstitutions;
    modifyingElement: any;
    idDetailedElement: number;
    idLevels: number[];
    idAdmins: number[];
    idInstitutionHasAdmins: number[];
    institutionHasAdminById: INormalizedInstitutionHasAdmins;
}

interface IPropsFormAdmin extends IReduxPropsFormAdmin, IReduxActionsForm, IPropsForm<IAdmin> {}

interface IReduxActionsForm {
    insertAdminAction: (formDictionary: IFormDictionary) => IAdmin;
    fetchServicesAction: (param: IQueryParams) => void;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    insertInstitutionHasAdminAction: (institutionHasAdmin: any) => void;
    updateAdminAction: (id: number, admin: IAdmin) => void;
    fetchPolesAction: (param: IQueryParams) => void;
    fetchAllLevelsAction: (param: IQueryParams) => void;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    updateInstitutionHasAdminAction: (id: number, institutionHasAdmin: Partial<IInstitutionHasAdmin>) => void;
    setShowConfirmationModalAction: (showConfirmation: boolean) => void;
    setConfirmationModalDataAction: (
        subtitle: string,
        text: string,
        onClick1: () => void,
        onClick2: () => void,
        textButton1: string,
        textButton2: string
    ) => void;
}

class FormAdminComponent extends FormComponent<IAdmin, IPropsFormAdmin> {
    async componentDidMount(): Promise<void> {
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        if (this.props.idPoles.length === 0)
            await this.props.fetchPolesAction({
                limit: 99999
            });
        if (this.props.idLevels.length === 0)
            await this.props.fetchAllLevelsAction({
                limit: 99999
            });
        if (this.props.idDetailedElement !== -1 && this.props.modifyingElement === null) {
            this.props.setModifyingElementAction(this.props.adminById[this.props.idDetailedElement]);
        }
    }

    async createElementAction(): Promise<void> {
        const formDictionaryAdmins = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.adminV2
        );
        try {
            const formDictionaryAdmin = formDictionaryAdmins[0];
            const foundAdmins = await fetchAdmins({
                query: {
                    strictEmail: formDictionaryAdmin.email.trim()
                }
            });
            const shouldAskUserValidation = foundAdmins.length > 0;
            if (shouldAskUserValidation) {
                await this.openCreationConfirmationModal(formDictionaryAdmin);
            } else {
                await this.createAdmin(formDictionaryAdmin, false);
            }
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.notAllInsertHaveBeenWellDone,
                icon: "fa fa-times",
                color: "danger"
            });
        }
    }

    async openCreationConfirmationModal(formDictionaryAdmin: any) {
        this.props.setShowConfirmationModalAction(true);
        this.props.setShowCreateOrModifyModalAction(false);
        await new Promise(resolve =>
            this.props.setConfirmationModalDataAction(
                this.props.lang.confirmationSubtitle_admin_insert,
                this.props.lang.confirmationText_admin_insert,
                (): void => {
                    this.props.setNotificationMessage({
                        message: this.props.lang.adminHasNotBeenAdded,
                        color: "danger"
                    });
                    resolve();
                },
                async (): Promise<void> => {
                    await this.createAdmin(formDictionaryAdmin, true);
                    this.props.setShowConfirmationModalAction(false);
                    this.props.setResetFormAction();
                    resolve();
                },
                this.props.lang.noDontApply,
                this.props.lang.yesApply
            )
        );
    }

    async createAdmin(formDictionaryAdmin: any, adminAlreadyExists: boolean): Promise<void> {
        const newAdmin = clone(formDictionaryAdmin);
        newAdmin.contractLevel = formDictionaryAdmin.contractLevel?.value;
        newAdmin.lang = formDictionaryAdmin.lang ? formDictionaryAdmin.lang.value : "fr";
        const idLanguage = LangToolbox.getIdLanguageFromLangString(newAdmin.lang);
        newAdmin.level = formDictionaryAdmin.level?.value;
        newAdmin.typeAdmin = formDictionaryAdmin.typeAdmin?.value;
        newAdmin.idLanguage = idLanguage;
        newAdmin.service = formDictionaryAdmin.notificationServices;
        newAdmin.isStatisticAllowed = true;
        newAdmin.isPlanningAllowed = true;
        newAdmin.shouldSendEmail = true;
        newAdmin.email = formDictionaryAdmin.email.trim();
        const idInstitutions = formDictionaryAdmin.assignmentInstitutions
            ? formDictionaryAdmin.assignmentInstitutions
            : [];
        newAdmin.idInstitution = idInstitutions;
        const { id: idAdmin } = await this.props.insertAdminAction(newAdmin);
        await Promise.all(
            idInstitutions.map(
                async (idInstitution: number): Promise<void> => {
                    await this.props.insertInstitutionHasAdminAction({
                        admin: idAdmin,
                        institution: idInstitution,
                        matricule: formDictionaryAdmin.matricule,
                        position: formDictionaryAdmin.position,
                        isActivated: true,
                        idLanguage: idLanguage,
                        shouldSendEmailForNewActivation: adminAlreadyExists
                    });
                    if (formDictionaryAdmin.service) {
                        const servicesInInstitution = formDictionaryAdmin.service.filter(
                            (idService: number): boolean => {
                                return this.props.servicesById[idService].institution.includes(idInstitution);
                            }
                        );
                        if (servicesInInstitution) {
                            await Promise.all(
                                servicesInInstitution.map(
                                    async (service: number): Promise<void> => {
                                        await insertAdminInInstitutionNotificationService(
                                            idAdmin,
                                            idInstitution,
                                            service
                                        );
                                    }
                                )
                            );
                        }
                    }
                }
            )
        );
    }

    async updateElementAction(): Promise<void> {
        const formDictionaryAdmins = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.adminV2
        );
        try {
            const formDictionaryAdmin = formDictionaryAdmins[0];
            const foundAdmins = await fetchAdmins({
                query: {
                    strictEmail: formDictionaryAdmin.email
                }
            });
            const emailAlreadyExists =
                foundAdmins.length > 0 && this.props.modifyingElement.email !== formDictionaryAdmin.email;
            if (emailAlreadyExists) {
                await this.openModificationExistingEmailModal();
            } else {
                await this.updateAdmin();
            }
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.notAllInsertHaveBeenWellDone,
                icon: "fa fa-times",
                color: "danger"
            });
        }
    }

    async updateAdmin(): Promise<void> {
        const admin = clone(getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.adminV2)[0]);
        admin.lang = admin.lang ? admin.lang.value : "fr";
        const idLanguage = LangToolbox.getIdLanguageFromLangString(admin.lang);
        admin.idLanguage = idLanguage;
        admin.contractLevel = admin.contractLevel?.value;
        admin.level = admin.level?.value;
        admin.typeAdmin = admin.typeAdmin?.value;
        admin.service = admin.notificationServices ? admin.notificationServices : [];
        await insertOrUpdateAdminInfo(admin.id, idLanguage);
        const adminBeforeUpdate = this.props.modifyingElement;
        admin.personalInfoEdited = {
            firstName: admin.firstName !== adminBeforeUpdate.firstName ? admin.firstName : undefined,
            lastName: admin.lastName !== adminBeforeUpdate.lastName ? admin.lastName : undefined,
            phone: admin.mobilePhone !== adminBeforeUpdate.mobilePhone ? admin.mobilePhone : undefined,
            matricule: admin.matricule !== adminBeforeUpdate.matricule ? admin.matricule : undefined
        };
        admin.shouldSendEmail =
            admin.personalInfoEdited.firstName ||
            admin.personalInfoEdited.lastName ||
            admin.personalInfoEdited.phone ||
            admin.personalInfoEdited.matricule;
        const idInstitution = this.props.selectedMulti[0];
        admin.institutionName = this.props.institutionById[idInstitution].name;
        if (adminBeforeUpdate.matricule !== admin.matricule || adminBeforeUpdate.position !== admin.position)
            await this.updateInstitutionHasAdmin(admin, idInstitution);
        const updatedAdmin = await this.updateAdminNotificationServices(admin);
        await this.props.updateAdminAction(admin.id, updatedAdmin);
    }

    async openModificationExistingEmailModal() {
        this.props.setShowConfirmationModalAction(true);
        await new Promise(resolve =>
            this.props.setConfirmationModalDataAction(
                this.props.lang.confirmationSubtitle_admin_update,
                this.props.lang.confirmationText_admin_update,
                (): void => {
                    this.props.setIsCreationOrUpdateLoadingAction(false);
                    this.props.setShowConfirmationModalAction(false);
                },
                (): void => {},
                firstLetterUppercase(this.props.lang.return),
                ""
            )
        );
    }

    async updateInstitutionHasAdmin(admin: IAdmin, idInstitution: number) {
        const institutionHasAdmins: IInstitutionHasAdmin[] = getModelsFromDict(
            this.props.institutionHasAdminById,
            this.props.idInstitutionHasAdmins
        );
        const institutionHasAdmin = institutionHasAdmins.find(
            (institutionHasAdmin: any): any =>
                institutionHasAdmin.admin === admin.id && idInstitution === institutionHasAdmin.institution
        );
        const institutionHasAdminUpdated: Partial<IInstitutionHasAdmin> = {
            matricule: admin.matricule,
            position: admin.position
        };
        if (institutionHasAdmin) {
            await this.props.updateInstitutionHasAdminAction(institutionHasAdmin.id, institutionHasAdminUpdated);
        } else {
            await this.props.insertInstitutionHasAdminAction(
                Object.assign(institutionHasAdminUpdated, {
                    isActivated: true,
                    admin: admin.id,
                    institution: idInstitution
                })
            );
        }
    }

    async updateAdminNotificationServices(admin: { [key: string]: any }): Promise<IAdmin> {
        const idInstitution: number = admin.assignmentInstitutions[0];
        const servicesById = this.props.servicesById;
        const allServicesBeforeUpdate: number[] = this.props.modifyingElement.service.filter(
            (service: number): boolean => service !== null
        );
        const servicesBeforeUpdateInInstitution = allServicesBeforeUpdate.filter((idService: number): boolean => {
            return servicesById[idService]?.institution.includes(idInstitution);
        });
        const servicesAfterUpdateInInstitution: number[] = admin.service.filter(
            (service: number): boolean => service !== null
        );
        const servicesToBeDeleted = servicesBeforeUpdateInInstitution.filter(
            (service: number): boolean => servicesAfterUpdateInInstitution.indexOf(service) === -1
        );
        const servicesToBeAdded = servicesAfterUpdateInInstitution.filter(
            (service: number): boolean => servicesBeforeUpdateInInstitution.indexOf(service) === -1
        );
        admin.service = allServicesBeforeUpdate;
        if (servicesToBeDeleted.length > 0) {
            const serviceToBeDeletedFromReducer = await this.deleteNotificationsServices(
                admin.id,
                idInstitution,
                servicesToBeDeleted,
                servicesAfterUpdateInInstitution
            );
            for (let i in serviceToBeDeletedFromReducer) {
                const index = admin.service.indexOf(serviceToBeDeletedFromReducer[i]);
                if (index > -1) admin.service.splice(index, 1);
            }
        }
        if (servicesToBeAdded.length > 0) {
            await this.addNotificationServicesInInstitution(admin.id, idInstitution, servicesToBeAdded);
            admin.service = admin.service.concat(servicesToBeAdded);
        }
        admin.assignmentInstitutions = [];
        admin.notificationPoles = [];
        admin.notificationServices = [];
        ArrayToolbox.removeDuplicateInArray(admin.service);
        return admin as IAdmin;
    }

    async deleteNotificationsServices(
        idAdmin: number,
        idInstitution: number,
        servicesToBeDeleted: number[],
        servicesAfterUpdate: number[]
    ): Promise<number[]> {
        const adminInInstitutionNotificationServices = await fetchAdminInInstitutionNotificationService({
            query: { service: servicesToBeDeleted, admin: idAdmin }
        });
        let servicesToBeKeptInReducer: number[] = [];
        await Promise.all(
            adminInInstitutionNotificationServices.map(
                async (adminInInstitutionNotificationService): Promise<void> => {
                    if (adminInInstitutionNotificationService.institution === idInstitution) {
                        await deleteAdminInInstitutionNotificationService(adminInInstitutionNotificationService.id);
                    } else {
                        servicesToBeKeptInReducer.push(adminInInstitutionNotificationService.id);
                    }
                }
            )
        );
        for (let i in servicesToBeKeptInReducer) {
            const index = servicesToBeDeleted.indexOf(servicesToBeKeptInReducer[i]);
            if (index > -1) servicesToBeDeleted.splice(index, 1);
        }
        return servicesToBeDeleted;
    }

    async addNotificationServicesInInstitution(
        idAdmin: number,
        idInstitution: number,
        servicesInInstitutionToBeAdded: number[]
    ) {
        for (let i in servicesInInstitutionToBeAdded) {
            await insertAdminInInstitutionNotificationService(
                idAdmin,
                idInstitution,
                servicesInInstitutionToBeAdded[i]
            );
        }
    }

    customCheck(value: string, column: IBackOfficeColumn, isOnSave: boolean): void {
        const formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.adminV2
        );
        if (
            isOnSave &&
            column.name === EBackOfficeColumnName.email &&
            value &&
            typeof value === "string" &&
            value.trim() !== "" &&
            isKeyElementAlreadyInSelectedInstitutions(
                this.props.adminById,
                this.props.idAdmins,
                formDictionaryTable[0].assignmentInstitutions,
                value.trim(),
                EBackOfficeColumnName.email,
                this.props.modifyingElement
            )
        ) {
            throw { columnName: EBackOfficeColumnName.email, errorCode: 449 };
        }
    }

    selectCheckboxListOptions(): any {
        const adminForm = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.adminV2)[0];
        let idSelectedAssignmentInstitutions: number[] =
            adminForm && adminForm.assignmentInstitutions ? adminForm.assignmentInstitutions : [];
        if (this.props.modifyingElement && idSelectedAssignmentInstitutions.length === 0) {
            idSelectedAssignmentInstitutions = this.props.selectedMulti;
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.assignmentInstitutions,
                this.props.selectedMulti,
                EBackOfficeTableName.adminV2,
                EBackOfficeColumnType.selectCheckbox,
                0
            );
        }
        const idSelectedServices: number[] = adminForm?.notificationServices ? adminForm.notificationServices : [];
        const selectCheckboxListOptionsPoles = getElementsInInstitution(
            getModelsFromDict<IPole>(this.props.polesById, this.props.idPoles),
            idSelectedAssignmentInstitutions
        );
        const idSelectedPoles: number[] = adminForm?.notificationPoles ? adminForm.notificationPoles : [];
        const selectCheckboxListOptionsServices = getElementsInInstitution(
            getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
            idSelectedAssignmentInstitutions
        );
        const idSelectedPolesToKeeps = idSelectedPoles.filter((idSelectedPole: number): boolean =>
            selectCheckboxListOptionsPoles.find((e): boolean => e.id === idSelectedPole)
        );
        const idSelectedServicesToKeeps = idSelectedServices.filter((idSelectedService: number): boolean =>
            selectCheckboxListOptionsServices.find((e): boolean => e.id === idSelectedService)
        );
        if (idSelectedPolesToKeeps.length !== idSelectedPoles.length)
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.notificationPoles,
                idSelectedPolesToKeeps,
                EBackOfficeTableName.adminV2,
                EBackOfficeColumnType.selectCheckbox,
                0
            );
        if (idSelectedServicesToKeeps.length !== idSelectedServices.length)
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.notificationServices,
                idSelectedServicesToKeeps,
                EBackOfficeTableName.adminV2,
                EBackOfficeColumnType.selectCheckbox,
                0
            );
        return {
            [EBackOfficeTableName.adminV2]: {
                [EBackOfficeColumnName.assignmentInstitutions]: getAssignmentInstitutionsInSelectedInstitution(
                    getModelsFromDict<IInstitution>(this.props.institutionById, this.props.idInstitutions),
                    []
                ),
                [EBackOfficeColumnName.notificationPoles]: selectCheckboxListOptionsPoles,
                [EBackOfficeColumnName.notificationServices]: selectCheckboxListOptionsServices
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsFormAdmin =>
        Object.assign(getConnectParentState(centralState), {
            servicesById: centralState.services.byId,
            selectedMulti: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids,
            idServices: centralState.services.ids,
            modifyingElement: centralState.tables.modifyingElement,
            adminById: centralState.admins.byId,
            selectOptions: {
                [EBackOfficeColumnName.lang]: selectLangOptions.lang,
                [EBackOfficeColumnName.typeAdmin]: selectTypeAdminOptions.typeAdmin,
                [EBackOfficeColumnName.level]: getElementsForSelectComponent(
                    centralState.level,
                    centralState.institutions.selectedMulti,
                    true,
                    true,
                    "value",
                    false,
                    e => `${e.id}${e.name == e.id ? "" : `: ${e.name}`}`
                ),
                [EBackOfficeColumnName.contractLevel]: selectContractLevelOptions.contractLevel
            },
            idPoles: centralState.poles.ids,
            idLevels: centralState.level.allIds,
            polesById: centralState.poles.byId,
            institutionById: centralState.institutions.byId,
            idDetailedElement: centralState.form.idDetailedElement,
            idAdmins: centralState.admins.ids,
            idInstitutionHasAdmins: centralState.institutionHasAdmin.ids,
            institutionHasAdminById: centralState.institutionHasAdmin.byId
        }),
    Object.assign(getConnectParentActions(), {
        insertAdminAction,
        fetchServicesAction,
        insertInstitutionHasAdminAction,
        updateAdminAction,
        fetchPolesAction,
        fetchAllLevelsAction,
        setFormKeyValueAction,
        updateInstitutionHasAdminAction,
        setShowConfirmationModalAction,
        setConfirmationModalDataAction
    })
)(FormAdminComponent);
