enum SpecialtyActionTypes {
    FETCH_SPECIALTIES = "FETCH_SPECIALTIES",
    FETCH_ALLSPECIALTIES = "FETCH_ALLSPECIALTIES",
    SET_SPECIALTIES = "SET_SPECIALTIES",
    SET_SELECTED_SPECIALTY = "SET_SELECTED_SPECIALTY",
    SET_SELECTED_SPECIALTY_MULTI = "SET_SELECTED_SPECIALTY_MULTI",
    SET_IS_LOADING_SPECIALTY = "SET_IS_LOADING_SPECIALTY",
    SET_SPECIALTY_ORDER = "SET_SPECIALTY_ORDER",
    INSERT_INSTITUTION_HAS_SPECIALTY = "INSERT_INSTITUTION_HAS_SPECIALTY",
    UPDATE_SPECIALTY = "UPDATE_SPECIALTY",
    DELETE_SPECIALTY = "DELETE_SPECIALTY",
    FETCH_SPECIALTIES_COUNT = "FETCH_SPECIALTIES_COUNT"
}
export default SpecialtyActionTypes;
