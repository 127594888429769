enum InstitutionOptionWorkerActionTypes {
    FETCH_INSTITUTIONOPTIONWORKERS = "FETCH_INSTITUTIONOPTIONWORKERS",
    SET_INSTITUTIONOPTIONWORKERS = "SET_INSTITUTIONOPTIONWORKERS",
    FETCH_INSTITUTIONOPTIONWORKERS_COUNT = "FETCH_INSTITUTIONOPTIONWORKERS_COUNT",
    SET_SELECTED_INSTITUTIONOPTIONWORKER = "SET_SELECTED_INSTITUTIONOPTIONWORKER",
    SET_SELECTED_INSTITUTIONOPTIONWORKER_MULTI = "SET_SELECTED_INSTITUTIONOPTIONWORKER_MULTI",
    SET_IS_LOADING_INSTITUTIONOPTIONWORKER = "SET_IS_LOADING_INSTITUTIONOPTIONWORKER",
    SET_INSTITUTIONOPTIONWORKER_ORDER = "SET_INSTITUTIONOPTIONWORKER_ORDER",
    INSERT_INSTITUTIONOPTIONWORKER = "INSERT_INSTITUTIONOPTIONWORKER",
    UPDATE_INSTITUTIONOPTIONWORKER = "UPDATE_INSTITUTIONOPTIONWORKER",
    DELETE_INSTITUTIONOPTIONWORKER = "DELETE_INSTITUTIONOPTIONWORKER",
    SET_INSTITUTIONOPTIONWORKER_ERROR_CODE = "SET_INSTITUTIONOPTIONWORKER_ERROR_CODE"
}
export default InstitutionOptionWorkerActionTypes;
