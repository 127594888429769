import { ISpecialHour } from "../../Model/SpecialHour";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const SpecialHourDefaultSelect = [
    "id",
    "name",
    "idInstitution",
    "isDayMission",
    "seniorityShouldBeLessThan",
    "seniorityShouldBeMoreThan",
    "isSunday",
    "isPublicHoliday",
    "job",
    "service"
];

export async function fetchSpecialHours(query: IQueryParams): Promise<ISpecialHour[]> {
    const specialHours = await postFetchFactory<ISpecialHour>(PossibleFetchs.specialHour, PossibleApis.medgoApi)(
        {
            select: SpecialHourDefaultSelect
        },
        query
    );
    if (specialHours.length === 0) return [];
    specialHours.forEach((e: ISpecialHour): void => {
        e.institution = [e.idInstitution];
    });
    return specialHours;
}

export async function fetchSpecialHourHasJob(query: IQueryParams): Promise<ISpecialHour[]> {
    const specialHourHasJobs = await postFetchFactory<ISpecialHour>(
        PossibleFetchs.specialHourHasJob,
        PossibleApis.medgoApi
    )(
        {
            select: ["id", "idJob", "idSpecialHour"]
        },
        query
    );
    return specialHourHasJobs;
}

export async function fetchSpecialHourHasService(query: IQueryParams): Promise<ISpecialHour[]> {
    const specialHourHasServices = await postFetchFactory<ISpecialHour>(
        PossibleFetchs.specialHourHasService,
        PossibleApis.medgoApi
    )(
        {
            select: ["id", "idService", "idSpecialHour"]
        },
        query
    );
    return specialHourHasServices;
}

export async function insertSpecialHour(specialHour: ISpecialHour): Promise<ISpecialHour> {
    return await postFactory<ISpecialHour>(PossiblePosts.specialHour, PossibleApis.medgoApi)(specialHour);
}

export async function insertSpecialHourHasService(idSpecialHour: number, idService: number): Promise<ISpecialHour> {
    return await postFactory<any>(
        PossiblePosts.specialHourHasService,
        PossibleApis.medgoApi
    )({
        idSpecialHour,
        idService
    });
}

export async function insertSpecialHourHasJob(idSpecialHour: number, idJob: number): Promise<ISpecialHour> {
    return await postFactory<any>(
        PossiblePosts.specialHourHasJob,
        PossibleApis.medgoApi
    )({
        idSpecialHour,
        idJob
    });
}

export async function updateSpecialHour(id: number, specialHour: ISpecialHour): Promise<ISpecialHour> {
    return await updateFactory<ISpecialHour>(PossibleUpdates.specialHour, PossibleApis.medgoApi)(id, specialHour);
}

export async function deleteSpecialHour(id: number): Promise<ISpecialHour> {
    return await deleteFactory<ISpecialHour>(PossibleDeletes.specialHour, PossibleApis.medgoApi)(id);
}

export async function deleteSpecialHourHasJob(id: number): Promise<ISpecialHour> {
    return await deleteFactory<ISpecialHour>(PossibleDeletes.specialHourHasJob, PossibleApis.medgoApi)(id);
}

export async function deleteSpecialHourHasService(id: number): Promise<ISpecialHour> {
    return await deleteFactory<ISpecialHour>(PossibleDeletes.specialHourHasService, PossibleApis.medgoApi)(id);
}
