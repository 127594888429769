import { en } from "./en";
import { fr } from "./fr";
import { de } from "./de";
import { es } from "./es";

export const langs = {
    en,
    fr,
    de,
    es
};
