import AxiosInstance from "../../modules/axios/axios.instance";

import { ICounter } from "../../Model/Counter";
import { PossibleFetchs, PossibleApis } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import {
    DeleteCounterResponseDto,
    FetchCountersResponseDto,
    InsertCounterResponseDto,
    UpdateCounterResponseDto
} from "../type/counter";
import { isArray } from "lodash";

const CounterDefaultSelect = [
    "id",
    "institution",
    "limitValue",
    "whichCounter",
    "starter",
    "statut",
    "isActive",
    "limitType"
];

export async function fetchCounters(query: IQueryParams): Promise<ICounter[]> {
    const payload = {
        idInstitutions: isArray(query.query.institution) ? query.query.institution : [query.query.institution],
        whichCounter: query.query.whichCounter,
        limitType: query.query.limitType,
        status: query.query.statut
    };
    const url = `${PossibleApis.hubloCounterV2}/counter/fetch`;
    const response = await AxiosInstance.post<FetchCountersResponseDto>(url, payload);
    return response.data.result.map(r => ({ ...r, statut: r.status }));
}

export async function fetchCountersCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<ICounter>(
        PossibleFetchs.counter,
        PossibleApis.hubloCounter,
        1
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertCounter(counter: ICounter): Promise<ICounter> {
    const payload = { ...counter, status: counter.statut };
    const url = `${PossibleApis.hubloCounterV2}/counter`;
    const response = await AxiosInstance.post<InsertCounterResponseDto>(url, payload);
    return { ...response.data.result, statut: response.data.result.status };
}

export async function updateCounter(id: number, counter: ICounter): Promise<ICounter> {
    const payload = { ...counter, status: counter.statut };
    const url = `${PossibleApis.hubloCounterV2}/counter/${id}`;
    const response = await AxiosInstance.put<UpdateCounterResponseDto>(url, payload);
    return { ...response.data.result, statut: response.data.result.status };
}

export async function deleteCounter(id: number): Promise<ICounter | boolean> {
    const url = `${PossibleApis.hubloCounterV2}/counter/${id}`;
    const response = await AxiosInstance.delete<DeleteCounterResponseDto>(url);
    return response.data.result === "ok";
}
