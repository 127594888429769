import { IReduxAction, IReduxInitialState } from "../store.type";
import ExportTypes from "../type/export.type";
import { IExport } from "../../../Model/Export";

const initialState: IReduxInitialState<IExport> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};

export default function exportReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ExportTypes.FETCH_EXPORTS:
        case ExportTypes.SET_EXPORTS:
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };

        case ExportTypes.FETCH_EXPORTS_COUNT:
            return {
                ...state,
                count: action.payload
            };
        case ExportTypes.SET_SELECTED_EXPORT:
            return {
                ...state,
                selectedId: action.payload
            };

        case ExportTypes.SET_SELECTED_EXPORT_MULTI:
            return {
                ...state,
                selectedMulti: action.payload
            };
        case ExportTypes.SET_IS_LOADING_EXPORT:
            return {
                ...state,
                isLoading: action.payload
            };
        case ExportTypes.SET_EXPORT_ORDER:
            return {
                ...state,
                ids: action.payload
            };
        case ExportTypes.INSERT_EXPORT:
        case ExportTypes.UPDATE_EXPORT: {
            const ids = Object.assign([], state.ids);
            ids.push(action.payload.map((e: IExport): number => e.id));
            const byId = Object.assign({}, state.byId);
            action.payload.forEach((e: IExport, i: number): void => {
                byId[e.id] = action.payload[i];
            });
            return {
                ...state,
                ids,
                byId
            };
        }
        default:
            return state;
    }
}
