import { connect } from "react-redux";
import {
    setSpecialtiesAction,
    fetchSpecialtiesAction,
    setIsLoadingSpecialtyAction
} from "../../store/action/specialty.action";

import { ISpecialty } from "../../../Model/Specialty";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedSpecialties } from "../../../Model/Specialty";
import { getModelsFromDict } from "../../modules/store/util";
import { INormalizedJobs } from "../../../Model/Job";
import { fetchAllJobsAction } from "../../store/action/job.action";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";

//@ts-ignore
interface IReduxPropsSpecialty extends IReduxPropsTable {
    idSpecialties: number[];
    specialtyDict: INormalizedSpecialties;
    jobDict: INormalizedJobs;
    idAllJobs: number[];
    searchValue: string;
}

interface IPropsSpecialty extends IReduxPropsSpecialty, IReduxActionsSpecialty, IPropsTable {}

interface IReduxActionsSpecialty extends IReduxActionsTable {
    setSpecialtiesAction: (elements: ISpecialty[]) => any;
    setIsLoadingSpecialtyAction: (isLoading: boolean) => any;
    fetchSpecialtiesAction: (param: IQueryParams, jobDict: INormalizedJobs) => any;
    fetchAllJobsAction: (param: IQueryParams) => any;
}

class TableSpecialtyContainer extends TableComponent<IPropsSpecialty> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingSpecialtyAction(true);
        if (this.props.idAllJobs.length === 0) await this.props.fetchAllJobsAction({ limit: 99999 });
        if (this.props.idSpecialties.length === 0) await this.props.fetchSpecialtiesAction(params, this.props.jobDict);
        this.props.setIsLoadingSpecialtyAction(false);
    }

    setElements(elements: any): void {
        this.props.setSpecialtiesAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.specialtyDict, this.props.idSpecialties)
            .filter(
                (e): boolean =>
                    !this.props.searchValue || isIncludedStringCaseInsensitive(e.name, this.props.searchValue)
            )
            .sort((a, b): number => a.job - b.job);
    }
}

export default connect(
    (centralState: any): IReduxPropsSpecialty =>
        Object.assign(getConnectParentState(centralState), {
            idSpecialties: centralState.specialties.ids,
            specialtyDict: centralState.specialties.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.specialties.isLoading,
            jobDict: centralState.jobs.allById,
            idAllJobs: centralState.jobs.allIds,
            searchValue: centralState.tables.searchValue
        }),
    Object.assign(getConnectParentActions(), {
        setSpecialtiesAction,
        fetchSpecialtiesAction,
        setIsLoadingSpecialtyAction,
        fetchAllJobsAction
    })
)(TableSpecialtyContainer);
