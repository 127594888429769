import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import langReducer from "./lang.reducer";
import notificationReducer from "./notification.reducer";
import adminsReducer from "./admin.reducer";
import institutionsReducer from "./institution.reducer";
import appReducer from "./app.reducer";
import servicesReducer from "./service.reducer";
import tableReducer from "./table.reducer";
import institutionChronosReducer from "./institutionChronos.reducer";
import categoryReducer from "./category.reducer";
import formReducer from "./form.reducer";
import institutionOctimeReducer from "./institutionOctime.reducer";
import scheduleReducer from "./schedule.reducer";
import jobReducer from "./job.reducer";
import institutionGroupReducer from "./institutionGroup.reducer";
import levelReducer from "./level.reducer";
import specialtyReducer from "./specialty.reducer";
import infoOnWorkerReducer from "./infoOnWorker.reducer";
import motifReducer from "./motif.reducer";
import interimAgencyReducer from "./interimAgency.reducer";
import contractSalaryGridReducer from "./contractSalaryGrid.reducer";
import templateReducer from "./template.reducer";
import specialHourReducer from "./specialHour.reducer";
import notificationContractReducer from "./notificationContract.reducer";
import poleReducer from "./pole.reducer";
import counterReducer from "./counter.reducer";
import exportReducer from "./export.reducer";
import payrollcodeReducer from "./payrollcode.reducer";
import publicholidayReducer from "./publicholiday.reducer";
import institutionOptionReducer from "./institutionOption.reducer";
import institutionHasSsoReducer from "./institutionHasSso.reducer";
import institutionHasOAuth2Reducer from "./institutionHasOAuth2.reducer";
import institutionInfoReducer from "./institutionInfo.reducer";
import institutionOptionContractReducer from "./institutionOptionContract.reducer";
import institutionOptionChurnReducer from "./institutionOptionChurn.reducer";
import institutionOptionMissionReducer from "./institutionOptionMission.reducer";
import institutionOptionWorkerReducer from "./institutionOptionWorker.reducer";
import institutionHasAdminReducer from "./institutionHasAdmin.reducer";
import notificationAdminReducer from "./notificationAdmin.reducer";

export default combineReducers({
    auth: authReducer,
    language: langReducer,
    notification: notificationReducer,
    institutions: institutionsReducer,
    app: appReducer,
    level: levelReducer,
    institutionGroups: institutionGroupReducer,
    admins: adminsReducer,
    services: servicesReducer,
    tables: tableReducer,
    category: categoryReducer,
    form: formReducer,
    institutionChronos: institutionChronosReducer,
    schedule: scheduleReducer,
    institutionOctime: institutionOctimeReducer,
    jobs: jobReducer,
    specialties: specialtyReducer,
    infoOnWorker: infoOnWorkerReducer,
    motifs: motifReducer,
    interimAgency: interimAgencyReducer,
    template: templateReducer,
    contractSalaryGrids: contractSalaryGridReducer,
    specialHours: specialHourReducer,
    notificationContract: notificationContractReducer,
    poles: poleReducer,
    counter: counterReducer,
    exports: exportReducer,
    payrollCode: payrollcodeReducer,
    publicHoliday: publicholidayReducer,
    institutionOption: institutionOptionReducer,
    sso: institutionHasSsoReducer,
    oauth2: institutionHasOAuth2Reducer,
    institutionInfo: institutionInfoReducer,
    institutionOptionContract: institutionOptionContractReducer,
    institutionOptionChurn: institutionOptionChurnReducer,
    institutionOptionMission: institutionOptionMissionReducer,
    institutionOptionWorker: institutionOptionWorkerReducer,
    institutionHasAdmin: institutionHasAdminReducer,
    notificationAdmin: notificationAdminReducer
});
