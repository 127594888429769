import React from "react";
import { connect } from "react-redux";
import { setSelectedTabAction } from "../../store/action/table.action";
import "../../scss/components/table/TableTab.scss";

interface IReduxPropsTableTab {
    currentLang: string;
    lang: any;
    selectedTab: number;
}

interface IPropsTableTab extends IReduxPropsTableTab {
    tabs: string[];
    setSelectedTabAction: (selectedTab: number) => void;
}

class TableTab extends React.Component<IPropsTableTab> {
    render(): JSX.Element {
        return (
            <div>
                <div className="row table-tab-container">
                    {this.props.tabs.map(
                        (tab, index): JSX.Element => {
                            if (index === this.props.selectedTab) {
                                return (
                                    <button
                                        type="button"
                                        onClick={(): void => {
                                            this.props.setSelectedTabAction(index);
                                        }}
                                        className="btn selected-table-tab"
                                    >
                                        {this.props.lang[tab].toUpperCase()}
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        type="button"
                                        className="btn table-tab"
                                        onClick={(): void => {
                                            this.props.setSelectedTabAction(index);
                                        }}
                                    >
                                        {this.props.lang[tab].toUpperCase()}
                                    </button>
                                );
                            }
                        }
                    )}
                </div>
                <hr className="table-tab-horizontal-row" />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsTableTab => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        selectedTab: centralState.tables.selectedTab
    }),
    { setSelectedTabAction }
)(TableTab);
