/* eslint-disable @typescript-eslint/camelcase */
import { CounterLimitType, CounterType } from "../../../Constant/Counter.constant";
import moment from "moment";
import { ILang } from "../../lang/Lang";

export function getWhichCounterLabel(whichCounter: number, lang: ILang): string {
    switch (whichCounter) {
        case CounterType.Weekly:
            return lang.weekly;

        case CounterType.Monthly:
            return lang.monthly;

        case CounterType.Yearly:
            return lang.yearly;
        case CounterType.WeeklyByRollingWeek:
            return lang.weeklyByRollingWeek;
        default:
            return "";
    }
}

export function getStarterLabel(starter: number, whichCounter: number, lang: ILang): string {
    switch (whichCounter) {
        case CounterType.Weekly:
            return lang.weeklyStarter.replace(
                "__DAY",
                moment()
                    .day(starter)
                    .format("dddd")
            );

        case CounterType.Monthly:
            return lang.monthlyStarter.replace("__DAY", `${starter}`);

        case CounterType.Yearly:
            return lang.yearlyStarter.replace(
                "__DAY",
                moment()
                    .set("dayOfYear", starter)
                    .format("D MMMM")
            );
        case CounterType.WeeklyByRollingWeek:
        default:
            return "";
    }
}

export function getCounterLimitTypeLabel(limitType: number, lang: ILang): string {
    switch (limitType) {
        case CounterLimitType.WorkedMinutes:
            return lang.hour;
        case CounterLimitType.Shifts:
            return lang.shift;
        default:
            return "";
    }
}

function getCounterWorkedMinutesLimitColumnDisplayName(counterType: CounterType, lang: ILang): string {
    switch (counterType) {
        case CounterType.Weekly:
            return lang.weeklyH;
        case CounterType.Monthly:
            return lang.monthlyH;
        case CounterType.Yearly:
            return lang.yearlyH;
        case CounterType.WeeklyByRollingWeek:
            return lang.weeklyByRollingWeekH;
        default:
            return "";
    }
}

function getCounterShiftsLimitColumnDisplayName(counterType: CounterType, lang: ILang): string {
    switch (counterType) {
        case CounterType.Weekly:
        case CounterType.WeeklyByRollingWeek:
            return lang.weeklyShifts;
        case CounterType.Monthly:
            return lang.monthlyShifts;
        case CounterType.Yearly:
            return lang.yearlyShifts;
        default:
            return "";
    }
}

export function getLimitColumnDisplayName(counterUniqueId: string, lang: ILang): string {
    const splittedIndices = counterUniqueId.split("_").map(entry => parseInt(entry, 10));
    switch (splittedIndices[1]) {
        case CounterLimitType.WorkedMinutes:
            return getCounterWorkedMinutesLimitColumnDisplayName(splittedIndices[0], lang);
        case CounterLimitType.Shifts:
            return getCounterShiftsLimitColumnDisplayName(splittedIndices[0], lang);
        default:
            return "";
    }
}
