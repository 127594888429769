enum InstitutionInfoActionTypes {
    FETCH_INSTITUTIONINFOS = "FETCH_INSTITUTIONINFOS",
    SET_INSTITUTIONINFOS = "SET_INSTITUTIONINFOS",
    FETCH_INSTITUTIONINFOS_COUNT = "FETCH_INSTITUTIONINFOS_COUNT",
    SET_SELECTED_INSTITUTIONINFO = "SET_SELECTED_INSTITUTIONINFO",
    SET_SELECTED_INSTITUTIONINFO_MULTI = "SET_SELECTED_INSTITUTIONINFO_MULTI",
    SET_IS_LOADING_INSTITUTIONINFO = "SET_IS_LOADING_INSTITUTIONINFO",
    SET_INSTITUTIONINFO_ORDER = "SET_INSTITUTIONINFO_ORDER",
    INSERT_INSTITUTIONINFO = "INSERT_INSTITUTIONINFO",
    UPDATE_INSTITUTIONINFO = "UPDATE_INSTITUTIONINFO",
    DELETE_INSTITUTIONINFO = "DELETE_INSTITUTIONINFO",
    SET_INSTITUTIONINFO_ERROR_CODE = "SET_INSTITUTIONINFO_ERROR_CODE"
}
export default InstitutionInfoActionTypes;
