import { IReduxAction } from "../store.type";
import InstitutionOptionWorkerTypes from "../type/institutionOptionWorker.type";
import {
    fetchInstitutionOptionWorkers,
    fetchInstitutionOptionWorkersCount,
    insertInstitutionOptionWorker,
    updateInstitutionOptionWorker,
    deleteInstitutionOptionWorker
} from "../../service/institutionOptionWorker.service";
import { IInstitutionOptionWorker, INormalizedInstitutionOptionWorkers } from "../../../Model/InstitutionOptionWorker";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionOptionWorkersAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionOptionWorkers = await fetchInstitutionOptionWorkers(query);
            dispatch({
                type: InstitutionOptionWorkerTypes.FETCH_INSTITUTIONOPTIONWORKERS,
                payload: {
                    byId: institutionOptionWorkers.reduce(
                        (
                            p: INormalizedInstitutionOptionWorkers,
                            c: IInstitutionOptionWorker
                        ): INormalizedInstitutionOptionWorkers => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionOptionWorkers.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionOptionWorkersCountAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionOptionWorkersCount(query);
            dispatch({
                type: InstitutionOptionWorkerTypes.FETCH_INSTITUTIONOPTIONWORKERS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOptionWorkerAction(
    institutionOptionWorker: IInstitutionOptionWorker
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOptionWorker = await insertInstitutionOptionWorker(institutionOptionWorker);
            dispatch({
                type: InstitutionOptionWorkerTypes.INSERT_INSTITUTIONOPTIONWORKER,
                payload: newInstitutionOptionWorker
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOptionWorkerAction(
    institution: number,
    institutionOptionWorker: IInstitutionOptionWorker
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionOptionWorker = await updateInstitutionOptionWorker(
                institution,
                institutionOptionWorker
            );
            dispatch({
                type: InstitutionOptionWorkerTypes.UPDATE_INSTITUTIONOPTIONWORKER,
                payload: updatedInstitutionOptionWorker
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOptionWorkerAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOptionWorker(id);
            dispatch({
                type: InstitutionOptionWorkerTypes.DELETE_INSTITUTIONOPTIONWORKER
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOptionWorkersAction(
    dict: INormalizedInstitutionOptionWorkers
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_INSTITUTIONOPTIONWORKERS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOptionWorkerAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_SELECTED_INSTITUTIONOPTIONWORKER,
            payload: id
        });
    };
}

export function setSelectedInstitutionOptionWorkerMultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_SELECTED_INSTITUTIONOPTIONWORKER_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionOptionWorkerAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_IS_LOADING_INSTITUTIONOPTIONWORKER,
            payload: isLoading
        });
    };
}
export function setInstitutionOptionWorkerOrderAction(
    idsSorted: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_INSTITUTIONOPTIONWORKER_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOptionWorkerErrorCodeAction(
    errorCode: number
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionWorkerTypes.SET_INSTITUTIONOPTIONWORKER_ERROR_CODE,
            payload: errorCode
        });
    };
}
