import { connect } from "react-redux";
import { IQueryParams } from "npm-medgo-query";
import { IInfoOnWorker } from "../../../Model/InfoOnWorker";
import {
    fetchAllInfoOnWorkerAction,
    insertInstitutionHasInfoOnWorkerAction
} from "../../store/action/infoOnWorker.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

interface IReduxPropsFormInfoOnWorker extends IReduxPropsForm<IInfoOnWorker> {
    idAllInfoOnWorkers: number[];
}

interface IPropsFormInfoOnWorker extends IReduxPropsFormInfoOnWorker, IReduxActionsForm, IPropsForm<IInfoOnWorker> {}

interface IReduxActionsForm {
    insertInstitutionHasInfoOnWorkerAction: (idInfoOnWorker: IInfoOnWorker, idInstitution: number) => void;
    fetchAllInfoOnWorkerAction: (param: IQueryParams) => void;
}

class FormInfoOnWorkerComponent extends FormComponent<IInfoOnWorker, IPropsFormInfoOnWorker> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllInfoOnWorkers.length === 0) await this.props.fetchAllInfoOnWorkerAction({ limit: 99999 });
    }
    async createElementAction(): Promise<void> {
        const formDictionaryInfoOnWorkers = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.infoOnWorker
        );
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionaryInfoOnWorkers);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryInfoOnWorker = formDictionaryInfoOnWorkers[key];
                    const idInfoOnWorker = formDictionaryInfoOnWorker.name.value;
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasInfoOnWorkerAction(idInfoOnWorker, idInstitution);
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsFormInfoOnWorker =>
        Object.assign(getConnectParentState(centralState), {
            selectOptions: {
                [EBackOfficeColumnName.name]: getElementsForSelectComponent(
                    centralState.infoOnWorker,
                    centralState.institutions.selectedMulti
                )
            },
            idAllInfoOnWorkers: centralState.infoOnWorker.allIds
        }),
    Object.assign(getConnectParentActions(), {
        insertInstitutionHasInfoOnWorkerAction,
        fetchAllInfoOnWorkerAction
    })
)(FormInfoOnWorkerComponent);
