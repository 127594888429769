import { ColoredSelectComponent } from "@hublo/legacy-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { setConfirmationModalDataAction, setShowConfirmationModalAction } from "../../store/action/form.action";
import { IBackOfficeTable } from "../../constants/Table.constants";
import {
    formDictionarySelector,
    institutionsSelector,
    institutionHasAdminsSelector,
    langSelector
} from "../../store/selectors";
import { IInstitutionHasAdmin, INormalizedInstitutionHasAdmins } from "../../../Model/InstitutionHasAdmin";
import { ISelectLabelValue } from "npm-medgo-components";
import useConfirmationModalDatas from "../../hooks/customInputsAdminV2.hook";
import { firstLetterUppercase } from "npm-medgo-toolbox/dist/modules/string";
import { DateTime } from "luxon";
import { isNullOrUndefined } from "npm-hublo-toolbox/dist/modules/equality";
import { getIdLanguageFromLangString } from "npm-hublo-toolbox/dist/modules/lang";

interface IPropsCustomInputsAdminV2 {
    backOfficeTable: IBackOfficeTable;
}

const CustomInputsAdminV2: React.FC<IPropsCustomInputsAdminV2> = ({ backOfficeTable }) => {
    const dispatch = useDispatch();
    const [lang, strLang] = useSelector(langSelector);
    const formDictionary = useSelector(formDictionarySelector);
    const institutionHasAdminsById: INormalizedInstitutionHasAdmins = useSelector(institutionHasAdminsSelector).byId;
    const institutionHasAdminsIds: number[] = useSelector(institutionHasAdminsSelector).ids;
    const idInstitution: number[] = useSelector(institutionsSelector).selectedMulti;
    const admin = getFormDictionaryRecordsDict(formDictionary, backOfficeTable.name[0]);
    const institutionHasAdmins: IInstitutionHasAdmin[] = getModelsFromDict(
        institutionHasAdminsById,
        institutionHasAdminsIds
    );
    const institutionHasAdmin = institutionHasAdmins.find(
        (institutionHasAdmin: any): any =>
            institutionHasAdmin.admin === admin[0]?.id && idInstitution.includes(institutionHasAdmin.institution)
    );
    const isActivated = admin[0] ? admin[0][EBackOfficeColumnName.status] : false;
    const deactivatedAt =
        admin[0] && admin[0][EBackOfficeColumnName.deactivatedAt]
            ? admin[0][EBackOfficeColumnName.deactivatedAt]
            : null;
    const { subtitle, text, onClick1, onClick2, textButton1, textButton2, colorButton2 } = useConfirmationModalDatas(
        isActivated,
        //@ts-ignore
        institutionHasAdmin,
        getIdLanguageFromLangString(admin[0].lang?.value || "fr")
    );
    const coloredSelectLabelValues = [
        { value: "true", label: firstLetterUppercase(lang.active), color: "#36D1A2" },
        { value: "false", label: firstLetterUppercase(lang.inactive), color: "#6C7C8C" }
    ];
    return (
        <div className="custom-inputs">
            <div className="custom-category-columns-name">{firstLetterUppercase(lang.status)}</div>
            <div className="custom-inputs-is-activated">
                <ColoredSelectComponent
                    options={coloredSelectLabelValues}
                    onChange={(value: ISelectLabelValue) => {
                        if ((value.value === "true") !== isActivated) {
                            dispatch(
                                setConfirmationModalDataAction(
                                    subtitle,
                                    text,
                                    onClick1,
                                    onClick2,
                                    textButton1,
                                    textButton2,
                                    "",
                                    false,
                                    () => {},
                                    colorButton2
                                )
                            );
                            dispatch(setShowConfirmationModalAction(true));
                        }
                    }}
                    value={isActivated ? [coloredSelectLabelValues[0]] : [coloredSelectLabelValues[1]]}
                    placeholder={""}
                    isDisabled={false}
                />
            </div>
            {!isActivated && !isNullOrUndefined(deactivatedAt) && (
                <div className="custom-deactivatedAt">{`${lang.since} ${DateTime.fromSeconds(deactivatedAt)
                    .setLocale(strLang)
                    .toLocaleString(DateTime.DATE_SHORT)}`}</div>
            )}
        </div>
    );
};

export default CustomInputsAdminV2;
