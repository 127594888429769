export function isProductionEnvironment(): boolean {
    return process.env.NODE_ENV === "production";
}

export function isDevelopmentEnvironment(): boolean {
    return process.env.NODE_ENV === "development";
}

export function isReactProductionEnviroment(): boolean {
    return window.location.hostname.indexOf("hublo.com") !== -1;
}

export function isReactDevelopmentEnvironment(): boolean {
    return window.location.hostname.indexOf("hubpreprod.com") !== -1;
}
