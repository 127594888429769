import { INotificationContract, ICreateNotificationContractRequest } from "../../Model/NotificationContract";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const NotificationContractDefaultSelect = ["id", "institution", "recapType", "to", "cc", "bcc"];

export async function fetchNotificationContracts(query: IQueryParams): Promise<INotificationContract[]> {
    const notificationContracts = await postFetchFactory<INotificationContract>(
        PossibleFetchs.notificationContract,
        PossibleApis.medgoApi
    )(
        {
            select: NotificationContractDefaultSelect
        },
        query
    );
    return notificationContracts;
}

export async function fetchInstitutionHasNotificationContracts(query: IQueryParams): Promise<INotificationContract[]> {
    const institutionHasNotificationContracts = await postFetchFactory<INotificationContract>(
        PossibleFetchs.institutionHasNotificationContract,
        PossibleApis.medgoApi
    )(
        {
            select: ["id", "institution", "notificationContract"]
        },
        query
    );
    return institutionHasNotificationContracts;
}

export async function insertNotificationContract(
    notificationContract: INotificationContract
): Promise<INotificationContract> {
    return await postFactory<INotificationContract>(
        PossiblePosts.notificationContract,
        PossibleApis.medgoApi
    )(notificationContract);
}

export async function insertInstitutionHasNotificationContract(
    notificationContract: number,
    idInstitution: number
): Promise<ICreateNotificationContractRequest> {
    const data = {
        institution: idInstitution,
        notificationContract: notificationContract
    };
    const institutionHasNotificationContract = await postFactory<ICreateNotificationContractRequest>(
        PossiblePosts.institutionHasNotificationContract,
        PossibleApis.medgoApi
    )(data);
    return institutionHasNotificationContract;
}

export async function updateNotificationContract(
    id: number,
    notificationContract: INotificationContract
): Promise<INotificationContract> {
    return await updateFactory<INotificationContract>(PossibleUpdates.notificationContract, PossibleApis.medgoApi)(
        id,
        notificationContract
    );
}

export async function deleteNotificationContract(id: number): Promise<INotificationContract> {
    return await deleteFactory<INotificationContract>(PossibleDeletes.notificationContract, PossibleApis.medgoApi)(id);
}

export async function deleteInstitutionHasNotificationContract(id: number): Promise<INotificationContract> {
    return await deleteFactory<INotificationContract>(
        PossibleDeletes.institutionHasNotificationContract,
        PossibleApis.medgoApi
    )(id);
}
