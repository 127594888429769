import React, { Component } from "react";
import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import { EBackOfficeTableName, IBackOfficeTable } from "../../constants/Table.constants";
import "../../scss/components/table/Table.scss";
import { downloadCSV, getColNamesStringForExport, getTranslatedColNamesTableCSV } from "../../modules/csv/export";
import {
    setShowCreateOrModifyModalAction,
    setFormKeyValueAction,
    setFormTableAction
} from "../../store/action/form.action";
import { HubloInputTextIcon } from "npm-medgo-components";
import { EBackOfficeColumnName, EBackOfficeColumnType, IBackOfficeColumn } from "../../constants/Column.constants";
import { INormalizedInstitutionGroups } from "../../../Model/InstitutionGroup";
import { getModelsFromDict } from "../../modules/store/util";
import { getFormColumns } from "../../../toolbox/column/ColumnsFunction";

interface IReduxActionsTableSubHeaderView {
    setShowCreateOrModifyModalAction: (show: boolean) => void;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    setFormTableAction: (backofficeTableName: EBackOfficeTableName) => void;
}

interface IReduxPropsTableSubHeaderView {
    currentLang: string;
    lang: any;
    me: IAdmin;
    selectedTab: number;
    institutionGroupDict: INormalizedInstitutionGroups;
    idInstitutionGroups: number[];
    selectedInstitutions: number[];
}

interface IPropsTableSubHeaderView extends IReduxPropsTableSubHeaderView, IReduxActionsTableSubHeaderView {
    backOfficeTable: IBackOfficeTable;
    elements: any[];
    onSearch: (value: string | null) => void;
    isLoading: boolean;
}

class TableSubHeaderView extends Component<IPropsTableSubHeaderView> {
    render(): JSX.Element {
        const elements = this.props.elements;
        const canAddNewLine: boolean =
            this.props.me.level === 1 &&
            (this.props.backOfficeTable.canAddNewLine ||
                (elements.length === 0 && this.props.backOfficeTable.canAddOnlyOneLine));
        return (
            <div className="col-12 is-paddingless" style={{ marginBottom: "38px" }}>
                <div className="row is-marginless">
                    {" "}
                    <button
                        className="hidden-export-btn"
                        onClick={(): void =>
                            downloadCSV(
                                elements,
                                getColNamesStringForExport(
                                    this.props.backOfficeTable,
                                    this.props.backOfficeTable.columns[this.props.selectedTab]
                                        ? this.props.backOfficeTable.columns[this.props.selectedTab]
                                        : []
                                ),
                                getTranslatedColNamesTableCSV(this.props.backOfficeTable),
                                this.props.backOfficeTable.name[this.props.selectedTab]
                            )
                        }
                        id="hidden-export-btn"
                    ></button>
                    {this.props.backOfficeTable.canSearch ? (
                        <div
                            className={`col-${this.props.backOfficeTable.canAddNewLine ? 9 : 12} is-paddingless`}
                            style={{ marginLeft: "-10px" }}
                        >
                            <HubloInputTextIcon
                                id="searchBar"
                                placeholder={
                                    this.props.lang[
                                        `searchPlaceholder_${this.props.backOfficeTable.name[this.props.selectedTab]}`
                                    ]
                                }
                                onChange={this.props.onSearch}
                                classNameIcon="fas fa-search"
                            />
                        </div>
                    ) : null}
                    {canAddNewLine ? (
                        <div
                            className={`col-${
                                this.props.backOfficeTable.canSearch ? 3 : 12
                            } is-paddingless is-vertical-centered`}
                        >
                            <button
                                className="button"
                                onClick={(): void => {
                                    this.props.setFormTableAction(this.props.backOfficeTable.name[0]);
                                    this.putDefaultValuesInForm();
                                    this.props.setShowCreateOrModifyModalAction(true);
                                }}
                            >
                                {this.props.isLoading ? (
                                    <span className="fas fa-spinner fa-lg fa-spin" />
                                ) : (
                                    <div className="is-display-inline-block is-position-relative">
                                        {
                                            this.props.lang[
                                                `add_${this.props.backOfficeTable.name[this.props.selectedTab]}`
                                            ]
                                        }
                                    </div>
                                )}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    putDefaultValuesInForm() {
        const columns = getFormColumns(this.props.backOfficeTable, this.props.selectedTab);
        columns.forEach((column: IBackOfficeColumn): void => {
            if (column.min) {
                this.props.setFormKeyValueAction(
                    column.name,
                    column.min,
                    this.props.backOfficeTable.name[this.props.selectedTab],
                    column.type,
                    0
                );
            }
            const institutionGroups = getModelsFromDict(
                this.props.institutionGroupDict,
                this.props.idInstitutionGroups
            );
            const institution = this.props.selectedInstitutions[0];
            if (
                column.name === EBackOfficeColumnName.assignmentInstitutions &&
                institutionGroups.filter(
                    institutionGroup =>
                        (institutionGroup.institution.includes(institution) && institutionGroup.type === 1) ||
                        institutionGroup.type === 6
                ).length === 0
            ) {
                this.props.setFormKeyValueAction(
                    column.name,
                    [institution],
                    this.props.backOfficeTable.name[this.props.selectedTab],
                    column.type,
                    0
                );
            }
        });
    }
}

export default connect(
    (centralState: any): IReduxPropsTableSubHeaderView => ({
        lang: centralState.language.lang,
        me: centralState.auth.user,
        currentLang: centralState.language.currentLang,
        selectedTab: centralState.tables.selectedTab,
        institutionGroupDict: centralState.institutionGroups.byId,
        idInstitutionGroups: centralState.institutionGroups.ids,
        selectedInstitutions: centralState.institutions.selectedMulti
    }),
    {
        setShowCreateOrModifyModalAction,
        setFormKeyValueAction,
        setFormTableAction
    }
)(TableSubHeaderView);
