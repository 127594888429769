import { connect } from "react-redux";
import {
    insertServiceAction,
    updateServiceAction,
    insertInstitutionHasServiceAction
} from "../../store/action/service.action";
import {
    FormComponent,
    IPropsForm,
    getConnectParentState,
    getConnectParentActions,
    IReduxPropsForm
} from "./Form.container";
import { IService } from "../../../Model/Service";
import { IFormDictionary } from "../../store/type/form.type";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { fetchPolesAction } from "../../store/action/pole.action";
import { IQueryParams } from "npm-medgo-query";
import { IPole } from "../../../Model/Pole";
import { ISelectLabelValue } from "npm-medgo-components";
import { selectParkingOptions } from "../../constants/SelectOptions.constants";

interface IReduxPropsFormService extends IReduxPropsForm<IService> {
    idPoles: number[];
}
interface IPropsFormService extends IReduxPropsFormService, IReduxActionsForm, IPropsForm<IService> {}

interface IReduxActionsForm {
    insertServiceAction: (formDictionary: IFormDictionary, poleName: string) => IService;
    insertInstitutionHasServiceAction: (idService: number, idInstitution: number) => void;
    fetchPolesAction: (param: IQueryParams) => void;
    updateServiceAction: (id: number, service: IService, poleName: string) => void;
}

class FormServiceComponent extends FormComponent<IService, IPropsFormService> {
    async componentDidMount(): Promise<void> {
        if (this.props.idPoles.length === 0)
            await this.props.fetchPolesAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
    }

    async createElementAction(): Promise<void> {
        let formDictionaryServices = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.service
        );
        Object.keys(formDictionaryServices).forEach((key: string): any => {
            formDictionaryServices[key] = Object.assign(formDictionaryServices[key], {
                code: formDictionaryServices[key].codeUF
            });
        });
        const selectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionaryServices);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    let formDictionaryService = formDictionaryServices[key];
                    const poleName = formDictionaryService.pole ? formDictionaryService.pole.label : "";
                    if (formDictionaryService.pole) {
                        formDictionaryService = Object.assign(formDictionaryService, {
                            pole: formDictionaryService.pole.value
                        });
                    }
                    if (formDictionaryService.parking) {
                        formDictionaryService = Object.assign(formDictionaryService, {
                            parking: formDictionaryService.parking.value
                        });
                    }
                    const { id: idService } = await this.props.insertServiceAction(formDictionaryService, poleName);
                    await Promise.all(
                        selectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasServiceAction(idService, idInstitution);
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        let formDictionaryService = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.service
        )[0];
        formDictionaryService = Object.assign(formDictionaryService, { code: formDictionaryService.codeUF });
        const poleName = formDictionaryService.pole ? formDictionaryService.pole.label : "";
        if (formDictionaryService.pole)
            formDictionaryService = Object.assign(formDictionaryService, { pole: formDictionaryService.pole.value });
        if (formDictionaryService.parking) {
            formDictionaryService = Object.assign(formDictionaryService, {
                parking: formDictionaryService.parking.value
            });
        }
        await this.props.updateServiceAction(formDictionaryService.id, formDictionaryService as IService, poleName);
    }
}

export default connect(
    (centralState: any): IPropsFormService =>
        Object.assign(getConnectParentState(centralState), {
            selectOptions: {
                [EBackOfficeColumnName.parking]: selectParkingOptions.parking,
                [EBackOfficeColumnName.pole]: getModelsFromDict<IPole>(centralState.poles.byId, centralState.poles.ids)
                    .filter((e): boolean =>
                        e.institution
                            ? (centralState.institutions.selectedMulti &&
                              centralState.institutions.selectedMulti.length > 0
                                  ? centralState.institutions.selectedMulti
                                  : centralState.institutions.ids
                              ).every((id: number): boolean => e.institution.includes(id))
                            : false
                    )
                    .map((e: IPole): ISelectLabelValue => ({ label: e.name, value: e.id }))
            },
            idPoles: centralState.poles.ids
        }),
    Object.assign(getConnectParentActions(), {
        insertServiceAction,
        insertInstitutionHasServiceAction,
        fetchPolesAction,
        updateServiceAction
    })
)(FormServiceComponent);
