import { connect } from "react-redux";
import { IAdmin, INormalizedAdmins } from "../../../Model/Admin";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import {
    insertOrUpdateAdminInfo,
    fetchAdminInInstitutionNotificationService,
    insertAdminInInstitutionNotificationService,
    deleteAdminInInstitutionNotificationService,
    fetchAdmins
} from "../../service/admin.service";
import { fetchInstitutionHasAdmins } from "../../service/institutionHasAdmin.service";
import { setShowConfirmationModalAction, setConfirmationModalDataAction } from "../../store/action/form.action";
import { insertAdminAction, updateAdminAction } from "../../store/action/admin.action";
import { insertInstitutionHasAdminAction } from "../../store/action/institutionHasAdmin.action";
import { setFormDictionaryAction, setResetFormAction } from "../../store/action/form.action";
import {
    getFormDictionaryRecordsDict,
    getModelsFromDict,
    isKeyElementAlreadyInFormOrInstitutions
} from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { fetchServicesAction } from "../../store/action/service.action";
import { IQueryParams } from "npm-medgo-query";
import { IFormDictionary } from "../../store/type/form.type";
import { EBackOfficeColumnName, IBackOfficeColumn } from "../../constants/Column.constants";
import { INormalizedServices, IService } from "../../../Model/Service";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { LangToolbox } from "npm-hublo-toolbox";

interface IReduxPropsFormAdmin extends IReduxPropsForm<IAdmin> {
    servicesById: INormalizedServices;
    idServices: number[];
    idInstitutions: number[];
    selectedMulti: number[];
    adminDict: INormalizedAdmins;
    idAdmins: number[];
}

interface IPropsFormAdmin extends IReduxPropsFormAdmin, IReduxActionsForm, IPropsForm<IAdmin> {}

interface IReduxActionsForm {
    insertAdminAction: (formDictionary: IFormDictionary) => IAdmin;
    fetchServicesAction: (param: IQueryParams) => void;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    insertInstitutionHasAdminAction: (institutionHasAdmin: any) => void;
    updateAdminAction: (id: number, admin: IAdmin) => void;
    setShowConfirmationModalAction: (showConfirmation: boolean) => void;
    setConfirmationModalDataAction: (
        subtitle: string,
        text: string,
        onClick1: () => void,
        onClick2: () => void,
        textButton1: string,
        textButton2: string
    ) => void;
    setFormDictionaryAction: (selectedOption: { [key: string]: any }) => void;
    setResetFormAction: () => void;
}

class FormAdminComponent extends FormComponent<IAdmin, IPropsFormAdmin> {
    async componentDidMount(): Promise<void> {
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
    }

    async createElementAction(): Promise<void> {
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formDictionaryAdmins = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.admin
        );
        const formDictionaryTemp = JSON.parse(JSON.stringify(this.props.formDictionary));
        const formKeys = Object.keys(formDictionaryAdmins);
        const foundAdmins = await fetchAdmins({
            query: {
                email: Object.values(formDictionaryAdmins).map(e => e.email)
            }
        });
        const shouldAskUserValidation = (
            await Promise.all(
                formKeys.map(async (key: any) => {
                    const formDictionaryAdmin = formDictionaryAdmins[key];
                    // @ts-ignore
                    const idLanguage = LangToolbox.getIdLanguageFromLangString(this.props.currentLang);
                    formDictionaryAdmin.idLanguage = idLanguage;
                    formDictionaryAdmin.idInstitution = idSelectedInstitutions[0];
                    const foundAdmin = foundAdmins.find(e => e.email === formDictionaryAdmin.email);
                    if (!foundAdmin) {
                        return false;
                    }
                    const foundInstitutionHasAdmin = await fetchInstitutionHasAdmins({
                        query: {
                            admin: foundAdmin.id,
                            institution: idSelectedInstitutions
                        }
                    });
                    return foundInstitutionHasAdmin.length === 0;
                })
            )
        ).some(e => e === true);
        if (shouldAskUserValidation) {
            this.props.setShowConfirmationModalAction(true);
            this.props.setShowCreateOrModifyModalAction(false);
            await new Promise(resolve =>
                this.props.setConfirmationModalDataAction(
                    this.props.lang[`confirmationSubtitle_admin_insert`]
                        ? this.props.lang[`confirmationSubtitle_admin_insert`]
                        : this.props.lang.areYouSure,
                    (this.props.lang[`confirmationText_admin_insert`] &&
                        this.props.lang[`confirmationText_admin_insert`]) ||
                        "",
                    (): void => {
                        this.props.setFormStatusAction("backToModal");
                        this.props.setFormDictionaryAction(formDictionaryTemp);
                        resolve();
                    },
                    async (): Promise<void> => {
                        await this.createAllAdmins(formDictionaryAdmins, idSelectedInstitutions);
                        this.props.setShowConfirmationModalAction(false);
                        this.props.setResetFormAction();
                        resolve();
                    },
                    this.props.lang.noDontApply,
                    this.props.lang.yesApply
                )
            );
        } else {
            await this.createAllAdmins(formDictionaryAdmins, idSelectedInstitutions);
        }
    }

    async createAllAdmins(formDictionaryAdmins: { [key: string]: any }, idSelectedInstitutions: number[]) {
        const formKeys = Object.keys(formDictionaryAdmins);
        const promises = formKeys.map(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryAdmin = formDictionaryAdmins[key];
                    // @ts-ignore
                    const idLanguage = LangToolbox.getIdLanguageFromLangString(this.props.currentLang);
                    formDictionaryAdmin.idLanguage = idLanguage;
                    formDictionaryAdmin.idInstitution = idSelectedInstitutions[0];
                    formDictionaryAdmin.email = formDictionaryAdmin.email.trim();
                    const { id: idAdmin } = await this.props.insertAdminAction(formDictionaryAdmin);
                    await insertOrUpdateAdminInfo(idAdmin, idLanguage);
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasAdminAction({
                                    admin: idAdmin,
                                    institution: idInstitution,
                                    matricule: "",
                                    position: "",
                                    isActivated: true
                                });
                                if (formDictionaryAdmin.service) {
                                    await Promise.all(
                                        formDictionaryAdmin.service.map(
                                            async (service: number): Promise<void> => {
                                                await insertAdminInInstitutionNotificationService(
                                                    idAdmin,
                                                    idInstitution,
                                                    service
                                                );
                                            }
                                        )
                                    );
                                }
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
        await Promise.all(promises);
    }

    async updateElementAction(): Promise<void> {
        const admin = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.admin)[0];
        await this.props.updateAdminAction(admin.id, admin as IAdmin);
        let servicesBeforeUpdate = this.props.modifyingElement.service.filter(
            (service: number): boolean => service !== null
        );
        let servicesAfterUpdate = admin.service.filter((service: number): boolean => service !== null);
        const servicesToBeDeleted = servicesBeforeUpdate.filter(
            (service: number): boolean => servicesAfterUpdate.indexOf(service) === -1
        );
        const servicesToBeAdded = servicesAfterUpdate.filter(
            (service: number): boolean => servicesBeforeUpdate.indexOf(service) === -1
        );
        for (let i in servicesToBeDeleted) {
            const idServiceToBeDeleted = servicesToBeDeleted[i];
            const adminInInstitutionNotificationService = await fetchAdminInInstitutionNotificationService({
                query: { service: idServiceToBeDeleted, admin: admin.id }
            });
            deleteAdminInInstitutionNotificationService(adminInInstitutionNotificationService[0].id);
        }
        if (servicesToBeAdded.length === 0) {
            return;
        }
        for (let i in servicesToBeAdded) {
            await insertAdminInInstitutionNotificationService(
                admin.id,
                this.props.idInstitutions[0],
                servicesToBeAdded[i]
            );
        }
    }

    customCheck(value: string, column: IBackOfficeColumn): void {
        const formDictionaryTable = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.admin);
        if (
            column.name === EBackOfficeColumnName.email &&
            value &&
            typeof value === "string" &&
            value.trim() !== "" &&
            isKeyElementAlreadyInFormOrInstitutions({
                dict: this.props.adminDict,
                ids: this.props.idAdmins,
                selectedMultis: this.props.selectedMulti,
                formDictionaryTable,
                formDictionaryKey: value,
                key: EBackOfficeColumnName.email,
                modifyingElement: this.props.modifyingElement
            })
        ) {
            throw { columnName: EBackOfficeColumnName.email, errorCode: 449 };
        }
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.admin]: {
                [EBackOfficeColumnName.service]: getElementsInInstitution(
                    getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                    this.props.selectedMulti
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsFormAdmin =>
        Object.assign(getConnectParentState(centralState), {
            servicesById: centralState.services.byId,
            selectedMulti: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids,
            idServices: centralState.services.ids,
            modifyingElement: centralState.tables.modifyingElement,
            idAdmins: centralState.admins.ids,
            adminDict: centralState.admins.byId
        }),
    Object.assign(getConnectParentActions(), {
        insertAdminAction,
        fetchServicesAction,
        insertInstitutionHasAdminAction,
        updateAdminAction,
        setShowConfirmationModalAction,
        setConfirmationModalDataAction,
        setFormDictionaryAction,
        setResetFormAction
    })
)(FormAdminComponent);
