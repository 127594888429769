import { IBackOfficeColumn, IFormCategoryColumns } from "../../src/constants/Column.constants";
import { IBackOfficeTable } from "../../src/constants/Table.constants";

export function getFormColumns(backOfficeTable: IBackOfficeTable, selectedTab: number): IBackOfficeColumn[] {
    if (backOfficeTable.formCategoriesColumns) {
        const columns: IBackOfficeColumn[] = [];
        backOfficeTable.formCategoriesColumns[selectedTab].forEach((categoryColumn: IFormCategoryColumns) =>
            categoryColumn.columns.forEach((column: IBackOfficeColumn) => columns.push(column))
        );
        return columns;
    }
    return backOfficeTable.formColumns
        ? backOfficeTable.formColumns[selectedTab]
        : backOfficeTable.columns[selectedTab];
}
