import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionOptionChurnTypes from "../type/institutionOptionChurn.type";
import { IInstitutionOptionChurn } from "../../../Model/InstitutionOptionChurn";

const initialState: IReduxInitialState<IInstitutionOptionChurn> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionOptionChurnReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOptionChurnTypes.FETCH_INSTITUTIONOPTIONCHURNS:
        case InstitutionOptionChurnTypes.SET_INSTITUTIONOPTIONCHURNS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] };

        case InstitutionOptionChurnTypes.FETCH_INSTITUTIONOPTIONCHURNS_COUNT:
            return { ...state, count: action.payload };

        case InstitutionOptionChurnTypes.SET_SELECTED_INSTITUTIONOPTIONCHURN:
            return { ...state, selectedId: action.payload };

        case InstitutionOptionChurnTypes.SET_SELECTED_INSTITUTIONOPTIONCHURN_MULTI:
            return { ...state, selectedMulti: action.payload };

        case InstitutionOptionChurnTypes.SET_IS_LOADING_INSTITUTIONOPTIONCHURN:
            return { ...state, isLoading: action.payload };

        case InstitutionOptionChurnTypes.SET_INSTITUTIONOPTIONCHURN_ORDER:
            return { ...state, ids: action.payload };

        case InstitutionOptionChurnTypes.INSERT_INSTITUTIONOPTIONCHURN:
        case InstitutionOptionChurnTypes.UPDATE_INSTITUTIONOPTIONCHURN: {
            const byId = { ...state.byId };
            const ids = [...state.ids];
            if (byId[action.payload.id]) {
                byId[action.payload.id] = Object.assign({}, byId[action.payload.id], action.payload);
            } else {
                byId[action.payload.id] = Object.assign({}, action.payload);
            }
            if (ids.indexOf(action.payload.id) === -1) {
                ids.push(action.payload.id);
            }
            return { ...state, byId: byId, ids: ids };
        }
        default:
            return state;
    }
}
