enum InstitutionGroupActionTypes {
    FETCH_INSTITUTIONGROUPS = "FETCH_INSTITUTIONGROUPS",
    SET_INSTITUTIONGROUPS = "SET_INSTITUTIONGROUPS",
    SET_SELECTED_INSTITUTIONGROUP = "SET_SELECTED_INSTITUTIONGROUP",
    SET_SELECTED_INSTITUTIONGROUP_MULTI = "SET_SELECTED_INSTITUTIONGROUP_MULTI",
    SET_IS_LOADING_INSTITUTIONGROUP = "SET_IS_LOADING_INSTITUTIONGROUP",
    SET_INSTITUTIONGROUP_ORDER = "SET_INSTITUTIONGROUP_ORDER",
    INSERT_INSTITUTIONGROUP = "INSERT_INSTITUTIONGROUP",
    UPDATE_INSTITUTIONGROUP = "UPDATE_INSTITUTIONGROUP",
    DELETE_INSTITUTIONGROUP = "DELETE_INSTITUTIONGROUP",
    FETCH_INSTITUTIONGROUPS_COUNT = "FETCH_INSTITUTIONGROUPS_COUNT"
}
export default InstitutionGroupActionTypes;
