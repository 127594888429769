import { IReduxAction } from "../store.type";
import InstitutionOptionTypes from "../type/institutionOption.type";
import {
    deleteInstitutionOption,
    fetchInstitutionOptions,
    fetchInstitutionOptionsCount,
    fetchInstitutionSlotConfig,
    fetchSlotConfigs,
    insertInstitutionOption,
    updateInstitutionOption,
    updateInstitutionSlotConfig
} from "../../service/institutionOption.service";
import { IInstitutionOption, INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { Equality } from "npm-hublo-toolbox";
import { isNil } from "lodash";
import { disableOptionAgency, enableOptionAgency, toggleHubloPoolOption } from "../../service/optionAgency.service";
import { IInstitutionSlotConfig } from "../../../Model/Slot";
import { getFeatureFlags } from "../../service/featureFlags.service";

export const defaultInstitutionOptions: IInstitutionOption = {
    institution: -1,
    isAddressRequired: false,
    isSocialSecurityNumberRequired: false,
    hasDPAE: false,
    isKorian: false,
    isHCL: false,
    hasContract: false,
    isMedgoDemo: false,
    hasInterim: false,
    hasUnspecifiedService: true,
    hasSMS: false,
    canPostMissionWithoutSchedule: true,
    canSeeTotalCostForInstitution: false,
    canValidateMission: false,
    eSignature: false,
    eSignatureProvider: "",
    HasMassEsignatureEtab: false,
    HasMultiReplacementContract: false,
    healthPass: false,
    isCvMandatory: false,
    isDiplomaMandatory: false,
    hasHubloPool: false,
    agencyClientId: null,
    timeLimitationToPostShiftInDays: 0,
    slotConfig: "",
    dealShift: "DISABLED",
    internalPool: false,
    internalPoolCalendarBlockDays: 0
};

function mergeInstitutionOptions(accumulator: any, currentValue: any) {
    return {
        isAddressRequired: accumulator.isAddressRequired || currentValue.isAddressRequired,
        isSocialSecurityNumberRequired:
            accumulator.isSocialSecurityNumberRequired || currentValue.isSocialSecurityNumberRequired,
        hasDPAE: accumulator.hasDPAE || currentValue.hasDPAE,
        isKorian: accumulator.isKorian || currentValue.isKorian,
        isHCL: accumulator.isHCL || currentValue.isHCL,
        hasContract: accumulator.hasContract || currentValue.hasContract,
        isMedgoDemo: accumulator.isMedgoDemo || currentValue.isMedgoDemo,
        hasInterim: accumulator.hasInterim || currentValue.hasInterim,
        hasUnspecifiedService: accumulator.hasUnspecifiedService || currentValue.hasUnspecifiedService,
        hasSMS: accumulator.hasSMS || currentValue.hasSMS,
        canPostMissionWithoutSchedule:
            accumulator.canPostMissionWithoutSchedule || currentValue.canPostMissionWithoutSchedule,
        canSeeTotalCostForInstitution:
            accumulator.canSeeTotalCostForInstitution || currentValue.canSeeTotalCostForInstitution,
        canValidateMission: accumulator.canValidateMission || currentValue.canValidateMission,
        eSignature: accumulator.eSignature || currentValue.eSignature,
        eSignatureProvider:
            currentValue.eSignatureProvider && currentValue.eSignatureProvider.length > 0
                ? currentValue.eSignatureProvider
                : accumulator.eSignatureProvider,
        HasMassEsignatureEtab: accumulator.HasMassEsignatureEtab || currentValue.HasMassEsignatureEtab,
        HasMultiReplacementContract:
            accumulator.HasMultiReplacementContract || currentValue.HasMultiReplacementContract,
        healthPass: accumulator.healthPass || currentValue.healthPass,
        isCvMandatory: accumulator.isCvMandatory || currentValue.isCvMandatory,
        isDiplomaMandatory: accumulator.isDiplomaMandatory || currentValue.isDiplomaMandatory,
        slotConfig: accumulator.slotConfig || currentValue.slotConfig,
        dealShift: accumulator.dealShift || currentValue.dealShift,
        internalPool: accumulator.internalPool || currentValue.internalPool,
        internalPoolCalendarBlockDays:
            accumulator.internalPoolCalendarBlockDays || currentValue.internalPoolCalendarBlockDays
    };
}

function getFusedInstitutionsOptions(institutionsOptions: any[]) {
    if (Equality.isNullOrUndefined(institutionsOptions) || institutionsOptions.length === 0) {
        return defaultInstitutionOptions;
    } else {
        return institutionsOptions.reduce(mergeInstitutionOptions);
    }
}

export function fetchInstitutionOptionsAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void, getState: () => any) => void {
    return async (dispatch: (x: IReduxAction) => void, getState: () => any): Promise<void> => {
        try {
            const {
                institutionOption: { slotConfigs: allSlotConfigs }
            } = getState();

            const institutionOptions = await fetchInstitutionOptions(query);
            const slotConfigPromises = query.query.institution.map(fetchInstitutionSlotConfig);
            const fetchedSlotConfigs = await Promise.all(slotConfigPromises);

            const updatedInstitutionOptions = institutionOptions.map((option, index) => {
                const slotConfigID = (fetchedSlotConfigs[index] as IInstitutionSlotConfig)?.idSlotConfig;
                const slotConfigLabelKey = allSlotConfigs.find((config: { id: string }) => config.id === slotConfigID)
                    ?.labelKey;

                return {
                    ...option,
                    slotConfig: slotConfigLabelKey ?? null
                };
            });

            dispatch({
                type: InstitutionOptionTypes.FETCH_INSTITUTIONOPTIONS,
                payload: {
                    byId: updatedInstitutionOptions.reduce(
                        (p: INormalizedInstitutionOptions, c: IInstitutionOption): INormalizedInstitutionOptions => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: updatedInstitutionOptions.map((e): number => e.institution),
                    fusedInstitutionsOptions: getFusedInstitutionsOptions(updatedInstitutionOptions)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionOptionsCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionOptionsCount(query);
            dispatch({
                type: InstitutionOptionTypes.FETCH_INSTITUTIONOPTIONS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOptionAction(
    institutionOption: IInstitutionOption
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOption = await insertInstitutionOption(institutionOption);
            dispatch({
                type: InstitutionOptionTypes.INSERT_INSTITUTIONOPTION,
                payload: newInstitutionOption
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOptionAction(
    institution: number,
    institutionOption: IInstitutionOption,
    hasHubloPoolOldValue?: boolean
): (dispatch: (x: IReduxAction) => void, getState: () => any) => void {
    return async (dispatch: (x: IReduxAction) => void, getState: () => any): Promise<void> => {
        try {
            const { agencyClientId, slotConfig: slotConfigId, ...otherInstitutionOptions } = institutionOption;
            const {
                institutionOption: { slotConfigs: allSlotConfigs }
            } = getState();

            const {
                flags: { useNewReadSlotSystem }
            } = await getFeatureFlags();

            const correspondingSlotConfig = allSlotConfigs.find((config: { id: string }) => config.id === slotConfigId);
            const shouldUpdateSlotConfig = !!correspondingSlotConfig; // if not found, it means it has not been selected
            if (useNewReadSlotSystem && shouldUpdateSlotConfig) {
                await updateInstitutionSlotConfig(institution, slotConfigId);
            }

            if (institutionOption.hasInterim && isNil(agencyClientId)) {
                await enableOptionAgency(institution);
            } else if (!institutionOption.hasInterim && !isNil(agencyClientId)) {
                await disableOptionAgency(institution);
            }
            if (hasHubloPoolOldValue !== institutionOption.hasHubloPool) await toggleHubloPoolOption(institution);
            const updatedInstitutionOption = await updateInstitutionOption(institution, otherInstitutionOptions);
            updatedInstitutionOption.hasHubloPool = institutionOption.hasHubloPool;
            updatedInstitutionOption.slotConfig = correspondingSlotConfig?.labelKey ?? null;

            dispatch({
                type: InstitutionOptionTypes.UPDATE_INSTITUTIONOPTION,
                payload: updatedInstitutionOption
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOptionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOption(id);
            dispatch({
                type: InstitutionOptionTypes.DELETE_INSTITUTIONOPTION
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOptionsAction(
    dict: INormalizedInstitutionOptions
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_INSTITUTIONOPTIONS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOptionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_SELECTED_INSTITUTIONOPTION,
            payload: id
        });
    };
}

export function setSelectedInstitutionOptionMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_SELECTED_INSTITUTIONOPTION_MULTI,
            payload: ids
        });
    };
}

export function setIsLoadingInstitutionOptionAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_INSTITUTIONOPTION,
            payload: isLoading
        });
    };
}

export function setInstitutionOptionOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_INSTITUTIONOPTION_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOptionErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_INSTITUTIONOPTION_ERROR_CODE,
            payload: errorCode
        });
    };
}

export function fetchAllSlotConfigsAction(): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const slotConfigs = await fetchSlotConfigs();

            dispatch({
                type: InstitutionOptionTypes.FETCH_SLOT_CONFIGS,
                payload: { slotConfigs }
            });
        } catch (err) {
            throw err;
        }
    };
}
