import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionHasAdminActionTypes from "../type/institutionHasAdmin.type";
import { IInstitutionHasAdmin } from "../../../Model/InstitutionHasAdmin";

const initialState: IReduxInitialState<IInstitutionHasAdmin> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionHasAdminReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionHasAdminActionTypes.FETCH_INSTITUTIONHASADMINS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InstitutionHasAdminActionTypes.INSERT_INSTITUTIONHASADMIN: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case InstitutionHasAdminActionTypes.UPDATE_INSTITUTIONHASADMIN: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case InstitutionHasAdminActionTypes.UPDATE_INSTITUTIONSHASADMIN: {
            let byId = {
                ...state.byId
            };
            action.payload.forEach((e: any) => {
                byId[e.id] = Object.assign({}, byId[e.id], e);
            });
            return { ...state, byId: byId };
        }

        default:
            return state;
    }
}
