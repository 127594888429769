//@ts-ignore
import * as clone from "clone";
import { IReduxAction, IReduxInitialState } from "../store.type";
import NotificationContractTypes from "../type/notificationContract.type";
import { INotificationContract } from "../../../Model/NotificationContract";

const initialState: IReduxInitialState<INotificationContract> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function notificationContractReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case NotificationContractTypes.FETCH_NOTIFICATIONCONTRACTS:
        case NotificationContractTypes.SET_NOTIFICATIONCONTRACTS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case NotificationContractTypes.SET_SELECTED_NOTIFICATIONCONTRACT: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case NotificationContractTypes.SET_SELECTED_NOTIFICATIONCONTRACT_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case NotificationContractTypes.SET_IS_LOADING_NOTIFICATIONCONTRACT: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case NotificationContractTypes.SET_NOTIFICATIONCONTRACT_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case NotificationContractTypes.INSERT_NOTIFICATIONCONTRACT: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case NotificationContractTypes.INSERT_INSTITUTION_HAS_NOTIFICATION_CONTRACT: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idNotificationContract]: {
                        ...state.byId[action.payload.idNotificationContract],
                        institution: [
                            ...(state.byId[action.payload.idNotificationContract].institution || []),
                            action.payload.idInstitution
                        ]
                    }
                }
            };
        }

        case NotificationContractTypes.UPDATE_NOTIFICATIONCONTRACT: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case NotificationContractTypes.DELETE_INSTITUTION_HAS_NOTIFICATIONCONTRACT: {
            let byId = clone(state.byId);
            let ids = clone(state.ids);
            delete byId[action.payload];
            ids.splice(state.ids.indexOf(action.payload), 1);
            return {
                ...state,
                byId,
                ids
            };
        }

        default:
            return state;
    }
}
