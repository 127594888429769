import { firstLetterUppercase } from "npm-hublo-toolbox/dist/modules/string";
import React from "react";
import { useSelector } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import { getModelsFromDict } from "../../modules/store/util";
import { adminByIdSelector, adminIdsSelector, langSelector } from "../../store/selectors/index";

const AdditionalInformationAdminV2: React.FC = () => {
    const [lang] = useSelector(langSelector);
    const adminByIds = useSelector(adminByIdSelector);
    const adminIds = useSelector(adminIdsSelector);
    const nbActiveAdmins = getModelsFromDict(adminByIds, adminIds).filter(
        (admin: IAdmin): any => admin.status && admin.level !== 1 && admin.typeAdmin !== 6
    ).length;
    return (
        <div className="additional-info">
            <div className="info">{lang.numberOfActiveAdministrators}</div>
            <div className="info">{`${firstLetterUppercase(lang.institution)} : ${nbActiveAdmins}`}</div>
        </div>
    );
};

export default AdditionalInformationAdminV2;
