import { connect } from "react-redux";
import { IPayrollcode, IDispatchedPayrollcode } from "../../../Model/Payrollcode";

import { updatePayrollcodeAction, insertPayrollcodeAction } from "../../store/action/payrollcode.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IFormDictionary } from "../../store/type/form.type";
import { stringHHmmToUtcMoment } from "../../../toolbox/date";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

//@ts-ignore
import * as clone from "clone";
import { ILang } from "../../lang/Lang";

interface IPropsFormPayrollcode extends IReduxActionsForm, IPropsForm<IDispatchedPayrollcode> {
    payrollcodeDict: IDispatchedPayrollcode[];
    idPayrollcodes: number[];
    selectedMulti: number[];
    itemToBeModified: IDispatchedPayrollcode;
    lang: ILang;
}

interface IReduxActionsForm {
    insertPayrollcodeAction: (formDictionary: IFormDictionary) => IDispatchedPayrollcode;
    updatePayrollcodeAction: (id: number, payrollcode: IPayrollcode) => void;
}

class FormPayrollCodeComponent extends FormComponent<IDispatchedPayrollcode, IPropsFormPayrollcode> {
    async createElementAction(): Promise<void> {
        const formDictionaryPayrollcodes = clone(
            getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.exportConfiguration)
        );
        const formKeys = Object.keys(formDictionaryPayrollcodes);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryPayrollcode = formDictionaryPayrollcodes[key];
                    formDictionaryPayrollcode.type = formDictionaryPayrollcode.type.value;
                    formDictionaryPayrollcode.beginAt = stringHHmmToUtcMoment(formDictionaryPayrollcode.beginAt).unix();
                    formDictionaryPayrollcode.endAt = stringHHmmToUtcMoment(formDictionaryPayrollcode.endAt).unix();
                    formDictionaryPayrollcode.isBusinessDay = formDictionaryPayrollcode.isBusinessDay
                        ? formDictionaryPayrollcode.isBusinessDay
                        : false;
                    formDictionaryPayrollcode.isNight = formDictionaryPayrollcode.isNight
                        ? formDictionaryPayrollcode.isNight
                        : false;
                    formDictionaryPayrollcode.isBeforeSundayOrPublicHoliday = formDictionaryPayrollcode.isBeforeSundayOrPublicHoliday
                        ? formDictionaryPayrollcode.isBeforeSundayOrPublicHoliday
                        : false;
                    let formDictionaryPayrollcodeWithInstitutions: any[] = [];
                    this.props.selectedMulti.forEach(
                        (selectedInstitution, index): void =>
                            (formDictionaryPayrollcodeWithInstitutions[index] = Object.assign(
                                {},
                                formDictionaryPayrollcode,
                                { institution: selectedInstitution }
                            ))
                    );
                    await Promise.all(
                        formDictionaryPayrollcodeWithInstitutions.map(
                            async (formDictionaryPayrollcodeWithInstitution): Promise<void> => {
                                await this.props.insertPayrollcodeAction(formDictionaryPayrollcodeWithInstitution);
                                return;
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        let payrollcode = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.exportConfiguration
        )[0];
        payrollcode = Object.assign(payrollcode, { id: this.props.itemToBeModified.id });
        payrollcode.type = payrollcode.type.value;
        payrollcode.beginAt = stringHHmmToUtcMoment(payrollcode.beginAt).unix();
        payrollcode.endAt = stringHHmmToUtcMoment(payrollcode.endAt).unix();
        await this.props.updatePayrollcodeAction(payrollcode.id, payrollcode as IPayrollcode);
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<IDispatchedPayrollcode> =>
        Object.assign(getConnectParentState(centralState), {
            payrollcodeDict: centralState.payrollCode.byId,
            idPayrollcodes: centralState.payrollCode.ids,
            selectedMulti: centralState.institutions.selectedMulti,
            itemToBeModified: centralState.tables.modifyingElement,
            lang: centralState.language.lang,
            selectOptions: {
                [EBackOfficeColumnName.type]: [
                    { label: "Salarié", value: 0 },
                    { label: "Vacataire", value: 1 }
                ]
            }
        }),
    Object.assign(getConnectParentActions(), {
        insertPayrollcodeAction,
        updatePayrollcodeAction
    })
)(FormPayrollCodeComponent);
