import React, { Component } from "react";
import { connect } from "react-redux";

import { IAdmin } from "../../../Model/Admin";
import BoxView from "./Box.view";
import { IBackOfficeColumn } from "../../constants/Column.constants";
import { IOptionsSelectCheckboxList } from "npm-medgo-components";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { ILang } from "../../lang/Lang";

interface IReduxPropsBox {
    me: IAdmin;
    lang: ILang;
    currentLang: string;
}
interface IPropsBox extends IReduxPropsBox {
    value: any;
    column: IBackOfficeColumn;
    table: EBackOfficeTableName;
    selectCheckboxListOptions?: IOptionsSelectCheckboxList[];
    hasError?: boolean;
}

class Box extends Component<IPropsBox> {
    render(): JSX.Element {
        return (
            <BoxView
                value={this.props.value}
                column={this.props.column}
                table={this.props.table}
                selectCheckboxListOptions={this.props.selectCheckboxListOptions}
                hasError={this.props.hasError}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsBox => ({
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user
    }),
    {}
)(Box);
