import { IReduxAction } from "../store.type";
import CounterTypes from "../type/counter.type";
import {
    fetchCounters,
    fetchCountersCount,
    insertCounter,
    updateCounter,
    deleteCounter
} from "../../service/counter.service";
import { ICounter, INormalizedCounters } from "../../../Model/Counter";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchCountersAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const counters = await fetchCounters(query);
            dispatch({
                type: CounterTypes.FETCH_COUNTERS,
                payload: {
                    byId: counters.reduce((p: INormalizedCounters, c: ICounter): INormalizedCounters => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: counters.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchCountersCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchCountersCount(query);
            dispatch({
                type: CounterTypes.FETCH_COUNTERS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertCounterAction(counter: ICounter): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newCounter = await insertCounter(counter);
            dispatch({
                type: CounterTypes.INSERT_COUNTER,
                payload: newCounter
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateCounterAction(id: number, counter: ICounter): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedCounter = await updateCounter(id, counter);
            dispatch({
                type: CounterTypes.UPDATE_COUNTER,
                payload: updatedCounter
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteCounterAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteCounter(id);
            dispatch({
                type: CounterTypes.DELETE_COUNTER
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setCountersAction(dict: INormalizedCounters): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_COUNTERS,
            payload: dict
        });
    };
}

export function setSelectedCounterAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_SELECTED_COUNTER,
            payload: id
        });
    };
}

export function setSelectedCounterMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_SELECTED_COUNTER_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingCounterAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_IS_LOADING_COUNTER,
            payload: isLoading
        });
    };
}
export function setCounterOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_COUNTER_ORDER,
            payload: idsSorted
        });
    };
}

export function setCounterErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: CounterTypes.SET_COUNTER_ERROR_CODE,
            payload: errorCode
        });
    };
}
