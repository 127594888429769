import React, { Component } from "react";
import { connect } from "react-redux";
import { IBackOfficeTable } from "../../constants/Table.constants";
import "../../scss/components/table/Table.scss";
import { NotificationComponent } from "npm-medgo-components";
import { IReduxNotification } from "../../store/type/notification.type";
import { setNotificationHide } from "../../store/action/notification.action";
import HubloBackOfficeSimpleTable from "./HubloBackOfficeSimpleTable.view";
import { IInstitutionGroup, INormalizedInstitutionGroups } from "../../../Model/InstitutionGroup";
import { IAdmin } from "../../../Model/Admin";
import { frontPage } from "npm-hublo-analytics";
import { getFeatureFlags } from "../../service/featureFlags.service";

interface IReduxActionsTableView {
    setNotificationHide: () => void;
}

interface IReduxPropsTableView {
    me: IAdmin;
    notification: IReduxNotification;
    idInstitutions: number[];
    idInstitutionGroups: number[];
    institutionGroupDict: INormalizedInstitutionGroups;
}

interface IPropsTableView extends IReduxPropsTableView, IReduxActionsTableView {
    backOfficeTable: IBackOfficeTable;
    elements: any[];
    onModifyLine: (id: number | string, index: number) => void;
    onDeleteLineClick: () => void;
    onSearch: (value: string | null) => void;
    pageNumber: number;
    selectCheckboxListOptions?: () => any;
    isLoading: boolean;
    onValidateLineForm?: (e: any) => any;
    onCancelledLineForm?: (e: any) => any;
}

class TableView extends Component<IPropsTableView> {
    state = {
        backOfficeTable: this.props.backOfficeTable
    };

    static defaultProps: any = {
        elements: []
    };

    async componentDidMount() {
        const {
            flags: { useNewReadSlotSystem }
        } = await getFeatureFlags();

        if (useNewReadSlotSystem === false && this.state.backOfficeTable) {
            const backOfficeTable = { ...this.state.backOfficeTable };

            if (backOfficeTable.columns) {
                backOfficeTable.columns = backOfficeTable.columns.map(columnsArray =>
                    columnsArray.filter(column => column.name !== "slotConfig")
                );
            }

            if (backOfficeTable.formColumns) {
                backOfficeTable.formColumns = backOfficeTable.formColumns.map(formColumnsArray =>
                    formColumnsArray.filter(column => column.name !== "slotConfig")
                );
            }

            this.setState({ backOfficeTable });
        }

        // admin level should always be different from 1 and admin typeAdmin different from 6 to be able to send page event and bypass npm-hublo-analytics restriction
        frontPage({
            segmentProperty: {
                idAdmin: this.props.me.id,
                idInstitutions: this.props.idInstitutions,
                institutionsGroupsGrouping: Object.values(this.props.institutionGroupDict).reduce((acc, current): {
                    [key: number]: IInstitutionGroup[];
                } => {
                    const idInstitutions = current.institution;
                    const institutionGroup = JSON.parse(JSON.stringify(current));
                    delete institutionGroup.institution;
                    idInstitutions.forEach((idInstitution: number): void => {
                        if (acc[idInstitution]) {
                            acc[idInstitution].push(institutionGroup);
                        } else {
                            acc[idInstitution] = [institutionGroup];
                        }
                    });
                    return acc;
                }, {}),
                adminLevel: -1,
                typeAdmin: -1,
                path: `${this.props.backOfficeTable.name}`
            },
            name: `${this.props.backOfficeTable.name} table Viewed`
        });
    }

    render(): JSX.Element {
        const UpperBannerComponent = this.props.backOfficeTable.upperBannerComponent;
        return (
            <div className="container-fluid">
                <NotificationComponent
                    text={this.props.notification.message}
                    icon={this.props.notification.icon}
                    isVisible={this.props.notification.isVisible}
                    handleHide={this.props.setNotificationHide}
                    color={this.props.notification.color}
                    disapearsAfter={2000}
                />
                {UpperBannerComponent ? <UpperBannerComponent /> : <></>}
                <HubloBackOfficeSimpleTable {...this.props} backOfficeTable={this.state.backOfficeTable} />
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsTableView => ({
        me: centralState.auth.user,
        notification: centralState.notification,
        idInstitutions: centralState.institutions.ids,
        idInstitutionGroups: centralState.institutionGroups.ids,
        institutionGroupDict: centralState.institutionGroups.byId
    }),
    {
        setNotificationHide
    }
)(TableView);
