import { IShiftTemplate } from "../../Model/ShiftTemplate";
import { PossibleFetchs, PossiblePosts, PossibleApis, PossibleUpdates } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";

const ShiftTemplateDefaultSelect = ["id", "institution", "shiftTemplate"];

export async function fetchShiftTemplate(query: IQueryParams): Promise<IShiftTemplate[]> {
    const shiftTemplates = await postFetchFactory<IShiftTemplate>(
        PossibleFetchs.shiftTemplate,
        PossibleApis.hubloMission,
        1
    )(
        {
            select: ShiftTemplateDefaultSelect
        },
        query
    );
    return shiftTemplates;
}

export async function insertShiftTemplate(shiftTemplateFile: FormData): Promise<any> {
    return await postFactory<FormData>(PossiblePosts.shiftTemplate, PossibleApis.hubloMission, 1)(shiftTemplateFile);
}

export async function updateShiftTemplate(shiftTemplateId: number, newShiftTemplateFile: FormData): Promise<any> {
    return await updateFactory<FormData>(
        PossibleUpdates.shiftTemplate,
        PossibleApis.hubloMission,
        1
    )(shiftTemplateId, newShiftTemplateFile);
}
