import { connect } from "react-redux";
import { fetchAllJobsAction } from "../../store/action/job.action";
import { fetchAllSpecialtiesAction } from "../../store/action/specialty.action";
import { insertTemplateAction, updateTemplateAction } from "../../store/action/template.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { ICreateTemplateRequest, ITemplate } from "../../../Model/Template";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { IQueryParams } from "npm-medgo-query";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { INormalizedJobs } from "../../../Model/Job";

//@ts-ignore
interface IReduxPropsFormTemplate extends IReduxPropsForm {
    idSelectedInstitutions: number[];
    allInstitutions: number[];
    jobDict: INormalizedJobs;
    idAllJobs: number[];
    idAllSpecialties: number[];
}

interface IPropsFormTemplate
    extends IReduxPropsFormTemplate,
        IReduxActionsForm,
        IPropsForm<{ job: { label: string; value: number }; specialty: { label: string; value: number } }> {}

interface IReduxActionsForm {
    insertTemplateAction: (formDictionary: ICreateTemplateRequest) => number;
    fetchAllJobsAction: (param: IQueryParams) => void;
    fetchAllSpecialtiesAction: (param: IQueryParams, jobDict: INormalizedJobs) => void;
    updateTemplateAction: (id: number, template: ITemplate) => void;
}

class FormTemplateComponent extends FormComponent<
    { job: { label: string; value: number }; specialty: { label: string; value: number } },
    IPropsFormTemplate
> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllJobs.length === 0) await this.props.fetchAllJobsAction({ limit: 99999 });
        if (this.props.idAllSpecialties.length === 0)
            await this.props.fetchAllSpecialtiesAction({ limit: 99999 }, this.props.jobDict);
    }
    async createElementAction(): Promise<void> {
        const formDictionaryTemplates = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.contract
        );
        const selectedInstitutions =
            this.props.idSelectedInstitutions.length === 0
                ? this.props.allInstitutions
                : this.props.idSelectedInstitutions;
        const formKeys = Object.keys(formDictionaryTemplates);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryTemplate = formDictionaryTemplates[key];
                    await Promise.all(
                        selectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertTemplateAction({
                                    institution: idInstitution,
                                    name: formDictionaryTemplate.name,
                                    specialty: formDictionaryTemplate.specialty
                                        ? formDictionaryTemplate.specialty.value
                                        : null,
                                    specialtyName: formDictionaryTemplate.specialty
                                        ? formDictionaryTemplate.specialty.label
                                        : "",
                                    job: formDictionaryTemplate.job ? formDictionaryTemplate.job.value : null,
                                    jobName: formDictionaryTemplate.job ? formDictionaryTemplate.job.label : "",
                                    isFullTime: formDictionaryTemplate.isFullTime
                                });
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        let template = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.contract)[0];
        template = Object.assign(template, {
            jobName: template.job ? template.job.label : "",
            specialtyName: template.specialty ? template.specialty.label : "",
            job: template.job ? template.job.value : null,
            specialty: template.specialty ? template.specialty.value : null
        });
        await this.props.updateTemplateAction(template.id, template as ITemplate);
    }
}

export default connect(
    (centralState: any): IPropsFormTemplate =>
        Object.assign(getConnectParentState(centralState), {
            formDictionary: centralState.form.formDictionary,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            selectOptions: {
                [EBackOfficeColumnName.job]: getElementsForSelectComponent(
                    centralState.jobs,
                    centralState.institutions.selectedMulti,
                    true
                ),
                [EBackOfficeColumnName.specialty]: getElementsForSelectComponent(
                    centralState.specialties,
                    centralState.institutions.selectedMulti,
                    true
                )
            },
            allInstitutions: centralState.institutions.ids,
            jobDict: centralState.jobs.allById,
            idAllJobs: centralState.jobs.allIds,
            idAllSpecialties: centralState.specialties.allIds
        }),
    Object.assign(getConnectParentActions(), {
        insertTemplateAction,
        fetchAllJobsAction,
        fetchAllSpecialtiesAction,
        updateTemplateAction
    })
)(FormTemplateComponent);
