import { IReduxAction } from "../store.type";
import PoleTypes from "../type/pole.type";
import { fetchPoles } from "../../service/pole.service";
import { IPole, INormalizedPoles } from "../../../Model/Pole";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchPolesAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const poles = await fetchPoles(query);
            dispatch({
                type: PoleTypes.FETCH_POLES,
                payload: {
                    byId: poles.reduce((p: INormalizedPoles, c: IPole): INormalizedPoles => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: poles.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
export function setPolesAction(dict: INormalizedPoles): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_POLES,
            payload: dict
        });
    };
}

export function setSelectedPoleAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_SELECTED_POLE,
            payload: id
        });
    };
}

export function setSelectedPoleMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_SELECTED_POLE_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingPoleAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_IS_LOADING_POLE,
            payload: isLoading
        });
    };
}
export function setPoleOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_POLE_ORDER,
            payload: idsSorted
        });
    };
}

export function setPoleErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_POLE_ERROR_CODE,
            payload: errorCode
        });
    };
}
