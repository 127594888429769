enum MotifActionTypes {
    FETCH_MOTIFS = "FETCH_MOTIFS",
    SET_MOTIFS = "SET_MOTIFS",
    FETCH_MOTIFS_COUNT = "FETCH_MOTIFS_COUNT",
    SET_SELECTED_MOTIF = "SET_SELECTED_MOTIF",
    SET_SELECTED_MOTIF_MULTI = "SET_SELECTED_MOTIF_MULTI",
    SET_IS_LOADING_MOTIF = "SET_IS_LOADING_MOTIF",
    SET_MOTIF_ORDER = "SET_MOTIF_ORDER",
    INSERT_INSTITUTION_HAS_MOTIF = "INSERT_INSTITUTION_HAS_MOTIF",
    UPDATE_MOTIF = "UPDATE_MOTIF",
    DELETE_MOTIF = "DELETE_MOTIF",
    SET_MOTIF_ERROR_CODE = "SET_MOTIF_ERROR_CODE",
    FETCH_ALL_MOTIFS = "FETCH_ALL_MOTIFS"
}
export default MotifActionTypes;
