import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionTypes from "../type/institution.type";
import { IInstitution } from "../../../Model/Institution";

const initialState: IReduxInitialState<IInstitution> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function InstitutionReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionTypes.FETCH_INSTITUTIONS:
        case InstitutionTypes.SET_INSTITUTIONS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InstitutionTypes.SET_SELECTED_INSTITUTION: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InstitutionTypes.SET_SELECTED_INSTITUTION_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InstitutionTypes.SET_IS_LOADING_INSTITUTION: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InstitutionTypes.SET_INSTITUTION_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case InstitutionTypes.UPDATE_INSTITUTION: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        default:
            return state;
    }
}
