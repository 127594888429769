import React from "react";
import { ILevel } from "../../../Model/Level";
import NotificationActivationsView from "../../components/notifications/view/NotificationActivations.view";
import NotificationInstitutionsServices from "../../components/notifications/view/NotificationInstitutionsServices.view";
import { defaultInstitutionOptions } from "../../store/action/institutionOption.action";
import { IInstitution } from "../../../Model/Institution";
import { INotificationAdmin } from "../../../Model/NotificationAdmin";
import "./Notifications.view.scss";

interface INotificationsViewProps {
    notificationAdmin: INotificationAdmin;
    institutions: IInstitution[];
    areServicesReadOnly: boolean;
    adminPermissions: ILevel;
    idServices: number[];
    infoMessageNotif: string;
    errorMessageNotif: string;
    infoMessageService: string;
    errorMessageService: string;
    isLoadingNotif: boolean;
    didLoadNotif: boolean;
    isLoadingService: boolean;
    didLoadService: boolean;
    handleSubmitNotification: () => void;
    handleSubmitService: () => void;
    onSelectNotif: (whichNotif: string, val: number) => void;
    onSelectService: (idInstitution: number, idServices: number[]) => void;
}

const NotificationsView: React.FC<INotificationsViewProps> = props => {
    return (
        <>
            <NotificationInstitutionsServices
                onSelectService={props.onSelectService}
                handleSubmitService={props.handleSubmitService}
                isReadingOnly={props.areServicesReadOnly}
                institutions={props.institutions}
                idServices={props.idServices}
                infoMessageService={props.infoMessageService}
                errorMessageService={props.errorMessageService}
                isLoadingService={props.isLoadingService}
                didLoadService={props.didLoadService}
            />

            <NotificationActivationsView
                institutionOptions={defaultInstitutionOptions}
                notificationAdmin={props.notificationAdmin}
                adminPermissions={props.adminPermissions}
                infoMessageNotif={props.infoMessageNotif}
                errorMessageNotif={props.errorMessageNotif}
                isLoadingNotif={props.isLoadingNotif}
                didLoadNotif={props.didLoadNotif}
                handleSubmitNotification={props.handleSubmitNotification}
                onSelectNotif={props.onSelectNotif}
            />
        </>
    );
};

export default NotificationsView;
