import React, { Component } from "react";

import { connect } from "react-redux";
import { IAdmin } from "../../../Model/Admin";
import { IBackOfficeTable } from "../../constants/Table.constants";
import { firstLetterUppercase } from "../../../modules/strings/case";
import { getModelsFromDict } from "../../modules/store/util";
import { INormalizedInstitutions } from "../../../Model/Institution";
import { IBackOfficeColumn, EBackOfficeColumnName } from "../../constants/Column.constants";
import ColumnName from "../../container/shared/ColumnName.view";
import { displayCriteria } from "../../../toolbox/permissions";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";

interface IReduxPropsLineHeaderView {
    currentLang: string;
    lang: any;
    me: IAdmin;
    idInstitutions: number[];
    institutionDict: INormalizedInstitutions;
    selectedTab: number;
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    idPoles: number[];
    modifyingElement: any;
}
interface IPropsLineHeaderView extends IReduxPropsLineHeaderView {
    backOfficeTable: IBackOfficeTable;
}

class LineHeaderView extends Component<IPropsLineHeaderView> {
    render(): JSX.Element {
        let columns = this.props.backOfficeTable.formColumns
            ? this.props.backOfficeTable.formColumns[this.props.selectedTab]
            : this.props.backOfficeTable.columns[this.props.selectedTab];
        columns = columns.filter((column: IBackOfficeColumn): boolean => {
            return column.name !== EBackOfficeColumnName.id && column.name !== EBackOfficeColumnName.institution;
        });
        return (
            <tr className={`tr-line-header-form`}>
                {columns.map(
                    (column, index): JSX.Element => {
                        if (
                            !displayCriteria(
                                column.displayCriteria,
                                getModelsFromDict(this.props.institutionDict, this.props.idInstitutions),
                                getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions),
                                this.props.modifyingElement ? true : false,
                                this.props.idPoles
                            )
                        )
                            return <></>;
                        const columnName = this.props.lang[`${column.name}-form`]
                            ? firstLetterUppercase(this.props.lang[`${column.name}-form`])
                            : firstLetterUppercase(this.props.lang[column.name]);
                        return (
                            <th key={`header_${index}`} className={`th-line-header-form`}>
                                <span className={`text-line-header-form`}>
                                    <ColumnName lang={this.props.lang} column={column} columnName={columnName} />
                                </span>
                            </th>
                        );
                    }
                )}
            </tr>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsLineHeaderView => ({
        lang: centralState.language.lang,
        me: centralState.auth.user,
        currentLang: centralState.language.currentLang,
        idInstitutions: centralState.institutions.selectedMulti,
        institutionDict: centralState.institutions.byId,
        selectedTab: centralState.tables.selectedTab,
        idInstitutionOptions: centralState.institutionOption.ids,
        institutionOptionDict: centralState.institutionOption.byId,
        idPoles: centralState.poles.ids,
        modifyingElement: centralState.tables.modifyingElement
    }),
    {}
)(LineHeaderView);
