import { IReduxAction, IReduxInitialState } from "../store.type";
import AdminTypes from "../type/admin.type";
import { IAdmin } from "../../../Model/Admin";

const initialState: IReduxInitialState<IAdmin> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function AdminReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AdminTypes.FETCH_ADMINS:
        case AdminTypes.SET_ADMINS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case AdminTypes.SET_SELECTED_ADMIN: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case AdminTypes.SET_SELECTED_ADMIN_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case AdminTypes.SET_IS_LOADING_ADMIN: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case AdminTypes.SET_ADMIN_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case AdminTypes.INSERT_ADMIN: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case AdminTypes.INSERT_INSTITUTION_HAS_ADMIN: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.admin]: {
                        ...state.byId[action.payload.admin],
                        institution: [
                            ...(state.byId[action.payload.admin].institution || []),
                            action.payload.institution
                        ],
                        matricule: action.payload.matricule,
                        position: action.payload.position
                    }
                }
            };
        }

        case AdminTypes.UPDATE_ADMIN: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case AdminTypes.DELETE_ADMIN_IN_INSTITUTION_NOTIFICATION_SERVICE: {
            const idService = action.payload.adminInInstitutionNotificationServiceAction.service;
            const service = state.byId[action.payload.idAdmin].service;
            const index = service.indexOf(idService);
            if (index !== -1) service.splice(index, 1);
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idAdmin]: {
                        ...state.byId[action.payload.idAdmin],
                        service
                    }
                }
            };
        }

        default:
            return state;
    }
}
