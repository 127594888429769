//@ts-ignore
import * as clone from "clone";
import { IReduxAction, IReduxInitialState } from "../store.type";
import ContractSalaryGridTypes from "../type/contractSalaryGrid.type";
import { IContractSalaryGrid } from "../../../Model/ContractSalaryGrid";

const initialState: IReduxInitialState<IContractSalaryGrid> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false,
    errorCode: -1,
    allById: {},
    allIds: []
};

export default function contractSalaryGridReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ContractSalaryGridTypes.FETCH_CONTRACTSALARYGRIDS:
        case ContractSalaryGridTypes.SET_CONTRACTSALARYGRIDS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case ContractSalaryGridTypes.SET_SELECTED_CONTRACTSALARYGRID: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case ContractSalaryGridTypes.SET_SELECTED_CONTRACTSALARYGRID_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case ContractSalaryGridTypes.SET_IS_LOADING_CONTRACTSALARYGRID: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case ContractSalaryGridTypes.SET_CONTRACTSALARYGRID_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case ContractSalaryGridTypes.SET_CONTRACTSALARYGRID_ERROR_CODE: {
            return {
                ...state,
                errorCode: action.payload
            };
        }

        case ContractSalaryGridTypes.FETCH_ALL_CONTRACTSALARYGRIDS: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case ContractSalaryGridTypes.INSERT_INSTITUTION_HAS_CONTRACTSALARYGRID: {
            const contractSalaryGrid = state.byId[action.payload.contractSalaryGrid]
                ? state.byId[action.payload.contractSalaryGrid]
                : state.allById[action.payload.contractSalaryGrid];
            const idInstitutions =
                state.byId[action.payload.contractSalaryGrid] &&
                state.byId[action.payload.contractSalaryGrid].institution
                    ? state.byId[action.payload.contractSalaryGrid].institution
                    : state.allById[action.payload.contractSalaryGrid].institution;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [contractSalaryGrid.id]: {
                        ...contractSalaryGrid,
                        institution: [...idInstitutions, action.payload.institution]
                    }
                },
                ids: state.ids.includes(action.payload.contractSalaryGrid)
                    ? [...state.ids]
                    : [...state.ids, action.payload.contractSalaryGrid]
            };
        }

        case ContractSalaryGridTypes.UPDATE_CONTRACTSALARYGRID: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        case ContractSalaryGridTypes.DELETE_INSTITUTION_HAS_CONTRACTSALARYGRIDS: {
            let byId = clone(state.byId);
            let ids = clone(state.ids);
            delete byId[action.payload];
            ids.splice(state.ids.indexOf(action.payload), 1);
            return {
                ...state,
                byId,
                ids
            };
        }

        default:
            return state;
    }
}
