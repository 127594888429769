enum ServiceActionTypes {
    FETCH_SERVICES = "FETCH_SERVICES",
    SET_SERVICES = "SET_SERVICES",
    FETCH_SERVICES_COUNT = "FETCH_SERVICES_COUNT",
    SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE",
    SET_SELECTED_SERVICE_MULTI = "SET_SELECTED_SERVICE_MULTI",
    SET_IS_LOADING_SERVICE = "SET_IS_LOADING_SERVICE",
    SET_SERVICE_ORDER = "SET_SERVICE_ORDER",
    INSERT_SERVICE = "INSERT_SERVICE",
    INSERT_INSTITUTION_HAS_SERVICE = "INSERT_INSTITUTION_HAS_SERVICE",
    UPDATE_SERVICE = "UPDATE_SERVICE",
    DELETE_SERVICE = "DELETE_SERVICE",
    FETCH_ALL_SERVICES = "FETCH_ALL_SERVICES"
}
export default ServiceActionTypes;
