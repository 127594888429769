import { IReduxAction, IReduxInitialState } from "../store.type";
import LevelTypes from "../type/level.type";
import { ILevel } from "../../../Model/Level";

const initialState: IReduxInitialState<ILevel> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false,
    allById: {},
    allIds: []
};

export default function LevelReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LevelTypes.FETCH_LEVELS:
        case LevelTypes.SET_LEVELS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case LevelTypes.FETCH_ALL_LEVELS: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case LevelTypes.SET_SELECTED_LEVEL: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case LevelTypes.SET_SELECTED_LEVEL_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case LevelTypes.SET_IS_LOADING_LEVEL: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case LevelTypes.SET_LEVEL_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        default:
            return state;
    }
}
