/* eslint-disable react/prop-types */
import { ISelectLabelValue } from "npm-medgo-components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SsoOAuth2Form } from "./forms/SsoOAuth2Form";
import { useInsitutionHasSsoLoading } from "./hooks/useInsitutionHasSsoLoading.hook";
import { currentInstitutionSelector, langSelector, ssoNameSelector } from "../../store/selectors";
import { Slider } from "./elements/Slider";
import { SsoTypeSelect } from "./elements/SsoTypeSelect";
import { SsoForm } from "./data-mapping/ssoType.mapping";
import { SsoName } from "./elements/SsoName";
import { useSsoState } from "./hooks/useSsoState.hook";
import { Notification } from "./elements/Notification";
import "./SsoConfigContainer.scss";

export const SsoConfigContainer = (): JSX.Element => {
    const idInstitution = useSelector(currentInstitutionSelector);
    const signupCode = useSelector(ssoNameSelector);
    const [lang] = useSelector(langSelector);
    const [sso] = useInsitutionHasSsoLoading(idInstitution);
    const [form, setForm] = useState<SsoForm>("ssoViaOAuth2");
    const { isEnabled, handleSsoState } = useSsoState(sso, idInstitution, form);

    const handleSsoTypeChange = (value: ISelectLabelValue): void => {
        setForm(value.value as SsoForm);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="card padded-card">
                    <div className="row">
                        <Slider onValueChanged={handleSsoState} text={lang.activateSso} isEnabled={isEnabled} />
                        <SsoTypeSelect onChange={handleSsoTypeChange} value={form} lang={lang} isEnabled={isEnabled} />
                    </div>
                    <SsoName isEnabled={isEnabled} signupCode={signupCode} lang={lang} />
                    <div className="row">
                        {
                            {
                                ssoViaOAuth2: (
                                    <SsoOAuth2Form idInstitution={idInstitution} lang={lang} isDisabled={!isEnabled} />
                                )
                            }[form]
                        }
                    </div>
                </div>
            </div>
            <Notification lang={lang} />
        </>
    );
};
