import React from "react";
import { EAvailableLang, SelectCheckboxListComponent } from "@hublo/legacy-components";
import { langSelector } from "../../store/selectors";
import { IPole } from "../../../Model/Pole";
import { useSelector } from "react-redux";

export default function InputPole({
    idPoles,
    poles,
    onSelectPole,
    isReadingOnly,
    lang
}: {
    idPoles: number[];
    poles: IPole[];
    onSelectPole: (idPoles: number[]) => void;
    isReadingOnly: boolean;
    lang: string;
}): JSX.Element {
    const [{ pole }] = useSelector(langSelector);
    return (
        <SelectCheckboxListComponent
            hasOptionAll={false}
            values={idPoles}
            options={poles.map(e => ({
                isCategory: false,
                label: e.name,
                values: [e.id]
            }))}
            onChange={onSelectPole}
            text={pole}
            isReadingOnly={isReadingOnly}
            currentLang={lang as EAvailableLang}
        />
    );
}
