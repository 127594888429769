import { IInstitutionGroup } from "../../Model/InstitutionGroup";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionGroupDefaultSelect = ["id", "name", "type", "institution"];

export async function fetchInstitutionGroups(query: IQueryParams): Promise<IInstitutionGroup[]> {
    const institutionGroups = await postFetchFactory<IInstitutionGroup>(
        PossibleFetchs.institutionGroup,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionGroupDefaultSelect
        },
        query
    );
    return institutionGroups;
}

export async function insertInstitutionGroup(institutionGroup: IInstitutionGroup): Promise<IInstitutionGroup> {
    const institutionGroups = await postFactory<IInstitutionGroup>(
        PossiblePosts.institutionGroup,
        PossibleApis.medgoApi
    )(institutionGroup);
    return institutionGroups;
}

export async function updateInstitutionGroup(
    id: number,
    institutionGroup: IInstitutionGroup
): Promise<IInstitutionGroup> {
    const institutionGroups = await updateFactory<IInstitutionGroup>(
        PossibleUpdates.institutionGroup,
        PossibleApis.medgoApi
    )(id, institutionGroup);
    return institutionGroups;
}

export async function deleteInstitutionGroup(id: number): Promise<IInstitutionGroup> {
    const institutionGroups = await deleteFactory<IInstitutionGroup>(
        PossibleDeletes.institutionGroup,
        PossibleApis.medgoApi
    )(id);
    return institutionGroups;
}
