import { IInstitutionOptionMission } from "../../Model/InstitutionOptionMission";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionOptionMissionDefaultSelect = [
    "institution",
    "canSelectOneWorkerForMissionRequestCreate",
    "hasDoubleValidation",
    "canSelectBetweenRecupAndSupp"
];

export async function fetchInstitutionOptionMissions(query: IQueryParams): Promise<IInstitutionOptionMission[]> {
    const institutionOptionMissions = await postFetchFactory<IInstitutionOptionMission>(
        PossibleFetchs.institutionOptionMission,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionOptionMissionDefaultSelect
        },
        query
    );
    return institutionOptionMissions;
}

export async function fetchInstitutionOptionMissionsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionOptionMission>(
        PossibleFetchs.institutionOptionMission,
        PossibleApis.medgoApi
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionOptionMission(
    institutionOptionMission: IInstitutionOptionMission
): Promise<IInstitutionOptionMission> {
    return await postFactory<IInstitutionOptionMission>(
        PossiblePosts.institutionOptionMission,
        PossibleApis.medgoApi
    )(institutionOptionMission);
}

export async function updateInstitutionOptionMission(
    id: number,
    institutionOptionMission: IInstitutionOptionMission
): Promise<IInstitutionOptionMission> {
    return await updateFactory<IInstitutionOptionMission>(
        PossibleUpdates.institutionOptionMission,
        PossibleApis.medgoApi
    )(id, institutionOptionMission);
}

export async function deleteInstitutionOptionMission(id: number): Promise<IInstitutionOptionMission> {
    return await deleteFactory<IInstitutionOptionMission>(
        PossibleDeletes.institutionOptionMission,
        PossibleApis.medgoApi
    )(id);
}
