import { connect } from "react-redux";
import {
    fetchSchedulesAction,
    setSchedulesAction,
    setIsLoadingScheduleAction,
    updateScheduleAction
} from "../../store/action/schedule.action";

import { ISchedule, IScheduleStore } from "../../../Model/Schedule";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";
import {
    fetchMissionIDsAttachedToMissionPeriods,
    removeScheduleFromAttachedMissions
} from "../../service/mission.service";
import moment from "moment";
import { ArrayToolbox } from "npm-medgo-toolbox";

//@ts-ignore
interface IReduxPropsSchedule extends IReduxPropsTable {
    idSchedule: number[];
    scheduleDict: IScheduleStore[];
    searchValue: string;
    idItemToBeDeleted: number;
}

interface IPropsTableSchedule extends IReduxPropsSchedule, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setSchedulesAction: (elements: ISchedule[]) => any;
    fetchSchedulesAction: (param: IQueryParams) => any;
    setIsLoadingScheduleAction: (isLoading: boolean) => any;
    updateScheduleAction: (id: number, schedule: IScheduleStore) => void;
}

class TableScheduleComponent extends TableComponent<IPropsTableSchedule> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingScheduleAction(true);
        if (this.props.idSchedule.length === 0) await this.props.fetchSchedulesAction(params);
        this.props.setIsLoadingScheduleAction(false);
    }

    setElements(elements: any): void {
        this.props.setSchedulesAction(elements);
    }

    getElements(): any[] {
        const allResults = getModelsFromDict(this.props.scheduleDict, this.props.idSchedule);
        const allResultsElements: IScheduleStore[] = allResults.sort((a, b): 1 | -1 | 0 =>
            a.code.toLowerCase() > b.code.toLowerCase() ? 1 : a.code.toLowerCase() < b.code.toLowerCase() ? -1 : 0
        );
        return allResultsElements.filter(
            (e): boolean =>
                !this.props.searchValue ||
                isIncludedStringCaseInsensitive(e.nbWorkedMinutes, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.endAt, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.beginAt, this.props.searchValue) ||
                isIncludedStringCaseInsensitive(e.code, this.props.searchValue)
        );
    }
    onDeleteLineClick(): void {
        const id = this.props.idItemToBeDeleted;
        const schedule = this.props.scheduleDict[id];
        schedule.isDeleted = false;
        this.props.updateScheduleAction(id, schedule);
        this.props.idSelectedMultiInstitutions.forEach((id: number): void => {
            removeScheduleFromAttachedMissions(id, schedule.id);
        });
    }
    async getMissionsCount(element: any): Promise<number> {
        const idMatchingScheduleFutureMissions = await fetchMissionIDsAttachedToMissionPeriods({
            query: {
                institution: this.props.idSelectedMultiInstitutions,
                schedule: element.id,
                rangeBeginAt: `${moment().valueOf()},${moment()
                    .add(1, "y")
                    .valueOf()}`
            }
        });
        return ArrayToolbox.removeDuplicateInArray(idMatchingScheduleFutureMissions).length;
    }
}

export default connect(
    (centralState: any): IReduxPropsSchedule =>
        Object.assign(getConnectParentState(centralState), {
            idSchedule: centralState.schedule.ids,
            scheduleDict: centralState.schedule.byId,
            isLoading: centralState.schedule.isLoading,
            searchValue: centralState.tables.searchValue,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        fetchSchedulesAction,
        setSchedulesAction,
        setIsLoadingScheduleAction,
        updateScheduleAction
    })
)(TableScheduleComponent);
