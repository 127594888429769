import { IReduxAction } from "../store.types";
import CategoryActionTypes from "../type/category.type";

const initialState: {
    isAddFormDisplayed: boolean;
} = {
    isAddFormDisplayed: false
};
export default function tableReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case CategoryActionTypes.SET_ADD_FORM_DISPLAYED:
            return {
                ...state,
                isAddFormDisplayed: action.payload
            };

        default:
            return state;
    }
}
