import { connect } from "react-redux";
import {
    setInstitutionInfosAction,
    fetchInstitutionInfosAction,
    setIsLoadingInstitutionInfoAction
} from "../../store/action/institutionInfo.action";
import { IInstitutionInfo } from "../../../Model/InstitutionInfo";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionInfos } from "../../../Model/InstitutionInfo";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionInfo extends IReduxPropsTable {
    idInstitutionInfos: number[];
    institutionInfoDict: INormalizedInstitutionInfos;
    idInstitutions: number[];
}

interface IPropsInstitutionInfo extends IReduxPropsInstitutionInfo, IReduxActionsInstitutionInfo, IPropsTable {}

interface IReduxActionsInstitutionInfo extends IReduxActionsTable {
    setInstitutionInfosAction: (elements: IInstitutionInfo[]) => any;
    fetchInstitutionInfosAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionInfoAction: (isLoading: boolean) => any;
}

class TableInstitutionInfoContainer extends TableComponent<IPropsInstitutionInfo> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionInfoAction(true);
        if (this.props.idInstitutionInfos.length === 0) await this.props.fetchInstitutionInfosAction(params);
        this.props.setIsLoadingInstitutionInfoAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionInfosAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionInfoDict, this.props.idInstitutionInfos);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionInfo =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionInfos: centralState.institutionInfo.ids,
            institutionInfoDict: centralState.institutionInfo.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionInfosAction,
        fetchInstitutionInfosAction,
        setIsLoadingInstitutionInfoAction
    })
)(TableInstitutionInfoContainer);
