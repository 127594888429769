import { Equality } from "npm-medgo-toolbox";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchLimits } from "../../../../Constant/Api.constant";
import { IInstitutionHasSso } from "../../../../Model/InstitutionHasSso";
import { fetchInstitutionHasSsosAction } from "../../../store/action/institutionHasSso.action";
import { ssoSelector } from "../../../store/selectors";

export const useInsitutionHasSsoLoading = (idInstitution: number): [IInstitutionHasSso | undefined, boolean] => {
    const dispatch = useDispatch();

    const { sso, isLoading } = useSelector(ssoSelector);

    useEffect((): void => {
        if (!Equality.isNullOrUndefined(idInstitution)) {
            dispatch(
                fetchInstitutionHasSsosAction({
                    limit: FetchLimits.sso,
                    orderby: [
                        {
                            columnName: "id",
                            orientation: "ASC"
                        }
                    ],
                    query: { institution: idInstitution },
                    select: ["id", "ssoType", "enabled"]
                })
            );
        }
    }, [dispatch, idInstitution]);

    return [sso, isLoading];
};
