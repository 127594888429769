import { connect } from "react-redux";
import {
    setSpecialHoursAction,
    fetchSpecialHoursAction,
    setIsLoadingSpecialHourAction,
    deleteSpecialHourAction,
    deleteSpecialHourHasJobAction,
    deleteSpecialHourHasServiceAction
} from "../../store/action/specialHour.action";
import { fetchSpecialHourHasJob, fetchSpecialHourHasService } from "../../service/specialHour.service";
import { fetchServicesAction } from "../../store/action/service.action";
import { fetchJobsAction } from "../../store/action/job.action";
import { ISpecialHour, INormalizedSpecialHours } from "../../../Model/SpecialHour";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { getModelsFromDict } from "../../modules/store/util";
import { IService } from "../../../Model/Service";
import { IJob } from "../../../Model/Job";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { getElementsInInstitution } from "../../../toolbox/filters";

interface IReduxPropsTableSpecialHour extends IReduxPropsTable {
    selectedMulti: number[];
    jobsById: IJob[];
    idInstitutions: number[];
    idSpecialHours: number[];
    SpecialHourDict: INormalizedSpecialHours;
    idServices: number[];
    idJobs: number[];
    idItemToBeDeleted: number;
}

interface IPropsTableSpecialHour extends IReduxPropsTableSpecialHour, IReduxActionsTable, IPropsTable {}

interface IReduxActionsTable {
    setSpecialHoursAction: (elements: ISpecialHour[]) => any;
    fetchSpecialHoursAction: (param: IQueryParams) => any;
    setSpecialHoursPageAction: (pageNum: number) => any;
    setSelectCheckboxListOptionsAction: (elemnts: any[], table: string, field: string) => void;
    fetchServicesAction: (param: IQueryParams) => any;
    fetchJobsAction: (param: IQueryParams) => any;
    setIsLoadingSpecialHourAction: (isLoading: boolean) => any;
    deleteSpecialHourAction: (id: number) => any;
    deleteSpecialHourHasJobAction: (id: number) => any;
    deleteSpecialHourHasServiceAction: (id: number) => any;
}

class TableSpecialHourComponent extends TableComponent<IPropsTableSpecialHour> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingSpecialHourAction(true);
        await this.props.fetchSpecialHoursAction(params);
        if (this.props.idSpecialHours.length === 0) await this.props.fetchSpecialHoursAction(params);
        if (this.props.idJobs.length === 0)
            await this.props.fetchJobsAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
        if (this.props.idServices.length === 0)
            await this.props.fetchServicesAction({ query: { institution: this.props.idInstitutions }, limit: 99999 });
        this.props.setIsLoadingSpecialHourAction(false);
    }

    getQueryFromFilter(): any {
        return {
            idInstitution: this.props.idInstitutions
        };
    }

    setElements(elements: any): void {
        this.props.setSpecialHoursAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<ISpecialHour>(this.props.SpecialHourDict, this.props.idSpecialHours);
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.specialHour]: {
                [EBackOfficeColumnName.job]: getElementsInInstitution(
                    getModelsFromDict<IJob>(this.props.jobsById, this.props.idJobs),
                    this.props.selectedMulti
                ),
                [EBackOfficeColumnName.service]: getElementsInInstitution(
                    getModelsFromDict<IService>(this.props.servicesById, this.props.idServices),
                    this.props.selectedMulti
                )
            }
        };
    }

    async onDeleteLineClick(): Promise<void> {
        await this.props.deleteSpecialHourAction(this.props.idItemToBeDeleted);
        const specialHourHasJobs = await fetchSpecialHourHasJob({
            query: { idSpecialHour: this.props.idItemToBeDeleted },
            limit: 99999
        });
        const specialHourHasServices = await fetchSpecialHourHasService({
            query: { idSpecialHour: this.props.idItemToBeDeleted },
            limit: 99999
        });
        for (let specialHourHasJob of specialHourHasJobs) {
            await this.props.deleteSpecialHourHasJobAction(specialHourHasJob.id);
        }
        for (let specialHourHasService of specialHourHasServices) {
            await this.props.deleteSpecialHourHasServiceAction(specialHourHasService.id);
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsTableSpecialHour =>
        Object.assign(getConnectParentState(centralState), {
            selectedMulti: centralState.institutions.selectedMulti,
            SpecialHourDict: centralState.specialHours.byId,
            isLoading: centralState.specialHours.isLoading,
            idSpecialHours: centralState.specialHours.ids,
            idServices: centralState.services.ids,
            servicesById: centralState.services.byId,
            idInstitutions: centralState.institutions.ids,
            jobsById: centralState.jobs.byId,
            idJobs: centralState.jobs.ids,
            idItemToBeDeleted: centralState.tables.idItemToBeDeleted
        }),
    Object.assign(getConnectParentActions(), {
        setSpecialHoursAction,
        fetchSpecialHoursAction,
        fetchServicesAction,
        fetchJobsAction,
        setIsLoadingSpecialHourAction,
        deleteSpecialHourAction,
        deleteSpecialHourHasJobAction,
        deleteSpecialHourHasServiceAction
    })
)(TableSpecialHourComponent);
