import { IReduxAction } from "../store.types";
import TableActionTypes from "../type/table.type";

export function setPageNumberAction(pageNum: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_PAGE_NUMBER,
            payload: pageNum
        });
    };
}

export function setModifyingElementAction(modifyingElement: any): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_CHECKED_ELEMENTS_DICT,
            payload: modifyingElement
        });
    };
}

export function setItemIdToBeDeleted(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_ELEMENT_ID_TO_DELETE,
            payload: id
        });
    };
}

export function setSearchValueAction(value: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_SEARCH_VALUE,
            payload: value
        });
    };
}

export function resetSearchValueAction(): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_SEARCH_VALUE,
            payload: null
        });
    };
}

export function setSelectedTabAction(selectedTab: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.SET_SELECTED_TAB,
            payload: selectedTab
        });
    };
}

export function resetSelectedTabAction(): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TableActionTypes.RESET_SELECTED_TAB,
            payload: 0
        });
    };
}
