enum ExportActionTypes {
    FETCH_EXPORTS = "FETCH_EXPORTS",
    SET_EXPORTS = "SET_EXPORTS",
    FETCH_EXPORTS_COUNT = "FETCH_EXPORTS_COUNT",
    SET_SELECTED_EXPORT = "SET_SELECTED_EXPORT",
    SET_SELECTED_EXPORT_MULTI = "SET_SELECTED_EXPORT_MULTI",
    SET_IS_LOADING_EXPORT = "SET_IS_LOADING_EXPORT",
    SET_EXPORT_ORDER = "SET_EXPORT_ORDER",
    INSERT_EXPORT = "INSERT_EXPORT",
    UPDATE_EXPORT = "UPDATE_EXPORT",
    DELETE_EXPORT = "DELETE_EXPORT",
    SET_EXPORT_ERROR_CODE = "SET_EXPORT_ERROR_CODE"
}
export default ExportActionTypes;
