import { ISelectLabelValue } from "npm-medgo-components";

import { EBackOfficeColumnName } from "./Column.constants";
import { rawTimeZones } from "@vvo/tzdb";
import { ServiceParking } from "../../Model/Service";

interface RawTimeZone {
    name: string;
    rawOffsetInMinutes: number;
}

const createTimezoneOption = ({ name, rawOffsetInMinutes }: RawTimeZone) => {
    const offsetSign = rawOffsetInMinutes >= 0 ? "+" : "";
    const offsetHours = rawOffsetInMinutes / 60;
    const label = `UTC/GMT ${offsetSign}${offsetHours} - ${name}`;

    return { value: name, label };
};

export const selectESignatureProviderOptions = {
    [EBackOfficeColumnName.eSignatureProvider]: [
        {
            value: "connective",
            label: "connective"
        },
        {
            value: "cegedim",
            label: "cegedim"
        },
        {
            value: "",
            label: "-"
        }
    ]
};

export const selectCountryOptions = {
    [EBackOfficeColumnName.country]: [
        {
            value: "fr",
            label: "fr"
        },
        {
            value: "de",
            label: "de"
        },
        {
            value: "ch",
            label: "ch"
        },
        {
            value: "be",
            label: "be"
        },
        {
            value: "lu",
            label: "lu"
        },
        {
            value: "es",
            label: "es"
        },
        {
            value: "it",
            label: "it"
        },
        {
            value: "uk",
            label: "uk"
        }
    ]
};

export const ServiceParkingLabel = {
    [ServiceParking.FREE_PARKING]: "Parking gratuit",
    [ServiceParking.PAID_PARKING]: "Parking payant",
    [ServiceParking.NO_PARKING]: "Pas de parking"
};

export const selectParkingOptions = {
    [EBackOfficeColumnName.parking]: [
        {
            value: ServiceParking.FREE_PARKING,
            label: "Parking gratuit"
        },
        {
            value: ServiceParking.PAID_PARKING,
            label: "Parking payant"
        },
        {
            value: ServiceParking.NO_PARKING,
            label: "Pas de parking"
        }
    ]
};

export const selectLangOptions = {
    [EBackOfficeColumnName.lang]: [
        {
            value: "fr",
            label: "Français"
        },
        {
            value: "de",
            label: "Deutsch"
        },
        {
            value: "en",
            label: "English"
        },
        {
            value: "es",
            label: "Español"
        }
    ]
};

export const selectContractLevelOptions = {
    [EBackOfficeColumnName.contractLevel]: [
        {
            value: 1,
            label: "1 - Hublo"
        },
        {
            value: 2,
            label: "2 - User on old Contract add-on (Contract in Shift Details)"
        },
        {
            value: 3,
            label: "3 - Denied"
        },
        {
            value: 9,
            label: "9 - User"
        },
        {
            value: 10,
            label: "10 - Signatory (Mass e-Signature)"
        }
    ]
};

export const selectTypeAdminOptions = {
    [EBackOfficeColumnName.typeAdmin]: [
        {
            value: 1,
            label: "1 - Decision-maker (Human Resources, Direction)"
        },
        {
            value: 2,
            label: "2 - Decision-maker & Power-User"
        },
        {
            value: 3,
            label: "3 - Power-User (Referrer or main user)"
        },
        {
            value: 4,
            label: "4 - User"
        },
        {
            value: 5,
            label: "5 - Viewer or Inactive or Human Resources"
        },
        {
            value: 6,
            label: "6 - Hublo Account"
        },
        {
            value: 7,
            label: "7 - Casual user (Astreinte)"
        }
    ]
};

export const selectTimezoneOptions = {
    [EBackOfficeColumnName.timezone]: rawTimeZones.map(createTimezoneOption)
};

export const selectSectorOptions = {
    [EBackOfficeColumnName.sector]: [
        {
            value: "health",
            label: "health"
        }
    ]
};

export const selectDealShiftOptions = {
    [EBackOfficeColumnName.dealShift]: [
        {
            value: "ENABLED",
            label: "ENABLED"
        },
        {
            value: "DISABLED",
            label: "DISABLED"
        }
    ]
};

export function getSelectNumberOptions(min: number, max: number): ISelectLabelValue[] {
    const selectLabelValues = [];
    for (let i = min; i < max; i++) {
        selectLabelValues.push({
            value: i,
            label: `${i}`
        });
    }
    return selectLabelValues;
}
