import { IReduxAction } from "../store.type";
import NotificationContractTypes from "../type/notificationContract.type";
import {
    fetchNotificationContracts,
    fetchInstitutionHasNotificationContracts,
    insertNotificationContract,
    updateNotificationContract,
    deleteNotificationContract,
    insertInstitutionHasNotificationContract,
    deleteInstitutionHasNotificationContract
} from "../../service/notificationContract.service";
import { INotificationContract, INormalizedNotificationContracts } from "../../../Model/NotificationContract";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchNotificationContractsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const notificationContracts = await fetchNotificationContracts(query);
            dispatch({
                type: NotificationContractTypes.FETCH_NOTIFICATIONCONTRACTS,
                payload: {
                    byId: notificationContracts.reduce(
                        (
                            p: INormalizedNotificationContracts,
                            c: INotificationContract
                        ): INormalizedNotificationContracts => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: notificationContracts.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionHasNotificationContractsAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<any> => {
        try {
            const InstitutionHasNotificationContracts = await fetchInstitutionHasNotificationContracts(query);
            dispatch({
                type: NotificationContractTypes.FETCH_INSTITUTION_HAS_NOTIFICATIONCONTRACTS
            });
            return InstitutionHasNotificationContracts;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertNotificationContractAction(
    notificationContract: INotificationContract
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<number> => {
        try {
            const newNotificationContract = await insertNotificationContract(notificationContract);
            dispatch({
                type: NotificationContractTypes.INSERT_NOTIFICATIONCONTRACT,
                payload: newNotificationContract
            });
            return newNotificationContract.id;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasNotificationContractAction(
    idNotificationContract: number,
    idInstitution: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        insertInstitutionHasNotificationContract(idNotificationContract, idInstitution);
        dispatch({
            type: NotificationContractTypes.INSERT_INSTITUTION_HAS_NOTIFICATION_CONTRACT,
            payload: {
                idNotificationContract,
                idInstitution
            }
        });
        return;
    };
}

export function updateNotificationContractAction(
    id: number,
    notificationContract: INotificationContract
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedNotificationContract = await updateNotificationContract(id, notificationContract);
            dispatch({
                type: NotificationContractTypes.UPDATE_NOTIFICATIONCONTRACT,
                payload: updatedNotificationContract
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteNotificationContractAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteNotificationContract(id);
            dispatch({
                type: NotificationContractTypes.DELETE_NOTIFICATIONCONTRACT
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionHasNotificationContractAction(
    idInstitutionHasNotificationContract: number,
    idNotificationContract: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionHasNotificationContract(idInstitutionHasNotificationContract);
            dispatch({
                type: NotificationContractTypes.DELETE_INSTITUTION_HAS_NOTIFICATIONCONTRACT,
                payload: idNotificationContract
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setNotificationContractsAction(
    dict: INormalizedNotificationContracts
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_NOTIFICATIONCONTRACTS,
            payload: dict
        });
    };
}

export function setSelectedNotificationContractAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_SELECTED_NOTIFICATIONCONTRACT,
            payload: id
        });
    };
}

export function setSelectedNotificationContractMultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_SELECTED_NOTIFICATIONCONTRACT_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingNotificationContractAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_IS_LOADING_NOTIFICATIONCONTRACT,
            payload: isLoading
        });
    };
}
export function setNotificationContractOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_NOTIFICATIONCONTRACT_ORDER,
            payload: idsSorted
        });
    };
}

export function setNotificationContractErrorCodeAction(
    errorCode: number
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationContractTypes.SET_NOTIFICATIONCONTRACT_ERROR_CODE,
            payload: errorCode
        });
    };
}
