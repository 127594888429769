//@ts-ignore
import * as clone from "clone";
import {
    AdminColumns,
    AdminV2Columns,
    FormAdminColumns,
    FormCategoryAdminV2Columns,
    IBackOfficeColumn,
    InstitutionColumns,
    InstitutionOptionColumns,
    InstitutionGroupColumns,
    ScheduleColumns,
    InfoOnWorkerColumns,
    InstitutionChronosColumns,
    FormInfoOnWorkerColumns,
    JobColumns,
    SpecialtyColumns,
    MissionMotifCreationColumns,
    ServiceColumns,
    FormJobColumns,
    FormSpecialtyColumns,
    FormMissionMotifCreationColumns,
    InterimAgencyColumns,
    SalaryGridColumns,
    InstitutionOctimeColumns,
    FormSalaryGridColumns,
    TemplateColumns,
    FormTemplateColumns,
    SpecialHourColumns,
    NotificationContractColumns,
    FormServiceColumns,
    CounterStatusColumns,
    CounterLimitColumns,
    PayrollCodeColumns,
    PublicHolidayColumns,
    PayrollCodeFormColumns,
    FormInterimAgencyColumns,
    FormSpecialHourColumns,
    EBackOfficeColumnName,
    InstitutionInfoColumns,
    InstitutionOptionContractColumns,
    InstitutionOptionChurnColumns,
    InstitutionOptionMissionColumns,
    InstitutionOptionWorkerColumns,
    FormInstitutionOptionColumns,
    FormInstitutionColumns,
    FormInstitutionOptionMissionColumns,
    FormInstitutionOptionWorkerColumns,
    FormInstitutionOptionContractColumns,
    IFormCategoryColumns
} from "./Column.constants";

import TableAdminComponent from "../container/table/TableAdmin.container";
import TableAdminV2Component from "../container/table/TableAdminV2.container";
import TableInstitutionContainer from "../container/table/TableInstitution.container";
import FormInstitutionContainer from "../container/form/FormInstitution.container";
import FormInstitutionOptionMissionContainer from "../container/form/FormInstitutionOptionMission.container";
import TableInstitutionInfoContainer from "../container/table/TableInstitutionInfo.container";
import TableInstitutionOptionContractContainer from "../container/table/TableInstitutionOptionContract.container";
import TableInstitutionOptionChurnContainer from "../container/table/TableInstitutionOptionChurn.container";
import TableInstitutionOptionMissionContainer from "../container/table/TableInstitutionOptionMission.container";
import TableInstitutionOptionWorkerContainer from "../container/table/TableInstitutionOptionWorker.container";
import TableInstitutionOptionContainer from "../container/table/TableInstitutionOption.container";
import TableInstitutionGroupContainer from "../container/table/TableInstitutionGroup.container";
import TableScheduleComponent from "../container/table/TableSchedule.container";
import TableInfoOnWorkerComponent from "../container/table/TableInfoOnWorker.container";
import TableInstitutionChronosContainer from "../container/table/TableInstitutionChronos.container";
import TableInstitutionOctimeComponent from "../container/table/TableInstitutionOctime.container";
import FormAdminContainer from "../container/form/FormAdmin.container";
import FormAdminV2Container from "../container/form/FormAdminV2.container";
import FormServiceContainer from "../container/form/FormService.container";
import FormScheduleContainer from "../container/form/FormSchedule.container";
import FormInfoOnWorkerContainer from "../container/form/FormInfoOnWorker.container";
import TableJobContainer from "../container/table/TableJob.container";
import FormJobContainer from "../container/form/FormJob.container";
import TableServiceContainer from "../container/table/TableService.container";
import TableSpecialtyContainer from "../container/table/TableSpecialty.container";
import FormSpecialtyContainer from "../container/form/FormSpecialty.container";
import TableMotifContainer from "../container/table/TableMotif.container";
import FormMotifContainer from "../container/form/FormMotif.container";
import TableInterimAgencyContainer from "../container/table/TableInterimAgency.container";
import TableSalaryGridContainer from "../container/table/TableSalaryGrid.container";
import FormSalaryGridContainer from "../container/form/FormSalaryGrid.container";
import TableTemplateContainer from "../container/table/TableTemplate.container";
import FormInterimAgencyContainer from "../container/form/FormInterimAgency.container";
import TableSpecialHourContainer from "../container/table/TableSpecialHour.container";
import FormTemplateContainer from "../container/form/FormTemplate.container";
import FormSpecialHourContainer from "../container/form/FormSpecialHour.container";
import TableNotificationContractContainer from "../container/table/TableNotificationContract.container";
import FormNotificationContractContainer from "../container/form/FormNotificationContract.container";
import TableCounterStatusContainer from "../container/table/TableCounterStatus.container";
import TableCounterLimitContainer from "../container/table/TableCounterLimit.container";
import { generateScheduleCSVTemplate } from "../modules/csv/templates";
import TablePayrollCodeContainer from "../container/table/TablePayrollCode.container";
import FormPayrollCodeContainer from "../container/form/FormPayrollCode.container";
import TablePublicHoliday from "../container/table/TablePublicHoliday.container";
import FormPublicHoliday from "../container/form/FormPublicHoliday.container";
import ExportBanner from "../components/table/ExportBanner.component";
import React from "react";
import { getScheduleColNamesForExport, importSanitizedScheduleFromJSON } from "../modules/csv/schedule";
import { SsoConfigContainer } from "../container/sso/SsoConfigContainer";
import FormInstitutionOptionContainer from "../container/form/FormInstitutionOption.container";
import FormInstitutionOptionWorkerContainer from "../container/form/FormInstitutionOptionWorker.container";
import FormInstitutionOptionContractContainer from "../container/form/FormInstitutionOptionContract.container";
import TableShiftSummaryTemplateContainer from "../container/table/TableShiftSummaryTemplate.container";
import AdditionalInformationAdminV2 from "../components/table/AdditionalInformationAdminV2.component";
import CustomInputsAdminV2 from "../components/detail/CustomInputsAdminV2.component";

/**
 * Table
 */
export enum EBackOfficeTableName {
    job = "job",
    institution = "institution",
    institutionOptionContract = "institutionOptionContract",
    institutionOptionChurn = "institutionOptionChurn",
    institutionOptionMission = "institutionOptionMission",
    institutionOptionWorker = "institutionOptionWorker",
    institutionOption = "institutionOption",
    institutionGroup = "institutiongroup",
    schedule = "schedule",
    service = "service",
    specialty = "specialty",
    missionMotifCreation = "missionMotifCreation",
    admin = "admin",
    adminV2 = "adminV2",
    infoOnWorker = "infoOnWorker",
    notification = "notification",
    contract = "contract",
    salaryGrid = "salaryGrid",
    institutionChronos = "institutionChronos",
    gtaOctime = "gtaOctime",
    interim = "interim",
    specialHour = "specialHour",
    counterStatus = "counterStatus",
    counterLimit = "counterLimit",
    exportConfiguration = "exportConfiguration",
    publicholidayConfiguration = "publicholidayConfiguration",
    institutionInfo = "institutionInfo",
    shiftSummaryTemplate = "shiftSummaryTemplate"
}
export enum EBackOfficeTablesKey {
    jobs = "jobs",
    institutions = "institutions",
    institutionGroups = "institutionGroups",
    schedules = "schedules",
    services = "services",
    specialtys = "specialtys",
    missionMotifCreations = "missionMotifCreations",
    admins = "admins",
    infoOnWorkers = "infoOnWorkers",
    notifications = "notifications",
    contracts = "contracts",
    salaryGrids = "salaryGrids",
    institutionChronos = "institutionChronos",
    gtasOctime = "gtasOctime",
    interims = "interims",
    specialHours = "specialHours",
    counterStatus = "counterStatus",
    counterLimit = "counterLimit",
    shiftSummaryTemplate = "shiftSummaryTemplate"
}

export enum EBackOfficeTableSubtitle {
    chooseTypeOfCounterForYourInstitution = "chooseTypeOfCounterForYourInstitution",
    onceLimitReachedWorkersWontBeAlerted = "onceLimitReachedWorkersWontBeAlerted",
    exports = "exports"
}

export enum EBackOfficeTableTitle {
    exportConfiguration = "exportConfiguration",
    institutionOptionChurn = "institutionOptionChurn",
    institutionOptionContract = "institutionOptionContract",
    institutionOptionMission = "institutionOptionMission",
    institutionOptionWorker = "institutionOptionWorker",
    institutionInfo = "institutionInfo"
}

export enum EBackOfficeTableTabs {
    payCode = "payCode",
    publicHolidays = "publicHolidays"
}

export enum EFormViewStyle {
    FormView = "FormView",
    FormCategoryView = "FormCategoryView"
}
export interface IBackOfficeTable {
    name: EBackOfficeTableName[];
    columns: IBackOfficeColumn[][];
    canAddNewLine: boolean;
    canImportCSV: boolean;
    canExportCSVTemplate: boolean;
    generateTableCSVTemplate: () => string[][];
    importSanitizedTableFromJSON: (result: { data: any }, IDInstitutions: number[]) => Promise<any>;
    getTableColNamesForExport: (cols: any[]) => any[];
    baseColumnsForExport: string[];
    columnsInterfaceModuleForExport: string[];
    canModifyLine: boolean;
    canSearch: boolean;
    tableComponent: any[];
    customComponent: any[];
    formComponent: any[];
    maximumOfElementsToBeDisplay: number;
    formColumns?: IBackOfficeColumn[][];
    formCategoriesColumns?: IFormCategoryColumns[][];
    subtitle: string;
    isNameDisplayed: boolean;
    isSubtitleDisplayed: boolean;
    hasDeleteButton: boolean;
    hasPagination?: boolean;
    title?: string;
    tabs?: string[];
    upperBannerComponent?: React.ComponentType;
    isFullWidth?: boolean;
    defaultSortField?: EBackOfficeColumnName;
    canAddOnlyOneLine: boolean;
    hasDetailsPage: boolean;
    formStyle: EFormViewStyle;
    pointerOnHover?: boolean;
    customInputs: any;
    additionalInformation?: React.FC;
}

const DefaultValueTable = {
    maximumOfElementsToBeDisplay: 30,
    canAddNewLine: true,
    canModifyLine: true,
    canSearch: false,
    isNameDisplayed: false,
    isSubtitleDisplayed: false,
    subtitle: null,
    hasDeleteButton: true,
    canImportCSV: false,
    canExportCSVTemplate: false,
    hasPagination: true,
    isFullWidth: false,
    generateTableCSVTemplate: (): string[][] => {
        return [[]];
    },
    importSanitizedTableFromJSON: (result: { data: any }): void => {
        result as unknown;
    },
    getTableColNamesForExport: (cols: any[]): any[] => {
        return cols;
    },
    baseColumnsForExport: [],
    columnsInterfaceModuleForExport: [],
    canAddOnlyOneLine: false,
    hasDetailsPage: false,
    formStyle: EFormViewStyle.FormView,
    formComponent: []
};

export const InstitutionTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institution],
    columns: [InstitutionColumns],
    tableComponent: [TableInstitutionContainer],
    formComponent: [FormInstitutionContainer],
    formColumns: [FormInstitutionColumns],
    canAddNewLine: false,
    canModifyLine: true,
    hasPagination: false,
    hasDeleteButton: false
});

export const InstitutionInfoTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionInfo],
    title: EBackOfficeTableTitle.institutionInfo,
    columns: [InstitutionInfoColumns],
    tableComponent: [TableInstitutionInfoContainer],
    formComponent: [FormAdminContainer],
    canAddNewLine: false,
    canModifyLine: false,
    hasPagination: false
});

export const InstitutionOptionContractTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionOptionContract],
    title: EBackOfficeTableTitle.institutionOptionContract,
    columns: [InstitutionOptionContractColumns],
    tableComponent: [TableInstitutionOptionContractContainer],
    formComponent: [FormInstitutionOptionContractContainer],
    formColumns: [FormInstitutionOptionContractColumns],
    canAddNewLine: false,
    canModifyLine: true,
    hasPagination: false,
    hasDeleteButton: false,
    canAddOnlyOneLine: true
});

export const InstitutionOptionChurnTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionOptionChurn],
    title: EBackOfficeTableTitle.institutionOptionChurn,
    columns: [InstitutionOptionChurnColumns],
    tableComponent: [TableInstitutionOptionChurnContainer],
    formComponent: [FormAdminContainer],
    canAddNewLine: false,
    canModifyLine: false,
    hasPagination: false
});

export const InstitutionOptionMissionTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionOptionMission],
    title: EBackOfficeTableTitle.institutionOptionMission,
    columns: [InstitutionOptionMissionColumns],
    tableComponent: [TableInstitutionOptionMissionContainer],
    formComponent: [FormInstitutionOptionMissionContainer],
    formColumns: [FormInstitutionOptionMissionColumns],
    canAddNewLine: false,
    canModifyLine: true,
    hasPagination: false,
    hasDeleteButton: false,
    canAddOnlyOneLine: true
});

export const InstitutionOptionWorkerTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionOptionWorker],
    title: EBackOfficeTableTitle.institutionOptionWorker,
    columns: [InstitutionOptionWorkerColumns],
    tableComponent: [TableInstitutionOptionWorkerContainer],
    formComponent: [FormInstitutionOptionWorkerContainer],
    formColumns: [FormInstitutionOptionWorkerColumns],
    canAddNewLine: false,
    canModifyLine: true,
    hasPagination: false,
    hasDeleteButton: false,
    canAddOnlyOneLine: true
});

export const InstitutionOptionTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionOption],
    columns: [InstitutionOptionColumns],
    tableComponent: [TableInstitutionOptionContainer],
    formComponent: [FormInstitutionOptionContainer],
    formColumns: [FormInstitutionOptionColumns],
    canAddNewLine: false,
    canModifyLine: true,
    hasDeleteButton: false
});

export const InstitutionChronosTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionChronos],
    columns: [InstitutionChronosColumns],
    tableComponent: [TableInstitutionChronosContainer],
    formComponent: [FormScheduleContainer],
    baseColumnsForExport: ["url", "isProxyRequired", "token", "password", "hasSecurityModule"],
    canAddNewLine: false,
    canModifyLine: false
});

export const InstitutionGroupTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.institutionGroup],
    columns: [InstitutionGroupColumns],
    tableComponent: [TableInstitutionGroupContainer],
    formComponent: [FormAdminContainer],
    canAddNewLine: false,
    canModifyLine: false
});

export const ScheduleTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.schedule],
    columns: [ScheduleColumns],
    tableComponent: [TableScheduleComponent],
    formComponent: [FormScheduleContainer],
    canImportCSV: true,
    canExportCSVTemplate: true,
    generateTableCSVTemplate: generateScheduleCSVTemplate,
    importSanitizedTableFromJSON: importSanitizedScheduleFromJSON,
    getTableColNamesForExport: getScheduleColNamesForExport,
    baseColumnsForExport: ["code", "beginAt", "endAt", "break", "nbWorkedMinutes"],
    columnsInterfaceModuleForExport: ["interfacingCode"],
    canSearch: true
});

export const InfoOnWorkerTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.infoOnWorker],
    columns: [InfoOnWorkerColumns],
    tableComponent: [TableInfoOnWorkerComponent],
    formComponent: [FormInfoOnWorkerContainer],
    formColumns: [FormInfoOnWorkerColumns],
    baseColumnsForExport: ["name", "interfacingCode"],
    canModifyLine: false,
    canSearch: true,
    defaultSortField: EBackOfficeColumnName.name
});

export const InstitutionOctimeTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.gtaOctime],
    columns: [InstitutionOctimeColumns],
    tableComponent: [TableInstitutionOctimeComponent],
    formComponent: [FormInfoOnWorkerContainer],
    formColumns: [FormInfoOnWorkerColumns],
    baseColumnsForExport: [
        "isProxyRequired",
        "url",
        "codeAPI",
        "user",
        "password",
        "loginKey",
        "isVersionGreaterOrEqualTo92",
        "clientName",
        "societe",
        "webrest",
        "codeSociete",
        "codeEtablissement",
        "codeService",
        "interfacingCode"
    ],
    canAddNewLine: false,
    canModifyLine: false
});

export const JobTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.job],
    columns: [JobColumns],
    tableComponent: [TableJobContainer],
    formComponent: [FormJobContainer],
    formColumns: [FormJobColumns],
    canModifyLine: false,
    canSearch: true,
    defaultSortField: EBackOfficeColumnName.name
});

export const SpecialtyTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.specialty],
    columns: [SpecialtyColumns],
    tableComponent: [TableSpecialtyContainer],
    formComponent: [FormSpecialtyContainer],
    formColumns: [FormSpecialtyColumns],
    canModifyLine: false,
    canSearch: true,
    defaultSortField: EBackOfficeColumnName.name
});

export const MissionMotifCreationTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.missionMotifCreation],
    columns: [MissionMotifCreationColumns],
    tableComponent: [TableMotifContainer],
    formComponent: [FormMotifContainer],
    formColumns: [FormMissionMotifCreationColumns],
    baseColumnsForExport: ["name", "isNameAbsentPersonRequired", "nameAbsentPersonPlaceholderInfo", "interfacingCode"],
    canModifyLine: false,
    canAddNewLine: false,
    canSearch: true
});

export const AdminTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.admin],
    columns: [AdminColumns],
    formColumns: [FormAdminColumns],
    tableComponent: [TableAdminComponent],
    formComponent: [FormAdminContainer],
    baseColumnsForExport: [
        "email",
        "firstName",
        "lastName",
        "mobilePhone",
        "level",
        "contractLevel",
        "isPlanningAllowed",
        "isStatisticAllowed",
        "service",
        "typeAdmin"
    ],
    canSearch: true,
    hasDeleteButton: false
});

export const AdminV2Table: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.adminV2],
    columns: [AdminV2Columns],
    formCategoriesColumns: [FormCategoryAdminV2Columns],
    tableComponent: [TableAdminV2Component],
    formComponent: [FormAdminV2Container],
    canSearch: true,
    canModifyLine: false,
    hasDetailsPage: true,
    formStyle: EFormViewStyle.FormCategoryView,
    pointerOnHover: true,
    additionalInformation: AdditionalInformationAdminV2,
    customInputs: CustomInputsAdminV2
});

export const ServiceTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.service],
    columns: [ServiceColumns],
    tableComponent: [TableServiceContainer],
    formComponent: [FormServiceContainer],
    formColumns: [FormServiceColumns],
    hasDeleteButton: false,
    baseColumnsForExport: [
        "name",
        "nameSMS",
        "code",
        "pole",
        "interfacingCode",
        "interfacingCode2",
        "interfacingCode3",
        "phone",
        "bedsCapacity",
        "building",
        "floor",
        "hasFood",
        "hasSmock",
        "minCaregiversPerShift",
        "parking",
        "software",
        "digicode"
    ],
    columnsInterfaceModuleForExport: [],
    canSearch: true
});

export const InterimAgencyTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.interim],
    columns: [InterimAgencyColumns],
    formColumns: [FormInterimAgencyColumns],
    tableComponent: [TableInterimAgencyContainer],
    formComponent: [FormInterimAgencyContainer],
    formStyle: EFormViewStyle.FormCategoryView,
    baseColumnsForExport: ["name", "email", "nbMinutesAfterLaunchingRequest", "hasAutoValidation"],
    hasDeleteButton: true
});

export const SalaryGridTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.salaryGrid],
    columns: [SalaryGridColumns],
    tableComponent: [TableSalaryGridContainer],
    formComponent: [FormSalaryGridContainer],
    formColumns: [FormSalaryGridColumns],
    baseColumnsForExport: ["name"],
    canAddNewLine: false,
    canModifyLine: false,
    hasDeleteButton: true,
    hasPagination: false
});

export const TemplateTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.contract],
    columns: [TemplateColumns],
    tableComponent: [TableTemplateContainer],
    formComponent: [FormTemplateContainer],
    baseColumnsForExport: ["name", "job", "specialty", "isFullTime"],
    formColumns: [FormTemplateColumns]
});

export const SpecialHourTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.specialHour],
    columns: [SpecialHourColumns],
    tableComponent: [TableSpecialHourContainer],
    formComponent: [FormSpecialHourContainer],
    baseColumnsForExport: [
        "name",
        "isDayMission",
        "isPublicHoliday",
        "isSunday",
        "seniorityShouldBeLessThan",
        "seniorityShouldBeMoreThan",
        "service",
        "job"
    ],
    formColumns: [FormSpecialHourColumns]
});

export const NotificationContractTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.notification],
    columns: [NotificationContractColumns],
    tableComponent: [TableNotificationContractContainer],
    formComponent: [FormNotificationContractContainer],
    baseColumnsForExport: ["recapType", "to", "cc", "bcc"],
    hasDeleteButton: false
});

export const PayrollExportTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.exportConfiguration, EBackOfficeTableName.publicholidayConfiguration],
    tableComponent: [TablePayrollCodeContainer, TablePublicHoliday],
    formComponent: [FormPayrollCodeContainer, FormPublicHoliday],
    formColumns: [PayrollCodeFormColumns, PublicHolidayColumns],
    columns: [PayrollCodeColumns, PublicHolidayColumns],
    title: EBackOfficeTableTitle.exportConfiguration,
    tabs: [EBackOfficeTableTabs.payCode, EBackOfficeTableTabs.publicHolidays],
    canSearch: true,
    upperBannerComponent: ExportBanner
});

export const SsoTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.notification],
    tableComponent: [SsoConfigContainer],
    canExportCSVTemplate: false
});

export const CounterStatusTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.counterStatus],
    title: EBackOfficeTableName.counterStatus,
    columns: [CounterStatusColumns],
    elementsKey: EBackOfficeTablesKey.counterStatus,
    tableComponent: [TableCounterStatusContainer],
    canAddNewLine: false,
    subtitle: [EBackOfficeTableSubtitle.chooseTypeOfCounterForYourInstitution],
    isNameDisplayed: false,
    isSubtitleDisplayed: true,
    canModifyLine: true,
    canSearch: false,
    hasDeleteButton: false,
    canImportCSV: false,
    canExportCSVTemplate: false,
    hasPagination: false,
    isFullWidth: true
});

export const CounterLimitTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.counterLimit],
    title: EBackOfficeTableName.counterLimit,
    columns: [CounterLimitColumns],
    elementsKey: EBackOfficeTablesKey.counterLimit,
    tableComponent: [TableCounterLimitContainer],
    canAddNewLine: false,
    subtitle: [EBackOfficeTableSubtitle.onceLimitReachedWorkersWontBeAlerted],
    isNameDisplayed: false,
    isSubtitleDisplayed: true,
    canModifyLine: true,
    canSearch: false,
    hasDeleteButton: false,
    canImportCSV: false,
    canExportCSVTemplate: false,
    hasPagination: false,
    isFullWidth: true
});

export const ShiftSummaryTemplateTable: IBackOfficeTable = Object.assign(clone(DefaultValueTable), {
    name: [EBackOfficeTableName.shiftSummaryTemplate],
    title: EBackOfficeTableName.shiftSummaryTemplate,
    elementsKey: EBackOfficeTablesKey.shiftSummaryTemplate,
    tableComponent: [TableShiftSummaryTemplateContainer],
    canAddNewLine: false,
    subtitle: null,
    isNameDisplayed: false,
    isSubtitleDisplayed: true,
    canModifyLine: true,
    canSearch: false,
    hasDeleteButton: false,
    canImportCSV: false,
    canExportCSVTemplate: false,
    hasPagination: false,
    isFullWidth: true
});

export const BackOfficeTables: IBackOfficeTable[] = [
    InstitutionTable,
    AdminTable,
    InstitutionGroupTable,
    InfoOnWorkerTable,
    JobTable,
    ServiceTable,
    SpecialtyTable,
    MissionMotifCreationTable,
    InterimAgencyTable,
    SalaryGridTable,
    SpecialHourTable,
    CounterStatusTable,
    CounterLimitTable,
    PayrollExportTable,
    ShiftSummaryTemplateTable
];

export const DEACTIVATE_STRING = " deactivated";
