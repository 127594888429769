import { IExport } from "../../Model/Export";
import { PossibleFetchs, PossiblePosts, PossibleApis, PossibleDeletes } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { Moment } from "moment";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const ExportDefaultSelect = ["id"];

export async function fetchExports(query: IQueryParams): Promise<IExport[]> {
    const exports = await postFetchFactory<IExport>(PossibleFetchs.export, PossibleApis.hubloExport)(
        {
            select: ExportDefaultSelect
        },
        query
    );
    return exports;
}

export async function fetchExportsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IExport>(PossibleFetchs.export, PossibleApis.hubloExport)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertExport(newExport: IExport): Promise<IExport> {
    return await postFactory<IExport>(PossiblePosts.export, PossibleApis.hubloExport)(newExport);
}

export async function insertExportLogOnExport(
    beginAt: Moment,
    endAt: Moment,
    idAdmin: number,
    idInstitution: number,
    exportedAt: Moment,
    filename: string
): Promise<IExport> {
    return await postFactory<IExport>(
        PossiblePosts.export,
        PossibleApis.hubloExport
    )({
        id: -1,
        beginAt: beginAt.unix(),
        endAt: endAt.unix(),
        admin: idAdmin,
        exportedAt: exportedAt.unix(),
        filename: filename,
        institution: idInstitution
    });
}

export async function deleteExport(id: number): Promise<IExport> {
    return await deleteFactory<IExport>(PossibleDeletes.export, PossibleApis.hubloExport)(id);
}
