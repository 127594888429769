import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionOctimeTypes from "../type/institutionOctime.type";
import { IInstitutionOctime } from "../../../Model/InstitutionOctime";

const initialState: IReduxInitialState<IInstitutionOctime> = {
    byId: {},
    ids: [],
    selectedId: -1,
    errorCode: -1,
    selectedMulti: [],
    isLoading: false
};

export default function institutionOctimeReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOctimeTypes.FETCH_INSTITUTIONOCTIMES:
        case InstitutionOctimeTypes.SET_INSTITUTIONOCTIMES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case InstitutionOctimeTypes.SET_SELECTED_INSTITUTIONOCTIME: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case InstitutionOctimeTypes.SET_SELECTED_INSTITUTIONOCTIME_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case InstitutionOctimeTypes.SET_IS_LOADING_INSTITUTIONOCTIME: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case InstitutionOctimeTypes.SET_INSTITUTIONOCTIME_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case InstitutionOctimeTypes.SET_INSTITUTIONOCTIME_ERROR_CODE: {
            return {
                ...state,
                errorCode: action.payload
            };
        }

        default:
            return state;
    }
}
