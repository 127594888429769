import { connect } from "react-redux";
import { insertInstitutionHasSpecialtyAction, fetchAllSpecialtiesAction } from "../../store/action/specialty.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { INormalizedSpecialties, ICreateSpecialtyRequest } from "../../../Model/Specialty";
import { IQueryParams } from "npm-medgo-query";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { INormalizedJobs } from "../../../Model/Job";
import { fetchAllJobsAction } from "../../store/action/job.action";

interface IReduxPropsFormSpecialty extends IReduxPropsForm<{ specialty: { label: string; value: number } }> {
    idAllSpecialties: number[];
    idAllJobs: number[];
    jobDict: INormalizedJobs;
    allSpecialtyDict: INormalizedSpecialties;
    specialties: any;
    idInstitutions: number[];
    selectedMulti: number[];
}

interface IPropsFormSpecialty
    extends IReduxPropsFormSpecialty,
        IReduxActionsForm,
        IPropsForm<{ specialty: { label: string; value: number } }> {}

interface IReduxActionsForm {
    insertInstitutionHasSpecialtyAction: (createSpecialtyRequest: ICreateSpecialtyRequest) => any;
    fetchAllSpecialtiesAction: (param: IQueryParams, jobDict: INormalizedJobs) => any;
    fetchAllJobsAction: (param: IQueryParams) => any;
}

class FormSpecialtyComponent extends FormComponent<
    { specialty: { label: string; value: number } },
    IPropsFormSpecialty
> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllJobs.length === 0) await this.props.fetchAllJobsAction({ limit: 99999 });
        if (this.props.idAllSpecialties.length === 0)
            await this.props.fetchAllSpecialtiesAction(
                { limit: 99999, orderby: [{ columnName: "job", orientation: "ASC" }] },
                this.props.jobDict
            );
    }
    async createElementAction(): Promise<void> {
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formDictionarySpecialtys = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.specialty,
            {
                specialty: {}
            }
        );
        const formKeys = Object.keys(formDictionarySpecialtys);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionarySpecialty = formDictionarySpecialtys[key];
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await Promise.all(
                                    formDictionarySpecialty.specialty.map(
                                        async (specialty: number): Promise<void> => {
                                            await this.props.insertInstitutionHasSpecialtyAction({
                                                institution: idInstitution,
                                                specialty: specialty,
                                                name: this.props.allSpecialtyDict[specialty].name
                                            });
                                        }
                                    )
                                );
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.specialty]: {
                [EBackOfficeColumnName.specialty]: getElementsForSelectComponent(
                    this.props.specialties,
                    this.props.selectedMulti.length > 0 ? this.props.selectedMulti : this.props.idInstitutions,
                    true,
                    false,
                    "name"
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<ICreateSpecialtyRequest> =>
        Object.assign(getConnectParentState(centralState), {
            specialties: centralState.specialties,
            idAllSpecialties: centralState.specialties.allIds,
            allSpecialtyDict: centralState.specialties.allById,
            idInstitutions: centralState.institutions.ids,
            selectedMulti: centralState.institutions.selectedMulti,
            jobDict: centralState.jobs.allById,
            idAllJobs: centralState.jobs.allIds
        }),
    Object.assign(getConnectParentActions(), {
        insertInstitutionHasSpecialtyAction,
        fetchAllSpecialtiesAction,
        fetchAllJobsAction
    })
)(FormSpecialtyComponent);
