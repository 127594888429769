import React, { Component } from "react";
import { IAdmin } from "../../../Model/Admin";
import FormView from "./Form.view";
import FormCategoryView from "./FormCategory.view";
import { IBackOfficeTable, EBackOfficeTableName, EFormViewStyle } from "../../constants/Table.constants";
import { setNotificationMessage } from "../../store/action/notification.action";
import { setFormDictionaryAction, setFormIsLoadingAction } from "../../store/action/form.action";
import { getModelsFromDict, getFormDictionaryRecordsDict } from "../../modules/store/util";
import { IBackOfficeColumn, EBackOfficeColumnType, EBackOfficeColumnName } from "../../constants/Column.constants";
import { IFormDictionary } from "../../store/type/form.type";
import { ISelectLabelValue } from "npm-medgo-components";
import {
    setFormStatusAction,
    setResetFormAction,
    setErrorMessageAction,
    setNumberFormsAction,
    setShowCreateOrModifyModalAction,
    setShowConfirmationModalAction,
    setIsCreationOrUpdateLoadingAction
} from "../../store/action/form.action";
import { setModifyingElementAction } from "../../store/action/table.action";
import { getElementsInInstitution } from "../../../toolbox/filters";
import { valueForm } from "../../type/form.type";
import { isNullOrUndefined } from "util";
import { IConfirmationModalData } from "../../components/Modals/Shared/Confirmation.modal";
import CreateOrModifyElement from "../../components/Modals/Shared/CreateOrModifyElement.modal";
import ElementDetails from "../../components/detail/ElementDetails.component";
import { getFormColumns } from "../../../toolbox/column/ColumnsFunction";
import { INormalizedInstitutions } from "../../../Model/Institution";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { displayCriteria } from "../../../toolbox/permissions";

export interface IReduxPropsForm<T> {
    me: IAdmin;
    lang: any;
    currentLang: string;
    idSelectedMultiInstitutions: number[];
    idInstitutions: number[];
    formDictionary: IFormDictionary;
    selectOptions?: { [key: string]: ISelectLabelValue[] };
    errorCode: number;
    formStatus: string;
    isVisibleNotification: boolean;
    modifyingElement: any;
    numberForms: number;
    selectedTab: number;
    confirmationModalData: IConfirmationModalData;
    formTable: EBackOfficeTableName;
    idDetailedElement: number;
    institutionsDict: INormalizedInstitutions;
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    idPoles: number[];
}

export interface IReduxActionsForm {
    setNotificationMessage: (errorMessage: any) => void;
    setFormDictionaryAction: (selectedOption: { [key: string]: any }) => void;
    setFormIsLoadingAction: (isLoading: boolean) => void;
    setFormStatusAction: (formStatus: any) => any;
    setResetFormAction: () => void;
    setErrorMessageAction: (object: {
        table: EBackOfficeTableName;
        index: number;
        column: EBackOfficeColumnName;
        message: string;
    }) => void;
    setNumberFormsAction: (numberForms: number) => void;
    setShowCreateOrModifyModalAction: (show: boolean) => void;
    setShowConfirmationModalAction: (show: boolean) => void;
    setModifyingElementAction: (element: any) => void;
    setIsCreationOrUpdateLoadingAction: (isCreationOrUpdateLoading: boolean) => void;
}

export interface IPropsForm<T> extends IReduxPropsForm<T>, IReduxActionsForm {
    byId: { [key: string]: T };
    ids: number[];
    backOfficeTable: IBackOfficeTable;
    selectCheckboxListOptions: () => any;
}

export class FormComponent<X, T extends IPropsForm<X>> extends Component<T> {
    constructor(props: T) {
        super(props);
        this.saveForm = this.saveForm.bind(this);
        this.checkFormDictionary = this.checkFormDictionary.bind(this);
        this.selectCheckboxListOptions = this.selectCheckboxListOptions.bind(this);
        this.customOnChange = this.customOnChange.bind(this);
        this.customSelectCheckboxOnChange = this.customSelectCheckboxOnChange.bind(this);
        this.customCheck = this.customCheck.bind(this);
        this.setErrorMessageFromErrorCode = this.setErrorMessageFromErrorCode.bind(this);
    }

    componentDidUpdate(nextProps: any): void {
        if (nextProps.formStatus !== this.props.formStatus) {
            switch (nextProps.formStatus) {
                case "insert":
                    this.saveForm(this.createElementAction.bind(this));
                    break;
                case "update":
                    this.saveForm(this.updateElementAction.bind(this));
                    break;
                case "backToModal":
                    this.props.setShowConfirmationModalAction(false);
                    this.props.setShowCreateOrModifyModalAction(true);
                    break;
                case "":
                    break;
                default:
                    throw new Error(`${nextProps.formStatus} FORM ACTION NO IMPLEMENTED`);
            }
            this.props.setFormStatusAction("");
        }
    }

    selectCheckboxListOptions(): any {}

    render(): JSX.Element {
        const CustomInputs = this.props.backOfficeTable.customInputs;
        return (
            <>
                {this.props.formTable === this.props.backOfficeTable.name[0] && (
                    <>
                        {this.props.backOfficeTable.hasDetailsPage && this.props.idDetailedElement !== -1 ? (
                            <ElementDetails
                                formView={this.renderFormView()}
                                backOfficeTableName={this.props.backOfficeTable.name[0]}
                                checkFormDictionary={this.checkFormDictionary}
                                customInputs={<CustomInputs backOfficeTable={this.props.backOfficeTable} />}
                                columns={getFormColumns(this.props.backOfficeTable, this.props.selectedTab)}
                            />
                        ) : (
                            <>
                                <CreateOrModifyElement
                                    backOfficeTable={this.props.backOfficeTable}
                                    lang={this.props.lang}
                                    checkFormDictionary={this.checkFormDictionary}
                                    formView={this.renderFormView()}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    }

    renderFormView(): JSX.Element {
        let selectCheckboxListOptions: any;
        if (this.selectCheckboxListOptions) {
            selectCheckboxListOptions = this.selectCheckboxListOptions();
        }
        if (this.props.backOfficeTable.formStyle === EFormViewStyle.FormCategoryView) {
            return (
                <FormCategoryView
                    {...this.props}
                    backOfficeTable={this.props.backOfficeTable}
                    saveForm={this.saveForm}
                    selectCheckboxListOptions={selectCheckboxListOptions}
                    customOnChange={this.customOnChange}
                    checkFormDictionary={this.checkFormDictionary}
                    setErrorMessageFromErrorCode={this.setErrorMessageFromErrorCode}
                    customSelectCheckboxOnChange={this.customSelectCheckboxOnChange}
                />
            );
        }
        return (
            <FormView
                {...this.props}
                backOfficeTable={this.props.backOfficeTable}
                saveForm={this.saveForm}
                selectCheckboxListOptions={selectCheckboxListOptions}
                customOnChange={this.customOnChange}
                checkFormDictionary={this.checkFormDictionary}
                setErrorMessageFromErrorCode={this.setErrorMessageFromErrorCode}
                customSelectCheckboxOnChange={this.customSelectCheckboxOnChange}
            />
        );
    }

    async saveForm(action: () => void): Promise<void> {
        if (this.props.formTable !== this.props.backOfficeTable.name[0]) return;
        this.props.setIsCreationOrUpdateLoadingAction(true);
        this.props.setFormIsLoadingAction(true);
        const hasError = this.checkFormDictionary(true);
        if (hasError) {
            this.props.setIsCreationOrUpdateLoadingAction(false);
            return;
        }
        try {
            await action();
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.errCodeGeneric500,
                icon: "fa fa-times",
                color: "danger"
            });
            this.props.setIsCreationOrUpdateLoadingAction(false);
            return;
        }
        this.props.setShowCreateOrModifyModalAction(false);
        this.props.setShowConfirmationModalAction(false);
        if (this.props.idDetailedElement !== -1) window.location.href = `#${this.props.backOfficeTable.name}`;
        if (!this.props.isVisibleNotification) {
            this.props.setNotificationMessage({
                message: this.notificationMessage(),
                icon: "fa fa-check",
                color: "success"
            });
        }
        this.props.setFormIsLoadingAction(false);
        this.props.setResetFormAction();
        this.props.setNumberFormsAction(1);
        this.props.setModifyingElementAction(null);
        this.props.setIsCreationOrUpdateLoadingAction(false);
    }

    notificationMessage(): string {
        const tableName = this.props.backOfficeTable.name[this.props.selectedTab];
        let message = "";
        if (!isNullOrUndefined(this.props.modifyingElement)) {
            message = this.props.lang[`${tableName}HasBeenUpdated`]
                ? this.props.lang[`${tableName}HasBeenUpdated`]
                : this.props.lang.modifyLine;
            return message;
        }
        const plural = this.props.numberForms > 1 ? "sHaveBeen" : "HasBeen";
        message = this.props.lang[`${tableName}${plural}Created`]
            ? this.props.lang[`${tableName}${plural}Created`]
            : this.props.lang.addLine;
        return message;
    }

    resetSelectInputs(): void {
        const selectInputs = getFormColumns(this.props.backOfficeTable, this.props.selectedTab).filter(
            (e: IBackOfficeColumn): boolean =>
                e.type === EBackOfficeColumnType.select || e.type === EBackOfficeColumnType.selectCheckbox
        );
        if (selectInputs.length > 0) {
            const newFormDictionary: IFormDictionary = Object.assign({}, this.props.formDictionary);
            const newFormDictionaryTable = getFormDictionaryRecordsDict(
                newFormDictionary,
                this.props.backOfficeTable.name[this.props.selectedTab]
            );
            selectInputs.map((input: IBackOfficeColumn): void => {
                if (input.type === EBackOfficeColumnType.select) newFormDictionaryTable[input.name] = null;
                if (input.type === EBackOfficeColumnType.selectCheckbox) newFormDictionaryTable[input.name] = [];
            });
            this.props.setFormDictionaryAction(newFormDictionary);
        }
    }

    setErrorMessageFromErrorCode(err: any, columnName: EBackOfficeColumnName, index: number): void {
        if (!err.errorCode) {
            console.log(err);
            err.errorCode = 500;
        }
        this.props.setFormIsLoadingAction(false);
        const errorCode = err.errorCode;
        this.props.setErrorMessageAction({
            table: this.props.backOfficeTable.name[this.props.selectedTab],
            index,
            column: columnName,
            message: this.props.lang[`errCodeGeneric${errorCode}`]
        });
    }

    checkFormDictionary(isOnSave: boolean): boolean {
        let hasError = false;
        const formDictionaryTables = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            this.props.backOfficeTable.name[this.props.selectedTab]
        );
        const keys = Object.keys(formDictionaryTables);
        if (keys.length === 0) {
            keys.push("0");
        }
        const columns = getFormColumns(this.props.backOfficeTable, this.props.selectedTab).filter(col =>
            displayCriteria(
                col.displayCriteria,
                getModelsFromDict(this.props.institutionsDict, this.props.idInstitutions),
                getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions),
                this.props.modifyingElement ? true : false,
                this.props.idPoles
            )
        );
        keys.forEach((key: any): void => {
            const formDictionaryTable = !isNullOrUndefined(formDictionaryTables[key]) ? formDictionaryTables[key] : {};
            for (let index = 0; index < columns.length; index++) {
                const column = columns[index];
                const value = formDictionaryTable[column.name];
                if (
                    (column.min !== undefined && column.min > value) ||
                    (column.max !== undefined && column.max < value)
                ) {
                    hasError = true;
                    this.setErrorMessageFromErrorCode({ errorCode: 406 }, column.name, key);
                }
                const checks = column.checks;
                for (let i = 0; i < checks.length; i++) {
                    const check = checks[i];
                    try {
                        check(value, formDictionaryTable, isOnSave);
                        this.customCheck(value, column, isOnSave);
                    } catch (err) {
                        this.setErrorMessageFromErrorCode(
                            { errorCode: err.errorCode ? err.errorCode : 400 },
                            column.name,
                            key
                        );
                        hasError = true;
                    }
                }
            }
        });
        return hasError;
    }

    getSelectedIdInstitutions(): number[] {
        return this.props.idSelectedMultiInstitutions && this.props.idSelectedMultiInstitutions.length > 0
            ? this.props.idSelectedMultiInstitutions
            : this.props.idInstitutions;
    }

    async createElementAction(): Promise<void> {
        throw new Error(`createElementAction should be implemented`);
    }

    async updateElementAction(): Promise<void> {
        throw new Error(`updateElementAction should be implemented`);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customOnChange(value: valueForm, column: IBackOfficeColumn, formIndex: number): void {
        //can be implemented in child containers
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customSelectCheckboxOnChange(value: valueForm, column: IBackOfficeColumn, formIndex: number): void {
        //can be implemented in child containers
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customCheck(value: valueForm, column: IBackOfficeColumn, isOnSave?: boolean): void {
        //can be implemented in child containers
    }
}

export function getElementsForSelectComponent(
    categoryState: any,
    idSelectedInstitutions: number[],
    isAdditiveInclusiveFilter: boolean = true,
    isFormated: boolean = true,
    sortKey: string = "label",
    isExcludingExistingElements: boolean = true,
    specialLabel: (element: any) => string = e => e.name
): ISelectLabelValue[] {
    const elements = getModelsFromDict<any>(categoryState.byId, categoryState.ids);
    const allElements = isAdditiveInclusiveFilter
        ? getModelsFromDict<any>(categoryState.allById, categoryState.allIds)
        : elements;
    const existingElements = isExcludingExistingElements
        ? getElementsInInstitution(elements, idSelectedInstitutions).map((e): number => e.id)
        : [];
    const elementsForSelectComponent = isFormated
        ? allElements
              .filter((e): boolean => !existingElements.includes(e.id))
              .map((e): ISelectLabelValue => ({ label: specialLabel(e), value: e.id }))
        : allElements.filter((e): boolean => !existingElements.includes(e.id));
    return elementsForSelectComponent.sort(function(a, b) {
        if (a[sortKey] < b[sortKey]) return -1;
        if (a[sortKey] > b[sortKey]) return 1;
        return 0;
    });
}

export function getConnectParentState(centralState: any): any {
    return {
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        me: centralState.auth.user,
        idSelectedMultiInstitutions: centralState.institutions.selectedMulti,
        formDictionary: centralState.form.formDictionary,
        idInstitutions: centralState.institutions.ids,
        institutionsDict: centralState.institutions.byId,
        formStatus: centralState.form.status,
        isVisibleNotification: centralState.notification.isVisible,
        modifyingElement: centralState.tables.modifyingElement,
        numberForms: centralState.form.numberForms,
        selectedTab: centralState.tables.selectedTab,
        confirmationModalData: centralState.form.confirmationModalData,
        formTable: centralState.form.formTable,
        idDetailedElement: centralState.form.idDetailedElement,
        idInstitutionOptions: centralState.institutionOption.ids,
        institutionOptionDict: centralState.institutionOption.byId,
        idPoles: centralState.poles.ids
    };
}

export function getConnectParentActions(): any {
    return {
        setNotificationMessage,
        setFormDictionaryAction,
        setFormIsLoadingAction,
        setFormStatusAction,
        setResetFormAction,
        setErrorMessageAction,
        setNumberFormsAction,
        setShowCreateOrModifyModalAction,
        setShowConfirmationModalAction,
        setModifyingElementAction,
        setIsCreationOrUpdateLoadingAction
    };
}
