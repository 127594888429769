import { connect } from "react-redux";
import { setMotifsAction, fetchMotifsAction, setIsLoadingMotifAction } from "../../store/action/motif.action";

import { IMotif } from "../../../Model/Motif";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedMotifs } from "../../../Model/Motif";
import { getModelsFromDict } from "../../modules/store/util";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";

interface IReduxPropsMotif extends IReduxPropsTable {
    idMotifs: number[];
    motifDict: INormalizedMotifs;
    searchValue: string;
}

interface IPropsMotif extends IReduxPropsMotif, IReduxActionsMotif, IPropsTable {}

interface IReduxActionsMotif extends IReduxActionsTable {
    setMotifsAction: (elements: IMotif[]) => any;
    fetchMotifsAction: (param: IQueryParams) => any;
    setIsLoadingMotifAction: (isLoading: boolean) => any;
}

class TableMotifContainer extends TableComponent<IPropsMotif> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingMotifAction(true);
        if (this.props.idMotifs.length === 0) await this.props.fetchMotifsAction(params);
        this.props.setIsLoadingMotifAction(false);
    }

    setElements(elements: any): void {
        this.props.setMotifsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<IMotif>(this.props.motifDict, this.props.idMotifs)
            .filter(
                (e): boolean =>
                    !this.props.searchValue ||
                    isIncludedStringCaseInsensitive(e.name, this.props.searchValue) ||
                    isIncludedStringCaseInsensitive(e.nameAbsentPersonPlaceholderInfo, this.props.searchValue) ||
                    isIncludedStringCaseInsensitive(e.interfacingCode, this.props.searchValue)
            )
            .sort((a, b): 1 | -1 | 0 =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            );
    }
}

export default connect(
    (centralState: any): IReduxPropsMotif =>
        Object.assign(getConnectParentState(centralState), {
            idMotifs: centralState.motifs.ids,
            motifDict: centralState.motifs.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.motifs.isLoading,
            searchValue: centralState.tables.searchValue
        }),
    Object.assign(getConnectParentActions(), {
        setMotifsAction,
        fetchMotifsAction,
        setIsLoadingMotifAction
    })
)(TableMotifContainer);
