import { connect } from "react-redux";
import { insertInstitutionHasJobAction, fetchAllJobsAction } from "../../store/action/job.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions,
    getElementsForSelectComponent
} from "./Form.container";
import { ICreateJobRequest, INormalizedJobs } from "../../../Model/Job";
import { IQueryParams } from "npm-medgo-query";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

interface IReduxPropsFormJob extends IReduxPropsForm<{ job: { label: string; value: number } }> {
    idAllJobs: number[];
    allJobDict: INormalizedJobs;
    selectedMulti: number[];
    jobs: any;
}

interface IPropsFormJob
    extends IReduxPropsFormJob,
        IReduxActionsForm,
        IPropsForm<{ job: { label: string; value: number } }> {}
interface IReduxActionsForm {
    insertInstitutionHasJobAction: (createJobRequest: ICreateJobRequest) => void;
    fetchAllJobsAction: (param: IQueryParams) => void;
}
class FormJobComponent extends FormComponent<{ job: { label: string; value: number } }, IPropsFormJob> {
    async componentDidMount(): Promise<void> {
        if (this.props.idAllJobs.length === 0) await this.props.fetchAllJobsAction({ limit: 99999 });
    }
    async createElementAction(): Promise<void> {
        const idSelectedInstitutions = this.getSelectedIdInstitutions();
        const formDictionaryJobs = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.job, {
            job: {}
        });
        const formKeys = Object.keys(formDictionaryJobs);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryJob = formDictionaryJobs[key];
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                if (Array.isArray(formDictionaryJob.job)) {
                                    await Promise.all(
                                        formDictionaryJob.job.map(
                                            async (job: number): Promise<void> => {
                                                await this.props.insertInstitutionHasJobAction({
                                                    institution: idInstitution,
                                                    job: job,
                                                    name: this.props.allJobDict[job].name
                                                });
                                            }
                                        )
                                    );
                                }
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    selectCheckboxListOptions(): any {
        return {
            [EBackOfficeTableName.job]: {
                [EBackOfficeColumnName.job]: getElementsForSelectComponent(
                    this.props.jobs,
                    this.props.selectedMulti.length > 0 ? this.props.selectedMulti : this.props.idInstitutions,
                    true,
                    false,
                    "name"
                )
            }
        };
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<ICreateJobRequest> =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutions: centralState.institutions.ids,
            selectedMulti: centralState.institutions.selectedMulti,
            jobs: centralState.jobs,
            idAllJobs: centralState.jobs.allIds,
            allJobDict: centralState.jobs.allById
        }),
    Object.assign(getConnectParentActions(), {
        insertInstitutionHasJobAction,
        fetchAllJobsAction
    })
)(FormJobComponent);
