import { connect } from "react-redux";
import {
    setInstitutionOptionsAction,
    fetchInstitutionOptionsAction,
    setIsLoadingInstitutionOptionAction
} from "../../store/action/institutionOption.action";
import { IInstitutionOption } from "../../../Model/InstitutionOption";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOption extends IReduxPropsTable {
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    idInstitutions: number[];
}

interface IPropsInstitutionOption extends IReduxPropsInstitutionOption, IReduxActionsInstitutionOption, IPropsTable {}

interface IReduxActionsInstitutionOption extends IReduxActionsTable {
    setInstitutionOptionsAction: (elements: IInstitutionOption[]) => any;
    fetchInstitutionOptionsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOptionAction: (isLoading: boolean) => any;
}

class TableInstitutionOptionContainer extends TableComponent<IPropsInstitutionOption> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOptionAction(true);
        if (this.props.idInstitutionOptions.length === 0) await this.props.fetchInstitutionOptionsAction(params);
        this.props.setIsLoadingInstitutionOptionAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOptionsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOption =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOptions: centralState.institutionOption.ids,
            institutionOptionDict: centralState.institutionOption.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionOptionsAction,
        fetchInstitutionOptionsAction,
        setIsLoadingInstitutionOptionAction
    })
)(TableInstitutionOptionContainer);
