import { ILang } from "./Lang";

/* eslint-disable @typescript-eslint/camelcase */
export const de: ILang = {
    isCvMandatory: "mandatory cv DE",
    isDiplomaMandatory: "mandatory diploma DE",
    hasHubloPool: "is an hublo pool client DE",
    no: "Nein",
    yes: "Ja",
    modifier: "Bearbeiten",
    institution: "Einrichtung",
    institutiongroup: "Gruppe von Einrichtungen",
    schedule: "Zeitcode",
    service: "Station",
    job: "Beruf",
    missionMotifCreation: "Grund für den Bedarf",
    specialty: "Kompetenz",
    admin: "Administrator",
    infoOnWorker: "Mitarbeiterprofil",
    notification: "Benachrichtigung",
    contract: "Vertrag",
    salaryGrid: "Bezahlung",
    institutionChronos: "gâta Chronos",
    gtaOctime: "gta Octime",
    interim: "Mitarbeiterüberlassung",
    specialHour: "spezielle Zeitregelung",
    institutionPlural: "Einrichtungen",
    institutiongroupPlural: "Gruppen von Einrichtungen",
    schedulePlural: "Zeitcodes",
    servicePlural: "Stationen",
    jobPlural: "Berufe",
    missionMotifCreationPlural: "Gründe für den Einsatz",
    specialtyPlural: "Kompetenzen",
    adminPlural: "Administratoren",
    infoOnWorkerPlural: "Mitarbeiterprofile",
    notificationPlural: "Benachrichtigungen",
    contractPlural: "Verträge",
    salaryGridPlural: "Bezahlung",
    institutionChronosPlural: "gta Chronos",
    gtaOctimePlural: "gta Octime",
    interimPlural: "Mitarbeiterüberlassunger",
    specialHourPlural: "spezielle Zeitregelungen",
    institutionNone: "Keine Einrichtung",
    institutiongroupNone: "Keine Einrichtungen",
    scheduleNone: "Kein Zeitcode",
    serviceNone: "Keine Station",
    jobNone: "Kein Beruf",
    missionMotifCreationNone: "Kein Grund",
    specialtyNone: "Keine Kompetenz",
    motifNone: "Kein Grund",
    adminNone: "Kein Administrator",
    infoOnWorkerNone: "Kein zusätzlichen Informationen",
    notificationNone: "Keine Benachrichtigung",
    contractNone: "Kein Vertrag",
    salaryGridNone: "Keine Bezahlung",
    institutionChronosNone: "Kein GTA Chronos",
    gtaOctimeNone: "Kein GTA Octime",
    interimNone: "Keine Mitarbeiterüberlassung",
    specialHourNone: "Keine spezielle Zeitregelung",
    actions: "Aktionen",
    add: "hinzufügen",
    next: "weiter",
    previous: "zurück",
    new: "neu",
    return: "Zurück",
    confirm: "Bestätigen",
    select: "Auswählen",
    date: "Datum",
    action: "Aktion",
    success: "Erfolg",
    here: "hier",
    sign: "unterschreiben",
    mission: "Schicht",
    proMissions: "Meine Schichten",
    proVacataires: "Mein Netzwerk",
    proContracts: "Meine Verträge",
    dashboard: "Auswertung",
    redirectToLocalhost: "Redirect to localhost",
    profile: "Mein Konto",
    myInstitutions: "Meine Einrichtungen",
    myNotifications: "Meine Benachrichtigungen",
    myConfig: "Einstellungen",
    logout: "Abmelden",
    title: "Back office - Hublo",
    addLine: "Zeile erfolgreich hinzugefügt",
    modifyLine: "Zeile erfolgreich geändert",
    errorLine: "Zeile nicht hinzugefügt, weil ein Fehler aufgetreten ist",
    errCodeGeneric520: "Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support",
    errCodeGeneric500: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut",
    errCodeGeneric400: "Bitte überprüfen Sie Ihre Einstellungen",
    errCodeGeneric404: "Die Informationen, die Sie suchen, können nicht gefunden werden",
    errCodeGeneric401: "Bitte anmelden",
    errCodeGeneric403: "Sie sind nicht berechtigt, diese Aktion auszuführen",
    errCodeGeneric409: "Name bereits vorhanden",
    errCodeGeneric406: "Falscher Wert",
    errCodeGeneric4062: "Ungültige Informationen",
    errCodeGeneric419: "Erforderliches Feld",
    errCodeGeneric449: "Administrator bereits erstellt",
    typeHelper: "1 : Standard",
    contractLevelHelper: "1: Hublo, 2: kann Verträge herunterladen, 3: kann keine Verträge herunterladen",
    recapTypeHelper:
        "0: keine Zusammenfassung, 1: tägliche Zusammenfassung der Verträge pro Schicht, 2: monatliche Zusammenfassung der Verträge pro Schicht, 3: DPAE",
    nbMinutesAfterLaunchingRequestHelper:
        "Zeit zwischen Aktivierung der Zeitarbeit und die Anfrage an die Agenturen (in Minuten)",
    seniorityShouldBeLessThanHelper: "Eingeschlossen",
    seniorityShouldBeMoreThanHelper: "Ausgeschlossen",
    interfacingCodeHelper: "Klicken Sie hier, um zu den FAQ weitergeleitet zu werden",
    id: "id",
    name: "Name",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail-Adresse",
    mobilePhone: "Telefonnummer",
    level: "Position",
    contractLevel: "Vertrag",
    isPlanningAllowed: "Planung",
    isStatisticAllowed: "Auswertungen",
    shouldSendEmail: "Senden einer Einladung",
    typeAdmin: "Admin-Typ",
    type: "Art",
    typeName: "Art Name",
    code: "Name",
    "code-form": "Codename",
    codeUF: "code UF",
    beginAt: "Start",
    url: "URL",
    isProxyRequired: "proxy",
    token: "token",
    password: "Kennwort",
    hasSecurityModule: "Sicherheitsmodul",
    endAt: "Ende",
    nbWorkedMinutes: "Arbeitszeit",
    interfacingCode: "Schnittstellen-Code",
    nameSMS: "SMS-Name",
    pole: "Abteilung",
    interfacingCode2: "Schnittstellencode 2",
    interfacingCode3: "Schnittstellencode 3",
    isNameAbsentPersonRequired: "Info erforderlich",
    nameAbsentPersonPlaceholderInfo: "Platzhalter",
    isFullTime: "Vollzeit",
    nbMinutesAfterLaunchingRequest: "Auslösung (Minuten)",
    hasAutoValidation: "automatische Validierung",
    isDayMission: "Tages-Schicht?",
    isPublicHoliday: "Feiertags-Schicht?",
    isSunday: "Schicht am Sonntag?",
    seniorityShouldBeLessThan: "Mindestanforderung an den Ausbildungsgrad",
    seniorityShouldBeMoreThan: "minimaler Ausbildungsgrad erforderlich",
    services: "Stationen",
    jobs: "Berufe",
    codeAPI: "API-Code",
    user: "Benutzer",
    loginKey: "Anmeldeschlüssel",
    isVersionGreaterOrEqualTo92: "version >= 9.2",
    clientName: "Kunde",
    societe: "Firma",
    webrest: "webrest",
    codeSociete: "Code des Unternehmens",
    codeEtablissement: "Einrichtungscode",
    codeService: "Abteilungscode",
    codeInterface: "Schnittstellencode",
    jobName: "Beruf",
    specialtyName: "Kompetenz",
    poleName: "Station",
    recapType: "Zusammenfassung",
    to: "to",
    cc: "cc",
    bcc: "bcc",
    break: "pause",
    "break-form": "unbezahlte Pause",
    searchPlaceholder_admin: "Suche nach einem Administrator mit Name, E-Mail, Telefonnummer",
    searchPlaceholder_adminV2:
        "Suche nach einem Administrator mit Name, E-Mail, Telefonnummer oder Registrierungsnummer",
    searchPlaceholder_schedule: "Suche nach einem Zeitcode, mit einem Name oder einer Arbeitszeit",
    add_schedule: "Einen Zeitcode hinzufügen",
    add_service: "Eine Station hinzufügen",
    add_job: "Einen Beruf hinzufügen",
    add_missionMotifCreation: "Einen Grund hinzufügen",
    add_specialty: "Eine Kompetenz hinzufügen",
    add_admin: "Einen Administrator hinzufügen",
    add_adminV2: "Einen Administrator hinzufügen",
    add_infoOnWorker: "Ein neue Information für Mitarbeiter hinzufügen",
    add_notification: "Eine Benachrichtigung hinzufügen",
    add_salaryGrid: "Ein Bezahlungmuster hinzufügen",
    add_interim: "Eine Mitarbeiterüberlassungs-Agentur hinzufügen",
    add_title_interim: "Mitarbeiterüberlassungs-Agentur hinzufügen",
    add_specialHour: "Eine spezielle Zeitregelung hinzufügen",
    add_contract: "Einen Vertrag hinzufügen",
    modify_schedule: "Bearbeiten eines Zeitcodes",
    modify_service: "Einen Dienst bearbeiten",
    modify_job: "Einen Beruf bearbeiten",
    modify_missionMotifCreation: "Einen Grund bearbeiten",
    modify_specialty: "Eine Kompetenz bearbeiten",
    modify_admin: "Einen Administrator ändern",
    modify_infoOnWorker: "Eine Zusatzinformation für Mitarbeiter bearbeiten",
    modify_notification: "Eine Benachrichtigung bearbeiten",
    modify_salaryGrid: "Eine Bezahlungsart bearbeiten",
    modify_interim: "Mitarbeiterüberlassungsmodul bearbeiten",
    modify_specialHour: "Eine spezielle Zeitregelung bearbeiten",
    modify_contract: "Meine Verträge bearbeiten",
    create: "Erstellen",
    save: "Speichern",
    cancel: "Abbrechen",
    notAllInsertHaveBeenWellDone: "Nicht alle Bearbeitungen waren erfolgreich",
    counter: "Stundenzähler",
    counterStatus: "Stundenzähler",
    counterLimit: "Sperrstundengrenze",
    isActive: "Status",
    whichCounter: "Stundenzähler",
    starter: "Beginn des Zählers",
    counterLimitType: "Obergrenze",
    export: "Exportieren",
    chooseTypeOfCounterForYourInstitution:
        "wählen Sie die Arten von zugänglichen Stundenzählern für Ihre Einrichtung aus.",
    onceLimitReachedWorkersWontBeAlerted:
        "Sobald das Zeitlimit erreicht ist, werden die Mitarbeiter nicht mehr benachrichtigt und können sich nicht mehr bewerben.",
    workerType: "Status Pflegefachkraft",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
    yearly: "Jährlich",
    yearlyBaseOnJoiningDate: "Jährlich - Basierend auf dem Beitrittsdatum",
    weeklyByRollingWeek: "Laufende Woche",
    weeklyH: "Wöchentlich (h)",
    weeklyByRollingWeekH: "Laufende Woche",
    monthlyH: "Monatlich (h)",
    yearlyH: "Jährlich (h)",
    yearlyJoinDateH: "Jährlich/Beitrittsdatum (h)",
    weeklyShifts: "Wöchentlich",
    monthlyShifts: "Monatlich",
    yearlyShifts: "Jährlich",
    yearlyJoinDateShifts: "Jährlich/Beitrittsdatum",
    weeklyStarter: "Jeden __DAY",
    monthlyStarter: "Jeden __DAY des Monats",
    yearlyStarter: "Jeden __DAY",
    yearlyBaseOnJoiningDateStarter: "Dienstalter",
    salarie: "Vollzeit-/Teilzeitkraft",
    vacataire: "Aushilfskraft",
    schedulesHaveBeenCreated: "Ihre Zeitcodes wurden erstellt!",
    scheduleHasBeenCreated: "Ihr Zeitcode wurde erstellt!",
    scheduleHasBeenUpdated: "Ihr Zeitcode wurde aktualisiert!",
    confirmationSubtitle_schedule: "Sind Sie sicher, dass Sie diesen Zeitcode bearbeiten möchten?",
    confirmationText_schedule: `Sie haben derzeit __MISSIONS_COUNT bevorstehende Schichten mit diesem Zeitcode.
        Die Bearbeitung des Zeitcodes hat keine Auswirkungen auf diese Schichten.
        Falls erforderlich, können Sie die Zeitangaben dieser Schichten bearbeiten, sobald diese besetzt sind.`,
    confirmationText_schedule_plural: `Sie haben derzeit __MISSIONS_COUNT bevorstehende Schichten mit diesem Zeitcode.
        Die Bearbeitung des Zeitcodes hat keine Auswirkungen auf diese Schichten.
        Falls erforderlich, können Sie die Zeitangaben dieser Schichten bearbeiten, sobald diese besetzt sind.`,
    yesApply: "Bestätigen",
    noDontApply: "Abbrechen",
    enabled: "Aktiviert",
    disabled: "Deaktiviert",
    deleteConfirmationSubtitle_schedule: "Sind Sie sicher, dass Sie diesen Zeitcode löschen möchten?",
    deleteConfirmationText_schedule:
        "Er werden Ihre bereits mit diesem Zeitcode erstellten Schichten davon nicht beeinflusst.",
    deleteActionCompletedConfirmationMessage_schedule: "Ihr Zeitcode wurde gelöscht!",
    rowsPerPageText: "Zeilen pro Seite",
    rangeSeparatorText: "von",
    areYouSure: "Sind Sie sicher?",
    deleted: "Gelöscht!",
    noResult: "Kein Ergebnis",
    exportMissionThenAttribute: "Schichten exportieren und Abrechnungscodes zuweisen",
    lastExportOnXXXByXXX: "Letzter Export am __VAR1 von __VAR2",
    payCode: "Abrechnungscodes",
    searchPayCode: "Suche nach einem Gehaltscode",
    addPayCode: "Hinzufügen eines Abrechnungscodes",
    internalCode: "Interner code",
    externalCode: "Externer code",
    businessDay: "Werktag",
    night: "Nacht",
    sundayAndHoliday: "Sonn- und Feiertag",
    rangeOfTotal: "__VAR1 von __VAR2",
    simpleCode: "Code",
    editPayCode: "Einen Abrechnungscode bearbeiten",
    deletePayCode: "Löschen eines Abrechnungscodes",
    askForDeletingPayCode: "Sind Sie sicher, dass Sie den Zahlungscode löschen möchten?",
    delete: "Löschen",
    payCodeWellCreated: "Der Abrechnungscode wurde erstellt!",
    payCodeWellChanged: "Der Abrechnungscode wurde geändert!",
    payCodeWellDeleted: "Der Abrechnungscode wurde gelöscht!",
    payrollExport: "Abrechnungscodes",
    sso: "SSO",
    ssoPageTitle: "SSO konfigurieren (Single Sign-On)",
    missionExportAndPayrollCodeConfiguration: "Schichten exportieren und Abrechnungscodes zuweisen",
    exportConfiguration: "Configuration de l'export",
    publicHolidays: "Feiertag",
    add_exportConfiguration: "Hinzufügen eines Abrechnungscodes",
    searchPlaceholder_exportConfiguration: "Suche nach einem Gehaltscode",
    isNight: "Nacht",
    isBusinessDay: "Werktag",
    isBeforeSundayOrPublicHoliday: "Sonn- und Feiertag",
    deleteConfirmationSubtitle_exportConfiguration: "Löschen eines Abrechnungskennzeichens",
    deleteConfirmationText_exportConfiguration: "Sind Sie sicher, dass Sie den Abrechnungscode löschen möchten?",
    modify_exportConfiguration: "Bearbeiten eines Abrechnungscodes",
    deleteActionCompletedConfirmationMessage_exportConfiguration: "Der Abrechnungscode wurde gelöscht!",
    exportConfigurationHasBeenUpdated: "Der Abrechnungscode wurde geändert!",
    exportConfigurationHasBeenCreated: "Abrechnungscodes wurden erstellt!",
    exportConfigurationsHaveBeenCreated: "Die Abrechnungscodes sind erstellt worden!",
    confirmationSubtitle_exportConfiguration: "Sind Sie sicher, dass Sie den Abrechnungscode bearbeiten möchten?",
    youShouldPreciseInstitutionName: "Sie müssen einen Namen für Ihre Einrichtung angeben.",
    yourAddressAreNotRecognized:
        "Ihre Adresse wurde nicht erkannt. Beginnen Sie, Ihre Adresse zu tippen, und wählen Sie Ihre Adresse aus den vorgeschlagenen Ergebnissen aus. Sollte Ihre Adresse nicht erscheinen, so wählen Sie eine Adresse in Ihrer Nähe aus.",
    youShouldIndicateValidAddress: "Sie müssen eine gültige Adresse angeben.",
    pleaseFillYourAddressAndSelectAChoice:
        "Tippen Sie bitte Ihre Adresse ein und wählen Sie aus der Liste mit den Adressvorschlägen aus.",
    youHaveQuestion: "Haben Sie eine Frage?",
    usefullInformations: "Praktische Informationen",
    institutionName: "Name der Einrichtung",
    visibleMobileForWorkers: "Handynummer für die Mitarbeiter sichtbar",
    institutionAddress: "Adresse der Einrichtung",
    timeCodePlural: "Zeitcodes",
    subtitleJobs: "Berufe",
    subtitleServices: "Stationen",
    speciaties: "Kompetenzen",
    toModifyServiceListOrTimeCode:
        "Um die Liste der Stationen, Berufe, Kompetenzen oder Zeitcodes zu ändern, kontaktieren Sie uns bitte unter",
    institutionPresentation: "Vorstellung der Einrichtung",
    accessInformation: "Zugangsdaten",
    dateInput: "TT/MM/JJJJ",
    publicholidayConfiguration: "Configuration de l'export",
    add_publicholidayConfiguration: "Einen Feiertag hinzufügen",
    searchPlaceholder_publicholidayConfiguration: "Suche nach einem Feiertag",
    deleteConfirmationSubtitle_publicholidayConfiguration: "Löschen eines Feiertags",
    deleteConfirmationText_publicholidayConfiguration:
        "Sind Sie sicher, dass Sie den __PUBLIC__HOLIDAY als gesetzlichen Feiertag Ihrer Einrichtung streichen wollen?",
    modify_publicholidayConfiguration: "Einen Feiertag bearbeiten",
    deleteActionCompletedConfirmationMessage_publicholidayConfiguration: "Der Feiertag wurde gestrichen!",
    publicholidayConfigurationHasBeenUpdated: "Der Feiertag wurde geändert!",
    publicholidayConfigurationHasBeenCreated: "Der Feiertag wurde erstellt!",
    publicholidayConfigurationsHaveBeenCreated: "Die Abrechnungscodes wurden erstellt!",
    confirmationSubtitle_publicholidayConfiguration: "Sind Sie sicher, dass Sie den Feiertag bearbeiten möchten?",
    addedPublicHolidayAlreadyExistsInTheInstitution:
        "Der hinzugefügte Feiertag existiert bereits in Ihrer Einrichtung.",
    addedPublicHolidayAlreadyExistsInOneOfTheSelectedInstitutions:
        "Der hinzugefügte Feiertag existiert bereits in einem oder mehreren der ausgewählten Einrichtungen.",
    addedPublicHolidaysAlreadyExistsInTheInstitution:
        "In Ihrer Einrichtung gibt es bereits einen oder mehrere Feiertage",
    addedPublicHolidaysAlreadyExistsInTheInstitutions:
        "Einer oder mehrere der Feiertage existieren bereits in einer oder mehreren der ausgewählten Einrichtungen.",
    noLastExport: "Bisher gab es noch keinen Export!",
    anEmailWillBeSentToXXX: "Der Export wurde an ___VAR1 geschickt",
    documentTemplate: "Dokumentvorlage",
    import: "Importieren",
    wrongFileFormat: "Ihre Datei ist nicht im richtigen Format",
    wrongFileFormatUseTemplate:
        "Ihr Dokument hat nicht das erforderliche Format. Bitte verwenden Sie die herunterladbare Vorlage.",
    onlyAcceptCSV:
        "Wir akzeptieren nur Dateien im .csv-Format.\nBitte nehmen Sie die entsprechenden Änderungen vor und importieren Sie Ihr Dokument erneut.",
    fileTooLarge: "Ihre Datei ist zu groß",
    maxCSVSizeIs:
        "Die maximal akzeptierte Dateigröße beträgt 100Ko.\nBitte nehmen Sie die entsprechenden Änderungen vor und importieren Sie Ihr Dokument erneut.",
    anErrorOccured: "Ein Fehler ist aufgetreten",
    askRetryImport: "Bitte importieren Sie Ihr Dokument erneut oder wenden Sie sich an unseren Support.",
    errorInDocument: "Ihr Dokument enthält einen Fehler.",
    askCorrectDocument:
        "Es haben sich Fehler in Ihre Datei eingeschlichen und verhindern, dass wir Ihre Zeitcodes aktualisieren können.\nBitte nehmen Sie die Korrekturen vor und importieren Sie Ihr Dokument erneut oder wenden Sie sich an unseren Support.",
    confirmationImport_schedule: "Sind Sie sicher, dass Sie diese Zeitcodes importieren möchten?",
    treatment_schedule: "Es werden neue Zeitcodes erstellt und bestehende Zeitcodes aktualisiert.",
    updated_schedule:
        "Wenn Sie Fragen haben, zögern Sie bitte nicht, sich mit uns in Verbindung zu setzen, indem Sie auf diese E-Mail antworten.",
    errorInCSV_schedule:
        "In Ihrer Datei ist ein Fehler aufgetreten, der uns daran hindert, Ihre Zeitcodes zu aktualisieren: __ERROR_MESSAGE__\nBitte korrigieren und reimportieren Sie Ihr Dokument oder wenden Sie sich an unseren Support.",
    missingField: "Bei __NAME__ sind nicht alle Pflichtfelder ausgefüllt.",
    invalidStartDate: "__NAME__ hat einen ungültigen Startzeitwert.",
    invalidEndDate: "__NAME__ hat einen ungültigen Endzeitwert.",
    invalidPauseTime: "__NAME__ hat einen ungültigen Pausenzeitwert.",
    invalidWorkingTime: "__NAME__ hat einen ungültigen Arbeitszeitwert.",

    youWantAddTimeCode: "Möchten Sie einen Zeitcode hinzufügen?",
    beCareful: "Achtung!",
    modificationNotSaved: "Sie haben Ihre Änderungen nicht gespeichert. Trotzdem fortfahren?",
    continue: "Weiter",
    writeNotesOrQuestions: "Schreiben Sie uns Ihre Anmerkungen oder Fragen.",
    addTimeCodeToCreateMission: "Einen Zeitcode hinzufügen, um das Erstellen einer Schicht zu erleichtern",
    contactUsAtAddress: "Kontaktieren Sie uns unter der Adresse ",
    orAskToBeContacted: " oder bitten Sie um Kontaktaufnahme.",
    askToBeRecontacted: "Um Kontaktaufnahme bitten",
    WhichTimeCodeYouWantAdd:
        "Welche Zeitcodes möchten Sie hinzufügen? Geben Sie bitte für jeden Code den Namen an, die Startzeit, die Endzeit und die tatsächlich gearbeitete Zeit (ohne Pausenzeit).",
    placeholderNameSchedule: "z.B. AJF19",
    placeholderInterfacingCodeSchedule: "z.B. AV205",
    presentation: "Beschreibung",
    address: "Adresse",
    postalCode: "Postleitzahl",
    modificationsSaved: "Die Änderungen wurden gespeichert.",
    accessInfo: "Zugangsdaten",
    verticalMenuTitle: "Einstellung der Einrichtung",
    templateName: "__NAME___DokumentVorlage.csv",
    exportName: "__NAME___Export.csv",
    scheduleCamelCase: "Zeitcodes",
    noInternetConnexion: "Keine Internetverbindung",
    serviceCamelCase: "Stationen",
    contractDemo: "⚡ Verträge",
    specialHourCamelCase: "Spezielle Zeitcode",
    infoOnWorkerCamelCase: "Besondere Mitarbeiterinformationen",
    salaryGridCamelCase: "GrilleDePrimes",
    institutionChronosCamelCase: "GtaChronos",
    adminCamelCase: "Admins",
    gtaOctimeCamelCase: "GtaOctime",
    importInProgress: "Import im Gange",
    importingPleaseWait: "Die Bearbeitung Ihrer Datei ist im Gange. Bitte warten Sie einen Moment.",
    missionMotifCreationCamelCase: "Gründe",
    interimCamelCase: "Zeitarbeiter",
    contractCamelCase: "Verträge",
    notificationCamelCase: "Benachrichtigungen",
    prefixMission: "Vorwahl der Schicht",
    phone: "Telefon",
    siret: "Siret",
    corporateName: "Juristischer Name",
    healthService: "AbteilStation",
    urssafCode: "Code urssaf",
    apeCode: "Code ape",
    activateSso: "SSO aktivieren",
    ssoViaOAuth2: "SSO mit OAuth2",
    clientId: "Client ID",
    clientSecret: "Client secret",
    authUrl: "Auth URL",
    userInfoUrl: "User info URL",
    accessTokenUrl: "Access Token URL",
    scope: "Scope",
    resource: "Resource",
    responseType: "Response Type",
    usePKCE: "Use Proof Key for Code Exchange extension",
    auth2FormClientIdRequired: "Bitte geben Sie die clientId Ihres Identitätsproviders an",
    auth2FormAuthUrlRequired: "Bitte geben Sie die für den Authentifizierungsprozess verwendete URL an",
    auth2FormAccessTokenUrlRequired: "Please specify the url used to get access tokens",
    ssoName: "SSO-Name",
    oauth2ConfigSaveError: "Beim Speichern der oauth2-Einstellungen ist ein Fehler aufgetreten",
    oauth2ConfigSaveSuccess: "gespeicherte Änderung!",
    myProfile: "Mein Profil",
    phoneNumber: "Handynummer",
    toChangeMailContactUs:
        "Um Ihre E-Mail-Adresse zu ändern, kontaktieren Sie uns bitte unter _CONSTANTS_EMAIL_CONTACT_.",
    changeMyPassword: "Mein Passwort ändern",
    actualPassword: "Aktuelles Passwort",
    newPassword: "Neues Passwort",
    confirmationPassword: "Neues Passwort bestätigen",
    language: "Sprache",
    confirmationCounterStatusTitle: "Sind Sie sicher, dass Sie den Status ändern wollen?",
    confirmationCounterStatusText: "Der Status für alle Ihre zukünftigen Schichten werden neu berechnet.",
    searchPlaceholder_service: "Suche nach einer Station",
    searchPlaceholder_specialty: "Suche nach einer Kompetenz",
    searchPlaceholder_job: "Suche nach einem Beruf",
    searchPlaceholder_missionMotifCreation: "Suche nach einem Grund",
    searchPlaceholder_infoOnWorker: "Suche nach zusätzlichen Informationen",
    identifyOrPasswordIncorrect: "Ihre ID oder Ihr Passwort ist nicht korrekt.",
    institutionInfo: "Einrichtung Info",
    hubSpotId: "hubSpotId",
    hubSpotInvoiceId: "hubSpotInvoiceId",
    institutionOptionContract: "Einrichtung Vertragsoption",
    coefficientValue: "Koeffizientenwert",
    hasDistinctionBetweenPartTimeAndFullTime: "Teilzeit-/Vollzeit-Vertragsrahmen",
    institutionOptionChurn: "institution Option Churn",
    isChurn: "Churn",
    firstNameTooShort: "Der Vorname ist zu kurz.",
    lastNameTooShort: "Der Nachname ist zu kurz.",
    institutionOptionMission: "Institution Option Schicht",
    hasPool: "Pool",
    institutionOptionWorker: "Einrichtung Option Worker",
    canSelectOneWorkerForMissionRequestCreate:
        "Vorschlag eines Arbeitnehmers für einen Bedarf eines Arbeitnehmers für einen Bedarf",
    hasDoubleValidation: "Doppelte Validierung",
    canSelectBetweenRecupAndSupp: "Wahl zwischen Freizeitausgleich und Bezahlung",
    institutionOption: "Option der Einrichtung",
    isAddressRequired: "Adresse erforderlich",
    isSocialSecurityNumberRequired: "Sozialversicherungsnummer",
    hasDPAE: "DPAE",
    isKorian: "Korian",
    isHCL: "HCL",
    hasContract: "Vertrag",
    isMedgoDemo: "Demo Hublo",
    hasInterim: "Vorläufig",
    hasUnspecifiedService: "Station nicht angegeben",
    hasSMS: "SMS",
    canPostMissionWithoutSchedule: "Schicht ohne Zeitcode",
    canSeeTotalCostForInstitution: "Sehen Sie die Gesamtkosten der Schicht",
    canValidateMission: "Um Schichten validieren zu können",
    jobCamelCase: "Berufe",
    specialtyCamelCase: "Kompetenzen",
    country: "Land",
    modify_institution: "Bearbeiten die Einrichtung",
    modify_institutionOption: "Bearbeiten die Option der Einrichtung",
    modify_institutionOptionWorker: "Bearbeiten einer Option",
    modify_institutionOptionMission: "Bearbeiten einer Option",
    modify_institutionOptionContract: "Bearbeiten einer Option",
    add_institutionOptionMission: "Hinzufügen einer Option",
    add_institutionOptionWorker: "Hinzufügen einer Option",
    add_institutionOptionContract: "Hinzufügen einer Option",
    eSignature: "E-Signatur",
    eSignatureProvider: "Anbieter E-Signatur",
    HasMassEsignatureEtab: "Massen-Signatur",
    HasMultiReplacementContract: "Multi Ersatzvertrag",
    healthPass: "Gesundheitspass",
    adminV2: "Administratoren",
    status: "status",
    all: "Alle",
    active: "Aktiv",
    inactive: "Inaktiv",
    getBackToAdminV2List: "Zurück zur Liste der Admins",
    lang: "Sprache",
    matricule: "Registrierungsnummer",
    contactInformation: "Kontaktinformationen",
    levelOfAuthorityAndAssignment: "Position und Aufgaben",
    documentsTemplates: "Dokumentvorlagen",
    shiftSummaryTemplate: "Schicht-Zusammenfassungsvorlage",
    noTemplateUploaded: "Keine Vorlage hochgeladen",
    submit: "einreichen",
    upload: "Hochladen",
    selectAFile: "Eine Datei auswählen",
    noFileSelected: "Keine Datei ausgewählt",
    fileUploadSuccess: "Ihre Datei wurde hochgeladen!",
    confirmationSubtitle_admin_insert: "Aufnahme des Admins in die Einrichtung",
    confirmationText_admin_insert: `Einer der Admins, den Sie anlegen möchten, existiert bereits in einer anderen Einrichtung. 
        Wir fügen ihm lediglich eine neue Einrichtung hinzu, ohne seine Informationen zu ändern. Wollen Sie fortfahren?`,
    placeholderFirstNameAdmin: "z.B.: Alexander",
    placeholderLastNameAdmin: "z.B.: FUHRMANN",
    placeholderEmailAdmin: "z.B.: alexander.fuhrmann@hublo.com",
    placeholderMobilePhoneAdmin: "z.B.: 0151 000 0000",
    placeholderMatriculeAdmin: "z.B.: X00000",
    none: "Keiner",
    assignmentInstitutions: "Zugewiesene Einrichtung(en)",
    position: "Position",
    notificationPoles: "Benachrichtigungsbereich(e)",
    notificationServices: "Benachrichtigungsabteilung(en)",
    requiredFields: "erforderliche Felder",
    assignmentInstitutions_customText: "Einrichtung(en)",
    notificationPoles_customText: "Bereich(e)",
    notificationServices_customText: "Abteilung(en)",
    confirmationTitle_deactivate_admin: "Sind Sie sicher, diese Lizenz zu deaktivieren?",
    confirmationTitle_activate_admin: "Sind Sie sicher, dass Sie diese Lizenz aktivieren möchten?",
    confirmationText_deactivate_admin: "Der Administrator darf die Plattform nicht mehr nutzen.",
    confirmationText_activate_admin: "Der Administrator wird über die Aktivierung seines Kontos benachrichtigt.",
    notification_deactivate_admin: "Ihr Administrator wurde deaktiviert!",
    notification_activate_admin: "Ihr Administrator wurde aktiviert!",
    specialties: "Kompetenzen",
    subtitleSpecialties: "Kompetenzen",
    agencyName: "Name der Agentur",
    placeholderAgencyName: "bspw. : Mitarbeiterüberlassung System",
    placeholderAgencyEmail: "bspw. : Mitarbeiterüberlassung@beispiel.de",
    placeholderNbMinutesAfterLaunchingRequest: "3",
    placeholderAgencyJob: "Job(s) auswählen",
    placeholderAgencySpecialty: "Kompetenz(en) auswählen",
    adminHasNotBeenAdded: "Admin wurde der Einrichtung nicht hinzugefügt",
    adminV2HasBeenCreated: "Ihr Administrator wurde erstellt!",
    adminV2HasBeenUpdated: "Ihr Administrator wurde bearbeitet!",
    sector: "Sektor",
    numberOfActiveAdministrators: "Anzahl der aktiven Administratoren:",
    noResult_adminV2: "Keine Übereinstimmung gefunden",
    confirmationSubtitle_admin_update: "Dieser Administrator verwendet bereits Hublo!",
    confirmationText_admin_update: `Diese E-Mail existiert bereits in unserer Datenbank.
        Verwenden Sie die Funktion "Admin hinzufügen", um den Admin mit Ihrer Einrichtung zu verknüpfen.`,
    since: "seit",
    deactivateTheLicense: "Deaktivieren der Lizenz",
    isOnForCreatingMission: "Wählbare Schicht",
    isDeleted: "Wählbarer Zeitcode",
    doYouWantToDeactivateAdminForAllInstitutions:
        "Möchten Sie diesen Administrator für alle Einrichtungen der Gruppe deaktivieren?",
    ifYouClickNoAdminWillBeDeactivatedOnlyOnThisInstitution: `Wenn Sie auf "Nein" klicken, wird der Administrator nur in dieser Einrichtung deaktiviert.`,
    defaultPlaceholder: "Wählen ...",
    manageNotifications: "Meine Benachrichtigungen verwalten",
    notifAboutWorker: "Benachrichtigungen zu meinen Mitarbeitern",
    wantNotifWhenWorker: " Ich möchte benachrichtigt werden, wenn ein Mitarbeiter ...",
    notifAboutMissions: "Benachrichtigungen zu meinen Schichten",
    wantNotifWhenAdmin: "Ich möchte benachrichtigt werden, wenn ein Administrator ...",
    wantNotifWhenMission: "Ich möchte benachrichtigt werden, wenn eine Schicht ...",
    wantNotifWhen: " Ich möchte benachrichtigt werden, wenn ...",
    listServicesWantNotif: "Zu diesen Stationen möchte ich benachrichtigt werden:",
    myServices: "Meine Stationen",
    isInvited: "... eingeladen wird",
    subscribes: "... sich registriert",
    isDeletedFromNetwork: "... aus dem Netzwerk gelöscht wird",
    hasSpecialtiesModified: "... seine Kompetenzen geändert hat",
    createsNewMission: "... eine neue Schicht veröffentlicht",
    createsMissionRequest: "... eine Schichtanfrage veröffentlicht",
    applyStandardMission: "... sich für eine Standardschicht bewirbt",
    applyExpressMission: "... sich für eine Express-Schicht bewirbt",
    assignStandardMission: "... einem Bewerber eine Standardschicht zuteilt",
    cancelMission: "... eine Schicht abbricht",
    declareFutureMission: "... einem Bewerber eine zukünftige Schicht zuteilt",
    declarePastMission: "... einem Bewerber eine vergangene Schicht zuteilt",
    askedNewSpecialties: "... um neue Kompetenzen gebeten hat",
    wasValidated: "... bestätigt wurde",
    wasModified: "... geändert wurde",
    isPending: "... in Bearbeitung ist",
    mustBeValidated: "... bestätigt werden muss",
    hasMissionOnDifferentService:
        "... eine Schicht in einer anderen Station als seiner Tätigkeitsabteilung erhalten hat",
    allCandidatesWarned: "... alle Bewerber für eine Schicht benachrichtigt wurden",
    adminWantsInterim: "... wenn ein leitender Angestellter für eine Schicht Mitarbeiterüberlassung anfordern will",
    agencySetInterim: "... wenn eine Agentur eine Zeitarbeitskraft für eine Schicht vorschlägt",
    allTheTime: "Immer",
    onlyConcernMyServices: "Nur wenn es meine Stationen betrifft",
    onlyIInvited: "Nur wenn ich ihn eingeladen habe",
    onlyMyMission: "Nur wenn die Schicht von mir erstellt wurde",
    onlyMyMissionCanceledOtherAdmin:
        "Nur wenn die Schicht von mir veröffentlicht und von einem anderen Administrator abgebrochen wurde",
    never: "Nie",
    accessNotifications: "Zugang zu Benachrichtigungen",
    hour: "Stunde",
    shift: "Schicht",
    idSubscription: "idSubscription",
    "idSubscription-form": "idSubscription",
    agencyClientId: "Agency ClientId",
    "agencyClientId-form": "Agency ClientId",
    subscriptionName: "Label",
    "subscriptionName-form": "Label",
    timeLimitationToPostShiftInDays: "Einschränkung der Missionserstellung (in Tagen)",
    timezone: "Zeitzone",
    slotConfig: "Zeitschlitz-Konfiguration",
    dealShift: "Eine Mission verhandeln",
    internalPool: "Internal pool",
    internalPoolCalendarBlockDays: "Internal pool kalender-sperrfrist (Tage)",
    floor: "Floor",
    building: "Building",
    hasSmock: "Is outfit provided",
    hasFood: "Is food provided",
    minCaregiversPerShift: "Caregivers / shift (min)",
    bedsCapacity: "Number of beds",
    parking: "Parking",
    software: "Software",
    digicode: "Digicode"
};
