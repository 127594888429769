import { IAdminInfo } from "../../Model/AdminInfo";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const AdminInfoDefaultSelect = ["id"];

export async function fetchAdminInfos(query: IQueryParams): Promise<IAdminInfo[]> {
    const adminInfos = await postFetchFactory<IAdminInfo>(PossibleFetchs.adminInfo, PossibleApis.medgoApi)(
        {
            select: AdminInfoDefaultSelect
        },
        query
    );
    return adminInfos;
}

export async function fetchAdminInfosCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IAdminInfo>(PossibleFetchs.adminInfo, PossibleApis.medgoApi)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertAdminInfo(adminInfo: IAdminInfo): Promise<IAdminInfo> {
    return await postFactory<IAdminInfo>(PossiblePosts.adminInfo, PossibleApis.medgoApi)(adminInfo);
}

export async function updateAdminInfo(id: number, adminInfo: IAdminInfo): Promise<IAdminInfo> {
    return await updateFactory<IAdminInfo>(PossibleUpdates.adminInfo, PossibleApis.medgoApi)(id, adminInfo);
}

export async function deleteAdminInfo(id: number): Promise<IAdminInfo> {
    return await deleteFactory<IAdminInfo>(PossibleDeletes.adminInfo, PossibleApis.medgoApi)(id);
}
