import { IReduxAction } from "../store.type";
import ContractSalaryGridTypes from "../type/contractSalaryGrid.type";
import {
    fetchContractSalaryGrids,
    fetchInstitutionHasContractSalaryGrids,
    insertInstitutionHasContractSalaryGrid,
    updateContractSalaryGrid,
    deleteContractSalaryGrid,
    deleteInstitutionHasContractSalaryGrid
} from "../../service/contractSalaryGrid.service";
import {
    IContractSalaryGrid,
    INormalizedContractSalaryGrids,
    ICreateContractSalaryGridRequest
} from "../../../Model/ContractSalaryGrid";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchContractSalaryGridsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const contractSalaryGrids = await fetchContractSalaryGrids(query);
            dispatch({
                type: ContractSalaryGridTypes.FETCH_CONTRACTSALARYGRIDS,
                payload: {
                    byId: contractSalaryGrids.reduce(
                        (p: INormalizedContractSalaryGrids, c: IContractSalaryGrid): INormalizedContractSalaryGrids => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: contractSalaryGrids.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionHasContractSalaryGridsAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<any> => {
        try {
            const InstitutionHasContractSalaryGrids = await fetchInstitutionHasContractSalaryGrids(query);
            dispatch({
                type: ContractSalaryGridTypes.FETCH_INSTITUTION_HAS_CONTRACTSALARYGRIDS
            });
            return InstitutionHasContractSalaryGrids;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchAllContractSalaryGridsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const contractSalaryGrids = await fetchContractSalaryGrids(query);
            dispatch({
                type: ContractSalaryGridTypes.FETCH_ALL_CONTRACTSALARYGRIDS,
                payload: {
                    byId: contractSalaryGrids.reduce(
                        (p: INormalizedContractSalaryGrids, c: IContractSalaryGrid): INormalizedContractSalaryGrids => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: contractSalaryGrids.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasContractSalaryGridAction(
    contractSalaryGrid: ICreateContractSalaryGridRequest
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newContractSalaryGrid = await insertInstitutionHasContractSalaryGrid(contractSalaryGrid);
            dispatch({
                type: ContractSalaryGridTypes.INSERT_INSTITUTION_HAS_CONTRACTSALARYGRID,
                payload: newContractSalaryGrid
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateContractSalaryGridAction(
    id: number,
    contractSalaryGrid: IContractSalaryGrid
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedContractSalaryGrid = await updateContractSalaryGrid(id, contractSalaryGrid);
            dispatch({
                type: ContractSalaryGridTypes.UPDATE_CONTRACTSALARYGRID,
                payload: updatedContractSalaryGrid
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteContractSalaryGridAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteContractSalaryGrid(id);
            dispatch({
                type: ContractSalaryGridTypes.DELETE_CONTRACTSALARYGRID
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionHasContractSalaryGridAction(
    idInstitutionHasContractSalaryGrid: number,
    idContractSalaryGrid: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionHasContractSalaryGrid(idInstitutionHasContractSalaryGrid);
            dispatch({
                type: ContractSalaryGridTypes.DELETE_INSTITUTION_HAS_CONTRACTSALARYGRIDS,
                payload: idContractSalaryGrid
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setContractSalaryGridsAction(
    dict: INormalizedContractSalaryGrids
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_CONTRACTSALARYGRIDS,
            payload: dict
        });
    };
}

export function setSelectedContractSalaryGridAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_SELECTED_CONTRACTSALARYGRID,
            payload: id
        });
    };
}

export function setSelectedContractSalaryGridMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_SELECTED_CONTRACTSALARYGRID_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingContractSalaryGridAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_IS_LOADING_CONTRACTSALARYGRID,
            payload: isLoading
        });
    };
}
export function setContractSalaryGridOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_CONTRACTSALARYGRID_ORDER,
            payload: idsSorted
        });
    };
}

export function setContractSalaryGridErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractSalaryGridTypes.SET_CONTRACTSALARYGRID_ERROR_CODE,
            payload: errorCode
        });
    };
}
