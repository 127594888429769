import React, { Component } from "react";
import { connect } from "react-redux";
import { EBackOfficeColumnName, EBackOfficeColumnType, IFormCategoryColumns } from "../../constants/Column.constants";
import { IBackOfficeTable, EBackOfficeTableName } from "../../constants/Table.constants";
import { IBackOfficeColumn } from "../../constants/Column.constants";
import "../../scss/components/table/FormTable.scss";
import { ISelectLabelValue } from "npm-medgo-components";
import FormInputComponent, { getSelectCheckboxOptions } from "../../components/form/FormLabelInput.component";
import { setFormKeyValueAction, resetErrorMessageAction, deleteFormLineAction } from "../../store/action/form.action";
import { hasErrorMessage, getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import BoxComponent from "../../components/table/Box.component";
import { IFormDictionary } from "../../store/type/form.type";
import { valueForm } from "../../type/form.type";
import { displayCriteria } from "../../../toolbox/permissions";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { INormalizedInstitutions } from "../../../Model/Institution";
import { firstLetterUppercase } from "npm-medgo-toolbox/dist/modules/string";

interface IReduxPropsFormCategoryView {
    errorMessage: { [table: string]: { [index: string]: { [column: string]: string } } };
    formDictionary: IFormDictionary;
    numberForms: number;
    selectedTab: number;
    institutionDict: INormalizedInstitutions;
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    idInstitutions: number[];
    lang: any;
    idDetailedElement: number;
    idPoles: number[];
    modifyingElement: any;
}

interface IReduxActionsTableFilterView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    resetErrorMessageAction: (table: EBackOfficeTableName, index: number, column: EBackOfficeColumnName) => void;
    deleteFormLineAction: (formIndex: number, backOfficeTableName: EBackOfficeTableName) => void;
}

interface IPropsFormCategoryView extends IReduxPropsFormCategoryView, IReduxActionsTableFilterView {
    backOfficeTable: IBackOfficeTable;
    selectOptions?: { [key: string]: ISelectLabelValue[] };
    selectCheckboxListOptions?: { [key: string]: { [key: string]: any[] } };
    customOnChange: (value: valueForm, column: IBackOfficeColumn, formIndex: number) => void;
    customSelectCheckboxOnChange: (value: valueForm, column: IBackOfficeColumn, formIndex: number) => void;
    checkFormDictionary: (isOnSave: boolean) => void;
    setErrorMessageFromErrorCode: (error: any, columnName: EBackOfficeColumnName, index: number) => void;
}

class FormCategoryView extends Component<IPropsFormCategoryView> {
    render(): JSX.Element {
        const backOfficeTable = this.props.backOfficeTable;
        const categoriesColumns: IFormCategoryColumns[] = backOfficeTable.formCategoriesColumns
            ? backOfficeTable.formCategoriesColumns[this.props.selectedTab]
            : [
                  {
                      columns: backOfficeTable.formColumns
                          ? backOfficeTable.formColumns[this.props.selectedTab]
                          : backOfficeTable.columns[this.props.selectedTab]
                  }
              ];
        const formCategories = [];
        for (let i = 0; i < categoriesColumns.length; i++) {
            formCategories.push(this.renderFormCategory(categoriesColumns[i]));
        }
        const width = this.props.idDetailedElement === -1 ? "752px" : "100%";
        return (
            <div style={{ width: width }}>
                <div style={{ textAlign: "right", marginBottom: "-20px", color: "#FF6B6B" }}>
                    *{this.props.lang.requiredFields}
                </div>
                {formCategories}
            </div>
        );
    }

    renderFormCategory(formCategoryColumns: IFormCategoryColumns): JSX.Element {
        const backOfficeTable = this.props.backOfficeTable;
        const columns = formCategoryColumns.columns.filter(
            (e): boolean =>
                e.name !== EBackOfficeColumnName.id &&
                e.name !== EBackOfficeColumnName.institution &&
                e.displayColumn &&
                displayCriteria(
                    e.displayCriteria,
                    getModelsFromDict(this.props.institutionDict, this.props.idInstitutions),
                    getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions),
                    this.props.modifyingElement ? true : false,
                    this.props.idPoles
                )
        );
        const columnsLines: IBackOfficeColumn[][] = [];
        let i = 0;
        const nbOfInputsPerLine = formCategoryColumns.nbOfInputsPerLine ? formCategoryColumns.nbOfInputsPerLine : 3;
        while (columns.slice(i, i + nbOfInputsPerLine).length > 0) {
            columnsLines.push(columns.slice(i, i + nbOfInputsPerLine));
            i += nbOfInputsPerLine;
        }
        return (
            <>
                {formCategoryColumns.name ? (
                    <div className="category-columns-name">
                        {firstLetterUppercase(this.props.lang[formCategoryColumns.name])}
                    </div>
                ) : (
                    <></>
                )}
                <table
                    style={{ width: "100%", marginBottom: "46px", marginTop: "10px" }}
                    key={`key_${formCategoryColumns.name}`}
                >
                    {columnsLines.map(
                        (columnsLine, line): JSX.Element => {
                            return (
                                <>
                                    <tr className="tr-line-form" key={line}>
                                        {columnsLine.map(
                                            (column, index): JSX.Element => {
                                                const hasError = hasErrorMessage(
                                                    this.props.errorMessage,
                                                    backOfficeTable.name[this.props.selectedTab],
                                                    0,
                                                    column.name
                                                );
                                                let component;
                                                if (column.disableFormInput) {
                                                    component = this.renderBoxComponent(column, hasError, 0);
                                                } else {
                                                    component = this.renderFormComponent(
                                                        column,
                                                        backOfficeTable,
                                                        hasError,
                                                        0
                                                    );
                                                }
                                                return (
                                                    <td
                                                        key={`header_${index}`}
                                                        className="td-line"
                                                        style={{
                                                            width: `${100 / nbOfInputsPerLine}%`,
                                                            paddingLeft: `${index === 0 ? "5px" : "20px"}`,
                                                            paddingRight: `${
                                                                index === nbOfInputsPerLine - 1 ? "5px" : "20px"
                                                            }`
                                                        }}
                                                    >
                                                        {component}
                                                    </td>
                                                );
                                            }
                                        )}
                                    </tr>
                                    {this.renderErrorLine(columnsLine)}
                                </>
                            );
                        }
                    )}
                </table>
            </>
        );
    }

    renderErrorLine(columns: IBackOfficeColumn[]): JSX.Element {
        const backOfficeTable = this.props.backOfficeTable;
        return (
            <tr className="tr-line-form">
                {columns.map(
                    (column, index): JSX.Element => {
                        const hasError = hasErrorMessage(
                            this.props.errorMessage,
                            backOfficeTable.name[this.props.selectedTab],
                            0,
                            column.name
                        );
                        return (
                            <td key={`header_${index}`} className="td-line">
                                {hasError ? (
                                    <div
                                        className="error-message"
                                        style={{
                                            paddingLeft: `${index === 0 ? "5px" : "20px"}`
                                        }}
                                    >
                                        {
                                            this.props.errorMessage[backOfficeTable.name[this.props.selectedTab]][0][
                                                column.name
                                            ]
                                        }
                                    </div>
                                ) : null}
                            </td>
                        );
                    }
                )}
            </tr>
        );
    }

    renderBoxComponent(column: IBackOfficeColumn, hasError: boolean, formIndex: number): JSX.Element {
        let formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            this.props.backOfficeTable.name[this.props.selectedTab]
        );
        formDictionaryTable = formDictionaryTable[formIndex] ? formDictionaryTable[formIndex] : {};
        const value = formDictionaryTable[column.name];
        return (
            <BoxComponent
                value={value}
                column={column}
                table={this.props.backOfficeTable.name[this.props.selectedTab]}
                selectCheckboxListOptions={getSelectCheckboxOptions(
                    this.props.selectCheckboxListOptions,
                    this.props.backOfficeTable.name[this.props.selectedTab],
                    column.name
                )}
                hasError={hasError}
            />
        );
    }

    renderFormComponent(
        column: IBackOfficeColumn,
        backOfficeTable: IBackOfficeTable,
        hasError: boolean,
        formIndex: number
    ): JSX.Element {
        const formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            backOfficeTable.name[this.props.selectedTab]
        );
        const columnName = this.props.lang[`${column.name}-form`]
            ? firstLetterUppercase(this.props.lang[`${column.name}-form`])
            : firstLetterUppercase(this.props.lang[column.name]);
        return (
            <FormInputComponent
                key={column.name}
                column={column}
                selectOptions={this.props.selectOptions}
                selectCheckboxListOptions={this.props.selectCheckboxListOptions}
                customSelectCheckboxOnChange={this.props.customSelectCheckboxOnChange}
                onChange={(value: valueForm): void => {
                    for (const index in formDictionaryTable) {
                        if (
                            hasErrorMessage(
                                this.props.errorMessage,
                                backOfficeTable.name[this.props.selectedTab],
                                parseInt(index),
                                column.name
                            )
                        )
                            this.props.resetErrorMessageAction(
                                backOfficeTable.name[this.props.selectedTab],
                                parseInt(index),
                                column.name
                            );
                    }
                    this.props.setFormKeyValueAction(
                        column.name,
                        value,
                        backOfficeTable.name[this.props.selectedTab],
                        column.type,
                        formIndex
                    );
                    this.props.customOnChange(value, column, formIndex);
                }}
                onBlur={(): void => {
                    try {
                        this.props.checkFormDictionary(false);
                    } catch (err) {
                        this.props.setErrorMessageFromErrorCode(err, err.columnName, formIndex);
                    }
                }}
                table={backOfficeTable.name[this.props.selectedTab]}
                formIndex={formIndex}
                hasError={hasError}
                name={columnName}
            />
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsFormCategoryView => ({
        errorMessage: centralState.form.errorMessage,
        formDictionary: centralState.form.formDictionary,
        numberForms: centralState.form.numberForms,
        selectedTab: centralState.tables.selectedTab,
        institutionDict: centralState.institutions.byId,
        idInstitutions: centralState.institutions.selectedMulti,
        idInstitutionOptions: centralState.institutionOption.ids,
        institutionOptionDict: centralState.institutionOption.byId,
        lang: centralState.language.lang,
        idDetailedElement: centralState.form.idDetailedElement,
        idPoles: centralState.poles.ids,
        modifyingElement: centralState.tables.modifyingElement
    }),
    {
        setFormKeyValueAction,
        resetErrorMessageAction,
        deleteFormLineAction
    }
)(FormCategoryView);
