enum InstitutionOctimeActionTypes {
    FETCH_INSTITUTIONOCTIMES = "FETCH_INSTITUTIONOCTIMES",
    SET_INSTITUTIONOCTIMES = "SET_INSTITUTIONOCTIMES",
    FETCH_INSTITUTIONOCTIMES_COUNT = "FETCH_INSTITUTIONOCTIMES_COUNT",
    SET_SELECTED_INSTITUTIONOCTIME = "SET_SELECTED_INSTITUTIONOCTIME",
    SET_SELECTED_INSTITUTIONOCTIME_MULTI = "SET_SELECTED_INSTITUTIONOCTIME_MULTI",
    SET_IS_LOADING_INSTITUTIONOCTIME = "SET_IS_LOADING_INSTITUTIONOCTIME",
    SET_INSTITUTIONOCTIME_ORDER = "SET_INSTITUTIONOCTIME_ORDER",
    INSERT_INSTITUTIONOCTIME = "INSERT_INSTITUTIONOCTIME",
    UPDATE_INSTITUTIONOCTIME = "UPDATE_INSTITUTIONOCTIME",
    DELETE_INSTITUTIONOCTIME = "DELETE_INSTITUTIONOCTIME",
    SET_INSTITUTIONOCTIME_ERROR_CODE = "SET_INSTITUTIONOCTIME_ERROR_CODE"
}
export default InstitutionOctimeActionTypes;
