import { connect } from "react-redux";
import { ISchedule } from "../../../Model/Schedule";
import {
    insertScheduleAction,
    insertInstitutionHasScheduleAction,
    updateScheduleAction
} from "../../store/action/schedule.action";

import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IFormDictionary } from "../../store/type/form.type";
import {
    stringHHmmToUtcMoment,
    minutesToHHmmFormat,
    datesMomentMinutesDiff,
    stringHHmmToMinutes
} from "../../../toolbox/date";
import {
    getFormDictionaryRecordsDict,
    hasErrorMessage,
    isKeyElementAlreadyInFormOrInstitutions
} from "../../modules/store/util";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { EBackOfficeColumnName, IBackOfficeColumn, EBackOfficeColumnType } from "../../constants/Column.constants";
import { setFormKeyValueAction, resetErrorMessageAction } from "../../store/action/form.action";
//@ts-ignore
import * as clone from "clone";
import { ILang } from "../../lang/Lang";

interface IPropsFormSchedule extends IReduxActionsForm, IPropsForm<ISchedule> {
    scheduleDict: ISchedule[];
    idSchedules: number[];
    selectedMulti: number[];
    errorMessage: { [table: string]: { [index: string]: { [column: string]: string } } };
    lang: ILang;
}

interface IReduxActionsForm {
    insertScheduleAction: (formDictionary: IFormDictionary) => ISchedule;
    insertInstitutionHasScheduleAction: (idSchedule: number, idInstitution: number) => void;
    updateScheduleAction: (id: number, schedule: ISchedule) => void;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    resetErrorMessageAction: (table: EBackOfficeTableName, index: number, column: EBackOfficeColumnName) => void;
}

class FormScheduleComponent extends FormComponent<ISchedule, IPropsFormSchedule> {
    async createElementAction(): Promise<void> {
        const formDictionarySchedules = clone(
            getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.schedule)
        );
        const formKeys = Object.keys(formDictionarySchedules);
        formKeys.forEach(
            async (key: any): Promise<void> => {
                try {
                    const formDictionarySchedule = formDictionarySchedules[key];
                    formDictionarySchedule.beginAt = stringHHmmToUtcMoment(formDictionarySchedule.beginAt);
                    formDictionarySchedule.endAt = stringHHmmToUtcMoment(formDictionarySchedule.endAt);
                    formDictionarySchedule.nbWorkedMinutes = stringHHmmToMinutes(
                        formDictionarySchedule.nbWorkedMinutes
                    );
                    const { id: idSchedule } = await this.props.insertScheduleAction(formDictionarySchedule);
                    const idSelectedInstitutions = this.getSelectedIdInstitutions();
                    await Promise.all(
                        idSelectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                await this.props.insertInstitutionHasScheduleAction(idSchedule, idInstitution);
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
    }

    async updateElementAction(): Promise<void> {
        const schedule = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.schedule)[0];
        await this.props.updateScheduleAction(schedule.id, schedule as ISchedule);
    }

    customOnChange(value: string, column: IBackOfficeColumn, formIndex: number): void {
        const formatRegEx = /\d{1,2}:\d{1,2}/;
        let formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.schedule
        );
        formDictionaryTable = formDictionaryTable[formIndex] ? formDictionaryTable[formIndex] : {};
        if (
            [EBackOfficeColumnName.break, EBackOfficeColumnName.beginAt, EBackOfficeColumnName.endAt].includes(
                column.name
            ) &&
            formatRegEx.test(formDictionaryTable.beginAt) &&
            formatRegEx.test(formDictionaryTable.endAt)
        ) {
            const momentBeginAt = stringHHmmToUtcMoment(formDictionaryTable.beginAt);
            const momentEndAt = stringHHmmToUtcMoment(formDictionaryTable.endAt);
            if (momentEndAt.isSameOrBefore(momentBeginAt, "minute")) momentEndAt.add(1, "d");
            const breakDurationMinutes = formatRegEx.test(formDictionaryTable.break)
                ? stringHHmmToMinutes(formDictionaryTable.break)
                : 0;
            const nbWorkedMinutes = datesMomentMinutesDiff(momentBeginAt, momentEndAt, breakDurationMinutes);
            const nbWorkedMinutesFormatted = minutesToHHmmFormat(nbWorkedMinutes);
            if (
                hasErrorMessage(
                    this.props.errorMessage,
                    EBackOfficeTableName.schedule,
                    formIndex,
                    EBackOfficeColumnName.nbWorkedMinutes
                )
            )
                this.props.resetErrorMessageAction(
                    EBackOfficeTableName.schedule,
                    formIndex,
                    EBackOfficeColumnName.nbWorkedMinutes
                );
            this.props.setFormKeyValueAction(
                EBackOfficeColumnName.nbWorkedMinutes,
                nbWorkedMinutesFormatted,
                EBackOfficeTableName.schedule,
                column.type,
                formIndex
            );
        }
    }

    customCheck(value: string, column: IBackOfficeColumn): void {
        const formDictionaryTable = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.schedule
        );
        if (
            column.name === EBackOfficeColumnName.code &&
            value &&
            typeof value === "string" &&
            value.trim() !== "" &&
            isKeyElementAlreadyInFormOrInstitutions({
                dict: this.props.scheduleDict,
                ids: this.props.idSchedules,
                selectedMultis: this.props.selectedMulti,
                formDictionaryTable,
                formDictionaryKey: value,
                key: EBackOfficeColumnName.code,
                modifyingElement: this.props.modifyingElement
            })
        ) {
            throw { columnName: EBackOfficeColumnName.code, errorCode: 409 };
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsForm<ISchedule> =>
        Object.assign(getConnectParentState(centralState), {
            scheduleDict: centralState.schedule.byId,
            idSchedules: centralState.schedule.ids,
            selectedMulti: centralState.institutions.selectedMulti,
            errorMessage: centralState.form.errorMessage,
            lang: centralState.language.lang
        }),
    Object.assign(getConnectParentActions(), {
        insertScheduleAction,
        updateScheduleAction,
        insertInstitutionHasScheduleAction,
        setFormKeyValueAction,
        resetErrorMessageAction
    })
)(FormScheduleComponent);
