import { connect } from "react-redux";
import { updateInstitutionAction } from "../../store/action/institution.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IInstitution } from "../../../Model/Institution";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";
import {
    selectCountryOptions,
    selectLangOptions,
    selectSectorOptions,
    selectTimezoneOptions
} from "../../constants/SelectOptions.constants";
import { EBackOfficeColumnName } from "../../constants/Column.constants";

interface IPropsFormInstitution extends IReduxPropsForm<IInstitution>, IReduxActionsForm, IPropsForm<IInstitution> {}

interface IReduxActionsForm {
    updateInstitutionAction: (id: number, institution: IInstitution) => void;
}

class FormInstitutionComponent extends FormComponent<IInstitution, IPropsFormInstitution> {
    async updateElementAction(): Promise<void> {
        const institution = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institution
        )[0];
        institution.country = institution.country.value;
        institution.lang = institution.lang.value;
        institution.sector = institution.sector.value;
        institution.timezone = institution.timezone.value;
        await this.props.updateInstitutionAction(institution.id, institution as IInstitution);
    }
}

export default connect(
    (centralState: any): IPropsFormInstitution =>
        Object.assign(getConnectParentState(centralState), {
            selectOptions: {
                [EBackOfficeColumnName.lang]: selectLangOptions.lang,
                [EBackOfficeColumnName.country]: selectCountryOptions.country,
                [EBackOfficeColumnName.sector]: selectSectorOptions.sector,
                [EBackOfficeColumnName.timezone]: selectTimezoneOptions.timezone
            }
        }),
    Object.assign(getConnectParentActions(), {
        updateInstitutionAction
    })
)(FormInstitutionComponent);
