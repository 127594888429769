import { IInstitution } from "../Model/Institution";
import { IInstitutionOption } from "../Model/InstitutionOption";

export function shouldDisplayInterface(institutions: IInstitution[]): boolean {
    return (
        institutions.filter((institution): boolean => {
            return institution.idInstitutionGTA !== undefined && institution.idInstitutionGTA !== null;
        }).length > 0
    );
}

export function shouldDisplayContractColumn(institutionOptions: IInstitutionOption[]): boolean {
    return (
        institutionOptions.filter((institutionOption): boolean => {
            return institutionOption.hasContract;
        }).length > 0
    );
}

export function shouldDisplayOnlyOnCreation(isModifying: boolean): boolean {
    return !isModifying;
}

export function shouldDisplayNotificationPoles(idPoles: number[]): boolean {
    return idPoles.length > 0;
}

export function displayCriteria(
    columnDisplayCriteria: any,
    institutions: IInstitution[],
    institutionOptions: IInstitutionOption[],
    isModifying: boolean,
    idPoles: number[]
) {
    switch (columnDisplayCriteria) {
        case shouldDisplayInterface:
            return shouldDisplayInterface(institutions);
        case shouldDisplayContractColumn:
            return shouldDisplayContractColumn(institutionOptions);
        case shouldDisplayOnlyOnCreation:
            return shouldDisplayOnlyOnCreation(isModifying);
        case shouldDisplayNotificationPoles:
            return shouldDisplayNotificationPoles(idPoles);
        default:
            return true;
    }
}
