import { PossibleApis } from "../../Constant/Api.constant";
import axiosInstance from "../../modules/axios/axios.instance";
import { AxiosError } from "axios";

interface EnableAgencyOptionError {
    error: string;
    message: string;
}
export async function enableOptionAgency(idInstitution: number): Promise<void> {
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency`;
    await axiosInstance.post<{ result: string }>(url).catch((err: AxiosError<EnableAgencyOptionError>) => {
        if (err.response?.status === 409) {
            return;
        }
        throw err;
    });
}

export async function toggleHubloPoolOption(idInstitution: number): Promise<void> {
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/toggle/is-hublo-pool-client`;
    await axiosInstance.put<{ result: string }>(url).catch((err: AxiosError<EnableAgencyOptionError>) => {
        throw err;
    });
}

export async function disableOptionAgency(idInstitution: number): Promise<void> {
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency`;
    await axiosInstance.delete<{ result: string }>(url).catch((err: AxiosError<EnableAgencyOptionError>) => {
        if (err.response?.status === 409) {
            return;
        }
        throw err;
    });
}
