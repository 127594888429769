import React from "react";

import { Equality, ArrayToolbox } from "npm-hublo-toolbox";

import { IInstitution } from "../../../../Model/Institution";
import { IPole } from "../../../../Model/Pole";
import { IService } from "../../../../Model/Service";
import InputPole from "../../Shared/InputPole.component";
import InputService from "../../Shared/InputService.component";
import { useSelector } from "react-redux";
import { poleIdsSelector, poleByIdSelector, serviceIdsSelector, serviceByIdSelector } from "../../../store/selectors";
import { getModelsFromDict } from "../../../modules/store/util";

interface IPropsNotificationServiceView {
    institution: IInstitution;
    onSelectService: (idServices: number[]) => void;
    idServices?: number[];
    isReadingOnly: boolean;
    lang: string;
}

function getIdPolesFromIdServices({
    services = [],
    idServices
}: {
    services: IService[];
    idServices: number[];
}): number[] {
    let selectedIdPoles = services
        .filter(e => idServices.indexOf(e.id) !== -1 && e.pole !== undefined && e.pole !== null)
        .map(e => e.pole) as number[];
    selectedIdPoles = ArrayToolbox.removeDuplicateInArray(selectedIdPoles);

    let unselectedIdPoles = services
        .filter(e => idServices.indexOf(e.id) === -1 && e.pole !== undefined && e.pole !== null)
        .map(e => e.pole) as number[];
    unselectedIdPoles = ArrayToolbox.removeDuplicateInArray(unselectedIdPoles);

    return ArrayToolbox.diffArrays(selectedIdPoles, unselectedIdPoles);
}

function getIdServicesFromIdPoles({
    idServices,
    services,
    idPoles
}: {
    idServices: number[];
    services: IService[];
    idPoles: number[];
}): number[] {
    const idCurrentPoles = getIdPolesFromIdServices({ services, idServices });
    const idPolesRemoved = ArrayToolbox.diffArrays(idCurrentPoles, idPoles);

    const idServicesToBeRemoved = services
        .filter(e => e.pole !== undefined && e.pole !== null && idPolesRemoved.indexOf(e.pole) !== -1)
        .map(e => e.id);
    const idServicesAfterRemove = ArrayToolbox.diffArrays(idServices, idServicesToBeRemoved);

    const idServicesToBeAdded = services
        .filter(e => e.pole !== undefined && e.pole !== null && idPoles.indexOf(e.pole) !== -1)
        .map(e => e.id);

    const idServicesAfterAdd = idServicesAfterRemove.concat(idServicesToBeAdded);
    return ArrayToolbox.removeDuplicateInArray(idServicesAfterAdd);
}

const NotificationServiceView: React.FC<IPropsNotificationServiceView> = props => {
    const institution = props.institution;
    const idServices = props.idServices ? props.idServices : [];

    const polesIds = useSelector(poleIdsSelector);
    const polesById = useSelector(poleByIdSelector);
    let poles = getModelsFromDict(polesById, polesIds).filter((pole: IPole): boolean => {
        return pole.institution.includes(institution.id);
    });

    const servicesIds = useSelector(serviceIdsSelector);
    const servicesById = useSelector(serviceByIdSelector);
    let services = getModelsFromDict(servicesById, servicesIds).filter((service: IService): boolean => {
        return service.institution.includes(institution.id);
    });

    const idPoles = services
        .filter(e => e.institution.indexOf(institution.id) !== -1)
        .filter(service => idServices && idServices.indexOf(service.id) !== -1)
        .map(service => service.pole)
        .filter(e => !Equality.isNullOrUndefined(e));

    if (props.isReadingOnly) {
        if (idPoles.length !== 0) {
            poles = poles.filter(pole => idPoles.map(ap => ap === pole.id).reduce((p, c) => p || c, false));
        }

        if (idServices && idServices.length !== 0) {
            services = services.filter(service =>
                idServices.map(ap => ap === service.id).reduce((p, c) => p || c, false)
            );
        }
    }
    return (
        <div>
            <FormService
                institution={props.institution}
                poles={poles}
                services={services}
                onSelectPole={idPoles => {
                    const idServicesFromPoles = getIdServicesFromIdPoles({
                        idServices,
                        idPoles,
                        services
                    });
                    props.onSelectService(idServicesFromPoles);
                }}
                onSelectService={props.onSelectService}
                idServices={idServices}
                isReadingOnly={props.isReadingOnly}
                lang={props.lang}
            />
        </div>
    );
};

function FormService({
    institution,
    poles = [],
    services = [],
    idServices,
    onSelectPole,
    onSelectService,
    isReadingOnly,
    lang
}: {
    poles: IPole[];
    services: IService[];
    institution: IInstitution;
    idServices: number[];
    onSelectService: (idServices: number[]) => void;
    onSelectPole: (idPoles: number[]) => void;
    isReadingOnly: boolean;
    lang: string;
}): JSX.Element {
    const hasPole = poles.length > 0;

    const idPoles = getIdPolesFromIdServices({ services, idServices });

    return (
        <div key={institution.id}>
            <div>
                <p className="text-comet-title margin-bottom-s">{institution.name}</p>
                <div className="row">
                    {hasPole ? (
                        <div className="col-6">
                            <InputPole
                                idPoles={idPoles}
                                onSelectPole={onSelectPole}
                                poles={poles}
                                isReadingOnly={isReadingOnly}
                                lang={lang}
                            />
                        </div>
                    ) : null}

                    <div className={hasPole ? "col-6" : "col-12"}>
                        <InputService
                            idServices={idServices}
                            onSelectService={onSelectService}
                            services={services}
                            isReadingOnly={isReadingOnly}
                            lang={lang}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotificationServiceView;
