import moment from "moment";
import { Equality } from "npm-medgo-toolbox";
import { decodeToken } from "../../../toolbox/token";
import { fetchInstitutionHasAdmins } from "../../service/institutionHasAdmin.service";
import { fetchSignUpCodes } from "../../service/signUpCode.service";
import { isReactProductionEnviroment, isReactDevelopmentEnvironment } from "../../../toolbox/environment";
import { PRODUCTION_DOMAIN, PRE_PRODUCTION_DOMAIN } from "../../../Constant/Domain.constant";
import { Url } from "npm-medgo-toolbox";

export function checkAndSaveTokensFromUrl(): void {
    const tokenInUrl = Url.getParamFromUrl("token");
    const SSOAccessTokenInUrl = Url.getParamFromUrl("SSOAccessToken");
    if (tokenInUrl && !Equality.isNullOrUndefined(tokenInUrl)) {
        localStorage.setItem("token", tokenInUrl);
        localStorage.removeItem("SSOAccessToken");
    }
    if (SSOAccessTokenInUrl && !Equality.isNullOrUndefined(SSOAccessTokenInUrl)) {
        localStorage.setItem("SSOAccessToken", SSOAccessTokenInUrl);
    }
}

function isAdminLoggedInViaSSO(): boolean {
    const SSOAccessTokenInLocalStorage = localStorage.getItem("SSOAccessToken");
    if (SSOAccessTokenInLocalStorage && !Equality.isNullOrUndefined(SSOAccessTokenInLocalStorage)) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage);
        if (decodedToken && decodedToken.exp) {
            return true;
        }
    }
    return false;
}

function needtoLogInViaSSO(): boolean {
    const SSOAccessTokenInLocalStorage = localStorage.getItem("SSOAccessToken");
    if (SSOAccessTokenInLocalStorage && !Equality.isNullOrUndefined(SSOAccessTokenInLocalStorage)) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage);
        if (decodedToken && decodedToken.exp) {
            if (moment().isAfter(moment.unix(decodedToken.exp))) {
                return true;
            }
            return false;
        }
        return true;
    }
    return false;
}

async function logInAgainViaSSO(): Promise<void> {
    const currentUrl = window.location.href;
    document.cookie = `shouldReturnTo=${currentUrl};domain=${
        isReactProductionEnviroment() ? PRODUCTION_DOMAIN : isReactDevelopmentEnvironment() ? PRE_PRODUCTION_DOMAIN : ""
    };path=/`;
    const SSOAccessTokenInLocalStorage = localStorage.getItem("SSOAccessToken");
    if (SSOAccessTokenInLocalStorage) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage);
        const idAdmin = decodedToken.adminId;
        const adminInstitutions = await fetchInstitutionHasAdmins({
            query: {
                admin: idAdmin
            }
        });
        const idInstitution = adminInstitutions[0].institution;
        const signUpCodes = await fetchSignUpCodes({
            query: {
                institutions: idInstitution
            },
            select: ["code"]
        });
        const code = signUpCodes[0].code;
        window.location.href = `${process.env.REACT_APP_SERVICE_AUTH_URL}/sso?institutionCode=${code}`;
    }
}

export async function handleSSOAdminIfNeeded(): Promise<void> {
    if (!isAdminLoggedInViaSSO()) {
        return;
    }
    if (!needtoLogInViaSSO()) {
        return;
    }
    await logInAgainViaSSO();
}
