import AxiosInstance from "../../modules/axios/axios\.instance";
import { PossibleApis, PossibleUpdates } from "../../Constant/Api.constant";

export function updateFactory<T>(
    modalName: PossibleUpdates,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (id: number, newObject: T) => Promise<T> {
    return async (id: number, newObject: T): Promise<T> => {
        const res = await AxiosInstance.put(`${api}/api/v${apiVersion}/${modalName}/${id}`, newObject);
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data;
    };
}

export function updateSubFieldFactory<T>(
    modalName: PossibleUpdates,
    api: string = PossibleApis.medgoApi,
    apiVersion: number = 2
): (id: number, newObject: T) => Promise<T> {
    return async (id: number, newObject: T): Promise<T> => {
        const res = await AxiosInstance.put(
            `${api}/api/v${apiVersion}/${modalName.replace(":id", id.toString())}`,
            newObject
        );
        const data = res.data && res.data.data ? res.data.data : res.data;
        return data;
    };
}

export function simpleUpdateFactory<T, ResponseType>(
    url: string,
    api: string = PossibleApis.medgoApi
): (updateData: T) => Promise<ResponseType> {
    return async (updateData: T): Promise<ResponseType> => {
        const res = await AxiosInstance.put(`${api}/${url}`, updateData);
        return res.data && res.data.data ? res.data.data : res.data;
    };
}
