enum ScheduleActionTypes {
    FETCH_SCHEDULES = "FETCH_SCHEDULES",
    SET_SCHEDULES = "SET_SCHEDULES",
    FETCH_SCHEDULES_COUNT = "FETCH_SCHEDULES_COUNT",
    SET_SELECTED_SCHEDULE = "SET_SELECTED_SCHEDULE",
    SET_SELECTED_SCHEDULE_MULTI = "SET_SELECTED_SCHEDULE_MULTI",
    SET_IS_LOADING_SCHEDULE = "SET_IS_LOADING_SCHEDULE",
    SET_SCHEDULE_ORDER = "SET_SCHEDULE_ORDER",
    INSERT_SCHEDULE = "INSERT_SCHEDULE",
    UPDATE_SCHEDULE = "UPDATE_SCHEDULE",
    DELETE_SCHEDULE = "DELETE_SCHEDULE",
    SET_SCHEDULE_ERROR_CODE = "SET_SCHEDULE_ERROR_CODE",
    INSERT_INSTITUTION_HAS_SCHEDULE = "INSERT_INSTITUTION_HAS_SCHEDULE"
}
export default ScheduleActionTypes;
