import { IReduxAction } from "../store.type";
import InstitutionGroupTypes from "../type/institutionGroup.type";
import {
    fetchInstitutionGroups,
    insertInstitutionGroup,
    updateInstitutionGroup,
    deleteInstitutionGroup
} from "../../service/institutionGroup.service";
import { IInstitutionGroup, INormalizedInstitutionGroups } from "../../../Model/InstitutionGroup";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionGroupsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionGroups = await fetchInstitutionGroups(query);
            dispatch({
                type: InstitutionGroupTypes.FETCH_INSTITUTIONGROUPS,
                payload: {
                    byId: institutionGroups.reduce(
                        (p: INormalizedInstitutionGroups, c: IInstitutionGroup): INormalizedInstitutionGroups => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionGroups.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionGroupAction(
    institutionGroup: IInstitutionGroup
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await insertInstitutionGroup(institutionGroup);
            dispatch({
                type: InstitutionGroupTypes.INSERT_INSTITUTIONGROUP
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionGroupAction(
    id: number,
    institutionGroup: IInstitutionGroup
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateInstitutionGroup(id, institutionGroup);
            dispatch({
                type: InstitutionGroupTypes.UPDATE_INSTITUTIONGROUP
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionGroupAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionGroup(id);
            dispatch({
                type: InstitutionGroupTypes.DELETE_INSTITUTIONGROUP
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionGroupsAction(
    dict: INormalizedInstitutionGroups
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_INSTITUTIONGROUPS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionGroupAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_SELECTED_INSTITUTIONGROUP,
            payload: id
        });
    };
}

export function setSelectedInstitutionGroupMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_SELECTED_INSTITUTIONGROUP_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionGroupAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_INSTITUTIONGROUP,
            payload: isLoading
        });
    };
}
export function setInstitutionGroupOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_INSTITUTIONGROUP_ORDER,
            payload: idsSorted
        });
    };
}
