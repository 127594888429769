function isTokenOk(this: any): void {
    const anonymousPaths = ["/health"];
    const token = localStorage.getItem("token") as string;
    if (anonymousPaths.includes(window.location.pathname)) {
        return;
    }
    const ssoAccessToken = localStorage.getItem("SSOAccessToken") as string;
    this.props.setTokenAction(token ? token : "", ssoAccessToken ? ssoAccessToken : "");
}

export function implementHookIsTokenOk(container: IImplementHookIsTokenOk): void {
    container.isTokenOk = (): void => isTokenOk.bind(container)();
}
export interface IImplementHookIsTokenOk {
    isTokenOk: () => void;
}
