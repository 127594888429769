import { IReduxAction, IReduxInitialState } from "../store.type";
import SpecialtyTypes from "../type/specialty.type";
import { ISpecialty } from "../../../Model/Specialty";

const initialState: IReduxInitialState<ISpecialty> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false,
    allById: {},
    allIds: []
};

export default function specialtyReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case SpecialtyTypes.FETCH_SPECIALTIES:
        case SpecialtyTypes.SET_SPECIALTIES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case SpecialtyTypes.SET_SELECTED_SPECIALTY: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case SpecialtyTypes.SET_SELECTED_SPECIALTY_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case SpecialtyTypes.SET_IS_LOADING_SPECIALTY: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case SpecialtyTypes.SET_SPECIALTY_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case SpecialtyTypes.FETCH_ALLSPECIALTIES: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case SpecialtyTypes.INSERT_INSTITUTION_HAS_SPECIALTY: {
            const specialty = state.byId[action.payload.specialty]
                ? state.byId[action.payload.specialty]
                : state.allById[action.payload.specialty];
            const idInstitutions =
                state.byId[action.payload.specialty] && state.byId[action.payload.specialty].institution
                    ? state.byId[action.payload.specialty].institution
                    : state.allById[action.payload.specialty].institution;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [specialty.id]: {
                        ...specialty,
                        institution: [...idInstitutions, action.payload.institution]
                    }
                },
                ids: state.ids.includes(action.payload.specialty)
                    ? [...state.ids]
                    : [...state.ids, action.payload.specialty]
            };
        }
        default:
            return state;
    }
}
