import { IReduxAction } from "../store.type";
import InstitutionOptionChurnTypes from "../type/institutionOptionChurn.type";
import {
    fetchInstitutionOptionChurns,
    fetchInstitutionOptionChurnsCount,
    insertInstitutionOptionChurn,
    updateInstitutionOptionChurn,
    deleteInstitutionOptionChurn
} from "../../service/institutionOptionChurn.service";
import { IInstitutionOptionChurn, INormalizedInstitutionOptionChurns } from "../../../Model/InstitutionOptionChurn";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionOptionChurnsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionOptionChurns = await fetchInstitutionOptionChurns(query);
            dispatch({
                type: InstitutionOptionChurnTypes.FETCH_INSTITUTIONOPTIONCHURNS,
                payload: {
                    byId: institutionOptionChurns.reduce(
                        (
                            p: INormalizedInstitutionOptionChurns,
                            c: IInstitutionOptionChurn
                        ): INormalizedInstitutionOptionChurns => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionOptionChurns.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionOptionChurnsCountAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionOptionChurnsCount(query);
            dispatch({
                type: InstitutionOptionChurnTypes.FETCH_INSTITUTIONOPTIONCHURNS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOptionChurnAction(
    institutionOptionChurn: IInstitutionOptionChurn
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOptionChurn = await insertInstitutionOptionChurn(institutionOptionChurn);
            dispatch({
                type: InstitutionOptionChurnTypes.INSERT_INSTITUTIONOPTIONCHURN,
                payload: newInstitutionOptionChurn
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOptionChurnAction(
    id: number,
    institutionOptionChurn: IInstitutionOptionChurn
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionOptionChurn = await updateInstitutionOptionChurn(id, institutionOptionChurn);
            dispatch({
                type: InstitutionOptionChurnTypes.UPDATE_INSTITUTIONOPTIONCHURN,
                payload: updatedInstitutionOptionChurn
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOptionChurnAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOptionChurn(id);
            dispatch({
                type: InstitutionOptionChurnTypes.DELETE_INSTITUTIONOPTIONCHURN
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOptionChurnsAction(
    dict: INormalizedInstitutionOptionChurns
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_INSTITUTIONOPTIONCHURNS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOptionChurnAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_SELECTED_INSTITUTIONOPTIONCHURN,
            payload: id
        });
    };
}

export function setSelectedInstitutionOptionChurnMultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_SELECTED_INSTITUTIONOPTIONCHURN_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionOptionChurnAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_IS_LOADING_INSTITUTIONOPTIONCHURN,
            payload: isLoading
        });
    };
}
export function setInstitutionOptionChurnOrderAction(
    idsSorted: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_INSTITUTIONOPTIONCHURN_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOptionChurnErrorCodeAction(
    errorCode: number
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionChurnTypes.SET_INSTITUTIONOPTIONCHURN_ERROR_CODE,
            payload: errorCode
        });
    };
}
