import { IReduxAction, IReduxInitialState } from "../store.type";
import ServiceTypes from "../type/service.type";
import { IService } from "../../../Model/Service";

const initialState: IReduxInitialState<IService> = {
    byId: {},
    ids: [],
    allById: {},
    allIds: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function serviceReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ServiceTypes.FETCH_SERVICES:
        case ServiceTypes.SET_SERVICES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case ServiceTypes.SET_SELECTED_SERVICE: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case ServiceTypes.SET_SELECTED_SERVICE_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case ServiceTypes.SET_IS_LOADING_SERVICE: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case ServiceTypes.SET_SERVICE_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case ServiceTypes.FETCH_ALL_SERVICES: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case ServiceTypes.INSERT_SERVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case ServiceTypes.INSERT_INSTITUTION_HAS_SERVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.idService]: {
                        ...state.byId[action.payload.idService],
                        institution: [
                            ...(state.byId[action.payload.idService].institution || []),
                            action.payload.idInstitution
                        ]
                    }
                }
            };
        }

        case ServiceTypes.UPDATE_SERVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        default:
            return state;
    }
}
