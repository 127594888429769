import { IReduxAction } from "../store.types";
import NotificationActionTypes from "../type/notification.type";

export interface INotification {
    message?: string;
    color?: "primary" | "success" | "info" | "warning" | "danger" | "link";
    icon?: string;
    isVisible?: boolean;
}

const initialState: INotification = {
    message: "",
    color: "primary",
    icon: "",
    isVisible: false
};

export default function notificationReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATION_MESSAGE:
            return {
                ...state,
                ...action.payload,
                isVisible: true
            };

        case NotificationActionTypes.SET_NOTIFICATION_HIDE:
            return {
                ...state,
                isVisible: false
            };
        default:
            return state;
    }
}
