import React, { Component } from "react";
import { connect } from "react-redux";
import { IBackOfficeTable, EBackOfficeTableName } from "../../constants/Table.constants";
import BoxComponent from "./Box.component";
import { EBackOfficeColumnType, IBackOfficeColumn } from "../../constants/Column.constants";
import { getSelectCheckboxOptions } from "../form/FormLabelInput.component";
import { setFormKeyValueAction } from "../../store/action/form.action";
import { ILang } from "../../lang/Lang";
interface IReduxPropsColumnView {
    lang: ILang;
    modifyingElement: any;
    formDictionary: any;
}
interface IPropsColumnView {
    index: number;
    column: IBackOfficeColumn;
    element: any;
    backOfficeTable: IBackOfficeTable;
    selectCheckboxListOptions?: { [key: string]: { [key: string]: any[] } };
}
interface IReduxActionColumnView {
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
}
function RenderField({
    column,
    index,
    element,
    backOfficeTable,
    selectCheckboxListOptions
}: IPropsColumnView & IReduxPropsColumnView): JSX.Element {
    if (column.type === EBackOfficeColumnType.selectCheckbox) {
        return (
            <td key={`header_${index}`} className={"td-line"}>
                <BoxComponent
                    value={element[column.name]}
                    column={column}
                    table={backOfficeTable.name[0]}
                    selectCheckboxListOptions={getSelectCheckboxOptions(
                        selectCheckboxListOptions,
                        backOfficeTable.name[0],
                        column.name
                    )}
                />
            </td>
        );
    }
    return (
        <td
            key={`header_${index}`}
            className={` ${column.type === EBackOfficeColumnType.boolean ? "td-icon" : "td-line"}`}
        >
            <BoxComponent value={element[column.name]} column={column} table={backOfficeTable.name[0]} />
        </td>
    );
}
class ColumnView extends Component<IPropsColumnView & IReduxPropsColumnView & IReduxActionColumnView> {
    render(): JSX.Element {
        return <RenderField {...this.props} />;
    }
}

export default connect(
    (centralState: any): IReduxPropsColumnView => ({
        lang: centralState.language.lang,
        modifyingElement: centralState.tables.modifyingElement,
        formDictionary: centralState.form.formDictionary
    }),
    {
        setFormKeyValueAction
    }
)(ColumnView);
