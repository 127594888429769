import { IReduxAction } from "../store.types";
import TableActionTypes from "../type/table.type";

const initialState: {
    pageNumber: number;
    saveElementDictionary: object;
    modifyingElement: any;
    idItemToBeDeleted: number;
    selectedTab: number;
} = {
    pageNumber: 1,
    saveElementDictionary: {},
    modifyingElement: null,
    idItemToBeDeleted: -1,
    selectedTab: 0
};
export default function tableReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case TableActionTypes.SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case TableActionTypes.SET_CHECKED_ELEMENTS_DICT:
            return {
                ...state,
                modifyingElement: action.payload
            };
        case TableActionTypes.SET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload
            };
        case TableActionTypes.SET_ELEMENT_ID_TO_DELETE:
            return {
                ...state,
                idItemToBeDeleted: action.payload
            };
        case TableActionTypes.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload
            };
        case TableActionTypes.RESET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: 0
            };
        default:
            return state;
    }
}
