import { IReduxAction } from "../store.types";
import NotificationActionTypes from "../type/notification.type";

export function setNotificationMessage(message: any): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationActionTypes.SET_NOTIFICATION_MESSAGE,
            payload: message
        });
    };
}

export function setNotificationHide(): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: NotificationActionTypes.SET_NOTIFICATION_HIDE
        });
    };
}
