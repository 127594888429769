import { connect } from "react-redux";
import {
    setInstitutionOptionMissionsAction,
    fetchInstitutionOptionMissionsAction,
    setIsLoadingInstitutionOptionMissionAction
} from "../../store/action/institutionOptionMission.action";
import { IInstitutionOptionMission } from "../../../Model/InstitutionOptionMission";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionOptionMissions } from "../../../Model/InstitutionOptionMission";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOptionMission extends IReduxPropsTable {
    idInstitutionOptionMissions: number[];
    institutionOptionMissionDict: INormalizedInstitutionOptionMissions;
    idInstitutions: number[];
}

interface IPropsInstitutionOptionMission
    extends IReduxPropsInstitutionOptionMission,
        IReduxActionsInstitutionOptionMission,
        IPropsTable {}

interface IReduxActionsInstitutionOptionMission extends IReduxActionsTable {
    setInstitutionOptionMissionsAction: (elements: IInstitutionOptionMission[]) => any;
    fetchInstitutionOptionMissionsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOptionMissionAction: (isLoading: boolean) => any;
}

class TableInstitutionOptionMissionContainer extends TableComponent<IPropsInstitutionOptionMission> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOptionMissionAction(true);
        if (this.props.idInstitutionOptionMissions.length === 0)
            await this.props.fetchInstitutionOptionMissionsAction(params);
        this.props.setIsLoadingInstitutionOptionMissionAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOptionMissionsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOptionMissionDict, this.props.idInstitutionOptionMissions);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOptionMission =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOptionMissions: centralState.institutionOptionMission.ids,
            institutionOptionMissionDict: centralState.institutionOptionMission.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionOptionMissionsAction,
        fetchInstitutionOptionMissionsAction,
        setIsLoadingInstitutionOptionMissionAction
    })
)(TableInstitutionOptionMissionContainer);
