import { IReduxAction } from "../store.types";
import AuthTypes from "../type/auth.type";

const initialState: any = {
    user: {},
    token: "",
    ssoAccessToken: "",
    isLoadingFetchMe: false
};
export default function authReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AuthTypes.FETCH_ME:
            return {
                ...state,
                user: action.payload
            };
        case AuthTypes.IS_LOADING_FETCH_ME:
            return {
                ...state,
                isLoadingFetchMe: action.payload
            };
        case AuthTypes.SET_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                ssoAccessToken: action.payload.ssoAccessToken
            };
        default:
            return state;
    }
}
