import { IReduxAction } from "../store.type";
import InstitutionHasOAuth2Types from "../type/institutionHasOAuth2.type";
import {
    fetchInstitutionHasOAuth2s,
    fetchInstitutionHasOAuth2sCount,
    insertInstitutionHasOAuth2,
    updateInstitutionHasOAuth2,
    deleteInstitutionHasOAuth2
} from "../../service/institutionHasOAuth2.service";
import { IInstitutionHasOAuth2, INormalizedInstitutionHasOAuth2s } from "../../../Model/InstitutionHasOAuth2";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { Oauth2ActionStatus } from "../reducer/institutionHasOAuth2.reducer";

export function fetchInstitutionHasOAuth2sAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.None
            });
            const institutionHasOAuth2s = await fetchInstitutionHasOAuth2s(query);
            dispatch({
                type: InstitutionHasOAuth2Types.FETCH_INSTITUTIONHASOAUTH2S,
                payload: {
                    byId: institutionHasOAuth2s.reduce(
                        (
                            p: INormalizedInstitutionHasOAuth2s,
                            c: IInstitutionHasOAuth2
                        ): INormalizedInstitutionHasOAuth2s => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionHasOAuth2s.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionHasOAuth2sCountAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.None
            });
            const count = await fetchInstitutionHasOAuth2sCount(query);
            dispatch({
                type: InstitutionHasOAuth2Types.FETCH_INSTITUTIONHASOAUTH2S_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasOAuth2Action(
    institutionHasOAuth2: IInstitutionHasOAuth2
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.None
            });
            const newInstitutionHasOAuth2 = await insertInstitutionHasOAuth2(institutionHasOAuth2);
            dispatch({
                type: InstitutionHasOAuth2Types.INSERT_INSTITUTIONHASOAUTH2,
                payload: newInstitutionHasOAuth2
            });
            return;
        } catch (err) {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.Failed
            });
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionHasOAuth2Action(
    id: number,
    institutionHasOAuth2: IInstitutionHasOAuth2
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.None
            });
            const updatedInstitutionHasOAuth2 = await updateInstitutionHasOAuth2(id, institutionHasOAuth2);
            dispatch({
                type: InstitutionHasOAuth2Types.UPDATE_INSTITUTIONHASOAUTH2,
                payload: updatedInstitutionHasOAuth2
            });
            return;
        } catch (err) {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.Failed
            });
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionHasOAuth2Action(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
                payload: Oauth2ActionStatus.None
            });
            await deleteInstitutionHasOAuth2(id);
            dispatch({
                type: InstitutionHasOAuth2Types.DELETE_INSTITUTIONHASOAUTH2
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionHasOAuth2sAction(
    dict: INormalizedInstitutionHasOAuth2s
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2S,
            payload: dict
        });
    };
}

export function setSelectedInstitutionHasOAuth2Action(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_SELECTED_INSTITUTIONHASOAUTH2,
            payload: id
        });
    };
}

export function setSelectedInstitutionHasOAuth2MultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_SELECTED_INSTITUTIONHASOAUTH2_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionHasOAuth2Action(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_IS_LOADING_INSTITUTIONHASOAUTH2,
            payload: isLoading
        });
    };
}
export function setInstitutionHasOAuth2OrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionHasOAuth2ErrorCodeAction(
    errorCode: Oauth2ActionStatus
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE,
            payload: errorCode
        });
    };
}
