import { ISpecialty } from "../../Model/Specialty";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const SpecialtyDefaultSelect = ["id", "name", "institution", "job"];

export async function fetchSpecialties(query: IQueryParams): Promise<ISpecialty[]> {
    const specialtys = await postFetchFactory<ISpecialty>(PossibleFetchs.specialty, PossibleApis.medgoApi)(
        {
            select: SpecialtyDefaultSelect
        },
        query
    );
    return specialtys;
}

export async function insertInstitutionHasSpecialty(specialty: ISpecialty): Promise<ISpecialty> {
    const specialtys = await postFactory<ISpecialty>(PossiblePosts.specialty, PossibleApis.medgoApi)(specialty);
    return specialtys;
}

export async function updateSpecialty(id: number, specialty: ISpecialty): Promise<ISpecialty> {
    const specialtys = await updateFactory<ISpecialty>(PossibleUpdates.specialty, PossibleApis.medgoApi)(id, specialty);
    return specialtys;
}

export async function deleteSpecialty(id: number): Promise<ISpecialty> {
    const specialtys = await deleteFactory<ISpecialty>(PossibleDeletes.specialty, PossibleApis.medgoApi)(id);
    return specialtys;
}
