import { IInterimAgency } from "../../Model/InterimAgency";
import { PossibleApis } from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import axiosInstance from "../../modules/axios/axios.instance";
import { isArray, isNil, uniq } from "lodash";
import { AxiosError } from "axios";

export interface SubscriptionAgencyResponse {
    name: string;
}
export interface SpecialtyResponseDto {
    id: string;
}
export interface SubscriptionJobDetailsResponse {
    id: string;
    specialties: SpecialtyResponseDto[];
}
export interface SubscriptionListItemResponseDto {
    id: string;
    name: string;
    idLegacySubscription: string;
    idClient: string;
    agency: SubscriptionAgencyResponse;
    delay: number;
    lang: "fr" | "en" | "de" | "es" | "nl";
    autoAccept: boolean;
    emails: string[];
    jobs: SubscriptionJobDetailsResponse[];
    createdAt: string;
}
export interface ListSubscriptionsResponseDto {
    result: SubscriptionListItemResponseDto[];
    total: number;
    cursor?: string;
}
const fetchInterimAgencysForInstitution = async (idInstitution: number) => {
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency/subscriptions`;
    return axiosInstance
        .get<ListSubscriptionsResponseDto>(url, {
            params: {
                take: 500
            }
        })
        .then(response => {
            return response.data.result.map(subscription => {
                const jobs = uniq(subscription.jobs.map(job => parseInt(job.id), 10));
                const specialties = uniq(
                    subscription.jobs.flatMap(job => job.specialties.map(s => parseInt(s.id, 10)))
                );
                return {
                    id: parseInt(subscription.idLegacySubscription, 10),
                    idSubscription: subscription.id,
                    subscriptionName: subscription.name,
                    agencyName: subscription.agency.name,
                    institution: idInstitution,
                    nbMinutesAfterLaunchingRequest: subscription.delay,
                    hasAutoValidation: subscription.autoAccept,
                    email: subscription.emails.join(","),
                    jobs,
                    specialties
                };
            });
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 422) {
                return [];
            }
            throw err;
        });
};
export async function fetchInterimAgencys(query: IQueryParams): Promise<IInterimAgency[]> {
    const idInstitution = query.query.institution;
    if (isArray(idInstitution)) {
        return (await Promise.all(idInstitution.map(async id => fetchInterimAgencysForInstitution(id)))).flat();
    } else {
        return fetchInterimAgencysForInstitution(idInstitution);
    }
}

export async function insertInterimAgency(
    interimAgency: IInterimAgency
): Promise<{ id: string; idLegacyAgency: string }> {
    const payload = {
        name: interimAgency.subscriptionName,
        emails: interimAgency.email.split(",").map(email => email.trim()),
        delay: interimAgency.nbMinutesAfterLaunchingRequest,
        autoAccept: isNil(interimAgency.hasAutoValidation) ? false : interimAgency.hasAutoValidation,
        lang: "fr",
        jobs: interimAgency.jobs,
        specialties: interimAgency.specialties
    };
    const url = `${PossibleApis.bffBackoffice}/institutions/${interimAgency.institution}/options/agency/subscriptions`;
    const { data } = await axiosInstance.post<{ id: string; idLegacyAgency: string }>(url, payload);
    return data;
}

export async function updateInterimAgency(params: {
    id: number;
    idSubscription: string;
    idInstitution: number;
    interimAgency: IInterimAgency;
}): Promise<{ id: string }> {
    const { id, idInstitution, interimAgency, idSubscription } = params;
    const payload = {
        idLegacySubscription: id,
        name: interimAgency.subscriptionName,
        emails: interimAgency.email.split(",").map(email => email.trim()),
        delay: interimAgency.nbMinutesAfterLaunchingRequest,
        autoAccept: interimAgency.hasAutoValidation
    };
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency/subscriptions/${idSubscription}`;
    const { data } = await axiosInstance.patch<{ id: string }>(url, payload);
    return data;
}

export async function deleteInterimAgency(params: { idSubscription: string; idInstitution: number }): Promise<void> {
    const { idInstitution, idSubscription } = params;
    const url = `${PossibleApis.bffBackoffice}/institutions/${idInstitution}/options/agency/subscriptions/${idSubscription}`;
    await axiosInstance.delete<{ id: string }>(url);
}
