enum InstitutionOptionActionTypes {
    FETCH_INSTITUTIONOPTIONS = "FETCH_INSTITUTIONOPTIONS",
    SET_INSTITUTIONOPTIONS = "SET_INSTITUTIONOPTIONS",
    FETCH_INSTITUTIONOPTIONS_COUNT = "FETCH_INSTITUTIONOPTIONS_COUNT",
    SET_SELECTED_INSTITUTIONOPTION = "SET_SELECTED_INSTITUTIONOPTION",
    SET_SELECTED_INSTITUTIONOPTION_MULTI = "SET_SELECTED_INSTITUTIONOPTION_MULTI",
    SET_IS_LOADING_INSTITUTIONOPTION = "SET_IS_LOADING_INSTITUTIONOPTION",
    SET_INSTITUTIONOPTION_ORDER = "SET_INSTITUTIONOPTION_ORDER",
    INSERT_INSTITUTIONOPTION = "INSERT_INSTITUTIONOPTION",
    UPDATE_INSTITUTIONOPTION = "UPDATE_INSTITUTIONOPTION",
    DELETE_INSTITUTIONOPTION = "DELETE_INSTITUTIONOPTION",
    SET_INSTITUTIONOPTION_ERROR_CODE = "SET_INSTITUTIONOPTION_ERROR_CODE",
    FETCH_SLOT_CONFIGS = "FETCH_SLOT_CONFIGS"
}
export default InstitutionOptionActionTypes;
