enum TemplateActionTypes {
    FETCH_TEMPLATES = "FETCH_TEMPLATES",
    SET_TEMPLATES = "SET_TEMPLATES",
    FETCH_TEMPLATES_COUNT = "FETCH_TEMPLATES_COUNT",
    SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE",
    SET_SELECTED_TEMPLATE_MULTI = "SET_SELECTED_TEMPLATE_MULTI",
    SET_IS_LOADING_TEMPLATE = "SET_IS_LOADING_TEMPLATE",
    SET_TEMPLATE_ORDER = "SET_TEMPLATE_ORDER",
    INSERT_TEMPLATE = "INSERT_TEMPLATE",
    UPDATE_TEMPLATE = "UPDATE_TEMPLATE",
    DELETE_TEMPLATE = "DELETE_TEMPLATE",
    SET_TEMPLATE_ERROR_CODE = "SET_TEMPLATE_ERROR_CODE"
}
export default TemplateActionTypes;
