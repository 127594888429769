import { Equality } from "npm-medgo-toolbox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IInstitutionHasOAuth2 } from "../../../../Model/InstitutionHasOAuth2";
import {
    insertInstitutionHasOAuth2Action,
    updateInstitutionHasOAuth2Action
} from "../../../store/action/institutionHasOAuth2.action";

interface IFormData {
    clientId: string;
    clientSecret: string;
    authUrl: string;
    accessTokenUrl: string;
    responseType: string;
    usePKCE: boolean;
    userInfoUrl?: string;
    scope?: string;
    resource?: string;
}

interface OAuth2FormProps {
    formData: IFormData;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const useOAuth2Form = (idInstitution: number, persistedData?: IInstitutionHasOAuth2): OAuth2FormProps => {
    const dispatch = useDispatch();
    const formInitialState = {
        clientId: "",
        clientSecret: "",
        authUrl: "",
        accessTokenUrl: "",
        responseType: "",
        scope: "",
        resource: "",
        usePKCE: false
    };
    const [formData, setFormData] = useState<IFormData>(formInitialState);

    useEffect((): void => {
        if (Equality.isNullOrUndefined(persistedData)) {
            setFormData(formInitialState);
        } else {
            const data = persistedData as IFormData;
            setFormData({
                clientId: data.clientId,
                clientSecret: data.clientSecret,
                authUrl: data.authUrl,
                accessTokenUrl: data.accessTokenUrl,
                userInfoUrl: data.userInfoUrl,
                responseType: data.responseType,
                scope: data.scope,
                resource: data.resource,
                usePKCE: data.usePKCE
            });
        }
    }, [persistedData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData((prev): IFormData => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formData.clientId?.length === 0 || formData.authUrl?.length === 0 || formData.accessTokenUrl?.length === 0)
            return;

        if (!Equality.isNullOrUndefined(persistedData)) {
            const id = persistedData?.id as number;
            dispatch(
                updateInstitutionHasOAuth2Action(id, {
                    ...formData,
                    institution: idInstitution,
                    id,
                    shouldPropagateToGroup: true
                })
            );
        } else {
            dispatch(
                insertInstitutionHasOAuth2Action({
                    id: -1,
                    institution: idInstitution,
                    shouldPropagateToGroup: true,
                    ...formData
                })
            );
        }
    };

    return {
        formData,
        handleChange,
        handleSubmit
    };
};
