import { IReduxAction, IReduxInitialState } from "../store.type";
import JobTypes from "../type/job.type";
import { IJob } from "../../../Model/Job";

const initialState: IReduxInitialState<IJob> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false,
    allById: {},
    allIds: []
};

export default function jobReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case JobTypes.FETCH_JOBS:
        case JobTypes.SET_JOBS: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case JobTypes.SET_SELECTED_JOB: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case JobTypes.SET_SELECTED_JOB_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case JobTypes.SET_IS_LOADING_JOB: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case JobTypes.SET_JOB_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case JobTypes.FETCH_ALL_JOBS: {
            return {
                ...state,
                allById: action.payload.byId,
                allIds: action.payload.ids
            };
        }

        case JobTypes.INSERT_INSTITUTION_HAS_JOB: {
            let job = state.byId[action.payload.job]
                ? state.byId[action.payload.job]
                : state.allById[action.payload.job];

            let institution = state.byId[action.payload.job] ? job.institution : [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [job.id]: {
                        ...job,
                        ...action.payload,
                        institution: [...institution, action.payload.institution]
                    }
                },
                ids: state.ids.includes(job.id) ? [...state.ids] : [...state.ids, job.id]
            };
        }

        default:
            return state;
    }
}
