import { connect } from "react-redux";
import {
    setInstitutionOptionWorkersAction,
    fetchInstitutionOptionWorkersAction,
    setIsLoadingInstitutionOptionWorkerAction
} from "../../store/action/institutionOptionWorker.action";
import { IInstitutionOptionWorker } from "../../../Model/InstitutionOptionWorker";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionOptionWorkers } from "../../../Model/InstitutionOptionWorker";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOptionWorker extends IReduxPropsTable {
    idInstitutionOptionWorkers: number[];
    institutionOptionWorkerDict: INormalizedInstitutionOptionWorkers;
    idInstitutions: number[];
}

interface IPropsInstitutionOptionWorker
    extends IReduxPropsInstitutionOptionWorker,
        IReduxActionsInstitutionOptionWorker,
        IPropsTable {}

interface IReduxActionsInstitutionOptionWorker extends IReduxActionsTable {
    setInstitutionOptionWorkersAction: (elements: IInstitutionOptionWorker[]) => any;
    fetchInstitutionOptionWorkersAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOptionWorkerAction: (isLoading: boolean) => any;
}

class TableInstitutionOptionWorkerContainer extends TableComponent<IPropsInstitutionOptionWorker> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOptionWorkerAction(true);
        if (this.props.idInstitutionOptionWorkers.length === 0)
            await this.props.fetchInstitutionOptionWorkersAction(params);
        this.props.setIsLoadingInstitutionOptionWorkerAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOptionWorkersAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOptionWorkerDict, this.props.idInstitutionOptionWorkers);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOptionWorker =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOptionWorkers: centralState.institutionOptionWorker.ids,
            institutionOptionWorkerDict: centralState.institutionOptionWorker.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionOptionWorkersAction,
        fetchInstitutionOptionWorkersAction,
        setIsLoadingInstitutionOptionWorkerAction
    })
)(TableInstitutionOptionWorkerContainer);
