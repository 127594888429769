export interface IService {
    id: number;
    name: string;
    nameSMS?: string;
    pole?: number;
    code?: string;
    isOnForCreatingMission?: boolean;
    interfacingCode?: string;
    interfacingCode2?: string;
    interfacingCode3?: string;
    institution: number[];
    poleName: string;
    phone?: string;
    address?: string;
    building?: string;
    floor?: string;
    hasFood?: boolean;
    hasSmock?: boolean;
    minCaregiversPerShift?: number;
    bedsCapacity?: number;
    parking?: ServiceParking;
    software?: string;
    digicode?: string;
}
export interface INormalizedServices {
    [key: string]: IService;
}

export interface ICreateServiceRequest {
    service: number;
    institution: number;
}

export enum ServiceParking {
    PAID_PARKING = "PAID_PARKING",
    FREE_PARKING = "FREE_PARKING",
    NO_PARKING = "NO_PARKING"
}
