import { IReduxAction } from "../store.type";
import SpecialHourTypes from "../type/specialHour.type";
import {
    fetchSpecialHours,
    insertSpecialHour,
    updateSpecialHour,
    deleteSpecialHour,
    insertSpecialHourHasService,
    insertSpecialHourHasJob,
    fetchSpecialHourHasJob,
    fetchSpecialHourHasService,
    deleteSpecialHourHasJob,
    deleteSpecialHourHasService
} from "../../service/specialHour.service";
import { ISpecialHour, INormalizedSpecialHours } from "../../../Model/SpecialHour";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchSpecialHoursAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const specialHours = await fetchSpecialHours(query);
            dispatch({
                type: SpecialHourTypes.FETCH_SPECIALHOURS,
                payload: {
                    byId: specialHours.reduce(
                        (p: INormalizedSpecialHours, c: ISpecialHour): INormalizedSpecialHours => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: specialHours.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchSpecialHourHasJobAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<any> => {
        try {
            const specialHourHasJob = await fetchSpecialHourHasJob(query);
            dispatch({
                type: SpecialHourTypes.FETCH_SPECIALHOUR_HAS_JOB
            });
            return specialHourHasJob;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchSpecialHourHasServiceAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<any> => {
        try {
            const specialHourHasService = await fetchSpecialHourHasService(query);
            dispatch({
                type: SpecialHourTypes.FETCH_SPECIALHOUR_HAS_SERVICE
            });
            return specialHourHasService;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertSpecialHourAction(specialHour: ISpecialHour): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<ISpecialHour> => {
        try {
            let newSpecialHour = await insertSpecialHour(specialHour);
            specialHour.id = newSpecialHour.id;
            dispatch({
                type: SpecialHourTypes.INSERT_SPECIALHOUR,
                payload: specialHour
            });
            return specialHour;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertSpecialHourHasServiceAction(
    idSpecialHour: number,
    idService: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<ISpecialHour> => {
        try {
            let insertedSpecialHourHasService = await insertSpecialHourHasService(idSpecialHour, idService);
            dispatch({
                type: SpecialHourTypes.INSERT_SPECIALHOUR_HAS_SERVICE,
                payload: {
                    idSpecialHour,
                    idService
                }
            });
            return insertedSpecialHourHasService;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertSpecialHourHasJobAction(
    idSpecialHour: number,
    idJob: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<ISpecialHour> => {
        try {
            let insertedSpecialHourHasJob = await insertSpecialHourHasJob(idSpecialHour, idJob);
            dispatch({
                type: SpecialHourTypes.INSERT_SPECIALHOUR_HAS_JOB,
                payload: {
                    idSpecialHour,
                    idJob
                }
            });
            return insertedSpecialHourHasJob;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateSpecialHourAction(
    idSpecialHour: number,
    specialHour: ISpecialHour
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await updateSpecialHour(idSpecialHour, specialHour);
            dispatch({
                type: SpecialHourTypes.UPDATE_SPECIALHOUR,
                payload: { specialHour, idSpecialHour }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteSpecialHourAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteSpecialHour(id);
            dispatch({
                type: SpecialHourTypes.DELETE_SPECIALHOUR,
                payload: id
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteSpecialHourHasJobAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteSpecialHourHasJob(id);
            dispatch({
                type: SpecialHourTypes.DELETE_SPECIALHOUR_HAS_JOB
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteSpecialHourHasServiceAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteSpecialHourHasService(id);
            dispatch({
                type: SpecialHourTypes.DELETE_SPECIALHOUR_HAS_SERVICE
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setSpecialHoursAction(dict: INormalizedSpecialHours): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_SPECIALHOURS,
            payload: dict
        });
    };
}

export function setSelectedSpecialHourAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_SELECTED_SPECIALHOUR,
            payload: id
        });
    };
}

export function setSelectedSpecialHourMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_SELECTED_SPECIALHOUR_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingSpecialHourAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_IS_LOADING_SPECIALHOUR,
            payload: isLoading
        });
    };
}
export function setSpecialHourOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_SPECIALHOUR_ORDER,
            payload: idsSorted
        });
    };
}

export function setSpecialHourErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialHourTypes.SET_SPECIALHOUR_ERROR_CODE,
            payload: errorCode
        });
    };
}
