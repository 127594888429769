import { IInstitutionHasAdmin } from "../../Model/InstitutionHasAdmin";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionHasAdminDefaultSelect = [
    "id",
    "admin",
    "institution",
    "matricule",
    "position",
    "isActivated",
    "deactivatedAt"
];

export async function fetchInstitutionHasAdmins(query: IQueryParams): Promise<IInstitutionHasAdmin[]> {
    const institutionHasAdmins = await postFetchFactory<IInstitutionHasAdmin>(
        PossibleFetchs.institutionHasAdmin,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionHasAdminDefaultSelect
        },
        query
    );
    return institutionHasAdmins;
}

export async function insertInstitutionHasAdmin(
    institutionHasAdmin: IInstitutionHasAdmin
): Promise<IInstitutionHasAdmin> {
    return await postFactory<IInstitutionHasAdmin>(
        PossiblePosts.institutionHasAdmin,
        PossibleApis.medgoApi
    )(institutionHasAdmin);
}

export async function updateInstitutionHasAdmin(
    id: number,
    institutionHasAdmin: IInstitutionHasAdmin
): Promise<IInstitutionHasAdmin> {
    return await updateFactory<IInstitutionHasAdmin>(PossibleUpdates.institutionHasAdmin, PossibleApis.medgoApi)(
        id,
        institutionHasAdmin
    );
}

export async function deleteInstitutionHasAdmin(id: number): Promise<IInstitutionHasAdmin> {
    return await deleteFactory<IInstitutionHasAdmin>(PossibleDeletes.institutionHasAdmin, PossibleApis.medgoApi)(id);
}
