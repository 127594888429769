import { IReduxAction } from "../store.type";
import ExportTypes from "../type/export.type";
import { fetchExports, fetchExportsCount, insertExportLogOnExport } from "../../service/export.service";
import { IExport, INormalizedExports } from "../../../Model/Export";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { Moment } from "moment";
import moment from "moment";

export function fetchExportsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const exports = await fetchExports(query);
            dispatch({
                type: ExportTypes.FETCH_EXPORTS,
                payload: {
                    byId: exports.reduce((p: INormalizedExports, c: IExport): INormalizedExports => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: exports.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchExportsCountAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            dispatch({
                type: ExportTypes.SET_EXPORT_ERROR_CODE,
                payload: -1
            });
            const count = await fetchExportsCount(query);
            dispatch({
                type: ExportTypes.FETCH_EXPORTS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertExportAction(
    beginAt: Moment,
    endAt: Moment,
    idAdmin: number,
    idInstitutions: number[]
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newExports = await Promise.all(
                idInstitutions.map(
                    (idInstitution): Promise<IExport> =>
                        insertExportLogOnExport(beginAt, endAt, idAdmin, idInstitution, moment(), "export")
                )
            );
            dispatch({
                type: ExportTypes.INSERT_EXPORT,
                payload: newExports
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
