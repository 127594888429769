import { IReduxAction } from "../store.type";
import InstitutionOptionMissionTypes from "../type/institutionOptionMission.type";
import {
    fetchInstitutionOptionMissions,
    fetchInstitutionOptionMissionsCount,
    insertInstitutionOptionMission,
    updateInstitutionOptionMission,
    deleteInstitutionOptionMission
} from "../../service/institutionOptionMission.service";
import {
    IInstitutionOptionMission,
    INormalizedInstitutionOptionMissions
} from "../../../Model/InstitutionOptionMission";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchInstitutionOptionMissionsAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const institutionOptionMissions = await fetchInstitutionOptionMissions(query);
            dispatch({
                type: InstitutionOptionMissionTypes.FETCH_INSTITUTIONOPTIONMISSIONS,
                payload: {
                    byId: institutionOptionMissions.reduce(
                        (
                            p: INormalizedInstitutionOptionMissions,
                            c: IInstitutionOptionMission
                        ): INormalizedInstitutionOptionMissions => {
                            p[c.institution] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: institutionOptionMissions.map((e): number => e.institution)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function fetchInstitutionOptionMissionsCountAction(
    query: IQueryParams
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const count = await fetchInstitutionOptionMissionsCount(query);
            dispatch({
                type: InstitutionOptionMissionTypes.FETCH_INSTITUTIONOPTIONMISSIONS_COUNT,
                payload: count
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionOptionMissionAction(
    institutionOptionMission: IInstitutionOptionMission
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newInstitutionOptionMission = await insertInstitutionOptionMission(institutionOptionMission);
            dispatch({
                type: InstitutionOptionMissionTypes.INSERT_INSTITUTIONOPTIONMISSION,
                payload: newInstitutionOptionMission
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateInstitutionOptionMissionAction(
    institution: number,
    institutionOptionMission: IInstitutionOptionMission
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedInstitutionOptionMission = await updateInstitutionOptionMission(
                institution,
                institutionOptionMission
            );
            dispatch({
                type: InstitutionOptionMissionTypes.UPDATE_INSTITUTIONOPTIONMISSION,
                payload: updatedInstitutionOptionMission
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteInstitutionOptionMissionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteInstitutionOptionMission(id);
            dispatch({
                type: InstitutionOptionMissionTypes.DELETE_INSTITUTIONOPTIONMISSION
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setInstitutionOptionMissionsAction(
    dict: INormalizedInstitutionOptionMissions
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_INSTITUTIONOPTIONMISSIONS,
            payload: dict
        });
    };
}

export function setSelectedInstitutionOptionMissionAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_SELECTED_INSTITUTIONOPTIONMISSION,
            payload: id
        });
    };
}

export function setSelectedInstitutionOptionMissionMultiAction(
    ids: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_SELECTED_INSTITUTIONOPTIONMISSION_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingInstitutionOptionMissionAction(
    isLoading: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_IS_LOADING_INSTITUTIONOPTIONMISSION,
            payload: isLoading
        });
    };
}
export function setInstitutionOptionMissionOrderAction(
    idsSorted: number[]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_INSTITUTIONOPTIONMISSION_ORDER,
            payload: idsSorted
        });
    };
}

export function setInstitutionOptionMissionErrorCodeAction(
    errorCode: number
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionMissionTypes.SET_INSTITUTIONOPTIONMISSION_ERROR_CODE,
            payload: errorCode
        });
    };
}
