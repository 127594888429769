import { IInstitutionOptionContract } from "../../Model/InstitutionOptionContract";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionOptionContractDefaultSelect = [
    "institution",
    "coefficientValue",
    "hasDistinctionBetweenPartTimeAndFullTime"
];

export async function fetchInstitutionOptionContracts(query: IQueryParams): Promise<IInstitutionOptionContract[]> {
    const institutionOptionContracts = await postFetchFactory<IInstitutionOptionContract>(
        PossibleFetchs.institutionOptionContract,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionOptionContractDefaultSelect
        },
        query
    );
    return institutionOptionContracts;
}

export async function fetchInstitutionOptionContractsCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionOptionContract>(
        PossibleFetchs.institutionOptionContract,
        PossibleApis.medgoApi
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionOptionContract(
    institutionOptionContract: IInstitutionOptionContract
): Promise<IInstitutionOptionContract> {
    return await postFactory<IInstitutionOptionContract>(
        PossiblePosts.institutionOptionContract,
        PossibleApis.medgoApi
    )(institutionOptionContract);
}

export async function updateInstitutionOptionContract(
    id: number,
    institutionOptionContract: IInstitutionOptionContract
): Promise<IInstitutionOptionContract> {
    return await updateFactory<IInstitutionOptionContract>(
        PossibleUpdates.institutionOptionContract,
        PossibleApis.medgoApi
    )(id, institutionOptionContract);
}

export async function deleteInstitutionOptionContract(id: number): Promise<IInstitutionOptionContract> {
    return await deleteFactory<IInstitutionOptionContract>(
        PossibleDeletes.institutionOptionContract,
        PossibleApis.medgoApi
    )(id);
}
