import { ITemplate } from "../../Model/Template";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const TemplateDefaultSelect = [
    "id",
    "name",
    "institution",
    "id",
    "name",
    "pole",
    "service",
    "job",
    "specialty",
    "isFullTime"
];

export async function fetchTemplates(query: IQueryParams): Promise<ITemplate[]> {
    const templates = await postFetchFactory<ITemplate>(PossibleFetchs.template, PossibleApis.medgoApi)(
        {
            select: TemplateDefaultSelect
        },
        query
    );
    return templates;
}

export async function insertTemplate(template: ITemplate): Promise<ITemplate> {
    return await postFactory<ITemplate>(PossiblePosts.template, PossibleApis.medgoApi)(template);
}

export async function updateTemplate(id: number, template: ITemplate): Promise<ITemplate> {
    return await updateFactory<ITemplate>(PossibleUpdates.template, PossibleApis.medgoApi)(id, template);
}

export async function deleteTemplate(id: number): Promise<ITemplate> {
    return await deleteFactory<ITemplate>(PossibleDeletes.template, PossibleApis.medgoApi)(id);
}
