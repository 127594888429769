import { Download } from "npm-medgo-toolbox";
import { isNullOrUndefined } from "util";
import { getLang, isInAnyLang } from "../../modules/lang";
import { IBackOfficeTable } from "../../constants/Table.constants";
import Papa from "papaparse";
import store from "../../store";
import { setConfirmationModalDataAction, setShowConfirmationModalAction } from "../../store/action/form.action";
import { maxSizeCSV } from "../../constants/File.constants";
import { shouldDisplayInterface } from "../../../toolbox/permissions";
import { IInstitution } from "../../../Model/Institution";
import { getModelsFromDict } from "../../modules/store/util";

function convertArrayOfObjectsToCSV(array: any[], colNames: string, translatedColNames: string): string {
    let result: string;

    const columnDelimiter = ";";
    const lineDelimiter = "\n";
    const keys = colNames.split(columnDelimiter);
    stop;

    result = "";
    result += translatedColNames;
    result += lineDelimiter;

    array.forEach((item): void => {
        let ctr = 0;
        keys.forEach((key): void => {
            if (ctr > 0) result += columnDelimiter;

            result += isNullOrUndefined(item[key]) ? " " : item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

export function getTranslatedColNamesTableCSV(table: IBackOfficeTable): string {
    const lang = getLang();
    const state = store.getState();
    // @ts-ignore
    let colNames = table.baseColumnsForExport.map((key: string): string => lang[key]).join(";");
    if (state.institutions !== null && state.institutions !== undefined) {
        const institutionsStore = (state.institutions as unknown) as { byId: Record<string, any>; ids: number[] };
        const institutionArray = getModelsFromDict<IInstitution>(institutionsStore.byId, institutionsStore.ids);
        if (shouldDisplayInterface(institutionArray) && table.columnsInterfaceModuleForExport.length) {
            colNames =
                //@ts-ignore
                colNames +
                ";" +
                table.columnsInterfaceModuleForExport.map((key: string): string => lang[key]).join(";");
        }
    }
    return colNames;
}

export function downloadCSV(array: any[], colNames: string, translatedColnames: string, tableName: string): void {
    const lang = getLang();
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array, colNames, translatedColnames);
    if (csv == null) return;

    const filename = lang.exportName.replace("__NAME__", lang[tableName + "CamelCase"]);

    csv = `data:text/csv;charset=utf-8,${csv}`;

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
}

export function getColNamesStringForExport(table: IBackOfficeTable, cols: any[]): string {
    cols = [...cols];
    if (cols[0] !== null && cols[0] !== undefined && isInAnyLang("id", cols[0].name)) cols.shift(); // remove ID
    const sanitizedCols = table.getTableColNamesForExport(cols);
    let result = "";
    sanitizedCols.forEach((col): void => {
        if (result !== "") {
            result += ";";
        }
        result += col.name;
    });
    return result;
}

export function exportTemplateCSV(table: IBackOfficeTable): void {
    const lang = getLang();
    const template = table.generateTableCSVTemplate();
    var csv = Papa.unparse(template, { delimiter: ";" });
    // @ts-ignore
    Download.userDownloadFile(lang.templateName.replace("__NAME__", lang[table.name + "CamelCase"]), csv);
}

export function validateCSVFile(file: any): void {
    const lang = getLang();
    if (!file.name.endsWith(".csv")) throw { title: lang.wrongFileFormat, error: lang.onlyAcceptCSV };
    if (file.size > maxSizeCSV) throw { title: lang.fileTooLarge, error: lang.maxCSVSizeIs };
}

export function importTableFromCSV(table: IBackOfficeTable, file: any, IDInstitutions: number[]): void {
    var fileReader = new FileReader();
    fileReader.onload = (): void => {
        Papa.parse(fileReader.result as string, {
            complete: async (result: { data: any }): Promise<void> => {
                const lang = getLang();
                setConfirmationModalDataAction(
                    lang.importInProgress,
                    lang.importingPleaseWait,
                    (): void => {},
                    (): void => {},
                    "",
                    ""
                )(store.dispatch);
                setShowConfirmationModalAction(true)(store.dispatch);
                await table
                    .importSanitizedTableFromJSON(result, IDInstitutions)
                    .then((): void => {
                        setShowConfirmationModalAction(false)(store.dispatch);
                    })
                    .catch((error): void => {
                        setConfirmationModalDataAction(
                            error.title,
                            error.error,
                            (): void => {
                                setShowConfirmationModalAction(false)(store.dispatch);
                            },
                            (): void => {},
                            getLang().return,
                            ""
                        )(store.dispatch);
                    });
            },
            delimiter: ";"
        });
    };
    fileReader.readAsText(file);
}
