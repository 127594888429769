import { IReduxAction } from "../store.type";
import ServiceTypes from "../type/service.type";
import {
    fetchServices,
    insertService,
    insertInstitutionHasService,
    updateService,
    deleteService
} from "../../service/service.service";
import { IService, INormalizedServices } from "../../../Model/Service";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { INormalizedPoles } from "../../../Model/Pole";

export function fetchServicesAction(
    query: IQueryParams,
    poleDict?: INormalizedPoles
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            let services = await fetchServices(query);
            if (poleDict) {
                services.forEach((e: IService): void => {
                    e.poleName = e.pole ? poleDict[e.pole]?.name : "";
                });
            }
            dispatch({
                type: ServiceTypes.FETCH_SERVICES,
                payload: {
                    byId: services.reduce((p: INormalizedServices, c: IService): INormalizedServices => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: services.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
export function fetchAllServicesAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const services = await fetchServices(query);
        dispatch({
            type: ServiceTypes.FETCH_ALL_SERVICES,
            payload: {
                byId: services.reduce((p: INormalizedServices, c: IService): INormalizedServices => {
                    p[c.id] = c;
                    return p;
                }, {}),
                ids: services.map((e): number => e.id)
            }
        });
        return;
    };
}

export function insertServiceAction(
    service: IService,
    poleName: string
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<IService> => {
        try {
            let serviceObject = await insertService(service);
            serviceObject = Object.assign(serviceObject, {
                poleName
            });
            dispatch({
                type: ServiceTypes.INSERT_SERVICE,
                payload: serviceObject
            });
            return serviceObject;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasServiceAction(
    idService: number,
    idInstitution: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        insertInstitutionHasService(idService, idInstitution);
        dispatch({
            type: ServiceTypes.INSERT_INSTITUTION_HAS_SERVICE,
            payload: {
                idService,
                idInstitution
            }
        });
        return;
    };
}

export function updateServiceAction(
    id: number,
    service: IService,
    poleName: string
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            let updatedService = await updateService(id, service);
            updatedService = Object.assign(updatedService, {
                poleName,
                codeUF: updatedService.code
            });
            dispatch({
                type: ServiceTypes.UPDATE_SERVICE,
                payload: updatedService
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteServiceAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteService(id);
            dispatch({
                type: ServiceTypes.DELETE_SERVICE
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setServicesAction(dict: INormalizedServices): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_SERVICES,
            payload: dict
        });
    };
}

export function setSelectedServiceAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_SELECTED_SERVICE,
            payload: id
        });
    };
}

export function setSelectedServiceMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_SELECTED_SERVICE_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingServiceAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_IS_LOADING_SERVICE,
            payload: isLoading
        });
    };
}
export function setServiceOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_SERVICE_ORDER,
            payload: idsSorted
        });
    };
}
