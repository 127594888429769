import React from "react";
import { useSelector } from "react-redux";
import { HubloErrorMessageInfoBox, HubloJumbotron } from "@hublo/legacy-components";

import { IInstitution } from "../../../../Model/Institution";
import { langSelector } from "../../../store/selectors";
import NotificationService from "./NotificationService.view";

interface IReduxPropsNotificationInstitutionsServiceView {
    institutions: IInstitution[];
}

interface IPropsNotificationInsitutionsServiceView extends IReduxPropsNotificationInstitutionsServiceView {
    onSelectService: (idInstitution: number, idServices: number[]) => void;
    handleSubmitService: () => void;
    idServices?: number[];
    isReadingOnly: boolean;
    isLoadingService: boolean;
    didLoadService: boolean;
    infoMessageService: string;
    errorMessageService: string;
}

const NotificationInstitutionsServices: React.FC<IPropsNotificationInsitutionsServiceView> = props => {
    const [lang, currentLang] = useSelector(langSelector);
    const idServices = props.idServices ? props.idServices : [];
    const institutionElements = props.institutions.map(institution => (
        <NotificationService
            key={institution.id}
            institution={institution}
            idServices={idServices}
            onSelectService={idServices => {
                props.onSelectService(institution.id, idServices);
            }}
            isReadingOnly={props.isReadingOnly}
            lang={currentLang}
        />
    ));

    return (
        <HubloJumbotron
            title={lang.myServices}
            size="l"
            innerJumbotron={
                <div>
                    <h4 className="margin-bottom-m">{lang.listServicesWantNotif}</h4>
                    <div>
                        <div className="container-fluid">{institutionElements}</div>
                    </div>

                    <HubloErrorMessageInfoBox
                        infoMessage={props.infoMessageService}
                        errorMessage={props.errorMessageService}
                        isLoading={props.isLoadingService}
                        nextPage={props.handleSubmitService}
                        lang={lang}
                    />
                </div>
            }
        />
    );
};

export default NotificationInstitutionsServices;
