import { connect } from "react-redux";
import { setJobsAction, fetchJobsAction, setIsLoadingJobAction } from "../../store/action/job.action";

import { IJob } from "../../../Model/Job";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedJobs } from "../../../Model/Job";
import { getModelsFromDict } from "../../modules/store/util";
import { isIncludedStringCaseInsensitive } from "../../../toolbox/search";

//@ts-ignore
interface IReduxPropsJob extends IReduxPropsTable {
    idJobs: number[];
    jobDict: INormalizedJobs;
    searchValue: string;
}

interface IPropsJob extends IReduxPropsJob, IReduxActionsJob, IPropsTable {}

interface IReduxActionsJob extends IReduxActionsTable {
    setJobsAction: (elements: IJob[]) => any;
    fetchJobsAction: (param: IQueryParams) => any;
    setIsLoadingJobAction: (isLoading: boolean) => any;
}

class TableJobContainer extends TableComponent<IPropsJob> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingJobAction(true);
        if (this.props.idJobs.length === 0) await this.props.fetchJobsAction(params);
        this.props.setIsLoadingJobAction(false);
    }

    setElements(elements: any): void {
        this.props.setJobsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict<IJob>(this.props.jobDict, this.props.idJobs).filter(
            (e): boolean => !this.props.searchValue || isIncludedStringCaseInsensitive(e.name, this.props.searchValue)
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsJob =>
        Object.assign(getConnectParentState(centralState), {
            idJobs: centralState.jobs.ids,
            jobDict: centralState.jobs.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            isLoading: centralState.jobs.isLoading,
            searchValue: centralState.tables.searchValue
        }),
    Object.assign(getConnectParentActions(), {
        setJobsAction,
        fetchJobsAction,
        setIsLoadingJobAction
    })
)(TableJobContainer);
