import { IReduxAction } from "../store.type";
import ScheduleTypes from "../type/schedule.type";
import {
    fetchSchedules,
    insertSchedule,
    updateSchedule,
    deleteSchedule,
    insertInstitutionHasSchedule,
    formatScheduleTimesFormats
} from "../../service/schedule.service";
import { ISchedule, INormalizedSchedules, IScheduleStore, INormalizedStoredSchedules } from "../../../Model/Schedule";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import { minutesToHHmmFormat, datesStringMinutesDiff } from "../../../toolbox/date";
import moment from "moment";

export function formatScheduleForStore(schedule: ISchedule): IScheduleStore {
    const breakMinutes = schedule.nbWorkedMinutes
        ? datesStringMinutesDiff(schedule.beginAt, schedule.endAt, schedule.nbWorkedMinutes)
        : 0;
    return {
        ...schedule,
        break: minutesToHHmmFormat(breakMinutes),
        nbWorkedMinutes: minutesToHHmmFormat(schedule.nbWorkedMinutes ? schedule.nbWorkedMinutes : 0),
        beginAt: moment(schedule.beginAt)
            .utc()
            .format("HH:mm"),
        endAt: moment(schedule.endAt)
            .utc()
            .format("HH:mm")
    };
}

export function fetchSchedulesAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const schedules = await fetchSchedules(query);
            schedules.map((schedule: any): any => (schedule.isDeleted = !schedule.isDeleted));
            const storedSchedules = schedules.map((schedule: any): IScheduleStore => formatScheduleForStore(schedule));
            const idStoredSchedules = storedSchedules.map((e): number => e.id);

            dispatch({
                type: ScheduleTypes.FETCH_SCHEDULES,
                payload: {
                    byId: storedSchedules.reduce(
                        (p: INormalizedStoredSchedules, c: IScheduleStore): INormalizedStoredSchedules => {
                            p[c.id] = c;
                            return p;
                        },
                        {}
                    ),
                    ids: idStoredSchedules
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasScheduleAction(
    idSchedule: number,
    idInstitution: number
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<ISchedule> => {
        try {
            let insertedInstitutionHasSchedule = await insertInstitutionHasSchedule(idSchedule, idInstitution);
            dispatch({
                type: ScheduleTypes.INSERT_INSTITUTION_HAS_SCHEDULE,
                payload: {
                    idSchedule,
                    idInstitution
                }
            });
            return insertedInstitutionHasSchedule;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertScheduleAction(schedule: ISchedule): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<ISchedule> => {
        try {
            schedule.isDeleted = !schedule.isDeleted;
            const newSchedule = await insertSchedule(schedule);
            newSchedule.isDeleted = !newSchedule.isDeleted;
            dispatch({
                type: ScheduleTypes.INSERT_SCHEDULE,
                payload: formatScheduleForStore(newSchedule)
            });
            return newSchedule;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateScheduleAction(id: number, schedule: ISchedule): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            schedule.isDeleted = !schedule.isDeleted;
            schedule = formatScheduleTimesFormats(schedule);
            const updatedSchedule = await updateSchedule(id, schedule);
            updatedSchedule.isDeleted = !schedule.isDeleted;
            dispatch({
                type: ScheduleTypes.UPDATE_SCHEDULE,
                payload: formatScheduleForStore(updatedSchedule)
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteScheduleAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteSchedule(id);
            dispatch({
                type: ScheduleTypes.DELETE_SCHEDULE
            });
            return;
        } catch (err) {
            const errorCode = getErrorFromResponse(err);
            dispatch({
                type: ScheduleTypes.SET_SCHEDULE_ERROR_CODE,
                payload: errorCode
            });
            throw err;
        }
    };
}

export function setSchedulesAction(dict: INormalizedSchedules): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_SCHEDULES,
            payload: dict
        });
    };
}

export function setSelectedScheduleAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_SELECTED_SCHEDULE,
            payload: id
        });
    };
}

export function setSelectedScheduleMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_SELECTED_SCHEDULE_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingScheduleAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_IS_LOADING_SCHEDULE,
            payload: isLoading
        });
    };
}
export function setScheduleOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_SCHEDULE_ORDER,
            payload: idsSorted
        });
    };
}

export function setScheduleErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ScheduleTypes.SET_SCHEDULE_ERROR_CODE,
            payload: errorCode
        });
    };
}
