import { IPayrollcode } from "../../Model/Payrollcode";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const PayrollcodeDefaultSelect = [
    "id",
    "name",
    "institution",
    "type",
    "code",
    "beginAt",
    "endAt",
    "isBusinessDay",
    "isNight",
    "isBeforeSundayOrPublicHoliday"
];

export async function fetchPayrollcodes(query: IQueryParams): Promise<IPayrollcode[]> {
    const payrollcodes = await postFetchFactory<IPayrollcode>(PossibleFetchs.payrollcode, PossibleApis.hubloExport)(
        {
            select: PayrollcodeDefaultSelect
        },
        query
    );
    return payrollcodes;
}

export async function fetchPayrollcodesCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IPayrollcode>(PossibleFetchs.payrollcode, PossibleApis.hubloExport)(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertPayrollcode(payrollcode: IPayrollcode): Promise<IPayrollcode> {
    return await postFactory<IPayrollcode>(PossiblePosts.payrollcode, PossibleApis.hubloExport)(payrollcode);
}

export async function updatePayrollcode(id: number, payrollcode: IPayrollcode): Promise<IPayrollcode> {
    return await updateFactory<IPayrollcode>(PossibleUpdates.payrollcode, PossibleApis.hubloExport)(id, payrollcode);
}

export async function deletePayrollcode(id: number): Promise<IPayrollcode> {
    return await deleteFactory<IPayrollcode>(PossibleDeletes.payrollcode, PossibleApis.hubloExport)(id);
}
