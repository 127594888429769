import { connect } from "react-redux";
import {
    updateInstitutionOptionWorkerAction,
    insertInstitutionOptionWorkerAction
} from "../../store/action/institutionOptionWorker.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IInstitutionOptionWorker } from "../../../Model/InstitutionOptionWorker";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";

interface IPropsForminstitutionOptionWorker
    extends IReduxPropsForm<IInstitutionOptionWorker>,
        IReduxActionsForm,
        IPropsForm<IInstitutionOptionWorker> {}

interface IReduxActionsForm {
    updateInstitutionOptionWorkerAction: (id: number, institutionOptionWorker: IInstitutionOptionWorker) => void;
    insertInstitutionOptionWorkerAction: (institutionOptionWorker: IInstitutionOptionWorker) => void;
}

class ForminstitutionOptionWorkerComponent extends FormComponent<
    IInstitutionOptionWorker,
    IPropsForminstitutionOptionWorker
> {
    async createElementAction(): Promise<void> {
        const formDictionaryInstitutionOptionWorkers = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionWorker
        );
        const institution = this.getSelectedIdInstitutions()[0];
        const formDictionaryInstitutionOptionWorker = Object.assign(formDictionaryInstitutionOptionWorkers[0], {
            institution
        });
        try {
            await this.props.insertInstitutionOptionWorkerAction(formDictionaryInstitutionOptionWorker);
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.notAllInsertHaveBeenWellDone,
                icon: "fa fa-times",
                color: "danger"
            });
        }
    }

    async updateElementAction(): Promise<void> {
        const institutionOptionWorker = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionWorker
        )[0];
        await this.props.updateInstitutionOptionWorkerAction(
            institutionOptionWorker.institution,
            institutionOptionWorker as IInstitutionOptionWorker
        );
    }
}

export default connect(
    (centralState: any): IPropsForminstitutionOptionWorker => Object.assign(getConnectParentState(centralState), {}),
    Object.assign(getConnectParentActions(), {
        updateInstitutionOptionWorkerAction,
        insertInstitutionOptionWorkerAction
    })
)(ForminstitutionOptionWorkerComponent);
