enum InfoOnWorkerActionTypes {
    FETCH_INFOONWORKERS = "FETCH_INFOONWORKERS",
    SET_INFOONWORKERS = "SET_INFOONWORKERS",
    FETCH_INFOONWORKERS_COUNT = "FETCH_INFOONWORKERS_COUNT",
    SET_SELECTED_INFOONWORKER = "SET_SELECTED_INFOONWORKER",
    SET_SELECTED_INFOONWORKER_MULTI = "SET_SELECTED_INFOONWORKER_MULTI",
    SET_IS_LOADING_INFOONWORKER = "SET_IS_LOADING_INFOONWORKER",
    SET_INFOONWORKER_ORDER = "SET_INFOONWORKER_ORDER",
    INSERT_INFOONWORKER = "INSERT_INFOONWORKER",
    DELETE_INFOONWORKER = "DELETE_INFOONWORKER",
    SET_INFOONWORKER_ERROR_CODE = "SET_INFOONWORKER_ERROR_CODE",
    FETCH_ALL_INFOONWORKERS = "FETCH_ALL_INFOONWORKERS"
}
export default InfoOnWorkerActionTypes;
