import React from "react";
import {
    EMedgoLabelClassName,
    HubloModalComponent,
    HubloTextAreaWithLabel,
    MedgoLabelComponent,
    ButtonMedgoActionComponent,
    EButtonMedgoActionClassName
} from "@hublo/legacy-components";
import { connect } from "react-redux";
import { Constants } from "npm-hublo-toolbox";
import { ILang } from "../../../lang/Lang";
import "./Confirmation.modal.scss";

interface IReduxPropsCreateElementModal {
    showConfirmationModal: boolean;
    messageContent: string;
    scheduleRequestContent: string;
}

export interface IConfirmationModalData {
    subtitle: string;
    text: string;
    onClick1: () => void;
    onClick2: () => void;
    textButton1: string;
    textButton2: string;
    placeholder?: string;
    isSimpleMessage?: boolean;
    handleChange?: (event: any) => void;
    colorButton2?: EButtonMedgoActionClassName;
}

interface IPropsCreateElementModal extends IReduxPropsCreateElementModal {
    lang: ILang;
    subtitle: string;
    text: string;
    onClick1: () => void;
    onClick2: () => void;
    textButton1: string;
    textButton2: string;
    placeholder?: string;
    isSimpleMessage?: boolean;
    handleChange?: (event: any) => void;
    colorButton2?: EButtonMedgoActionClassName;
    show?: boolean;
}

class ConfirmationModal extends React.Component<IPropsCreateElementModal> {
    render(): JSX.Element {
        return (
            <HubloModalComponent
                show={this.props.show === undefined ? this.props.showConfirmationModal : this.props.show}
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={""}
                isTitleBold={true}
                size={"sm"}
                dialogClassName={"modal-dialog-centered"}
            />
        );
    }
    renderFooter(): JSX.Element {
        return (
            <div className="col-12 row">
                {this.props.textButton1 !== "" ? (
                    <div className="col">
                        <ButtonMedgoActionComponent
                            label={this.props.textButton1}
                            handleClick={this.props.onClick1}
                            className={EButtonMedgoActionClassName.medgoButtonDefault}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {this.props.textButton2 !== "" ? (
                    <div className="col">
                        <ButtonMedgoActionComponent
                            label={this.props.textButton2}
                            handleClick={this.props.onClick2}
                            className={this.props.colorButton2}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
    renderContent(): JSX.Element {
        if (this.props.placeholder && this.props.handleChange) {
            const contactDetails = Constants.companyContactDetailsFor(this.props.lang as any);
            return (
                <div>
                    <div className="row col modal-subtitle">{this.props.subtitle}</div>
                    <div className="row col confirmation-modal-content">
                        <MedgoLabelComponent
                            className={EMedgoLabelClassName.medgoLabelFormLabel}
                            text={this.props.lang.contactUsAtAddress}
                        />
                        <a className="link-form" href={"mailto:" + `${contactDetails.email}`}>
                            {contactDetails.email}
                        </a>
                        <MedgoLabelComponent
                            className={EMedgoLabelClassName.medgoLabelFormLabel}
                            text={this.props.lang.orAskToBeContacted}
                        />
                        <HubloTextAreaWithLabel
                            placeholder={this.props.placeholder}
                            value={
                                this.props.isSimpleMessage
                                    ? this.props.messageContent
                                    : this.props.scheduleRequestContent
                            }
                            onChange={this.props.handleChange}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="row col modal-subtitle">{this.props.subtitle}</div>
                    <div className="col modal-text">{this.props.text}</div>
                </div>
            );
        }
    }
}

export default connect(
    (centralState: any): IReduxPropsCreateElementModal => ({
        showConfirmationModal: centralState.form.showConfirmationModal,
        messageContent: centralState.form.messageContent,
        scheduleRequestContent: centralState.form.scheduleRequestContent
    }),
    {}
)(ConfirmationModal);
