import { IReduxAction } from "../store.type";
import TemplateTypes from "../type/template.type";
import { fetchTemplates, insertTemplate, updateTemplate, deleteTemplate } from "../../service/template.service";
import { ITemplate, INormalizedTemplates } from "../../../Model/Template";
import { INormalizedJobs } from "../../../Model/Job";
import { INormalizedSpecialties } from "../../../Model/Specialty";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchTemplatesAction(
    query: IQueryParams,
    jobs: INormalizedJobs,
    specialties: INormalizedSpecialties
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const templates = await fetchTemplates(query);
            let newTemplates = {};
            for (let i = 0; i < templates.length; i++) {
                let template = templates[i];
                template = Object.assign(template, {
                    jobName: template.job && jobs[template.job] ? jobs[template.job].name : "",
                    specialtyName:
                        template.specialty && specialties[template.specialty]
                            ? specialties[template.specialty].name
                            : ""
                });
                newTemplates = Object.assign(newTemplates, template);
            }
            dispatch({
                type: TemplateTypes.FETCH_TEMPLATES,
                payload: {
                    byId: templates.reduce((p: INormalizedTemplates, c: ITemplate): INormalizedTemplates => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: templates.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertTemplateAction(template: ITemplate): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            let newTemplate = await insertTemplate(template);
            newTemplate.jobName = template.jobName;
            newTemplate.specialtyName = template.specialtyName;
            dispatch({
                type: TemplateTypes.INSERT_TEMPLATE,
                payload: newTemplate
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateTemplateAction(id: number, template: ITemplate): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedTemplate = await updateTemplate(id, template);
            updatedTemplate.jobName = template.jobName;
            updatedTemplate.specialtyName = template.specialtyName;
            dispatch({
                type: TemplateTypes.UPDATE_TEMPLATE,
                payload: updatedTemplate
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteTemplateAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteTemplate(id);
            dispatch({
                type: TemplateTypes.DELETE_TEMPLATE,
                payload: id
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setTemplatesAction(dict: INormalizedTemplates): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_TEMPLATES,
            payload: dict
        });
    };
}

export function setSelectedTemplateAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_SELECTED_TEMPLATE,
            payload: id
        });
    };
}

export function setSelectedTemplateMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_SELECTED_TEMPLATE_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingTemplateAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_IS_LOADING_TEMPLATE,
            payload: isLoading
        });
    };
}
export function setTemplateOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_TEMPLATE_ORDER,
            payload: idsSorted
        });
    };
}

export function setTemplateErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: TemplateTypes.SET_TEMPLATE_ERROR_CODE,
            payload: errorCode
        });
    };
}
