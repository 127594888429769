import { IInstitutionOptionWorker } from "../../Model/InstitutionOptionWorker";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const InstitutionOptionWorkerDefaultSelect = ["institution", "hasPool"];

export async function fetchInstitutionOptionWorkers(query: IQueryParams): Promise<IInstitutionOptionWorker[]> {
    const institutionOptionWorkers = await postFetchFactory<IInstitutionOptionWorker>(
        PossibleFetchs.institutionOptionWorker,
        PossibleApis.medgoApi
    )(
        {
            select: InstitutionOptionWorkerDefaultSelect
        },
        query
    );
    return institutionOptionWorkers;
}

export async function fetchInstitutionOptionWorkersCount(query: IQueryParams): Promise<number> {
    const data = await postFetchFactory<IInstitutionOptionWorker>(
        PossibleFetchs.institutionOptionWorker,
        PossibleApis.medgoApi
    )(
        {
            select: ["$count"]
        },
        query
    );
    // @ts-ignore
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0;
}

export async function insertInstitutionOptionWorker(
    institutionOptionWorker: IInstitutionOptionWorker
): Promise<IInstitutionOptionWorker> {
    return await postFactory<IInstitutionOptionWorker>(
        PossiblePosts.institutionOptionWorker,
        PossibleApis.medgoApi
    )(institutionOptionWorker);
}

export async function updateInstitutionOptionWorker(
    id: number,
    institutionOptionWorker: IInstitutionOptionWorker
): Promise<IInstitutionOptionWorker> {
    return await updateFactory<IInstitutionOptionWorker>(
        PossibleUpdates.institutionOptionWorker,
        PossibleApis.medgoApi
    )(id, institutionOptionWorker);
}

export async function deleteInstitutionOptionWorker(id: number): Promise<IInstitutionOptionWorker> {
    return await deleteFactory<IInstitutionOptionWorker>(
        PossibleDeletes.institutionOptionWorker,
        PossibleApis.medgoApi
    )(id);
}
