import { IReduxAction, IReduxInitialState } from "../store.type";
import PoleTypes from "../type/pole.type";
import { IPole } from "../../../Model/Pole";

const initialState: IReduxInitialState<IPole> = {
    byId: {},
    ids: [],
    selectedId: -1,
    selectedMulti: [],
    isLoading: false
};

export default function poleReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case PoleTypes.FETCH_POLES:
        case PoleTypes.SET_POLES: {
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids
            };
        }

        case PoleTypes.SET_SELECTED_POLE: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case PoleTypes.SET_SELECTED_POLE_MULTI: {
            return {
                ...state,
                selectedMulti: action.payload
            };
        }

        case PoleTypes.SET_IS_LOADING_POLE: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case PoleTypes.SET_POLE_ORDER: {
            return {
                ...state,
                ids: action.payload
            };
        }

        case PoleTypes.INSERT_POLE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id]
            };
        }

        case PoleTypes.UPDATE_POLE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            };
        }

        default:
            return state;
    }
}
