import { Equality } from "npm-medgo-toolbox";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchLimits } from "../../../../Constant/Api.constant";
import { IInstitutionHasOAuth2 } from "../../../../Model/InstitutionHasOAuth2";
import { fetchInstitutionHasOAuth2sAction } from "../../../store/action/institutionHasOAuth2.action";
import { oauth2Selector } from "../../../store/selectors";

export const useInstitutionHasOAuth2Loading = (idInstitution: number): [IInstitutionHasOAuth2 | undefined, boolean] => {
    const dispatch = useDispatch();
    const { oauth2, isLoading } = useSelector(oauth2Selector);

    useEffect((): void => {
        if (!Equality.isNullOrUndefined(idInstitution)) {
            dispatch(
                fetchInstitutionHasOAuth2sAction({
                    limit: FetchLimits.sso,
                    orderby: [
                        {
                            columnName: "id",
                            orientation: "ASC"
                        }
                    ],
                    query: { institution: idInstitution },
                    select: [
                        "id",
                        "clientId",
                        "clientSecret",
                        "authUrl",
                        "accessTokenUrl",
                        "userInfoUrl",
                        "scope",
                        "resource",
                        "responseType",
                        "usePKCE"
                    ]
                })
            );
        }
    }, [dispatch, idInstitution]);

    return [oauth2, isLoading];
};
