import { IReduxAction } from "../store.type";
import MotifTypes from "../type/motif.type";
import { fetchMotifs, insertInstitutionHasMotif, updateMotif, deleteMotif } from "../../service/motif.service";
import { IMotif, INormalizedMotifs, ICreateMotifRequest } from "../../../Model/Motif";
import { IQueryParams } from "npm-medgo-query";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";

export function fetchMotifsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const motifs = await fetchMotifs(query);
            dispatch({
                type: MotifTypes.FETCH_MOTIFS,
                payload: {
                    byId: motifs.reduce((p: INormalizedMotifs, c: IMotif): INormalizedMotifs => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: motifs.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
export function fetchAllMotifsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const motifs = await fetchMotifs(query);
            dispatch({
                type: MotifTypes.FETCH_ALL_MOTIFS,
                payload: {
                    byId: motifs.reduce((p: INormalizedMotifs, c: IMotif): INormalizedMotifs => {
                        p[c.id] = c;
                        return p;
                    }, {}),
                    ids: motifs.map((e): number => e.id)
                }
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function insertInstitutionHasMotifAction(
    motif: ICreateMotifRequest
): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const newMotif = await insertInstitutionHasMotif(motif);
            dispatch({
                type: MotifTypes.INSERT_INSTITUTION_HAS_MOTIF,
                payload: newMotif
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function updateMotifAction(id: number, motif: IMotif): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const updatedMotif = await updateMotif(id, motif);
            dispatch({
                type: MotifTypes.UPDATE_MOTIF,
                payload: updatedMotif
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function deleteMotifAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            await deleteMotif(id);
            dispatch({
                type: MotifTypes.DELETE_MOTIF
            });
            return;
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}

export function setMotifsAction(dict: INormalizedMotifs): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_MOTIFS,
            payload: dict
        });
    };
}

export function setSelectedMotifAction(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_SELECTED_MOTIF,
            payload: id
        });
    };
}

export function setSelectedMotifMultiAction(ids: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_SELECTED_MOTIF_MULTI,
            payload: ids
        });
    };
}
export function setIsLoadingMotifAction(isLoading: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_IS_LOADING_MOTIF,
            payload: isLoading
        });
    };
}
export function setMotifOrderAction(idsSorted: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_MOTIF_ORDER,
            payload: idsSorted
        });
    };
}

export function setMotifErrorCodeAction(errorCode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MotifTypes.SET_MOTIF_ERROR_CODE,
            payload: errorCode
        });
    };
}
