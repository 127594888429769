import { DateTimeToolbox } from "npm-medgo-toolbox";
import { IInstitution } from "../../../Model/Institution";
import { stringHHmmToUtcMoment, minutesBetweenHHmm } from "../../../toolbox/date";
import { getLang, isInAnyLang } from "../../modules/lang";
import { getModelsFromDict } from "../../modules/store/util";
import store from "../../store";
import { shouldDisplayInterface } from "../../../toolbox/permissions";
import { upsertBatchSchedule, insertBatchInstitutionHasSchedule } from "../../service/schedule.service";
import ScheduleTypes from "../../store/type/schedule.type";
import { formatScheduleForStore } from "../../store/action/schedule.action";
import { setNotificationMessage } from "../../store/action/notification.action";

function sanitizeScheduleInfo(scheduleInfo: string[], shouldAddInterfaceCode: boolean): string[] {
    const lang = getLang();
    scheduleInfo.unshift(""); // add ID field
    if (shouldAddInterfaceCode) scheduleInfo.push("");
    let error = "";
    if (scheduleInfo.length !== 6)
        throw lang.errorInCSV_schedule
            .replace("__ERROR_MESSAGE__", lang.missingField)
            .replace("__NAME__", scheduleInfo[1]);
    if (!DateTimeToolbox.isValidHourHHMM(scheduleInfo[2])) error = lang.invalidStartDate;
    if (!DateTimeToolbox.isValidHourHHMM(scheduleInfo[3])) error = lang.invalidEndDate;
    if (!DateTimeToolbox.isValidHourHHMM(scheduleInfo[4])) error = lang.invalidPauseTime;
    const nbWorkedMinutes =
        minutesBetweenHHmm(scheduleInfo[2], scheduleInfo[3]) - DateTimeToolbox.HHmmToMinutes(scheduleInfo[4]);
    if (nbWorkedMinutes < 1) error = lang.invalidWorkingTime;
    if (error !== "")
        throw lang.errorInCSV_schedule.replace("__ERROR_MESSAGE__", error).replace("__NAME__", scheduleInfo[1]);
    scheduleInfo[2] = stringHHmmToUtcMoment(scheduleInfo[2])
        .format()
        .toString();
    scheduleInfo[3] = stringHHmmToUtcMoment(scheduleInfo[3])
        .format()
        .toString();
    return scheduleInfo;
}

function updateStoreWithUpsertedSchedules(updatedSchedules: any[], createdSchedules: any[]): void {
    updatedSchedules.forEach((schedule: any): void => {
        store.dispatch({
            type: ScheduleTypes.UPDATE_SCHEDULE,
            payload: formatScheduleForStore(schedule)
        });
    });
    createdSchedules.forEach((schedule: any): void => {
        store.dispatch({
            type: ScheduleTypes.INSERT_SCHEDULE,
            payload: formatScheduleForStore(schedule)
        });
    });
}

export async function importSanitizedScheduleFromJSON(result: { data: any }, IDInstitutions: number[]): Promise<any> {
    const lang = getLang();
    let scheduleInfoArray = result.data;
    scheduleInfoArray = scheduleInfoArray.filter(
        // "> 1" because an empty field cause the resulting array to contain an empty string
        (scheduleInfo: string[]): boolean => scheduleInfo.length > 1
    );
    if (!scheduleInfoArray.length) {
        throw {
            title: lang.errorInDocument,
            error: lang.errorInCSV_schedule.replace("__ERROR_MESSAGE__", lang.wrongFileFormatUseTemplate)
        };
    }
    const scheduleInfoColumns = scheduleInfoArray.shift();
    let shouldAddInterfaceCode = !isInAnyLang("interfacingCode", scheduleInfoColumns[scheduleInfoColumns.length - 1]);
    try {
        scheduleInfoArray = scheduleInfoArray.map((scheduleInfo: any): string[] =>
            sanitizeScheduleInfo(scheduleInfo, shouldAddInterfaceCode)
        );
    } catch (error) {
        throw { title: lang.errorInDocument, error: error };
    }
    try {
        while (scheduleInfoArray.length > 0) {
            const batch = scheduleInfoArray.splice(0, 50);
            for (let i = 0; i < IDInstitutions.length; i++) {
                const data = (await upsertBatchSchedule(batch, IDInstitutions[i])) as {
                    created: any[];
                    updated: any[];
                };
                if (data.created.length) {
                    await insertBatchInstitutionHasSchedule(
                        data.created.map((schedule: any): number => schedule.id),
                        IDInstitutions[i]
                    );
                }
                data.created.forEach((schedule, index, schedules) => (schedules[index].isDeleted = true));
                data.updated.forEach((schedule, index, schedules) => (schedules[index].isDeleted = true));
                updateStoreWithUpsertedSchedules(
                    data.updated.map((schedule: any): any => {
                        return { ...schedule, institution: [IDInstitutions[i]] };
                    }),
                    data.created.map((schedule: any): any => {
                        return { ...schedule, institution: [IDInstitutions[i]] };
                    })
                );
                setNotificationMessage({
                    message: lang.updated_schedule,
                    icon: "fa fa-check",
                    color: "success"
                })(store.dispatch);
            }
        }
    } catch (error) {
        throw { title: lang.anErrorOccured, error: lang.askRetryImport };
    }
}

export function getScheduleColNamesForExport(cols: any[]): any[] {
    const state = store.getState();
    if (state.institutions !== null && state.institutions !== undefined) {
        const institutionsStore = (state.institutions as unknown) as { byId: Record<string, any>; ids: number[] };
        const institutionArray = getModelsFromDict<IInstitution>(institutionsStore.byId, institutionsStore.ids);
        if (shouldDisplayInterface(institutionArray)) {
            return cols;
        }
    }
    cols.pop(); // Remove interfacingCode
    return cols;
}
