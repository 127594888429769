enum PoleActionTypes {
    FETCH_POLES = "FETCH_POLES",
    SET_POLES = "SET_POLES",
    FETCH_POLES_COUNT = "FETCH_POLES_COUNT",
    SET_SELECTED_POLE = "SET_SELECTED_POLE",
    SET_SELECTED_POLE_MULTI = "SET_SELECTED_POLE_MULTI",
    SET_IS_LOADING_POLE = "SET_IS_LOADING_POLE",
    SET_POLE_ORDER = "SET_POLE_ORDER",
    INSERT_POLE = "INSERT_POLE",
    UPDATE_POLE = "UPDATE_POLE",
    DELETE_POLE = "DELETE_POLE",
    SET_POLE_ERROR_CODE = "SET_POLE_ERROR_CODE"
}
export default PoleActionTypes;
