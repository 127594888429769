import { connect } from "react-redux";
import {
    updateInstitutionOptionMissionAction,
    insertInstitutionOptionMissionAction
} from "../../store/action/institutionOptionMission.action";
import {
    FormComponent,
    IPropsForm,
    IReduxPropsForm,
    getConnectParentState,
    getConnectParentActions
} from "./Form.container";
import { IInstitutionOptionMission } from "../../../Model/InstitutionOptionMission";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict } from "../../modules/store/util";

interface IPropsFormInstitutionOptionMission
    extends IReduxPropsForm<IInstitutionOptionMission>,
        IReduxActionsForm,
        IPropsForm<IInstitutionOptionMission> {}

interface IReduxActionsForm {
    updateInstitutionOptionMissionAction: (id: number, institutionOptionMission: IInstitutionOptionMission) => void;
    insertInstitutionOptionMissionAction: (institutionOptionMission: IInstitutionOptionMission) => void;
}

class FormInstitutionOptionMissionComponent extends FormComponent<
    IInstitutionOptionMission,
    IPropsFormInstitutionOptionMission
> {
    async createElementAction(): Promise<void> {
        const formDictionaryInstitutionOptionMissions = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionMission
        );
        const institution = this.getSelectedIdInstitutions()[0];
        const formDictionaryInstitutionOptionMission = Object.assign(formDictionaryInstitutionOptionMissions[0], {
            institution
        });
        try {
            await this.props.insertInstitutionOptionMissionAction(formDictionaryInstitutionOptionMission);
        } catch (err) {
            this.props.setNotificationMessage({
                message: this.props.lang.notAllInsertHaveBeenWellDone,
                icon: "fa fa-times",
                color: "danger"
            });
        }
    }

    async updateElementAction(): Promise<void> {
        const institutionOptionMission = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.institutionOptionMission
        )[0];
        await this.props.updateInstitutionOptionMissionAction(
            institutionOptionMission.institution,
            institutionOptionMission as IInstitutionOptionMission
        );
    }
}

export default connect(
    (centralState: any): IPropsFormInstitutionOptionMission => Object.assign(getConnectParentState(centralState), {}),
    Object.assign(getConnectParentActions(), {
        updateInstitutionOptionMissionAction,
        insertInstitutionOptionMissionAction
    })
)(FormInstitutionOptionMissionComponent);
