import { firstLetterUppercase } from "npm-medgo-toolbox/dist/modules/string";
import React from "react";
import DataTable, { IDataTableColumn, IDataTablePaginationOptions } from "react-data-table-component";
import { connect } from "react-redux";
import { EBackOfficeTableName, IBackOfficeTable } from "../../constants/Table.constants";
import TableTab from "../../components/table/TableTab.component";
import { isNullOrUndefined } from "util";
import { displayCriteria } from "../../../toolbox/permissions";
import ColumnView from "../../components/table/Column.view";
import { IBackOfficeColumn } from "../../constants/Column.constants";
import { getModelsFromDict } from "../../modules/store/util";
import ColumnName from "../shared/ColumnName.view";
import SubHeaderView from "./SubHeader.view";
import { setItemIdToBeDeleted, setModifyingElementAction } from "../../store/action/table.action";
import { IAdmin } from "../../../Model/Admin";
import { setShowConfirmationModalAction, setFormTableAction } from "../../store/action/form.action";
import { INormalizedInstitutionOptions } from "../../../Model/InstitutionOption";
import { INormalizedInstitutions } from "../../../Model/Institution";

interface IReduxPropsDataTableView {
    lang: any;
    selectedTab: number;
    me: IAdmin;
    idInstitutionOptions: number[];
    institutionOptionDict: INormalizedInstitutionOptions;
    institutionDict: INormalizedInstitutions;
    idInstitutions: number[];
    idPoles: number[];
    modifyingElement: any;
}

interface IReduxActionDataTableView {
    setShowConfirmationModalAction: (show: boolean, isDeleteConfirmation?: boolean) => void;
    setItemIdToBeDeleted: (id: number) => void;
    setModifyingElementAction: (element: any) => void;
    setFormTableAction: (backofficeTableName: EBackOfficeTableName) => void;
}

interface IPropsDataTableView extends IReduxPropsDataTableView, IReduxActionDataTableView {
    backOfficeTable: IBackOfficeTable;
    elements: any[];
    onModifyLine: (id: number, index: number, isModifyingInModal?: boolean) => void;
    selectCheckboxListOptions?: () => any;
    isLoading: boolean;
    onSearch: (value: string | null) => void;
}

export class DataTableView extends React.Component<IPropsDataTableView> {
    render(): JSX.Element {
        const elements = this.props.elements;
        const paginationComponenetOptions: IDataTablePaginationOptions = {
            rowsPerPageText: this.props.lang.rowsPerPageText,
            rangeSeparatorText: this.props.lang.rangeSeparatorText
        };
        const subtitle = !isNullOrUndefined(this.props.backOfficeTable.subtitle)
            ? firstLetterUppercase(this.props.lang[this.props.backOfficeTable.subtitle[this.props.selectedTab]])
            : null;
        const name = !isNullOrUndefined(this.props.backOfficeTable.name)
            ? firstLetterUppercase(this.props.lang[this.props.backOfficeTable.name[this.props.selectedTab]])
            : null;
        const onRowClicked = this.props.backOfficeTable.hasDetailsPage
            ? (e: any) => {
                  window.location.href = `#${this.props.backOfficeTable.name}/${e.id}`;
                  this.props.setFormTableAction(this.props.backOfficeTable.name[0]);
              }
            : () => {};
        return (
            <>
                {this.props.backOfficeTable.title ? (
                    <div className="table-title">
                        {firstLetterUppercase(this.props.lang[this.props.backOfficeTable.title])}
                    </div>
                ) : (
                    <></>
                )}
                {this.props.backOfficeTable.isNameDisplayed ? (
                    <div className="hublobackofficesimpletable-name">{name}</div>
                ) : null}
                {this.props.backOfficeTable.isSubtitleDisplayed ? <div> {subtitle} </div> : null}
                {this.props.backOfficeTable.tabs ? <TableTab tabs={this.props.backOfficeTable.tabs} /> : <></>}
                <div style={{ overflow: "auto" }}>
                    <DataTable
                        customStyles={{
                            table: {
                                style: {
                                    marginBottom:
                                        this.props.backOfficeTable.name[0] === EBackOfficeTableName.admin ||
                                        this.props.backOfficeTable.name[0] === EBackOfficeTableName.specialHour
                                            ? "125px"
                                            : "0"
                                }
                            },
                            headRow: {
                                style: {
                                    backgroundColor: "#F2F2F2",
                                    border: "1px solid #EBF1F8",
                                    boxSizing: "border-box",
                                    borderTopLeftRadius: "5px",
                                    borderTopRightRadius: "5px"
                                }
                            },
                            headCells: {
                                style: {
                                    fontFamily: "Nunito",
                                    fontWeight: "bold",
                                    fontStyle: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#6C7C8C"
                                }
                            },
                            rows: {
                                style: {
                                    background: "#FFFFFF",
                                    border: "1px solid #EBF1F8"
                                },
                                highlightOnHoverStyle: {
                                    backgroundColor: "#FBFAFA",
                                    border: "1px solid #EBF1F8",
                                    transitionDuration: "0.15s",
                                    transitionProperty: "background-color"
                                }
                            },
                            cells: {
                                style: {
                                    fontFamily: "Nunito",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#6C7C8C",
                                    text: "center !important"
                                }
                            },
                            pagination: {
                                style: {
                                    background: "#FFFFFF",
                                    border: "1px solid #EBF1F8",
                                    borderBottomLeftRadius: "5px",
                                    borderBottomRightRadius: "5px"
                                }
                            },
                            subHeader: {
                                style: {
                                    paddingLeft: "0px",
                                    paddingRight: "0px"
                                }
                            }
                        }}
                        columns={
                            this.props.backOfficeTable.columns[this.props.selectedTab]
                                ? this.translateColumnNamesAndAddActionsColumn(
                                      this.getColumns(),
                                      this.props.lang,
                                      this.props.backOfficeTable.canModifyLine && this.props.me.level === 1,
                                      this.props.onModifyLine,
                                      this.props.backOfficeTable,
                                      this.props.selectCheckboxListOptions
                                  )
                                : []
                        }
                        pointerOnHover={this.props.backOfficeTable.pointerOnHover}
                        highlightOnHover
                        noHeader={true}
                        data={elements}
                        pagination={this.props.backOfficeTable.hasPagination}
                        paginationComponentOptions={paginationComponenetOptions}
                        subHeader={true}
                        subHeaderWrap={false}
                        subHeaderComponent={
                            <SubHeaderView
                                backOfficeTable={this.props.backOfficeTable}
                                elements={elements}
                                onSearch={this.props.onSearch}
                                isLoading={this.props.isLoading}
                            />
                        }
                        noDataComponent={
                            this.props.lang[`noResult_${this.props.backOfficeTable.name[0]}`]
                                ? this.props.lang[`noResult_${this.props.backOfficeTable.name[0]}`]
                                : this.props.lang.noResult
                        }
                        sortIcon={
                            <i
                                className="fas fa-arrow-down"
                                style={{ marginLeft: "2px", height: "14px !important", width: "14px !important" }}
                            ></i>
                        }
                        defaultSortField={
                            this.props.backOfficeTable.defaultSortField
                                ? this.props.backOfficeTable.defaultSortField
                                : ""
                        }
                        onRowClicked={onRowClicked}
                    />
                </div>
            </>
        );
    }

    translateColumnNamesAndAddActionsColumn(
        columns: any[],
        lang: any,
        displayActionButtons: boolean,
        onModifyLine: (id: number, index: number) => void,
        backOfficeTable: any,
        selectCheckboxListOptions?: () => any
    ): IDataTableColumn[] {
        const translatedColumns: IDataTableColumn[] = [];
        const selectCheckboxListOption = selectCheckboxListOptions ? selectCheckboxListOptions() : null;
        columns.forEach((col): void => {
            if (
                displayCriteria(
                    col.displayCriteria,
                    getModelsFromDict(this.props.institutionDict, this.props.idInstitutions),
                    getModelsFromDict(this.props.institutionOptionDict, this.props.idInstitutionOptions),
                    this.props.modifyingElement ? true : false,
                    this.props.idPoles
                ) &&
                col.displayColumn
            ) {
                translatedColumns.push({
                    name: <ColumnName lang={this.props.lang} column={col} />,
                    sortable: true,
                    selector: col.name,
                    cell: (row, index): JSX.Element => (
                        <ColumnView
                            element={row}
                            index={index}
                            column={col}
                            backOfficeTable={backOfficeTable}
                            selectCheckboxListOptions={selectCheckboxListOption}
                            key={`${col.name}__${index}`}
                        />
                    ),
                    width: col.width,
                    minWidth: col.minWidth,
                    maxWidth: col.maxWidth,
                    center: col.centerContent,
                    conditionalCellStyles: [{ when: () => col.centerContent, style: { paddingLeft: "0px" } }]
                });
            }
        });
        if (displayActionButtons) {
            translatedColumns.push({
                name: "",
                // eslint-disable-next-line react/display-name
                cell: (row, index, column, id): JSX.Element => (
                    <div className="td-line show-on-line-hover">
                        <div className="line_button">
                            <i
                                key={`header_${-1}`}
                                onClick={(): void => {
                                    onModifyLine(row.id, index);
                                }}
                                className="fa fa-pencil pointer mr-23"
                                aria-hidden="true"
                            ></i>
                            &nbsp;
                            {backOfficeTable.hasDeleteButton && (
                                <i
                                    key={`header_${id}`}
                                    onClick={(): void => {
                                        this.props.setItemIdToBeDeleted(row.id);
                                        this.props.setShowConfirmationModalAction(true, true);
                                    }}
                                    className="fa fa-trash-alt pointer"
                                    aria-hidden="true"
                                ></i>
                            )}
                        </div>
                    </div>
                )
            });
        }
        return translatedColumns.map((e, i): any => {
            e.id = `${e.name}_${i}}`;
            return e;
        });
    }

    getColumns(): IBackOfficeColumn[] {
        return this.props.backOfficeTable.columns[this.props.selectedTab];
    }
}

export default connect(
    (centralState: any): IReduxPropsDataTableView => ({
        lang: centralState.language.lang,
        selectedTab: centralState.tables.selectedTab,
        me: centralState.auth.user,
        idInstitutionOptions: centralState.institutionOption.ids,
        institutionOptionDict: centralState.institutionOption.byId,
        idInstitutions: centralState.institutions.ids,
        institutionDict: centralState.institutions.byId,
        idPoles: centralState.poles.ids,
        modifyingElement: centralState.tables.modifyingElement
    }),
    { setShowConfirmationModalAction, setItemIdToBeDeleted, setModifyingElementAction, setFormTableAction }
)(DataTableView);
