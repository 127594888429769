import { IReduxAction, IReduxInitialState } from "../store.type";
import InstitutionHasOAuth2Types from "../type/institutionHasOAuth2.type";
import { IInstitutionHasOAuth2 } from "../../../Model/InstitutionHasOAuth2";

export enum Oauth2ActionStatus {
    None = -1,
    Failed = 1,
    Succeeded = 2
}

const initialState: IReduxInitialState<IInstitutionHasOAuth2> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: true,
    errorCode: Oauth2ActionStatus.None
};

export default function institutionHasOAuth2Reducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionHasOAuth2Types.FETCH_INSTITUTIONHASOAUTH2S:
        case InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2S:
            return {
                ...state,
                byId: action.payload.byId,
                ids: action.payload.ids,
                isLoading: false
            };

        case InstitutionHasOAuth2Types.FETCH_INSTITUTIONHASOAUTH2S_COUNT:
            return {
                ...state,
                count: action.payload
            };

        case InstitutionHasOAuth2Types.SET_SELECTED_INSTITUTIONHASOAUTH2:
            return {
                ...state,
                selectedId: action.payload
            };

        case InstitutionHasOAuth2Types.SET_SELECTED_INSTITUTIONHASOAUTH2_MULTI:
            return {
                ...state,
                selectedMulti: action.payload
            };

        case InstitutionHasOAuth2Types.SET_IS_LOADING_INSTITUTIONHASOAUTH2:
            return {
                ...state,
                isLoading: action.payload
            };

        case InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ORDER:
            return {
                ...state,
                ids: action.payload
            };

        case InstitutionHasOAuth2Types.SET_INSTITUTIONHASOAUTH2_ERROR_CODE:
            return {
                ...state,
                errorCode: action.payload,
                isLoading: action.payload === -1 ? true : false
            };

        case InstitutionHasOAuth2Types.INSERT_INSTITUTIONHASOAUTH2:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                ids: state.ids.includes(action.payload.id) ? [...state.ids] : [...state.ids, action.payload.id],
                isLoading: false,
                errorCode: Oauth2ActionStatus.Succeeded
            };

        case InstitutionHasOAuth2Types.UPDATE_INSTITUTIONHASOAUTH2:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                },
                isLoading: false,
                errorCode: Oauth2ActionStatus.Succeeded
            };

        default:
            return state;
    }
}
