/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./Slider.scss";

interface SliderProps {
    onValueChanged: (isSelected: boolean) => void;
    text: string;
    isEnabled: boolean;
    id?: string;
    disabled?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
    onValueChanged,
    text,
    isEnabled,
    disabled = false,
    id = "slider"
}): JSX.Element => {
    const [value, setValue] = useState(false);

    const handleClick = (): void => {
        onValueChanged(!value);
        setValue((prev): boolean => !prev);
    };

    return (
        <div className="col-sm no-left-padding">
            <div className="custom-control custom-switch custom-switch-lg">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={id}
                    onClick={handleClick}
                    checked={isEnabled}
                    disabled={disabled}
                />
                <label className="custom-control-label" htmlFor={id}>
                    {text}
                </label>
            </div>
        </div>
    );
};
