import { PossiblePosts, PossibleApis } from "../../Constant/Api.constant";
import AxiosInstance from "../../modules/axios/axios.instance";

export function postFactory<T>(
    modalName: PossiblePosts,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (data: T) => Promise<T> {
    return async (data: T): Promise<T> => {
        const res = await AxiosInstance.post(`${api}/api/v${apiVersion}/${modalName}`, data);
        return res.data.data;
    };
}

export function postByIdSubFieldFactory<T>(
    modalName: PossiblePosts,
    api = PossibleApis.medgoApi,
    apiVersion: number = 2
): (id: number, data?: T) => Promise<T> {
    return async (id: number, data?: T): Promise<T> => {
        const res = await AxiosInstance.post(
            `${api}/api/v${apiVersion}/${modalName.replace(":id", id.toString())}`,
            data
        );
        return res.data.data;
    };
}
