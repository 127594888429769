import { connect } from "react-redux";
import {
    setInstitutionOptionChurnsAction,
    fetchInstitutionOptionChurnsAction,
    setIsLoadingInstitutionOptionChurnAction
} from "../../store/action/institutionOptionChurn.action";
import { IInstitutionOptionChurn } from "../../../Model/InstitutionOptionChurn";
import {
    TableComponent,
    IPropsTable,
    IReduxPropsTable,
    getConnectParentState,
    IReduxActionsTable,
    getConnectParentActions
} from "./Table.container";
import { IQueryParams } from "npm-medgo-query";
import { INormalizedInstitutionOptionChurns } from "../../../Model/InstitutionOptionChurn";
import { getModelsFromDict } from "../../modules/store/util";

//@ts-ignore
interface IReduxPropsInstitutionOptionChurn extends IReduxPropsTable {
    idInstitutionOptionChurns: number[];
    institutionOptionChurnDict: INormalizedInstitutionOptionChurns;
    idInstitutions: number[];
}

interface IPropsInstitutionOptionChurn
    extends IReduxPropsInstitutionOptionChurn,
        IReduxActionsInstitutionOptionChurn,
        IPropsTable {}

interface IReduxActionsInstitutionOptionChurn extends IReduxActionsTable {
    setInstitutionOptionChurnsAction: (elements: IInstitutionOptionChurn[]) => any;
    fetchInstitutionOptionChurnsAction: (param: IQueryParams) => any;
    setIsLoadingInstitutionOptionChurnAction: (isLoading: boolean) => any;
}

class TableInstitutionOptionChurnContainer extends TableComponent<IPropsInstitutionOptionChurn> {
    async fetchElementsAction(params: IQueryParams): Promise<void> {
        this.props.setIsLoadingInstitutionOptionChurnAction(true);
        if (this.props.idInstitutionOptionChurns.length === 0)
            await this.props.fetchInstitutionOptionChurnsAction(params);
        this.props.setIsLoadingInstitutionOptionChurnAction(false);
    }

    setElements(elements: any): void {
        this.props.setInstitutionOptionChurnsAction(elements);
    }

    getElements(): any[] {
        return getModelsFromDict(this.props.institutionOptionChurnDict, this.props.idInstitutionOptionChurns);
    }
}

export default connect(
    (centralState: any): IReduxPropsInstitutionOptionChurn =>
        Object.assign(getConnectParentState(centralState), {
            idInstitutionOptionChurns: centralState.institutionOptionChurn.ids,
            institutionOptionChurnDict: centralState.institutionOptionChurn.byId,
            idSelectedInstitutions: centralState.institutions.selectedMulti,
            idInstitutions: centralState.institutions.ids
        }),
    Object.assign(getConnectParentActions(), {
        setInstitutionOptionChurnsAction,
        fetchInstitutionOptionChurnsAction,
        setIsLoadingInstitutionOptionChurnAction
    })
)(TableInstitutionOptionChurnContainer);
