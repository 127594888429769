import { connect } from "react-redux";
import {
    insertInterimAgencyAction,
    updateInterimAgencyAction,
    fetchInterimAgencysAction
} from "../../store/action/interimAgency.action";
import { FormComponent, getConnectParentActions, getConnectParentState, IPropsForm } from "./Form.container";
import { IInterimAgency } from "../../../Model/InterimAgency";
import { IFormDictionary } from "../../store/type/form.type";
import { fetchJobsAction } from "../../store/action/job.action";
import { fetchSpecialtiesAction } from "../../store/action/specialty.action";
import { EBackOfficeTableName } from "../../constants/Table.constants";
import { getFormDictionaryRecordsDict, getModelsFromDict } from "../../modules/store/util";
import { EBackOfficeColumnName, EBackOfficeColumnType, IBackOfficeColumn } from "../../constants/Column.constants";
import { IJob, INormalizedJobs } from "../../../Model/Job";
import { INormalizedSpecialties, ISpecialty } from "../../../Model/Specialty";
import { IReduxPropsTable } from "../table/Table.container";
import { IQueryParams } from "npm-medgo-query";
import { deleteSubscriptionJobs, insertSubscriptionJobs } from "../../service/interimAgencyHasJob.service";

interface IReduxPropsInterimAgency extends IReduxPropsTable {
    idJobs: number[];
    idSpecialties: number[];
    jobDict: INormalizedJobs;
    specialtyDict: INormalizedSpecialties;
    modifyingElement: any;
}
interface IReduxActionsInterimAgency {
    insertInterimAgencyAction: (formDictionary: IFormDictionary) => Promise<IInterimAgency>;
    fetchInterimAgencysAction: (param: IQueryParams) => any;
    updateInterimAgencyAction: (params: {
        id: number;
        idSubscription: string;
        idInstitution: number;
        interimAgency: IInterimAgency;
    }) => any;
    setFormKeyValueAction: (
        key: string,
        value: any,
        table: EBackOfficeTableName,
        columnType: EBackOfficeColumnType,
        index: number
    ) => void;
    fetchJobsAction: (param: IQueryParams) => any;
    fetchSpecialtiesAction: (param: IQueryParams, jobDict: INormalizedJobs) => any;
}

interface IPropsFormInterimAgency
    extends IReduxActionsInterimAgency,
        IReduxPropsInterimAgency,
        IPropsForm<IInterimAgency> {}

class FormInterimAgencyComponent extends FormComponent<IInterimAgency, IPropsFormInterimAgency> {
    async componentDidMount(): Promise<void> {
        if (this.props.idJobs.length === 0)
            await this.props.fetchJobsAction({
                query: { institution: this.props.idInstitutions },
                limit: 99999
            });
        if (this.props.idSpecialties.length === 0)
            await this.props.fetchSpecialtiesAction(
                {
                    query: { institution: this.props.idInstitutions },
                    limit: 99999
                },
                this.props.jobDict
            );
    }
    async createElementAction(): Promise<void> {
        const formDictionaryInterimAgencys = getFormDictionaryRecordsDict(
            this.props.formDictionary,
            EBackOfficeTableName.interim
        );
        const selectedInstitutions = this.getSelectedIdInstitutions();
        const formKeys = Object.keys(formDictionaryInterimAgencys);
        const promises = formKeys.map(
            async (key: any): Promise<void> => {
                try {
                    const formDictionaryInterimAgency = formDictionaryInterimAgencys[key];
                    await Promise.all(
                        selectedInstitutions.map(
                            async (idInstitution: number): Promise<void> => {
                                const formDictionary = Object.assign(formDictionaryInterimAgency, {
                                    institution: idInstitution
                                });
                                formDictionary.name = formDictionary.agencyName;
                                await this.props.insertInterimAgencyAction(formDictionary);
                            }
                        )
                    );
                } catch (err) {
                    this.props.setNotificationMessage({
                        message: this.props.lang.notAllInsertHaveBeenWellDone,
                        icon: "fa fa-times",
                        color: "danger"
                    });
                }
            }
        );
        await Promise.all(promises);
        await this.props.fetchInterimAgencysAction({
            query: {
                institution: selectedInstitutions[0]
            }
        });
    }

    async updateElementAction(): Promise<void> {
        let interimAgency = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.interim)[0];
        interimAgency.name = interimAgency.agencyName;
        const idInterimAgency = interimAgency.id;
        const currentSelectableSpecialties = getModelsFromDict<ISpecialty>(
            this.props.specialtyDict,
            this.props.idSpecialties
        ).filter((specialty: any) => interimAgency.jobs.indexOf(specialty.job) !== -1);
        interimAgency.specialties = interimAgency.specialties.filter(
            (idSpecialty: number): boolean => currentSelectableSpecialties.map(e => e.id).indexOf(idSpecialty) !== -1
        );
        const selectedInstitutions = this.getSelectedIdInstitutions();
        await this.props.updateInterimAgencyAction({
            id: idInterimAgency,
            idSubscription: interimAgency.idSubscription,
            idInstitution: selectedInstitutions[0],
            interimAgency: interimAgency as IInterimAgency
        });
        const jobsBeforeUpdate = this.props.modifyingElement.jobs.filter((idJob: number): boolean => idJob !== null);
        const jobsAfterUpdate = interimAgency.jobs.filter((idJob: number): boolean => idJob !== null);
        const jobsToBeDeleted = jobsBeforeUpdate.filter(
            (idJob: number): boolean => jobsAfterUpdate.indexOf(idJob) === -1
        );
        const jobsToBeAdded = jobsAfterUpdate.filter(
            (idJob: number): boolean => jobsBeforeUpdate.indexOf(idJob) === -1
        );
        const specialtiesBeforeUpdate = this.props.modifyingElement.specialties.filter(
            (idSpecialty: number): boolean => idSpecialty !== null
        );

        const specialtiesAfterUpdate = interimAgency.specialties.filter(
            (idSpecialty: number): boolean => idSpecialty !== null
        );

        const specialtiesToBeDeleted = specialtiesBeforeUpdate.filter(
            (idSpecialty: number): boolean => specialtiesAfterUpdate.indexOf(idSpecialty) === -1
        );

        const specialtiesToBeAdded = specialtiesAfterUpdate.filter(
            (idSpecialty: number): boolean => specialtiesBeforeUpdate.indexOf(idSpecialty) === -1
        );
        if (jobsToBeDeleted.length || specialtiesToBeDeleted.length) {
            await deleteSubscriptionJobs({
                idSubscription: interimAgency.idSubscription,
                idLegacySubscription: interimAgency.id,
                idInstitution: selectedInstitutions[0],
                jobs: jobsToBeDeleted,
                specialties: specialtiesToBeDeleted
            });
        }
        if (jobsToBeAdded.length || specialtiesToBeAdded.length) {
            await insertSubscriptionJobs({
                idSubscription: interimAgency.idSubscription,
                idLegacySubscription: interimAgency.id,
                idInstitution: selectedInstitutions[0],
                jobs: jobsToBeAdded,
                specialties: specialtiesToBeAdded
            });
        }
    }

    selectCheckboxListOptions(): any {
        let interimForm = getFormDictionaryRecordsDict(this.props.formDictionary, EBackOfficeTableName.interim)[0];
        const selectCheckboxListOptionsServices =
            interimForm && interimForm.jobs
                ? getModelsFromDict<ISpecialty>(this.props.specialtyDict, this.props.idSpecialties).filter(
                      (specialty: any) => interimForm.jobs.indexOf(specialty.job) !== -1
                  )
                : [];
        return {
            [EBackOfficeTableName.interim]: {
                [EBackOfficeColumnName.jobs]: getModelsFromDict<IJob>(this.props.jobDict, this.props.idJobs),
                [EBackOfficeColumnName.specialties]: selectCheckboxListOptionsServices
            }
        };
    }

    customSelectCheckboxOnChange(value: any, column: IBackOfficeColumn, formIndex: number): void {
        if (column.name === EBackOfficeColumnName.jobs) {
            const interimForm = getFormDictionaryRecordsDict(
                this.props.formDictionary,
                EBackOfficeTableName.interim
            )[0];

            if (interimForm?.jobs && interimForm?.specialties) {
                const allowedSpecialities = getModelsFromDict<ISpecialty>(
                    this.props.specialtyDict,
                    this.props.idSpecialties
                ).filter((specialty: any) => interimForm.jobs.indexOf(specialty.job) !== -1);

                const filteredSpecialities = interimForm.specialties.filter((id: number) =>
                    allowedSpecialities.map(speciality => speciality.id).includes(id)
                );

                const newFormDictionary: IFormDictionary = {
                    interim: {
                        0: { ...interimForm, jobs: interimForm.jobs, specialties: filteredSpecialities }
                    }
                };

                this.props.setFormDictionaryAction(newFormDictionary);
            }
        }
    }
}

export default connect(
    (centralState: any): IPropsFormInterimAgency =>
        Object.assign(getConnectParentState(centralState), {
            idJobs: centralState.jobs.ids,
            idSpecialties: centralState.specialties.ids,
            jobDict: centralState.jobs.allById,
            specialtyDict: centralState.specialties.allById,
            modifyingElement: centralState.tables.modifyingElement
        }),
    Object.assign(getConnectParentActions(), {
        insertInterimAgencyAction,
        fetchInterimAgencysAction,
        updateInterimAgencyAction,
        fetchJobsAction,
        fetchSpecialtiesAction
    })
)(FormInterimAgencyComponent);
