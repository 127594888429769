import AuthTypes from "../type/auth.type";
import { fetchMe } from "../../service/user.service";
import { IReduxAction } from "../store.types";
import { getErrorFromResponse } from "../../../toolbox/store/apiError";
import jwt from "jsonwebtoken";
import { LangToolbox } from "npm-hublo-toolbox";

export function setIsLoadingFetchMeAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AuthTypes.IS_LOADING_FETCH_ME,
            payload: bool
        });
    };
}
export function setTokenAction(token: string, ssoAccessToken: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        const decoded: any = jwt.decode(token);
        const idLanguage = decoded?.user?.idLanguage ?? decoded?.idLanguage;
        if (idLanguage) {
            localStorage.setItem("lang", LangToolbox.getLangStringFromId(idLanguage));
        }
        dispatch({
            type: AuthTypes.SET_TOKEN,
            payload: { token, ssoAccessToken }
        });
    };
}

export function fetchMeAction(): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const user = await fetchMe();
            dispatch({
                type: AuthTypes.FETCH_ME,
                payload: user
            });
        } catch (err) {
            throw getErrorFromResponse(err);
        }
    };
}
