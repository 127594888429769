import { IJob, ICreateJobRequest } from "../../Model/Job";
import {
    PossibleFetchs,
    PossiblePosts,
    PossibleUpdates,
    PossibleDeletes,
    PossibleApis
} from "../../Constant/Api.constant";
import { IQueryParams } from "npm-medgo-query";
import { postFetchFactory } from "../../toolbox/factory/postFetch.factory";
import { postFactory } from "../../toolbox/factory/post.factory";
import { updateFactory } from "../../toolbox/factory/update.factory";
import { deleteFactory } from "../../toolbox/factory/delete.factory";

const JobDefaultSelect = ["id", "name", "institution"];
export async function fetchJobs(query: IQueryParams): Promise<IJob[]> {
    const jobs = await postFetchFactory<IJob>(PossibleFetchs.job, PossibleApis.medgoApi)(
        {
            select: JobDefaultSelect
        },
        query
    );
    return jobs;
}

export async function insertInstitutionHasJob(job: ICreateJobRequest): Promise<ICreateJobRequest> {
    const jobs = await postFactory<ICreateJobRequest>(PossiblePosts.job, PossibleApis.medgoApi)(job);
    return jobs;
}

export async function updateJob(id: number, job: IJob): Promise<IJob> {
    const jobs = await updateFactory<IJob>(PossibleUpdates.job, PossibleApis.medgoApi)(id, job);
    return jobs;
}

export async function deleteJob(id: number): Promise<IJob> {
    const jobs = await deleteFactory<IJob>(PossibleDeletes.job, PossibleApis.medgoApi)(id);
    return jobs;
}
