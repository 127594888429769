import { ILang } from "./Lang";

/* eslint-disable @typescript-eslint/camelcase */
export const fr: ILang = {
    no: "Non",
    yes: "Oui",
    modifier: "Modifier",
    institution: "établissement",
    institutiongroup: "groupement",
    schedule: "code horaire",
    service: "service",
    job: "métier",
    missionMotifCreation: "motif",
    specialty: "spécialité",
    admin: "admin",
    infoOnWorker: "info Supp",
    notification: "notification",
    contract: "contrat",
    salaryGrid: "grille de primes",
    institutionChronos: "gta Chronos",
    gtaOctime: "gta Octime",
    interim: "intérim",
    specialHour: "heures spéciales",
    institutionPlural: "établissements",
    institutiongroupPlural: "groupements",
    schedulePlural: "codes horaires",
    servicePlural: "services",
    jobPlural: "métiers",
    missionMotifCreationPlural: "motifs",
    specialtyPlural: "spécialités",
    adminPlural: "admins",
    infoOnWorkerPlural: "infos Supp",
    notificationPlural: "notifications",
    contractPlural: "contrats",
    salaryGridPlural: "grilles de primes",
    institutionChronosPlural: "gta Chronos",
    gtaOctimePlural: "gta Octime",
    interimPlural: "intérims",
    specialHourPlural: "heures spéciales",
    institutionNone: "Aucun établissement",
    institutiongroupNone: "Aucun groupement",
    scheduleNone: "Aucun code horaire",
    serviceNone: "Aucun service",
    jobNone: "Aucun métier",
    missionMotifCreationNone: "Aucun motif",
    specialtyNone: "Aucune spécialité",
    motifNone: "Aucun motif",
    adminNone: "Aucun admin",
    infoOnWorkerNone: "Aucune info supp",
    notificationNone: "Aucune notification",
    contractNone: "Aucun contrat",
    salaryGridNone: "Aucune grille de prime",
    institutionChronosNone: "Aucun GTA Chronos",
    gtaOctimeNone: "Aucun GTA Octime",
    interimNone: "Aucun intérim",
    specialHourNone: "Aucune heure spéciale",
    actions: "actions",
    add: "add",
    next: "suivant",
    previous: "précédent",
    new: "nouveau",
    return: "retour",
    confirm: "Confirmer",
    select: "Sélectionner",
    date: "Date",
    action: "Action",
    success: "Succès",
    here: "ici",
    sign: "signer",
    mission: "Mission",
    proMissions: "mes missions",
    proVacataires: "mon réseau",
    proContracts: "mes contrats",
    dashboard: "tableau de bord",
    redirectToLocalhost: "Redirection vers localhost",
    profile: "Mon compte",
    myInstitutions: "mes établissements",
    myNotifications: "mes notifications",
    myConfig: "paramétrage",
    logout: "déconnexion",
    title: "Back office - Hublo",
    addLine: "Ligne ajoutée avec succès",
    modifyLine: "Ligne modifiée avec succès",
    errorLine: "Ligne non ajoutée car une erreur est survenue",
    errCodeGeneric520: "Une erreur est survenue. Veuillez contacter le support",
    errCodeGeneric500: "Une erreur s’est produite, veuillez réessayer",
    errCodeGeneric400: "Veuillez vérifier vos paramètres",
    errCodeGeneric404: "L’information que vous cherchez est introuvable",
    errCodeGeneric401: "Veuillez vous authentifier",
    errCodeGeneric403: "Vous n’etes pas authorisé à effectuer cette action",
    errCodeGeneric409: "Nom déjà existant",
    errCodeGeneric406: "Valeur incorrecte",
    errCodeGeneric4062: "Information non valide",
    errCodeGeneric419: "Champs requis",
    errCodeGeneric449: "Admin déjà existant",
    typeHelper: "1 : par défaut",
    contractLevelHelper: "1 : Hublo, 2 : peut télécharger les contrats, 3 : ne peut pas télécharger les contrats",
    recapTypeHelper:
        "0 : pas de recap, 1 : recap envoyés tous les jours pour des contrats par mission, 2 : recap mensuel pour des contrats par remplaçant, 3 : DPAE",
    nbMinutesAfterLaunchingRequestHelper: "Temps entre l'activation de l'interim et l'envoi aux agences (en minutes)",
    seniorityShouldBeLessThanHelper: "Inclus",
    seniorityShouldBeMoreThanHelper: "Exclus",
    interfacingCodeHelper: "Cliquer ici pour être redirigé vers la FAQ",
    id: "id",
    name: "nom",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Adresse mail",
    mobilePhone: "Numéro de téléphone",
    level: "level",
    contractLevel: "contrat",
    isPlanningAllowed: "plannification",
    isStatisticAllowed: "statistiques",
    shouldSendEmail: "envoi d'une invitation",
    typeAdmin: "type d’admin",
    type: "type",
    typeName: "type",
    code: "code",
    "code-form": "Code",
    codeUF: "code UF",
    beginAt: "début",
    url: "URL",
    isProxyRequired: "proxy",
    token: "token",
    password: "mot de passe",
    hasSecurityModule: "module de sécurité",
    endAt: "fin",
    nbWorkedMinutes: "temps de travail",
    interfacingCode: "code interface",
    nameSMS: "Nom SMS",
    pole: "pole",
    interfacingCode2: "code interface 2",
    interfacingCode3: "code interface 3",
    isNameAbsentPersonRequired: "info requise",
    nameAbsentPersonPlaceholderInfo: "placeholder",
    isFullTime: "temps plein",
    nbMinutesAfterLaunchingRequest: "Déclenchement (minutes)",
    hasAutoValidation: "Validation Automatique",
    isDayMission: "mission de jour ?",
    isPublicHoliday: "mission jour férié ?",
    isSunday: "mission le dimanche ?",
    seniorityShouldBeLessThan: "minimum d'ancienneté nécessaire",
    seniorityShouldBeMoreThan: "maximum d'ancienneté nécessaire",
    services: "services",
    jobs: "métiers",
    codeAPI: "code API",
    user: "utilisateur",
    loginKey: "clé login",
    isVersionGreaterOrEqualTo92: "version >= 9.2",
    clientName: "client",
    societe: "société",
    webrest: "webrest",
    codeSociete: "code société",
    codeEtablissement: "code établissement",
    codeService: "code service",
    codeInterface: "code interface",
    jobName: "métier",
    specialtyName: "spécialité",
    poleName: "pole",
    recapType: "type de récapitulatif",
    to: "to",
    cc: "cc",
    bcc: "bcc",
    break: "pause",
    "break-form": "pause non payée",
    searchPlaceholder_admin: "Rechercher un administrateur avec un nom, email, téléphone",
    searchPlaceholder_adminV2: "Rechercher un administrateur avec un nom, email, téléphone, ou matricule",
    searchPlaceholder_schedule: "Rechercher un code horaire avec un nom, un horaire ou un temps de travail",
    add_schedule: "Ajouter un code horaire",
    add_service: "Ajouter un service",
    add_job: "Ajouter un métier",
    add_missionMotifCreation: "Ajouter un motif",
    add_specialty: "Ajouter une spécialité",
    add_admin: "Ajouter un admin",
    add_adminV2: "Ajouter un administrateur",
    add_infoOnWorker: "Ajouter une info supp",
    add_notification: "Ajouter une notification",
    add_salaryGrid: "Ajouter une grille de prime",
    add_interim: "Ajouter une agence",
    add_title_interim: "Ajouter une agence de sourcing",
    add_specialHour: "Ajouter une heure spéciale",
    add_contract: "Ajouter un contrat",
    modify_schedule: "Modifier un code horaire",
    modify_service: "Modifier un service",
    modify_job: "Modifier un métier",
    modify_missionMotifCreation: "Modifier un motif",
    modify_specialty: "Modifier une spécialité",
    modify_admin: "Modifier un admin",
    modify_infoOnWorker: "Modifier une info supp",
    modify_notification: "Modifier une notification",
    modify_salaryGrid: "Modifier une grille de prime",
    modify_interim: "Modifier un intérim",
    modify_specialHour: "Modifier une heure spéciale",
    modify_contract: "Modifier un contrat",
    create: "Créer",
    save: "Enregistrer",
    cancel: "Annuler",
    notAllInsertHaveBeenWellDone: "Toutes les insertions n'ont pas été bien réalisées",
    counter: "compteurs",
    counterStatus: "compteurs",
    counterLimit: "limite bloquante",
    isActive: "État",
    whichCounter: "Type de compteurs",
    counterLimitType: "Type",
    starter: "Début du compteur",
    export: "Exporter",
    chooseTypeOfCounterForYourInstitution: "choisissez les types de compteurs accessibles pour votre établissement.",
    onceLimitReachedWorkersWontBeAlerted:
        "Une fois la limite atteinte, les remplaçants ne sont plus alertés et ne peuvent plus postuler.",
    workerType: "Types de remplaçants",
    weekly: "Hebdomadaire",
    monthly: "Mensuel",
    yearly: "Annuel",
    yearlyBaseOnJoiningDate: "Annuel - en fonction de la date d'ancienneté",
    weeklyByRollingWeek: "Semaine glissante",
    weeklyH: "Heures hebdomadaires",
    weeklyByRollingWeekH: "Semaine glissante",
    monthlyH: "Heures mensuelles",
    yearlyH: "Heures annuelles",
    yearlyJoinDateH: "Heures annuelles (ancienneté)",
    weeklyShifts: "Missions hebdomadaires",
    monthlyShifts: "Missions mensuelles",
    yearlyShifts: "Missions annuelles",
    yearlyJoinDateShifts: "Missions annuelles (ancienneté)",
    weeklyStarter: "Chaque __DAY",
    monthlyStarter: "Chaque __DAY du mois",
    yearlyStarter: "Chaque __DAY",
    yearlyBaseOnJoiningDateStarter: "date d'ancienneté",
    salarie: "Salarié",
    vacataire: "Vacataire",
    schedulesHaveBeenCreated: "Vos codes horaires ont bien été créés !",
    scheduleHasBeenCreated: "Votre code horaire a bien été créé !",
    scheduleHasBeenUpdated: "Votre code horaire a été modifié !",
    confirmationSubtitle_schedule: "Êtes-vous sûr de vouloir modifier ce code horaire ?",
    confirmationText_schedule:
        "Vous avez actuellement __MISSIONS_COUNT mission à venir utilisant ce code horaire. La modification du code horaire n’affectera pas ces missions. Si besoin, vous pourrez modifier les horaires de ces missions une fois vos missions pourvues.",
    confirmationText_schedule_plural:
        "Vous avez actuellement __MISSIONS_COUNT mission à venir utilisant ce code horaire. La modification du code horaire n’affectera pas ces missions. Si besoin, vous pourrez modifier les horaires de ces missions une fois vos missions pourvues.",
    yesApply: "Confirmer",
    noDontApply: "Annuler",
    enabled: "Activé",
    disabled: "Désactivé",
    deleteConfirmationSubtitle_schedule: "Êtes-vous sûr de vouloir supprimer ce code horaire ?",
    deleteConfirmationText_schedule: "Cela n’affectera pas vos missions déjà créées avec ce code horaire.",
    deleteActionCompletedConfirmationMessage_schedule: "Votre code horaire a été supprimé !",
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "sur",
    areYouSure: "Êtes-vous sûr ?",
    deleted: "Supprimé !",
    noResult: "Aucun résultat",
    exportMissionThenAttribute: "Exporter les missions et attribuer les codes paie",
    lastExportOnXXXByXXX: "Dernier export le __VAR1 par __VAR2",
    payCode: "Codes paie",
    searchPayCode: "Rechercher un code paie",
    addPayCode: "Ajouter un code paie",
    internalCode: "Code interne",
    externalCode: "Code externe",
    businessDay: "Jour ouvrable",
    night: "Nuit",
    sundayAndHoliday: "Veille dim et jour férié nuit",
    rangeOfTotal: "__VAR1 de __VAR2",
    simpleCode: "Code",
    editPayCode: "Editer un code paie",
    deletePayCode: "Supprimer un code paie",
    askForDeletingPayCode: "Êtes-vous sûr de vouloir supprimer le code paie ?",
    delete: "Supprimer",
    payCodeWellCreated: "Le code paie a bien été créé !",
    payCodeWellChanged: "Le code paie a bien été modifié !",
    payCodeWellDeleted: "Le code paie a bien été supprimé !",
    payrollExport: "Export paie",
    sso: "SSO",
    ssoPageTitle: "Configurer le SSO (Single Sign-On)",
    missionExportAndPayrollCodeConfiguration: "Exporter les missions et attribuer les codes paie",
    exportConfiguration: "Configuration de l'export",
    publicHolidays: "Jours fériés",
    add_exportConfiguration: "Ajouter un code paie",
    searchPlaceholder_exportConfiguration: "Rechercher un code paie",
    isNight: "Nuit",
    isBusinessDay: "Jour ouvrable",
    isBeforeSundayOrPublicHoliday: "Veille dim et jour férié nuit",
    deleteConfirmationSubtitle_exportConfiguration: "Supprimer un code paie",
    deleteConfirmationText_exportConfiguration: "Êtes-vous sûr de vouloir supprimer le code paie ?",
    modify_exportConfiguration: "Éditer un code paie",
    deleteActionCompletedConfirmationMessage_exportConfiguration: "Le code paie a bien été supprimé !",
    exportConfigurationHasBeenUpdated: "Le code paie a bien été modifié !",
    exportConfigurationHasBeenCreated: "Le code paie a bien été créé !",
    exportConfigurationsHaveBeenCreated: "Les codes paies ont bien été créés !",
    confirmationSubtitle_exportConfiguration: "Êtes-vous sûr de vouloir éditer le code paie ?",
    youShouldPreciseInstitutionName: "Vous devez renseigner un nom pour votre établissement",
    yourAddressAreNotRecognized:
        "Votre adresse n'a pas été reconnue. Commencez à taper votre adresse et sélectionnez votre adresse dans les résultats proposés. Si votre adresse n'apparaît pas, sélectionnez une adresse à proximité de chez vous",
    youShouldIndicateValidAddress: "Vous devez sélectionner une adresse valide",
    pleaseFillYourAddressAndSelectAChoice:
        "Veuillez renseigner votre adresse et sélectionner un choix parmi la liste d'adresse proposée",
    youHaveQuestion: "Vous avez une question ?",
    usefullInformations: "Informations pratiques",
    institutionName: "Nom de l'établissement",
    visibleMobileForWorkers: "Téléphone visible par les remplaçants",
    institutionAddress: "Adresse de l'établissement",
    timeCodePlural: "Codes horaires",
    subtitleJobs: "Métiers",
    subtitleServices: "Services",
    speciaties: "Spécialités",
    toModifyServiceListOrTimeCode:
        "Pour modifier la liste des services, des métiers, des spécialités ou des codes horaires, merci de nous contacter à",
    institutionPresentation: "Présentation de l'établissement",
    accessInformation: "Information d'accès",
    dateInput: "JJ/MM/AAAA",
    publicholidayConfiguration: "Configuration de l'export",
    add_publicholidayConfiguration: "Ajouter un jour férié",
    searchPlaceholder_publicholidayConfiguration: "Rechercher un jour férié",
    deleteConfirmationSubtitle_publicholidayConfiguration: "Supprimer un jour férié",
    deleteConfirmationText_publicholidayConfiguration:
        "Êtes-vous sûr de vouloir supprimer le __PUBLIC__HOLIDAY des jours fériés de votre établissement ?",
    modify_publicholidayConfiguration: "Éditer un jour férié",
    deleteActionCompletedConfirmationMessage_publicholidayConfiguration: "Le jour férié a bien été supprimé !",
    publicholidayConfigurationHasBeenUpdated: "Le jour férié a bien été modifié !",
    publicholidayConfigurationHasBeenCreated: "Le jour férié a bien été créé !",
    publicholidayConfigurationsHaveBeenCreated: "Les jour fériés ont bien été créés !",
    confirmationSubtitle_publicholidayConfiguration: "Êtes-vous sûr de vouloir éditer le jour férié ?",
    addedPublicHolidayAlreadyExistsInTheInstitution: "Le jour férié ajouté existe déjà dans votre établissement.",
    addedPublicHolidayAlreadyExistsInOneOfTheSelectedInstitutions:
        "Le jour férié ajouté existe déjà dans un ou plusieurs des établissements sélectionnés.",
    addedPublicHolidaysAlreadyExistsInTheInstitution:
        "Un ou plusieurs jours ajoutés existent déjà dans votre établissement.",
    addedPublicHolidaysAlreadyExistsInTheInstitutions:
        "Un ou plusieurs jours ajoutés existent déjà dans un ou plusieurs des établissements sélectionnés.",
    noLastExport: "Il n’y a pas eu encore eu d’export effectué !",
    anEmailWillBeSentToXXX: "L'export a été envoyé à ___VAR1",
    documentTemplate: "Modèle de document",
    import: "Importer",
    wrongFileFormat: "Votre fichier n’est pas au bon format",
    wrongFileFormatUseTemplate:
        "Votre document n'est pas au format attendu. Veuillez utiliser le template téléchargeable.",
    onlyAcceptCSV:
        "Nous n’acceptons que les fichiers au format .csv\nVeuillez faire les modifications appropriées et importer de nouveau votre document.",
    fileTooLarge: "Votre fichier est trop volumineux",
    maxCSVSizeIs:
        "La taille maximale acceptée de fichier est de 100Ko.\nVeuillez faire les modifications appropriées et importez de nouveau votre document.",
    anErrorOccured: "Une erreur s’est produite",
    askRetryImport: "Veuillez importer de nouveau votre document ou contacter notre support.",
    errorInDocument: "Il y a une erreur dans votre document",
    askCorrectDocument:
        "Des erreurs se sont glissées dans votre fichier et nous empêchent de pouvoir mettre à jour vos codes horaires.\nVeuillez effectuer les corrections et réimporter votre document ou contacter notre support.",
    confirmationImport_schedule: "Êtes-vous sûr de vouloir importer ces codes horaires ?",
    treatment_schedule: "Les nouveaux codes horaires seront créés et les codes horaires existants seront mis à jour.",
    updated_schedule: "Vos codes horaires ont été mis à jour !",
    errorInCSV_schedule:
        "Une erreur s'est glissé dans votre fichier et nous empêche de pouvoir mettre à jour vos codes horaires : __ERROR_MESSAGE__\nVeuillez effectuer les corrections et importer de nouveau votre document ou contacter notre support.",
    missingField: "__NAME__ n'a pas tous les champs requis renseignés.",
    invalidStartDate: "__NAME__ a une valeur invalide d'heure de début.",
    invalidEndDate: "__NAME__ a une valeur invalide d'heure de fin.",
    invalidPauseTime: "__NAME__ a une valeur invalide de temps de pause.",
    invalidWorkingTime: "__NAME__ a une valeur invalide de temps de travail.",
    youWantAddTimeCode: "Vous souhaitez ajouter un code horaire ?",
    beCareful: "Attention",
    modificationNotSaved: "Vous n'avez pas enregistré vos modifications, souhaitez-vous néanmoins continuer ?",
    continue: "Continuer",
    writeNotesOrQuestions: "Écrivez-nous vos remarques ou vos questions",
    addTimeCodeToCreateMission: "Ajouter un code horaire pour faciliter la création d'une mission",
    contactUsAtAddress: "Contactez-nous à l'adresse ",
    orAskToBeContacted: " ou demandez à être recontacté(e).",
    askToBeRecontacted: "Demander à être recontacté(e)",
    WhichTimeCodeYouWantAdd:
        "Quels codes horaires souhaitez-vous ajouter ? Veuillez préciser pour chaque code le nom du code, l'heure de début, l'heure de fin, la durée effectivement travaillée sans temps de pause.",
    placeholderNameSchedule: "ex: AJF19",
    placeholderInterfacingCodeSchedule: "ex: AV205",
    presentation: "Présentation",
    address: "Adresse",
    postalCode: "Code postal",
    modificationsSaved: "Les modifications ont été enregistrées",
    accessInfo: "Information d'accès",
    verticalMenuTitle: "Paramètres de l'établissement",
    templateName: "__NAME___Modele.csv",
    exportName: "__NAME___Export.csv",
    scheduleCamelCase: "CodesHoraires",
    infoOnWorkerCamelCase: "InfosSupp",
    salaryGridCamelCase: "GrilleDePrimes",
    noInternetConnexion: "Pas de connexion Internet",
    serviceCamelCase: "Services",
    contractDemo: "⚡ Mes Contrats",
    specialHourCamelCase: "HeuresSpéciales",
    institutionChronosCamelCase: "GtaChronos",
    adminCamelCase: "Admins",
    importInProgress: "Import en cours",
    importingPleaseWait: "Traitement de votre fichier en cours. Veuillez patienter quelques instants.",
    missionMotifCreationCamelCase: "Motifs",
    gtaOctimeCamelCase: "GtaOctime",
    interimCamelCase: "Interim",
    contractCamelCase: "Contracts",
    notificationCamelCase: "Notifications",
    jobCamelCase: "Métiers",
    specialtyCamelCase: "Spécialités",
    prefixMission: "Préfixe de la mission",
    phone: "Téléphone",
    siret: "Siret",
    corporateName: "Nom Légal",
    healthService: "Service de santé",
    urssafCode: "Code urssaf",
    apeCode: "Code ape",
    activateSso: "Activer le SSO",
    ssoViaOAuth2: "SSO via OAuth2",
    clientId: "Client ID",
    clientSecret: "Client secret",
    authUrl: "Auth URL",
    accessTokenUrl: "Access Token URL",
    userInfoUrl: "User info URL",
    scope: "Le scope",
    resource: "Ressource",
    responseType: "Type de réponse",
    usePKCE: "Utiliser la clé de preuve pour l'échange de code",
    auth2FormClientIdRequired: "Veuillez saisir le clientId de votre fournisseur de service",
    auth2FormAuthUrlRequired: "Veuillez saisir l'url utilisée pour l'authentification",
    auth2FormAccessTokenUrlRequired: "Veuillez saisir l'url de récupération de jeton d'accés",
    ssoName: "Désignation SSO",
    oauth2ConfigSaveError: "Une erreur est survenue lors de la sauvegarde des informations OAuth2",
    oauth2ConfigSaveSuccess: "Modification effectuée!",
    myProfile: "Mon profil",
    phoneNumber: "Numéro de téléphone portable",
    toChangeMailContactUs: "Pour changer votre email, contactez-nous à l'adresse _CONSTANTS_EMAIL_CONTACT_",
    changeMyPassword: "Changer mon mot de passe",
    actualPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmationPassword: "Confirmation du nouveau mot de passe",
    language: "Langue",
    confirmationCounterStatusTitle: "Êtes-vous sûr de vouloir modifier les limites d’heures ou de missions ?",
    confirmationCounterStatusText:
        "Les limites d'heure ou de missions pour l'ensemble de vos missions à venir seront recalculées.",
    searchPlaceholder_service: "Rechercher un service",
    searchPlaceholder_specialty: "Rechercher une spécialité",
    searchPlaceholder_job: "Rechercher un métier",
    searchPlaceholder_missionMotifCreation: "Rechercher un motif",
    searchPlaceholder_infoOnWorker: "Rechercher une info supplémentaire",
    identifyOrPasswordIncorrect: "Votre identifiant ou votre mot de passe est incorrect",
    institutionInfo: "Institution Info",
    hubSpotId: "hubSpotId",
    hubSpotInvoiceId: "hubSpotInvoiceId",
    institutionOptionContract: "Institution Option Contract",
    coefficientValue: "valeur du coefficient",
    hasDistinctionBetweenPartTimeAndFullTime: "Trame contrat temps partiel / temps complet",
    institutionOptionChurn: "institution Option Churn",
    isChurn: "Churn",
    firstNameTooShort: "Le prénom est trop court",
    lastNameTooShort: "Le nom est trop court",
    institutionOptionMission: "Institution Option Mission",
    hasPool: "Pool",
    institutionOptionWorker: "Institution Option Worker",
    canSelectOneWorkerForMissionRequestCreate: "Proposition de worker pour un besoin",
    hasDoubleValidation: "Double validation",
    canSelectBetweenRecupAndSupp: "Choix entre récupération et heures supplémentaires",
    institutionOption: "Option de l'établissement",
    isAddressRequired: "Adresse requise",
    isSocialSecurityNumberRequired: "Numéro de sécu",
    hasDPAE: "DPAE",
    isKorian: "Korian",
    isHCL: "HCL",
    hasContract: "Contrat",
    isMedgoDemo: "Demo Hublo",
    hasInterim: "Intérim",
    hasUnspecifiedService: "Service non spécifié",
    hasSMS: "SMS",
    canPostMissionWithoutSchedule: "Mission sans code horaire",
    canSeeTotalCostForInstitution: "Voir le coût total de la mission",
    canValidateMission: "Pouvoir valider les missions",
    country: "Pays",
    modify_institution: "Modifier l'établissement",
    modify_institutionOption: "Modifier les options de l'établissement",
    modify_institutionOptionWorker: "Modifier une option",
    modify_institutionOptionMission: "Modifier une option",
    modify_institutionOptionContract: "Modifier une option",
    add_institutionOptionMission: "Ajouter une option",
    add_institutionOptionWorker: "Ajouter une option",
    add_institutionOptionContract: "Ajouter une option",
    eSignature: "Sign. électronique",
    eSignatureProvider: "Sign. électronique prestataire",
    HasMassEsignatureEtab: "Signature en masse",
    HasMultiReplacementContract: "Contrat multi remplacement",
    healthPass: "Pass sanitaire",
    isCvMandatory: "CV obligatoire",
    isDiplomaMandatory: "Diplome obligatoire",
    hasHubloPool: "hublo pool",
    adminV2: "Administrateurs",
    status: "statut",
    all: "Tous",
    active: "Actif",
    inactive: "Inactif",
    getBackToAdminV2List: "Revenir à la liste des admins",
    lang: "langue",
    matricule: "matricule",
    contactInformation: "Informations de contact",
    levelOfAuthorityAndAssignment: "Niveau d'habilitation et affectations",
    documentsTemplates: "Modèles de documents",
    shiftSummaryTemplate: "Modèle de récapitulatif de mission",
    noTemplateUploaded: "Aucun modèle importé",
    submit: "envoyer",
    upload: "importer",
    selectAFile: "sélectionner un fichier",
    noFileSelected: "aucun fichier sélectionné",
    fileUploadSuccess: "Votre fichier a été importé !",
    confirmationSubtitle_admin_insert: "Ajout de l'admin à l'établissement",
    confirmationText_admin_insert:
        "Un des admins que vous voulez créer existe déjà dans un autre établissement. Nous allons seulement lui ajouter un nouvel établissement sans modifier ses informations. Voulez-vous continuer ?",
    placeholderFirstNameAdmin: "ex : Jean",
    placeholderLastNameAdmin: "ex : BERNARD",
    placeholderEmailAdmin: "ex : jean.bernard@hublo.com",
    placeholderMobilePhoneAdmin: "ex : 06 00 00 00 00",
    placeholderMatriculeAdmin: "ex : X00000",
    none: "Aucun",
    assignmentInstitutions: "site(s) d'affectation",
    position: "poste",
    notificationPoles: "pôle(s) de notification",
    notificationServices: "service(s) de notification",
    requiredFields: "champs obligatoires",
    assignmentInstitutions_customText: "établissement(s)",
    notificationPoles_customText: "pôle(s)",
    notificationServices_customText: "service(s)",
    confirmationTitle_deactivate_admin: "Êtes-vous sûr de vouloir désactiver cette licence ?",
    confirmationTitle_activate_admin: "Êtes-vous sûr de vouloir activer cet utilisateur ?",
    confirmationText_deactivate_admin: "L’administrateur ne pourra plus accéder à la plateforme.",
    confirmationText_activate_admin: "L'administrateur sera informé de ce changement.",
    notification_deactivate_admin: "Votre utilisateur a été désactivé !",
    notification_activate_admin: "Votre utilisateur a été activé !",
    specialties: "Compétences",
    subtitleSpecialties: "Compétences",
    agencyName: "Nom de l’agence",
    placeholderAgencyName: "ex : Interim System",
    placeholderAgencyEmail: "ex : interim@example.com",
    placeholderNbMinutesAfterLaunchingRequest: "3",
    placeholderAgencyJob: "Sélectionner",
    placeholderAgencySpecialty: "Sélectionner",
    adminHasNotBeenAdded: "L'admin n'a pas été ajouté à l'établissement",
    adminV2HasBeenCreated: "Votre administrateur a été créé !",
    adminV2HasBeenUpdated: "Votre administrateur a été modifé !",
    sector: "Secteur",
    numberOfActiveAdministrators: "Nombre d'administrateurs actifs :",
    noResult_adminV2: "Aucune correspondance trouvée",
    confirmationSubtitle_admin_update: "Cet admin utilise déjà Hublo !",
    confirmationText_admin_update:
        "Désolé, cet email existe déjà dans notre base de données. Utilisez l’ajout d’un admin pour lier l’admin à votre institution.",
    since: "depuis le",
    deactivateTheLicense: "Désactiver la licence",
    isOnForCreatingMission: "Service sélectionnable",
    isDeleted: "Code horaire sélectionnable",
    doYouWantToDeactivateAdminForAllInstitutions:
        "Voulez-vous désactiver cet administrateur de tous les établissements du groupement ?",
    ifYouClickNoAdminWillBeDeactivatedOnlyOnThisInstitution: `Si vous cliquez sur "Non", l'administrateur sera désactivé uniquement sur cet établissement.`,
    defaultPlaceholder: "Select...",
    manageNotifications: "Gérer mes notifications",
    notifAboutWorker: "Notifications liées à mon réseau de remplaçants",
    wantNotifWhenWorker: " Je souhaite être notifié lorsqu'un remplaçant...",
    notifAboutMissions: "Notifications liées à mes missions",
    wantNotifWhenAdmin: "Je souhaite être notifié lorsqu'un administrateur...",
    wantNotifWhenMission: "Je souhaite être notifié lorsqu'une mission...",
    wantNotifWhen: " Je souhaite être notifié lorsque...",
    listServicesWantNotif: "Voici la liste des services pour lesquels je souhaite être notifié",
    myServices: "Mes services",
    isInvited: "... est invité",
    subscribes: "... s'inscrit",
    isDeletedFromNetwork: "... est supprimé du réseau",
    hasSpecialtiesModified: "... a ses compétences modifiées",
    createsNewMission: "... poste une nouvelle mission",
    createsMissionRequest: "... poste un besoin de mission",
    applyStandardMission: "... postule à une mission Standard",
    applyExpressMission: "... postule à une mission Express",
    assignStandardMission: "... attribue une mission Standard à un candidat",
    cancelMission: "... annule une mission",
    declareFutureMission: "... déclare une mission future à un candidat",
    declarePastMission: "... déclare une mission passée à un candidat",
    askedNewSpecialties: "... a demandé de nouvelles compétences",
    wasValidated: "... a été validée",
    wasModified: ".. a été modifiée",
    isPending: "... est à traiter",
    mustBeValidated: "... doit être validée",
    hasMissionOnDifferentService: "... a une mission attribuée sur un service différent de son service d'origine",
    allCandidatesWarned: "... tous les candidats d'une mission ont été prévenus",
    adminWantsInterim: "... lorsqu'un cadre souhaite solliciter l'intérim pour une mission",
    agencySetInterim: "... lorsqu'une agence propose un intérimaire pour une mission",
    allTheTime: "Tout le temps",
    onlyConcernMyServices: "Uniquement si cela concerne mes services",
    onlyIInvited: "Uniquement si c'est moi qui l'ai invité",
    onlyMyMission: "Uniquement si c'est moi qui ai créé la mission",
    onlyMyMissionCanceledOtherAdmin:
        "Uniquement si j'ai posté la mission et qu'elle est annulée par un autre administrateur",
    never: "Jamais",
    accessNotifications: "Accéder aux notifications",
    hour: "Heure",
    shift: "Mission",
    idSubscription: "idSubscription",
    "idSubscription-form": "idSubscription",
    agencyClientId: "Agency ClientId",
    "agencyClientId-form": "Agency ClientId",
    subscriptionName: "Label",
    "subscriptionName-form": "Label",
    timeLimitationToPostShiftInDays: "Limite de création des missions (en jours)",
    timezone: "Fuseau horaire",
    slotConfig: "Configuration de créneau",
    dealShift: "Négocier une mission",
    internalPool: "Internal pool",
    internalPoolCalendarBlockDays: "Internal pool période de blocage du calendrier (jours)",
    floor: "Étage",
    building: "Bâtiment",
    hasSmock: "Tenue fournie",
    hasFood: "Repas compris",
    minCaregiversPerShift: "Soignants / garde (min)",
    bedsCapacity: "Nombre de lits",
    parking: "Parking",
    software: "Logiciel",
    digicode: "Digicode"
};
